// angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// app
import { SharedModule } from './features/shared/shared.module';
import { ModifierComponent } from './features/shared/modifier/modifier.component';
import { ModifierWrapperComponent } from './features/shared/modifier/modifier-wrapper/modifier-wrapper.component';
import { NotificationComponent } from './features/notification/notification.component';
import { ReviewAndPayComponent } from './features/review-and-pay/review-and-pay.component';
import {
  LocationGuardService,
  NotificationGuardService,
  AuthGuard,
  GuestUserGuard,
  SelectedLocationGuard,
  CartGuard,
  PaymentSelectionGuard,
  OrderingTimeActivateGuard,
  LocationAccessGuardService,
  BrowserGuardService
} from '@moduurnv2/libs-orderingapp/src/guards';
import { EmailResetLinkComponent } from './features/login/components/email-reset-link/email-reset-link.component';
import { EmailResetResolver } from './features/login/components/email-reset-link/email-reset-link-resolver';
import { ReviewAndPayWrapperComponent } from './features/review-and-pay/review-and-pay-wrapper/review-and-pay-wrapper.component';
import { PageNotFoundComponent } from './features/shared/page-not-found/page-not-found.component';
import {BrowserNotSupportComponent} from './features/shared/browser-not-support/browser-not-support.component';
import { LocationResolver } from './features/organization-locations/location.resolver';
import { TableEmptyComponent } from './features/table-empty/table-empty.component';
import { LocationAccessComponent } from './features/home/location-access/location-access.component';
import { UserRewardsWrapperComponent } from './features/user-rewards/user-rewards-wrapper/user-rewards-wrapper.component';
import { ValidateEmailComponent } from './features/payment-method/components/gift-loyalty/validate-email/validate-email.component';
import { SSORedirect } from './features/shared/sso-redirect/sso-redirect.component';
import { ForceUpdateComponent } from './features/force-login/force-update.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/locations',
    pathMatch: 'full',
  },
  {
    path: 'login',
    canActivate: [GuestUserGuard],
    loadChildren: () =>
      import('./features/login-wrapper/login-wrapper.module').then((m) => m.LoginWrapperModule),
    },


  {
    path: 'notification',
    canActivate: [NotificationGuardService],
    loadChildren: () =>
      import('./features/notification/notification.module').then(
        (m) => m.NotificationModule
      )
  },
  {
    path: 'user-profile',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/user-profile/user-profile.module').then(
        (m) => m.UserProfileModule
      )
  },
  {
    path: 'locations',
    canActivate: [LocationGuardService], // commented out part of MV2-1016(Removing default location selection flow)
    loadChildren: () =>
      import(
        './features/organization-locations/organization-locations.module'
      ).then((m) => m.OrganizationLocationsModule),
    resolve: [LocationResolver]
  },
  {
    path: 'locations/:locationId/order',
    pathMatch: 'full',
    canActivate: [SelectedLocationGuard, CartGuard, OrderingTimeActivateGuard],
    component: ReviewAndPayWrapperComponent
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./core/payments-wrapper/payments-wrapper.module').then(
        (m) => m.PaymentWrapperModule
      )
  },
  {
    path: 'payment-profile',
    loadChildren: () =>
      import('./core/payments-wrapper/payments-wrapper.module').then(
        (m) => m.PaymentWrapperModule
      )
  },
  {
    path: 'locations/:locationId',
    canActivate: [SelectedLocationGuard,LocationAccessGuardService],
    loadChildren: () =>
      import('./core/location-wrapper/location-wrapper.module').then(
        (m) => m.LocationWrapperModule
      )
  },
  {
    path: 'order/complete',
    canActivate: [CartGuard, PaymentSelectionGuard],
    loadChildren: () =>
      import('./features/order-complete/order-complete.module').then(
        (m) => m.OrderCompleteModule
      )
  },
  {
    path: 'order/success',
    loadChildren: () =>
      import('./features/order-success/order-success.module').then(
        (m) => m.OrderSuccessModule
      )
  },
  {
    path: 'modifier/:menuItemId',
    component: ModifierWrapperComponent,
  },
  {
    path: 'order-receipt',
    loadChildren: () =>
      import('./features/order-receipt/order-receipt.module').then(
        (m) => m.OrderReceiptModule
      ),
  },
  {
    path: 'locations/:locationId/view-orders',
    canActivate: [SelectedLocationGuard],
    loadChildren: () =>
      import('./features/view-all-orders/view-all-orders.module').then(
        (m) => m.ViewAllOrdersModule
      )
  },
  {
    path: 'reset',
    component: EmailResetLinkComponent,
    resolve: { encryptionDetails: EmailResetResolver },
  },
  {
    path: 'emptyTable',
    component: TableEmptyComponent,
  },
  {
    path: 'locationAccess',
    component: LocationAccessComponent,
  },
  // {
  //   path: 'notsupport',
  //   component: BrowserNotSupportComponent,
  // },
  {
    path: 'user-rewards',
    loadChildren: () =>
      import('./features/user-rewards/user-rewards.module').then(
        (m) => m.UserRewardsModule
      ),
    //component:UserRewardsWrapperComponent,
  },
  {
    path: 'validate/:validateId',
    component: ValidateEmailComponent,
  },
  {
    path: 'update',
    component: ForceUpdateComponent,
  },
  {
    path: 'ssoredirection',
    component: SSORedirect,
  },
  // {
  //   path: 'pagenotfound',
  //   component: PageNotFoundComponent,
  // },
  {
    path: '**',
    redirectTo: 'locations',
  },
];

@NgModule({
  imports: [SharedModule, RouterModule.forRoot(routes)],
  providers: [
    LocationGuardService,
    NotificationGuardService,
    AuthGuard,
    GuestUserGuard,
    CartGuard,
    SelectedLocationGuard,
    PaymentSelectionGuard,
    OrderingTimeActivateGuard,
    LocationResolver,
    LocationAccessGuardService,
    BrowserGuardService
  ],
})
export class AppRoutingModule {}

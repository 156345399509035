// angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

//rxjs
import { Observable, Subscription, throwError} from 'rxjs';

// app
import { environment } from '../../core/environments/environment';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';


@Injectable({
  providedIn: 'root'
})
export class OrderDetailsService {
  constructor(
    private http: HttpClient
  ) { }


  
  getOrderStatusText(textCode,deliveryTypeTextCode) {
    let text = '';
    switch (textCode) {
      case 'ORDERSTATUS01':
        text = 'order-received';
        break;
      case 'ORDERSTATUS02':
        text = 'order-received';
        break;
      case 'ORDERSTATUS03':
        text =
          deliveryTypeTextCode == 'DELIVERYTYPE04'
            ? 'order-processed'
            : 'order-prepared';
        break;
      case 'ORDERSTATUS04':
        if (
          deliveryTypeTextCode == 'DELIVERYTYPE01' ||
          deliveryTypeTextCode == 'DELIVERYTYPE03'
        )
          text = 'order-delivered';
        else if (
          deliveryTypeTextCode == 'DELIVERYTYPE02' ||
          deliveryTypeTextCode == 'DELIVERYTYPE07'
        )
          text = 'order-picked-up';
        else if (
            deliveryTypeTextCode == 'DELIVERYTYPE08' ||
            deliveryTypeTextCode == 'DELIVERYTYPE09'
          )
            text = 'order-completed';
        else if (deliveryTypeTextCode == 'DELIVERYTYPE04')
          text = 'order-mailed';
        else {
          text = 'order-delivered';
        }
        break;
      case 'ORDERSTATUS05':
        text = 'order-cancelled';
      default:
        break;
    }
    return text;
  }

  getOrderDetails(orderId:string):Observable<any>{
    const url = `${environment.locationApiUrl}orders/${orderId}/details`;
    return this.http.get(url);
  }

  // fetchInProgress(){
  //   const url = `${environment.customerApiUrl}customers/inprogress`;
  //   const data = {
  //       orderIds: null,
  //       customerId: null,
  //       organizationId: null,
  //   };
  //   const progressOrders = localStorage.getItem('progressOrders')
  //   const loggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
  //   const userData = JSON.parse(localStorage.getItem('userData'));

  //   if(loggedIn != true && progressOrders){
  //       const orderIds = JSON.parse(localStorage.getItem('progressOrders'));
  //       data.orderIds = orderIds;
  //   } else if(loggedIn == true && userData && userData.user_id){
  //       data.customerId = userData.user_id;
  //       data.organizationId = Config.organizationId;
  //   }else{
  //       return throwError(new Error('Incorrect details'));
  //   }

  //   return this.http.post(url, data);
  // }

  updateNotification(data){
      const url = `${environment.customerApiUrl}customers/notification`;
      
      return this.http.post(url, data);
  }
}

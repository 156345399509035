// angular
import { Injectable } from '@angular/core';

// app
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { Store } from '@ngxs/store';
import { LocationState } from '../state';

@Injectable({
  providedIn: 'root',
})
export class MenuItemService {

  constructor(private http: HttpClient, private store: Store) {}

  fetchMenuItemDetails(menuItem): Observable<any> {
    // const url = 'https://betawso2.moduurn.ca/mobileStoreApi/v1/stores/menuItem/5f22d3ee90d1d74e663bc0cb/menuItemDetails?locationId=5ad30febbf5dde30177f1e69&menuProfileId=5f22d3ed90d1d74e663bc06c&lat=48.4880405&long=-123.3896868';
    const location = this.store.selectSnapshot(app => app.location.selectedLocation);
    
    let url = `${environment.mobileStoreApiv3Url}stores/${location._id}/menuItem/${menuItem}`;
    const { futureDate } = this.store.selectSnapshot(app => app.cart);
    if(futureDate)
      url += `?selectedTime=${futureDate}`;

    return this.http.get(url);
  }
}

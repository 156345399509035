<!-- <div *ngIf="currentFutureTime"> -->
<div class="future-date" [ngClass]="{ 'home-background': static == false }">
  <img class="calander-image" [src]="iconURL" alt="calendar" />
  <div
    class="future-text"
    *ngIf="
      (currentFutureTime != 'ASAP' && static != false) || static == false;
      else asap_text
    "
  >
    <span class="pre-text">
      {{
        selectedDelivery && selectedDelivery.text
          ? selectedDelivery.text
          : 'Delivery'
      }}
      Time:</span
    >
    <span
      class="time-text"
      *ngIf="currentFutureTime == 'ASAP'; else timeText"
      >{{ getDisplayTime }}</span
    >
    <ng-template #timeText>
      <div class="time-text">{{ getDisplayTime }}</div>
    </ng-template>
  </div>

  <ng-template #asap_text>
    <div class="asap-text">{{ eta | translate }}</div></ng-template
  >

  <div class="future-text" *ngIf="showChange()">
    <button
      (click)="futureOrderFromOrganization()"
      *ngIf="static == false && isEnableDeliverySchedule"
      data-qa="change"
    >
      {{ 'change' | translate }}
    </button>
  </div>
</div>
<!-- </div> -->

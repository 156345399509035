// angular
import { Injectable } from '@angular/core';
// app
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { OrderResponse } from '../models/order.interface';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class OrderService {  
  constructor(private http: HttpClient, private store: Store) {}

  placeOrder(payload) {
    const url = `${environment.placeOrderApiUrl}placeOrderV3?isAPI=true`;
    // if (payload.order.deliveryAddress.state == 'British Columbia')
    //   payload.order.deliveryAddress.state = 'BC';
   // const url = `http://localhost:8288/api/v2/orders/placeOrderV3?isAPI=true`;
    return this.http.post<OrderResponse>(url, payload);
  }

  clearCurrentCart(locationId, cartId) {
    const payload = {
      locationId: locationId,
      giftCardAmount: 0,
    };
    const url = `${environment.cartApiUrl}${cartId}/clear`;
    return this.http.post(url, payload);
  }

  getMealCodeCart(cartId){
    let deliveryLocation =
      localStorage.getItem('selectedAdrsLocation') &&
      localStorage.getItem('selectedAdrsLocation') != 'undefined'
        ? JSON.parse(localStorage.getItem('selectedAdrsLocation'))
        : undefined;
    let lat = deliveryLocation ? deliveryLocation['loc'][1] : undefined;
    let long = deliveryLocation ? deliveryLocation['loc'][0] : undefined;
    let distance = localStorage.getItem('locationDistance')
      ? JSON.parse(localStorage.getItem('locationDistance'))
      : undefined;

    let deliveryTime = new Date().toISOString();
    const { futureDate } = this.store.selectSnapshot((app) => app.cart);
    if (futureDate) {
      deliveryTime = new Date(futureDate).toISOString();
    }
    let url = `${environment.placeOrderApiUrl}cart/${cartId}/mealCodePayment?deliveryTime=${deliveryTime}`;
    if (lat && long && distance !== undefined)
      url += `&latitude=${lat}&longitude=${long}&distance=${distance * 1000}`;
    return this.http.get(url);
  } 
}

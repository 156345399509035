<div class="row outer-container showBackground">
<div class="row change-adrs-container show">
  <div class="header" *ngIf="!isAddressBook">
    <div class="firstcolumn">
      <span aria-label="saved address">{{ 'saved-adrs' | translate }}</span>
    </div>
    <div class="secondcolumn">
      <img
        [src]="icon.close"
        (click)="closePopup($event)"
        class="icon"
        data-qa="close-adrs-list"
        alt="close"
      />
    </div>
  </div>
  <div class="alt-header" *ngIf="isAddressBook">
    <div class="alt-firstcolumn">
      <img
        [src]="icon.back"
        (click)="closePopup($event)"
        class="icon"
        data-qa="close-adrs-list"
        alt="back"
      />
    </div>
    <div class="alt-secondcolumn">
      <span>{{ 'your-adrs' | translate }}</span>
    </div>
  </div>
  <div class="adrs-card">
    <p>{{ 'address-why-used' | translate }}</p>
    <button type="button" (click)="addNewAddress()" class="sub-header">
      <img [src]="icon.add_red" class="icon" alt="add icon"/>
      <span data-qa="address-list-add" aria-label="addresslist-add">
        {{ 'add-address' | translate }}
      </span>
    </button>

    <moduurnv2-address-card
      [isAddressBook]="isAddressBook"
      *ngFor="let item of adrsDetailsList"
      [items]="item"
      (closeNewScreen)="closeCurrentScreen($event)"
      (closeModal)="closePage()"
    >
    </moduurnv2-address-card>
  </div>
</div></div>
<!-- <moduurnv2-address-map
  [isAddressBook]="isAddressBook"
  [modalMapStatus]="viewMapModalStatus"
  *ngIf="viewMapModalStatus"
  (closeEvent)="closeMap()"
>
</moduurnv2-address-map> -->

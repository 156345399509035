<div class="web-view-outer-container">
  <div
    class="review-container"
    *ngIf="cartItems && cartItems[0] && cartPrice && locationDefaults"
  >
    <div class="header-banner">
      <div
        class="back-btn"
        (click)="closeReview()"
        data-qa="review-back-button"
        aria-label="back"
      >
        <img src="assets/images/Back.svg" class="left-icon" alt="back"/><span
          class="back-text"
          >{{ 'back' | translate }}</span
        >
      </div>
      <div class="heading">
        {{
          selectedDelivery && selectedDelivery.textCode == 'DELIVERYTYPE08'
            ? ('ReviewOrder' | translate)
            : ('ReviewPay' | translate)
        }}
      </div>
    </div>
    <div class="review-section">
      <div class="review-max-container">
        <moduurnv2-address-selector-desktop></moduurnv2-address-selector-desktop>
        <div class="review-and-pay-content">
          <div class="review-and-pay">
            <div class="combo-outer">
              <moduurnv2-combo-list-cart *ngIf="cartItems[0]?.combos?.length > 0"></moduurnv2-combo-list-cart>
            </div>
            <span class="seperator-line" *ngIf="cartItems[0]?.combos?.length > 0 && cartItems[0]?.menuItems?.length > 0"></span>
            <moduurnv2-list-cart
              [items]="cartItems[0].menuItems"
              *ngIf="cartItems[0].menuItems.length > 0"
            ></moduurnv2-list-cart>
            <ng-container  *ngIf="getModifiers$ | async as modifiers;">
            <ng-container   *ngIf="orderSettings.isAllowOrderLevelModifier && modifiers.length">
            <hr>
            <h4 *ngIf="locationDefaults.activeProfile && locationDefaults.activeProfile.orderLevelModifierConfigurations && locationDefaults.activeProfile.orderLevelModifierConfigurations.name">{{locationDefaults?.activeProfile?.orderLevelModifierConfigurations?.name | formattedLanguage : locationDefaults.activeProfile.orderLevelModifierConfigurations.translation}}</h4>
            <moduurnv2-order-level-modifiers>
              
            </moduurnv2-order-level-modifiers>
          </ng-container>
        </ng-container>


        <ng-container *ngIf="!hideSpecialInstructions" >        
            <moduurnv2-special-instruction-desktop
              (open)="openSpecialInstruction()"
              [isSpecialInstructionPresent]="isSpecialInstructionPresent"
              [specialInstructionText]="specialInstructionText"
              [isSpecialInstruction]="isSpecialInstruction"
            ></moduurnv2-special-instruction-desktop>

            <div
              class="special-instruction-container"
              *ngIf="isSpecialInstruction"
              >              
              <!-- <img
                src="/assets/images/close-small.svg"
                (click)="closeSpecialInstructionView()"
                data-qa="close-button"
                class="close"
                aria-label="close"
                alt="close"
              /> -->
              <textarea
                class="text"
                rows="4"
                tabindex="1"
                #specialInstruction
                data-qa="special-instruction-text"
                aria-label="special instruction text"
                [placeholder]="
                  orderSettings?.specialInstructionPopupPlaceholder
                    ? orderSettings.specialInstructionPopupPlaceholder
                    : 'Please enter instructions here'
                "
                (keydown)="clickedOnSpecialInst()"
                (click)="clickedOnSpecialInst()"
                
                >{{ specialInstructionText }}</textarea
              >
              <div class="instruction">
                {{ specialInstructionPopupMessage }}
              </div>
              <!-- <div
                class="button-wrapper"
                *ngIf="isSpecialInstructionPresent; else addButton"
              >
                <button
                  class="btn delete-btn"
                  (click)="deleteSpecialInstruction()"
                  aria-label="delete"
                  data-qa="delete-special-instruction"
                >
                  {{ 'delete-btn' | translate }}
                </button>
                <button
                  class="btn save-btn"
                  (click)="saveSpecialInstruction(specialInstruction?.value)"
                  data-qa="save-special-instruction-button"
                  aria-label="save"
                >
                  {{ 'save' | translate }}
                </button>
              </div>
              <ng-template #addButton>
                <button
                  class="add-instruction"
                  (click)="saveSpecialInstruction(specialInstruction?.value)"
                  data-qa="add-special-instruction-button"
                  aria-label="add"
                >
                  {{ 'add' | translate }}
                </button>
              </ng-template> -->
            </div>
          </ng-container>

            <ng-container *ngIf="!hideInstructions">
              <moduurnv2-alert></moduurnv2-alert>
            </ng-container>

            <moduurnv2-price-split-up
              *ngIf="!allowNoPaymentOrdering"
            ></moduurnv2-price-split-up>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="payment-method-container">
    <ng-container *ngIf="cartPrice?.totalAmount > 0">
    <div class="payment-header">
      <div class="payment-step" aria-label="finish order">
        {{ 'finish-ordering' | translate }}:
        <span>{{ 'step' | translate }} 1 / 2</span>
      </div>
      <span class="pay-method-heading" aria-label="select payment">{{
        'select-payment-method' | translate
      }}</span>
      <img
        [src]="icon.selected"
        *ngIf="nextPage || isAckrooPayment() || isMealPayment()"
        class="pay-selected"
        alt="payment selected"
      />
    </div>
    <div
      *ngIf="!nextPage && !isAckrooPayment() && !isMealPayment() && !isPayLocation"
      class="payment-sub-container"
      [ngClass]="{ setheight: desktopView && !giftCard }"
    >
      <moduurnv2-payment (desktopView)="setDesktop($event)"></moduurnv2-payment>
    </div>
    <div *ngIf="nextPage || isAckrooPayment() || isMealPayment() || isPayLocation" class="payment-sub-container">
      <moduurnv2-customer-card-info
        (changePayMethod)="changePayMethod($event)"
      ></moduurnv2-customer-card-info>
    </div>
  </ng-container>
    <div
      class="customer-info-container"
      [class.cust-bottom-fixed]="cartPrice?.totalAmount == 0"
      *ngIf="nextPage || isAckrooPayment() || isMealPayment() || isPayLocation"
    >
      <div class="customer-header">
        <div class="customer-step">
          {{ 'finish-ordering' | translate }}
          <span *ngIf="cartPrice?.totalAmount > 0">{{ 'step' | translate }} 2 / 2</span>
        </div>
        <span class="customer-info-heading">{{
          'customer-information' | translate
        }}</span>
      </div>
      <moduurnv2-customer-info></moduurnv2-customer-info>
    </div>

    <div *ngIf="desktopView && !nextPage && !isPayOnDelivery && !giftCard">
      <moduurnv2-add-payment-card
        (addCardDesktopView)="addCardDesktop($event)"
      ></moduurnv2-add-payment-card>
    </div>
  </div>
</div>

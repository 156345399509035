<div class="row outer-container">
  <div class="header-container">
    <span class="header" aria-label="pick up details">{{ 'pick-up-details'| translate }}</span>
    <div
    class="head-icon-outer"
    data-qa="close-pickup-popup"
    aria-label="close"
    (click)="closeSelectionModal()"
  >
    <img [src]="closeButton" alt="close button"/>
  </div>
  </div>
  <div class="action-outer-container">
    <ng-container *ngIf="pickUpStations;else noPickupStation">
      <div class="custom-selected">
        <div class="selected">
          <div
            class="selected-text"
            (click)="openPickUpSelectionList($event)"
            id="location"
          >
          <ng-container *ngIf="!showPickUpStationList && selectedPickUpStation?.name;else noSelection">
            <div class="text">{{ selectedPickUpStation?.name }}</div>
          </ng-container>
          <ng-template #noSelection>
            <div class="placeholder-text">{{'choose-pick-up'| translate }}</div>
          </ng-template>
            <img class="image" src="assets/images/Chevron_Down.svg" alt="chevron down" />
          </div>
          <div
            class="selection-list"
            [ngClass]="{ show: showPickUpStationList }"
          >
            <div
              class="selection-item"
              *ngFor="let station of pickUpStations"
              (click)="pickUpStationSelection(station, $event)"
            >
              {{ station.name }}
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="requiredError" class="error">
        {{ requiredError }}
      </div>
    </ng-container>
    <ng-template #noPickupStation>
      {{ 'no-pick-ups'| translate }}
    </ng-template>
  </div>
  <button class="block-button proceed-btn" (click)="proceed()" aria-label="change location" data-qa="change-location">
    {{ 'Continue-adrs'| translate }}
  </button>
</div>

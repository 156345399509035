<div class="tip-container">
  <div class="tip-detail">
    <div class="tip-action options">
      <label class="add-tip options-label" aria-label="add tip"
        >{{ 'add-tip' | translate }}
        <input
          type="checkbox"
          (click)="onCheckClicked()"
          data-qa="add-tip-checkbox"
          [checked]="showData"
        />
        <span class="checkmark"></span>
      </label>
    </div>
    <span class="tip-amount">{{ tipAmount | formattedCurrency }}</span>
  </div>
  <div class="tip-value" *ngIf="showData">
    <ng-container *ngFor="let item of avalibleTips.tipValues">
      <div
        class="tip-percent"
        [ngClass]="{ active: item?.value == selectedPercent }"
        (click)="onValueChanged(item?.value, '%')"
        data-qa="percent-tip"
      >
        <label>{{ item?.value == 0 ? 'No Tip' : item?.value + '%' }}</label>
      </div>
    </ng-container>
    <div class="tip-percent enter-tip">
      <label>{{ whitelabel.currency }}</label>
      <input
        id="tipAmount"
        type="tel"
        placeholder=" {{'enter-amt'| translate}}."
        value="{{ selectedAmount }}"
        (keyup)="onValueChanged($event?.target?.value, '$', true, true)"
        (keyup.enter)="onValueSet({ type: '$', value: selectedAmount })"
        (focusout)="onValueSet({ type: '$', value: selectedAmount })"
        data-qa="amount-tip"
      />
    </div>
  </div>
</div>

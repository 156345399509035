import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import {
  SweetAlertService,
  Address,
  AddressState,
  SharedService,
  VenueManagementState,
  CartState,
  LocationState,
  AuthState,
  AddressComponentEnums,
} from '@moduurnv2/libs-orderingapp/src/core';
import {
  ChangeMapPopupStatus,
} from '@moduurnv2/libs-orderingapp/src/core/actions';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { RoomUpdateSelectionComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/venue-management/components/room-update-selection/room-update-selection.component';
import { RoomTimeSelectionComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/venue-management/components/room-time-selection/room-time-selection.component';
import { AddressListDesktopComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/address-book-desktop/address-list.component-desktop'
import { AddressListComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/address-book/address-list.component'
import { AddressWrapperComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/address-wrapper/address-wrapper.component'
import { Subject } from 'rxjs';
@Injectable()
export class MenuGuardService implements CanActivate {
  isFirstTime: Boolean = true;
  selectedLoc: Address;
  isMobile:boolean;
  @Select(AddressState.getDeliveryAddress) DeliveryAddress$: Observable<
    Address
  >;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    public store: Store,
    public router: Router,
    public sweetAlert: SweetAlertService,
    private translate: TranslateService,
    public modal: ModalService,
    private shared: SharedService
  ) {}
  canActivate(): boolean {
    const selectedDelivery = this.store.selectSnapshot(
      (app) => app && app.delivery && app.delivery.selectedDelivery
    );
    this.DeliveryAddress$.subscribe((address) => {
      this.selectedLoc = address;
    });
    if (
      (this.selectedLoc == undefined || this.selectedLoc == null) &&
      selectedDelivery &&
      selectedDelivery.textCode == 'DELIVERYTYPE01'
    ) {
       this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
        (isMobileView) => (this.isMobile = isMobileView)
      );
      const address = this.store.selectSnapshot(AddressState.getAddressList);
      const addressListLocal = this.store.selectSnapshot(AddressState.getAddressListFromLocal);
      const auth = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
      let pageToShow=AddressComponentEnums.ADDRESS_LIST;
      if((auth?.isLoggedIn && !address?.length) || (!auth?.isLoggedIn && !addressListLocal?.length)){
        pageToShow = AddressComponentEnums.ADDRESS_DETAILS;
      }
      if(this.isMobile){
        const selectedLocation = this.store.selectSnapshot(
          LocationState.getSelectedLocation
        );
        let selectedFromPage = this.router.url.split('/').pop();
        if(selectedFromPage != 'order')
          this.modal.showSmallModal(AddressWrapperComponent,{data:{pageToShow:pageToShow}});
        else
        this.router.navigate([`locations/${selectedLocation._id}/home`]);
        return false;
      }else{
     
      let selectedFromPage = this.router.url.split('/').pop();
      if (selectedFromPage != 'order'){
    
    this.modal.showMediumModalDesktop(AddressWrapperComponent,{data:{pageToShow:pageToShow}});
      }
      // this.modal.showMediumModalDesktop(AddressWrapperComponent);
      else {
        this.sweetAlert.toast(
          'error',
          this.translate.instant('select-delivery-address')
        );
        this.modal.showMediumModalDesktop(AddressWrapperComponent,{data:{pageToShow:pageToShow}});
      }

      return false;
    }
    } else if (selectedDelivery?.textCode == 'DELIVERYTYPE10') {
      let selectedVenue = this.store.selectSnapshot(
        VenueManagementState.getSelectedRoom
      );
      let selectedFutureDate = this.store.selectSnapshot(CartState.getFutureDate);
      if (selectedVenue?.name) {
        if(!selectedFutureDate && selectedVenue?.allowSchedule){
          this.forceUserSelectVenue();
          return false;
        } else {
          return true;
        }
      } else {
        this.forceUserSelectVenue();
        return false;
      }
    } else {
      return true;
    }
  }

  forceUserSelectVenue() {
    this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobile = isMobileView)
    );
    if (this.isMobile) {
      this.modal.showModal(RoomTimeSelectionComponent);
    } else {
      this.modal.showMediumModalDesktop(RoomUpdateSelectionComponent);
    }
  }
}

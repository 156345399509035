import { State, Selector, StateContext, Action, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CartCreation, Cart, CartItems, CartResponse, FutureTimesModel } from '../models';
import {
  FetchCart,
  SetSelectedCart,
  SetCartItems,
  AddItemToCart,
  UpdateCart,
  SetCartTip,
  ClearCart,
  SetFutureDate,
  UpdateCount,
  FetchPrepTime,
  UpdateCartItem,
  FetchCartMenuItems,
  SetSpecialInstruction,
  ClearFutureDate,
  SetDefaultTipApplied,
  UpdateCartTotal,
  FetchETA,SetAckrooPaymentDetails, ClearDeals,
  UpdateCartDetails, ValidateDeal,
  FetchFutureTimes,
  ClearFutureTimes,
  UpdateComboCount,
  DeleteCombo,
  AddOrderLevelModifierToCart,
  RemoveOrderLevelModifierFromCart,
  FetchOrderLevelModifiers,
  SetMealPaymentDetails,
  PerformReordering
} from '../actions';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { Injectable } from '@angular/core';
import { tap, catchError, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { CartService, SweetAlertService } from '../services';
import { Tip } from '../models/cart.interface';
import { LocationState } from './location.state';
import { OrderService,SharedService } from '../services';
import { SetCartId } from '../actions/auth.actions';
import { forkJoin, throwError } from 'rxjs';
import {AuthState} from './auth.state';
import { DealsState } from './deals.state';
import { DeliveryState } from './delivery-methods.state';
import { LocationDefaultState } from './location-defaults.state';
export class CartStateModel {
  cartCreation: CartCreation;
  selectedCart: CartCreation;
  cart: Cart;
  cartItems: CartItems[];
  cartResponse: CartResponse;
  tip: any;
  paymentGateway: any;
  futureDate: string;
  futureEndDate:string
  prepTime: any;
  specialInstruction: string;
  eta: string;
  defaultTipApplied: boolean;
  futureTimes : FutureTimesModel;
}

@State<CartStateModel>({
  name: 'cart',
  defaults: {
    defaultTipApplied: false,
    cart: undefined,
    cartItems: undefined,
    selectedCart: null,
    cartCreation: {
      organizationId: '',
      locationId: '',
      deliveryMethod: '',
      guest: true,
      customerId:undefined,
      combos: undefined,
      futureOrderDetails: undefined,
      menuItems: undefined,
      totalPrice: 100,
    },
    cartResponse: undefined,
    tip: undefined,
    paymentGateway: undefined,
    futureDate: null,
    futureEndDate:undefined,
    prepTime: null,
    specialInstruction: '',
    eta: null,
    futureTimes: undefined
  },
})
@Injectable()
export class CartState {
  constructor(
    private cartservice: CartService,
    private store: Store,
    private orderService: OrderService,
    private sweetAlert:SweetAlertService,
    private shared:SharedService,
    private router: Router
  ) {
  }

  @Selector()
  static getCart(state: CartStateModel) {
    return state.cart;
  }

  @Selector()
  static getCartComboPriceItems(state: CartStateModel) {
    return state.cart?.comboPrice;
  }

  @Selector()
  static getCartPriceItems(state: CartStateModel) {
    return state.cart.itemPrice;
  }
  @Selector()
  static getCartItems(state: CartStateModel) {
    return state.cartItems;
  }
  @Selector()
  static getCartResponse(state: CartStateModel) {
    return state.cartResponse;
  }
  @Selector()
  static getCartTip(state: CartStateModel) {
    return state.tip;
  }
  @Selector()
  static getPaymentGateway(state: CartStateModel) {
    return state.paymentGateway;
  }

  @Selector()
  static getPrepTime(state: CartStateModel) {
    return state.prepTime;
  }
  @Selector()
  static getFutureDate(state: CartStateModel) {
    return state.futureDate;
  }
  @Selector()
  static getFutureEndDateTime(state: CartStateModel) {
    return state.futureEndDate;
  }

  @Selector()
  static getSpecialInstruction(state: CartStateModel) {
    return state.specialInstruction;
  }
  @Selector()
  static getETA(state: CartStateModel) {
    return state.eta;
  }
  @Selector()
  static getDeaultTipApplied(state: CartStateModel) {
    return state.defaultTipApplied;
  }
  @Selector()
  static getFutureTimes(state: CartStateModel) {
    return state.futureTimes;
  }

  @Action(AddItemToCart)
  addItemToCart(
    { getState }: StateContext<CartStateModel>,
    { payload }: AddItemToCart
  ) {
    const { futureDate } = getState();
    const menuItem = payload;
    const selectedDelivery = this.store.selectSnapshot(
      (app) => app && app.delivery && app.delivery.selectedDelivery
    );
    const { _id: locationId } = this.store.selectSnapshot(
      (app) => app.location.selectedLocation
    );
    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    const newCart: CartCreation = {
      organizationId: Config.organizationId,
      locationId: locationId,
      deliveryMethod: selectedDelivery._id,
      guest: customer?.customerId ? false: true,
      menuItems: menuItem,
      customerId: customer?.customerId ? customer.customerId : undefined,
      combos: [],
      futureOrderDetails: { isFuture: false, pickupTime: '' },
      totalPrice: 100,
    };
    if (futureDate) {
      newCart.futureOrderDetails.isFuture = true;
      newCart.futureOrderDetails.pickupTime = new Date(
        futureDate
      ).toISOString();
    }
    this.store.dispatch(new SetSelectedCart(newCart));
    this.store.dispatch(new FetchETA());
    this.store.dispatch(new SetAckrooPaymentDetails(undefined));
    this.store.dispatch(new SetMealPaymentDetails(undefined));
  }

  @Action(UpdateCart)
  updateCart(
    { setState }: StateContext<CartStateModel>,
    { payload }: UpdateCart
  ) {
    const getcart = this.store.selectSnapshot(CartState.getCart);
    console.warn(getcart);
    return this.cartservice.updateCart(payload).pipe(
      tap((response) => {
        if (response) {
          setState(
            patch({
              cart: response,
            })
          );
          this.store.dispatch(new SetAckrooPaymentDetails(undefined));
          this.store.dispatch(new SetMealPaymentDetails(undefined));
          this.cartservice.getCartItems(getcart.cart_id, Config.organizationId);
        } else throw response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  @Action(UpdateCount)
  updateCount(
    { setState }: StateContext<CartStateModel>,
    { payload }: UpdateCount
  ) {
    const getcart = this.store.selectSnapshot(CartState.getCart);
    console.warn(getcart);
    return this.cartservice.updateCount(payload).pipe(
      tap((response) => {
        console.log(response)
        if (response && response["cart_id"]) {
          setState(
            patch({
              cart: response,
            })
          );
          this.store.dispatch(new SetAckrooPaymentDetails(undefined));
          // this.store.dispatch(new SetMealPaymentDetails(undefined));
          this.cartservice.getCartItems(getcart.cart_id, Config.organizationId);
          const dealInCart = this.store.selectSnapshot(DealsState.getSelectedDeal);
          const cart = this.store.selectSnapshot(CartState.getCart);
          if(dealInCart && cart.cart_id){
            this.store.dispatch(new ValidateDeal(cart.cart_id,cart.subTotal));
          }
        } else {

          this.sweetAlert.toast('error', response['msg']);
          throw response};
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  @Action(SetSelectedCart)
  setCart(
    { setState }: StateContext<CartStateModel>,
    { payload }: SetSelectedCart
  ) {
    const getcart = this.store.selectSnapshot(CartState.getCart);
    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    if(!getcart && customer?.customerId){
      payload['guest'] = false;
      payload['customerId'] = customer.customerId;
    }
    return this.cartservice
      .createNewCart(payload, getcart ? getcart.cart_id : undefined)
      .pipe(
        tap((response) => {
          if (response) {
            if (response['cart_id'])
              this.store.dispatch(new SetCartId(response['cart_id']));
            setState(
              patch({
                cart: response,
              })
            );
            console.warn("response with cart",response);
            if(response['cart_id'])
            this.cartservice.getCartItems(
              response['cart_id'],
              payload['organizationId']
            );

            if(response['errorCode'] == 1500){
              this.shared.forceClearCart(response);
            }
          } else throw response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  @Action(SetCartItems)
  setCartItems({ setState }, { payload }: SetCartItems) {
    if (payload.cartId)
      return this.cartservice.getCartItemDetails(payload).pipe(
        tap((response) => {
          if (response) {
            setState(
              patch({
                cartItems: response,
                paymentGateway: response[0].paymentGateway,
              })
            );
          } else throw response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
    else return console.error({ message: 'no cart id' });
  }
  @Action(FetchCart)
  fetchCartItems({ patchState, getState }: StateContext<CartStateModel>) {
    const { cart } = getState();
    if (cart && cart.cart_id && cart.locationId) {
      const cartId = cart.cart_id;
      const locationId = cart.locationId;
      this.cartservice
        .getCartDetails(cartId, locationId)
        .subscribe((response) => {
          const cartResponse: any = response;
          if(response['errorCode'] == 1500){
            this.shared.forceClearCart(response);
          }else
          if(response)
          this.store.dispatch(new FetchETA());
          // if(cartResponse && cartResponse.orderLevelModifierPrice && cartResponse.orderLevelModifierPrice.length ){

            if (this.router.url.indexOf('/order') >= -1)
              this.fetchOrderLevelModifiers();
          // }
          patchState({
            cart: cartResponse,
          });
        });
    }
  }

  @Action(ClearCart)
  ClearCart(
    { patchState }: StateContext<CartStateModel>,
    { payload }: ClearCart
  ) {
    const selectedCart = this.store.selectSnapshot(CartState.getCart);
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    let selectedLocationId = undefined;

    if (payload) {
      selectedLocationId = payload;
    } else if (selectedLocation && selectedLocation._id) {
      selectedLocationId = selectedLocation._id;
    }
    if (selectedLocationId && selectedCart && selectedCart.cart_id) {
      this.orderService.clearCurrentCart(
        selectedLocationId,
        selectedCart.cart_id
      ).subscribe(
        (response) => {},
        (err) => {
          console.error(err);
          this.sweetAlert.toast('error', err.message);
        }
      )
    }
    this.store.dispatch(new ClearDeals());

    patchState({
      defaultTipApplied: false,
      cart: undefined,
      cartItems: undefined,
      selectedCart: null,
      cartCreation: {
        organizationId: '',
        locationId: '',
        deliveryMethod: '',
        guest: true,
        customerId:undefined,
        combos: undefined,
        futureOrderDetails: undefined,
        menuItems: undefined,
        totalPrice: 100,
      },
      cartResponse: undefined,
      tip: undefined,
      specialInstruction: '',
      eta: null,
    });
    this.store.dispatch(new SetAckrooPaymentDetails(undefined));
    this.store.dispatch(new SetMealPaymentDetails(undefined));
  }
  @Action(SetCartTip)
  setCartTip(
    { patchState, getState }: StateContext<CartStateModel>,
    { payload }: SetCartTip
  ) {
    let selectedCartItem = this.store.selectSnapshot(CartState.getCartItems);
    return this.cartservice.addTip(payload).pipe(
      tap((response) => {
        if (response) {
          if (response && response.status == 1) {
            let selectedTip = {} as Tip;
            selectedTip.type = payload.tip['type'];
            selectedTip.value = payload.tip['value'];
            selectedCartItem[0].tip = selectedTip;

            patchState({
              cartResponse: response,
              cartItems: selectedCartItem,
            });
          }
          this.store.dispatch(new SetAckrooPaymentDetails(undefined));
          // this.store.dispatch(new SetMealPaymentDetails(undefined));
          this.store.dispatch(new FetchCart());
          this.store.dispatch(new FetchCartMenuItems({}));
          
        } else throw response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  @Action(SetFutureDate)
  setFuture(
    { patchState, getState }: StateContext<CartStateModel>,
    { payload }: SetFutureDate
  ) {
    if (payload) {
      const { location, futureTime } = payload;
      let futureEndDateTime = payload.futureEndTime;

      const { cart } = getState();
      let cart_id = null;
      if (cart) cart_id = cart.cart_id;
      if (futureTime) {
        this.cartservice
          .checkFuture(futureTime, location._id, cart_id,payload)
          .subscribe(
            (response) => {
              if (response) {
                const { status, isBlackout, message } = response as any;
                if (status === 200) {
                  if (!isBlackout) {
                    if(futureEndDateTime){
                      patchState({
                        futureDate: futureTime,
                        futureEndDate: futureEndDateTime
                      })
                    } else {
                      patchState({
                        futureDate: futureTime,
                      });
                    }
                    this.cartservice.futureDateChanged();
                  }else{
                    this.sweetAlert.toast('error', message);
                  }
                } else {
                  console.error(message);
                }
              }
            },
            (err) => {
              console.error(err);
              this.sweetAlert.toast('error', err.message);
            }
          );
      } else {
        if(futureEndDateTime){
          patchState({
            futureDate: futureTime,
            futureEndDate: futureEndDateTime
          })
        } else {
          patchState({
            futureDate: futureTime,
          });
        }
        
        if(!futureTime){
          patchState({
            futureEndDate: undefined
          })
        }
        this.cartservice.futureDateChanged();
      }
    }
  }

  @Action(FetchPrepTime)
  fetchPrepTime(
    { setState }: StateContext<CartStateModel>,
    { payload }: FetchPrepTime
  ) {
    return this.cartservice.fetchPrepTime(payload).pipe(
      tap((response) => {
        setState(
          patch({
            prepTime: response,
          })
        );
      })
    );
  }

  @Action(UpdateCartItem)
  updateCartItem(
    { getState }: StateContext<CartStateModel>,
    { payload }: UpdateCartItem
  ) {
    const { cart } = getState();
    const cartId = cart.cart_id;
    return this.cartservice.updateCartItem(payload, cartId).pipe(
      switchMap((response) =>
        forkJoin([
          this.store.dispatch(new FetchCart()),
          this.store.dispatch(
            new SetCartItems({
              cartId,
              organizationId: Config.organizationId,
            })
          ),
          this.store.dispatch(new ValidateDeal(cart?.cart_id,cart?.subTotal)),
        ])
      )
    );
  }

  @Action(FetchCartMenuItems)
  fetchCartMenuItems({ getState, setState }: StateContext<CartStateModel>) {
    const getcart = this.store.selectSnapshot(CartState.getCart);
    if (getcart && getcart.cart_id)
      this.cartservice.getCartItems(getcart.cart_id, Config.organizationId);
  }

  @Action(SetSpecialInstruction)
  setSpecialInstruction(
    { setState }: StateContext<CartStateModel>,
    { payload }: SetSpecialInstruction
  ) {
    setState(
      patch({
        specialInstruction: payload,
      })
    );
  }

  @Action(SetDefaultTipApplied)
  SetDefaultTipApplied(
    { setState }: StateContext<CartStateModel>,
    { payload }: SetDefaultTipApplied
  ) {
    setState(
      patch({
        defaultTipApplied: payload,
      })
    );
  }
  @Action(UpdateCartTotal)
  updateCartTotal(
    { setState }: StateContext<CartStateModel>,
    { payload }: UpdateCartTotal
  ) {
    setState(
      patch({
        cart: payload,
      })
    );
  }

  @Action(FetchETA)
  fetchETA({ setState }: StateContext<CartStateModel>) {
    return this.cartservice.fetchETA().pipe(
      tap((response) => {
        setState(
          patch({
            eta: response,
          })
        );
      })
    );
  }
  @Action(ClearFutureDate)
  ClearFutureDate({ setState }: StateContext<CartStateModel>) {
    setState(
      patch({
        futureDate: null,
        futureEndDate:null
      })
    );
  }

  @Action(UpdateCartDetails)
  UpdateCartDetails({ setState }: StateContext<CartStateModel>, { payload,cartId }: UpdateCartDetails) {
    return this.cartservice.updateCartDetails(payload,cartId).pipe(
      tap((response) => {
         const dealInCart = this.store.selectSnapshot(DealsState.getSelectedDeal);
         const cart = this.store.selectSnapshot(CartState.getCart);
          if(dealInCart && cart.cart_id){
            this.store.dispatch(new ValidateDeal(cart.cart_id,cart.subTotal));
          }
        setState(
          patch({
            cartResponse: response,
          })
        );
      })
    );
  }

  @Action(FetchFutureTimes)
  FetchFutureTimes(
    { setState }: StateContext<CartStateModel>,
    { payload }: FetchFutureTimes
  ) {
    const state = this.store.selectSnapshot((app) => app);
    const { delivery,venuemanagement,location } = state;
    let selectedDeliveryMethod = delivery?.selectedDelivery;
    if(!selectedDeliveryMethod)
      selectedDeliveryMethod = payload.selectedDelivery;
    const selectedVenue = payload?.selectedVenue ? payload.selectedVenue : venuemanagement?.selectedRoom;
    const selectedLocation = payload?.location ? payload?.location : location.selectedLocation;
    const isClassRoomOrder = payload?.isClassRoomOrder ? payload.isClassRoomOrder : false;
    return this.cartservice.getFutureTimes(selectedLocation?._id,selectedDeliveryMethod,selectedVenue,isClassRoomOrder).pipe(
      tap((response) => {
        setState(
          patch({
            futureTimes: response,
          })
        );
      })
    );
  }

  @Action(ClearFutureTimes)
  ClearFutureTimes({ setState }: StateContext<CartStateModel>) {
    setState(
      patch({
        futureTimes: undefined
      })
    );
  }
  
  @Action(UpdateComboCount)
  updateComboCount({ setState }: StateContext<CartStateModel>, { payload,comboId }: UpdateComboCount) {
    const state = this.store.selectSnapshot((app) => app);
    const { cart } = state;
    const cartId = cart?.cart.cart_id;
    return this.cartservice.updateCartComboCount(payload,cart,comboId).pipe(
      switchMap((response) =>
        forkJoin([
          this.store.dispatch(new FetchCart()),
          this.store.dispatch(
            new SetCartItems({
              cartId,
              organizationId: Config.organizationId,
            })
          ),
        ])
      )
    );
  }

  @Action(DeleteCombo)
  deleteCombo({ setState }: StateContext<CartStateModel>, { comboId }: UpdateComboCount) {
    const state = this.store.selectSnapshot((app) => app);
    const { cart } = state;
    const cartId = cart?.cart.cart_id;
    return this.cartservice.deleteCartCombo(cart,comboId).pipe(
      switchMap((response) =>
        forkJoin([
          this.store.dispatch(new FetchCart()),
          this.store.dispatch(
            new SetCartItems({
              cartId,
              organizationId: Config.organizationId,
            })
          ),
        ])
      )
    );
  }


  @Action(AddOrderLevelModifierToCart)
  addOrderLevelModifierToCart(
    { getState }: StateContext<CartStateModel>,
    { payload }: AddOrderLevelModifierToCart
  ) {
    const state = this.store.selectSnapshot((app) => app);
    const { cart } = state;
    const cartId = cart?.cart.cart_id;
    return this.cartservice.addOrderLevelModifier(cartId,payload).pipe(
      tap((response) => {
        this.store.dispatch(new FetchCart());
      })
    );
    
  }

  @Action(RemoveOrderLevelModifierFromCart)
  removeOrderLevelModifierFromCart(
    { getState }: StateContext<CartStateModel>,
    { payload }: RemoveOrderLevelModifierFromCart
  ) {
    const state = this.store.selectSnapshot((app) => app);
    const { cart } = state;
    const cartId = cart?.cart.cart_id;
    let firstAttempt = true;
    return this.cartservice.removeOrderLevelModifier(cartId,payload).pipe(
      tap((response) => {
        if(response && firstAttempt){
          firstAttempt= false;
          this.store.dispatch(new FetchCart());
          // this.fetchOrderLevelModifiers();
        }
      })
    );
    
  }

  fetchOrderLevelModifiers = ()=>{
    const selectedLocation = this.store.selectSnapshot(
      LocationDefaultState.getLocationDefaults
    );
    const selectedDelivery = this.store.selectSnapshot(
      DeliveryState.getSelectedDelivery
    );
    let data = {menuProfileId: selectedLocation.activeProfile._id, deliveryType: selectedDelivery._id};
    this.store.dispatch(new FetchOrderLevelModifiers(data));
  }

  @Action(PerformReordering)
  performReordering(
    { getState }: StateContext<CartStateModel>,
    { payload }: PerformReordering
  ) {
    const appStore = this.store.selectSnapshot(app=>app);
    const selectedDelivery = appStore?.delivery?.selectedDelivery;
    const selectedLocationId = appStore?.location?.selectedLocation?._id;
    const customerId = appStore?.auth?.loggedInUser?.customerId;
    const currentCart = appStore?.cart;
    let futureOrderDetails ={ isFuture: false, pickupTime: '' }
    if(currentCart?.futureDate){
      futureOrderDetails['isFuture'] = true;
      futureOrderDetails['pickupTime'] = new Date(currentCart.futureDate).toISOString();
    }
    const newCart: CartCreation = {
      organizationId: Config.organizationId,
      locationId: selectedLocationId,
      deliveryMethod: selectedDelivery._id,
      guest: customerId ? false: true,
      menuItems: this.mapMenuItemsForReordering(payload.cartDetails.menuItems),
      customerId: customerId,
      combos: this.mapCombosForReordering(payload.cartDetails.combos),
      futureOrderDetails: futureOrderDetails,
      totalPrice: 100,
    };
    // CLEAR CART BEFORE REORDERING
    this.store.dispatch(new ClearCart());
    return this.store.dispatch(new SetSelectedCart(newCart)).pipe(
      tap((response) => {
        if (response) {
          return response
        } else throw response;
      }),
      catchError((error) => {
        this.sweetAlert.toast('error', 'Some went wrong. Please try reordering again.');
        return throwError(error);
      })
    );

    // return this.cartservice
    //   .createNewCart(payload, getcart ? getcart.cart_id : undefined)
    //   .pipe(
    //     tap((response) => {
    //       if (response) {
    //         if (response['cart_id'])
    //           this.store.dispatch(new SetCartId(response['cart_id']));
    //         setState(
    //           patch({
    //             cart: response,
    //           })
    //         );
    //         console.warn("response with cart",response);
    //         if(response['cart_id'])
    //         this.cartservice.getCartItems(
    //           response['cart_id'],
    //           payload['organizationId']
    //         );

    //         if(response['errorCode'] == 1500){
    //           this.shared.forceClearCart(response);
    //         }
    //       } else throw response;
    //     }),
    //     catchError((error) => {
    //       return throwError(error);
    //     })
    //   );
  }

  
  mapMenuItemsForReordering(menuItems,isItemInsideCombo = false) {
    return menuItems.map( eachMenuItem => ({
      count: eachMenuItem.count,
      menuItem: eachMenuItem?.menuItem?._id,
      portion: eachMenuItem?.portion?._id,
      cartModifiers: eachMenuItem.cartModifiers.map( eachCartModifier => ({
        cartModifier: eachCartModifier.cartModifier._id,
        cartModifierItems: eachCartModifier.cartModifierItems.map( eachCartModifierItem => ({
          type: eachCartModifierItem.type,
          count: eachCartModifierItem.count,
          cartModifierItem: eachCartModifierItem.cartModifierItem._id,
          cartModifierSubItems: eachCartModifierItem.cartModifierSubItems.map( eachCartModifierSubItem => ({
            count: eachCartModifierSubItem.count,
            cartModifierSubItem: eachCartModifierSubItem.cartModifierSubItem._id
          }) )
        })),
      })),
      cartFreeTextModifiers : eachMenuItem.cartFreeTextModifiers?.map( modifier => ({
        cartModifier: modifier.cartModifier._id,
        value: modifier.value,
      })),
      groupMenuItemId: isItemInsideCombo ? eachMenuItem.groupMenuItemId : undefined
    }))
  }

  mapCombosForReordering(combos) {
    return combos.map( eachCombo => ({
      count: eachCombo.count,
      comboId: eachCombo.comboId._id,
      valid: true,
      comboGroups: this.formatComboGroupsForReordering(eachCombo)
    }))
  }

  formatComboGroupsForReordering(eachCombo){
    return eachCombo.comboGroups.map(eachGroup=>({
      externalIntegId: eachGroup.externalIntegId,
      groupId:eachGroup.groupId._id,
      groupItems: this.mapMenuItemsForReordering(eachGroup.groupItems,true)
    }))
  }

}



import { ViewAllOrders } from '../models';

export class FetchViewAllOrdersList {
    static readonly type = '[VIEWALLORDERS] FetchList';

    constructor(public payload?: any) {}
}

export class FetchInProgressOrderList {
    static readonly type = '[VIEWALLORDERS] FetchInprogressList';

    constructor(public payload?: any) {}
}
export class UpdateCurbsideNotification {
    static readonly type = '[VIEWALLORDERS] UpdateCurbsideNotification';

    constructor(public payload: { orderId : string, message : string }) {}
}

export class FetchInProgressCurbside {
    static readonly type = '[VIEWALLORDERS] FetchInProgressCurbside';

    constructor(public payload?: any) {}
}



import { Component, OnInit } from '@angular/core';
import {
  WhitelabelState,
  LocationDefaultState,
  LocationDefaults,
  BaseComponent,
  AuthEmailState,
} from '@moduurnv2/libs-orderingapp/src/core';
import { AuthState, Auth } from '@moduurnv2/libs-orderingapp';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Capacitor } from '@capacitor/core';
import { AuthService } from '../../core/auth.service';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { zendeskWebwidgetService } from '../zendesk-webwidget/zendesk-webwidget.service';
import { UserSpecificationDataService } from '../zendesk-webwidget/user-specification-data/user-specification-data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'moduurnv2-footer-desktop',
  templateUrl: './footer-desktop.component.html',
  styleUrls: ['./footer-desktop.component.scss'],
})
export class FooterDesktopComponent extends BaseComponent implements OnInit {
  userEmail: string;
  userName: any;
  routePath: string;
  constructor(
    private _NgxZendeskWebwidgetService: zendeskWebwidgetService,
    private store: Store,
    private authService: AuthService,
    private userSpecificationDataService: UserSpecificationDataService,
    private router:Router
  ) {
    super();
  }
  aboutUsLink: string = '';
  sendFeedpackLink: string = '';
  privacyPolicyLink: string = '';
  termsServiceLink: string = '';
  appStoreLink: string = '';
  playStoreLink: string = '';
  twitterLink: string = '';
  facebookLink: string = '';
  instagramLink: string = '';
  feedbackEmail: string = '';
  deliveryPartners = [];
  loggedInUserDetails: any;
  authSubscription: Subscription;
  chatCount: number = 0;
  appVersion: string = Config.appVersion;
  chatSentStatus: boolean;


  @Select(LocationDefaultState.getLocationDefaults)
  locationDefaults$: Observable<LocationDefaults>;

  @Select(AuthState.getCommonAuthDetails)
  loggedInUserDetails$: Observable<Auth>;
  ngOnInit(): void {
    
    this.authSubscription = this.loggedInUserDetails$.subscribe((res) => {
      this.loggedInUserDetails = res;
    });

    // this.loggedInUserDetails = this.store.selectSnapshot(
    //   AuthEmailState.getUserDetails
    // );

   
    let whitelabel = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
    let selectedLocation = this.store.selectSnapshot(
      LocationDefaultState.getLocationDefaults
    );
    this.appStoreLink = whitelabel.appStoreURL;
    this.facebookLink = whitelabel.facebook;
    this.instagramLink = whitelabel.instagram;
    this.playStoreLink = whitelabel.playStoreURL;
    this.twitterLink = whitelabel.twitter;
    this.feedbackEmail = whitelabel.feedbackEmailAddress;
    this.locationDefaults$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedLocation) => {
        if (selectedLocation) {
          this.deliveryPartners = selectedLocation.locationData
            ? selectedLocation.locationData.deliveryPartnerPhoneNumbers
            : [];
        }
      });
      this.chatSentStatus = this._NgxZendeskWebwidgetService.getSentStatus();
  }

  get checkWeb() {
    return Capacitor.getPlatform() === 'web';
  }
  
  async navigateToModuurn() {
    let orgName;
    let whitelabel = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
    if (whitelabel) {
      orgName = whitelabel.restaurantName.replace(/ /g, '_');
    }
    orgName = encodeURIComponent(orgName);
    const res = await this.authService.getDeviceType();
    let url = '';
    if (res.operatingSystem == 'android')
      url = `https://moduurn.com/?utm_source=AndroidApp&utm_medium=${orgName}&utm_campaign=Merchant-Referrals`;
    else if (res.operatingSystem == 'ios')
      url = `https://moduurn.com/?utm_source=iOSApp&utm_medium=${orgName}&utm_campaign=Merchant-Referrals`;
    else
      url = `https://moduurn.com/?utm_source=WebApp&utm_medium=${orgName}&utm_campaign=Merchant-Referrals`;
    window.open(url);
  }

  
  /**
   * Start the Zendek Chat
   */
   startWebChat() {
    const userDetails = this.getUserInfo(); //get the user information
    this._NgxZendeskWebwidgetService.activate(); // popout the widget
    if (this.loggedInUserDetails) {
      this._NgxZendeskWebwidgetService.zE('webWidget', 'identify', {
        name: this.userName,
        email: this.userEmail,
      });

      this._NgxZendeskWebwidgetService.zE('webWidget', 'prefill', {
        name: {
          value: this.userName,
          readOnly: false, // optional
        },
        email: {
          value: this.userEmail,
          readOnly: false, // optional
        },
      });
    }
    this.sentUserInfoChat(userDetails);
    this.closeWidget();
    this.alertChatNotification();
  }

    /**
   * function for setting the user Information for Agent
   * @returns
   */
     getUserInfo() {
      let user = this.store.selectSnapshot(AuthEmailState.getUserDetails);
      const userPhone = this.loggedInUserDetails ? user.primaryPhone : '';
      this.userEmail = this.loggedInUserDetails ? user.email : '';
      this.userName = this.loggedInUserDetails
        ? this.loggedInUserDetails.userName
        : '';
      const customerId = this.loggedInUserDetails
        ? this.loggedInUserDetails.customerId
        : '';
      const userOs = this.userSpecificationDataService.getOsName();
      const userBrowser = this.userSpecificationDataService.getBrowserName();
      const userScreenWidth = this.userSpecificationDataService.getScreenWidth();
      const userScreenHeight = this.userSpecificationDataService.getScreenHeight();
      const deviceType = this.userSpecificationDataService.getdeviceType();
      const customer = customerId ? 'CustomerId:' + customerId + '\n' : '';
      const customerName = this.userName ? 'Name:' + this.userName + '\n' : '';
  
      const userDetails =
        customer +
        customerName +
        'OS:' +
        userOs +
        '\nBrowser:' +
        userBrowser +
        '\nScreen:' +
        userScreenWidth +
        'W*' +
        userScreenHeight +
        'H\nDeviceType:' +
        deviceType +
        '\nApp Version:' +
        this.appVersion;
      return userDetails;
    }

    
  /**
   * Function for popout the widget when unread message from the agent
   */
  alertChatNotification() {
    this._NgxZendeskWebwidgetService.zE(
      'webWidget:on',
      'chat:unreadMessages',
      (number) => {
        this.routePath=this.router.url.split('/')[3]?this.router.url.split('/')[3]:'';
        if(this.routePath!='order')
        this._NgxZendeskWebwidgetService.activate();

      }
    );
  }

  /**
   * function for close the widget when customer click end chat
   */
  closeWidget() {
    this._NgxZendeskWebwidgetService.zE('webWidget:on', 'chat:end', () => {
      this._NgxZendeskWebwidgetService.zE('webWidget', 'close');
    });
  }

  /**
   * function for sending the user details to agent
   * @param userDetails
   */
  sentUserInfoChat(userDetails) {
    console.log("status",this.chatSentStatus);
    console.log("this.chatCount",this.chatCount);
    this._NgxZendeskWebwidgetService.zE(
      'webWidget:on',
      'userEvent',
      (event) => {
        // console.log('Event---', event.action);
        if (event.action === 'Chat Shown') {
          this.chatCount++;
          if (this.chatCount ==  1 && this.chatSentStatus===false)
            this._NgxZendeskWebwidgetService.zE(
              'webWidget',
              'chat:send',
              userDetails
            );
 // we could replace the this._NgxZendeskWebwidgetService.setSentStatusFalse() function with
          // just false and see if there is an issue
          this.chatSentStatus = this._NgxZendeskWebwidgetService.setSentStatusTrue();
        }
      }
    );
  }
}

import {
  State,
  Selector,
  StateContext,
  Action,
  Select,
  Store,
} from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Delivery, Location } from '../models';
import {
  FetchDeliveryList,
  SetSelectedDelivery,
  ClearSelectedDelivery,
} from '../actions';
import { Injectable } from '@angular/core';
import { DeliveryMethodService, LocationService } from '../services';
import { tap, catchError, flatMap } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { LocationState } from './location.state';
import { ActivatedRoute } from '@angular/router';
import { throwError } from 'rxjs';
// import { LocationDefaultState } from './location-defaults.state';
import { WhitelabelState } from './whitelabel.state';


export class DeliveryStateModel {
  deliveryList: Delivery[];
  selectedDelivery: Delivery;
  location: string;
}

@State<DeliveryStateModel>({
  name: 'delivery',
  defaults: {
    selectedDelivery: null,
    deliveryList: undefined,
    location:''
  },
})
@Injectable()
export class DeliveryState {
  constructor(private delivery: DeliveryMethodService, private location:LocationService, private store: Store, private route: ActivatedRoute) {}
  @Select(LocationState.getSelectedLocation) selectedLocation$: Observable<
    Location
  >;

  @Selector()
  static getSelectedDelivery(state: DeliveryStateModel) {
    return state.selectedDelivery;
  }
  @Selector()
  static getDeliveryList(state: DeliveryStateModel) {
    return state.deliveryList;
  }
  @Selector()
  static getDeliveryLocation(state: DeliveryStateModel) {
    return state.location;
  }

  @Action(FetchDeliveryList)
  fetchDeliveries({ setState }: StateContext<DeliveryStateModel>) {
    const location = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    // const locationDetails = this.store.selectSnapshot(
    //   LocationDefaultState.getLocationDefaults
    // ); 

    const locationDetails = this.store.selectSnapshot(
      (app) => app && app.locationDefaults && app.locationDefaults.locationDefaultDetails
    );
    
    const delivery = this.store.selectSnapshot(
      DeliveryState.getSelectedDelivery
    );
    let isAvailable:boolean = false;
    let deliveryTypeToSelect;
    const qrData = this.store.selectSnapshot(
      WhitelabelState.getQRScanDetails
    );

    // console.log('qrData->',qrData)

    return this.delivery.fetchDeliveries(location._id).pipe(
      tap((response) => {
        if (response && response.deliveryTypes) {
          setState(
            patch({
              deliveryList: response.deliveryTypes,
              location: response.name
            })
          );
          if(delivery){
            // if(locationDetails?.locationData?.orderSettings?.hideTableServiceSelection == true){
            //   isAvailable = false
            // }else{
             
              let deliveryTypeTextCode = qrData?.deliveryType ? qrData?.deliveryType : delivery.textCode;
              
            isAvailable = response.deliveryTypes.find(
              (deliveryType) => deliveryType.textCode === deliveryTypeTextCode
              );
              deliveryTypeToSelect = isAvailable
              setState(
                patch({
                  selectedDelivery: deliveryTypeToSelect,
                })
              ); 
              
            // }
          }
          if (response.deliveryTypes.length == 0 || !isAvailable) {
            // console.log('========check=========');
            // console.log(qrData);
            // console.log(delivery)
            // console.log(location)
            // console.log('++++++check end++++++++');
            if(qrData?.deliveryType && location?._id == qrData?.locationId ){
              deliveryTypeToSelect = response.deliveryTypes.find((type) => type.textCode === qrData.deliveryType)
            } else {
                if(locationDetails?.locationData?.orderSettings?.hideTableServiceSelection == true && 
                  response.deliveryTypes[0].textCode == "DELIVERYTYPE03"){
                    deliveryTypeToSelect = response.deliveryTypes[1];
                  }else{
                    deliveryTypeToSelect = response.deliveryTypes[0];
                  }
            }
            setState(
              patch({
                selectedDelivery: deliveryTypeToSelect,
              })
            );
          }
        } else throw response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  @Action(SetSelectedDelivery)
  setLocation(
    { getState, setState }: StateContext<DeliveryStateModel>,
    { payload }: SetSelectedDelivery
  ) {
    const state = getState();
    if(state.deliveryList){
      setState(
        patch({
          selectedDelivery: state.deliveryList.find(
            (deliveryType) => deliveryType.textCode === payload
          ),
        })
      );
    }

  }
  @Action(ClearSelectedDelivery)
  ClearSelectedDelivery({ setState }: StateContext<DeliveryStateModel>) {
    setState(
      patch({
        selectedDelivery: null,
      })
    );
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import {
  BaseComponent,
  AuthState,
  Auth,
  MenuListState,
  ChangeCategory,
  DeliveryState,
  WhitelabelState,
  fromOrderSuccess,
  CartState,
  Cart,
  SharedService
} from './../../../core';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { AlertPopupComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/alert-popup/alert-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import {SetLoginFrom} from './../../../core/actions/auth.actions';
import { LoginDesktopComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/login/components/login-desktop/login-desktop.component'
export class MenuBaseComponent extends BaseComponent {
  profileIcon: string = 'assets/images/profileIcon.svg';
  @Input('selectedPage') selectedPage: string;
  loggedInUserDetails;
  hideProfile: boolean;
  hideOrders: boolean;
  enabledGiftandLoyality:boolean=false;
  enableCoupon: boolean;
  cartCount:number;
  pageList = [
    {
      id: 1,
      name: 'home',
      icon: 'assets/images/homeIcon.svg',
      activeIcon: 'assets/images/homeIcon-active.svg',
    },
    {
      id: 2,
      name: 'menu',
      icon: 'assets/images/menuIcon.svg',
      activeIcon: 'assets/images/menuIcon-active.svg',
    },
    {
      id: 3,
      name: 'orders',
      icon: 'assets/images/orderIcon.svg',
      activeIcon: 'assets/images/Bag_Items_Icon_Active.svg',
    },
    {
      id: 4,
      name: 'profile',
      icon: 'assets/images/profileIcon.svg',
      activeIcon: 'assets/images/profileIcon.svg',
    },
    {
      id: 5,
      name: 'profile',
      icon: 'assets/images/profileIcon.svg',
      activeIcon: 'assets/images/profileIcon.svg',
    },
    {
      id: 6,
      name: 'deals',
      icon: 'assets/images/Deals_Icon_Inactive.svg',
      activeIcon: 'assets/images/Deals_Icon_Active.svg',
    },
  ];

  @Select(AuthState.getCommonAuthDetails)
  loggedInUserDetails$: Observable<Auth>;
  @Select(CartState.getCart) cart$: Observable<Cart>;
  isMobile: boolean;
  constructor(
    private router: Router,
    private readonly store: Store,
    private route: ActivatedRoute,
    private modal:ModalService,
    private sharedService:SharedService,
    private translate:TranslateService
  ) {
    super();
    let path = window.location.pathname.split('/');
    this.selectedPage = path[path.length - 1];
    this.cart$.subscribe((response) => {
      if(response)
      this.cartCount = response.count?response.count:undefined;
      else
      this.cartCount = undefined;
    });
    //this.loggedInUserDetails = localStorage.getItem("loggedInUser")? JSON.parse(localStorage.getItem("loggedInUser")):undefined;
  }

  @Input() userDetails;
  ngOnInit(): void {
    //this.setProfileIcon();
    let organizationOtherConfigs = this.store.selectSnapshot(
      WhitelabelState.getOther
    );
    let whiteLabelConfigs = this.store.selectSnapshot(
      WhitelabelState.getWhitelabel
    );
    this.hideProfile = organizationOtherConfigs.allowOnlyGuestUsers;
    this.enableCoupon = organizationOtherConfigs.enableCoupons;
    this.hideOrders = whiteLabelConfigs.commonSettings.hideOrderRelatedScreens;
    let other = this.store.selectSnapshot(WhitelabelState.getOther);
    if(other && other.paymentGateway && other.paymentGateway.textCode == 'PAYMENTGATEWAY02' && other.loyaltyAndGiftCards && other.loyaltyAndGiftCards.isAllow)
    {
      this.enabledGiftandLoyality=true;
    }
  }

  setMenuIcon(page) {
    let icon = this.selectedPage == page.name ? page.activeIcon : page.icon;
    if (page.name == 'profile') {
      icon = this.setProfileIcon(icon);
    }
    return icon;
  }
  setProfileIcon(icon) {
    if (
      this.loggedInUserDetails &&
      this.loggedInUserDetails.provider == 'GOOGLE'
    ) {
      return this.loggedInUserDetails.photoUrl;
    } else {
      return icon;
    }
  }

  routeTo(path) {
    if (this.selectedPage == path) return;

    const selectedLocation = this.store.selectSnapshot(
      (app) => app.location.selectedLocation
    );
    const locationId = selectedLocation?._id ? selectedLocation._id : undefined;
    const authDetails = this.store.selectSnapshot(
      AuthState.getCommonAuthDetails
    );
    const currentPage = this.router.url.split('/').pop();
    switch (path) {
      case 'menu':
        let menuLists = this.store.selectSnapshot(MenuListState.getMenuList);
        let selectedCategory = this.store.selectSnapshot(
          MenuListState.getMenuListCategory
        );
        if (menuLists && selectedCategory) {
          this.store.dispatch(new ChangeCategory(menuLists.categories[0]));
        }
        this.store.dispatch(new ChangeCategory(null));
        this.routeToMenu(currentPage,locationId)
        break;
      case 'home':{
        if(locationId)
        this.router.navigate([`locations/${locationId}/home`]);
        else
        this.router.navigate([`locations`]);
        break;
       }
      case 'view-orders':
        {
          this.store.dispatch(new fromOrderSuccess(false));
          this.router.navigate([`locations/${locationId}/view-orders`]);
        }
        break;
      case 'payments':
        this.router.navigate([`/payment`]);
        break;
      case 'profile':
        authDetails && authDetails.isLoggedIn
          ? this.router.navigate(['user-profile'])
          : this.router.navigate([`login`]);
        break;
        case 'deals':
          this.router.navigate([`locations/${locationId}/deals-listing`]);
          break;
      case 'user-rewards':
        this.router.navigate([`/user-rewards`]);
        break;
    }
    //this.selectedPage = path;
  }

  routeToMenu(currentPage,locationId) {
     const selectedDelivery = this.store.selectSnapshot(
      (app) => app && app.delivery && app.delivery.selectedDelivery
    );
    this.sharedService.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobile = isMobileView)
    );
    if(selectedDelivery?.textCode == 'DELIVERYTYPE07' && !this.isMobile){
      this.curbSidePickUpValidation(locationId,selectedDelivery.text)
    } else {
      this.router.navigate([`locations/${locationId}/menu`]);
      // this.router.navigate([`locations/${locationId}/menu`])
      // .then(() => {
      //   window.location.reload();
      // });
    }
  }

  curbSidePickUpValidation(locationId,deliveryTypeText){
    const authDetails = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    let header;
    let isModalOpen = true;
    if(!authDetails?.isLoggedIn){
      header =  this.translate.currentLang == 'en' ? (this.translate.instant('curbside-desktop-warning-one') + deliveryTypeText + ' order. '+this.translate.instant('curbside-desktop-warning-two')) : (this.translate.instant('curbside-desktop-warning-one') + this.translate.instant('curbside-desktop-warning-two'));
    } else {
      header = this.translate.currentLang == 'en' ? `Please note that for ${deliveryTypeText} you must log in to your mobile app under the same user name to let the kitchen know that you have arrived.` : this.translate.instant('curbside-desktop-warning-loggedin')
    }
    this.modal.showSmallModal(AlertPopupComponent,{data:{
        header:header,
        actionable:authDetails?.isLoggedIn ? false : true ,
        closeBtnText:authDetails?.isLoggedIn ? 'Ok': this.translate.instant('cancel'),
        proceedText:'Login'
      },
      whenClosed: (data) => {
        if(isModalOpen){
          if(!authDetails?.isLoggedIn && data){
            this.store.dispatch(new SetLoginFrom(undefined));
            this.modal.showMediumModalDesktop(LoginDesktopComponent);
          } else if(authDetails?.isLoggedIn){
            this.router.navigate([`locations/${locationId}/menu`]);
          }
        } 
        isModalOpen = false;
      }})
  }
}

import { OnInit, Input, Output } from '@angular/core';

import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { EventEmitter } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import {
  BaseComponent,
  FetchLocationInfo,
  LocationState,
  LocationInfo,
} from '@moduurnv2/libs-orderingapp/src/core';
export class LocationInfoOperatingHoursBaseComponent extends BaseComponent
  implements OnInit {
  @Input() location;
  @Input() modalPopup;
  @Output() closeEvent = new EventEmitter();
  @Select(LocationState.getLocationInfo) locationInfo$: Observable<
    LocationInfo
  >;
  locationInfo: LocationInfo = undefined;
  currLat: number;
  currLng: number;
  constructor(public store: Store) {
    super();

    this.locationInfo$
      .pipe(takeUntil(this.destroy$))
      .subscribe((locationInfo) => {
        if (locationInfo) {
          this.locationInfo = locationInfo;
        }
      });
  }
  ngOnInit(): void {
    this.store.dispatch(new FetchLocationInfo(this.location._id));
  }
}

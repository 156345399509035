import {
  BaseComponent,
  environment,
  FetchMenuList,
  MenuListState,
  MenuList,
  CategoryList,
  ClearOrderResponseMessage,
  LocationState,
  Location,
  FetchLocationStatus,
  DeliveryState,
  Delivery,
  WhitelabelState,
  CartState,
  Whitelabel,
  FetchLocationDefaults,
} from '@moduurnv2/libs-orderingapp/src/core';
import { OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subscription } from 'rxjs';


export class MenuListBaseComponent extends BaseComponent implements OnInit {
  @Select(MenuListState.getMenuList) menuList$: Observable<MenuList>;
  @Select(MenuListState.getMenuListCategories) categories$: Observable<
    CategoryList[]
  >;
  @Select(MenuListState.getMenuListCategory) category$: Observable<
    CategoryList
  >;
  @Select(LocationState.getSelectedLocation) location$: Observable<Location>;
  @Select(DeliveryState.getSelectedDelivery) delivery$: Observable<Delivery>;
  @Select(WhitelabelState.getWhitelabel) whitelabel$: Observable<Whitelabel>;
  // items = this.menuList$[0].categories;
  selectedCategory: any;
  searchText: string = '';
  imgBasePath: string = environment.image_basepath;
  location: Location;
  delivery: Delivery;
  isFuture: boolean = false;
  bannerImg: string;
  whitelabel: Whitelabel;
  categories: Subscription;
  emptyMessage : string= "";
  constructor(public store: Store, private route: ActivatedRoute) {
    super();
    this.location$.pipe(takeUntil(this.destroy$)).subscribe((location) => {
      if (location) {
        this.location = location;
      }
    });
    this.delivery$.pipe(takeUntil(this.destroy$)).subscribe((delivery) => {
      if (delivery) {
        this.delivery = delivery;
      }
    });

    this.whitelabel$.pipe(takeUntil(this.destroy$)).subscribe((whitelabel) => {
      if (whitelabel)
        if (whitelabel) {
          // this.bannerImg = environment.imagebaseurl + whitelabel.appSliderImages[0];
          this.whitelabel = whitelabel;
          let image = whitelabel.menuPage && whitelabel.menuPage.desktop? whitelabel.menuPage.desktop: whitelabel.appSliderImages[0];
          this.bannerImg = environment.imagebaseurl + image;
        }
    });
  }

  ngOnInit() {
    const { sectionId } = this.route.snapshot.params;
    this.store.dispatch(new FetchMenuList(sectionId));
    this.getShifts();
    this.store.dispatch(new FetchLocationDefaults());
    this.categories = this.categories$.subscribe((items) => {
      if(items && items.length){
        this.emptyMessage = "";
      }
      else{
        this.emptyMessage = "There are no items available for the selected delivery type. Please choose a different delivery type to order";
      }
    });
  }
  getItems(value) {
    // let data = this.items.filter((item) => item._id == value);
    // this.selectedCategory = data[0].items;
  }

  public getShifts() {
    const locationId = this.location?._id;
    const orderType = this.delivery?.textCode;
    const futureDate = this.store.selectSnapshot(CartState.getFutureDate);

    let currTime = new Date();
    let selectedDate = new Date();
    if (futureDate) selectedDate = new Date(futureDate);
    let url =
      environment.mobileStoreApiUrl +
      'stores/location/' +
      locationId +
      '/shifts?type=' +
      orderType +
      '&currentTime=' +
      currTime.toISOString() +
      '&timezoneOffset=' +
      currTime.getTimezoneOffset();
    if (futureDate) {
      url =
        environment.mobileStoreApiUrl +
        'stores/location/' +
        locationId +
        '/shifts?type=' +
        orderType +
        '&currentTime=' +
        currTime.toISOString() +
        '&future=true&selectedTime=' +
        selectedDate.toISOString() +
        '&timezoneOffset=' +
        selectedDate.getTimezoneOffset();
    }
    this.store.dispatch(new FetchLocationStatus(url));
  }
}

<ng-container *ngIf="isMobile; then mobileTemplate; else desktopTemplate">
</ng-container>

<ng-template #desktopTemplate>
  <div class="login-desktop-container">
    <div class="left">
      <img
        class="logo"
        [src]="organizationLogo"
        aria-label="organization logo"
        alt="organization-img"
      />
    </div>
    <div class="right login-grid-container">
      <div class="grid-item login-body">
        <ng-container *ngIf="loginProviders">
          <div
            class="login-method"
            data-qa="signup-button"
            *ngFor="let provider of loginProviders"
            (click)="signUp(provider.textCode)"
          >
            <div class="login-provider-img">
              <div
                [ngStyle]="setImageGradientStyle(provider.gradient)"
                class="login-provider-img-outer"
              >
                <img [src]="provider.iconUrl" alt="login-provider-img"/>
              </div>
            </div>
            <div
              class="login-text"
              attr.aria-label=" {{ provider.text | translate }}"
            >
              <div class="grid-item provider-heading">
                {{ provider.text | translate }}
              </div>
              <div class="grid-item provider-arrow">
                <img [src]="loginRightArrowIcon" alt="arrow icon"/>
              </div>
              <div
                class="grid-item provider-subheading"
                attr.aria-label=" {{ provider.textTagLine | translate }}"
              >
                {{ provider.textTagLine | translate }}
              </div>
            </div>
          </div>
        </ng-container>
        <div class="terms-conditions">
          <div class="error-text" *ngIf="isSignupClicked && !setTerms">
            {{ 'terms-conditions-error' | translate }}
          </div>
          <div class="login-text">
            <div
              class="provider-subheading user-login options"
              attr.aria-label="{{ 'disclaimer-text' | translate }} {{
                'terms-conditions' | translate
              }}"
            >
            <label class="options-label">
              {{ 'disclaimer-text' | translate }}
              <span class="primary-color">
                <a target="blank" href="https://moduurn.com/privacy-policy">
                  {{ 'terms-conditions' | translate }}
                </a>
              </span>
              <input
                type="checkbox"
                [ngModel]="setTerms"
                [ngClass]="{ selected: setTerms }"
                (click)="changeTerms($event)"
              />
              <span class="checkmark"></span>
            </label>
            </div>
          </div>
        </div>
        <div
          class="user-account"
          data-qa="email-login"
          (click)="emailLogin()"
          attr.aria-label="{{ 'have-account' | translate }}  {{
            'login' | translate
          }}"
          *ngIf="allowEmailAuth === true"
        >
          <div class="login-text">
            <div class="provider-subheading user-login">
              {{ 'have-account' | translate }}
              <span class="primary-color">{{ 'login' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
      <moduurnv2-email-auth
        class="login-popup"
        *ngIf="pageToShow"
        [pageToShow]="pageToShow"
        (showEmailAuthModal)="showEmailAuthModal($event)"
      ></moduurnv2-email-auth>
    </div>
  </div>
</ng-template>

<ng-template #mobileTemplate>
  <div class="login-grid-container">
    <div class="grid-item login-head">
      <div
        class="login-image"
        [ngStyle]="{ 'background-image': 'url(' + loginHeaderImg + ')' }"
      >
        <div
          class="head-icon-outer"
          data-qa="back-button"
          (click)="goBack()"
          aria-label="back button"
        >
          <img [src]="loginLeftArrowIcon" class="left-icon" alt="left icon"/>
        </div>
        <div class="head-text-outer">
        <ng-container *ngIf="bannerText;else banner">
          {{ 'get-started' | translate }}!
          <div class="head-text-main" [theme]="options">
            {{bannerText}}
        </div>
        </ng-container>
        <ng-template #banner>
          <div class="head-text-main" [theme]="options">
            {{ 'get-started' | translate }}!
          </div>
          <div class="head-text-sub" *ngIf="allowEmailAuth === true">
            {{ 'create-account' | translate }}
          </div>
        </ng-template>
        </div>
      </div>
    </div>
    <div class="grid-item login-body">
      <ng-container *ngIf="loginProviders">
        <div
          class="login-method"
          data-qa="signup-button"
          *ngFor="let provider of loginProviders"
          (click)="signUp(provider.textCode)"
        >
          <div class="login-provider-img">
            <div
              [ngStyle]="setImageGradientStyle(provider.gradient)"
              class="login-provider-img-outer"
            >
              <img [src]="provider.iconUrl" alt="login provider"/>
            </div>
          </div>
          <div
            class="login-text"
            attr.aria-label=" {{ provider.text | translate }}"
          >
            <div class="grid-item provider-heading">
              {{ provider.text | translate }}
            </div>
            <div class="grid-item provider-arrow">
              <img [src]="loginRightArrowIcon" alt="arrow icon"/>
            </div>
            <div
              class="grid-item provider-subheading"
              attr.aria-label=" {{ provider.textTagLine | translate }}"
            >
              {{ provider.textTagLine | translate }}
            </div>
          </div>
        </div>
      </ng-container>
    <!-- </div> -->
    <div class="terms-conditions">
      <div class="login-text">
        <div class="error-text" *ngIf="isSignupClicked && !setTerms">
          {{ 'terms-conditions-error' | translate }}
        </div>
        <div
          class="provider-subheading user-login options"
          attr.aria-label="{{ 'disclaimer-text' | translate }} {{
            'terms-conditions' | translate
          }}"
        >
          <label class="options-label">
            {{ 'disclaimer-text' | translate }}
            <span class="primary-color">
              <a target="blank" href="https://moduurn.com/privacy-policy">
                {{ 'terms-conditions' | translate }}
              </a>
            </span>
            <input
              type="checkbox"
              [ngModel]="setTerms"
              [ngClass]="{ selected: setTerms }"
              (click)="changeTerms($event)"
            />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div
        class="user-account"
        data-qa="email-login"
        (click)="emailLogin()"
        attr.aria-label="{{ 'have-account' | translate }}  {{
          'login' | translate
        }}"
        *ngIf="allowEmailAuth === true"
      >
        <div class="login-text">
          <div class="provider-subheading user-login">
            {{ 'have-account' | translate }}
            <span class="primary-color">{{ 'login' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
  <moduurnv2-email-auth
    class="login-popup"
    *ngIf="pageToShow"
    [pageToShow]="pageToShow"
    (showEmailAuthModal)="showEmailAuthModal($event)"
  ></moduurnv2-email-auth>
</ng-template>

import { Component, OnInit,Input } from '@angular/core';
import { VenueTableSelectionBaseComponent } from '@moduurnv2/libs-orderingapp/src/features/ui/base/venue-table-selection-base.component';
import { Store } from '@ngxs/store';
import { ModalService } from './../../../shared/modal/modal.service'
@Component({
  selector: 'moduurnv2-lounge-update-selection',
  templateUrl: './lounge-update-selection.component.html',
  styleUrls: ['./lounge-update-selection.component.scss','./../../common-styles/venue-update-selection.scss']
})
export class LoungeUpdateSelectionComponent extends VenueTableSelectionBaseComponent {
  
  @Input() _modalClose: any;
  constructor(store:Store,private modal:ModalService) { 
    super(store)
  }

  set modalClose(modalRef: any) {
    this._modalClose = () => {
      this.modal.closeModal(modalRef);
    };
  }

  closeSelectionModal() {
    if (this._modalClose) this._modalClose();
  }
}

import { Output, Input, EventEmitter, OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
// import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { AddressMapDesktopComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/address-book-desktop/components/address-map-desktop/address-map.component-desktop';
import {
  FetchAddressList,
  ChangeMapPopupStatus,
  FetchDeliveryAddressDetails,
  ClearEnteredAddress
} from '@moduurnv2/libs-orderingapp/src/core/actions';
import {
  AddressState,
  AuthState,
  LocationState,
} from '@moduurnv2/libs-orderingapp/src/core/state';
import {
  Address,
  Auth,
  AddressData,
  AddressComponentCloseModel,
  AddressComponentEnums
} from '@moduurnv2/libs-orderingapp/src/core/models';
import { BaseComponent } from '@moduurnv2/libs-orderingapp/src/core/base/base-component';
import { environment } from '@moduurnv2/libs-orderingapp/src/core/environments/environment';
import { count, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '@moduurnv2/libs-orderingapp/src/core';
import { AddressDetailsComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/address-book/components/address-details/address-details.component';
import { AddressDetailsDesktopComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/address-book-desktop/components/address-details-desktop/address-details-desktop.component';
export class AddressListBaseComponent extends BaseComponent implements OnInit {
  @Output() closeEvent = new EventEmitter();
  @Output() closeNewScreen = new EventEmitter<AddressComponentCloseModel>();
  // viewMapModalStatus: boolean = false;
  selectedLoc: Address;
  isMobile: boolean;
  isDeliverable: boolean = false;
  nonDeliveryCount:number=0;
  customerSelectedAddress:any;
  // @Input() modalChangeAdrsStatus: boolean;
  @Input() isAddressBook: boolean = false;
  // @Select(AddressState.getMapPopUpStatus) getMapPopUpStatus$: Observable<
  //   boolean
  // >;
    @Select(AddressState.getDeliveryAddress) selectedAddress$: Observable<
    Address
  >;
  @Select(AddressState.getAddressList) addressList$: Observable<Address[]>;
  @Select(AddressState.getTempSelectedAddress) DeliveryAddress$: Observable<
    Address
  >;
  @Select(AddressState.getAddressListFromLocal)
  addressListLocal$: Observable<Address[]>;
  @Select(AuthState.getCommonAuthDetails)
  loggedInUserDetails$: Observable<Auth>;
  adrsList: any;
  adrsDetailsList: any;
  isLocationSelected: boolean = false;
  newLocation: any;
  localLocation: any;
  isMapOpen: boolean = false;
  imgBasePath: string = environment.image_basepath;
  _modalClose: any = null;
  icon = {
    add: this.imgBasePath + environment.images.add,
    close: this.imgBasePath + environment.images.close,
    back: this.imgBasePath + environment.images.back_arrow,
    add_red: this.imgBasePath + environment.images.add_red,
  };
  loggedInUserDetails: any;
  translation = {
    deliveryDetails: this.translate.instant('delvry-detls'),
    addressUsed: this.translate.instant('address-why-used'),
    addAddress: this.translate.instant('add-address'),
    savedAddress: this.translate.instant('saved-adrs'),
  };
  ngOnInit() { 
    //  this.subscribeMapPopupStatus();
 
    this.sharedService.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobile = isMobileView)
    );
    
    this.subscribeaddressList();
  }
  constructor(
    public store: Store,
    // public modal: ModalService,
    private translate: TranslateService,
    private sharedService: SharedService
  ) {
    super();
    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    if (customer && customer.customerId) {
      this.store.dispatch(new FetchAddressList());
    }
  }
  openMap() {
    localStorage.removeItem('tempSelectedAdrsLocation');
    // this.store.dispatch(new ChangeMapPopupStatus(true));
    this.openMapPage();
  }

  subscribeaddressList() {
    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    let isInitialLoad = true;
    if (customer && customer.customerId) {
      this.addressList$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      //  if(this.checkAddressWithDistanceIsUpdated(this.adrsList,data)){
        this.adrsList = data;
        this.adrsDetailsList = data;
        if(this.adrsList){
          if(isInitialLoad){
            if (!this.adrsList || !this.adrsList.length){
              this.openAddressDetails({isAddressBook:true,modalAdrsDetailsStatus:true})
            }
            isInitialLoad = false;
          }
          setTimeout(()=>{ this.calculateDistance();}, 1000)
        }
      //  }
      });
    } else {
      this.addressListLocal$
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          // if(!isInitialLoad)
        if(this.checkAddressWithDistanceIsUpdated(this.adrsList,data)){
          this.adrsList = data;
          this.adrsDetailsList = data;
          if(isInitialLoad){
            // if (this.adrsList && this.adrsList.length > 0) {
            //   setTimeout(()=>{ this.calculateDistance();}, 1000)
            // } else {
            //  this.openAddressMapModal();
            // }
            if (!this.adrsList || !this.adrsList.length){
              this.openAddressMapModal();
            }
            isInitialLoad = false;
          } 
          if(this.adrsList && this.adrsList.length > 0){
            setTimeout(()=>{ this.calculateDistance();}, 1000)
          }
        }
        });
    }
  }

  checkAddressWithDistanceIsUpdated(currentAdrsList, newAdrsList){
    let isAddressUpdatedWithDistance = false;
    if(!currentAdrsList && !newAdrsList){
      isAddressUpdatedWithDistance = true;
    }
    if(newAdrsList?.length && currentAdrsList?.length == newAdrsList?.length){
      isAddressUpdatedWithDistance = newAdrsList.some(function(address,index) {
        return address.distance && !currentAdrsList[index].distance;
      }); 
    } else {
      isAddressUpdatedWithDistance = true;
    }
    return isAddressUpdatedWithDistance;
  }

  openAddressMapModal(){
    let selectedAddress = this.store.selectSnapshot(AddressState.getDeliveryAddress);
    if(!selectedAddress){
      if(this.isMobile){
        localStorage.removeItem('tempSelectedAdrsLocation');
        this.openAddressDetails();
      }
      else{
      if(!this.isAddressBook)
      // this.modal.showMediumModalDesktop(AddressDetailsDesktopComponent);
      this.openAddressDetails();
      }
    }
  }

  // set modalClose(modalRef: any) {
  //   this._modalClose = () => {
  //     this.modal.closeModal(modalRef);
  //   };
  // }

  calculateDistance() {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if (selectedLocation?._id) {
      let adrsdataObj: AddressData = {
        locationId: selectedLocation._id,
        locationCoordinates: selectedLocation['loc'],
        addressCoordinates: [],
      };
      if (this.adrsList && this.adrsList.length > 0) {
        for (let i = 0; i < this.adrsList.length; i++) {
          adrsdataObj.addressCoordinates.push({
            id: this.adrsList[i]._id,
            loc: {
              lng: this.adrsList[i]['loc']?this.adrsList[i]['loc'][0]?this.adrsList[i]['loc'][0]:this.adrsList[i]['loc']['lng']:undefined,
              lat: this.adrsList[i]['loc']?this.adrsList[i]['loc'][1]?this.adrsList[i]['loc'][1]:this.adrsList[i]['loc']['lat']:undefined,
            },
            details:{
              city:this.adrsList[i].city,
              state:this.adrsList[i].state,
              streetAddress:this.adrsList[i].streetAddress,
              unitNumber:this.adrsList[i].unitNumber,
              postalcode:this.adrsList[i].postalcode,
              country:this.adrsList[i].country
            }
          });
        }
      }
      // let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
      // if (customer && customer.customerId) {
        this.store.dispatch(new FetchDeliveryAddressDetails(adrsdataObj))
      //   this.addressList$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      //     if(data){
      //       this.adrsDetailsList = data;
      //     }
      //   });
      // } else {
      //   this.store.dispatch(new FetchDeliveryAddressDetails(adrsdataObj))
      //     this.addressListLocal$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      //       if(data){
      //         this.adrsDetailsList = data;
      //       }
      //     });
      // }
    } else {
      this.adrsDetailsList = this.adrsList;
    }
  }
  openAddressDetails(data?){

    //  if(this.isMobile)
    //  this.openDetails({isAddressBook:true});
    //  else
     this.openDetails(data);
    // this.modal.showSmallModal(AddressDetailsComponent,{data:{isAddressBook:true,modalAdrsDetailsStatus:true}});
  }

  addNewAddress(){
    localStorage.removeItem('tempSelectedAdrsLocation');
    this.openDetails()
  }
  closePopup(event){
    if(event){
    event.stopPropagation();
    event.preventDefault();
    }
    // this.closeEvent.emit();
    // if (this._modalClose) this._modalClose();
    this.closePage();
  }

  closePage(){
    this.closeNewScreen.emit({toPage:AddressComponentEnums.NONE,fromPage:AddressComponentEnums.ADDRESS_LIST});
  }

  openDetails(data?){
    this.closeNewScreen.emit({toPage:AddressComponentEnums.ADDRESS_DETAILS,fromPage:AddressComponentEnums.ADDRESS_LIST,dataToPass:data});
  }

  openMapPage(data?){
      this.closeNewScreen.emit({toPage:AddressComponentEnums.ADDRESS_MAP,fromPage:AddressComponentEnums.ADDRESS_LIST,dataToPass:data});
  }

  closeCurrentScreen(event){
    if(event)
    this.openDetails(event.data)
  }

}

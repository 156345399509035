<div class="four-zero-four">
  <div class="container">
    <div class="grey-container">
      <h1 [style.opacity]="flicker">404</h1>
      <h4>Oops!</h4>
      <p *ngIf="whiteLabel$ | async as whiteLabel">
        We were unable to find the page you were looking for. click
        <a
          [ngStyle]="{ color: whiteLabel.appPrimaryColor }"
          (click)="callRefresh()"
          >here</a
        >
        to go back again
      </p>
    </div>
  </div>
</div>

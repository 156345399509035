import { Component } from '@angular/core';
import { ModalService } from './../../modal/modal.service'
import { ScreenSwitchWrapperBaseComponent, SharedService } from '@moduurnv2/libs-orderingapp/src';
import { Store } from '@ngxs/store';
@Component({
  selector: 'moduurnv2-modifier-wrapper',
  templateUrl: './modifier-wrapper.component.html',
  styleUrls: ['./modifier-wrapper.component.scss']
})
export class ModifierWrapperComponent extends ScreenSwitchWrapperBaseComponent {
  dataPassedToModal: any;
  _modalClose: any = null;

  constructor(private modal: ModalService, shared: SharedService, store:Store) {
    super(shared, store);
  }

  set data(data: any) {
    this.dataPassedToModal = data;
  }

  // set modalClose(modalRef: any) {
  //   this._modalClose = () => {
  //     this.modal.closeModal(modalRef);
  //   };
  // }
  set modalClose(modalRef: any) {
    this._modalClose = (data) => {
      if(data)
        this.modal.closeComponentModal(modalRef, data);
      else
        this.modal.closeModal(modalRef);
    };
  }
}

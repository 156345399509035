import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'; 
import { environment } from '../../core/environments/environment';
import { Config } from '../../../../../apps/orderingapp/web-orderingapp/src/config'

@Injectable({
  providedIn: 'root'
})
export class YepdeskApiService {

  yepdeskurl = `${environment.organizationApiUrl}organizations/${Config.organizationId}/events`;

  constructor( private http:HttpClient ) { }

  getAllEvents(){
   return this.http.get(this.yepdeskurl)
  }
}

<!-- <span class="delivery" *ngIf="item.Deliverable==true">Delivers to</span>
  <span class="nodelivery" *ngIf="item.Deliverable==false">Does't Delivers to</span> -->
<div *ngIf="!isAddressBook">
  <div class="delivery-outer">
    <span class="delivery" *ngIf="items.deliverable == true">
      {{ 'delivers to' | translate }}</span
    >
    <span class="nodelivery" *ngIf="items.deliverable == false">
      {{ 'not delivers to' | translate }}</span
    >
  </div>
  <!-- <div class="nodelivery" *ngIf="items.deliverable == false">
    <span>{{ 'not delivers to' | translate }}</span>
  </div> -->
</div>
<div
  class="row"
  [ngClass]="{
    disableddiv: items.deliverable !== true && !isAddressBook,
    selectedRow: !isShowDiv
  }"
  (click)="selectAdress($event,items._id)"
>
  <div class="distance" data-qa="add-new-adrs">
    <img [src]="icon.location" alt="location"/>
    <div *ngIf="items.distance !== undefined">
      <span>{{ items?.distance | distanceConverter }}</span>
    </div>
  </div>
  <div class="address">
    <div class="header-details">
      <div class="header" *ngIf="items.addressType">
        <span>{{ items?.addressType }}</span>
      </div>
      <div class="btns">
        <span
          *ngIf="!isAddressBook"
          class="edit-btn"
          (click)="editAdrs($event,items._id)"
          data-qa="edit-address"
          >{{ 'Edit' | translate }}</span
        >
        <span
          *ngIf="!isAddressBook"
          class="edit-btn"
          (click)="removeAdrs($event,items._id)"
          >{{ 'delete-btn' | translate }}</span
        >
      </div>
    </div>
    <span>{{ items?.name }}</span>
    <div
      class="details"
      data-qa="select-address"
    >
      <span *ngIf="items.unitNumber != ''">{{ items.unitNumber }},</span>
      <span *ngIf="items.buzzerNumber != ''">{{ items.buzzerNumber }},</span>
      <span *ngIf="items.streetAddress != ''">{{ items.streetAddress }},</span>
      <span *ngIf="items.city != ''">{{ items.city }} ,</span>
      <span *ngIf="items.state != ''">{{ items.state }},</span>
      <span *ngIf="items.country != ''">{{ items.country }},</span>
      <span *ngIf="items.postalcode != ''">{{ items.postalcode }}</span>
    </div>
    <div [hidden]="isShowDiv">
      <button
        class="primary-btn edit"
        (click)="editAdrs($event,items._id)"
        data-qa="edit-address"
      >
        {{ 'edit-btn' | translate }}
      </button>
      <button
        class="primary-btn delete"
        (click)="removeAdrs($event,items._id)"
        data-qa="remove-address"
      >
        {{ 'delete-btn' | translate }}
      </button>
    </div>
  </div>
  <div
    class="selected"
    *ngIf="
      !isAddressBook &&
      selectedLoc != undefined &&
      selectedLoc._id != '' &&
      items.deliverable
    "
  >
    <img [src]="icon.selected" *ngIf="selectedLoc._id == items._id" alt="selected"/>
  </div>
</div>
<hr class="border" />

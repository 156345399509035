<div class="header-wrapper">
  <div class="header-desktop-container">
    <img
      class="logo"
      [src]="organizationLogo"
      (click)="routeToHome()"
      aria-label="organization logo"
      alt="to home"
    />

    <!-- <img
    class="logo"
    src="assets/images/Blenz_Coffee_Logo.svg"
    (click)="routeToHome()"
    alt="to home"
  /> -->

    <div class="app">
      <!-- <button
        (click)="scrollToFooter('footer')"
        class="button"
        [ngStyle]="{
          'background-image': 'url(' + mobileIcon + ')',
          'background-repeat': 'no-repeat',
          'background-position-y': '45%'
        }"
      >
        <label class="text"> {{ 'Get the mobile app' | translate }}</label>
      </button> -->
    </div>

    <div class="auth" *ngIf="!allowOnlyGuestUsers">
      <ng-container
        *ngIf="
          loggedInUserDetails$ | async as loggedInUserDetails;
          else guest_user
        "
      >
        <!-- <moduurnv2-button-drop-down
          *ngIf="
            loggedInUserDetails && loggedInUserDetails.isLoggedIn;
            else guest_user
          "
          [buttonImage]="'/assets/images/profileIcon.svg'"
          [buttonText]="loggedInUserDetails.userName"
          [items]="userMenuItems"
          imageHeight="2em"
          (selectedItem)="getSeletedMenu($event)"
        ></moduurnv2-button-drop-down> -->

        <moduurnv2-button-drop-down
          *ngIf="
            loggedInUserDetails && loggedInUserDetails.isLoggedIn;
            else guest_user
          "
          [letterForImage]="
            loggedInUserDetails.userName
              ? loggedInUserDetails.userName.charAt(0)
              : ''
          "
          [buttonText]="loggedInUserDetails.userName"
          [items]="userMenuItems"
          (selectedItem)="getSeletedMenu($event)"
        ></moduurnv2-button-drop-down>
      </ng-container>
      <ng-template #guest_user
        ><button (click)="showRegister()" data-qa="login-register-button" class="login-register">
          {{ 'Login_Register' | translate }}
        </button></ng-template
      >
    </div>

    <!-- <div class="language-selection">
      <moduurnv2-button-drop-down
        [buttonImage]="'/assets/images/Language_Icon.svg'"
        [buttonText]="getSelectedLanguage()"
        [items]="languageList"
        [isBorderVisible]="true"
        [isHoverEffect]="true"
        imageHeight="1em"
        (selectedItem)="getSeletedLanguage($event)"
      ></moduurnv2-button-drop-down>
    </div> -->
  </div>
</div>

import { Component, OnInit, Input,EventEmitter,Output } from '@angular/core';
import { environment } from '@moduurnv2/libs-orderingapp/src/core';
import { Store, Select } from '@ngxs/store';
import { SetLoginFrom } from '@moduurnv2/libs-orderingapp/src/core';
import { Router } from '@angular/router';
@Component({
  selector: 'moduurnv2-email-auth',
  templateUrl: './email-auth.component.html',
  styleUrls: ['./email-auth.component.scss'],
})
export class EmailAuthComponent implements OnInit {
  loginLeftArrowIcon: string =
    environment.image_basepath + environment.images.arrow_left_icon;
  isMobileView:boolean = false;
  @Output() showEmailAuthModal: EventEmitter<any> = new EventEmitter<any>();
  @Input() pageToShow: string;
  @Input() loginFrom: string = undefined;

  constructor( private store: Store, private router:Router) {}

  ngOnInit(): void {
    if(this.loginFrom) this.store.dispatch(new SetLoginFrom(this.loginFrom));
  }

  showSubModal($event){
    if($event === 'LOGIN')
    this.pageToShow = $event;
    else if($event === 'FORGOT-PASSWORD')
    this.pageToShow = $event;
    else{
      this.pageToShow = $event;
      this.hideModal();
    }
  }

  hideModal(){
    this.showEmailAuthModal.emit(false)
  }
}

<script src="http://cdn.rawgit.com/hammerjs/touchemulator/master/touch-emulator.js"></script>
<div class="main-header-container notch-safe-area">
  <img data-qa="hamburger-icon" aria-label="hamburger menu" class="hamburger-menu" (click)="openMenu()"
    src="/assets/images/menu-collapse-icon.svg" alt="hamburger icon" />

  <div class="hamburger-menu-container" [ngClass]="{ 'hamburger-menu-container-visible ': isMenuVisible }"
    (click)="closeMenu()">
    <div class="hamburger-menu-content">
      <a *ngIf="whitelabel$ | async as whitelabel" (click)="navigateToModuurn()" class="logo main"
        aria-label="organization logo"><img [src]="getLogo(whitelabel)" alt="organization icon" /></a>

      <div class="border"></div>

      <ng-container *ngFor="let item of menuItems">
        <div class="item" [attr.data-qa]="'menu-item-' + item._id" attr.aria-label="{{ item.name | translate }}">
          <img [src]="item.icon" *ngIf="item.needIcon" alt="need icon" />
          <span [class]="item.needIcon ? '' : 'menu-item-small'">{{
            item.name | translate
            }}</span>
        </div>
      </ng-container>

      <!-- <div class="border"></div> -->
      <!-- <a href="#"
        ><img src="/assets/images/H_About Us Icon.svg" alt="about us icon"/>
        <span>{{ 'About Us' | translate }}</span></a
      > -->
      <!-- <a href="mailto:support@moduurn.com">
        <img src="/assets/images/H_Feedback Icon.svg" alt="feedback icon"/>
        <span>{{ 'Customer Support' | translate }}</span></a
      > -->

      <div class="item">
        <span class="item-head">{{ 'Customer Support' | translate }}:</span>
        <ul class="support-opt">
          <li>
            {{ 'tech-and-payment' | translate }} :<a class="normal-text"
              href="mailto:support@moduurn.com">support@moduurn.com</a>
            &
            <a class="action-text call" href="tel:1-855-MODUURN">1-855-MODUURN</a>
            ext 2    <a (click)="startWebChat()" target="_blank">
              <img class="icon-chat" src="assets/images/chat-support-icon.svg" />
            </a>
          </li>
          <li *ngIf="feedbackEmail">
            {{ 'food-issues' | translate }} :<a class="normal-text" href="mailto:{{ feedbackEmail }}">{{ feedbackEmail
              }}</a>
          </li>
          <li *ngIf="deliveryPartners && deliveryPartners.length > 0">
            {{ 'delivery' | translate }} :
            <ng-container *ngFor="let partner of deliveryPartners; let i = index">
              <div>
                <a class="action-text call" href="{{ 'tel:' + partner.phoneNumber }}">{{ partner.phoneNumber }}</a>
              </div>
            </ng-container>
          </li>

        </ul>
      </div>
      <!-- <a href="#"
        ><img src="/assets/images/H_Help Icon.svg" alt="help icon"/>
        <span>{{ 'Help' | translate }}</span></a
      > -->

      <div class="items-wrapper" id="footer">
        <ng-container *ngIf="checkWeb">
          <a [href]="appStoreLink" target="_blank" *ngIf="appStoreLink">
          <img class="button" src="assets/images/App_Store.svg" alt="app store" />
          </a>
          <a [href]="playStoreLink" target="_blank" *ngIf="playStoreLink">
          <img class="button" src="assets/images/Play_Store.svg" alt="play store" />
          </a>
        </ng-container>
        <a [href]="instagramLink" target="_blank" *ngIf="instagramLink">
          <img class="icon" src="assets/images/Instagram_logo.svg" alt="Instagram icon" />
        </a>
        <a [href]="twitterLink" target="_blank" *ngIf="twitterLink">
          <img class="icon" src="assets/images/Twitter_Logo.svg" alt="twitter icon" />
        </a>
        <a [href]="facebookLink" target="_blank" *ngIf="facebookLink">
          <img class="icon" src="assets/images/Facebook_Logo.svg" alt="facebook icon" />
        </a>
     
       
      </div>
      <div class="border"></div>
      <!-- <a href="#" class="menu-item-small">
        {{ 'Privacy Policy' | translate }}</a
      > -->
      <!-- <a href="#" class="menu-item-small">
        {{ 'Terms of Service' | translate }}</a
      > -->
      <span class="version">Version {{ appVersion }}</span>
    </div>
  </div>

  <!--
  </div> -->
  <ng-container *ngIf="
      selectedMethod && selectedMethod.textCode == 'DELIVERYTYPE08';
      else orderFrom
    ">
    <img [src]="organizationLogo" class="loc-logo" aria-label="organization logo"
      [ngClass]="{ 'logo-padding ': isSingleLocation }" alt="organization icon" />
  </ng-container>
  <ng-template #orderFrom>
    <label class="location" [ngClass]="{ disabled: !isHomePage || isSingleLocation || blockLocationChange()}"
      (click)="organizationLocation()">{{ location ? ('ordering-from' | translate) : ('CHOOSE ' | translate) }}
      <span class="organization">{{
        location ? location.name : ('RESTAURANT LOCATION' | translate)
        }}</span></label>
    <!-- <span class="location-arrow">
      <img *ngIf="isHomePage && !isSingleLocation" (click)="organizationLocation()" src="/assets/images/arrow-down.svg"
        alt="down" />
    </span> -->
  </ng-template>

   <!--<ng-container *ngIf="checkShowDelivery">
    <ng-container *ngIf="
        selectedMethod &&
          selectedMethod.textCode != 'DELIVERYTYPE09' &&
          selectedMethod.textCode != 'DELIVERYTYPE10';
        then addressSelection;
        else roomStationSelection
      "></ng-container>
  </ng-container> -->
<!-- 
  <ng-template #addressSelection>
    <ng-container *ngIf="selectedMethod && selectedMethod.textCode === 'DELIVERYTYPE01'">
      <img [ngClass]="{ disabled: !isHomePage }" src="/assets/images/location-icon-small.svg" class="map-icon"
        aria-label="organization logo" (click)="openAddressBook()" alt="location icon" />
    </ng-container>
  </ng-template> -->
  <!-- <ng-template #roomStationSelection>
    <ng-container *ngIf="selectedMethod && selectedMethod.textCode == 'DELIVERYTYPE09';
        else roomSelection">
      <img [ngClass]="{ disabled: !isHomePage }" src="/assets/images/pickup-select.svg" class="selection-icon"
        (click)="openVenuePickupStationTimeSelectionPopup()" alt="cafeteria pickup" />
    </ng-container>

    <ng-template #roomSelection>
      <img [ngClass]="{ disabled: !isHomePage }" src="/assets/images/classroom-delivery-select.svg"
        (click)="openVenueRoomTimeSelectionPopup()" class="selection-icon" alt="classroom delivery" />
    </ng-template>
  </ng-template> --> 
</div>
<div
  class="adrs-list-container"
  [ngClass]="{
  disableddiv: items.deliverable != true && !isAddressBook,
  selectedRow: !isShowDiv
}"
 (click)="selectAdress($event,items._id)"
>
  <!-- <div class="delivery" *ngIf="!isAddressBook">
    <span class="delivery" *ngIf="items.deliverable==true"
      >{{ 'delivers to' | translate }}</span
    >
    <span class="nodelivery" *ngIf="items.deliverable==false"
      >{{ 'not delivers to' | translate }}</span
    >
  </div> -->
  <div class="delivery-outer" *ngIf="!isAddressBook">
    <span class="delivery" *ngIf="items.deliverable == true">
      {{ 'delivers to' | translate }}</span
    >
    <span class="nodelivery" *ngIf="items.deliverable == false">
      {{ 'not delivers to' | translate }}</span
    >
  </div>
  <div class="row scroll-pane">
    <div class="distance" data-qa="add-new-adrs">
      <img [src]="icon.location" alt="distance"/>
      <div *ngIf="items.distance !== undefined">
        <span>{{ items?.distance | distanceConverter }}</span>
      </div>
    </div>
    <div class="address">
      <div class="header-details">
        <div class="header">
          <span *ngIf="items?.addressType" class="adrs-type"
            >{{ items?.addressType }}</span
          >
        </div>
        <div class="btns">
          <span class="edit-btn" (click)="editAdrs($event,items._id)"
            >{{ 'Edit' | translate }}</span
          >
          <span class="edit-btn" (click)="removeAdrs($event,items._id)"
            >{{ 'Delete' | translate }}</span
          >
        </div>
      </div>
      <div>
        <span>{{ items?.name }}</span>
        <div class="details">
          <span *ngIf="items.unitNumber != ''"
            >{{ items.unitNumber }},</span
          >
          <span *ngIf="items.buzzerNumber != ''"
            >{{ items.buzzerNumber }},</span
          >
          <span *ngIf="items.streetAddress != ''"
            >{{ items.streetAddress }},</span
          >
          <span *ngIf="items.city != ''">{{ items.city }} ,</span>
          <span *ngIf="items.state != ''">{{ items.state }},</span>
          <span *ngIf="items.country != ''">{{ items.country }},</span>
          <span *ngIf="items.postalcode != ''">{{ items.postalcode }}</span>
        </div>
      </div>
      <!-- <div *ngIf="isAddressBook" class="btns">
        <button class="primary-btn edit" (click)="editAdrs($event,items._id)">
          {{ 'edit-btn' | translate }}
        </button>
        <button class="primary-btn delete" (click)="removeAdrs(items._id)">
          {{ 'delete-btn' | translate }}
        </button>
      </div> -->
    </div>
    <div
      *ngIf="!isAddressBook && selectedLoc != undefined && selectedLoc._id != '' && items.deliverable"
      class="selected"
    >
      <img [src]="icon.selected" *ngIf="selectedLoc._id == items._id" alt="selected"/>
    </div>
  </div>
  <hr class="border" />
</div>

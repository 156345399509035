import { Component, OnInit, Input,AfterViewChecked } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import {
  MenuItemState,
  FetchSelectedMenuItem,
  SubmitModifierItem,
  UpdateItemCount,
  environment,
  LocationState,
  CartState,
  ComboState,
  UpdateCount,
  UpdateCustomItemCount,
  AddItemToCart,
  ValiateMenuItem,
  AuthState,
  WhitelabelState,
  SetLoginFrom,
} from '@moduurnv2/libs-orderingapp/src';
import { Observable } from 'rxjs/internal/Observable';
import { ModalService } from '../../modal/modal.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {LanguageLocalize} from '@moduurnv2/features/ui/pipes/index'
import {  takeUntil } from 'rxjs/operators';
import {  Subject } from 'rxjs';
import { AlertPopupComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/alert-popup/alert-popup.component';
import { LoginDesktopComponent } from '../../../login/components/login-desktop/login-desktop.component';

@Component({
  selector: 'moduurnv2-modifier-desktop',
  templateUrl: './modifier-desktop.component.html',
  styleUrls: ['./modifier-desktop.component.scss'],
})
export class ModifierDesktopComponent implements OnInit{
  
  @Select(MenuItemState.getSelectedMenuItem) selectedMenuItem$: Observable<any>;
  @Select(MenuItemState.getMenuItemPrice) price$: Observable<any>;
  @Select(MenuItemState.getGeneratedCount) count$: Observable<any>;
  @Select(MenuItemState.getItemDetails) details$: Observable<any>;
  @Select(LocationState.getLocationStatus)locationStatus$:Observable<any>
  @Select(MenuItemState.getMenuItemPoints) points$: Observable<any>;

  close = environment.image_basepath + environment.images.desktop_modal_close;
  @Input() data: any = null;
  imageBase = environment.imagebaseurl;
  checkIfEdit: boolean = false;
  submitClick: boolean = false;
  @Input() _modalClose: any;
  routeFromPage: String = '';
  isModifiable: boolean = false;
  selectedMenuItem:any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  modifierslist: any;
  selectedModifier: any;
  portionCheck: boolean = false;
  fromCombo: string;
  fromComboDetails;
  menuItemIdInGroup: string;
  count;
  existingSameItemInCart;
  freeTextmodifierslist: any;
  selectedFreeTextModifier : any;
  isShowCalorieDetails:boolean;
  isHideDescription:boolean;  
  showModiScroll:boolean=false;

  constructor(
    private store: Store,
    private modal: ModalService,
    private router: Router,
 
    private translate: TranslateService
  ) {
   
  
  }

  ngOnInit() {    
    if (this.data) this.loadInitialData(this.data);

    this.count$.pipe(takeUntil(this.destroy$))
    .subscribe((cnt) => {
      if (cnt) {
        this.count= cnt;
      }
    });

    this.selectedMenuItem$.pipe(takeUntil(this.destroy$))
      .subscribe((selectedMenuItem) => {
        if (selectedMenuItem) {
          this.selectedMenuItem = selectedMenuItem;
          if(this.fromCombo && this.fromComboDetails){
            this.checkPriceOverriding();
          }
          this.getGeneratedPortion(selectedMenuItem.portions);
          if(this.selectedMenuItem?.portions?.length>0 || this.selectedMenuItem?.modifiers.length > 0 ||  (this.selectedMenuItem?.freeTextModifiers && this.selectedMenuItem?.freeTextModifiers .length)) this.isModifiable = true;
          if(!this.isModifiable){
            this.handleItemWithoutModifiersOnLoad();
          }

        }
      });  
      this.locationStatus$.subscribe(data=>{        
        this.isShowCalorieDetails=data.activeProfile.showCalorieInformation;
        this.isHideDescription=data.activeProfile.hideDescription;
      })             
  }
    
  set modalClose(modalRef: any) {
    this._modalClose = () => {
      this.modal.closeModal(modalRef);
    };
  }

  loadInitialData(data: any) {    
    const { menuItemId, routeFrom, fromCombo, menuItemIdInGroup } = data;
    this.routeFromPage = routeFrom;
    this.fromCombo = fromCombo;
    this.fromComboDetails = this.store.selectSnapshot(ComboState.getComboGroup(fromCombo));
    
    this.menuItemIdInGroup = menuItemIdInGroup;
    this.store.dispatch(new FetchSelectedMenuItem(menuItemId, !!fromCombo,this.menuItemIdInGroup));
    this.checkIfEdit = this.store.selectSnapshot(MenuItemState.getCartDetails);
  }
  checkPriceOverriding(){
    let menuItem = this.fromComboDetails.group_items.filter(item => item.menuItem._id == this.selectedMenuItem._id);
    if(menuItem && menuItem[0] && menuItem[0].selectedOverridePortions ){
     let selectedOverridePortions = menuItem[0].selectedOverridePortions;
      this.selectedMenuItem.portions.map(item => {
        let index = selectedOverridePortions.findIndex(portionId => portionId ==item._id);
        if(index > -1){
          item.price = menuItem[0].overridePriceValue;
        }
      })

    }
  }
  ngOnDestroy() {
    this.fromCombo = '';
  }


  choiceText(modifierItem) {
    const { isForced, maxLimit, minLimit } = modifierItem;
    let choiceOption = this.translate.instant('please-select');
    let endText = this.translate.instant('optional');
    if (isForced) endText = this.translate.instant('required');

    if (maxLimit === 1) return `${choiceOption} 1 `+this.translate.instant('option')+ ` (${endText})` ;
    else if (maxLimit > 0)
      if (!minLimit || minLimit === 0) return `${choiceOption} ${this.translate.instant('upto')} ${maxLimit} `+this.translate.instant('option')+'s'+ ` (${endText})`;
      else if (minLimit === maxLimit)
        return `${choiceOption} ${maxLimit} `+this.translate.instant('option')+'s'+ ` (${endText})`;
      else return `${choiceOption} ${this.translate.instant('between')} ${minLimit} ${this.translate.instant('and')} ${maxLimit} `+this.translate.instant('option')+'s'+ ` (${endText})`;
  }
  choiceTextFreeTextModifier(modifierItem) {
    const { isForced } = modifierItem;
    
    let endText = this.translate.instant('optional');
    if (isForced) endText = this.translate.instant('required');
    return '(' +endText + ')';
  }

  headerErrors(modifierValid, id = null) {    
    const submitted = this.store.selectSnapshot(MenuItemState.getSubmitted);
    const selectedMenuItem = this.store.selectSnapshot(
      MenuItemState.getSelectedMenuItem
    );    

    if (this.submitClick && submitted && (modifierValid === false || !selectedMenuItem.valid)) {      
      let menuItemDivId = this.getErrorMenuItemToHighlight(selectedMenuItem);      
      var element = document.getElementById(menuItemDivId ? menuItemDivId : id);      
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
        this.submitClick = false;
      }
    }
    return submitted ? modifierValid === false : false;
  }

  headerErrorsFreeTextModifier(modifier, id = null) {
    const submitted = this.store.selectSnapshot(MenuItemState.getSubmitted);
    const selectedMenuItem = this.store.selectSnapshot(
      MenuItemState.getSelectedMenuItem
    );
    if (this.submitClick && submitted && modifier.isForced == true && (modifier.value == false || !selectedMenuItem.valid)) {
      let menuItemDivId = this.getErrorMenuItemToHighlight(selectedMenuItem);
      
      var element = document.getElementById(menuItemDivId ? menuItemDivId : id);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
        this.submitClick = false;
      }
    }
    return submitted ? (modifier.isForced == true && modifier.valid == false) :  false;
  }

  getErrorMenuItemToHighlight(selectedMenuItem){ 
    let modifiersMenuItemIds = selectedMenuItem.modifiers ?  selectedMenuItem.modifiers.filter(filtMenItms =>  !filtMenItms.valid) : [];
    let freeTextModifiersMenuItemIds = selectedMenuItem.freeTextModifiers ? selectedMenuItem.freeTextModifiers.filter(filtFreTxtMenItms =>  !filtFreTxtMenItms.valid) : [];
    return modifiersMenuItemIds.length > 0 ? modifiersMenuItemIds[0]._id : (freeTextModifiersMenuItemIds.length > 0 ? freeTextModifiersMenuItemIds[0]._id : null) ;
  }


  submitMenuItem() {
    const authDetails = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    let other = this.store.selectSnapshot(WhitelabelState.getOther);
    let header = 'Please login to Continue';
    let isModalOpen = true;
    if(other.employeeLoyalty && other.employeeLoyalty.isAllow){
      if(!authDetails?.isLoggedIn){
        this.closePopup();
       return this.modal.showSmallModal(AlertPopupComponent,{data:{
          header:header,
          actionable:authDetails?.isLoggedIn ? false : true ,
          closeBtnText:authDetails?.isLoggedIn ? 'Ok': this.translate.instant('cancel'),
          proceedText:'Login'
        },
        whenClosed: (data) => {
          if(isModalOpen){
            if(!authDetails?.isLoggedIn && data){
              this.store.dispatch(new SetLoginFrom(undefined));
              this.modal.showMediumModalDesktop(LoginDesktopComponent);
            } 
            // else if(authDetails?.isLoggedIn){
            //   this.router.navigate([`locations/${locationId}/menu`]);
            // }
          } 
          isModalOpen = false;
        }})
      }
    }


    this.submitClick = true;
    if(this.isModifiable){

    if(this.fromCombo){
      if(this.selectedMenuItem.valid){
        this.store.dispatch(new SubmitModifierItem(this.fromCombo, this.menuItemIdInGroup));
        return this.closePopup();
      }
    }
    this.store.dispatch(new SubmitModifierItem());
    if (this.selectedMenuItem.valid) {
      switch (this.routeFromPage) {
        case 'HOME':
          const selectedLocation = this.store.selectSnapshot(
            LocationState.getSelectedLocation
          );
          if (selectedLocation)
            this.router.navigate([`/locations/${selectedLocation._id}/menu`]);
          this.closePopup();
          break;
        default:
            this.closePopup();  
          break;
      }
    }
    } else {
      if(this.fromCombo){
        if(this.selectedMenuItem.valid){
          this.store.dispatch(new SubmitModifierItem(this.fromCombo, this.menuItemIdInGroup));
        }
      } else {
        this.updateItemWithoutModifiersCount();
      }
      this.closePopup();
    }

  }

  increaseCounter() {
    this.changeCounter(1);
  }

  updateCustomCount(count) {
    this.store.dispatch(new UpdateCustomItemCount(count));
  }


  decreaseCounter() {
    this.changeCounter(-1);
  }

  changeCounter(count) {
    this.store.dispatch(new UpdateItemCount(count));
  }

  closePopup() {
    if (this._modalClose) 
      if(this.fromCombo)
        this._modalClose(this.selectedMenuItem);
      else
        this._modalClose();
  }

  getActionCaption({ actionCaption, translatedactionCaption,name, translation },type) {   
    let Lang = new LanguageLocalize(this.translate,this.store);
    let nameTrans = name ? Lang.transform(name,translation) : '';
    let captionTrans= actionCaption ? Lang.transform(actionCaption,translatedactionCaption) : '';
    if (captionTrans) return `${captionTrans} ${nameTrans}`;
    else{      
      return type==="freeText"? 'Please enter your'+` ${nameTrans}`   : this.translate.instant('choose-your')+` ${nameTrans}` ;
    }
  }


  // getActionCaptionFreeModifier(modifier) {   
  //   let Lang = new LanguageLocalize(this.translate,this.store);
  //   let nameTrans = Lang.transform(modifier.name,modifier.translation);
  //   let captionTrans=Lang.transform(modifier.actionCaption,modifier.translatedactionCaption);
  //   if (captionTrans) return `${captionTrans} ${nameTrans}`;
  //   else{
  //       console.log(modifier)
  //     return this.translate.instant('choose-your')+` ${nameTrans}`;
  //   }
  // }

  get checkComboMax() {
    // if(this.fromComboDetails){
    //   const { itemCount } = this.fromComboDetails;
    //   const count = this.store.selectSnapshot(MenuItemState.getGeneratedCount);
    //   return itemCount? count >= itemCount: false;
    // }
    if(this.fromCombo){
      const cartCombo = this.store.selectSnapshot(ComboState.getCartCombo);
      if(cartCombo?.comboGroups){
        const foundCartCombo = cartCombo.comboGroups.find( comboGroup => comboGroup.groupId === this.fromComboDetails._id );
        if(foundCartCombo){
             const count = this.store.selectSnapshot(MenuItemState.getGeneratedCount);
            const itemCount = foundCartCombo.groupItems.reduce((total, item) => {
            return total + item.count;
          }, 0);
          return this.fromComboDetails.itemCount && (itemCount+count) >= this.fromComboDetails.itemCount;
        }
      }
        const { itemCount } = this.fromComboDetails;
        const count = this.store.selectSnapshot(MenuItemState.getGeneratedCount);
        return count >= itemCount;
      
    }
    return false;
  }

  get checkGroupFull(){
    if(this.fromCombo){
      const cartCombo = this.store.selectSnapshot(ComboState.getCartCombo);
      if(cartCombo?.comboGroups){
        const foundCartCombo = cartCombo.comboGroups.find( comboGroup => comboGroup.groupId === this.fromComboDetails._id );
        if(foundCartCombo){
          const itemCount = foundCartCombo.groupItems.reduce((total, item) => {
            return total + item.count;
          }, 0);
          return this.fromComboDetails.itemCount && itemCount >= this.fromComboDetails.itemCount;
        }
      }
    }
    return false;
  }
  scrollOnTop(val){
    var element = document.getElementById('tb-'+val);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
     
    }

  }
  getModifierSelectedValue(id)
{
  this.modifierslist =this.store.selectSnapshot(MenuItemState.getGeneratedModifiers)
  this.selectedModifier= this.modifierslist.find(function (item) {
    if(item.selected==true)
    return (item._id == id && item.selected==true);
  });
}
getFreeTextModifierSelectedValue(id)
{
  this.freeTextmodifierslist =this.store.selectSnapshot(MenuItemState.getGeneratedFreeTextModifiers)
  this.selectedFreeTextModifier= this.freeTextmodifierslist.find(function (item) {
    if(item.selected==true)
    return (item._id == id && item.selected==true);
  });
}
  getGeneratedPortion(portion)
  {
    portion = portion.filter(item => item.selected=== true);
    if(!portion)
    return;
    if(portion.length>0)
      this.portionCheck=true;
  }


  handleItemWithoutModifiersOnLoad(){
    const cartItemPrice = this.store.selectSnapshot(CartState.getCartPriceItems);
    if(cartItemPrice){
      this.existingSameItemInCart = cartItemPrice.find(item=> item.itemId == this.selectedMenuItem._id);
      if(this.existingSameItemInCart){
        if(this.existingSameItemInCart.count && this.existingSameItemInCart.count >1)
        this.updateCustomCount(this.existingSameItemInCart.count);
      }
    }
  }

  updateItemWithoutModifiersCount(){
    const cart = this.store.selectSnapshot(CartState.getCart);
    if(this.existingSameItemInCart?.id){
      const payload ={
        count : this.count,
        menuItemId:this.existingSameItemInCart.id,
        cartId: cart.cart_id
      }
      this.store.dispatch(new UpdateCount(payload));
    } else {
      const payload =[{
        cartModifiers: [],
        count: this.count,
        menuItem: this.selectedMenuItem._id,
      }]
      this.store.dispatch(new AddItemToCart(payload));
    }
  }

  setModifierValue(){
    this.store.dispatch(new ValiateMenuItem(this.selectedMenuItem))
  }

  ScrollToBottomModiferList(){
  const modiScrollTop= document.getElementById("modiScroll").scrollTop;
  document.getElementById("modiScroll").scrollTo({
         top:modiScrollTop+450,
         behavior:'smooth'
       })
  }
  displayModiScroll(){    
    const modifierClient = document.getElementById("modiScroll")?.clientHeight;   
    const modiScrollTotal= document.getElementById("modiScroll")?.scrollHeight;
    const modiScrollTop= document.getElementById("modiScroll")?.scrollTop;  
    const modiOffset = document.getElementById("modiScroll")?.offsetHeight;
  
    if(modiScrollTotal && modifierClient && modiScrollTotal>modifierClient && (modiOffset + modiScrollTop+50 >= modiScrollTotal)== false){ 
      return true;   
    }else if(modiOffset + modiScrollTop+50 >= modiScrollTotal){
        return false;
    }else{
        return false;
    }
  }

  isLoyaltyEnabled(menuItem){
    if (menuItem.organizationId && menuItem.organizationId.other && menuItem.organizationId.other.employeeLoyalty && menuItem.organizationId.other.employeeLoyalty.isAllow)
          return true;   
    else
    return false;  
  }

}

import {
  BaseComponent,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Input } from '@angular/core';

export class ListCartBaseComponent extends BaseComponent {
  @Input() items;
  @Input() comboItems;
  isReviewCart: boolean = true;
  constructor() {
    super();
  }
}

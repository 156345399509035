import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import {
  format,
  AsYouType,
  parse,
  isValidNumber,
  CountryCode,
} from 'libphonenumber-js';
import { Provinces, WhitelabelState } from '@moduurnv2/libs-orderingapp/src';
import { Store } from '@ngxs/store';

@Component({
  selector: 'phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
})
export class PhoneInputComponent implements OnInit {
  @Input() phone;
  @Input() required: boolean = false;
  @Input() submitted: boolean = false;
  @Output() Formatted = new EventEmitter<Object>();

  emittedPhone = { valid: false, international: '' };
  countries = Provinces.countries;
  orgWhiteLabelDetails = this.store.selectSnapshot(
    WhitelabelState.getWhitelabel
  );
  selectedCountry = this.orgWhiteLabelDetails?.physicalAddress?.country
    ? this.countries.find(
        (x) => x.name == this.orgWhiteLabelDetails.physicalAddress.country
      )
    : this.countries[0];
  private valid: boolean = false;
  constructor(private store: Store) {}

  ngOnChanges(change) {
    setTimeout(() => {
      this.emitPhone(this.phone);
    });
  }

  ngOnInit() {}

  emitPhone(event) {
    const pattern = /^[0-9-()+\s]+$/;
    if (event) {
      event = event.trim();
      this.valid = false;
      let mappedPhone: CountryCode = <CountryCode>this.selectedCountry.phone;
      let formatted = new AsYouType(mappedPhone).input(event);
      let parsed = parse(formatted);
      let index = Provinces.countries.findIndex((value) => {
        return value.phone == String(parsed.country);
      });
      if (parsed.country && index > -1) {
        if (this.selectedCountry.phone != parsed.country) {
          this.selectedCountry = Provinces.countries[index];
        }
        mappedPhone = <CountryCode>this.selectedCountry.phone;
        formatted = new AsYouType(mappedPhone).input(String(parsed.phone));
      }
      this.valid = isValidNumber(formatted, mappedPhone);
      let international = event;
      if (!pattern.test(event)) {
        setTimeout(() => {
          this.phone = '';
          this.phone = event.replace(/[^0-9-()+\s]/g, '');
        });
      } else if (this.valid) {
        this.phone = '';
        setTimeout(() => {
          this.phone = formatted;
        });
        international = format(formatted, mappedPhone, 'International');
      }
      this.emittedPhone = {
        valid: this.valid,
        international: international,
      };
      this.Formatted.emit({
        valid: this.valid,
        international: international,
      });
    } else {
      this.Formatted.emit({
        valid: false,
        international: '',
      });
      this.emittedPhone = {
        valid: false,
        international: '',
      };
    }
  }
}

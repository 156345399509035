<div class="row">
  <div class="header-container">
    <div
      class="head-icon-outer"
      data-qa="close-room-popup"
      aria-label="close"
      (click)="closeSelectionModal()"
    >
      <img
        src="/assets/images/Close_Icon_white_bg.svg"
        data-qa="close-button"
        class="close"
        aria-label="close"
        alt="close"
      />
    </div>
  </div>
  <div class="notification-container">
    <div class="action-outer-container">
      <!-- <input
        type="text"
        class="input"
        [(ngModel)]="notificationMessage"
        placeholder="Please enter your Vehicle Description or Other Instructions"
      /> -->
      <textarea
        class="review-text"
        placeholder="Please enter your Vehicle Description or Other Instructions"
        [(ngModel)]="notificationMessage"
        rows="4"
        [class.dangerBorder]="notificationMessage.trim()=='' && submitted"
      ></textarea>
      <div *ngIf="notificationMessage.trim()=='' && submitted" class="error-message">Vehicle Description or Instructions required.</div>
    </div>
    <ng-container *ngIf="selectedLocationDefaults?.other?.curbsideDeliveryPointEntryEnable">
      <div class="input-outer-container">
        <label for='pickupSpot'>{{selectedLocationDefaults?.other?.curbsideDeliveryPointActionCaption}}</label>
        <input type="text" class="review-text" [class.dangerBorder]="pickupSpot.trim()=='' && submitted" placeholder="Ex : A22" id='pickupSpot' [(ngModel)]="pickupSpot"/>
      </div>
      <div *ngIf="pickupSpot.trim()=='' && submitted" class="error-message text-right">This field is required.</div>
    </ng-container>
    <button
      class="block-button proceed-btn"
      (click)="notifyLocation()"
      data-qa="continue"
      aria-label="continue"
    >
      {{ 'Continue-adrs' | translate }}
    </button>
  </div>
</div>

<!-- Modifier wrapper -->
<div
  class="modifier-wrapper"
  *ngIf="selectedMenuItem$ | async as selectedMenuItem; else modifierLoader"
>
  <button class="close-button" (click)="closePopup()">
    <img [src]="close" data-qa="close-button" aria-label="close" alt="close"/>
  </button>
  <!-- image container -->
  <div class="scrollable">
    <ng-container *ngIf="details$ | async as itemDetail">
      <div
        class="item-image"
        [bg-image]="imageBase + itemDetail.imageURL"
      ></div>

      <!-- modifier details -->
      <div class="item-details" [attr.data-qa]="selectedMenuItem?._id">
        <div class="item-name" [attr.data-qa]="selectedMenuItem?.name">
          {{ itemDetail.name | formattedLanguage: itemDetail.translation:true }}
        </div>
        <div class="item-description" *ngIf="!isHideDescription" >
          {{
            itemDetail.description
              | formattedLanguage: itemDetail.translation:false
          }}
        </div>
        <div class="item-icons">
          <moduurnv2-icon-list
            [icons]="itemDetail?.icons"
            [showText]="true"
          ></moduurnv2-icon-list>
        </div>
        <div class="calorieDetails" *ngIf="isShowCalorieDetails" >
          <ng-container *ngIf="itemDetail.minCalorie && itemDetail.maxCalorie" >
            <span *ngIf="itemDetail.minCalorie" >{{itemDetail.minCalorie}}</span>
            <span *ngIf="itemDetail.maxCalorie"> - {{itemDetail.maxCalorie}} Kcal</span> 
          </ng-container>
          <ng-container *ngIf="itemDetail.minCalorie && !itemDetail.maxCalorie" >
              <span>{{itemDetail.minCalorie}} Kcal</span>
          </ng-container>
          <ng-container *ngIf="itemDetail.maxCalorie && !itemDetail.minCalorie" >
              <span>{{itemDetail.maxCalorie}} Kcal</span>
          </ng-container>

          <!-- <span *ngIf="item.minCalorie" >{{item.minCalorie}}</span>
          <span *ngIf="item.maxCalorie">{{item.maxCalorie}}</span> -->
        </div>
      </div>
    </ng-container>

    <!-- Item options list -->
    <div class="option-list">
      <!-- Portion -->
      <div
        class="option-item accordion-modifier"
        *ngIf="selectedMenuItem?.portions?.length > 0"
      >
        <input id="tab-modifier-01" type="checkbox" checked  (click)="scrollOnTop('01')" />
        <div class="box" id="tb-01">
          <label class="box-title" for="tab-modifier-01">
            <div class="option-header">
              <div class="option-heading accordion-modifier-head">
                {{
                  selectedMenuItem.portionActionCaption
                    | formattedLanguage: selectedMenuItem.captiontranslation
                }}
              </div>
              <div class="option-requirements accordion-modifier-req">
                {{ 'select-option' | translate }} ({{ 'required' | translate }})
              </div>
            </div>
            <img src="assets/images/Bullet-Chevron-Down.svg" class="imgicon" alt="down"/>
          </label>

          <p class="tick-mark" *ngIf="portionCheck === true"></p>
          <div class="box-content">
            <moduurnv2-portion-list
              [portions]="selectedMenuItem.portions" [isLoyaltyEnabled]="isLoyaltyEnabled(selectedMenuItem)"
            ></moduurnv2-portion-list>
          </div>
        </div>
      </div>

      <!-- Modifiers -->
      <ng-container
        *ngIf="selectedMenuItem?.modifiers?.length > 0; else noModifiers"
      >
        <ng-container
          *ngFor="let modifier of selectedMenuItem?.modifiers; let i = index"
        >
          <div
            class="option-item accordion-modifier"
            [attr.data-qa]="modifier._id"
          >
            <input
              id="tab-modifier-{{ i }}"
              type="radio"
              name="accordion"
              *ngIf="
                selectedMenuItem.portions &&
                selectedMenuItem.portions.length > 0
              " (click)="scrollOnTop(i)"
            />
            <input
              id="tab-modifier-{{ i }}"
              type="radio"
              name="accordion"
              *ngIf="selectedMenuItem.portions.length == 0 && i == 0"
              checked (click)="scrollOnTop(i)"
            />
            <input
              id="tab-modifier-{{ i }}"
              type="radio"
              name="accordion"
              *ngIf="selectedMenuItem.portions.length == 0 && i != 0" (click)="scrollOnTop(i)"
            />
            <div class="box" id="tb-{{ i }}">
              <label
                class="box-title"
                for="tab-modifier-{{ i }}"
                [ngClass]="{
                  'label-error': headerErrors(modifier.valid, modifier._id)
                }"
              >
                <div class="option-header" [id]="modifier._id">
                  <div class="option-heading accordion-modifier-head">
                    {{ getActionCaption(modifier) }}
                  </div>
                  <div class="option-requirements accordion-modifier-req">
                    {{ choiceText(modifier) }}
                  </div>
                </div>
                <img
                  src="assets/images/Bullet-Chevron-Down.svg"
                  class="imgicon"
                  alt="down"
                />
              </label>
              <label class="box-close" for="acc-close"></label>

              {{ getModifierSelectedValue(modifier._id) }}
              <p
                class="tick-mark"
                *ngIf="modifier.valid && selectedModifier?._id == modifier._id"
              ></p>

              <div class="box-content">
                <ng-container
                  *ngFor="let modifierItem of modifier?.modifierItems"
                >
                  <moduurnv2-modifier-list
                    [modifierItem]="modifierItem"
                    [modifier]="modifier"
                    [isLoyaltyEnabled]="isLoyaltyEnabled(selectedMenuItem)"
                  >
                  </moduurnv2-modifier-list>
                </ng-container>
              </div>
            </div>
            <input type="radio" name="accordion" id="acc-close" />
          </div>
        </ng-container>
      </ng-container>
      <ng-container
      *ngFor="
        let modifier of selectedMenuItem?.freeTextModifiers;
        let i = index
      "
    >
      <div
        class="option-item accordion-modifier"
        [attr.data-qa]="modifier._id"
      >
        <input
          id="tab-freetext-modifier-{{ i + selectedMenuItem?.modifiers?.length }}"
          name="accordion"
          type="radio"
          *ngIf="
            selectedMenuItem.portions &&
            selectedMenuItem.portions.length > 0
          "
        />
        <input
          id="tab-freetext-modifier-{{ i + selectedMenuItem?.modifiers?.length }}"
          name="accordion"
          type="radio"
          *ngIf="selectedMenuItem.portions.length == 0 &&  (i + selectedMenuItem?.modifiers?.length ) == 0"
          checked
        />
        <input
          id="tab-freetext-modifier-{{ i + selectedMenuItem?.modifiers?.length }}"
          name="accordion"
          type="radio"
          *ngIf="selectedMenuItem.portions.length == 0 && ( i + selectedMenuItem?.modifiers?.length ) != 0"
        />
        <div class="box">
          <label
            class="box-title"
            for="tab-freetext-modifier-{{ i + selectedMenuItem?.modifiers?.length}}"
            [ngClass]="{
              'label-error': headerErrorsFreeTextModifier(modifier, modifier._id)
            }"
          >
            <div class="option-header" [id]="modifier._id">
              <div
                class="option-heading accordion-modifier-head"
                [ngClass]="{
                  'head-error': headerErrorsFreeTextModifier(
                    modifier,
                    modifier._id
                  )
                }"
              >
                {{ getActionCaption(modifier) }}
              </div>

              <div class="option-requirements accordion-modifier-req">
                {{ choiceTextFreeTextModifier(modifier) }}
              </div>
            </div>
            <img
              src="assets/images/Bullet-Chevron-Down.svg"
              class="imgicon"
            />
          </label>

          <label class="box-close" for="acc-close"></label>
          {{ getFreeTextModifierSelectedValue(modifier._id) }}
          <p
            class="tick-mark"
            *ngIf="
            modifier?.value?.trim().length > 0
            "
          ></p>

          <div class="box-content">
            <!-- <ng-container
              *ngFor="let modifierItem of modifier?.modifierItems"
            >
              <moduurnv2-modifier-list
                [modifierItem]="modifierItem"
                [modifier]="modifier"
              >
              </moduurnv2-modifier-list>
            </ng-container> -->
            <textarea
            class="text-area"
            rows="4"
            custom-autofocus
            tabindex="1"
            [(ngModel)] ="modifier.value"
            (ngModelChange)="setModifierValue()"
            [placeholder]=""
            maxlength="280"
            ></textarea>

          </div>
        </div>
        <input type="radio" name="accordion" id="acc-close" />
      </div>
    </ng-container>
      <ng-template #noModifiers>
        <!-- <div class="unavailable">
        Sorry, no modifiers available.
      </div> -->
      </ng-template>
    </div>
  </div>
  <div class="modifier-footer">
    <div class="counter" *ngIf="count$ | async as count">
      <button (click)="decreaseCounter()" data-qa="modifier-count-dec">
        -
      </button>
      <div class="count" data-qa="modifier-count">{{ count }}</div>
      <button
        (click)="increaseCounter()"
        data-qa="modifier-count-inc"
        [ngClass]="{ 'disable-button': checkComboMax }"
      >
        +
      </button>
    </div>

    <div class="add-button-wrapper">
      <button
        (click)="submitMenuItem()"
        [class.zeroPayment]="allowNoPaymentOrdering"
        [theme]="themeOptions"
        [ngClass]="{
          'disable-button': checkGroupFull
        }"
      >
        <div class="button-grid" [class.zeroPayment]="allowNoPaymentOrdering">
          <ng-container
            *ngIf="!!fromCombo; then comboItemText; else menuItemText"
          ></ng-container>
          <ng-template #comboItemText>
            <span>
              Add to Combo
            </span>
          </ng-template>
          <ng-template #menuItemText>
            <span>
              <span>
                <ng-container
                  *ngIf="checkIfEdit; then update; else addToBag"
                ></ng-container>
              </span>
              <ng-template #addToBag>{{
                'add-to-order' | translate
              }}</ng-template>
              <ng-template #update>Update </ng-template>
            </span>
            <span
              data-qa="modifier-total"
              class="amt"
              *ngIf="price$ | async as price else zeroPrice"
            >
              {{ price | formattedCurrency }} <span 
              data-qa="modifier-total-points"
              *ngIf="points$ | async as point"
            >
            <span class="loyalty" *ngIf="isLoyaltyEnabled(selectedMenuItem)"> {{ point }} PTS</span></span></span>
              
            <ng-template #zeroPrice>
              <span
              data-qa="modifier-total"
              class="amt"
            >
              {{ 0 | formattedCurrency }} <span class="modifier-total-points loyalty"
              data-qa="modifier-total-points"
              *ngIf="points$ | async as point"
            >
            <span class="loyalty" *ngIf="isLoyaltyEnabled(selectedMenuItem)"> {{ point }} PTS</span></span></span>
            </ng-template>
            <ng-template #zeroPoints>
              <span class="loyalty" *ngIf="isLoyaltyEnabled(selectedMenuItem)"
              data-qa="modifier-total"
              class="amt"
            >
               0 PTS </span>
            </ng-template>
          </ng-template>
        </div>
      </button>
    </div>
  </div>
</div>

<ng-template #modifierLoader>
  <div class="modifier-wrapper loading">
    <div class="image-container loader">
      <button class="close-button" (click)="closePopup()">
        <img [src]="close" data-qa="close-button" aria-label="close" alt="close"/>
      </button>
    </div>
    <div class="details-container">
      <div class="item-details">
        <div class="item-name loader"></div>
        <div class="item-description loader"></div>
      </div>
    </div>
    <div class="content-container">
      <div class="option-list">
        <div class="option-item">
          <div class="option-header">
            <div class="option-heading loader"></div>
            <div class="option-requirements loader"></div>
          </div>
          <div class="content-item loader"></div>
          <div class="content-item loader"></div>
        </div>
      </div>
    </div>
    <div class="footer-container">
      <div class="button loader"></div>
    </div>
  </div>
</ng-template>

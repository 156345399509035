import { Address, AddressData } from '../models';

export class SetAddress {
  static readonly type = '[ADDRESSES] SetList';

  constructor(public payload: Address[]) {}
}

export class SetSelectedAddress {
  static readonly type = '[ADDRESS] SetNewAddress';

  constructor(public payload: Address) {}
}
export class ChangeDeliveryAddressLocal {
  static readonly type = '[ADDRESS] ChangeAddressLocal';

  constructor(public payload: string) {}
}
export class ChangeDeliveryAddress {
  static readonly type = '[ADDRESS] ChangeAddress';

  constructor(public payload: string) {}
}
export class ChangeToNewDeliveryAddress {
  static readonly type = '[ADDRESS] changeNewAddress';

  constructor(public payload: Address) {}
}
export class ChangeTempToDeliveryAddress {
  static readonly type = '[ADDRESS] setNewFromTempAddress';

  constructor(public payload: Address) {}
}
export class SaveNewDeliveryAddress {
  static readonly type = '[ADDRESS] addNewAddress';

  constructor(public payload: Address) {}
}
export class DeleteDeliveryAddress {
  static readonly type = '[ADDRESS] deleteAddress';

  constructor(public payload: string) {}
}
export class FetchAddressList {
  static readonly type = '[ADDRESSES] FetchAddresses';

  constructor(public payload?: string) {}
}
export class FetchDeliveryAddress {
  static readonly type = '[ADDRESS] FetchAddress';

  constructor(public payload?: string) {}
}
export class ChangePopupStatus {
  static readonly type = '[STATUS] ChangeStatus';

  constructor(public payload?: boolean) {}
}
export class ChangeMapPopupStatus {
  static readonly type = '[STATUS] ChangeMapStatus';

  constructor(public payload?: boolean) {}
}
export class ChangeadrsDetailsPopupStatus {
  static readonly type = '[STATUS] ChangeadrsDetails';

  constructor(public payload?: boolean) {}
}
export class UpdateAddressList {
  static readonly type = '[ADDRESSES] UpdateAddressList';

  constructor(public payload?: Address[]) {}
}
export class FetchDeliveryAddressDetails {
  static readonly type = '[ADDRESS] FetchDeliveryAddressDetails';

  constructor(public payload?: AddressData) {}
}
export class FetchDeliverableDetails {
  static readonly type = '[ADDRESSINFO] FetchDeliveryAddressDetails';

  constructor(public payload?: AddressData) {}
}
export class ClearSelectedAddress {
  static readonly type = 'ClearSelectedAddress';

  constructor() {}
}
export class UpdateDeliverableStatus {
  static readonly type = 'UpdateDeliverableStatus';

  constructor(public payload?: boolean) {}
}

export class SetEnteredAddress {
  static readonly type = 'SetEnteredAddress';

  constructor(public payload?: any) {}
}

export class ClearEnteredAddress {
  static readonly type = 'ClearEnteredAddress';

  constructor() {}
}

export class ClearErrorMessagesOnAddressAddition {
  static readonly type = 'ClearErrorMessagesOnAddressAddition';
  constructor() {}
}


import { Router } from '@angular/router';
import { OnInit } from '@angular/core';

import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs';

import {
  BaseComponent,
  environment,
  Regex,
  AuthEmailSignUp,
  AuthEmailState,
  SetUserDetailsOnSignUp,
  AuthEmailSignUpResponse,
  LocationState,
} from '@moduurnv2/libs-orderingapp/src/core';

import { Config } from 'apps/orderingapp/web-orderingapp/src/config';

export class EmailVerificationBaseComponent extends BaseComponent implements OnInit {
  emailRegex = Regex.email;
  constructor() {
    super();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
   
  }

}

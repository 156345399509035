import { OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { AddressState } from '../../../core/state/address.state';
import { environment } from '../../../core/environments/environment';
import { Address,AddressComponentCloseModel,AddressComponentEnums } from '../../../core/models/address.interface';
import { BaseComponent } from '../../../core/base/base-component';
import {
  ChangeTempToDeliveryAddress,
  ChangeadrsDetailsPopupStatus,
  ChangeMapPopupStatus
} from '../../../core/actions';
import { Observable } from 'rxjs';
import * as mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { HttpClient } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { AddressDetailsDesktopComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/address-book-desktop/components/address-details-desktop/address-details-desktop.component';
import { SharedService } from '@moduurnv2/libs-orderingapp/src/core';
import { AddressMapDesktopComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/address-book-desktop/components/address-map-desktop/address-map.component-desktop';
import { Plugins } from '@capacitor/core';
const { Geolocation } = Plugins;
export class AddressMapBaseComponent extends BaseComponent implements OnInit {
  loggedInUserDetails: any;
  // viewAdrsDetModalStatus: boolean = false;
  @Input() modalMapStatus: boolean;
  @Input() isAddressBook: boolean;
  @Output() closeEvent = new EventEmitter();
  @Output() closeNewScreen = new EventEmitter<AddressComponentCloseModel>();
  // @Select(AddressState.getAdrsDetailsPopUpStatus)
  // getAdrsDetailsPopUpStatus$: Observable<boolean>;
  imgBasePath: string = environment.image_basepath;
  icon = {
    back: this.imgBasePath + environment.images.back_arrow,
    edit: this.imgBasePath + environment.images.edit,
    location_pin: this.imgBasePath + environment.images.adrs_location_pin,
  };
  map: mapboxgl.Map;
  geocoder: MapboxGeocoder;
  currentlocation: string;
  currLat: number = 43.6568;
  currLng: number = -79.4512;
  instructions: string;
  buzzerNumber: string;
  unitNumber: string;
  postalcode: string;
  country: string;
  state: string;
  city: string;
  streetAddress: string;
  _id: string;
  loc: [];
  selectedLocation: Address;
  currentMarkers: any;
  el: any;
  isDisabled: boolean = false;
  regionCode: string;
  listLimit: number = 3;
  _modalClose: any = null;
  addressData:any;
  isMobileView: boolean = true;
  constructor(
    private store: Store,
    private httpresult: HttpClient,
    public modal: ModalService,
    public translate: TranslateService,
    public sharedService: SharedService
  ) {
    super();
     this.sharedService.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
  }
  // subscribeMapPopupStatus() {
  //   this.getAdrsDetailsPopUpStatus$
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe((status) => {
  //       this.viewAdrsDetModalStatus = status;
  //     });
  // }
  ngOnInit() {
    // if(this.sharedService.isMobileScreen())
    //   this.listLimit = 3;
    Object.getOwnPropertyDescriptor(mapboxgl, 'accessToken').set(
      environment.mapbox.accessToken
    );
    this.getlocation();
    this.loggedInUserDetails = localStorage.getItem('loggedInUser')
      ? JSON.parse(localStorage.getItem('loggedInUser'))
      : undefined;
  }
  
  getlocation() {
      Geolocation.getCurrentPosition()
        .then((position) => {
          if (position) {
            this.currLat = position.coords.latitude;
            this.currLng = position.coords.longitude;
          }
          this.initMapbox();
          this.initGeocoder();
        })
        .catch((error) => {
          this.initMapbox();
          this.initGeocoder();
        })
  }
  initMapbox() {
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [this.currLng, this.currLat],
      zoom: 13,
    });

    //Add geolocate control to the map.
    this.map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      })
    );
  }

  initGeocoder() {
    var popup = new mapboxgl.Popup({
      offset: 25,
      closeOnClick: false,
      closeButton: false,
    }).setHTML(
      `&emsp;&emsp;<strong>${this.translate.instant('delivery-here')}</strong>`
    );
    this.el = document.createElement('div');
    this.el.className = 'marker';
    this.el.style.backgroundImage = 'url(' + this.icon.location_pin + ')';
    this.el.style.width = '50px';
    this.el.style.height = '50px';
    this.el.style.backgroundColor = 'none';
    this.el.style.backgroundRepeat = 'no-repeat';
    const marker = new mapboxgl.Marker(this.el)
      .setLngLat([this.currLng, this.currLat])
      .setDraggable(true)
      .setPopup(popup) // sets a popup on this marker
      .addTo(this.map);
    marker.togglePopup(); // toggle popup open or closed
    this.currentMarkers = marker;
    this.geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      marker: false,
      mapboxgl: mapboxgl,
      placeholder: this.translate.instant('your-address'),
      limit: this.listLimit,
    });
    let address = localStorage.getItem('tempSelectedAdrsLocation')
      ? JSON.parse(localStorage.getItem('tempSelectedAdrsLocation'))
      : undefined;
    if (address != undefined) {
      this.currLng = address.loc[0];
      this.currLat = address.loc[1];
    }
    document
      .getElementById('geocoder')
      .appendChild(this.geocoder.onAdd(this.map));
    this.getAddressDetails(this.currLng, this.currLat);

    marker.on('dragend', onDragEnd);
    const vm1 = this;
    function onDragEnd() {
      this.currentMarkers = marker;
      var lngLat = marker.getLngLat();
      vm1.getAddressDetails(lngLat.lng, lngLat.lat);
    }
    this.geocoder.on('result', (results) => {
      this.currentMarkers.remove();
      const marker = new mapboxgl.Marker(this.el)
        .setLngLat(results.result.geometry.coordinates)
        .setDraggable(true)
        .setPopup(popup) // sets a popup on this marker
        .addTo(this.map);
      marker.togglePopup(); // toggle popup open or closed
      this.currentMarkers = marker;
      this.adressData(results.result);
      marker.on('dragend', onDragEnd);
      const vm1 = this;
      function onDragEnd() {
        this.currentMarkers = marker;
        var lngLat = marker.getLngLat();
        vm1.getAddressDetails(lngLat.lng, lngLat.lat);
      }
      //  marker.setLngLat(results.result.geometry.coordinates);
      this.map.flyTo({
        center: results.result.geometry.coordinates,
        essential: true, // this animation is considered essential with respect to prefers-reduced-motion
      });
    });
  }
  getAddressDetails(lng, lat) {
    var url =
      environment.mapbox.reverseDecodingURL +
      lng +
      `,` +
      lat +
      `.json?types=poi&access_token=` +
      environment.mapbox.accessToken;
    this.httpresult.get(url).subscribe((data) => {
      if (data['features'] && data['features'].length) {
        let result = data['features'][0];
        this.geocoder.query(result['text']);
        //this.geocoder.setInput(result['place_name']).select;
        this.adressData(result);
      } else {
        if (document.getElementById('currentLocation'))
          this.isDisabled =
            document.getElementById('currentLocation').innerHTML === '';
      }
    });
    // this.isDisabled = document.getElementById('currentLocation').innerHTML === "";
  }
  adressData(result) {
    if (result == undefined) this.isDisabled = true;
    else this.isDisabled = false;
    if (result != undefined) {
      this.postalcode = '';
      this.city = '';
      this.state = '';
      this.country = '';
      this.regionCode = '';
      document.getElementById('location').innerHTML = result['place_name']
        ? result['place_name']
        : '';
      for (let index in result.context) {
        if (result.context[index].id.includes('postcode'))
          this.postalcode = result.context[index].text;
        else if (result.context[index].id.includes('place'))
          this.city = result.context[index].text;
        else if (result.context[index].id.includes('region')) {
          this.state = result.context[index].text;
          this.regionCode = result.context[index].short_code;
        } else if (result.context[index].id.includes('country'))
          this.country = result.context[index].text;
      }
      this.loc = result.geometry.coordinates;
      this.streetAddress =
        (result.address ? result.address + ' ' : '') +
        (result.text ? result.text : '') +
        (result.properties?.address ? ',' + result.properties.address : '');
      let AddressObj: Address = {
        instructions: '',
        buzzerNumber: '',
        unitNumber: '',
        postalcode: this.postalcode,
        country: this.country,
        state: this.state,
        city: this.city,
        streetAddress: this.streetAddress,
        _id: '',
        loc: this.loc,
        addressType: '',
        distance: '',
        deliverable: undefined,
        regionCode: this.regionCode,
      };
      this.selectedLocation = AddressObj;
    }
  }

  continue() {
    // this.store.dispatch(new ChangeadrsDetailsPopupStatus(true));
    let address = localStorage.getItem('tempSelectedAdrsLocation')
      ? JSON.parse(localStorage.getItem('tempSelectedAdrsLocation'))
      : undefined;
    if (address != undefined) this.selectedLocation._id = address._id;
    if(this.addressData){
      this.selectedLocation.unitNumber = this.addressData.unitNumber;
      this.selectedLocation.buzzerNumber = this.addressData.buzzerNumber;
      this.selectedLocation._id = this.addressData._id;
      this.selectedLocation.addressType = this.addressData.addressType;
      this.selectedLocation.instructions = this.addressData.instructions;
    }
    localStorage.setItem('tempSelectedAdrsLocation',this.selectedLocation ? JSON.stringify(this.selectedLocation) : '');
    this.store.dispatch(new ChangeTempToDeliveryAddress(this.selectedLocation));
    this.openDetails();
  }

  // closeAdrsDetails() {
  //   this.store.dispatch(new ChangeadrsDetailsPopupStatus(false));
  // }
  set data(data: any) {
    this.modalMapStatus = data.modalMapStatus;
    this.isAddressBook = data.isAddressBook;
    this.addressData = data.addressData;
  }
  closeMap(){
    // if (this._modalClose) this._modalClose();
    this.closePage();
  }
  // set modalClose(modalRef: any) {
  //   this._modalClose = () => {
  //     this.modal.closeModal(modalRef);
  //   };
  // }
  // openMap() {
  //   this.store.dispatch(new ChangeMapPopupStatus(true));
  // }
  closePage(){
    this.closeNewScreen.emit({toPage:AddressComponentEnums.NONE,fromPage:AddressComponentEnums.ADDRESS_MAP});
  }
  openDetails(){
    this.closeNewScreen.emit({toPage:AddressComponentEnums.ADDRESS_DETAILS,fromPage:AddressComponentEnums.ADDRESS_MAP});
  }
}

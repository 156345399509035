<div class="header"></div>
<ng-container *ngIf="linkValid && linkValid==true; then valid; else invalid"></ng-container>
<ng-template #valid>
  <moduurnv2-email-change-password></moduurnv2-email-change-password>
</ng-template>
<ng-template #invalid>
  <div class="invalid-link" *ngIf="linkMessage && linkValid==false">
    <span class="msg">{{ linkMessage | translate }}</span>
    <div class="row bar-button">
      <button class="btn-submit" type="button" (click)="returnToHome()">
        {{'return-to-home' | translate}}
      </button>
    </div>
  </div>
</ng-template>


<div class="button-container show-count-container" [ngClass]="{ loading: loading }">
  <button
    (click)="subtractCount($event)"
    data-qa="decrement-button"
    class="button-subtract"
    attr.aria-label="{{ 'subtract' | translate }}"
  >
    -
  </button>
  <div [ngClass]="{
      'count-value': currentCombo.count
    }" ng-model="currentCombo.count">
    {{ currentCombo.count }}
  </div>
  <button
    class="button-add count-plus"
    data-qa="increment-button"
    (click)="addCount()"
    attr.aria-label="{{ 'add' | translate }}"
  >
    +
  </button>
</div>

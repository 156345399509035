import { BaseComponent, LocationState } from '@moduurnv2/libs-orderingapp/src/core';
import {
  Category,
  DiscountsState,
  Discount,
  CustomerFavourites,
  CustomerFavouritesState,
  FetchCustomerFavouritesList,
  PromotionalCategory,
  FetchDeliveryList,
  FetchDiscountsList,
  DeliveryState,
  Delivery,
  HomeModel,
  HomeState,
  FetchHomeData,
  WhitelabelState,
  QRScanDetails,
  AuthEmailState,
} from '@moduurnv2/libs-orderingapp/src/core';
import { ValidationService } from '@moduurnv2/libs-orderingapp/src/core/services';
import { Store, Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

import { FormGroup, FormBuilder } from '@angular/forms';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { takeUntil } from 'rxjs/operators';
// import { AlertPopupComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/alert-popup/alert-popup.component';

export class HomeBaseComponent extends BaseComponent {
  submitted = false;
  today: Date = new Date();
  homeForm: FormGroup;
  enableCategoryList: boolean = true;
  enableDiscount: boolean = false;
  enablecustomerFavouritesList: boolean = true;
  enablePromotionalCategoryList: boolean = true;
  enabledeliveryList: boolean = true;
  enabledGiftandLoyality:boolean = false;
  private _homeSubscriprion: Subscription;
  private _customerSubscriprion: Subscription;

  @Select(HomeState.getHomeData)
  homeData$: Observable<HomeModel>;
  promotionalCategory: PromotionalCategory;
  @Select(HomeState.getHomeCategories) category$: Observable<Category>;
  category: Category;

  @Select(DiscountsState.getDiscountsList) discountList$: Observable<
    Discount[]
  >;
  @Select(CustomerFavouritesState.getCustomerFavouritesList)
  customerFavouritesList$: Observable<CustomerFavourites[]>;
  @Select(DeliveryState.getDeliveryList) deliveryList$: Observable<Delivery[]>;
  @Select(DeliveryState.getSelectedDelivery)
  selectedDelivery$: Observable<Delivery>;
  @Select(WhitelabelState.getLandingPage) landingPage$: Observable<any>;
  @Select(LocationState.getSelectedLocation) selectedLocation$: Observable<any>;
  @Select(WhitelabelState.getQRScanDetails) qrScanDetails$: Observable<QRScanDetails>;
  @Select(AuthEmailState.getUserDetails) getUserDetails$: Observable<any>;

  orgInfo: any;
  locationInfo: any;
  qrScanDetails:QRScanDetails;
  selectedLocation;
  selectedDelivery:Delivery;
  deliveryList:Delivery[];
  isGuest : any;
  constructor(
    public fb: FormBuilder,
    public validatior: ValidationService,
    public store: Store,
    public modal: ModalService
  ) {
    super();
    this.subscribeListItems();
  }

  ngOnInit() {
    this.homeForm = this.fb.group({
      postalCode: [null, this.validatior.postalCodeValidator(true)],
    });
    this.deliveryList$
    .pipe(takeUntil(this.destroy$))
    .subscribe((deliveryList) => {
      if (deliveryList) {
        //Temporary change as part of MV2-1958 . TO BE DELETED after bringing configuration START
        // const tableServiceIndex = deliveryList.findIndex(delivery=> delivery.textCode =="DELIVERYTYPE03");
        // if(tableServiceIndex > -1)
        // deliveryList.splice(tableServiceIndex,1)
        //Temporary change as part of MV2-1958 . TO BE DELETED after bringing configuration END
        this.deliveryList = deliveryList;
      }
    });
    this.selectedLocation$
    .pipe(takeUntil(this.destroy$))
    .subscribe((location) => {
      if (location && location.landingPage) {
        this.locationInfo = location.landingPage.find(page => page.isPublished);
      }
      if(location)
      this.selectedLocation =location;
    });
    this.landingPage$
    .pipe(takeUntil(this.destroy$))
    .subscribe((landingPage) => {
      if (landingPage) {
        this.orgInfo = landingPage;
      }
      });
      this.qrScanDetails$.pipe(takeUntil(this.destroy$)).subscribe((qrScanDetails)=>{
        if(qrScanDetails)
        this.qrScanDetails = qrScanDetails
      })
    this.selectedDelivery$
    .pipe(takeUntil(this.destroy$))
    .subscribe((selectedDelivery) => {
      if (selectedDelivery) {
        this.selectedDelivery = selectedDelivery;
      }
    });
    this.getUserDetails$
    .pipe(takeUntil(this.destroy$))
    .subscribe((user) => {
      this.isGuest = user && user.user_id ? false : true;;
    });
    // Fetch categories
    this.store.dispatch(new FetchDeliveryList());
    this.store.dispatch(new FetchHomeData());
    this.store.dispatch(new FetchCustomerFavouritesList());
    this.store.dispatch(new FetchDiscountsList());
    let other = this.store.selectSnapshot(WhitelabelState.getOther);
   
    if(other && other.paymentGateway && other.paymentGateway.textCode == 'PAYMENTGATEWAY02' && other.loyaltyAndGiftCards && other.loyaltyAndGiftCards.isAllow) 
    {
      this.enabledGiftandLoyality=true;
    }
    
  }
  subscribeListItems() {
    this._homeSubscriprion = this.homeData$.subscribe((homeData) => {
      // this.category = homeData.category;
      this.promotionalCategory = homeData.promotionalCategory;

      if (
        this.promotionalCategory &&
        this.promotionalCategory.items &&
        this.promotionalCategory.items.length == 0
      )
        this.enablePromotionalCategoryList = false;
      else this.enablePromotionalCategoryList = true;
    });

    this._customerSubscriprion = this.customerFavouritesList$.subscribe(
      (customerFavouritesList) => {
        if (
          customerFavouritesList != undefined &&
          customerFavouritesList.length == 0
        )
          this.enablecustomerFavouritesList = false;
        else this.enablecustomerFavouritesList = true;
      }
    );
  }
  ngOnDestroy() {
    this._homeSubscriprion.unsubscribe();
    this._customerSubscriprion.unsubscribe();
  }
  get homeFormControl() {
    return this.homeForm.controls;
  }

  onSubmit() {
    this.submitted = true;
  }

  showReOrdering(){
    if(this.qrScanDetails?.venueName && this.selectedDelivery && this.selectedDelivery.textCode == this.qrScanDetails.deliveryType && this.qrScanDetails.locationId == this.selectedLocation?._id){
      return false;
    } else 
    return true;
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {EmailResetLinkBaseComponent,SharedService,LocationState,AuthEmailState,ClearResetLinkValid} from '@moduurnv2/libs-orderingapp'
import {FormBuilder} from '@angular/forms'
import { Store, Select } from '@ngxs/store';

@Component({
  selector: 'moduurnv2-email-reset-link',
  templateUrl: './email-reset-link.component.html',
  styleUrls: ['./email-reset-link.component.scss']
})
export class EmailResetLinkComponent extends EmailResetLinkBaseComponent implements OnInit {
  constructor(store: Store, router: Router) {
    super(store,router)
  }
  ngOnInit(): void {
  }
}

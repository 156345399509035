import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { NgxsModule } from '@ngxs/store';

import { UISharedModule } from '@moduurnv2/features';
import { VenueManagementState } from '@moduurnv2/libs-orderingapp/src/core';

import { RoomTimeSelectionComponent } from './components/room-time-selection/room-time-selection.component';
import { RoomUpdateSelectionComponent} from './components/room-update-selection/room-update-selection.component';
import { PickupLocationTimeSelectionComponent } from './components/pickup-location-time-selection/pickup-location-time-selection.component';
import { PickupLocationUpdateSelectionComponent } from './components/pickup-location-update-selection/pickup-location-update-selection.component';
import { InlinePlainTimePickerComponent } from './../shared/inline-plain-time-picker/inline-plain-time-picker.component';
import { VenueUpateSelectorComponent } from './components/venue-update-selector/venue-update-selector.component';
import { LoungeTableSelectionComponent } from './components/lounge-table-selection/lounge-table-selection.component';
import { LoungeUpdateSelectionComponent } from './components/lounge-update-selection/lounge-update-selection.component';

@NgModule({
  declarations: [
    RoomTimeSelectionComponent,
    RoomUpdateSelectionComponent,
    PickupLocationTimeSelectionComponent,
    PickupLocationUpdateSelectionComponent,
    InlinePlainTimePickerComponent,
    VenueUpateSelectorComponent,
    LoungeTableSelectionComponent,
    LoungeUpdateSelectionComponent
  ],
  imports: [
    UISharedModule,
    CommonModule,
    FormsModule,
    NgxsModule.forFeature([VenueManagementState]),
  ],
  exports: [
    RoomTimeSelectionComponent,
    RoomUpdateSelectionComponent,
    PickupLocationTimeSelectionComponent,
    PickupLocationUpdateSelectionComponent,
    InlinePlainTimePickerComponent,
    VenueUpateSelectorComponent,
    LoungeTableSelectionComponent,
    LoungeUpdateSelectionComponent
  ],
})
export class VenueManagementModule {}

export class SetSelectedOrderId {
  static readonly type = '[SelectedOrderId] SetSelectedOrderId';

  constructor(public payload: string){}
}


export class SetInprogressOrderIds {
  static readonly type = '[SelectedOrderId] SetInprogressOrderIds';

  constructor(public payload: string[]){}
}

<div class="combo-list-cart-container hide-scroll" *ngFor="let combo of comboItems; trackBy: trackById">
  <div class="combo-added-items">
    <!-- <ng-container> -->
      <div class="combo-header-container">
        <div class="review-pay-combo-container">
        <div class="combo-header">
          <span class="header-text">Combo Order</span>
          <div class="triangle-left"></div>
        </div>
        <img class="delete-icon" src="assets/images/red-delete.png" (click)="deleteCombo(combo)" alt="delete"></div>
        <div class="combo-cart-details">
          <div class="combo-head">
            <p class="combo-name">{{combo.comboId.name}}</p>
            <div class="item-count-container">
              <moduurnv2-combo-count [currentCombo]="combo" [isReviewPage]=true></moduurnv2-combo-count>
              <div class="combo-price">{{combo.displayPrice | formattedCurrency}}</div>
            </div>
          </div>

          <div class="cart-details" *ngFor="let comboGroup of combo.comboGroups;">
              <div class="combo-group-container">
                <p class="selected-combo-item-group">{{comboGroup.groupName}}</p>
                <p class="combo-group-price">{{comboGroup.displayPrice | formattedCurrency}}</p>
                <p class="combo-group-discount-price" *ngIf="comboGroup.isDiscountedPrice">{{comboGroup.displayDiscountedPrice | formattedCurrency}}</p>
              </div>
              <div class="selected-combo-container" *ngFor="let comboItem of comboGroup.groupItems;">
                <div class="combo-item">
                  <div class="item-name">
                    {{comboItem.menuItem.name}}
                  </div>
                  <div class="item-price">
                    X {{comboItem.count}}
                  </div>
                </div>
                <span class="combo-item-modifier" *ngIf="comboItem.portion">{{comboItem.portion.name}}</span>
                <ng-container *ngIf="comboItem.cartModifiers.length > 0">
                  <div class="item-row" *ngFor="let cartModifier of comboItem.cartModifiers">
                    <div *ngIf="cartModifier">{{cartModifier.cartModifier.name}}: </div>
                    <div *ngIf="cartModifier.cartModifierItems.length > 0" class="modifier-container">
                      <ng-container *ngFor="let modifierItem of cartModifier.cartModifierItems;let modItemIndex=index">
                        <div>
                          {{modifierItem.cartModifierItem.name}}
                          <ng-container *ngIf="modifierItem.count">
                            [x {{modifierItem.count}}]
                          </ng-container>
                        </div>
                        <div *ngIf="modifierItem.cartModifierSubItems.length > 0" class="sub-mod-container">
                          <ng-container *ngFor="let subItem of modifierItem.cartModifierSubItems;let subItemIndex=index">
                            <!-- <ng-container *ngIf="subItemIndex==0">(</ng-container> -->
                            <div class="bread">
                              {{subItem.cartModifierSubItem.name}}
                              <ng-container *ngIf="subItem.count">
                                [x {{subItem.count}}]
                              </ng-container>
                            </div>
                            <!-- <ng-container *ngIf="subItemIndex != modifierItem.cartModifierSubItems.length - 1">, </ng-container>
                            <ng-container *ngIf="subItemIndex == modifierItem.cartModifierSubItems.length - 1">)</ng-container> -->
                          </ng-container>
                        </div>
                        <!-- <ng-container *ngIf="modItemIndex != cartModifier.cartModifierItems.length - 1">, </ng-container> -->
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="comboItem.cartFreeTextModifiers.length > 0">
                  <div class="item-row" *ngFor="let cartModifier of comboItem.cartFreeTextModifiers">
                    <span *ngIf="cartModifier">{{cartModifier.cartModifier.name}}: {{cartModifier.value}}</span> <br>
                  </div>
                </ng-container>
              </div>
          </div>
        </div>
      </div>
    <!-- </ng-container> -->
  </div>
</div>

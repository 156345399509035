import {
  BaseComponent,
  Venue,
  FetchVenues,
  SetSelectedRoom,
  LocationDefaultState,
  CartState,
  FutureTimesModel,
  ClearFutureTimes,
  FetchFutureTimes,
  LocationState,
  DeliveryState
} from '@moduurnv2/libs-orderingapp/src/core';
import { Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../core/environments/environment';
import { Store, Select } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';

import { VenueManagementState } from '../../../core/state/venue-management.state';
import { Observable } from 'rxjs/internal/Observable';
import { TranslateService } from '@ngx-translate/core';
import {ModalService} from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
export class VenueRoomTimeSelectionBaseComponent extends BaseComponent
  implements OnInit {
  backButton: string =
    environment.image_basepath + environment.images.arrow_left_icon;
  closeButton: string =
    environment.image_basepath + environment.images.close_update_modal;
  selectedRoom: Venue = { name: '' };
  enteredRoom: string = '';
  showRoomList: boolean = false;
  requiredError: string = '';;
  dateValidationError: string = this.translate.instant('select-room-time');
  rooms: Venue[] = [];
  noRoomsSelectedError: boolean = false;
  allowRoomManualEntering: boolean = true;
  changeInVenueSelection: boolean = false;
  showDatePicker: boolean = false;
  @Output() closeEvent = new EventEmitter();
  @Select(VenueManagementState.getVenues) venues$: Observable<Venue[]>;
  @Select(CartState.getFutureDate) selectedFutureDate$ : Observable<any>;
  @Select(VenueManagementState.getSelectedRoom) selectedRoom$ : Observable<Venue>;
  @Select(CartState.getFutureTimes) futureTimes$: Observable<FutureTimesModel>;
  @Input() _modalClose: any;
  isInvalid:boolean = true;
  _data: any = null;
  isSubmitted: boolean = false;
  isClassRoomOrder: boolean = true;
  hideCloseButton:boolean = false;
  deliveryDetailsTxt : string = this.translate.instant('delivery-details');
  enterRoomTxt : string = this.translate.instant('enter-room');
  selectRoomNumberTxt : string = this.translate.instant('select-room-number') ;
  noRoomsTxt : string = this.translate.instant('no-rooms');
  whenTxt : string = this.translate.instant('when');
  continueAddrsTxt : string = this.translate.instant('Continue-adrs');
  openTimePicker: boolean = false;
  constructor(protected store: Store, public translate:TranslateService,private router:Router,private modal:ModalService) {
    super();
    this.populateInitialData();
    const selectedDelivery = this.store.selectSnapshot(DeliveryState.getSelectedDelivery);
    if(selectedDelivery?.text && this.translate?.currentLang == 'en'){
    this.selectRoomNumberTxt = 'Select a '+ selectedDelivery.text.split(' ')[0];
    this.noRoomsTxt = 'No ' + selectedDelivery.text.split(' ')[0] + ' available';
    this.enterRoomTxt = 'Enter ' + selectedDelivery.text.split(' ')[0] + ' number';
    }
  }

  ngOnInit() {
  }

  populateInitialData() {
    const locationDefaults = this.store.selectSnapshot(
      LocationDefaultState.getLocationDefaults
    );
    if (
      locationDefaults &&
      locationDefaults?.locationData?.orderSettings?.allowRoomEntering
    ) {
      this.allowRoomManualEntering =
        locationDefaults.locationData.orderSettings.allowRoomEntering;
      const selectedRoom = this.store.selectSnapshot(
        VenueManagementState.getSelectedRoom
      );
      if (selectedRoom) {
        this.enteredRoom = selectedRoom.name;
      }
    } else {
      this.allowRoomManualEntering = false;
    }
    if (!this.allowRoomManualEntering) {
      let filter = {
        tableType: 'TABLETYPE05',
      };
      this.store.dispatch(new FetchVenues(filter));
      this.venues$.pipe(takeUntil(this.destroy$)).subscribe((venues) => {
        if (venues && venues.length > 0) {
          this.rooms = venues;
            this.selectedRoom$.pipe(takeUntil(this.destroy$)).subscribe((selectedRoom) => {
              if(selectedRoom){
                this.onLoadRoomSelection(selectedRoom);
                if(this.router.url.split('/').pop() !== 'order' && selectedRoom.name){
                    this.showDatePicker = true;
                }
              }
            })
        } else {
          this.noRoomsSelectedError = true;
        }
        this.changeInVenueSelection=false;
      });
    }
    this.changeInVenueSelection=false;
    this.initBtnDisabling();
  }

  set data(data: any) {
    this._data = data;
    this.openTimePicker = data.openTimePicker ? data.openTimePicker : this.openTimePicker;
    this.hideCloseButton = (data.allowOverlayClick !== undefined && data.allowOverlayClick !== null) ? !data.allowOverlayClick : false;
  }

  openRoomSelectionList(event) {
    event.stopPropagation();
    event.preventDefault();
    this.showRoomList = !this.showRoomList;
  }

  onLoadRoomSelection(station, event?) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.requiredError = '';
    this.selectedRoom = station;
    this.showRoomList = false;
    if(this.router.url.split('/').pop() !== 'order' && this.selectedRoom.name){
      this.showDatePicker = true;
    }
    this.initBtnDisabling(this.selectedRoom);
  }

  roomSelection(station, event?) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.requiredError = '';
    this.selectedRoom = station;
    this.showRoomList = false;
    this.changeInVenueSelection = true;
    if(this.router.url.split('/').pop() !== 'order' && this.selectedRoom.name){
      this.showDatePicker = true;
    }
    this.continueBtnDisabling(this.selectedRoom);
  }

  proceed() {
    this.isSubmitted = true;
    if (this.allowRoomManualEntering && this.enteredRoom != '') {
      this.selectedRoom.name = this.enteredRoom;
    }
    if (this.selectedRoom && this.selectedRoom.name != '') {
      const selectedLocation = this.store.selectSnapshot(LocationState.getSelectedLocation);
      let allowAsap = selectedLocation?.orderSettings?.asapOrder.isAllow;
      let allowFuture =  selectedLocation?.orderSettings?.futureOrder?.isAllow;

      if(allowAsap && !allowFuture && this.selectedRoom && !this.selectedRoom.allowSchedule){
        this.store.dispatch(new SetSelectedRoom(this.selectedRoom));
        this.closeSelectionModal(true);
      } else
      if(this.isInvalid){
        // this.isSubmitted = false;
        return;
      } else {
        if(this.selectedRoom?.allowSchedule){
          this.store.dispatch(new ClearFutureTimes());
          const payload ={
            selectedVenue: this.selectedRoom,
            isClassRoomOrder: true
          }
          this.store.dispatch(new FetchFutureTimes(payload))
          this.futureTimes$
          .pipe(takeUntil(this.destroy$))
          .subscribe((response) => {
                if(this.isSubmitted && response){

                  if(response?.futureOrderOptions?.length > 0){
                    let selectedFutureDate = this.store.selectSnapshot(CartState.getFutureDate);
                    let selectedDateTimings = response.futureOrderOptions.find(shift=> new Date(shift.dateValue).getDate() == new Date(selectedFutureDate).getDate());
                    let dateExist= false;
                    let selectedFutureHour = new Date(selectedFutureDate).getHours();
                    let selectedFutureMinute = new Date(selectedFutureDate).getMinutes();
                    if(selectedDateTimings?.timeArray?.length){
                      for(let i = 0; i < selectedDateTimings.timeArray.length; i++) {
                          if (selectedDateTimings.timeArray[i].timeValue.hours == selectedFutureHour && selectedDateTimings.timeArray[i].timeValue.minutes == selectedFutureMinute) {
                            dateExist = true;
                              break;
                          }
                      }
                    }
                    if(dateExist){
                      this.store.dispatch(new SetSelectedRoom(this.selectedRoom));
                      this.closeSelectionModal(true);
                    } else {
                      this.isInvalid = true;
                      return;
                    }
                  }
                }
              },(error)=>{
                console.error(error);
              }
          );
        } else {
          this.store.dispatch(new SetSelectedRoom(this.selectedRoom));
          this.closeSelectionModal(true);
        }
      }
    } else {
      this.handleError();
    }
  }

  handleError() {
    const selectedDelivery = this.store.selectSnapshot(DeliveryState.getSelectedDelivery);
    // if (this.allowRoomManualEntering){
      this.requiredError = (selectedDelivery?.text && this.translate?.currentLang == 'en') ? 'Please select a '+ selectedDelivery.text.split(' ')[0] + ' to continue' :this.translate.instant('select-room');
    // }
    // else {
    //  this.requiredError = (selectedDelivery?.text && this.translate?.currentLang == 'en') ? 'Please select a '+ selectedDelivery.text.split(' ')[0] + ' to continue' :this.translate.instant('select-room');
    // }
  }

  initBtnDisabling(selectedRoom?){
    if(selectedRoom){
      if(this.changeInVenueSelection)
      this.isInvalid = true;
      else
      this.isInvalid = false;
      this.checkFuture();
    }
    else {
      if (!this.selectedRoom || !this.selectedRoom?.name) {
        this.isInvalid = true;
      }
    }

    if(this.allowRoomManualEntering){
      this.isInvalid = false;
    }


 }

 continueBtnDisabling(selectedRoom?){
    if(this.changeInVenueSelection)
    this.isInvalid = true;
    else
    this.isInvalid = false;
}


 checkFuture(){
    this.selectedFutureDate$.pipe(takeUntil(this.destroy$)).subscribe((futureDate) => {
      // if(futureDate){
        this.isInvalid = false;
      // }
      if(!futureDate && this.selectedRoom?.allowSchedule){
        this.changeInVenueSelection = true;
        this.isInvalid = true;
      }
    })
  }

  dateSelectedEvent(isSelected){
    if(isSelected){
      this.isInvalid = false;
      if (this.selectedRoom && this.selectedRoom.name != '') {
        this.store.dispatch(new SetSelectedRoom(this.selectedRoom));
      }
      this.changeInVenueSelection = false;
    }
  }

  
  closeSelectionModal(data?) {
    this.isSubmitted = false;
    if (this._modalClose) this._modalClose(data);
  }

  set modalClose(modalRef: any) {
    this._modalClose = (data) => {
      this.modal.closeComponentModal(modalRef, data);
    };
  }

}

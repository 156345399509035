<div>
<ng-container *ngIf="isMobileView;else isDesktopView">
  <ng-container *ngIf="pageToShow == 'ADDRESS_LIST'">
    <moduurnv2-address-list (closeNewScreen)="closeCurrentComponent($event)" [fromPage]="fromPage"></moduurnv2-address-list>
  </ng-container>
  <ng-container *ngIf="pageToShow == 'ADDRESS_DETAILS'">
    <moduurnv2-address-details (closeNewScreen)="closeCurrentComponent($event)" [fromPage]="fromPage"></moduurnv2-address-details>
  </ng-container>
  <ng-container *ngIf="pageToShow == 'ADDRESS_MAP'">
    <moduurnv2-address-map (closeNewScreen)="closeCurrentComponent($event)" [fromPage]="fromPage"></moduurnv2-address-map>
  </ng-container>
</ng-container>
<ng-template #isDesktopView>
  <ng-container *ngIf="pageToShow == 'ADDRESS_LIST'">
    <moduurnv2-address-list-desktop (closeNewScreen)="closeCurrentComponent($event)" [isAddressBook]="isAddressBook" [fromPage]="fromPage"></moduurnv2-address-list-desktop>
  </ng-container>
  <ng-container *ngIf="pageToShow == 'ADDRESS_DETAILS'">
    <moduurnv2-address-details-desktop (closeNewScreen)="closeCurrentComponent($event)" [isAddressBook]="isAddressBook" [fromPage]="fromPage"></moduurnv2-address-details-desktop>
  </ng-container>
  <ng-container *ngIf="pageToShow == 'ADDRESS_MAP'">
    <moduurnv2-address-map-desktop (closeNewScreen)="closeCurrentComponent($event)" [isAddressBook]="isAddressBook" [fromPage]="fromPage"></moduurnv2-address-map-desktop>
  </ng-container>
</ng-template>

</div>
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  SharedService,
  GiftAndLoyaltyCardService,
  SweetAlertService,
} from '@moduurnv2/libs-orderingapp/src/core';
import { GiftCardBaseComponent } from '@moduurnv2/libs-orderingapp/src';

import { FormBuilder } from '@angular/forms';
import { GiftLoyaltyCardComponent } from '../card-details/gift-loyalty-card.component';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'moduurnv2-resend-email-verification',
  templateUrl: './resend-email-verification.component.html',
  styleUrls: ['./resend-email-verification.component.scss'],
})
export class ResendEmailVerificationComponent extends GiftCardBaseComponent {
  constructor(
    store: Store,
    router: Router,
    _shared: SharedService,
    fb: FormBuilder,
    loyaltyService: GiftAndLoyaltyCardService,
    modal: ModalService,
    public sweetAlert: SweetAlertService,
    public translate: TranslateService
  ) {
    super(fb, store, router, _shared, loyaltyService, modal, sweetAlert,translate);
  }
  giftLoyaltyCard = this.translate.instant('gift-loyalty-card');
  giftCardVerify = this.translate.instant('gift-card-verification');
  checkEmail = this.translate.instant('check-email');
  noReceiveEmail = this.translate.instant('no-recieve-email');
  resend = this.translate.instant('Resend');
  finish= this.translate.instant('finish');

  onFinish() {
    this.confirmEmailVerification(GiftLoyaltyCardComponent);

  }
}

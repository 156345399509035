<div class="row">
  <div class="header-container">
    <div
      class="head-icon-outer"
      data-qa="close-room-popup"
      aria-label="close"
      (click)="closeSelectionModal()"
      *ngIf="!hideCloseButton" 
    >
      <img [src]="backButton" class="left-icon" alt="back button"/>
    </div>
    <span class="header">{{ 'delivery-details' | translate }}</span>
  </div>
  <div class="action-outer-container">
    <label for="location">{{ 'delivery-location' | translate }}</label>
    <ng-container
      *ngIf="
        allowRoomManualEntering;
        then roomManualEntryTemplate;
        else roomSelectionTemplate
      "
    ></ng-container>
    <ng-template #roomManualEntryTemplate>
      <input
        type="text"
        placeholder="{{ enterRoomTxt }}"
        class="manual-entry"
        [(ngModel)]="enteredRoom"
      />
    </ng-template>
    <ng-template #roomSelectionTemplate>
      <ng-container *ngIf="rooms; else noRoomsAvailable">
        <div class="custom-selected">
          <div class="selected">
            <div
              class="selected-text"
              (click)="openRoomSelectionList($event)"
              id="location"
            >
              <ng-container
                *ngIf="!showRoomList && selectedRoom?.name; else noSelection"
              >
                <div class="text">{{ selectedRoom?.name }}</div>
              </ng-container>
              <ng-template #noSelection>
                <div class="placeholder-text">
                  {{ selectRoomNumberTxt }}
                </div>
              </ng-template>
              <img class="image" src="assets/images/Chevron_Down.svg" alt="Chevron Down" />
            </div>
            <div class="selection-list" [ngClass]="{ show: showRoomList }">
              <div
                class="selection-item"
                *ngFor="let room of rooms"
                (click)="roomSelection(room, $event)"
              >
                {{ room.name }}
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #noRoomsAvailable>
        {{ noRoomsTxt }}
      </ng-template>
    </ng-template>
    <div *ngIf="requiredError" class="error" aria-label="error">
      {{ requiredError }}
    </div>
    <ng-container *ngIf="showDatePicker || allowRoomManualEntering">
      <label for="time" class="time-heading" aria-label="when">{{ whenTxt }}</label>
      <moduurnv2-inline-plain-time-picker [venueChanged]="changeInVenueSelection" [selectedVenue]="selectedRoom" [isClassRoomOrder]="isClassRoomOrder" (dateSelectedEvent)="dateSelectedEvent($event)" [validationError]="isInvalid && isSubmitted" [openTimePicker]="openTimePicker"></moduurnv2-inline-plain-time-picker>
      <div *ngIf="isInvalid && isSubmitted" class="error" aria-label="error">
        {{ dateValidationError }}
      </div>
    </ng-container>
  </div>
  <!-- <button
    class="block-button proceed-btn"
    [class.disabled]="isInvalid"
    (click)="proceed()"
    data-qa="continue"
    aria-label="continue"
    [disabled]="isInvalid"
  > -->
  <button
  class="block-button proceed-btn"
  (click)="proceed()"
  data-qa="continue"
  aria-label="continue"
  >
    {{ 'Continue-adrs' | translate }}
  </button>
</div>

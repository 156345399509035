import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthErrorEvent } from 'angular-oauth2-oidc';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
  throwError,
} from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import {
  ClearLoginDetails,
  environment,
  setLoggedInUserDetails,
} from '@moduurnv2/libs-orderingapp/src';
import { Store } from '@ngxs/store';
import { ExtendedAuthService } from './extended-oAuth.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest(
    this.isAuthenticated$,
    this.isDoneLoading$
  ).pipe(map((values) => values.every((b) => b)));

  private navigateToLoginPage() {
    // TODO: Remember current URL
    console.log('navigete to home');
    this.router.navigateByUrl('locations');
  }

  constructor(
    private oauthService: ExtendedAuthService,
    private configService: ConfigService,
    private http: HttpClient,
    private router: Router,
    private store: Store
  ) {
    // Useful for debugging:
    this.oauthService.events.subscribe((event) => {
      if (event instanceof OAuthErrorEvent) {
        console.error(event);
      } else {
        console.warn(event);
      }
    });

    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup.
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      console.warn(
        'Noticed changes to access_token (most likely from another tab), updating isAuthenticated'
      );
      this.isAuthenticatedSubject$.next(
        this.oauthService.hasValidAccessToken()
      );

      if (!this.oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });

    this.oauthService.events.subscribe((_) => {
      this.isAuthenticatedSubject$.next(
        this.oauthService.hasValidAccessToken()
      );
    });

    this.oauthService.events
      .pipe(filter((e) => ['token_received'].includes(e.type)))
      .subscribe((e) => this.oauthService.loadUserProfile());

    this.oauthService.events
      .pipe(
        filter((e) => ['session_terminated', 'session_error'].includes(e.type))
      )
      .subscribe((e) => this.navigateToLoginPage());

    this.oauthService.setupAutomaticSilentRefresh();

  }
  public async getDeviceType(){
    return await this.oauthService.getDeviceType();
  }
  public runInitialLoginSequence(): Promise<void> {
    if (location.hash) {
      console.log('Encountered hash fragment, plotting as table...');
      console.table(
        location.hash
          .substr(1)
          .split('&')
          .map((kvp) => kvp.split('='))
      );
    }
    return this.oauthService
      .loadDiscoveryDocument()
      .then(() => this.oauthService.tryLogin())

      .then(() => {
        if (this.oauthService.hasValidAccessToken()) {
          return Promise.resolve();
        }
        return this.refresh()
          .then(() => Promise.resolve())
          .catch((result) => {
            if (this.checkUserInteractionRequiredOnRefreshFailure(result)) {
              if (this.configService.autoLogin) {
                console.log('Forcing user to log in');
                this.login();
              } else {
                console.warn(
                  'User interaction is needed to log in, we will wait for the user to manually log in.'
                );
              }
              return Promise.resolve();
            }
            return Promise.reject(result);
          });
      })

      .then(() => {
        this.isDoneLoadingSubject$.next(true);

        if (
          this.oauthService.state &&
          this.oauthService.state !== 'undefined' &&
          this.oauthService.state !== 'null'
        ) {
          console.log(
            'There was state, so we are sending you to: ' +
              this.oauthService.state
          );

          this.createLoginUser(this.oauthService.state);
          // this.router.navigateByUrl();
        }
      })
      .catch(() => this.isDoneLoadingSubject$.next(true));
  }

  private checkUserInteractionRequiredOnRefreshFailure(result: any): boolean {
    const errorCodes = [
      // OAuth2 error codes
      // See RFC https://tools.ietf.org/html/rfc6749#section-5.2
      'invalid_grant',

      // OIDC error codes
      // See https://openid.net/specs/openid-connect-core-1_0.html#AuthError
      'interaction_required',
      'login_required',
      'account_selection_required',
      'consent_required',
    ];

    // Notice that implicit and code flows return errors in different ways
    const k = this.oauthService.responseType === 'code' ? 'error' : 'reason';

    return result && result[k] && errorCodes.indexOf(result[k].error) >= 0;
  }

  public login(targetUrl?: string) {
    this.oauthService.initLoginFlow(targetUrl);
  }

  public logout() {
    if (this.configService.revokeTokenOnLogout) {
      const token = this.oauthService.getAccessToken(); // Get token before logging out which clears the token
      this.revokeToken(token);
    }

    this.oauthService.logOut();
  }

  public refresh(): Promise<object> {
    return this.oauthService.responseType === 'code'
      ? this.oauthService.refreshToken()
      : this.oauthService.silentRefresh();
  }

  public hasValidToken() {
    return this.oauthService.hasValidAccessToken();
  }

  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public get accessToken() {
    return this.oauthService.getAccessToken();
  }
  public get refreshToken() {
    return this.oauthService.getRefreshToken();
  }
  public get identityClaims() {
    return this.oauthService.getIdentityClaims();
  }
  public get idToken() {
    return this.oauthService.getIdToken();
  }
  public get logoutUrl() {
    return this.oauthService.logoutUrl;
  }

  // Revoke access token
  // Notice that WSO2 IS 5.8.0 automatically revokes the associated refresh token
  // (check response headers of access token revocation) which looks very reasonable.
  private revokeToken(token: string) {
    console.log('Revoking token = ' + token);
    const revocationUrl = this.oauthService.tokenEndpoint.replace(
      /\/token$/,
      '/revoke'
    );
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('token', token);
    urlSearchParams.append('token_type_hint', 'access_token');
    urlSearchParams.append('client_id', this.oauthService.clientId);
    this.http
      .post(revocationUrl, urlSearchParams.toString(), { headers })
      .subscribe(
        (result) => {
          console.log(
            'Access token and related refresh token (if any) have been successfully revoked'
          );
        },
        (error) => {
          console.error('Something went wrong on token revocation');
          //this.oidcSecurityService.handleError(error);
          return throwError(error);
        }
      );
  }

  createLoginUser(state) {
    const claims = this.oauthService.getIdentityClaims() as {
      email: string;
      name: string;
    };
    if (claims && claims.email && claims.name) {
      const userLoginObject = {
        email: claims.email,
        name: claims.name,
        organizationId: Config.organizationId,
        cart_id: localStorage.getItem('cartId'),
      };
      const url = environment.federatedApi + 'azure/login';
      this.store.dispatch(new ClearLoginDetails());
      this.http.post(url, userLoginObject).subscribe((response) => {
        const responseData = response as any;
        if (response && responseData.user_id) {
          const combined = {
            ...responseData,
            ...this.oauthService.getIdentityClaims(),
            access_token: this.accessToken,
          };

          // localStorage.setItem("userInfo", JSON.stringify(combined));
          // localStorage.setItem("userData", JSON.stringify(combined));
          // localStorage.setItem("username", combined.fname + ' ' + combined.lname);
          // localStorage.setItem("isLoggedIn", 'true');
          // console.log('saved data');
          this.store.dispatch(new setLoggedInUserDetails(combined));
        }
        if (state) this.router.navigateByUrl(state);
      });
      // if (state) this.router.navigateByUrl(state);
    } else if (state) this.router.navigateByUrl(state);
  }
}

<div
  class="giftloyalty-login-container"
  *ngIf="(!isShowAuth && isMobileView) || !isMobileView"
>
  <div class="banner-text" *ngIf="isMobileView">
    {{ 'gift-loyalty-card' | translate }}
  </div>
  <div class="giftloyalty-icon">
    <img *ngIf="isMobileView" src="/assets/images/Gift-Loyalty-Icon.svg" alt="gift loyalty"/>
    <img *ngIf="!isMobileView" src="/assets/images/Gift-Card-Icon_Big.svg" alt="gift card"/>
  </div>
  <div class="header">
    {{ 'gift-loyalty-missing' | translate }}
  </div>
  <div class="sub-header">
    {{ 'gift-loyalty-login' | translate }}
  </div>
  <div class="row signup-tag-container">
    
    <span
      >{{ 'dont-have-account' | translate }}
      <button
        class="signup-tag"
        data-qa="sign-up"
        type="button"
        (click)="register()"
        attr.aria-label="{{ 'signup' | translate }}"
      >
        {{ 'signup' | translate }}
      </button></span
    >
    <div class="terms-conditions" [ngClass]="{ 'error' : isSignupClicked && !setTerms }">
      <div class="error-text">
        <label *ngIf="isSignupClicked && !setTerms">
          {{ 'terms-conditions-error' | translate }}</label
        >
      </div>
      <div class="login-text options">
        <label class="provider-subheading user-login options-label">
          {{ 'disclaimer-text' | translate }}
          <span class="primary-color">
            <a target="blank" href="https://moduurn.com/privacy-policy">
              {{ 'terms-conditions' | translate }}
            </a>
          </span>
          <input type="checkbox" [ngModel]="setTerms" (click)="changeTerms($event)" [ngClass]="{ selected: setTerms }" />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
  </div>
  <div class="row sign-in-button-container">
    <button
      class="btn-submit"
      (click)="continue()"
      data-qa="continue-step"
    >
      {{ 'login' | translate }}
    </button>
  </div>
</div>
<div *ngIf="isMobileView">
  <moduurnv2-email-auth
    class="login-popup"
    *ngIf="pageToShow && isShowAuth"
    [pageToShow]="pageToShow"
    [loginFrom]="'giftAndLoyalty'"
    (showEmailAuthModal)="showEmailAuthModal($event)"
  ></moduurnv2-email-auth>
</div>

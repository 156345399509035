import {
  BaseComponent,
  MenuListState,
  MenuItemList,
  SetMenuItem,
  environment,
  LocationState
} from '@moduurnv2/libs-orderingapp/src/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil } from 'rxjs/operators';
import { Input, OnInit } from '@angular/core';  
import { ActivatedRoute } from '@angular/router';
// import { ,Location} from "@moduurnv2/libs-orderingapp/src/core";

export class ItemDetailsBaseComponent extends BaseComponent implements OnInit {
  @Input('item') item;
  @Input('itemInComboGroup') itemInComboGroup;
  @Select(MenuListState.getSelectedMenuItem)
  public selectedMenuItem$: Observable<MenuItemList>;
  @Select(LocationState.getLocationStatus)locationStatus$:Observable<any>
  selected: boolean = false;
  customScroll: boolean = true;
  isShowCalorieDetails:boolean;
  isHideDescription:boolean;

  constructor(public store: Store, public route: ActivatedRoute) {
    super();       
  }
  ngOnInit() {           
    this.locationStatus$.subscribe(data=>{           
      this.isShowCalorieDetails=data.activeProfile.showCalorieInformation;
      this.isHideDescription=data.activeProfile.hideDescription;
    })    
    this.selectedMenuItem$
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.selected = response && response._id === this.item._id;
      });
      
  }

  ngOnDestroy(){
    this.selected = false;
    this.store.dispatch(new SetMenuItem(null));
  }

  getImageURL(icon) {
    return `assets/images/${icon.filename}.svg`;
  }

  showDetail(event) {
    this.customScroll = false;
    let dispatchData = this.item;
    if (this.selected) dispatchData = null;
    this.store.dispatch(new SetMenuItem(dispatchData));
  }

  serverURL(imageURL) {
    if (this.selected && this.item && this.item._id && this.customScroll) {
      var element = document.getElementById(this.item._id);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        this.customScroll = false;
      }
    }
    return `${environment.imagebaseurl}${imageURL}`;
  }

  get descText() {
    if (this.selected) return this.item.description;
    else return this.item.description;
  }
}

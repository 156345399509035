import { LocationSelectionAnalytics, 
         AnalyticsTrackingType, 
         ServiceTypeSelectionAnalytics, 
         ItemCheckoutAnalytics } from '../models';

export class setAnalyticsData {
  static readonly type = '[AnalyticsTracking] AnalyticsTrackingData';

  constructor(public payload?) {}
}

// export class setLocationSelectionAnalytics {
//   static readonly type = '[AnalyticsTracking] LocationSelectionAnalytics';

//   constructor(public payload?: LocationSelectionAnalytics) {}
// }
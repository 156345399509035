<ng-container
  *ngIf="isMobileView; then mobileView; else desktopView"
></ng-container>
<ng-template #mobileView>
  <moduurnv2-modifier
    [data]="dataPassedToModal"
    [_modalClose]="_modalClose"
  ></moduurnv2-modifier>
</ng-template>
<ng-template #desktopView>
  <moduurnv2-modifier-desktop
    [data]="dataPassedToModal"
    [_modalClose]="_modalClose"
  ></moduurnv2-modifier-desktop>
</ng-template>

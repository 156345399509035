import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve,Router } from '@angular/router';
import { Observable } from 'rxjs';

import { Store } from '@ngxs/store';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { CheckResetLinkValid,LocationState} from '@moduurnv2/libs-orderingapp/src/core';
@Injectable()
export class EmailResetResolver implements Resolve<any> {

  isEncrypted: boolean = false;
  encrypted;

  constructor(private store: Store,private router:Router) { }

  ngOnInit() { }

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<any> | any {
    let routeData = route.queryParams;
    let keyArray = Object.keys(routeData) || [];
    if (keyArray.indexOf('username') == -1) {
      if (routeData && keyArray.length > 0) {
        this.encrypted = keyArray[0];
        this.isEncrypted = true;
      }
    } else {
      this.encrypted = routeData.username;
      this.isEncrypted = false;
    }
    if(this.encrypted){
      let request = { username: encodeURIComponent(this.encrypted), encrypted: this.isEncrypted, organizationId: Config.organizationId };
      this.store.dispatch(new CheckResetLinkValid(request));
    } else {
      const selectedLocation = this.store.selectSnapshot(
        LocationState.getSelectedLocation
      );
      if(selectedLocation._id){
        this.router.navigate([`/locations/${selectedLocation._id}/home`]);
      } else {
        this.router.navigate([`/locations`]);
      }
    }
  }
}

// angular
import { Injectable } from '@angular/core';

// app
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { CustomerFavourites } from '../models';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CustomerFavouritesService {
  private CustomerFavouritesinit: CustomerFavourites[] = [
    {
      _id: '1',
      name: 'Spicy Pumpkin Soup',
      price: '8.95',
      image: 'assets/images/cust-fav-1.png',
    },
    {
      _id: '2',
      name: 'Shrimp and Chicken',
      price: '12.49',
      image: 'assets/images/cust-fav-2.png',
    },
    {
      _id: '3',
      name: 'Spicy Pumpkin Soup',
      price: '8.95',
      image: 'assets/images/cust-fav-1.png',
    },
    {
      _id: '4',
      name: 'Shrimp and Chicken',
      price: '12.49',
      image: 'assets/images/cust-fav-2.png',
    },
  ];
  constructor(private http: HttpClient) {}

  fetchCustomerFavourites(organizationId?: string): Observable<any> {
    return of(this.CustomerFavouritesinit).pipe();
  }

  updateCustomerFavourite(customerId,favPayLoad): Observable<any> {
    const url = `${environment.customerApiv3Url}customers/${customerId}/locationFavorite`;
    return this.http.post(url,favPayLoad);
  }
}

<div class="footer-container">
  <div class="footer-wrapper">
    <ul class="nav">
      <!-- <li class="item">{{ 'About Us' | translate }}</li> -->
      <li class="item">
        <span class="item-head">{{ 'Customer Support' | translate }}:</span>
        <ul class="support-opt">
          <li>
            {{ 'tech-and-payment' | translate }} (<a
              class="normal-text"
              href="mailto:support@moduurn.com"
              >support@moduurn.com</a
            >
            &
            <a class="action-text call" href="tel:1-855-MODUURN"
              >1-855-MODUURN</a
            >
            ext 2)
            <a (click)="startWebChat()" target="_blank">
              <img class="icon-chat" src="assets/images/chat-support-icon.svg" />
            </a>
          </li>
          <li *ngIf="feedbackEmail">
            {{ 'food-issues' | translate }} (<a
              class="normal-text"
              href="mailto:{{ feedbackEmail }}"
              >{{ feedbackEmail }}</a
            >)
          </li>
          <li *ngIf="deliveryPartners && deliveryPartners.length > 0">
            {{ 'delivery' | translate }}
            (<ng-container
              *ngFor="let partner of deliveryPartners; let i = index"
              ><a
                class="action-text call"
                href="{{ 'tel:' + partner.phoneNumber }}"
                >{{ partner.phoneNumber }}</a
              >
              <ng-container *ngIf="i != deliveryPartners.length - 1">
                ,
              </ng-container> </ng-container
            >)
          </li>
        </ul>
      </li>
      <!-- <li class="item">{{ 'Privacy Policy' | translate }}</li>
      <li class="item">{{ 'Terms of Service' | translate }}</li> -->
    </ul>
    <div class="nav-bottom">
      <div class="moduurn">
        <div>{{ 'Powered by' | translate }}</div>
        <a (click)="navigateToModuurn()" target="_blank">
          <img class="logo" src="assets/images/moduurn-logo-red.png" alt="moduurn icon"
        /></a>(Version {{ appVersion }})
      </div>
      <div class="items-wrapper" id="footer">
        <ng-container *ngIf="checkWeb">
          <a [href]="appStoreLink" target="_blank" *ngIf="appStoreLink">
          <img class="button" src="assets/images/App_Store.svg" alt="app store"/>
          </a>
          <a [href]="playStoreLink" target="_blank" *ngIf="playStoreLink">
          <img class="button" src="assets/images/Play_Store.svg" alt="play store"/>
          </a>
        </ng-container>
        <a [href]="instagramLink" target="_blank" *ngIf="instagramLink">
          <img class="icon" src="assets/images/Instagram_logo.svg" alt="instagram icon"/>
        </a>
        <a [href]="twitterLink" target="_blank" *ngIf="twitterLink">
          <img class="icon" src="assets/images/Twitter_Logo.svg" alt="twitter icon"/>
        </a>
        <a [href]="facebookLink" target="_blank" *ngIf="facebookLink">
          <img class="icon" src="assets/images/Facebook_Logo.svg" alt="facebook icon"/>
        </a>
      
        
  
      </div>
    </div>
  </div>
</div>

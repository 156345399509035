import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import {
  SharedService,
  GiftAndLoyaltyCardService,
  SweetAlertService,
  PaymentMethodState,
  FetchMealCodePayment,
  OrderState,
} from '@moduurnv2/libs-orderingapp/src/core';
import { StudentMealBaseComponent } from '@moduurnv2/libs-orderingapp/src';

import { FormBuilder } from '@angular/forms';

import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'moduurnv2-meal-program-details',
  templateUrl: './meal-program-details.component.html',
  styleUrls: ['./meal-program-details.component.scss'],
})
export class MealProgramDetailsComponent extends StudentMealBaseComponent {
  mealCodePayment: any = false;
  @Select(OrderState.mealCodePayment) mealCodePayment$;
  mealCodePaymentSubscription : Subscription;

  constructor(
    store: Store,
    router: Router,
    _shared: SharedService,
    fb: FormBuilder,
    loyaltyService: GiftAndLoyaltyCardService,
    modal: ModalService,
    public sweetAlert: SweetAlertService,
    public translate: TranslateService
  ) {
    super(
      fb,
      store,
      router,
      _shared,
      loyaltyService,
      modal,
      sweetAlert,
      translate
    );
    this.fetchMealCodeCart();
  }

  continueDesktop() {
    super.continue().subscribe(response => {

      const mealDetails = this.store.selectSnapshot(PaymentMethodState.getMealPaymentDetails);
      if(!mealDetails) {
        this.sweetAlert.toast('error', 'Incorrect meal code');
        return;
      }

      if(this.isMobileView) {
        this.navigateToReviewAndPay()
      } else {
        this.closePopup();
      }


    });   
  }

  fetchMealCodeCart() {
    this.mealCodePayment = 'loading';
    this.mealCodePaymentSubscription = this.mealCodePayment$.subscribe( payment => {
        this.mealCodePayment = payment;
    });
    this.store.dispatch(new FetchMealCodePayment())
      .subscribe( cartData => {
        this.mealCodePaymentSubscription.unsubscribe();
      }, err => {
        this.mealCodePaymentSubscription.unsubscribe();
      });
  }
}

<!-- Modifier wrapper -->
<div
  class="modifier-wrapper"
  *ngIf="selectedMenuItem$ | async as selectedMenuItem; else modifierLoader"
>
  <!-- image container -->
  <ng-container *ngIf="details$ | async as itemDetail">
    <div class="item-name" [attr.data-qa]="selectedMenuItem?.name">
      {{ itemDetail.name | formattedLanguage: itemDetail.translation }}
    </div>
    <img
      class="closebtn"
      [src]="close"
      data-qa="close-button"
      aria-label="close"
      (click)="closePopup()"
      alt="close"
    />
    <!-- <div class="item-image" [bg-image]="imageBase + itemDetail.imageURL">
      <button class="close-button" (click)="closePopup()">
        <img [src]="close" data-qa="close-button" aria-label="close" alt="close"/>
      </button>
    </div> -->

    <!-- modifier details -->
    <div class="item-details" [attr.data-qa]="selectedMenuItem?._id">
      <!-- <div class="item1">dfd</div> -->
      <div class="left-pane">
        <div
          class="item-image"
          [class]="itemDetail?.imageURL ? '' : 'hide'"
          [bg-image]="imageBase + itemDetail.imageURL"
        ></div>
        <div class="item-icons" [class]="itemDetail?.icons ? '' : 'hide'">
          <moduurnv2-icon-list
            [icons]="itemDetail?.icons"
            showText="true"
          ></moduurnv2-icon-list>
        </div>
        <div
          class="item-description-head"
          [class]="itemDetail?.description ? '' : 'hide'" 
          *ngIf="!isHideDescription"
        >
          {{ 'description' | translate }}
        </div>
        <div
          class="item-description"
          *ngIf="itemDetail?.description && !isHideDescription"
          [class]="itemDetail?.description ? '' : 'hide'"
        >
          {{
            itemDetail.description
              | formattedLanguage: itemDetail.translation:false
          }}
        </div>
        <div class="item-description-head" [class]="isShowCalorieDetails ? (itemDetail?.minCalorie || itemDetail?.maxCalorie )? '' : 'hide':'hide'">
         {{ 'Calorie Information' | translate}}
        </div>
        <div class="item-description" style="padding-left:8px" *ngIf="isShowCalorieDetails" >
          <ng-container *ngIf="itemDetail.minCalorie && itemDetail.maxCalorie" >
            <span *ngIf="itemDetail.minCalorie" >{{itemDetail.minCalorie}}</span>
            <span *ngIf="itemDetail.maxCalorie"> - {{itemDetail.maxCalorie}} Kcal</span> 
          </ng-container>
          <ng-container *ngIf="itemDetail.minCalorie && !itemDetail.maxCalorie" >
              <span>{{itemDetail.minCalorie}} Kcal</span>
          </ng-container>
          <ng-container *ngIf="itemDetail.maxCalorie && !itemDetail.minCalorie" >
              <span>{{itemDetail.maxCalorie}} Kcal</span>
          </ng-container>

          <!-- <span *ngIf="item.minCalorie" >{{item.minCalorie}}</span>
          <span *ngIf="item.maxCalorie">{{item.maxCalorie}}</span> -->
        </div>
      </div>
      <div class="right-pane custom-scroll"  id="modiScroll" (scroll)="displayModiScroll()" >
        <!-- Item options list -->
        <div class="option-list" *ngIf="selectedMenuItem?.portions" id="modifierContentBox" >
          <!-- Portion -->
          <div
            class="option-item accordion-modifier"
            *ngIf="
              selectedMenuItem.portions && selectedMenuItem.portions.length > 0
            "
          >
            <input id="tab-modifier-01" type="checkbox" checked (click)="scrollOnTop('01')"/>
            <div class="box" id="tb-01" >
              <label for="tab-modifier-01">
                <div class="option-header box-title">
                  <div class="option-heading accordion-modifier-head">
                    {{
                      selectedMenuItem.portionActionCaption
                        | formattedLanguage: selectedMenuItem.captiontranslation
                    }}
                  </div>
                  <div class="option-requirements accordion-modifier-req">
                    {{ 'select-option' | translate }} ({{
                      'required' | translate
                    }})
                  </div>
                </div>
                <img
                  src="assets/images/Bullet-Chevron-Down.svg"
                  class="imgicon"
                  alt="down"
                />
              </label>

              <p class="tick-mark" *ngIf="portionCheck === true"></p>
              <div class="box-content">
                <moduurnv2-portion-list
                  [portions]="selectedMenuItem.portions" [isLoyaltyEnabled]="isLoyaltyEnabled(selectedMenuItem)"
                ></moduurnv2-portion-list>
              </div>
            </div>
            <input type="radio" name="accordion" id="acc-close" />
          </div>
          <!-- {{modifierslist | json}} -->
          <!-- Modifiers -->

          <ng-container
            *ngIf="
              selectedMenuItem?.modifiers?.length > 0 ||
                selectedMenuItem?.portions?.length > 0;
              else noModifiers
            "
          >
            <ng-container
              *ngFor="
                let modifier of selectedMenuItem?.modifiers;
                let i = index
              "
            >
              <div
                class="option-item accordion-modifier"
                [attr.data-qa]="modifier._id"
              >
                <input
                  id="tab-modifier-{{ i }}"
                  name="accordion"
                  type="radio"
                  *ngIf="
                    selectedMenuItem.portions &&
                    selectedMenuItem.portions.length > 0
                  " (click)="scrollOnTop(i)"
                />
                <input
                  id="tab-modifier-{{ i }}"
                  name="accordion"
                  type="radio"
                  *ngIf="selectedMenuItem.portions.length == 0 && i == 0"
                  checked (click)="scrollOnTop(i)"
                />
                <input
                  id="tab-modifier-{{ i }}"
                  name="accordion"
                  type="radio"
                  *ngIf="selectedMenuItem.portions.length == 0 && i != 0" (click)="scrollOnTop(i)"
                />
                <div class="box" id="tb-{{ i }}">
                  <label
                    class="box-title"
                    for="tab-modifier-{{ i }}"
                    [ngClass]="{
                      'label-error': headerErrors(modifier.valid, modifier._id)
                    }"
                  >
                    <div class="option-header" [id]="modifier._id">
                      <div
                        class="option-heading accordion-modifier-head"
                        [ngClass]="{
                          'head-error': headerErrors(
                            modifier.valid,
                            modifier._id
                          )
                        }"
                      >
                        {{ getActionCaption(modifier,"modifier") }}
                      </div>

                      <div class="option-requirements accordion-modifier-req">
                        {{ choiceText(modifier) }}
                      </div>
                    </div>
                    <img
                      src="assets/images/Bullet-Chevron-Down.svg"
                      class="imgicon"
                      alt="down"
                    />
                  </label>

                  <label class="box-close" for="acc-close"></label>
                  {{ getModifierSelectedValue(modifier._id) }}
                  <p
                    class="tick-mark"
                    *ngIf="
                      modifier.valid && selectedModifier?._id == modifier._id
                    "
                  ></p>

                  <div class="box-content">
                    <ng-container
                      *ngFor="let modifierItem of modifier?.modifierItems"
                    >
                      <moduurnv2-modifier-list
                        [modifierItem]="modifierItem"
                        [modifier]="modifier"
                        [isLoyaltyEnabled]="isLoyaltyEnabled(selectedMenuItem)"
                      >
                      </moduurnv2-modifier-list>
                    </ng-container>
                  </div>
                </div>
                <input type="radio" name="accordion" id="acc-close" />
              </div>
            </ng-container>
          </ng-container>
          <ng-container
          *ngIf="
            selectedMenuItem?.freeTextModifiers?.length > 0 ||
              selectedMenuItem?.freeTextModifiers?.length > 0;
            else noModifiers
          "
        >
          <ng-container
          *ngFor="
            let modifier of selectedMenuItem?.freeTextModifiers;
            let i = index
          "
        >
          <div
            class="option-item accordion-modifier"
            [attr.data-qa]="modifier._id"
          >
            <input
              id="tab-freetext-modifier-{{ i + selectedMenuItem?.modifiers?.length }}"
              name="accordion"
              type="radio"
              *ngIf="
                selectedMenuItem.portions &&
                selectedMenuItem.portions.length > 0
              "
            />
            <input
              id="tab-freetext-modifier-{{ i + selectedMenuItem?.modifiers?.length }}"
              name="accordion"
              type="radio"
              *ngIf="selectedMenuItem.portions.length == 0 && (i + selectedMenuItem?.modifiers?.length) == 0"
              checked
            />
            <input
              id="tab-freetext-modifier-{{ i + selectedMenuItem?.modifiers?.length }}"
              name="accordion"
              type="radio"
              *ngIf="selectedMenuItem.portions.length == 0 && (i + selectedMenuItem?.modifiers?.length) != 0"
            />
            <div class="box">
              <label
                class="box-title"
                for="tab-freetext-modifier-{{ i + selectedMenuItem?.modifiers?.length}}"
                [ngClass]="{
                  'label-error': headerErrorsFreeTextModifier(modifier, modifier._id)
                }"
              >
                <div class="option-header" [id]="modifier._id">
                  <div
                    class="option-heading accordion-modifier-head"
                    [ngClass]="{
                      'head-error': headerErrorsFreeTextModifier(
                        modifier,
                        modifier._id
                      )
                    }"
                  >
                  
                    {{ getActionCaption(modifier,"freeText") }}
                  </div>

                  <div class="option-requirements accordion-modifier-req">
                    {{ choiceTextFreeTextModifier(modifier) }}
                  </div>
                </div>
                <img
                  src="assets/images/Bullet-Chevron-Down.svg"
                  class="imgicon"
                />
              </label>

              <label class="box-close" for="acc-close"></label>
              {{ getFreeTextModifierSelectedValue(modifier._id) }}
              <p
                class="tick-mark"
                *ngIf="
                modifier?.value?.trim().length > 0
                "
              ></p>

              <div class="box-content">
                <!-- <ng-container
                  *ngFor="let modifierItem of modifier?.modifierItems"
                >
                  <moduurnv2-modifier-list
                    [modifierItem]="modifierItem"
                    [modifier]="modifier"
                  >
                  </moduurnv2-modifier-list>
                </ng-container> -->
                <textarea
                class="text-area"
                rows="4"
                custom-autofocus
                tabindex="1"
                [(ngModel)] ="modifier.value"
                (ngModelChange)="setModifierValue()"
                [placeholder]=""
                maxlength="280"
                ></textarea>

              </div>
            </div>
            <input type="radio" name="accordion" id="acc-close" />
          </div>
        </ng-container>
        </ng-container>
          <ng-template #noModifiers>
            <!-- <div class="unavailable">
              Sorry, no modifiers available.
            </div> -->
          </ng-template>
        </div>            
        <div class="arrow-bottom" *ngIf="displayModiScroll()" >
         <img class = "color-down-arrow" src="/assets/images/arrow-dropdown-circle.svg" alt="Slide up/down Image" (click)="ScrollToBottomModiferList()" >
        </div>        
      </div>
      <div class="left-bottom-items"></div>
      <div class="action-items">
        <div class="counter" *ngIf="count$ | async as count">
          <button (click)="decreaseCounter()" data-qa="modifier-count-dec">
            -
          </button>
          <div class="count" data-qa="modifier-count">{{ count }}</div>
          <button
            (click)="increaseCounter()"
            data-qa="modifier-count-inc"
            [ngClass]="{ 'disable-button': checkComboMax }"
          >
            +
          </button>
        </div>

        <div class="add-button-wrapper">
          <button
            (click)="submitMenuItem()"
            [ngClass]="{
              'center-align': !!fromCombo,
              'disable-button': checkGroupFull
            }"
          >
            <ng-container
              *ngIf="!!fromCombo; then comboItemText; else menuItemText"
            ></ng-container>
            <ng-template #comboItemText>
              <span>
                Add to Combo
              </span>
            </ng-template>
            <ng-template #menuItemText>
              <span>
                {{ 'add-to-order' | translate }}
              </span>
              
              <span
                data-qa="modifier-total"
                class="amt"
                *ngIf="price$ | async as price else zeroPrice"
              >
              <span class="modifier-total-points"
              data-qa="modifier-total-points"
              *ngIf="points$ | async as point"
            >
            <span class="loyalty" *ngIf="isLoyaltyEnabled(selectedMenuItem)">{{ point }} PTS</span> </span>   {{ price | formattedCurrency }}</span
              >
              
              <ng-template #zeroPrice>
                <span
                data-qa="modifier-total"
                class="amt"
              >
                {{ 0 | formattedCurrency }}  <span class="modifier-total-points"
                data-qa="modifier-total-points"
                *ngIf="points$ | async as point"
              >
              <span *ngIf="isLoyaltyEnabled(selectedMenuItem)"> {{ point }} PTS</span></span> </span
              >
              </ng-template>
              <ng-template #zeroPoints>
                <span
                data-qa="modifier-total"
                class="amt"
              >
                + 0 PTS </span
              >
              </ng-template>
            </ng-template>
          </button>
        </div>
      </div>

      <!-- <ng-template #itemCounter>
        <div class="action-items">
          <div class="btn-wrapper">
            <moduurnv2-item-count
              [menuitem]="itemDetail"
            ></moduurnv2-item-count>
          </div>
        </div>
      </ng-template> -->
    </div>
  </ng-container>
</div>
<ng-template #modifierLoader>
  <div class="modifier-wrapper loading">    
      <div class="item-name loader loading-item-name"></div>      
      <img [src]="close" data-qa="close-button" class="closebtn" (click)="closePopup()" aria-label="close" alt="close"/>
      <div class="item-details">
        <div class="left-pane loader-left-pane">
          <div class="item-image loader-item-image loader"></div>
          <div class="item-icons loader-item-icons loader"> </div>
          <div class="item-description-head loader-item-description-head loader"></div>
          <div class="item-description loader-item-description loader"> </div>
        </div>
        <div class="loader loader-right-pane"></div>
        <div class="left-bottom-items loader"></div>
        <div class="action-items loader loader-action-items"></div>
      </div>      
  </div>
</ng-template>

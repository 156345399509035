import { Component } from '@angular/core';
import { SharedService,AddressComponentCloseModel } from '@moduurnv2/libs-orderingapp';
import {Address,AddressComponentEnums}  from '@moduurnv2/libs-orderingapp/src/core/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalService } from './../../shared/modal/modal.service';
import { Observable } from 'rxjs/internal/Observable';
import { Store,Select } from '@ngxs/store';
import {AddressState} from '@moduurnv2/libs-orderingapp/src/core/state';

@Component({
  selector: 'moduurnv2-address-wrapper',
  templateUrl: './address-wrapper.component.html',
  styleUrls: ['./address-wrapper.component.scss'],
})
export class AddressWrapperComponent {
  destroy$: Subject<boolean> = new Subject<boolean>();
  @Select(AddressState.getAddressList) addressList$: Observable<Address[]>;
  adrsDetailsList: any;
  lastData:any;
  isMobileView;
  pageToShow : AddressComponentEnums = AddressComponentEnums.ADDRESS_LIST;
  dataToPass:any;
  isAddressBook:boolean = false;
  fromPage:AddressComponentEnums;
  addressList : any;
  constructor(private shared: SharedService,private modal:ModalService, private store: Store,) {
    this.addressList$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.addressList = data;
      if(this.addressList?.length){
        this.pageToShow = AddressComponentEnums.ADDRESS_LIST;
      }
  });
  }
  ngOnInit() {
     this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
  }


  set data(data: any) {
    if(data.pageToShow)
    this.pageToShow = data.pageToShow;
    if(data.isAddressBook)
    this.isAddressBook=data.isAddressBook;
  }

   closeCurrentComponent(event:AddressComponentCloseModel){
    this.dataToPass= undefined;
    if(event){
      this.dataToPass= event.dataToPass;
      this.fromPage = event.fromPage;
      if(event.toPage == 'NONE'){
        this.modal.closeAllModals()
      } else{
        this.pageToShow = event.toPage;
      }
    }
   }


}

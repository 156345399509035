import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalContentDirective } from './modal-content.directive';
import { OverlayComponent } from './overlay.component';
import { OverlayLargeDesktopComponent } from './overlay-large-desktop/overlay-large-desktop.component';
import { OverlayMediumDesktopComponent } from './overlay-medium-desktop/overlay-medium-desktop.component';
import { OverlayFutureComponent } from './overlay-future/overlay-future.component';
import { OverlaySmallFormComponent } from './overlay-small-form/overlay-small-form.component';
import { OverlayLocInfoComponent } from './overlay-loc-info/overlay-loc-info.component';
import { OverlayFlashMobileComponent } from './overlay-flash-mobile/overlay-flash-mobile.component';
import { OverlayGreyedOutComponent } from './overlay-greyed-out/overlay-greyed-out.component';
import { OverlaySmallComponent } from './overlay-small/overlay-small.component';
import { OverlayMobileFullComponent } from './overlay-mobile-full/overlay-mobile-full.component';
import { OverlayFullScreenComponent } from './overlay-full-screen/overlay-full-screen.component';

@NgModule({
  declarations: [
    ModalContentDirective,
    OverlayComponent,
    OverlayLargeDesktopComponent,
    OverlayMediumDesktopComponent,
    OverlayFutureComponent,
    OverlayLocInfoComponent,
    OverlayFlashMobileComponent,
    OverlaySmallFormComponent,
    OverlayGreyedOutComponent,
    OverlaySmallComponent,
    OverlayMobileFullComponent,
    OverlayFullScreenComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule
  ],
  providers: [],
  entryComponents: [
    OverlayComponent,
    OverlayLargeDesktopComponent,
    OverlayMediumDesktopComponent,
    OverlayFutureComponent,
    OverlayLocInfoComponent,
    OverlayFlashMobileComponent,
    OverlaySmallFormComponent,
    OverlayGreyedOutComponent,
    OverlaySmallComponent,
    OverlayMobileFullComponent,
    OverlayFullScreenComponent
  ]
})
export class ModalModule { }

import { Component } from '@angular/core';
import { AddressMapBaseComponent } from '@moduurnv2/libs-orderingapp/src/features/ui/base/address-map-base-component';
@Component({
  selector: 'moduurnv2-address-map',
  templateUrl: './address-map.component.html',
  styleUrls: ['./address-map.component.scss'],
})
export class AddressMapComponent extends AddressMapBaseComponent {
  // _modalClose: any = null;
  // closeSelectionModal(data?) {
  //   this.closeMap();
  //   // if (this._modalClose) this._modalClose(data);
  // }

    // set modalClose(modalRef: any) {
    // this._modalClose = (data?) => {
    // console.log('try to close?');
    // this.modal.closeComponentModal(modalRef, data);
    // };
    // }

  continuetoDetails(event) {
    event.stopPropagation();
    event.preventDefault();
    this.continue();
    // this.closeSelectionModal(this.addressData);
  // this.modal.showMediumModalDesktop(AddressDetailsDesktopComponent);
  }
}

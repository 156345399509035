import { Component, Output, EventEmitter, Input } from '@angular/core';
import { ReviewPayBaseComponent } from '@moduurnv2/libs-orderingapp/src';

@Component({
    selector: 'moduurnv2-special-instruction-desktop',
    templateUrl: './special-instruction-desktop.component.html',
    styleUrls: ['./special-instruction-desktop.component.scss'],
})
export class SpecialInstructionDesktopComponent extends ReviewPayBaseComponent {
    @Output() open = new EventEmitter();
    @Input() isSpecialInstructionPresent:boolean;
    @Input() isSpecialInstruction:boolean;
    @Input() specialInstructionText:string;
}

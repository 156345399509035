import { Router,ActivatedRoute } from '@angular/router';
import { OnInit,Component,Input } from '@angular/core';
import {FormBuilder} from '@angular/forms'
import { Store, Select } from '@ngxs/store';
import {EmailChangePasswordBaseComponent,SharedService} from '@moduurnv2/libs-orderingapp';
import { ModalService } from '../../shared/modal/modal.service';

@Component({
  selector: 'moduurnv2-email-change-password',
  templateUrl: './email-change-password.component.html',
  styleUrls: ['./email-change-password.component.scss']
})
export class EmailChangePasswordComponent extends EmailChangePasswordBaseComponent {
  _data: any = null;
  constructor(store: Store,router: Router,route: ActivatedRoute,_shared:SharedService,fb:FormBuilder,modal: ModalService) {
    super(store,router,route,fb,_shared,modal)
   }
   set data(data: any) {
    this._data = data;
    this.showOldPassword = data.oldPassword;
  }
  set modalClose(modalRef: any) {
    this._modalClose = () => {
      this.modal.closeModal(modalRef);
    };
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthState,
  CartState,
  Deals,
  DealsBaseComponent,
  DealsState,
  EmailLoginBaseComponent,
  FetchDeals,
  LocationState,
  RemoveDeal,
  SetLoginFrom,
  SharedService,
} from '@moduurnv2/libs-orderingapp/src';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalService } from '../../../shared/modal/modal.service';
import { AnalyticsHandler } from '@moduurnv2/core/services';

@Component({
  selector: 'moduurnv2-deals-order-list',
  templateUrl: './deals-order-list.component.html',
  styleUrls: ['./deals-order-list.component.scss'],
})
export class DealsOrderListComponent extends DealsBaseComponent
  implements OnInit {
  isShowAuth: boolean = false;
  pageToShow: string;
  @Select(DealsState.getAllDeals) allDeals$: Observable<Deals[]>;

  allDeals: Deals[];
  _modalClose: any = null;

  constructor(
    store: Store,
    router: Router,
    shared: SharedService,
    modal: ModalService,
    analyticsHandler: AnalyticsHandler,
  ) {
    super(shared, store, modal, router, analyticsHandler);
    let cart = store.selectSnapshot(CartState.getCart);
    store.dispatch(new FetchDeals(cart.cart_id, cart.subTotal));
  }

  set modalClose(modalRef: any) {
    this._modalClose = () => {
      this.modal.closeModal(modalRef);
    };
  }

  ngOnInit() {
    this.allDeals$.pipe(takeUntil(this.destroy$)).subscribe((deals) => {
      if (deals) {
        this.allDeals = deals;
        this.checkIsEmptyDealScreen(this.allDeals);
        let chosenDeal = deals.filter((deal) => {
          if (deal?._id == this.checkDeal?._id) {
            return deal;
          }
        });
        if (chosenDeal.length == 0) {
          this.store.dispatch(new RemoveDeal());
        }
      }
    });
  }

  goBack() {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if (selectedLocation)
      this.router.navigate([`/locations/${selectedLocation._id}/order`]);
  }

  closePopup() {
    if (this._modalClose) this._modalClose();
  }

  close() {
    if (this.isMobileView) {
      this.goBack();
    } else {
      this.closePopup();
    }
  }
}

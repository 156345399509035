export const webEnvironment = {
  firebase: {
    apiKey: 'AIzaSyB0s7nwXFRqr9RSUmes7ZIoi4w4dAovPZI',
    authDomain: 'moduurnv2-5507e.firebaseapp.com',
    databaseURL: 'https://moduurnv2-5507e.firebaseio.com',
    projectId: 'moduurnv2-5507e',
    storageBucket: 'moduurnv2-5507e.appspot.com',
    messagingSenderId: '397838476055',
    appId: '1:397838476055:web:98850d098d53373b1ccd52',
    measurementId: 'G-2TZBFFF286',
  },
};

import { Component } from '@angular/core';
import { AuthState, ReviewPayBaseComponent, SetLoginFrom } from '@moduurnv2/libs-orderingapp/src';
import { DealsOrderListComponent } from '../../../deals/components/deals-order-list/deals-order-list.component';
import { LoginDesktopComponent } from '../../../login/components/login-desktop/login-desktop.component';

@Component({
    selector: 'moduurnv2-price-split-up',
    templateUrl: './price-split-up.component.html',
    styleUrls: ['./price-split-up.component.scss'],
})
export class PriceSplitUpComponent extends ReviewPayBaseComponent {

  openDealsModal(){
    const auth = this.store.selectSnapshot(AuthState);
    if (auth?.loggedInUser?.isLoggedIn) {
      if(this.isMobileView){
        this.openDeals();
      }else{
        this.modal.showMediumModalDesktop(DealsOrderListComponent);
      }
    }else{
      this.store.dispatch(new SetLoginFrom('deals'));
      if(this.isMobileView){
        this.router.navigate(['/login']);
      }else{
        this.modal.showMediumModalDesktop(LoginDesktopComponent);
      }
    }
  }
}

import { Output, Input, ViewChild, TemplateRef } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Router,NavigationExtras } from '@angular/router';
import {
  BaseComponent,
  PaymentMethodState,
  SetPaymentMethod,
  FetchSavedCards,
  SetSelectedCard,
  TokenizeCard,
  LocationState,
  NewCard,
  ClearSelectedCard,
  RemoveCard,
  SavedCard,
  SweetAlertService,
  AuthState,
  LocationDefaultState,
  LocationDefaults,
  FetchLocationDefaults,
  AuthEmailState,
  FetchGiftLoyaltyCards,
  CartState,
  GiftLoyaltyMethodState,
  GiftCard,
  WhitelabelState,
  environment,
  SetLoginFrom,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil } from 'rxjs/operators';
import {
  paymentCardType,
  paymentMethods,
} from './../../../core/services/constant';
import { SharedService } from '@moduurnv2/libs-orderingapp/src/core';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { Auth } from './../../../core/models';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export class PaymentMethodBaseComponent extends BaseComponent {
  @Output() closeEvent = new EventEmitter();
  @Output() desktopView = new EventEmitter();
  @Input() paymentView: boolean = true;
  @Input('cards') cards: any;
  @Select(PaymentMethodState.getPaymentsList) paymentList$;
  @Select(PaymentMethodState.getAckrooPaymentDetails) ackrooPaymentDetails$;
  @Select(PaymentMethodState.getMealPaymentDetails) mealPaymentDetails$;
  @Select(PaymentMethodState.getSavedCards) savedCards$: Observable<
    SavedCard[]
  >;
  @Select(AuthState.getCommonAuthDetails)
  loggedInUserDetails$: Observable<Auth>;

  authSubscription: Subscription;
  loggedInUserDetails;
  @Select(LocationDefaultState.getLocationDefaults)
  locationDefaults$: Observable<LocationDefaults>;
  @Select(GiftLoyaltyMethodState.getSavedCards) GiftCards$: Observable<
    GiftCard[]
  >;
  @Select(GiftLoyaltyMethodState.getErrorResponse)
  GiftCardErrResponse$: Observable<any>;
  giftCard: any = undefined;
  emailValidated: boolean = false;
  registeredUser: boolean = false;
  enableGiftCard: boolean = false;
  bannerImage = 'assets/images/Payment-Banner.png';

  selectedCard = {
    card_id: '',
    function: '',
    name: '',
    number: '',
    expiry_month: '',
    expiry_year: '',
    card_type: '',
  };

  paymentDetails = [];
  paymentList;
  ackrooPaymentDetails: any = undefined;
  mealPaymentDetails: any = undefined;
  isMobileView: boolean;
  card: NewCard;
  paymentMethods = [];
  isProfile = false;
  hideSavedCard: boolean = false;
  paymentData: any;
  cartPrices: any = [];
  payMealProgram: boolean = false;
  // @ViewChild('giftCardAndLoyaltyTemplate',{ static : true }) giftCardAndLoyaltyTemplate: TemplateRef<any>;

  constructor(
    public store: Store,
    private router: Router,
    public sweetAlert: SweetAlertService,
    private shared: SharedService,
    public modal: ModalService,
    public translate: TranslateService
  ) {
    super();
    this.authSubscription = this.loggedInUserDetails$.subscribe((res) => {
      this.loggedInUserDetails = res;
    });
    this.paymentList$
      .pipe(takeUntil(this.destroy$))
      .subscribe((paymentList) => {
        if (paymentList) {
          this.paymentList = paymentList;
        }
      });
    this.GiftCards$.pipe(takeUntil(this.destroy$)).subscribe((giftCards) => {
      let errorResponse = this.store.selectSnapshot(
        GiftLoyaltyMethodState.getErrorResponse
      );
      if (giftCards) {
        this.giftCard = undefined;
        if (giftCards.length && giftCards.length >= 0) {
          this.giftCard = giftCards.find((c) => c.active == true);
          this.registeredUser = true;
          this.emailValidated = true;
        } else if (
          errorResponse &&
          errorResponse.error &&
          errorResponse.error === 2103
        ) {
          this.emailValidated = false;
          this.giftCard = undefined;
          this.registeredUser = true;
        } else {
          this.giftCard = undefined;
          this.registeredUser = false;
          this.giftCard = undefined;
        }
      }
    });

    this.ackrooPaymentDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ackrooPaymentDetails) => {
        if (ackrooPaymentDetails) {
          this.ackrooPaymentDetails = ackrooPaymentDetails;
          if (ackrooPaymentDetails['isAllAmountPayedFromGiftCard'])
            this.hideSavedCard = true;
        } else this.ackrooPaymentDetails = undefined;
      });

      this.mealPaymentDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe((mealPaymentDetails) => {
        if (mealPaymentDetails) {
          this.mealPaymentDetails = mealPaymentDetails;
      }});

    this.savedCards$.pipe(takeUntil(this.destroy$)).subscribe((cardList) => {
      if (cardList) {
        this.paymentDetails = cardList;
        if (this.isProfile && this.paymentDetails.length) {
          // this.selectedCard =  this.paymentDetails[0]; open default
        } else {
          this.selectedCard = {
            card_id: '',
            function: '',
            name: '',
            number: '',
            expiry_month: '',
            expiry_year: '',
            card_type: '',
          };
        }
      }
    });
    this.locationDefaults$
      .pipe(takeUntil(this.destroy$))
      .subscribe((locationDefaults) => {
        if (location && locationDefaults?.locationData) {
          let modes = locationDefaults.locationData.orderSettings.paymentModes;
          this.payMealProgram = modes['payMealProgram'];
          this.paymentMethods = this.shared.getActivePaymentMethods(modes);
          this.hideSavedCard = !modes.payNow;
        }
      });
    this.checkSavedCards();
    this.isProfile = this.router.url.split('/').pop() === 'payment-profile';
  }

  ngOnInit(): void {

    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    if (customer && customer.customerId) {
      let other = this.store.selectSnapshot(WhitelabelState.getOther);
      if (other && other.paymentGateway && other.paymentGateway.textCode) {
        this.store.dispatch(new FetchSavedCards(other.paymentGateway.textCode));
      }
    }
    this.store.dispatch(new FetchLocationDefaults());
    this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
    this.store.dispatch(new FetchGiftLoyaltyCards());
    let other = this.store.selectSnapshot(WhitelabelState.getOther);
    let location = this.store.selectSnapshot(LocationState.getSelectedLocation);
    if (
      other &&
      other.paymentGateway &&
      other.paymentGateway.textCode == 'PAYMENTGATEWAY02' &&
      other.loyaltyAndGiftCards &&
      other.loyaltyAndGiftCards.isAllow &&
      location &&
      location.loyaltyAndGiftCards &&
      location.loyaltyAndGiftCards.isAllow
    ) {
      this.enableGiftCard = true;
    }
    let defaults = this.store.selectSnapshot(
      LocationDefaultState.getLocationDefaults
    );
    if (defaults) {
      let modes = defaults.locationData.orderSettings.paymentModes;
      this.payMealProgram = modes['payMealProgram'];
      this.paymentMethods = this.shared.getActivePaymentMethods(modes);
    }
    let whitelabel = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
    if (whitelabel?.paymentPage?.phone) {
      this.bannerImage =
        environment.imagebaseurl + whitelabel.paymentPage.phone;
    }
  }

  onClick(
    item,
    giftCardsAndLoyaltyComponent = undefined,
    giftCardsAndLoyaltyTitle = ''
  ) {
    if (item.id === 5) this.hideSavedCard = true;
    else this.hideSavedCard = false;
    switch (true) {
      case item.id == 1: {
        break;
      }

      case item.id == 2: {
        break;
      }
      case item.id == 3: {
         this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
        this.store.dispatch(new ClearSelectedCard());
        this.desktopView.emit({ isdesktop: this.isMobileView, type: 3 });
        if (this.isMobileView) this.addCard();
        break;
      }

      case item.id == 4: {
        this.desktopView.emit({ isdesktop: this.isMobileView, type: 4 });
        this.openGiftCardsAndLoyalty(
          giftCardsAndLoyaltyComponent,
          giftCardsAndLoyaltyTitle
        );
        break;
      }
      case item.id == 5:
        let paymentMethod = this.paymentMethods.find(
          (method) => method.id == 5
        );
        this.store.dispatch(new ClearSelectedCard());
        this.store.dispatch(new SetPaymentMethod(paymentMethod));
        const selectedLocation = this.store.selectSnapshot(
          LocationState.getSelectedLocation
        );
        this.desktopView.emit({
          isdesktop: this.isMobileView,
          type: 5,
          savedCard: false,
        });
        if (selectedLocation._id) {
          let navigationExtras: NavigationExtras = {
            queryParams: {
                "paymentId": item.id
            }
          };
          if (this.isMobileView)
            this.router.navigate([`/locations/${selectedLocation._id}/order`],navigationExtras);
        }
        break;
    }
  }

  addCard() {
    this.router.navigate([`/payment/add-card`]);
  }

  getCardStyle(index: number) {
    this.paymentList$.paylater = false;

    if (index % 2 == 0) {
      return {
        background: `linear-gradient( 243deg, #ffd1cc 0%, #ccf0ff 100% )`,
      };
    } else {
      return {
        background: `linear-gradient( 243deg, #ccffd5 0%, #daccff 100% )`,
      };
    }
  }
  checkSavedCards() {
    this.savedCards$.pipe(takeUntil(this.destroy$)).subscribe((cardList) => {
      if (cardList) {
        this.paymentDetails = cardList;
      }
    });
  }

  getCard(card_type) {
    //  visa

    if (card_type == 'VI' || card_type == 'VISA') {
      return paymentCardType.visa;
    }

    // Mastercard

    if (card_type == 'MC' || card_type == 'MASTERCARD') {
      return paymentCardType.mastercard;
    }

    // AMEX
    if (card_type == 'AM' || card_type == 'AMERICAN') {
      return paymentCardType.amex;
    }

    // Discover

    if (card_type == 'NN' || card_type == 'DISCOVER') {
      return paymentCardType.discover;
    }

    return null;
  }

  selectCard(card) {
    if (this.isProfile) {
      this.selectedCard = card;
      return;
    }
    card.cardType = this.getCard(card.card_type).type;
    this.store.dispatch(new SetSelectedCard(card));
    let paymentMethod = this.paymentMethods.find((method) => method.id == 3);
    this.store.dispatch(new SetPaymentMethod(paymentMethod));
    this.closeEvent.emit();
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if(this.isMobileView){
      const previouslyNavigatedFrom = this.store.selectSnapshot(AuthState.getLoginFrom);
      if(previouslyNavigatedFrom=="reviewPage"){
        this.store.dispatch(new SetLoginFrom("reviewPage/payment"))
      }
    }
    if (!this.isMobileView) {
      this.desktopView.emit({
        isdesktop: this.isMobileView,
        type: 5,
        savedCard: true,
      });
    } else if (selectedLocation._id) {
      let navigationExtras: NavigationExtras = {
        queryParams: {
            "paymentId": 5
        }
      };
      this.router.navigate([`/locations/${selectedLocation._id}/order`],navigationExtras);
    }
  }

  payUsingNewCard(card) {
    this.store.dispatch(new TokenizeCard(card));
  }

  deleteCard(card) {
    this.sweetAlert
      .confirmDelete(
        this.translate.instant('are-you-sure'),
        this.translate.instant('remove-card')
      )
      .then((choice) => {
        if (choice) {
          this.store.dispatch(new RemoveCard(card));
        }
      });
  }
  unselectCard(card) {
    this.selectedCard = {
      card_id: '',
      function: '',
      name: '',
      number: '',
      expiry_month: '',
      expiry_year: '',
      card_type: '',
    };
  }
  ngOnDestroy() {
    if (this.authSubscription) this.authSubscription.unsubscribe();
  }
  addGiftLoyalty() {
    this.router.navigate([`/payment/gift-loyalty`]);
  }
  isGuestUser() {
    let userData = this.store.selectSnapshot(AuthEmailState.getUserDetails);
    let userEmail = userData && userData.email ? userData.email : '';
    let locationDetails = JSON.parse(localStorage.getItem('selectedLocation'));
    this.loggedInUserDetails = localStorage.getItem('loggedInUser')
      ? JSON.parse(localStorage.getItem('loggedInUser'))
      : undefined;
    let isGuest = userData && userData.user_id ? false : true;
    return isGuest;
  }
  openGiftCardsAndLoyalty(
    giftCardsAndLoyaltyComponent,
    giftCardsAndLoyaltyTitle
  ) {
    if (this.isMobileView) {
      if (this.isGuestUser()) this.router.navigate([`/payment/gift-loyalty`]);
      else if (this.registeredUser && !this.emailValidated)
        this.router.navigate([`/payment/resend-email`]);
      else {
        this.router.navigate([`/payment/gift-card`]);
      }
    } else {
      this.modal.showMediumModalDesktop(giftCardsAndLoyaltyComponent, {
        needHeader: true,
        headerTitle: giftCardsAndLoyaltyTitle,
        allowOverlayClick: false,
      });
    }
  }

  openStdentsMealProgram(MealProgramDetailsComponent, mealProgramTitle) {
    if (this.isMobileView) {
      this.router.navigate([`/payment/student-meal`]);
    } else {
      this.modal.showMediumModalDesktop(MealProgramDetailsComponent, {
        needHeader: true,
        headerTitle: mealProgramTitle,
        allowOverlayClick: false,
      });
    }
  }
}

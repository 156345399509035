import { Component } from '@angular/core';
import { AddressMapBaseComponent } from '@moduurnv2/libs-orderingapp/src/features/ui/base/address-map-base-component';
import { AddressDetailsDesktopComponent } from '../address-details-desktop/address-details-desktop.component';
//import { AddressDetailsDesktopComponent } from '../address-details-desktop/address-details-desktop.component';
@Component({
  selector: 'moduurnv2-address-map-desktop',
  templateUrl: './address-map.component-desktop.html',
  styleUrls: ['./address-map.component-desktop.scss'],
})
export class AddressMapDesktopComponent extends AddressMapBaseComponent {
  // _modalClose: any = null;
  // closeSelectionModal(data?) {
  //   if (this._modalClose) this._modalClose(data);
  //   }

  //   set modalClose(modalRef: any) {
  //   this._modalClose = (data) => {
  //   this.modal.closeComponentModal(modalRef, data);
  //   };
  //   }

  continuetoDetails(event) {
    event.stopPropagation();
    event.preventDefault();
    this.continue();
    // this.closeSelectionModal(this.addressData);
  // this.modal.showMediumModalDesktop(AddressDetailsDesktopComponent);
  }
}

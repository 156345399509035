import { State, Selector, StateContext, Action, Store } from '@ngxs/store';
import { PushNotificationToken } from '../models';
import { SetPushNotification } from '../actions';
import { Injectable } from '@angular/core';

export class PushNotificationModel {
  pushNotification: PushNotificationToken;
}

@State<PushNotificationModel>({
  name: 'pushnotification',
  defaults: {
    pushNotification: undefined,
  },
})
@Injectable()
export class PushNotificationState {
  constructor(private store: Store) {}

  @Selector()
  static getPushNotification(state: PushNotificationModel) {
    return state.pushNotification;
  }

  @Action(SetPushNotification)
  PushNotification(
    { patchState }: StateContext<PushNotificationModel>,
    { payload }: SetPushNotification
  ) {
    patchState({
      pushNotification: payload,
    });
  }
}

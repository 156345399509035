import { Component, Input, OnInit } from '@angular/core';
import {
  BaseComponent,
  MenuItemCreation,
  CartState,
  CartItems,
  SweetAlertService,
  SetItemDetails,
  AddItemToCart,
  UpdateCount,
  SetMenuItem,
  SharedService,
  AuthState,
  SetLoginFrom,
  WhitelabelState,
  AddItemToCombo,
  ComboState,
  UpdateItemCountCombo,
  UpdateEntireCartCombo
} from '@moduurnv2/libs-orderingapp';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ModalService } from '../modal/modal.service';
import { ModifierWrapperComponent } from '../modifier/modifier-wrapper/modifier-wrapper.component';
import {TranslateService} from '@ngx-translate/core';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { LoginDesktopComponent } from '../../login/components/login-desktop/login-desktop.component';
import { Router } from '@angular/router';
import { ComboGroup } from '@moduurnv2/libs-orderingapp/src/core/models/combo.interface';
@Component({
  selector: 'moduurnv2-combo-item-count',
  templateUrl: 'combo-item-count.component.html',
  styleUrls: ['combo-item-count.component.scss'],
})
export class ComboItemCountComponent extends BaseComponent implements OnInit {
  @Input('menuitem') menuItem;
  @Input('reviewmenuitem') reviewMenuItem: boolean = false;
  @Input('group') group:ComboGroup;
  selectedMenuItem;
  @Select(ComboState.getCartCombo) cartCombo$: Observable<any>;
  @Select(WhitelabelState.getOther) otherConfig$: Observable<any>;
  value: number = 0;
  isEmpty: boolean;
  myCart: any;
  countSubject = new Subject();
  loading: boolean = false;
  isMobileView: boolean = true;
  popup: boolean = false;
  checkAuth: boolean = false;
  path:any;
  constructor(
    public store: Store,
    public sweetAlert: SweetAlertService,
    private modal: ModalService,
    private shared: SharedService,
    public translate: TranslateService,
    private router : Router
  ) {
    super();
    this.isEmpty = true;
    this.path = router.url.split('/');
  }

  ngOnInit() {
    this.value = 0;
    this.cartCombo$.pipe(takeUntil(this.destroy$)).subscribe((cartCombo) => {
      this.checkIfSelectedMenuItem(cartCombo);
    });
    this.otherConfig$.pipe(takeUntil(this.destroy$)).subscribe((config) => {
      this.checkAuth = config.isForceLogin;
    });
    this.setupCountSubject();
    this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
  }

  subtractCount(item,event) {
    event.stopPropagation();
    const cartCombos = this.store.selectSnapshot(ComboState.getCartCombo);
    let itemId = '';
    
    if (this.reviewMenuItem) itemId = item._id;
    else itemId = item._id;
    
    if(item?.modifiers?.length == 0 && item?.portions?.length == 0 && item?.freeTextModifiers?.length == 0){
    if (cartCombos && this.value) {
      const foundCartGroup = cartCombos.comboGroups.find( comboGroup => comboGroup.groupId === this.group._id);
      let selectedMenuItem = foundCartGroup.groupItems.find((menuItem) => {
        if (this.reviewMenuItem) return menuItem.menuItem === itemId;
        else return menuItem.menuItem === itemId;
      });
      if (this.reviewMenuItem && this.value == 1) {
        const payload = {
          count: this.value,
          menuItemId: selectedMenuItem.menuItem,
          groupId: this.group._id
        };
        this.modal.showSmallModal(AlertPopupComponent,{data:{
          header:this.translate.instant('are-you-sure-remove'),
          actionable:true
        },whenClosed: (data) => {
            if(data)this.countSubject.next(payload);
          },
        });
      } else {
        this.value--;
        const payload = {
          count: this.value,
          menuItemId: selectedMenuItem.menuItem,
          groupId: this.group._id
        };

        this.countSubject.next(payload);
      }
    } else {
      this.isEmpty = true;
    }
    } else {
      if (cartCombos && this.value) {
          this.decrementComboItemWithCustomizationCount(cartCombos,itemId);
        // let selectedMenuItem = foundCartGroup.groupItems.reverse().find((menuItem) => {
        //   if (this.reviewMenuItem) return menuItem.menuItem === itemId;
        //   else return menuItem.menuItem === itemId;
        // });
        // if(selectedMenuItem){
          
        // } else {
        //   this.isEmpty = true;
        // }
      }
    }

    if (!this.value) {
      this.isEmpty = true;
    }
  }

  addComboItemCount(item,event) {
    event.stopPropagation();
    const cartCombos = this.store.selectSnapshot(ComboState.getCartCombo);
    
    let itemId = '';
    
    if (this.reviewMenuItem) itemId = item._id;
    else itemId = item._id;
    if (cartCombos ) {
      const foundCartGroup = cartCombos.comboGroups.find( comboGroup => comboGroup.groupId === this.group._id);
      let selectedMenuItem = foundCartGroup.groupItems.find((menuItem) => {
        if (this.reviewMenuItem) return menuItem.menuItem === itemId;
        else return menuItem.menuItem === itemId;
      });
      if(selectedMenuItem?.count > 0){
        if(this.checkCustomize){
          let isModalOpen= true;
          this.modal.showSmallModal(AlertPopupComponent,{data:{
        header:'SELECT',
            actionable:true,
            proceedText:'The same',
            closeBtnText:'To modify...',
            isSecondaryBtnEnabled:true
          },whenClosed: (data) => {
            if(isModalOpen){
              if(data==true){
                isModalOpen= false;
                // this.incrementComboItemCount(selectedMenuItem);
                this.incrementComboItemWithCustomizationCount(cartCombos,itemId);
              } else if(data == false){
                isModalOpen= false;
                if(this.checkAuth){
                  this.checkAuthorisation(item)
                } else {
                  this.addItem(item)
                }
              } else{
                isModalOpen= false;
              }
            }
            },
          });
        } else {
          this.incrementComboItemCount(selectedMenuItem);
        }
      }

    }
  }

  
  decrementComboItemWithCustomizationCount(cartCombos,itemId){
    const foundCartGroup = cartCombos.comboGroups.find( comboGroup => comboGroup.groupId === this.group._id);
    if(foundCartGroup?.groupItems?.length){
      for(let i=foundCartGroup.groupItems.length -1 ; i>=0; i--){
          let currentGroupItem = foundCartGroup.groupItems[i]
          if(currentGroupItem.menuItem == itemId){
            if(currentGroupItem.count == 1)
            foundCartGroup.groupItems.splice(i,1);
            else if(currentGroupItem.count > 1)
            currentGroupItem.count -= 1;
            break;
          }
      } 
      this.store.dispatch(new UpdateEntireCartCombo(cartCombos))
    }
  }

  incrementComboItemWithCustomizationCount(cartCombos,itemId){
    const foundCartGroup = cartCombos.comboGroups.find( comboGroup => comboGroup.groupId === this.group._id);
    if(foundCartGroup?.groupItems?.length){
      for(let i=foundCartGroup.groupItems.length -1 ; i>=0; i--){
          let currentGroupItem = foundCartGroup.groupItems[i]
          if(currentGroupItem.menuItem == itemId){
            currentGroupItem.count += 1;
            break;
          }
      } 
      this.store.dispatch(new UpdateEntireCartCombo(cartCombos))
    }
  }
  
  incrementComboItemCount(selectedMenuItem){
    this.value++;
    const payload = {
      count: this.value,
      menuItemId: selectedMenuItem.menuItem,
      groupId: this.group._id
    };
    this.countSubject.next(payload);
  }

  checkAuthorisation(item){
    const auth = this.store.selectSnapshot(AuthState);
    if (auth?.loggedInUser?.isLoggedIn) {
      this.addItem(item);
    }else{
      if(!this.isMobileView){
        this.modal.showMediumModalDesktop(LoginDesktopComponent);
      }else{
        this.showLogin();
      }
    }
  }

  showLogin() {
    this.store.dispatch(new SetLoginFrom(this.path[this.path.length - 1]));
    if (this.isMobileView) {
      this.router.navigate(['/login']);
    }
  }

  addItem(menuitem) {    
    const foundComboMenuItem = this.group.group_items.find( comboItem => comboItem.menuItem._id === menuitem._id);    
    if (!menuitem.portion || !menuitem.cartModifiers) {
      const itemCount = this.value + 1;
      this.selectedMenuItem = {
          count: itemCount,
          menuItem: menuitem._id,
          portion: undefined,
          cartModifiers: [],
          _id: foundComboMenuItem._id
        };
    }

    // create a new cart call
    if (this.checkCustomize) {     
      const menuDetails = {
        icons: menuitem.icons,
        description: menuitem.description,
        imageURL: menuitem.image,
        name: menuitem.name,
        _id: menuitem._id,
        translation:menuitem.translation,
        minCalorie:menuitem.minCalorie,
        maxCalorie:menuitem.maxCalorie,
      };
      this.store.dispatch(new SetItemDetails(menuDetails));
      if (menuitem._id) {
        this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
        if (this.isMobileView)
          this.modal.showModal(ModifierWrapperComponent, {
            data: { menuItemId: menuitem._id, fromCombo: this.group._id, menuItemIdInGroup: foundComboMenuItem._id },
          });
        else
          this.modal.showLargeModalDesktop(ModifierWrapperComponent, {
            data: { menuItemId: menuitem._id, fromCombo: this.group._id, menuItemIdInGroup: foundComboMenuItem._id },
            whenClosed: (data) => {
              
            }
          });
      }
    } else {
      // if(this.validateMaxItemsPerUser(this.selectedMenuItem.menuItem,this.selectedMenuItem.count)){
        this.store.dispatch(new AddItemToCombo(this.selectedMenuItem,this.group._id));
      // }
    }
    this.store.dispatch(new SetMenuItem(null));
  }

  get checkCustomize() {
    const { portions, modifiers , freeTextModifiers  } = this.menuItem;
    return portions?.length || modifiers.length  || (freeTextModifiers && freeTextModifiers.length);
  }

  checkIfSelectedMenuItem(cartCombo) {
    if(cartCombo) {
      const foundComboGroup = cartCombo.comboGroups.find((comboGroup) => {
        return comboGroup.groupId === this.group._id;  
      });
      const foundMenuItem = foundComboGroup?.groupItems.filter( groupItem => {
        return groupItem.menuItem === this.menuItem._id;
      })
  
      if (foundMenuItem && foundMenuItem.length) {
        this.isEmpty = false;
        this.value = foundMenuItem.reduce( (total, menuItem) =>  total + menuItem.count ,0);
      } else {
        this.isEmpty = true;
        this.value = 0;
      }
    } else {
      this.isEmpty = true;
      this.value = 0;
    }
  }

  setupCountSubject() {
    this.countSubject
      .pipe(debounceTime(500), takeUntil(this.destroy$))
      .subscribe((data) => {
            const payload = data as any;
            this.store.dispatch(new UpdateItemCountCombo(payload,this.group._id));
            // if(this.validateMaxItemsPerUser(payload.menuItemId, payload.count)){
            //     if (payload) {
            //       // this.store.dispatch(new UpdateComboCount(payload));
            //     }
            // }
      });
  }

  get checkModifier() {
    // if (this.reviewMenuItem) return false;
    // else if (this.isEmpty) return true;
    // else return this.checkCustomize > 0;
    return this.isEmpty;
  }

  checkGroupItemsCount(groupId, menuItemId?) {
    const cartCombo = this.store.selectSnapshot(ComboState.getCartCombo);
    
    if(cartCombo){
      
      const group = cartCombo.comboGroups.find(
        (combo) => combo.groupId === groupId
      );

      if (group && group.groupItems && group.groupItems.length > 0)
        return group.groupItems.reduce((total, item) => {
          return item.menuItem !== menuItemId ? total + item.count: total;
        }, 0);
      
    }
    
    return 0;
  }

  get checkGroupFull(){
    const cartCombo = this.store.selectSnapshot(ComboState.getCartCombo);
    if(cartCombo?.comboGroups){
      const foundCartCombo = cartCombo.comboGroups.find( comboGroup => comboGroup.groupId === this.group._id );
      if(foundCartCombo){
        const itemCount = foundCartCombo.groupItems.reduce((total, item) => {
          return total + item.count;
        }, 0);
        return this.group.itemCount && itemCount >= this.group.itemCount;
      }
    }
    return false;
  }
}

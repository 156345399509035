import { OnInit, Output, Input } from '@angular/core';
import {
  AuthEmailState,
  BaseComponent,
  CartItems,
  CartState,
  CheckMealDiscount,
  ClearSelectedCard,
  GiftAndLoyaltyCardService,
  LocationState,
  PaymentMethodState,
  SetMealPaymentDetails,
  SetPaymentMethod,
  SweetAlertService,
} from '@moduurnv2/libs-orderingapp/src/core';

import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from '@angular/forms';

import { Router,NavigationExtras } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { paymentCardType, paymentMethods } from '../../../core/services';
import { SharedService } from '@moduurnv2/libs-orderingapp/src/core';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil, tap } from 'rxjs/operators';
interface Credential {
  code: string;
}

export class StudentMealBaseComponent extends BaseComponent implements OnInit {
  cartItems: CartItems[];
  cartPrice: any = [];
  isMobileView: boolean = true;
  studentMealForm = new FormGroup({
    code: new FormControl('', Validators.compose([Validators.required])),
  });

  credential: Credential = {
    code: '',
  };

  icon = {
    alert: 'assets/images/alert-icon-new.svg',
  };

  _modalClose: any = null;
  amountToPay: number;
  paymentDetails: any;
  paymentMethods = paymentMethods;
  cardType: string;

  constructor(
    private fb: FormBuilder,
    public store: Store,
    private router: Router,
    private shared: SharedService,
    private loyaltyService: GiftAndLoyaltyCardService,
    public modal: ModalService,
    public sweetAlert: SweetAlertService,
    public translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
     this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
    this.cartItems = this.store.selectSnapshot(CartState.getCartItems);
    this.cartPrice = this.store.selectSnapshot(CartState.getCart);
    this.getCartLocationDetails();
  }
  get studentMealFormControl() {
    return this.studentMealForm.controls;
  }
  getCartLocationDetails() {
    if (this.cartPrice && this.cartItems) {
      let userData = this.store.selectSnapshot(AuthEmailState.getUserDetails);
      let userEmail = userData && userData.email ? userData.email : '';
      var amountToPay =this.amountToPay;
      let organizationId = this.cartItems[0].organizationId;
      let locationId = this.cartItems[0].locationId;
      this.paymentDetails = {
        amountToPay: amountToPay,
        userEmail: userEmail,
        isGuestUser: !userEmail,
        organizationId: organizationId,
        locationId: locationId,
      };
    
    }
  }


  closePopup() {
    if (this._modalClose) this._modalClose();
  }

  set modalClose(modalRef: any) {
    this._modalClose = (data) => {
      this.modal.closeComponentModal(modalRef, data);
    };
  }
  continue(){
    return this.store.dispatch(new CheckMealDiscount(this.studentMealForm.value.code)).pipe(tap ( response => {
      // this.amountToPay=this.getPaymentAmount();
      const mealProgram = this.store.selectSnapshot(PaymentMethodState.getMealPaymentDetails);
      if(mealProgram) {
        this.amountToPay = this.getPaymentAmount();
        this.setMealPaymentDetails(false);
      }
    }));
  }
  
  setMealPaymentDetails(isAllAmountPayed) {

    if(!this.studentMealForm?.valid)
      return ;

    let userData = this.store.selectSnapshot(AuthEmailState.getUserDetails);
    let userEmail = userData && userData.email ? userData.email : '';

    const mealDetails = this.store.selectSnapshot(PaymentMethodState.getMealPaymentDetails);

    let mealPaymentDetails = {
      amountToPay: mealDetails?.discount,
      userEmail: userEmail,
      locationId: this.paymentDetails.locationId,
      code: this.studentMealForm.value.code,
      organizationId: this.paymentDetails.organizationId,
    };
    
    let paymentMethod = this.paymentMethods.find((method) => method.id == 6);
    this.store.dispatch(new ClearSelectedCard());
    this.store.dispatch(new SetPaymentMethod(paymentMethod));
    this.store.dispatch(new SetMealPaymentDetails(mealPaymentDetails));
   
    if (mealPaymentDetails) {
      this.closePopup();
      if (!isAllAmountPayed) {
        if (this.isMobileView) {
          this.router.navigate([`/payment`]);
          return;
        } else return;
      }
      
    

      if (this.isMobileView) {
        const selectedLocation = this.store.selectSnapshot(
          LocationState.getSelectedLocation
        );
        if (selectedLocation._id) {
          this.router.navigate([`/locations/${selectedLocation._id}/order`]);
        }
      }
    }
  }

  getPaymentAmount(){
    return 1;
  }

  navigateToReviewAndPay() {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    let navigationExtras: NavigationExtras = {
      queryParams: {
          "paymentId": 6
      }
    };
    this.router.navigate([`/locations/${selectedLocation._id}/order`],navigationExtras);
  }
}

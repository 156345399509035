import {
  BaseComponent
} from '@moduurnv2/libs-orderingapp/src/core';
import { Input, OnInit } from '@angular/core';
import {OrderDetailsService} from './../../../core/services/order-details.service';
import {environment} from './../../../core/environments/environment'
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
interface Constant {
  text: string;
  textCode: string;
  textType: string;
}

interface Timeline {
  text: string;
  textCode: string;
  time: string;
  isCompleted: boolean;
}

export class TimeLineBaseComponent extends BaseComponent implements OnInit {
  // @Input('timeLine') orderStatusTimeLine: Timeline[];
  private _orderStatusTimeLine: Timeline[];
    
  @Input('timeLine') set orderStatusTimeLine(value: Timeline[]) {
  
     this._orderStatusTimeLine = value;
     if(this.typeOfDelivery)
      this.formatOrderStatus();
  
  }
  
  get orderStatusTimeLine(): Timeline[] {
  
      return this._orderStatusTimeLine;
  
  }

  @Input('deliveryMethod') typeOfDelivery: Constant;

  timelineSuccessIcon =
  environment.image_basepath + environment.images.timeline_tick;
  orderCancelIcon =
  environment.image_basepath + environment.images.order_cancel_icon;
  constructor(private _orderDetailsService:OrderDetailsService, private translate : TranslateService) {
    super();
  }

  ngOnInit() {
    this.formatOrderStatus();
  }

  formatOrderStatus() {
    let timeLineData = this.orderStatusTimeLine;
    let deliveryTypeTextCode = this.typeOfDelivery?.textCode;
    for (let i = 0; i < this.orderStatusTimeLine.length; i++) {
      timeLineData[i].text = this._orderDetailsService.getOrderStatusText(
        this.orderStatusTimeLine[i].textCode,deliveryTypeTextCode
      );
    }
  }

  formatDate(date) {

    if(date){
      let timeFormat = 'hh:mm A';
      if(this.translate && this.translate.currentLang == 'fr'){
        timeFormat   = 'HH:mm'
       }
       return  moment(date).format('MMM DD ' + timeFormat);
    }
    else{
      return '';
    }
   
  }

}

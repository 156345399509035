// angular
import { Injectable } from '@angular/core';

// app
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ViewAllOrdersService {
  constructor(private http: HttpClient) {}

  fetchViewAllOrders(customerId,OrganizationId) {
    const url = `${environment.customerApiUrl}customers/${customerId}/organization/${OrganizationId}/orders`;
    return this.http.get(url);
  }

  
  fetchInprogressOrders(body) {
    const url = `${environment.customerApiUrl}customers/inprogress`;
    return this.http.post(url,body);
  }

  fetchInprogressCurbside(body) {
    if(body?.customerId || body?.orderIds?.length > 0) {
      const url = `${environment.customerApiUrl}customers/curbside/organization/${body.organizationId}/inprogress`;
      return this.http.post(url, body);
    } else {
      return throwError({ error: 'Could not fetch in progress orders because not logged in'});
    }
  }
}

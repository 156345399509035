<div class="list-cart-component">
  <div class="item-details">
    <br />
    <ng-container *ngFor="let item of items; trackBy: trackById">
      <div class="list-value">
        <div class="left-column">
          <div
            class="item"
            [ngClass]="{ disable: item.isDeliverable == false }"
          >
            {{
              item.menuItem.name | formattedLanguage: item.menuItem.translation
            }}
          </div>
          <div
            class="price"
            [ngClass]="{ disable: item.isDeliverable == false }"
            *ngIf="deliveryType !== 'DELIVERYTYPE08'"
          >
            {{ itemPrice(item) | formattedCurrency }} <span class="loyalty" *ngIf="itemPoints(item)">{{ itemPoints(item) }} PTS</span>
          </div>
          <div class="item-description">
            <label *ngIf="item.portion"
              >
              {{
                item.portion.name | formattedLanguage: item.portion.translation
              }}
              <br
            /></label>
            <ng-container *ngFor="let modifier of item?.cartModifiers">
              <div *ngIf="modifier?.cartModifierItems?.length">
                <ng-container
                  *ngFor="
                    let modifierItem of modifier?.cartModifierItems;
                    index as i
                  "
                >
                  <ng-container *ngIf="i == 0"
                    >{{
                      modifier.cartModifier.name
                        | formattedLanguage: modifier.cartModifier.translation
                    }}:
                    {{
                      modifierItem.cartModifierItem.name
                        | formattedLanguage
                          : modifierItem.cartModifierItem.translation
                    }}{{modifierItem.count ? ' x ' + modifierItem.count :' x 1' }}
                  </ng-container>

                  <ng-container *ngIf="i > 0"
                    >,
                    {{
                      modifierItem.cartModifierItem.name
                        | formattedLanguage
                          : modifierItem.cartModifierItem.translation
                    }}<ng-container *ngIf="modifierItem.type =='item'">{{modifierItem.count ? ' x ' + modifierItem.count :' x 1' }}</ng-container></ng-container
                  >
                  <ng-container
                    *ngIf="modifierItem?.cartModifierSubItems?.length"
                  >
                    <ng-container
                      *ngFor="
                        let modifierSubItem of modifierItem?.cartModifierSubItems;
                        index as j
                      "
                    >
                      <ng-container *ngIf="j == 0">
                        ({{
                          modifierSubItem.cartModifierSubItem.name
                            | formattedLanguage
                              : modifierSubItem.cartModifierSubItem.translation
                        }}{{modifierSubItem.count ? ' x ' + modifierSubItem.count :' x 1' }}</ng-container
                      >
                      <ng-container *ngIf="j > 0"
                        >,
                        {{
                          modifierSubItem.cartModifierSubItem.name
                            | formattedLanguage
                              : modifierSubItem.cartModifierSubItem.translation
                        }}{{modifierSubItem.count ? ' x ' + modifierSubItem.count :' x 1' }}</ng-container
                      >
                      <ng-container
                        *ngIf="
                          j == modifierItem.cartModifierSubItems.length - 1
                        "
                        >)</ng-container
                      >
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
            <ng-container *ngFor="let modifier of item?.cartFreeTextModifiers">
              {{
                modifier.cartModifier.name 
                  
              }}: {{modifier.value}} <br>
            </ng-container>
          </div>
        </div>
        <div class="right-column">
          <div class="button" data-qa="list-cart-add" aria-label="listcart add">
            <moduurnv2-item-count
              [menuitem]="item"
              [reviewmenuitem]="isReviewCart"
            >
            </moduurnv2-item-count>
          </div>
          <div
            class="price"
            *ngIf="deliveryType !== 'DELIVERYTYPE08'"
            [ngClass]="{ disable: item.isDeliverable == false }"
          >
          <span *ngIf="itemTotalPrice(item)">{{ itemTotalPrice(item) | formattedCurrency }} </span>  <span *ngIf="itemTotalPoints(item)" ><span *ngIf="itemTotalPrice(item)">+</span>{{itemTotalPoints(item)}} PTS</span>
          </div>
          <div class="edit">
            <div
              class="special-button icon"
              (click)="editListCart(item)"
              data-qa="list-cart-edit"
              aria-label="listcart edit"
              *ngIf="checkModifier(item)"
            >
              <div
                class="text"
                data-qa="list-cart-edit"
                aria-label="listcart edit"
              >
                {{ 'edit-item' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>

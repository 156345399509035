import { CustomerFavourites } from '../models';

export class FetchCustomerFavouritesList {
    static readonly type = '[CUSTOMERFAVOURITES] fetchCustomerFavourites';
    
    constructor(public payload?: string) {}
}


export class UpdateFavouriteLocation {
  static readonly type = '[CUSTOMERFAVOURITES] Update Favourite_Location';

  constructor(public payload: any) {}
}
<div style="width: 100%;height: 100vw;">
<div class="map-container showmap" >

  <div class="map-display" id="map">
    <div class="row">
      <div class="main-header">
      <div class="heading">

        <span>{{'Add New Address'| translate}}</span>
      </div>

    </div>

  </div>
  <img
  src="assets/images/back-arrow.svg"
  class="icon"
  class="icon"
  data-qa="close-map"
  (click)="closeMap()"
  alt="back"
/>
      <div class="secondcolumn">
        <div class="input">
          <div id="geocoder" class="search-location"></div>
        </div>
      </div>

    <div class="loader"></div>
  </div>

  <div class="map-controls">
    <div class="control-header">{{ 'selected-delvry-adrs' | translate }}</div>
    <div id="location" class="location"></div>
    <hr class="border" />
    <button
      class="primary-button"
      (click)="continuetoDetails($event)"
      data-qa="address-continue"
      aria-label="address continue"
      [disabled]="isDisabled"
      [ngClass]="{ disable: isDisabled }"
    >
      {{ 'continue-adrs' | translate }}
    </button>
    <!-- <moduurnv2-address-details
      *ngIf="viewAdrsDetModalStatus"
      [isAddressBook]="isAddressBook"
      [modalAdrsDetailsStatus]="viewAdrsDetModalStatus"
      (closeEvent)="closeAdrsDetails()"
    >
    </moduurnv2-address-details> -->
  </div>
</div>
</div>

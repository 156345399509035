<div class="row">
  <div class="auth-header-container">
    <div class="head-icon-outer" *ngIf="isMobileView">
      <img
        type="button"
        data-qa="go-back"
        (click)="goBack()"
        [src]="loginLeftArrowIcon"
        class="left-icon"
        aria-label="back button"
        alt="go back button"
      />
    </div>
    <img
      *ngIf="!isMobileView"
      src="/assets/images/Close.svg"
      data-qa="close-button"
      (click)="closLogin()"
      class="close"
      aria-label="close"
      alt="close"
    />
    <span class="auth-header">{{'welcome-back'| translate}}!</span>
    <span class="auth-sub-header">{{ 'please-login' | translate }}</span>
  </div>
  <div class="sign-in-outer-container">
    <form [formGroup]="loginForm" (ngSubmit)="loginUsingEmail(loginBtn)">
      <label for="email" class="email-container">{{
        'email-id' | translate
      }}</label>
      <div class="row form-group">
        <input
          type="email"
          name="email"
          class="email-text"
          placeholder="{{ 'Email' | translate }}"
          formControlName="email"
          id="email"
          required
          [pattern]="emailRegex"
          autofocus
        />
        <div
          class="text-danger"
          *ngIf="
            (loginFormControl.email.touched || formSubmitted) &&
            loginFormControl.email.errors?.required
          "
        >
          {{ 'email-required' | translate }}
        </div>
        <div
          class="text-danger"
          *ngIf="
            loginFormControl.email.touched &&
            loginFormControl.email.errors?.inValidEmail
          "
        >
          {{ 'valid-email' | translate }}
        </div>
        <div
          class="text-danger"
          *ngIf="errorInLogin"
        >
          {{ errorInLogin | translate }}
        </div>
      </div>
      <label for="password" class="email-container">{{
        'password' | translate
      }}</label>
      <div class="row form-group password-input">
        <input
          type="password"
          id="password"
          name="password"
          class="form-control md-input"
          class="password-text"
          placeholder="{{ 'password' | translate }}"
          formControlName="password"
          required
          password-toggle
        />
        <div
          class="text-danger"
          *ngIf="
            (loginFormControl.password.touched || formSubmitted) &&
            loginFormControl.password.errors?.required
          "
        >
          {{ 'password-required' | translate }}
        </div>
      </div>
      <div class="row remember-forgot-container">
        <div class="md-checkbox-outer">
          <div class="md-checkbox options">
            <label class="options-label">
            <input
              type="checkbox"
              id="remember"
              data-qa="remember-me"
              name="remember"
              formControlName="isRememberMe"
              attr.aria-label="{{ 'Remember Me' | translate }}"
              class="dark"
            />
            <span class="checkmark"></span>
          </label>
            <label class="remember-me" for="remember">
              {{ 'remember-me'| translate }}</label>
            <button
              (click)="forgotPassword()"
              data-qa="forgot-password"
              class="forgot-password"
              type="button"
              attr.aria-label="{{ 'forgot-password' | translate }}"
            >
              {{ 'forgot-password?' | translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="row signup-tag-container">
        <span
          >{{ 'dont-have-account' | translate }}
          <button
            class="signup-tag"
            data-qa="sign-up"
            type="button"
            (click)="register()"
            attr.aria-label="{{ 'signup' | translate }}"
          >
            {{ 'signup' | translate }}
          </button></span
        >
      </div>
      <div class="row sign-in-button-container">
        <button
          class="btn-submit"
          data-qa="sign-in"
          #loginBtn
          id="login-submit"
          type="submit"
          attr.aria-label="{{ 'login' | translate }}"
        >
          {{ 'login' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>

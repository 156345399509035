<div class="row">
    <div class="header-container">
      <div
        class="head-icon-outer"
        data-qa="close-lounge-popup"
        aria-label="close"
        (click)="closeSelectionModal()"
      >
        <img [src]="backButton" class="left-icon" alt="back button"/>
      </div>
      <span class="header" aria-label="orgName">{{orgName}}</span>
    </div>
    <div class="action-outer-container">
      At: {{loungeName}}. <br/>
      <span class="table-number">{{ 'table'| translate }}: {{selectedVenue?.name}}</span>
      <label class="sub-text">{{ 'change-table-text'| translate }}</label>
      <ng-container *ngIf="allowTableManualEntering; then tableManualEntryTemplate; else tableSelectionTemplate"></ng-container>
      <ng-template #tableManualEntryTemplate>
         <input type="text" placeholder="Enter table number" class="manual-entry" [(ngModel)]="enteredTable" data-qa="enter-table-number">
      </ng-template>
      <ng-template #tableSelectionTemplate>
      <ng-container *ngIf="tables;else noTablesAvailable">
        <div class="custom-selected">
          <div class="selected">
            <div
              class="selected-text"
              (click)="openTableSelectionList($event)"
              id="location"
            >
            <ng-container *ngIf="!showTableList && selectedVenue?.name;else noSelection">
              <div class="text">{{ selectedVenue?.name }}</div>
            </ng-container>
            <ng-template #noSelection>
              <div class="placeholder-text">{{ 'enter-table'| translate }}</div>
            </ng-template>
              <img class="image" src="assets/images/Chevron_Down.svg" alt="chevron down" />
            </div>
            <div
              class="selection-list"
              [ngClass]="{ show: showTableList }"
            >
              <div
                class="selection-item"
                *ngFor="let table of tables"
                (click)="tableSelection(table, $event)"
              >
                {{ table.name }}
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #noTablesAvailable>
        {{ 'no-tables'| translate }}
      </ng-template>
    </ng-template>
    <div *ngIf="requiredError" class="error">
      {{ requiredError }}
    </div>
      <!-- <label for="time" class="time-heading">When</label>
      <moduurnv2-inline-plain-time-picker></moduurnv2-inline-plain-time-picker> -->

    </div>
    <button class="block-button proceed-btn" (click)="proceed()" data-qa="suubmit" aria-label="submit">
      {{ 'Continue-adrs'| translate}}
    </button>
  </div>
  
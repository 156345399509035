import { Component } from '@angular/core';
import { AddressListBaseComponent } from '@moduurnv2/libs-orderingapp/src/features/ui/base/address-list-base-component';
import { AddressDetailsComponent } from './components/address-details/address-details.component';
@Component({
  selector: 'moduurnv2-address-list',
  templateUrl: './address-list.component.html',
  styleUrls: ['./address-list.component.scss'],
})
export class AddressListComponent extends AddressListBaseComponent {
  openMapPopup() {
    localStorage.removeItem('tempSelectedAdrsLocation');
    this.openMap();
  }
  // set data(data: any) {
  //   this.modalChangeAdrsStatus = data.modalChangeAdrsStatus;
  //   this.isAddressBook = data.isAddressBook;
  // }
}

import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import {
  SetPushNotification,
  PushNotificationToken,
  PushNotificationState,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Store } from '@ngxs/store';
import { SweetAlertService } from '@moduurnv2/libs-orderingapp/src/core';
@Injectable()
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private store: Store,
    public sweetAlert: SweetAlertService
  ) {
    this.angularFireMessaging.messaging.subscribe((_messaging: any) => {
      // _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging._next = (payload: any) => {
        this.onreceiveMessage(payload);
      };
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
  }
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        let pushNotificationToken = {} as PushNotificationToken;
        pushNotificationToken.token = token;
        this.store.dispatch(new SetPushNotification(pushNotificationToken));
      },
      (err) => {
        //writelog 'Unable to get permission to send notification.
      }
    );
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      this.currentMessage.next(payload);
    });
  }
  onreceiveMessage(payload) {
    if (payload && payload.data && payload.data.type) {
      let type = payload.data.type;
      if (type === 'order') {
        let notification = JSON.parse(payload.data.notification);

        this.sweetAlert.toast('info', notification.body);
        // const NotificationOptions = {
        //   body: notification.body,
        //   data: payload.data,
        //   icon: notification.icon,
        //   click_action: notification.click_action,
        // };

        // navigator.serviceWorker
        //   .getRegistration('/firebase-cloud-messaging-push-scope')
        //   .then((registration) => {
        //     registration.showNotification(
        //       notification.title,
        //       NotificationOptions
        //     );
        //   });
      }
    }
  }

  reciveNotificationIfEnabled() {
    if ('Notification' in window) {
      if (Notification.permission === 'granted') {
        this.requestPermission();
        this.receiveMessage();
        //this.message = this.currentMessage;
        //check for notificationType
        //here we need to check server whether the user already subscribed for messages (notificationType). Then we don't need to show the notification box.
      }
    }
  }

  checkForNotificationPopup() {
    if (!('Notification' in window)) {
      return false;
    }
    if (Notification.permission === 'granted') {
      return false;
    } else if (Notification.permission !== 'denied') {
      return true;
    }
  }
  async askPermission() {
    let vm = this;
    await Notification.requestPermission().then(function (permission) {
      if (permission === 'granted') {
        vm.requestPermission();
        vm.receiveMessage();
        return true;
      } else {
        return false;
      }
    });
  }
}

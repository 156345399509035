import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'itemSearch', pure: false })
export class ItemSearch implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} searchText
   * @returns {any[]}
   */
  transform(
    items: any[],
    searchText: string,
    searchfield1: string = '',
    searchfield2: string = ''
  ): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter((it) => {
      if (searchfield1 && searchfield2) {
        return (
          it[searchfield1].toLocaleLowerCase().includes(searchText) ||
          it[searchfield2].toLocaleLowerCase().includes(searchText)
        );
      } else if (searchfield1) {
        return it[searchfield1].toLocaleLowerCase().includes(searchText);
      } else if (searchfield2) {
        return it[searchfield2].toLocaleLowerCase().includes(searchText);
      } else return it.toLocaleLowerCase().includes(searchText);
    });
  }
}

<ng-container *ngIf="isMobileView">
    <ng-container *ngIf="locationDefaultStatus$ | async as locationStatus">
        <div *ngIf="locationStatus && (!locationStatus.isOpenToday || !locationStatus.isCurrentlyOpen)"  >
            <div class="view-notification" >
                <span>{{ 'locCloseMsg' | translate }}</span>          
            </div>
        </div>
    </ng-container>
</ng-container>
<ng-container *ngIf="!isMobileView">    
    <ng-container *ngIf="locationDefaultStatus$ | async as locationStatus">
        <div *ngIf=" locationStatus && (!locationStatus.isOpenToday || !locationStatus.isCurrentlyOpen)"  >
            <br/>
            <div class="view-notification-desktop" >
                <span>{{ 'locCloseMsg' | translate }}</span>                             
            </div>
        </div>
    </ng-container>
</ng-container>
import { State, Selector, StateContext, Action, Store } from '@ngxs/store';
import { SetSelectedOrderId,SetInprogressOrderIds } from '../actions';
import { Injectable } from '@angular/core';
import { patch } from '@ngxs/store/operators';
export class SelectedOrder {
  selectedOrderIdToView: string;
  inProgressOrderIds:any;
}

@State<SelectedOrder>({
  name: 'selectedOrderIdToView',
  defaults: {
    selectedOrderIdToView:null,
    inProgressOrderIds:[]
  },
})
@Injectable()
export class SelectedOrderState {
  constructor() {}

  @Selector()
  static getSelectedOrderId(state: SelectedOrder){
      return state.selectedOrderIdToView;
  }

  @Action(SetSelectedOrderId)
  SetSelectedOrderId({ setState }: StateContext<SelectedOrder>,  payload : SetSelectedOrderId) {
      setState(
        patch({
          selectedOrderIdToView:payload.payload
        })
        );
  }

  
  @Selector()
  static getInprogressOrderIds(state: SelectedOrder){
      return state.inProgressOrderIds;
  }

  @Action(SetInprogressOrderIds)
  setInprogressOrderIds({ setState }: StateContext<SelectedOrder>,  payload : SetInprogressOrderIds) {
      setState(
        patch({
          inProgressOrderIds:payload.payload
        })
    );
  }
  
}

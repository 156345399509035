import { Component, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import {
  EmailLoginBaseComponent,
  SharedService,
} from '@moduurnv2/libs-orderingapp';
import {
  AuthEmailState,
  FetchGiftLoyaltyCards,
  SetAgreeToTerms,
  SetLoginFrom,
} from '@moduurnv2/libs-orderingapp/src/core';
import { EmailSignUpComponent } from '../../../../login/components/email-sign-up/email-sign-up.component';

import { ModalService } from '../../../../shared/modal/modal.service';
import { LoginDesktopComponent } from '../../../../login/components/login-desktop/login-desktop.component';
import { inherits } from 'util';
import { AnalyticsHandler } from '@moduurnv2/core/services';
@Component({
  selector: 'moduurnv2-giftloyalty-login',
  templateUrl: './gift-loyalty-login.component.html',
  styleUrls: ['./gift-loyalty-login.component.scss'],
})
export class GiftLoyaltyLoginComponent extends EmailLoginBaseComponent {
  pageToShow;
  isSignupClicked: boolean = false;
  setTerms: boolean = false;
  constructor(
    store: Store,
    router: Router,
    _shared: SharedService,
    modal: ModalService,
    analyticsHandler:AnalyticsHandler
  ) {
    super(store, router, _shared, modal,analyticsHandler);
  }
  isShowAuth = false;
  set modalClose(modalRef: any) {
    this._modalClose = () => {
      this.modal.closeModal(modalRef);
    };
  }

  register() {
    this.isSignupClicked = true;
    if(!this.setTerms)
      return;
    this.isShowAuth = true;
    if (!this.isMobileView) {
      this.store.dispatch(new SetLoginFrom('giftAndLoyalty'));
      this.modal.showMediumModalDesktop(EmailSignUpComponent);
    } else this.pageToShow = 'REGISTER';
  }
  continue() {
    this.isShowAuth = true;
    if (!this.isMobileView) {
      this.store.dispatch(new SetLoginFrom('giftAndLoyalty'));
      this.modal.showMediumModalDesktop(LoginDesktopComponent);
    } else this.pageToShow = 'LOGIN';
  }
  showEmailAuthModal($event) {
    if ($event == false) {
      this.pageToShow = undefined;
      this.isShowAuth = false;
    }
  }

  changeTerms(event){
    this.setTerms = !this.setTerms;
    this.store.dispatch(new SetAgreeToTerms(this.setTerms));
  }
}

<div class="add-card-container">
  <form [formGroup]="cardDetailsForm" class="card-form-container">
    <div class="group">
      <input
        formControlName="name"
        required
        data-qa="card-name"
        aria-label="name on card"
      />
      <span class="highlight"></span>
      <span class="bar"></span>
      <label>{{ 'name' | translate }}</label>
      <div
        *ngIf="
          cardDetailsForm.controls.name.touched &&
          !cardDetailsForm.controls.name.valid
        "
        class="alert"
      >
        {{ 'name-alert' | translate }}
      </div>
    </div>
    <div class="group">
      <input
        type="tel"
        formControlName="number"
        [maxlength]="cardType ? cardType.minlength : 19"
        appCreditCard
        required
        (ngModelChange)="clearvalues()"
        data-qa="card-number"
        aria-label="card number"
      />
      <span class="highlight"></span>
      <span class="bar"></span>
      <label>{{ 'card-num' | translate }}</label>
      <div
        *ngIf="
          cardDetailsForm.controls.number.touched &&
          !cardDetailsForm.controls.number.valid
        "
        class="alert"
      >
        {{ 'card-alert' | translate }}
      </div>
    </div>
    <div class="group-by">
      <div class="group expiry">
        <input
          type="tel"
          formControlName="expiry"
          maxlength="7"
          (keyup)="formatDate($event)"
          data-qa="card-expiry"
          aria-label="expiry of card"
          required
        />
        <span class="bar"></span>
        <label>{{ 'expiry-date' | translate }}</label>
        <div
          *ngIf="
            cardDetailsForm.controls.expiry.touched &&
            !cardDetailsForm.controls.expiry.valid
          "
          class="alert"
        >
          {{ 'date-error' | translate }}
        </div>
      </div>
      <div class="group cvv">
        <input
          type="tel"
          formControlName="cvd"
          [maxlength]="cardType ? cardType.cvvLength : 3"
          (keyup)="checkFormat($event)"
          data-qa="card-cvc"
          aria-label="card cvc"
          required
        />
        <span class="highlight"></span>
        <span class="bar"></span>
        <label>CVC</label>
        <div
          *ngIf="
            (cardDetailsForm.controls.cvd.touched &&
              !cardDetailsForm.controls.cvd.valid) ||
            (cardDetailsForm.controls.cvd.errors &&
              cardDetailsForm.controls.cvd.errors.minlength)
          "
          class="alert"
        >
          {{ 'cvv-error' | translate }}
        </div>
      </div>
    </div>
    <div class="group address" *ngIf="paymentGateway && paymentGateway && paymentGateway.textCode == 'PAYMENTGATEWAY02' && isStreetNameValidationRequired">
      <input
        formControlName="address"
        required
        data-qa="card-address"
        aria-label="Address"
        minlength="3"
      />
      <span class="highlight"></span>
      <span class="bar"></span>
      <label class="placeholder">{{ 'address' | translate }}</label>
      <div
        *ngIf="
          cardDetailsForm.controls.address.touched &&
          !cardDetailsForm.controls.address.valid
        "
        class="alert"
      >
        {{ 'address-alert' | translate }}
      </div>
      <div class="description-container">{{ 'address-example'  | translate}} </div>
  
    </div>
    <div class="group-by" *ngIf="paymentGateway && paymentGateway &&  paymentGateway.textCode == 'PAYMENTGATEWAY02'">
      <div  class="group">
          <input
            formControlName="zipcode"
            required
            data-qa="card-zipcode"
            aria-label="Zip Code"
            pattern="\s*(\S\s*){4,}"
          />
          <span class="highlight"></span>
          <span class="bar"></span>
          <label class="placeholder">{{ 'zipcodeOrPostal' | translate }}</label>
          <div
            *ngIf="
              cardDetailsForm.controls.zipcode.touched &&
              !cardDetailsForm.controls.zipcode.valid
            "
            class="alert"
          >
            {{ 'zipcodeOrPostalError' | translate }}
          </div>
        </div>

  </div>
  </form>
  <!-- <div class="options-container" *ngIf="user && user.user_id">
    <div class="options">
      <label class="options-label" aria-label="check to save card"
        >{{ 'save-card-future' | translate }}
        <input
          type="checkbox"
          data-qa="save-card"
          aria-label="save card"
          [(ngModel)]="saveToAccount"
        />
        <span class="checkmark"></span>
      </label>
      <p class="sub-text">
        {{ 'save-card-sub-text' | translate }}
      </p>
    </div>

  </div> -->

  <div class="button-container">
    <button class="cancel-btn" (click)="onCancel()" data-qa="cancel">
      {{ 'cancel' | translate }}
    </button>
    <button
      class="btn-submit"
      (click)="onSubmit()"
      data-qa="continue-step"
      type="submit"
      [disabled]="!cardDetailsForm.valid"
    >
      {{ 'continue-adrs' | translate }}
    </button>

    <!-- <button
        type="button"
        class="add-card-desktop"
        (click)="onSubmit()"
        data-qa="add-card-desktop"
        aria-label="add card-desktop"
        [disabled]="!cardDetailsForm.valid"
      >
        {{ 'continue' | translate }} <span class="arrow">></span>
      </button> -->
  </div>
</div>

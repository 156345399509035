import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import {
  BaseComponent,
  environment,
  LocationState,
  SharedService,
  WhitelabelState,
  TypeOfLandingPages,
  LocationDefaultState,
  LocationDefaults
} from '@moduurnv2/libs-orderingapp/src';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ModalService } from '../../../shared/modal/modal.service';


@Component({
  selector: 'moduurnv2-splash-message',
  templateUrl: 'splash-message.component.html',
  styleUrls: ['splash-message.component.scss']
})
export class SplashMessageComponent extends BaseComponent implements OnInit {
  @Select(WhitelabelState.getLandingPage) landingPage$: Observable<any>;
  @Select(LocationState.getSelectedLocation) selectedLocation$: Observable<any>;

  @Select(LocationDefaultState.getLocationDefaults)
  locationDefaults$: Observable<LocationDefaults>;

  orgInfo: any;
  restaurantLogo: string;
  imagePath: string;
  _modalClose: any = null;
  splash: any;
  locationInfo: any;
  pagesToView: number = 0;
  splashIndex: number = 0;
  loader: boolean = true;
  orgSplash;
  locSplash:boolean;
  isMobileView:boolean;
  typeOfSplashScreen :string;
  constructor(private store: Store,private router:Router, private modal: ModalService, private shared: SharedService) {
    super();
  }

  
  set data(data: any) {
    if(data){
        this.typeOfSplashScreen = data.typeOfSplashScreen;
        let whiteLabel = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
        if (whiteLabel) {
          this.restaurantLogo =
            environment.imagebaseurl + whiteLabel.restaurantLogo;
        }
        this.imagePath = environment.imagebaseurl;
        this.orgSplash = sessionStorage.getItem('OrgSplash');
    }
  }


  set modalClose(modalRef: any) {
    this._modalClose = () => {
      // this.modal.closeModal(modalRef);
      this.modal.closeUserDefinedComponentModals(1,{isReverseOrder:true})
    };
  }
  
  ngOnInit() {    
     this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
    this.loader=true;
    setTimeout(() => {

      if(this.typeOfSplashScreen == TypeOfLandingPages.ORGANIZATIONLEVEL){
      this.landingPage$
      .pipe(takeUntil(this.destroy$))
      .subscribe((landingPage) => {
        if (landingPage) {
          this.orgInfo = landingPage;
          this.orgInfo['level'] = 0; // Means First Modal (Organization Level)
          sessionStorage.setItem('OrgSplash', 'true');
        }
        });
      }

    // setTimeout(() => {
    if((this.typeOfSplashScreen == TypeOfLandingPages.LOCATIONLEVEL) && this.router.url != '/locations'){
      
    // this.selectedLocation$
    // .pipe(takeUntil(this.destroy$))
    // .subscribe((location) => {
    //   console.log('selected location',location);
    //   if (location && location.landingPage) {
    //     this.locationInfo = location.landingPage.find(page => page.isPublished);
    //     this.locSplash = !Object.keys(sessionStorage).includes(`Splash ${location._id}`);
    //     this.locationInfo['level'] = 1; // Means Second Modal (Location Level)
    //     if(this.locationInfo && this.locationInfo.isPublished && this.locSplash){
    //       sessionStorage.setItem(`Splash ${location._id}`, 'true');
    //     }
    //   }
    // });

     this.locationDefaults$
    .pipe(takeUntil(this.destroy$))
    .subscribe((locationDefalult:any) => {    
       let location = locationDefalult.locationData;       
       if (location && location.landingPage) {
        this.locationInfo = location.landingPage.find(page => page.isPublished);
        this.locSplash = !Object.keys(sessionStorage).includes(`Splash ${location._id}`);
        this.locationInfo['level'] = 1; // Means Second Modal (Location Level) locationData
        if(this.locationInfo && this.locationInfo.isPublished && this.locSplash){
          sessionStorage.setItem(`Splash ${location._id}`, 'true');
        }
      }
      
    });
    }
      

    if (this.orgInfo && !this.orgSplash && this.orgInfo.isPublished && this.locationInfo && this.locationInfo.isPublished) {
      this.pagesToView = 2;
      let array = new Array();
      array.push(this.orgInfo, this.locationInfo);
      this.splash = array;
    } else if (
      (this.orgInfo && !this.orgSplash && this.orgInfo.isPublished) ||
      (this.locationInfo && this.locationInfo.isPublished && this.locSplash)
    ) {
      this.pagesToView = 1;
      this.splash = (this.orgInfo && !this.orgSplash) ? this.orgInfo : this.locationInfo;
    }
    this.loader = false;

    }, 900);  
  }


  changeIndex(event) {
    event.stopPropagation();
    this.splashIndex = 1;
    // this.loader = true;
    // setTimeout(() => {
    //   this.loader = false;
    // }, 2000);
  }

  close() {
    // window.history.back();
    //  if (this._modalClose) this._modalClose();
    if(this.typeOfSplashScreen == TypeOfLandingPages.LOCATIONLEVEL)
    this.modal.closeAllModals();
    else
    this.modal.closeUserDefinedComponentModals(1,{isReverseOrder:true})
  }

  
  closeLandingModal(splashIndx){
    if(splashIndx != undefined && this.splash && this.splash?.length > 0) {
      this.splashIndex += 1;
      if((this.splashIndex + 1) > this.splash.length){
        this.close();
      }
    } else
      this.close()
  }

  get checkContent() {
    return this.splash ? (this.splash[this.splashIndex]?.title.trim() || this.splash[this.splashIndex]?.message.trim()) : '';
  }

  get checkNumberOfRows() {
    if(this.pagesToView > 1 && this.splash && this.splash?.length > 1){
    const noImage = !this.splash[this.splashIndex]?.imageUrl;
    const noContent = !(this.splash[this.splashIndex]?.title.trim() || this.splash[this.splashIndex]?.message.trim());
    return noImage || noContent;
    } else 
    return false;
    // return (!!this.splash[this.splashIndex]?.title.trim() || !!this.splash[this.splashIndex]?.message.trim()) && !this.splash[this.splashIndex]?.imageUrl;
  }
}

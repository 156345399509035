import { Component, OnInit, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import {
  CartState,
  LocationState,
  SweetAlertService,
  SetSelectedLocation,
  FetchLocationDefaults,
  ClearCart,
  BaseComponent,
  DeliveryState,
  Delivery,
  Location,
  FetchFreshSelectedLocation,
  VenueManagementState,
  LocationDefaultState,
  Venue
} from '@moduurnv2/libs-orderingapp/src';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { FuturePickerComponent } from '../future-picker/future-picker.component';
import { ModalService } from '../modal/modal.service';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { RoomUpdateSelectionComponent } from './../../venue-management/components/room-update-selection/room-update-selection.component';
import { RoomTimeSelectionComponent } from './../../venue-management/components/room-time-selection/room-time-selection.component';
@Component({
  selector: 'moduurnv2-inline-time-picker',
  templateUrl: 'inline-time-picker.component.html',
  styleUrls: ['inline-time-picker.component.scss'],
})
export class InlineTimePickerComponent extends BaseComponent implements OnInit {
  @Select(CartState.getFutureDate) futureDate$: Observable<any>;
  @Select(DeliveryState.getSelectedDelivery) selectedDelivery$: Observable<
    Delivery
  >;
  @Input('static') static: boolean = true;
  futureDate: string = 'Order for Now';
  isEnableDeliverySchedule: boolean = false;

  @Select(CartState.getETA) eta$: any;
  selectedDelivery: any;
  eta: any;
  @Select(LocationState.getSelectedLocation) getSelectedLocation$;
  @Select(VenueManagementState.getSelectedRoom) selectedRoom$: Observable<
    Venue
  >;
  @Select(CartState.getFutureEndDateTime) futureEndDate$: Observable<any>;
  locationData: Location;
  currentFutureTime: any;
  selectedVenue: Venue;
  futureEndDate:string='';
  constructor(
    private store: Store,
    private modal: ModalService,
    private translate: TranslateService,
    public sweetAlert: SweetAlertService,
    public router: Router
  ) {
    super();
    this.eta$.pipe(takeUntil(this.destroy$)).subscribe((eta) => {
      if (eta) {
        this.eta = eta.message;
      }
    });
    this.selectedDelivery$
      .pipe(takeUntil(this.destroy$))
      .subscribe((delivery) => {
        if (delivery) {
          this.selectedDelivery = delivery;
        }
      });
    this.selectedRoom$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selRoom) => {
        if (selRoom) {
          this.selectedVenue = selRoom;
        }
      });
  }

  ngOnInit() {
    this.listenForLocation();
    this.futureDate$.pipe(takeUntil(this.destroy$)).subscribe((response) => {
      console.log(response);
      this.currentFutureTime = response;
      this.futureEndDate$.pipe(takeUntil(this.destroy$)).subscribe((endDate) => {
        if(endDate){
          this.futureEndDate = endDate;
        }
      });
    });
  }

  displayTime(futureDate) {
    if (futureDate) {
      let timeFormat = 'hh:mm A';
      if(this.translate && this.translate.currentLang == 'fr'){
        timeFormat   = 'HH:mm'
       }
      const futureTime = moment(futureDate);
      let timeText = futureTime.format('MMM DD '+ timeFormat);
      const usePicker = this.store.selectSnapshot(LocationState.getTimePicker);
      const locationDefaultsDetails = this.store.selectSnapshot(LocationDefaultState.getLocationDefaults);
      const timeSlot = locationDefaultsDetails.locationData?.orderSettings?.futureOrder?.overrideTimeSlot?.isAllow ? 
                        locationDefaultsDetails.locationData?.orderSettings?.futureOrder?.overrideTimeSlot?.timeSlot : 30;
  
      if (usePicker === false)
      timeText += ` - ${futureTime.add({ minutes: timeSlot }).format(timeFormat)}`;
      return timeText;
    }
    return 'Order for Now';
  }

  futureOrderFromOrganization() {
    if(this.selectedDelivery?.textCode == 'DELIVERYTYPE10'){
      this.showVenueRoomDeliveryPopup();
    } else {
    const location = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    this.store.dispatch(new FetchFreshSelectedLocation());
    this.modal.showFuture(FuturePickerComponent, {
      data: {
        orderSettings: location.orderSettings,
        locationName: location.name,
        location: location,
      },
      whenClosed: (data) => {
        if (data) this.orderFromOrganization(location, data);
      },
    });
  }
  }

  showVenueRoomDeliveryPopup() {
    this.modal.showMediumModalDesktop(RoomTimeSelectionComponent);
  }

  async orderFromOrganization(location, data?) {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if (location.isLocationOpen) {
      if (selectedLocation && selectedLocation._id != location._id) {
        let selectedCart = this.store.selectSnapshot(CartState.getCart);
        if (selectedCart) {
          let vm = this;
          await this.sweetAlert
            .confirm(
              this.translate.instant('are-you-sure'),
              this.translate.instant('loc-change-clear-cart')
            )
            .then((choice) => {
              if (choice) {
                this.router.navigate([`locations/${location._id}/home`]);
                this.store.dispatch(new SetSelectedLocation(location._id));
                this.store.dispatch(new FetchLocationDefaults(data));
                this.store.dispatch(new ClearCart()); //Changes for MV2-166
              } else {
                return;
              }
            });
        } else {
          this.router.navigate([`locations/${location._id}/home`]);
          this.store.dispatch(new SetSelectedLocation(location._id));
          this.store.dispatch(new FetchLocationDefaults(data));
          this.store.dispatch(new ClearCart()); //Changes for MV2-166
        }
      } else {
        this.store.dispatch(new SetSelectedLocation(location._id));
        this.store.dispatch(new FetchLocationDefaults(data));
        this.router.navigate([`locations/${location._id}/home`]);
      }
    }
  }

  showChange() {
    const location = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );

    // if(location?.orderSettings?.futureOrder?.isAllow && ((this.selectedDelivery.textCode != 'DELIVERYTYPE10') || (this.selectedDelivery.textCode == 'DELIVERYTYPE10' && (this.selectedVenue && !this.selectedVenue.allowSchedule)))){
    //   return true;
    // }
    if(location?.orderSettings?.futureOrder?.isAllow){
      return true;
    }
    return false;
  }

  listenForLocation() {
    this.getSelectedLocation$
      .pipe(takeUntil(this.destroy$))
      .subscribe((locationData) => {
        if (locationData.loading !== true) {
          const location = locationData as Location;
          this.locationData = location;
          if (location.orderSettings.futureOrder && location.orderSettings.futureOrder.isAllow)
            this.isEnableDeliverySchedule = true;
        }
      });
    this.store.dispatch(new FetchFreshSelectedLocation());
  }

  get iconURL() {
    if(this.static)
      return `/assets/images/Calendar_Icon_Small.svg`;
    return `/assets/images/Calendar_Icon_Small_Active.svg`;
  }

  get getDisplayTime() {
    let timeFormat = 'hh:mm A';
    if(this.translate && this.translate.currentLang == 'fr'){
      timeFormat   = 'HH:mm'
     }
    if (this.currentFutureTime) {
      const futureTime = moment(this.currentFutureTime);
      let timeText = futureTime.format('MMM DD ' + timeFormat);
      const usePicker = this.store.selectSnapshot(LocationState.getTimePicker);
      const locationDefaultsDetails = this.store.selectSnapshot(LocationDefaultState.getLocationDefaults);
      const timeSlot = locationDefaultsDetails.locationData?.orderSettings?.futureOrder?.overrideTimeSlot?.isAllow ? 
                        locationDefaultsDetails.locationData?.orderSettings?.futureOrder?.overrideTimeSlot?.timeSlot : 30;
  
      if(usePicker === false){
        if(this.futureEndDate && this.selectedDelivery.textCode == 'DELIVERYTYPE10'){
          timeText += ` - ${moment(this.futureEndDate).format(timeFormat)}`;
        } else {
          timeText += ` - ${futureTime.add({ minutes: timeSlot }).format(timeFormat)}`;
        }
      }

      return timeText;
    }
    return 'Order for Now';
  }
}

import { State, Selector, StateContext, Action, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { MenuList, MenuItemList } from '../models';
import {
  FetchMenuList,
  SetCategory,
  SetMenuItem,
  ChangeCategory,
  ClearMenuList
} from '../actions';
import { Injectable } from '@angular/core';
import { MenuListService } from '../services';
import { tap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { CategoryList } from '../models/menu-list.interface';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectedOrderState } from './selected-order.state';
import { throwError } from 'rxjs';

export class MenuListStateModel {
  menuList: MenuList;
  selectedCategory: CategoryList;
  selectedMenuItem: MenuItemList;
}

@State<MenuListStateModel>({
  name: 'menulist',
  defaults: {
    menuList: null,
    selectedCategory: null,
    selectedMenuItem: null,
  },
})
@Injectable()
export class MenuListState {
  constructor(
    private menulists: MenuListService,
    private store: Store,
    private route: ActivatedRoute
  ) {}

  @Selector()
  static getMenuList(state: MenuListStateModel) {
    return state.menuList;
  }

  @Selector()
  static getMenuListCategories(state: MenuListStateModel) {
    return state.menuList.categories;
  }

  @Selector()
  static getMenuListCategory(state: MenuListStateModel) {
    return state.selectedCategory;
  }

  @Selector()
  static getSelectedMenuItem(state: MenuListStateModel) {
    return state.selectedMenuItem;
  }

  @Action(FetchMenuList)
  fetchMenuList(
    ctx: StateContext<MenuListStateModel>,
    { payload }: FetchMenuList
  ) {
    return this.menulists.fetchMenuList().pipe(
      tap((response) => {
        if (response) {
          ctx.setState(
            patch({
              menuList: response,
            })
          );

          const { categories } = response as MenuList;
          if (categories && categories.length > 0) {
            // let existingSelectedCategory = this.store.selectSnapshot(MenuListState.getMenuListCategory);
            // let categoryFromRoute;
            // if(this.route.snapshot.params.sectionId){
            //   categoryFromRoute =this.route.snapshot.params.sectionId;
            // }
            // if(!existingSelectedCategory) {
            //   let selectedCategory
            //   if(categoryFromRoute){
            //     selectedCategory =
            //     categories.find((category) => category._id === categoryFromRoute)
            //   } else {
            //     selectedCategory =
            //     categories.find((category) => category._id === payload) ||
            //     categories[0];
            //   }
            //    ctx.dispatch(new SetCategory(selectedCategory));
            // }
            const state = ctx.getState();
            let category = null;
            let selectedCategory = state.selectedCategory?state.selectedCategory:null;
            if(selectedCategory){
              category = categories.find((category) => category._id === selectedCategory._id);
            }else{
              category =
              categories.find((category) => category._id === payload) ||
              categories[0];
            }
            ctx.dispatch(new SetCategory(category));
          }
        } else throw response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  @Action(ChangeCategory)
  ChangeCategory(
    { setState }: StateContext<MenuListStateModel>,
    { payload }: ChangeCategory
  ) {
    setState(
      patch({
        selectedCategory: payload,
        selectedMenuItem: null,
      })
    );
  }
  @Action(SetCategory)
  setCategoryList(
    { setState }: StateContext<MenuListStateModel>,
    { payload }: SetCategory
  ) {
    setState(
      patch({
        selectedCategory: payload,
      })
    );
  }

  @Action(SetMenuItem)
  setSelectedMenuItem(
    { setState }: StateContext<MenuListStateModel>,
    { payload }: SetMenuItem
  ) {
    setState(
      patch({
        selectedMenuItem: payload,
      })
    );
  }

  @Action(ClearMenuList)
  clearMenuList({ setState }: StateContext<MenuListStateModel>,{}: any){
    setState({
        menuList: null,
        selectedCategory: null,
        selectedMenuItem: null,
    })
  }
}

/**
 * Usage: dateString | localizedDate :pattern
 **/

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'localizedDate',
  pure: false,
})
export class DateLocalize implements PipeTransform {
  constructor(private translate: TranslateService) {}
  transform(
    value: any,
    pattern:
      | 'short'
      | 'medium'
      | 'long'
      | 'full'
      | 'shortDate'
      | 'mediumDate'
      | 'longDate'
      | 'fullDate'
      | 'shortTime'
      | 'mediumTime'
      | 'longTime'
      | 'fullTime' = 'mediumDate'
  ): any {
    const datePipe: DatePipe = new DatePipe(this.translate.currentLang);
    return datePipe.transform(value, pattern);
  }
}

<div class="row">
  <div class="header-container">
    <div
      class="head-icon-outer"
      data-qa="close-pickup-popup"
      aria-label="close"
      (click)="closeSelectionModal()"
    >
      <img [src]="backButton" class="left-icon" alt="back button"/>
    </div>
    <span class="header" aria-label="pick up details">{{ 'pick-up-details'| translate }}</span>
  </div>
  <div class="action-outer-container">
    <label for="location" aria-label="pick up location">{{ 'pick-up-location'| translate }}</label>

    <ng-container *ngIf="pickUpStations;else noPickupStation">
      <div class="custom-selected">
        <div class="selected">
          <div
            class="selected-text"
            data-qa="open-selection-list"
            aria-label="open selection list"
            (click)="openPickUpSelectionList($event)"
            id="location"
          >
          <ng-container *ngIf="!showPickUpStationList && selectedPickUpStation?.name ;else noSelection">
            <div class="text">{{ selectedPickUpStation?.name }}</div>
          </ng-container>
          <ng-template #noSelection>
            <div class="placeholder-text" aria-label="choose">{{'choose-pick-up'| translate }}</div>
          </ng-template>
            <img class="image" src="assets/images/Chevron_Down.svg" alt="chevron down" />
          </div>
          <div
            class="selection-list"
            [ngClass]="{ show: showPickUpStationList }"
          >
            <div
              class="selection-item"
              *ngFor="let station of pickUpStations"
              (click)="pickUpStationSelection(station, $event)"
            >
              {{ station.name }}
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="requiredError" class="error">
        {{ requiredError }}
      </div>
    </ng-container>
    <ng-template #noPickupStation>
      {{ 'no-pick-ups'| translate }}
    </ng-template>

    <!-- <label for="time" class="time-heading">{{ 'when'| translate }}</label> -->
    <!-- <ng-container *ngIf="hideTimeSelection;else showTimeSelectionTemplate">
      <div class="time-display-container">
        <img class="calendar" src="assets/images/calendar.svg" alt="calendar">
        <div class="time-text"> {{ selectedPickUpStation.startDateTime | date:'medium' }} -  {{ selectedPickUpStation.endDateTime | date:'medium' }} </div>
      </div>
    </ng-container>
    <ng-template #showTimeSelectionTemplate> -->
      <!-- <moduurnv2-inline-plain-time-picker></moduurnv2-inline-plain-time-picker> -->
    <!-- </ng-template> -->

  </div>
  <button class="block-button proceed-btn" (click)="proceed()" data-qa="submit" aria-label="submit">
    {{ 'Continue-adrs'| translate}}
  </button>
</div>

import { BaseComponent, SweetAlertService,RemoveGiftCard } from '@moduurnv2/libs-orderingapp/src/core';
import { OnInit,Output } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../core/environments/environment';
import { Store, Select } from '@ngxs/store';
import { Auth } from '../../../core/models';
import { LocationState, AuthState,GiftLoyaltyMethodState,GiftCard,FetchGiftLoyaltyCards,SharedService } from '../../../core';
import { takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { EventEmitter } from '@angular/core';
import { WhitelabelState } from './../../../core/state';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
export class UserRewardsBaseComponent extends BaseComponent implements OnInit{
  @Output() enableCardVerification = new EventEmitter();
  @Output() enablebalance = new EventEmitter();
  @Select(AuthState.getCommonAuthDetails)
  loggedInUserDetails$: Observable<Auth>;
  @Select(GiftLoyaltyMethodState.getSavedCards) savedCards$: Observable<
  GiftCard[]>;
  authSubscription: Subscription;
  loggedInUserDetails;
  userProfileModal: boolean = false;
  viewChangeAdrsModalStatus: boolean;
  addressBookStatus: boolean = true;
  card: GiftCard;
  cardBalance=0;
  cardNumber='';
  isMobileView: boolean = true;
  showEmailVerification : boolean = true;
  emailValidated:boolean = true;
  registeredUser :boolean = true;
  giftCard:any = undefined;
  bannerImg: string = '';
  @Select(GiftLoyaltyMethodState.getSavedCards) GiftCards$: Observable<
  GiftCard[]
  >;
  cardDetails: any;
  selCard: any ;
  // selectedCard:any;
  ackrooUrls : any;
  loyaltyProgram : any;
  constructor(public router: Router, public store: Store, public modal: ModalService,public shared: SharedService,
    public sweetAlert: SweetAlertService,
    public translate: TranslateService,
    public _location : Location,
    ) {
    super();
    this.authSubscription = this.loggedInUserDetails$.subscribe((res) => {
      this.loggedInUserDetails = res;
    });
    this.store.dispatch(new FetchGiftLoyaltyCards());
    this.savedCards$.pipe(takeUntil(this.destroy$)).subscribe((cardList) => {
      if (cardList && cardList.length && cardList.length > 0) {
        // const count = cardList.filter((c) => c.active==true).length;
        // if(count>1)
        // this.card = cardList.find((c=>c.active==true)&&(c=>c.gift_balance!='0.0')&& (c=>c.loyalty_balance!='0.0'));
        // else
        // this.card = cardList.find(c=>c.active==true);
        // if(this.card){
        // this.cardNumber=this.card.cardnumber;
        // this.cardBalance=parseFloat(this.card.gift_balance) +
        // parseFloat(this.card.loyalty_balance);
        // }
        this.cardDetails = cardList.filter((c) => c.active==true);        
        this.cardDetails.forEach(element => {
             element['cardBalance']=parseFloat(element.gift_balance) +
        parseFloat(element.loyalty_balance);
        });
       
        
        
      }
      else {
        this.cardDetails = [];
      }
});
  }
  ngOnInit(){
    let whitelabel = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
    if (whitelabel){
      this.bannerImg = environment.imagebaseurl + whitelabel.appSliderImages[0];
      if(whitelabel.giftPage && whitelabel.giftPage.desktop)
        this.bannerImg = environment.imagebaseurl + whitelabel.giftPage.desktop;

    }
   this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
  this.GiftCards$.pipe(takeUntil(this.destroy$)).subscribe((giftCards) => {
  let errorResponse = this.store.selectSnapshot(GiftLoyaltyMethodState.getErrorResponse);
  if (giftCards) {
    this.giftCard = undefined;
    if (
      giftCards.length &&
      giftCards.length >= 0
    ) {
      this.giftCard = giftCards.find((c) => c.active == true);
      this.registeredUser = true;
      this.emailValidated = true;
    } else if (
      errorResponse
      && errorResponse.error &&
      errorResponse.error === 2103
    ) {
      this.giftCard = undefined;
      this.emailValidated = false;
      this.registeredUser = false;
    }
    else
    {
      this.giftCard = undefined;
      this.registeredUser = false;
      this.emailValidated = true;
    }
  }
});
    let config = this.store.selectSnapshot(WhitelabelState.getOther);
    this.loyaltyProgram = config.loyaltyAndGiftCards ? config.loyaltyAndGiftCards.loyaltyProgram : undefined;
   this.ackrooUrls = config.loyaltyAndGiftCards? config.loyaltyAndGiftCards.ackroo : undefined;
  }
  ngOnDestroy() {
    if (this.authSubscription) this.authSubscription.unsubscribe();
  }
  profileHeaderImg: string =
    environment.image_basepath + environment.images.rewards_banner;
  profileLeftArrowIcon: string =
    environment.image_basepath + environment.images.arrow_left_icon;


  goBack() {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    const previouslyNavigatedFrom  = this.store.selectSnapshot(AuthState.getLoginFrom)
    const url = this.router.url;
    if (selectedLocation?._id) {
      if(previouslyNavigatedFrom=='locations'&& url=="/user-profile"){
        console.log("here")
        this.router.navigate(['locations'])
      }else{
        // this.router.navigate([`/locations/${selectedLocation._id}/home`]);
        this._location.back()
      }
    }else{
      this.router.navigate([`locations`]);
    }
  }
  addGiftCard() {
    localStorage.setItem('isFromRewardPage', "True");
    this.router.navigate([`/payment/add-gift-card`]);
  }
  setEnableCardVerification(val)
  {
    this.enableCardVerification=val;
    this.showEmailVerification=!val;
  }
  setEnableBalance(val)
  {
    this.showEmailVerification=val;
    this.enableCardVerification=val;
    this.enablebalance=val;
    this.emailValidated =val;
  }

  giftAndLoyaltyAmountPresent(card){
    if(parseFloat(card.gift_balance) && parseFloat(card.gift_balance)>0 && parseFloat(card.loyalty_balance) && parseFloat(card.loyalty_balance)>0){
      return true;
    }
    return false;
  }

  deleteCard(cardDetails) {

    this.sweetAlert
    .confirmDelete(
      this.translate.instant('are-you-sure'),
      this.translate.instant('remove-card')
    )
    .then((choice) => {
      if (choice) {
        this.store.dispatch(new RemoveGiftCard(cardDetails)).subscribe(response =>{
          this.store.dispatch(new FetchGiftLoyaltyCards());
        });
        
      }
    });

  }
 
  
  
}



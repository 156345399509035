import { environment } from '@moduurnv2/libs-orderingapp/src/core/environments/environment';
import { BaseComponent } from '@moduurnv2/libs-orderingapp/src/core/base';
import {
  SweetAlertService,
  SharedService,
} from '@moduurnv2/libs-orderingapp/src/core/services';
import {
  SetMenuItem,
  FetchOrderLevelModifiers,
  SetSelectedModifier,
  RemoveOrderLevelModifierFromCart,
} from '@moduurnv2/libs-orderingapp/src/core/actions';
import {
  LocationDefaultState,
  DeliveryState,
} from '@moduurnv2/libs-orderingapp/src/core/state';
import { TranslateService } from '@ngx-translate/core';
import { ThemeOptions } from '@moduurnv2/features/ui/directives/theme.directive';
import { Store } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertPopupComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/alert-popup/alert-popup.component';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { OrderModifierComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/order-level-modifiers/modifier/modifier.component';

export class OrderLevelModifierDetailsBaseComponent extends BaseComponent
  implements OnInit {
  @Input('item') item;
  selected: boolean = false;
  customScroll: boolean = true;
  isMobileView: boolean = true;
  @Input() _modalClose: any;
  themeOptions: ThemeOptions = {
    appPrimaryColor: true,
    appFontFamily: true,
  };
  loading: boolean = false;
  loader: any;
  isShowCalorieDetails:Boolean;
  isHideDescription:Boolean;
  constructor(
    public store: Store,
    public route: ActivatedRoute,
    public shared: SharedService,
    private modal: ModalService,
    private sweetAlert: SweetAlertService,
    private translate: TranslateService
  ) {
    super();
  }
  ngOnInit() {
    // console.log(this.item);
    this.shared.display
      .pipe(takeUntil(this.destroy$))
      .subscribe((isMobileView) => (this.isMobileView = isMobileView));

    const selectedLocation = this.store.selectSnapshot(
      LocationDefaultState.getLocationDefaults
    );    
    this.isShowCalorieDetails = selectedLocation.activeProfile.showCalorieInformation;
    this.isHideDescription = selectedLocation.activeProfile.hideDescription;
    const selectedDelivery = this.store.selectSnapshot(
      DeliveryState.getSelectedDelivery
    );
    let data = {
      menuProfileId: selectedLocation.activeProfile._id,
      deliveryType: selectedDelivery._id,
    };
    this.store.dispatch(new FetchOrderLevelModifiers(data));

    this.loader = {
      id: '',
    };
  }

  ngOnDestroy() {
    this.selected = false;
  }

  showDetail(event) {
    this.customScroll = false;
    let dispatchData = this.item;
    if (this.selected) dispatchData = null;
    this.store.dispatch(new SetMenuItem(dispatchData));
  }

  serverURL(imageURL) {
    if (this.selected && this.item && this.item._id && this.customScroll) {
      var element = document.getElementById(this.item._id);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        this.customScroll = false;
      }
    }
    return `${environment.imagebaseurl}${imageURL}`;
  }

  get descText() {
    if (this.selected) return this.item.description;
    else return this.item.description;
  }

  set modalClose(modalRef: any) {
    this._modalClose = () => {
      this.modal.closeModal(modalRef);
    };
  }

  closePopup() {
    if (this._modalClose) this._modalClose();
  }

  addModifier(modifier) {
    this.store.dispatch(new SetSelectedModifier(modifier._id));
    const selectedLocation = this.store.selectSnapshot(
      LocationDefaultState.getLocationDefaults
    );
    const selectedDelivery = this.store.selectSnapshot(
      DeliveryState.getSelectedDelivery
    );
    let settings = {
      menuProfileId: selectedLocation.activeProfile._id,
      deliveryType: selectedDelivery._id,
    };
    modifier = {...modifier,
                isShowCalorieDetails:this.isShowCalorieDetails,
                isHideDescription:this.isHideDescription,
              }
    this.shared.display
      .pipe(takeUntil(this.destroy$))
      .subscribe((isMobileView) => (this.isMobileView = isMobileView));
    if (this.isMobileView) {
      let vm = this;
      this.modal.showModal(OrderModifierComponent, {
        data: modifier,
        whenClosed: (data) => {
          if (data) {
            modifier.loaderStart = true;
            vm.loader = {
              id: modifier._id,
            };
            this.store.dispatch(new FetchOrderLevelModifiers(settings));
          } else {
            modifier.loaderStart = false;
          }
        },
      });
    } else {
      let vm = this;
      this.modal.showSmallModal(OrderModifierComponent, {
        data: modifier,
        whenClosed: (data) => {
          if (data) {
            modifier.loaderStart = true;
            vm.loader = {
              id: modifier._id,
            };
            this.store.dispatch(new FetchOrderLevelModifiers(settings));
          } else {
            modifier.loaderStart = false;
          }
        },
      });
    }
  }
  deleteModifier(modifier) {
    let firstAttempt = true;
    this.modal.showSmallModal(AlertPopupComponent, {
      data: {
        header: this.translate.instant('are-you-sure-remove'),
        actionable: true,
      },
      whenClosed: (data) => {
        if (data && firstAttempt) {
          firstAttempt = false;
          this.loader.id = '';
          modifier.loaderStart = false;
          this.store.dispatch(
            new RemoveOrderLevelModifierFromCart(modifier._id)
          );
        }
      },
    });
  }
}

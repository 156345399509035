<div class="address-container">
  <div class="header">
    <span class="close" (click)="closePopup($event)">
      <img src="assets/images/back-arrow.svg" alt="back"/>
    </span>
    <span class="header-text">{{ translation.deliveryDetails }}</span>
      <img
          [src]="icon.close"
          data-qa="address-modal-close-button"
          class="close-button"
          aria-label="address-modal-close"
          alt="close"
          (click)="closePage()"
        />
  </div>
  <div class="modal-body">
    <div class="modal-header">
      <p>{{ translation.addressUsed }}</p>
      <button type="button" (click)="openDetailsScreen($event)" class="add-address">
        <img [src]="icon.add_red" class="icon" alt="add icon" />
        <span data-qa="address-list-add" aria-label="addresslist-add">
          {{ translation.addAddress }}
        </span>
      </button>
      <div class="sub-text">{{ translation.savedAddress }}</div>
      <hr class="main-border" />
    </div>
    <div class="modal-content hide-scroll">
      <moduurnv2-address-card-desktop
        [isAddressBook]="isAddressBook"
        *ngFor="let item of adrsList"
        [items]="item"
        (closeNewScreen)="closeCurrentScreen($event)"
        (closeModal)="closePage()"
      >
      </moduurnv2-address-card-desktop>
    </div>
  </div>
</div>

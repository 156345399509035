<div class="background-overlay">
  <div class="content-modal">
    <div class="modal-content">
      <ng-container>

<div [theme]="themeOptions" class="description">{{'number-of-people-at-your-table' | translate}}</div>

      </ng-container>
      <ng-template #futureLoader>
        <div class="loader-date loader"></div>
        <div class="loader-time loader"></div>
      </ng-template>

<ng-container>
        <div class="count-box form-group">
          <input #customerCount
          type="tel"
          maxlength="3"
          class="count-text"
          name="customerCount"
          (keyup)="checkFormat($event)"
          id="customerCount"
          [(ngModel)]="personCount"
          placeholder="{{ 'Please enter number of people' | translate }}"
          />
        </div>
        <div
          class="text-danger"
          *ngIf="isHavingValue"
        >
          {{ 'Please enter a valid number' | translate }}
        </div>
        <button type="button" [theme]="themeOptions" (click)="personCountSubmit(customerCount.value)" class="btn-primary">
          {{ 'continue'| translate }}
        </button>
      </ng-container>
    </div>
  </div>
</div>

<div class="row address-card">
  <div class="firstcolumn">
    <div class="content">
      <span class="header">
        <ng-container
          *ngIf="
            selectedVenue?.name && selectedVenue.name != '';
            else noSelectionHeading
          "
        >
          {{ selectedVenueHeading }}
        </ng-container>
        <ng-template #noSelectionHeading>
          {{ noSelectionMsg }}
        </ng-template>
      </span>
      <br />
      <span class="description">
        <span
          class="address-type"
          *ngIf="
            selectedDelivery.textCode == 'DELIVERYTYPE08';
            else venueAddress
          "
        >
          <span class="table-number"
            >{{ 'table' | translate }} {{ selectedVenue?.name }}</span
          >
        </span>
        <ng-template #venueAddress>
          <span class="address-type">{{ selectedVenue?.name }}</span>
        </ng-template>
      </span>
      <br />
    </div>
  </div>
  <div
    class="secondcolumn"
    *ngIf="showChangeButton()"
  >
    <button
      class="change"
      data-qa="change-venue"
      (click)="openUpdateVenueModal()"
      aria-label="change venue"
    >
      <ng-container *ngIf="selectedVenue?.name; else unSelected">
        {{ 'change' | translate }}</ng-container
      >
      <ng-template #unSelected>{{ 'Select' | translate }}</ng-template>
    </button>
  </div>
</div>
<ng-template> </ng-template>

<div #overlay class="overlay">
    <!-- <button #closeButton (click)="closeModal($event)" class="close-button">
        <span aria-hidden="true">X</span>
    </button> -->
    <div class="modal-container">
      <div class="modal-header" *ngIf="needHeader">
        <span class="heading"
          >{{headerTitle}}</span>
        <div class="close-image" >
          <img
            src="assets/images/Close.svg"
            data-qa="close-button"
            class="close" (click)="closeModal($event)"
            alt="close"
          />
        </div>
      </div>
        <ng-template appModalContent></ng-template>
    </div>
</div>

import {
  NgModule,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
// libs
import {
  AppInitService,
  CustomerFavouritesState,
  environment,
} from '@moduurnv2/libs-orderingapp/src/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

// app
import { CoreModule } from './core/core.module';
import { SharedModule } from './features/shared/shared.module';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { Config } from '../config';
import { UISharedModule } from '@moduurnv2/features';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxsModule } from '@ngxs/store';

import { AnalyticsHandler,QRScannerService, GoogleTagManagerHandler } from '@moduurnv2/core/services';
import { PWAService } from '@moduurnv2/core/services';
import { PixelHandler } from '@moduurnv2/core/services';
import { GiftAndLoyaltyCardService } from '@moduurnv2/libs-orderingapp/src/core';
import {
  MainMenuState,
  PushNotificationState,
  AuthEmailState,
  AuthState,
  ClearLoginDetails,
  PaymentMethodState,
  CartState,
  GiftLoyaltyMethodState,
  DealsState,
  SetEmptyLoginDetails,
  OrderLevelModifierState,
  LocationState,
  YepdeskEventState
} from '@moduurnv2/libs-orderingapp/src/core';

import { NGXS_PLUGINS } from '@ngxs/store';
import { getActionTypeFromInstance } from '@ngxs/store';
import { EmailResetResolver } from './../app/features/login/components/email-reset-link/email-reset-link-resolver';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PageNotFoundComponent } from './../app/features/shared/page-not-found/page-not-found.component';
import { LoginModule } from './../app/features/login/login.module';
import { ValidateEmailComponent } from './features/payment-method/components/gift-loyalty/validate-email/validate-email.component';
import { NgxJsonLdModule } from 'ngx-json-ld';
//import { NgxJsonLdModule } from '@ngx-lite/json-ld';
import {zendeskWebwidgetModule,zendeskWebwidgetConfig} from './../app/features/shared/zendesk-webwidget/zendesk-webwidget.module';


export class ZendeskConfig extends zendeskWebwidgetConfig {
  accountUrl = Config.zendeskAccountUrl;
  beforePageLoad(zE) {
    zE.setLocale('en');
    zE.hide();
  }
  callback(zE) {
       zE('webWidget', 'hide');
    }
}
export function performStoreOperations(state, action, next) {
  
  if (getActionTypeFromInstance(action) == (ClearLoginDetails.type || SetEmptyLoginDetails.type)) {
    // if we are a logout type, lets erase all the state
    if (state.authEmail) {
      state.authEmail.login = {};
      state.authEmail.signUp = {};
      state.authEmail.forgotPassword = undefined;
      state.authEmail.resetPasswordLinkValidity = undefined;
      state.authEmail.resetPassword = undefined;
      state.authEmail.customerId = undefined;
    }
    if (state.paymentMethods) {
      state.paymentMethods.paymentList = undefined;
      state.paymentMethods.selectedPayment = undefined;
      state.paymentMethods.savedCards = undefined;
    }
    if((getActionTypeFromInstance(action) == ClearLoginDetails.type) && state.cart){
      state.cart.cart = undefined;
      state.cart.cartItems = undefined;
      state.cart.cartResponse = undefined;
      state.cart.tip = undefined;
      state.cart.paymentGateway = undefined;
    }
    state.auth = {};
    localStorage.removeItem('selectedAdrsLocation');
    localStorage.removeItem('tempSelectedAdrsLocation');
    if (state.address) {
      state.address.isDeliverable = false;
    }
  }

  return next(state, action);
}
import { ModalModule } from './features/shared';
import { TableEmptyComponent } from './features/table-empty/table-empty.component';
import { AzureCoreModule } from './features/core/core.module';
import { SSORedirect } from './features/shared/sso-redirect/sso-redirect.component';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { ForceUpdateComponent } from './features/force-login/force-update.component';

@NgModule({
  imports: [
    zendeskWebwidgetModule.forRoot(ZendeskConfig),
    NgxsModule.forFeature([
      MainMenuState,
      PushNotificationState,
      AuthEmailState,
      AuthState,
      CartState,
      GiftLoyaltyMethodState,
      PaymentMethodState,
      DealsState,
      LocationState,
      OrderLevelModifierState,
      YepdeskEventState,
      CustomerFavouritesState
    ]),
    NgxsResetPluginModule.forRoot(),
    CoreModule,
    CommonModule,
    SharedModule,
    AppRoutingModule,
    UISharedModule,
    LoginModule,
    NgxsReduxDevtoolsPluginModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately',
    }),
    BrowserAnimationsModule,
    NgxSpinnerModule,
    ModalModule,
    //NgxJsonLdModule,
    NgxJsonLdModule,
    AzureCoreModule.forRoot(),
  ],
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    TableEmptyComponent,
    ValidateEmailComponent,
    SSORedirect,
    ForceUpdateComponent,
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (initService: AppInitService) => () =>
        initService.load(Config.organizationId ,Config.appVersion).toPromise(),
      deps: [AppInitService],
      multi: true,
    },
    QRScannerService,
    AnalyticsHandler,
    PWAService,
    PixelHandler,
    {
      provide: NGXS_PLUGINS,
      useValue: performStoreOperations,
      multi: true,
    },
    EmailResetResolver,
    GiftAndLoyaltyCardService,
    GoogleTagManagerHandler,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}

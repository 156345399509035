import {
  BaseComponent,
  environment,
  FetchMenuList,
  MenuListState,
  MenuList,
  CategoryList,
  ClearOrderResponseMessage,
  LocationState,
  Location,
  FetchLocationStatus,
  DeliveryState,
  Delivery,
  WhitelabelState,
  CartState,
  Whitelabel,
  FetchLocationDefaults,
  FetchCart,
  ComboState,
  Combo,
  ComboGroup
} from '@moduurnv2/libs-orderingapp/src/core';
import { OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { Router,ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import {ItemDetailsBaseComponent} from './item-details-base.component';
export class ComboListBaseComponent extends ItemDetailsBaseComponent implements OnInit {
  @Select(MenuListState.getMenuList) menuList$: Observable<MenuList>;
  @Select(MenuListState.getMenuListCategories) categories$: Observable<
    CategoryList[]
  >;
  @Select(MenuListState.getMenuListCategory) category$: Observable<
    CategoryList
  >;
  @Select(LocationState.getSelectedLocation) location$: Observable<Location>;
  @Select(DeliveryState.getSelectedDelivery) delivery$: Observable<Delivery>;
  @Select(WhitelabelState.getWhitelabel) whitelabel$: Observable<Whitelabel>;

  @Select(ComboState.getSelectedCombo) comboGroups$: Observable<Combo>;
  @Select(LocationState.getLocationStatus)locationStatus$:Observable<any>
  // items = this.menuList$[0].categories;
  selectedCategory: any;
  searchText: string = '';
  imgBasePath: string = environment.image_basepath;
  location: Location;
  delivery: Delivery;
  comboGroups: any= {};
  isFuture: boolean = false;
  bannerImg: string;
  whitelabel: Whitelabel;
  upArrowImage = 'assets/images/uparrow.png'; 
  constructor(store: Store,route: ActivatedRoute,public router : Router) {
    super(store,route);
    this.location$.pipe(takeUntil(this.destroy$)).subscribe((location) => {
      if (location) {
        this.location = location;        
      }
    });
    this.locationStatus$.subscribe(data=>{             
      this.isShowCalorieDetails=data.activeProfile.showCalorieInformation;
      this.isHideDescription=data.activeProfile.hideDescription;      
    })
    this.delivery$.pipe(takeUntil(this.destroy$)).subscribe((delivery) => {
      if (delivery) {
        this.delivery = delivery;
      }
    });
    this.comboGroups$.pipe(takeUntil(this.destroy$)).subscribe((combogroups) => {       
        this.comboGroups = combogroups;
        if(this.comboGroups?.combo_groups){
          this.comboGroups.combo_groups.forEach(element => {
            element.displayError = false;
          });
        }     
    });
    this.whitelabel$.pipe(takeUntil(this.destroy$)).subscribe((whitelabel) => {
      if (whitelabel)
        if (whitelabel) {
          // this.bannerImg = environment.imagebaseurl + whitelabel.appSliderImages[0];
          this.whitelabel = whitelabel;
          let image = whitelabel.menuPage && whitelabel.menuPage.desktop? whitelabel.menuPage.desktop: whitelabel.appSliderImages[0];
          this.bannerImg = environment.imagebaseurl + image;
        }
    });
  }

  ngOnInit() {
    const { sectionId } = this.route.snapshot?.params;
    this.store.dispatch(new FetchMenuList(sectionId));
    this.getShifts();
    this.store.dispatch(new FetchLocationDefaults());
    this.store.dispatch(new FetchCart());
  }
  getItems(value) {
    // let data = this.items.filter((item) => item._id == value);
    // this.selectedCategory = data[0].items;
  }

  public getShifts() {
    const locationId = this.location?._id;
    const orderType = this.delivery?.textCode;
    const futureDate = this.store.selectSnapshot(CartState.getFutureDate);

    let currTime = new Date();
    let selectedDate = new Date();
    if (futureDate) selectedDate = new Date(futureDate);
    let url =
      environment.mobileStoreApiUrl +
      'stores/location/' +
      locationId +
      '/shifts?type=' +
      orderType +
      '&currentTime=' +
      currTime.toISOString() +
      '&timezoneOffset=' +
      currTime.getTimezoneOffset();
    if (futureDate) {
      url =
        environment.mobileStoreApiUrl +
        'stores/location/' +
        locationId +
        '/shifts?type=' +
        orderType +
        '&currentTime=' +
        currTime.toISOString() +
        '&future=true&selectedTime=' +
        selectedDate.toISOString() +
        '&timezoneOffset=' +
        selectedDate.getTimezoneOffset();
    }
    this.store.dispatch(new FetchLocationStatus(url));
  }


  changeViewAllMenuItems(comboGroup) {
    comboGroup.open = !comboGroup.open
    if(comboGroup.open && comboGroup.subHeadings?.length == 1){
      this.changeViewSubMenuItems(comboGroup.subHeadings[0],true)
    }
  }
  changeViewSubMenuItems(subHeading,defaultValue?){
    if(defaultValue){
      subHeading.open = defaultValue;
    } else {
      subHeading.open = !subHeading.open;
    }
  }

  routetoMenu() {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    this.router.navigate([
      `/locations/${selectedLocation._id}/menu`,
    ]);
  }

  getSelectedItems(group: ComboGroup){    
    const cartCombo = this.store.selectSnapshot(ComboState.getCartCombo);    
    if(cartCombo) {
      const foundCartGroup = cartCombo.comboGroups.find( comboGroup => comboGroup.groupId === group._id);
      if(foundCartGroup){       
        if(group.itemCount && this.checkGroupItemsCount(foundCartGroup) < group.itemCount){         
          return group.itemCount - this.checkGroupItemsCount(foundCartGroup)==1?
          `Select ${group.itemCount - this.checkGroupItemsCount(foundCartGroup)} more item`:
          `Select ${group.itemCount - this.checkGroupItemsCount(foundCartGroup)} more items`;
        }
        else
          return `${this.checkGroupItemsCount(foundCartGroup)} selected`;
      }
    }
    return group.itemCount==1?`Select ${group.itemCount} more item`:`Select ${group.itemCount} more items`;
  }

  checkGroupItemsCount(group) {
    if (group && group.groupItems && group.groupItems.length > 0) {
      return group.groupItems.reduce((total, item) => {
        return total + item.count;
      }, 0);
    } else {
      return 0;
    }
  }

  comboIsAddedToCartAndCheckForInvalidCombo(){
     const {comboGroups : comboGroupsSet} = this.store.selectSnapshot(ComboState.getCartCombo)    
    let comboCartItemsAvail=[];
    comboGroupsSet.map( (comboGroup) => {
       if(comboGroup.groupItems.length>0){
         comboCartItemsAvail.push(comboGroup.groupId)
       }
     }) 
     if( this.comboGroups.combo_groups){     
      this.comboGroups.combo_groups.forEach(comboItem => {      
      if(comboCartItemsAvail.includes(comboItem._id) ){
        comboItem.displayError = !comboItem.maxReached ;    
      }
      else{ 
        comboItem.displayError =false;       
      }
      if(comboItem.required && !comboItem.maxReached){
        comboItem.displayError = true
      }
    });     
  }
     const  comboItemToHightLight = this.getErrorComboGroupToHighlight(this.comboGroups.combo_groups);   
    if(comboItemToHightLight?._id){
      const comboItemToHightLight_id = comboItemToHightLight._id;
      const element = document.getElementById(comboItemToHightLight_id)    
   setTimeout(()=>{
    if(element){
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',      
     });    
  }
  },100)
     
    }    
  }
  getErrorComboGroupToHighlight(comboItemsArray){
    return comboItemsArray.find( (combo)=>{
      return combo.displayError==true
    })
  }
}

import {
  BaseComponent,
  environment,
  UpdateAddressList,
  DeleteDeliveryAddress,
  FetchAddressList,
  AddressState,
  Address,
  SweetAlertService,
  FetchCartMenuItems,
  UpdateDeliverableStatus,
  ClearSelectedAddress,
  ClearEnteredAddress
} from '@moduurnv2/libs-orderingapp/src/core';
import { Observable } from 'rxjs/internal/Observable';
import { Input, OnInit,Output,EventEmitter } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import {
  ChangeDeliveryAddress,
  ChangeDeliveryAddressLocal,
  AuthState,
  FetchMenuList,
  AddressComponentCloseModel,
  AddressComponentEnums
} from '@moduurnv2/libs-orderingapp/src/core';
import { takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';

export class AddressCardBaseComponent extends BaseComponent implements OnInit {
  @Input('items') items: any;
  @Input() isAddressBook: boolean;
  @Output() closeNewScreen = new EventEmitter<AddressComponentCloseModel>();
  @Output() closeModal = new EventEmitter<any>();

  
  @Select(AddressState.getDeliveryAddress) DeliveryAddress$: Observable<
    Address
  >;
  @Select(AddressState.getAddressList) addressList$: Observable<Address[]>;
  imgBasePath: string = environment.image_basepath;
  icon = {
    location: this.imgBasePath + environment.images.location,
    edit: this.imgBasePath + environment.images.edit,
    remove: this.imgBasePath + environment.images.close,
    selected: this.imgBasePath + environment.images.success,
  };
  selectedAdrs: string;
  addressList: any;
  selectedLoc: Address;
  distance: string;
  isShowDiv: boolean = true;
  ngOnInit() {
    this.subscribeAdrs();
  }
  constructor(
    public store: Store,
    public sweetAlert: SweetAlertService,
    private httpresult: HttpClient,
    public modal: ModalService,
    private translate: TranslateService
  ) {
    super();
  }
  selectAdress(event,_id) {
    // if(event){
    //   event.stopPropagation();
    //   event.preventDefault();
    // }
    if (this.isAddressBook) {
      this.toggleDisplayDiv();
    } else {
      let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
      if (customer && customer.customerId) {
        this.store.dispatch(new ChangeDeliveryAddress(_id));
      } else {
        this.store.dispatch(new ChangeDeliveryAddressLocal(_id));
      }
      // this.vm.store.dispatch(new ChangePopupStatus(false));
      this.selectedAdrs = _id;
      this.store.dispatch(new FetchMenuList());
      this.store.dispatch(new FetchCartMenuItems({}));
      this.store.dispatch(new UpdateDeliverableStatus(true));
      this.closeModal.emit();
    }
  }
  removeAdrs(event,_id) {
    if(event){
      event.stopPropagation();
      event.preventDefault();
    }
    this.sweetAlert
      .confirmDelete(
        this.translate.instant('delete-address'),
        this.translate.instant('delete-not-undone')
      )
      .then((choice) => {
        if (choice) {
          this.store.dispatch(new ClearEnteredAddress());
          let customer = this.store.selectSnapshot(
            AuthState.getCommonAuthDetails
          );
          if (customer?.isLoggedIn) {
            this.store
              .dispatch(new DeleteDeliveryAddress(_id))
              .subscribe((data) => {
                this.store.dispatch(new FetchAddressList());
                this.addressList$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
                });
              });
          } else {
            let locations = JSON.parse(localStorage.getItem('locationList'));
            let index=-1;
            if(locations){
            index = locations.findIndex((adrs) => adrs._id == _id);
            locations.splice(index, 1);
            }
            localStorage.setItem('locationList', JSON.stringify(locations));
            this.store.dispatch(new UpdateAddressList(locations));
          }
          if (this.selectedLoc && (this.selectedLoc._id == _id)) {
            localStorage.removeItem('selectedAdrsLocation');
            this.store.dispatch(new ClearSelectedAddress());
          }
        }
      });
  }

  subscribeAdrs() {
    this.DeliveryAddress$.pipe(takeUntil(this.destroy$)).subscribe(
      (address) => {
        this.selectedLoc = address;
      }
    );
  }
  toggleDisplayDiv() {
    this.isShowDiv = !this.isShowDiv;
  }

  
  openDetails(data?){
    this.closeNewScreen.emit({toPage:AddressComponentEnums.ADDRESS_DETAILS,fromPage:AddressComponentEnums.ADDRESS_LIST,dataToPass:data});
  }
}

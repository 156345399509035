<div class="modifier-wrapper">
  <ng-container
  *ngIf="getModifiers$ | async as modifiers; else modifierLoader"
>
  <!-- Modifier header -->
  <div *ngFor="let modifierItem of  modifiers"
    class="order-level-modifier"
    [ngClass]="{ 'error':(isPaymentSelected && modifierItem.isForced && !modifierItem.isInCart) }"
  >
   <div  class="modifier-name"   >{{ modifierItem.name | formattedLanguage: modifierItem.translation }} {{modifierItem.isForced ? '(required)' : ''}} 
     <!-- <ng-container *ngIf="isShowCalorieDetails"> 
       <span *ngIf="modifierItem.minCalorie && modifierItem.maxCalorie">
        [ {{modifierItem.minCalorie}}-{{modifierItem.maxCalorie}} Kcal ]
       </span> 
      <span *ngIf="modifierItem.minCalorie && !modifierItem.maxCalorie" >[ {{modifierItem.minCalorie}}Kcal ]</span>
      <span *ngIf="!modifierItem.minCalorie && modifierItem.maxCalorie" >[ {{modifierItem.maxCalorie}}Kcal ]</span>
      </ng-container> -->
    <br><span class="modifier-price"> {{modifierItem.price ? (modifierItem.price | formattedCurrency): '' }}</span>
  </div>

    <div *ngIf="!modifierItem.isInCart"
  data-qa="add-button" (click)="addModifier(modifierItem)"
  class="button-container"
  [ngClass]="{
    loading:loader.id==modifierItem._id&& !modifierItem.isInCart
  }"
>
  <span
    class="add-item"
    ng-model="value"
    attr.aria-label="{{ 'add' | translate }}"
    >{{ 'add' | translate }}</span
  >
  </div>
  <!-- <div *ngIf="modifierItem.isInCart"
    data-qa="delete-button"
    class="delete-container"
    [ngClass]="{
      loading: loading
    }"
  >
    <img
    class="delete-icon"
    src="assets/images/red-delete.png"
    (click)="deleteModifier(modifierItem)"
    alt="delete"
    /> -->
    <!-- <br> <div  *ngFor="let modifierPriceItem of cartDetails?.orderLevelModifierPrice" class="pricelist">
      <ng-container *ngIf="modifierItem._id == modifierPriceItem.itemId">
        <div class="price" *ngIf="modifierPriceItem?.totalBeforetax > '0.00'">{{ modifierPriceItem?.totalBeforetax | formattedCurrency }}
        </div>
      </ng-container>
    </div> -->
  <!-- </div> -->
  <div
        *ngIf="modifierItem.isInCart"
        class="delete-container"
        (click)="deleteModifier(modifierItem)"
        data-qa="delete-button"
        [ngClass]="{
          loading: loading
        }"
      >
       
        <ng-container *ngFor="let modifierPriceItem of cartDetails?.orderLevelModifierPrice">
          <ng-container *ngIf="modifierItem._id == modifierPriceItem.itemId">
            <div class="item-total-price" *ngIf="modifierPriceItem?.totalBeforetax > '0.00'">{{ modifierPriceItem?.totalBeforetax | formattedCurrency }}
            </div>
          </ng-container>
        </ng-container>
        <div class="text icon" alt = "delete">
          {{ 'remove' | translate }}
        </div>
  </div>
  <!-- <div  *ngFor="let modifierPriceItem of cartDetails?.orderLevelModifierPrice">
    <ng-container *ngIf="modifierItem._id == modifierPriceItem.itemId">
      <div class="price" *ngIf="modifierPriceItem?.totalBeforetax > '0.00'">{{ modifierPriceItem?.totalBeforetax | formattedCurrency }}
      </div>
    </ng-container>
  </div> -->
  <div class="item-description">
    <ng-container *ngFor="let modifierPriceItem of cartDetails?.orderLevelModifierPrice">
      <div *ngIf="modifierItem._id == modifierPriceItem.itemId">
        <div *ngFor="let modifierSubItem of modifierPriceItem?.cartModifierItems">
          <div *ngIf="modifierSubItem?.type=='item'">
            <ng-container 
                    >{{
                      modifierSubItem.cartModifierItem.name
                    }}{{ modifierSubItem?.count ? ' x ' + modifierSubItem?.count : ' x 1' }}
                  </ng-container>
          </div>
        
          <div *ngIf="modifierSubItem?.type=='modifier'">
              <ng-container
                    *ngFor="
                      let subItem of modifierSubItem?.cartModifierSubItems;
                      index as j
                    "
                  >
                  <ng-container *ngIf="j == 0">{{modifierSubItem?.cartModifierItem?.name}}: {{
                    subItem?.cartModifierSubItem?.name
                    }}{{ subItem?.count ? ' x ' + subItem?.count : ' x 1' }}
                  </ng-container>
                  <ng-container *ngIf="j > 0">, {{modifierSubItem?.cartModifierItem?.name}}: {{
                    subItem?.cartModifierSubItem?.name
                    }}{{ subItem?.count ? ' x ' + subItem?.count : ' x 1' }}
                  </ng-container>
              </ng-container>
          </div>
      </div>
    </div>
    </ng-container>
  </div>
<!-- </div> -->

<!-- <div class="item-descriptions">

  <ng-container *ngFor="let modifiers of modifierItem?.modifierItems">
    <div *ngIf="modifiers?.type=='item' && modifiers?.selected">
        <ng-container 
          >{{
            modifiers.name
          }}{{ modifiers.count ? ' x ' + modifiers.count : ' x 1' }}
        </ng-container>
    </div>
    <div *ngIf="modifiers?.type=='modifier'">
      <ng-container *ngIf="modifiers?.items?.length" 
        ><ng-container
        *ngFor="
          let modifierSubItem of modifiers?.items;
          index as j
        "
      ><ng-container *ngIf="modifierSubItem?.selected"><ng-container *ngIf="j == 0">{{modifiers.name}}: {{
        modifierSubItem.name
        }}{{ modifierSubItem?.count ? ' x ' + modifierSubItem?.count : ' x 1' }}
      </ng-container>
      <ng-container *ngIf="j > 0">, {{modifiers.name}}: {{
        modifierSubItem.name
        }}{{ modifierSubItem?.count ? ' x ' + modifierSubItem?.count : ' x 1' }}
      </ng-container></ng-container></ng-container>
      </ng-container></div>
  </ng-container>
  
</div> -->
  </div>
  
</ng-container>
</div>
<ng-template #modifierLoader>
  <div class="modifier-wrapper loading">
    <div class="image-container loader">
      <button class="close-button" (click)="closePopup()">
        <img [src]="close" data-qa="close-button" aria-label="close" alt="close"/>
      </button>
    </div>
    <div class="details-container">
      <div class="item-details">
        <div class="item-name loader"></div>
        <div class="item-description loader"></div>
      </div>
    </div>
    <div class="content-container">
      <div class="option-list">
        <div class="option-item">
          <div class="option-header">
            <div class="option-heading loader"></div>
            <div class="option-requirements loader"></div>
          </div>
          <div class="content-item loader"></div>
          <div class="content-item loader"></div>
        </div>
      </div>
    </div>
    <div class="footer-container">
      <div class="button loader"></div>
    </div>
  </div>
</ng-template>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login.component';
import { LoginDesktopComponent } from './components/login-desktop/login-desktop.component';

import { UISharedModule } from '@moduurnv2/features';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { FormBuilder,NgForm } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { EmailLoginComponent } from './components/email-login/email-login.component';
import { EmailSignUpComponent } from './components/email-sign-up/email-sign-up.component';
import { EmailForgotPasswordComponent } from './components/email-forgot-password/email-forgot-password.component';
import { EmailVerificationComponent } from './components/email-verification/email-verification.component';
import { EmailAuthComponent } from './components/email-auth/email-auth.component';
import { EmailResetLinkComponent } from './components/email-reset-link/email-reset-link.component';

import {DpDatePickerModule} from 'ng2-date-picker';
export const routes: Routes = [];

@NgModule({
  imports: [UISharedModule, CommonModule, RouterModule.forChild(routes),ReactiveFormsModule,SharedModule,DpDatePickerModule],
  declarations: [LoginComponent, EmailLoginComponent, EmailSignUpComponent, EmailForgotPasswordComponent, EmailVerificationComponent, EmailAuthComponent, EmailResetLinkComponent,LoginDesktopComponent],
  exports: [EmailAuthComponent,LoginDesktopComponent,EmailSignUpComponent,EmailForgotPasswordComponent]
})
export class LoginModule {}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'moduurnv2-time-switch-display',
  templateUrl: './time-switch-display.component.html',
  styleUrls: ['./time-switch-display.component.scss']
})
export class TimeSwitchDisplayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

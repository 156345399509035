import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { GeoLocationService } from './../services/geolocation-service';
import { FetchCurrentCoordinates } from './../actions';

export class GeoLocationStateModel {
  currentCoordinates: any;
}

@State<GeoLocationStateModel>({
  name: 'geolocation',
  defaults: {
    currentCoordinates: undefined,
  },
})
@Injectable()
export class GeoLocationState {
  constructor(private _geoLocationService: GeoLocationService) {}
  @Selector()
  static getCurrentCoordinates(state: GeoLocationStateModel) {
    return state.currentCoordinates;
  }

  @Action(FetchCurrentCoordinates)
  fetchCurrentCoordinates(
    { patchState }: StateContext<GeoLocationStateModel>,
    {}: any
  ) {
    return this._geoLocationService.getCurrentLocation().pipe(
      tap((response) => {
        if (response) {
          patchState({
            currentCoordinates: response,
          });
        }
      })
    );
  }
}

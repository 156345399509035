<div class="background-overlay" [ngClass]="{'scroller':showDatePicker==false}">
  <div class="content-modal">
    <div class="modal-header">
      <span class="tooltip" >  
      <ng-container *ngIf ="selectedDelivery?.textCode == 'DELIVERYTYPE01' || selectedDelivery?.textCode == 'DELIVERYTYPE04' ">
        <img  class = "info" src="assets/images/info.svg" (click) = "invertInfo()" />
        <div class="bottom" [ngClass]="{'show' : showInfo, 'hide' : !showInfo}" (click) = "invertInfo()">
            <p>“Please note that the time selected here will be the order pick-up time for the driver. The delivery time may vary depending on driving conditions."</p>
        </div>
      </ng-container>
      </span>
      <span class="heading">
         <ng-container *ngIf="selectedDelivery; else defaultHeading">
          {{
            (selectedDelivery?.textCode == 'DELIVERYTYPE01' ||
            selectedDelivery?.textCode == 'DELIVERYTYPE04' ||
            selectedDelivery?.textCode == 'DELIVERYTYPE10'
              ?  'schedule-delivery'
              : 'schedule-pickup'
            ) | translate
          }}
        </ng-container>
        <ng-template #defaultHeading>{{'schedule-your-order' | translate}}</ng-template>
        <!-- {{ selectedItem?.displayName }} -->
      </span>
      <div class="close-image" *ngIf="!hideCloseButton" (click)="closePopup()">
        <img
          [src]="icon.close"
          data-qa="close-button"
          class="close"
          aria-label="future-close"
          alt="close"
        />
      </div>
    </div>
    <div class="modal-content" [ngClass]="{'modal-content-mobile  hide-scroll':(isMobileView && !showDatePicker)}">
      
      <!-- <ng-container
        *ngIf="
          selectedLocation && selectedLocation.loading !== true;
          else futureLoader
        "
      > -->
      <ng-container *ngIf="futureLoading !== true; else futureLoader">
        <ng-container
          *ngIf="showDatePicker; then datePicker; else calender"
        ></ng-container>

        <ng-template #calender>
          <!-- <div class="location-data" *ngIf="locationName">
            <img
              src="/assets/images/location-icon-small.svg"
              class="map-icon"
              alt="map"
            />
            <label
              class="location"
              [ngClass]="{ disabled: isHomePage === false }"
              >{{
                locationName
                  ? ('ORDERING FROM' | translate)
                  : ('CHOOSE ' | translate)
              }}
              <span class="organization">{{
                locationName
                  ? locationName
                  : ('RESTAURANT LOCATION' | translate)
              }}</span></label
            >
          </div> -->
          <div class="select-asap" *ngIf="showASAP()">
            <!-- <span>Order</span>
          <button class="btn-asap" (click)="asapDate()">ASAP</button> -->
            <button class="btn-primary primary" (click)="asapDate(selectedLocation)">
              {{ 'order-for-now' | translate }}
            </button>
          </div>
          <div class="scroll-picker">
            <dp-day-time-calendar
              theme="calender-custom dp-material"
              [config]="dayPickerConfig"
              [(ngModel)]="selectedDate"
            ></dp-day-time-calendar>
          </div>
        </ng-template>

        <ng-template #datePicker>
          <ng-template #noTimesMessage>
            There does not seem to be any available future times
          </ng-template>
          <ng-container *ngIf="haveFutureTimes; else noTimesMessage">
            <!-- Dates list -->
            <div class="custom-selected">
              <div class="selected">
                <div class="selected-text" (click)="openDateList($event)">
                  <div class="text">{{ selectedTimeDate?.date?.dateText }}</div>
                  <img
                    class="image"
                    src="assets/images/Chevron_Down.svg"
                    alt="chevron down"
                  />
                </div>
                <div class="selection-list" [ngClass]="{ show: showDateList }">
                  <div
                    class="selection-item"
                    *ngFor="let day of futureDates"
                    (click)="selectDay(day)"
                  >
                    {{ day.dateText }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Times List -->
            <div class="custom-selected">
              <div class="selected">
                <div class="selected-text" (click)="openTimeList($event)">
                  <div class="text">{{ selectedTimeDate?.time?.timeText }}</div>
                  <img
                    class="image"
                    src="assets/images/Chevron_Down.svg"
                    alt="chevron down"
                  />
                </div>
                <div class="selection-list" [ngClass]="{ show: showTimeList }">
                  <div
                    class="selection-item"
                    *ngFor="let time of futureTimes"
                    (click)="selectTime(time)"
                  >
                    {{ time.timeText }}
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-template>
      </ng-container>
      <ng-template #futureLoader>
        <div class="loader-date loader"></div>
        <div class="loader-time loader"></div>
      </ng-template>
      <div class="schedule-button">
      <button class="btn-primary continue-green" (click)="futuerDate()">
          <!-- {{ 'schedule' | translate }}  -->
          {{ 'continue-adrs' | translate }}
      </button>
    </div>
    </div>
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';
import { Icons } from '@moduurnv2/libs-orderingapp';
@Pipe({ name: 'menuSearch', pure: false })
export class MenuSearch implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} searchText
   * @returns {any[]}
   */
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter((it) => {
      if (it['itemTitle'].toLocaleLowerCase().includes(searchText)) return it;
      else {
        let iconList = this.searchIcon(it?.icons);
        if (iconList.length > 0) {
          for (let i = 0; i < iconList.length; i++) {
            if (iconList[i]['name'].toLocaleLowerCase().includes(searchText))
              return it;
          }
        }
      }
    });
  }
  searchIcon(itemIcons) {
    let iconList = [];

    iconList = itemIcons
      .map((iconId) => {
        let foundIcon: any = Icons.find((icon) => icon.id === iconId);
        if (foundIcon) return foundIcon;
      })
      .filter((icon) => icon !== undefined);

    return iconList;
  }
}

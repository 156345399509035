<div class="address-container" [ngStyle]="{'grid-template-columns' : getDisplayTime ? '33% 33% 33%' : '50% 50%'}">
  <!-- <div>
  </div> -->
  <div class="delivery-grid order-from" aria-label="ordering from">
    <img src="assets/images/delivering-icon.svg" alt="delivery icon"/>
    <div class="inner-container">
      {{ 'ordering-from' | translate }}:
      <div class="title">{{ selectedLocation.name }}</div>
      <div class="detail">{{ selectedLocation.address }}</div>
    </div>
  </div>
  <div class="delivery-grid delivery-time" *ngIf="selectedDelivery && selectedDelivery.textCode !='DELIVERYTYPE04' && getDisplayTime">
    <img src="assets/images/calendar-icon.svg" alt="calendar"/>
    <div class="inner-container">
      {{
        selectedDelivery && selectedDelivery.text
          ? selectedDelivery.text
          : 'Delivery'
      }}:
      <div class="title">{{ getDisplayTime ? getDisplayTime : eta }}</div>
    </div>
  </div>
  <ng-container
    *ngIf="
      selectedDelivery?.textCode &&
        (selectedDelivery.textCode == 'DELIVERYTYPE09' ||
          selectedDelivery.textCode == 'DELIVERYTYPE10');
      then venueSelectionTemplate;
      else genericSelectionTemplate
    "
  >
  </ng-container>
  <ng-template #venueSelectionTemplate>
    <ng-container
      *ngIf="
        selectedDelivery.textCode == 'DELIVERYTYPE09';
        then venuePickup;
        else venueRoomDelivery
      "
    ></ng-container>
    <ng-template #venuePickup>
      <div class="delivery-grid delivery-time">
        <img
          src="assets/images/pickup-select-small.svg"
          class="deliverytype-icon"
          alt="pickup"
        />
        <div class="inner-container">
          {{ 'pickup-from' | translate }}:
          <div class="title" *ngIf="selectedVenue && selectedVenue.name">
            {{ selectedVenue.name }}
          </div>
          <button class="button change-btn" (click)="showVenuePickupPopup()">
            {{ 'change' | translate }}
          </button>
        </div>
      </div>

      <!-- 
          <img src="assets/images/pickup-select-small.svg" class="deliverytype-icon" alt="pickup"/>
            <div class="deliver-to order-from">
              Pick up from:
              <div class="address">
                <div class="title">
                  {{ selectedVenue.name }}
                </div>
              </div>
            <div class="change-btn ">
              <button class="button" (click)="showVenuePickupPopup()">
                {{ 'change' | translate }}
              </button>
            </div>
            </div> -->
    </ng-template>
    <ng-template #venueRoomDelivery>
      <div class="delivery-grid delivery-time">
        <img
          src="assets/images/Street_Sign_Icon.svg"
          class="deliverytype-icon"
          alt="street sign"
        />
        <div class="inner-container">
          {{ 'delivering-to' | translate }}:
          <div class="title" *ngIf="selectedVenue && selectedVenue.name">
            {{ selectedVenue.name }}
          </div>
          <!-- <button
            class="button change-btn"
            (click)="showVenueRoomDeliveryPopup()"
            *ngIf="selectedVenue && !selectedVenue.allowSchedule"
          >
            {{ 'change' | translate }}
          </button> -->
        </div>
      </div>

      <!-- <img src="assets/images/Street_Sign_Icon.svg" class="deliverytype-icon" alt="street sign"/>
          <div class="item deliver-icon order-from">
            <div class="heading">{{ 'delivering-to' | translate }}:</div>
            <div class="address">
              <div class="title">
                {{ selectedVenue.name }}
              </div>
            </div>
            <div class="change-btn">
            <button class="button" (click)="showVenueRoomDeliveryPopup()">
              {{ 'change' | translate }}
            </button>
          </div>
          </div> -->
    </ng-template>
  </ng-template>
  <ng-template #genericSelectionTemplate>
    <ng-container
      *ngIf="
        selectedDelivery &&
          (selectedDelivery.textCode == 'DELIVERYTYPE01' ||
            selectedDelivery.textCode == 'DELIVERYTYPE04');
        else pickup
      "
    >
      <!-- <div style="margin-left: 1rem;"> -->
      <!-- </div> -->
      <div class="delivery-grid deliver-to" aria-label="delivering to">
        <img src="assets/images/location-icon-small.svg" class="left-icon" alt="location icon"/>
        <div class="inner-container">
          {{ 'delivery-adrs' | translate }}:
          <div class="detail">
            <span class="title">{{ deliveryLocation?.addressType }}</span>
            {{ deliveryAddress }}
          </div>
          <button
            class="change-btn"
            (click)="openModal()"
            data-qa="change-address"
            aria-label="change"
          >
            {{ 'change' | translate }}
          </button>
        </div>
      </div>
    </ng-container>
    <ng-template #pickup>
      <div class="delivery-grid deliver-to" aria-label="pickup from ">
        <img src="assets/images/pickup-select-small.svg" class="left-icon" alt="pickup"/>
        <div class="inner-container">

          <ng-container *ngIf="qrScanDetails?.venueName && selectedDelivery.textCode == qrScanDetails.deliveryType &&
          qrScanDetails.locationId == selectedLocation?._id;else genericCaption">
            {{qrScanDetails?.venueType?.text}}:
            <div class="title">{{ selectedVenue.name }}</div>
          </ng-container>
          <ng-template #genericCaption>
          {{
            selectedDelivery && selectedDelivery.text
              ? selectedDelivery.text
              : 'Pickup'
          }}
          {{ 'from' | translate }}:
          <div class="title">{{ selectedLocation.name }}</div>
          <div class="detail">{{ selectedLocation.address }}</div>
          </ng-template> 

        </div>
      </div>
    </ng-template>
  </ng-template>
</div>

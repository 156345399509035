import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Plugins } from '@capacitor/core';

const { Device } = Plugins;

declare const window: any;

@Injectable({ providedIn: 'root' })
export class ExtendedAuthService extends OAuthService {

  initCodeFlow(additionalState, params) {
    this.createLoginUrl(additionalState, '', null, false, params)
      .then(this.customBorwser)
      .catch((error) => {
        console.error('Error in initAuthorizationCodeFlow');
        console.error(error);
      });
  }
  async getDeviceType(){
   const info = await Device.getInfo();
   return info;
  }
  async customBorwser(uri) {
    console.log('callback',uri);
    const info = await Device.getInfo();
    if (info.platform === 'web') location.href = uri;
    else {
      const browser = window.cordova.InAppBrowser.open(
        uri,
        '_blank',
        'location=no,clearsessioncache=yes,clearcache=yes'
      );
      browser.addEventListener('loadstart', (event) => {
        if (event.url.indexOf('http://localhost/ssoredirection') === 0 || event.url.indexOf("capacitor://localhost/ssoredirection") === 0) {
          browser.removeEventListener('exit', () => {});
          browser.close();
          const responseParameters = event.url.split('?')[1].split('&');
          const parsedResponse = {};
          for (let i = 0; i < responseParameters.length; i++) {
            parsedResponse[
              responseParameters[i].split('=')[0]
            ] = responseParameters[i].split('=')[1];
          }
          location.href = event.url;
          // const defaultError = 'Problem authenticating with Okta';
          // if (parsedResponse['state'] !== this.state) {
          //   console.log(defaultError);
          // } else if (
          //   parsedResponse['access_token'] !== undefined &&
          //   parsedResponse['access_token'] !== null
          // ) {
          //   console.log(parsedResponse);
          // } else {
          //   console.log(defaultError);
          // }
        }
      });
      browser.addEventListener('exit', function (event) {
        console.log('The Okta sign in flow was canceled');
      });
    }
  }

}

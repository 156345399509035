import { Component } from '@angular/core';
import { ListCartBaseComponent } from '@moduurnv2/libs-orderingapp/src/features';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import {
  SetItemDetails,
  CartState,
  DeliveryState,
} from '@moduurnv2/libs-orderingapp/src';
import { ModifierComponent } from '../../../shared/modifier/modifier.component';
import { ModalService } from '../../../shared/modal/modal.service';
import { SharedService } from '@moduurnv2/libs-orderingapp';
import { ModifierDesktopComponent } from '../../../shared/modifier/modifier-desktop/modifier-desktop.component';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'moduurnv2-list-cart',
  templateUrl: './list-cart.component.html',
  styleUrls: ['./list-cart.component.scss'],
})
export class ListCartComponent extends ListCartBaseComponent {
  isMobileView: boolean = false;
  deliveryType: string;
  constructor(
    private store: Store,
    private router: Router,
    private modal: ModalService,
    private shared: SharedService
  ) {
    super();
    this.deliveryType = this.store.selectSnapshot(
      DeliveryState.getSelectedDelivery
    ).textCode;
  }

  editListCart(item) {
     this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );    
    const menuDetails = {
      icons: item.menuItem.icons,
      description: item.menuItem.description,
      imageURL: item.menuItem.image,
      name: item.menuItem.name,
      _id: item.menuItem._id,
      cartDetails: item,
      translation: item.menuItem.translation,
      minCalorie:item.menuItem.minCalorie,
      maxCalorie:item.menuItem.maxCalorie,
    };

    this.store.dispatch(new SetItemDetails(menuDetails));
    // if (item.menuItem._id)
    //   this.router.navigate([`modifier/${item.menuItem._id}`]);
    if (item.menuItem._id) {
      this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
      // MOBILE LARGE MODAL
      if (this.isMobileView)
        this.modal.showModal(ModifierComponent, {
          data: { menuItemId: item.menuItem._id },
        });
      //DESKTOP LARGE MODAL
      else
        this.modal.showLargeModalDesktop(ModifierDesktopComponent, {
          data: { menuItemId: item.menuItem._id },
        });
    }
  }

  checkModifier(item) {
    const { menuItem } = item;
    if (menuItem)
      return menuItem.modifiers.length > 0 || menuItem.portions.length > 1 ||  (menuItem.freeTextModifiers && menuItem.freeTextModifiers.length > 0);
    return false;
  }
  getItemId(portion) {
    if (portion) return portion._id;
    else return null;
  }
  itemTotalPrice(item) {
    const cartItemPrice = this.store.selectSnapshot(
      CartState.getCartPriceItems
    );
    if (!cartItemPrice) return 0;
    // Need clarification from sonia on MV2-407 point 9 before removing the comment
    // let matchingItem;
    // if (item.portion)
    //   matchingItem = cartItemPrice.find(
    //     (eachItem) =>
    //       eachItem.itemId === item.menuItem._id &&
    //       eachItem.portion &&
    //       item.portion._id === this.getItemId(eachItem.portion)
    //   );
    // else {
    //   matchingItem = cartItemPrice.find(
    //     (eachItem) => eachItem.itemId === item.menuItem._id
    //   );
    // }
    if(cartItemPrice) {
      const matchingItem = cartItemPrice.find(
        (eachItem) => eachItem.id === item._id
      );
      if (matchingItem) return matchingItem.totalBeforetax;
    }

    return 0;
  }

  itemPrice(item) {
    const cartItemPrice = this.store.selectSnapshot(
      CartState.getCartPriceItems
    );
    if (cartItemPrice) {
      const matchingItem = cartItemPrice.find(
        (eachItem) => eachItem.id === item._id
      );
      if (matchingItem) return matchingItem.price;
    }
    return 0;
  }
  itemPoints(item) {
    const cartItemPrice = this.store.selectSnapshot(
      CartState.getCartPriceItems
    );
    if (cartItemPrice) {
      const matchingItem = cartItemPrice.find(
        (eachItem) => eachItem.id === item._id
      );
      if (matchingItem) return matchingItem.baseEmpLoyaltyPoints;
    }
    return 0;
  }
  itemTotalPoints(item) {
    const cartItemPrice = this.store.selectSnapshot(
      CartState.getCartPriceItems
    );
    if (cartItemPrice) {
      const matchingItem = cartItemPrice.find(
        (eachItem) => eachItem.id === item._id
      );
      if (matchingItem) return matchingItem.totalEmpLoyaltyPoints;
    }
    return 0;
  }
}

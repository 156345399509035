import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '../modal/modal.service';
import { ThemeOptions } from '@moduurnv2/features/ui/directives/theme.directive';
import { environment } from '@moduurnv2/libs-orderingapp/src/core/environments/environment';
@Component({
  selector: 'moduurnv2-alert-popup',
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.scss'],
})

export class AlertPopupComponent implements OnInit {
  @Input() data:any = null;
  @Input() _modalClose: any;
  header:string;
  isHtmlHeader:boolean = false;
  content:string;
  isHtmlContent:boolean = false;
  actionBtn:boolean = false;
  closeBtnText:string = 'Close';
  proceedText:string ='';
  themeFont: ThemeOptions = {
    // appFontFamily:true
  };
  themeOptions: ThemeOptions = {
    // appFontFamily:true,
    appPrimaryColor: true,
  };
  isSecondaryBtnEnabled:boolean = false;
  closeIcon: string = environment.image_basepath + environment.images.desktop_modal_close;
  constructor( private modal:ModalService ) {
  }
  
  ngOnInit(): void {
    if(this.data) this.populateData(this.data);
  }

  populateData(data){
    this.header = data.header; 
    this.content = data.content;
    this.actionBtn = data.actionable; // providing this will show Proceed button where the text can be configured by passing proceedText value
    this.isHtmlContent = data.isHtmlContent; // if set to true will allow passing html content instead of string
    this.isHtmlHeader = data.isHtmlHeader;// if set to true will allow passing html heading instead of string
    this.proceedText = data.proceedText;// custom text for Proceed button
    this.isSecondaryBtnEnabled = data.isSecondaryBtnEnabled; // 
    // if(data.actionable){
      this.closeBtnText = data.closeBtnText ? data.closeBtnText : 'Cancel';// custom text for Cancel button
    // }
  }

  getData(){
    this._modalClose(true);
  }

  secondaryBtnClick() {
    this.closePopupOnSecondaryBtnClick();
  }

  closePopup(){
    if (this._modalClose) this._modalClose();
  }

  closePopupOnSecondaryBtnClick() {
    if (this._modalClose) this._modalClose(false);
  }

  set modalClose(modalRef: any) {
    this._modalClose = (data) => {
        this.modal.closeAllComponentModals(modalRef, data);
    };
  }
}

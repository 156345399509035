import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@moduurnv2/libs-orderingapp/src/core/environments/environment';

// For embedding urls related to images, videos and iframes src
@Pipe({ name: 'safeResourceUrl' })
export class SafeResourcePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

// For embedding urls related to css styling with an external url invloved . Ex: css background:url('$imgurl')
@Pipe({ name: 'safeStyleUrl' })
export class SafeStylePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url: string) {
    const regexPrefixMatch = this.returnRegExpToTest(environment.imagebaseurl);
    let regex = new RegExp(regexPrefixMatch);
      return this.sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }

  returnRegExpToTest(url) {
    let protocol = url.split('//')[0];
    let domainPath = url.split('//')[1];
    let domainSplit = domainPath.split('/')
    return this.generateRegExp(protocol, domainSplit);
  }

  generateRegExp(protocol, domainUrlSplit) {
    if (domainUrlSplit.length) {
      let finalRegex = `^(${protocol}\\/\\/)?`
      for (var i = 0; i < domainUrlSplit.length; i++) {
        if (domainUrlSplit[i].length > 0) {
          finalRegex += `${domainUrlSplit[i]}\\/`;
        }
      }
      return finalRegex;//+ '+(\w)';
    }
  }
}


// For embedding urls related to script additions ,Ex: img src
@Pipe({ name: 'safeUrl' })
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}

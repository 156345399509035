import {
  BaseComponent,
  Venue,
  SetSelectedVenue,
  WhitelabelState, DeliveryState
} from '@moduurnv2/libs-orderingapp/src/core';
import { OnInit, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../core/environments/environment';
import { Store, Select } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';

import { VenueManagementState } from '../../../core/state/venue-management.state';
import { Observable } from 'rxjs/internal/Observable';

export class VenueTableSelectionBaseComponent extends BaseComponent
  implements OnInit {
  backButton: string =
    environment.image_basepath + environment.images.arrow_left_icon;
  closeButton: string =
    environment.image_basepath + environment.images.close_update_modal;

  orgName: string;
  loungeName: string;
  selectedVenue:Venue={name:''};
  enteredTable: string = '';
  showTableList: boolean = false;
  requiredError: string = '';
  tables: Venue[] = [];
  noTablesSelectedError: boolean = false;
  allowTableManualEntering: boolean = true;

  @Output() closeEvent = new EventEmitter();
  @Select(VenueManagementState.getVenues) venues$: Observable<Venue[]>;
  @Select(VenueManagementState.getSelectedVenue) venue$;

  constructor(protected store: Store) {
    super();
  }
  
  ngOnInit() {
    this.populateInitialData();
  }

  populateInitialData() {
    this.venue$.pipe(takeUntil(this.destroy$)).subscribe((venue) => {
      if(venue){
        this.selectedVenue = venue;
        this.enteredTable = this.selectedVenue.name
      }
    });
    this.orgName = this.store.selectSnapshot(WhitelabelState.getWhitelabel).restaurantName;
    this.loungeName = this.store.selectSnapshot(DeliveryState.getDeliveryLocation);
  }

  openTableSelectionList(event) {
    event.stopPropagation();
    event.preventDefault();
    this.showTableList = !this.showTableList;
  }

  tableSelection(station, event?) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.requiredError = '';
    this.selectedVenue = station;
    this.showTableList = false;
  }

  proceed() {
    if (this.enteredTable) {
      if(this.selectedVenue.name !== this.enteredTable){
        this.selectedVenue.name = this.enteredTable;
        this.store.dispatch(new SetSelectedVenue(this.selectedVenue));
      }
      this.closeSelectionModal();
    } else {
      this.requiredError = 'Please select a table to continue';
    }
  }

  closeSelectionModal() {
    this.closeEvent.emit();
  }
}

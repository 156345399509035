import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import {
  EmailLoginBaseComponent,
  SharedService,
  AuthState,
} from '@moduurnv2/libs-orderingapp';
import { environment, LocationState } from '@moduurnv2/libs-orderingapp/src/core';
import { Store, Select } from '@ngxs/store';
import { EmailForgotPasswordComponent } from '../email-forgot-password/email-forgot-password.component';
import { EmailSignUpComponent } from '../email-sign-up/email-sign-up.component';

import { ModalService } from '../../../shared/modal/modal.service';
import { EmailVerificationComponent } from '../../../payment-method/components/gift-loyalty/email-verification/email-verification.component';
import { ResendEmailVerificationComponent } from '../../../payment-method/components/gift-loyalty/resend-email-verification/resend-email-verification.component';
import { GiftLoyaltyCardComponent } from '../../../payment-method/components/gift-loyalty/card-details/gift-loyalty-card.component';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsHandler } from '@moduurnv2/core/services';
@Component({
  selector: 'moduurnv2-email-login',
  templateUrl: './email-login.component.html',
  styleUrls: ['./email-login.component.scss'],
})
export class EmailLoginComponent extends EmailLoginBaseComponent {
  @Output() modalValue: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    store: Store,
    router: Router,
    _shared: SharedService,
    modal: ModalService,
    public translate: TranslateService,
    analyticsHandler:AnalyticsHandler
  ) {
    super(store, router, _shared, modal,analyticsHandler);
  }

  set modalClose(modalRef: any) {
    this._modalClose = () => {
      this.modal.closeModal(modalRef);
    };
  }

  forgotPassword() {
    if (!this.isMobileView)
      this.modal.showMediumModalDesktop(EmailForgotPasswordComponent);
    else
      this.modalValue.emit('FORGOT-PASSWORD');
  }
  register() {
    this.modalValue.emit('REGISTER');
    if (!this.isMobileView)
      if (this._modalClose) {
        this._modalClose();
      }
  }

  loginUsingEmail(loginBtn) {
    const loginFrom = this.store.selectSnapshot(AuthState.getLoginFrom);
    const selectedLocation = this.store.selectSnapshot(LocationState.getSelectedLocation);
    if (loginFrom == 'giftAndLoyalty') {
      let componentTitle = this.translate.instant('gift-loyalty-card');
      this.setloginSuccessModal(
        GiftLoyaltyCardComponent,
        componentTitle,
        ResendEmailVerificationComponent
      );
    } else if (loginFrom == 'deals' || loginFrom == 'deals-listing' || loginFrom == 'menu') {
      console.log("Success");
      if(this.isMobileView){
        this.router.navigate([`locations/${selectedLocation}/${loginFrom}`]);
      }
    } else {
      this.setloginSuccessModal(undefined, '', undefined);
    }
    super.loginUsingEmail(loginBtn);
  }
}

<div class="popup-content" [theme]="themeFont">
  <div class="header bold" *ngIf="!isHtmlHeader">
    {{ header }}
    <img [src]="closeIcon" *ngIf="isSecondaryBtnEnabled" (click)="closePopup()"/>
  </div>
  <div class="header" *ngIf="isHtmlHeader" [innerHTML]="header"></div>
  <div class="body" *ngIf="content && !isHtmlContent">
    {{ content }}
  </div>
  <div class="body" *ngIf="content && isHtmlContent" [innerHTML]="content"></div>
  <div class="button-wrapper">
    <button
      class="btn"
      (click)="secondaryBtnClick()"
      [ngClass]="data.actionable? 'cancel-btn':'non-action'"
      data-qa="close"
      aria-label="close"
      [theme]="themeOptions"
    >
      {{ closeBtnText }}
    </button>
    <button
      *ngIf="actionBtn && !proceedText"
      class="btn remove-btn"
      (click)="getData()"
      data-qa="proceed"
      aria-label="proceed"
      [theme]="themeFont"
    >{{ 'remove' | translate }}
    </button>
    <button
    *ngIf="actionBtn && proceedText"
    class="btn action-btn"
    (click)="getData()"
    data-qa="proceed"
    aria-label="proceed"
    [theme]="themeFont"
    >{{proceedText}}
    </button>
  </div>
</div>

<div class="alert-box">
  <img [src]="icon.info" class="icon" alt="info"/>
  <div class="text">
    {{ 'reviewPayAlert' | translate
    }}<a
      *ngIf="
        locationDefaults &&
        locationDefaults.locationData &&
        locationDefaults.locationData.businessPhoneNumber
      "
      class="phone"
      aria-label="phone number"
      href="{{ 'tel:' + locationDefaults.locationData.businessPhoneNumber }}"
    >
      {{ locationDefaults.locationData.businessPhoneNumber }}</a
    >
  </div>
</div>

import { State, Selector, StateContext, Action, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Location, LocationInfo, LocationStatus } from '../models';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import {
  FetchLocationList,
  SetSelectedLocation,
  setLocationDistance,
  FetchLocationInfo,
  FetchLocationStatus,
  FetchCart,
  ClearCart,
  ClearSelectedDelivery,
  ClearFutureDate,
  FetchFreshSelectedLocation,
  ClearSelectedLocation,
  UpdateDeliverableStatus,
  SetCalenderPicker,
  SetLocationStatusLocal
} from '../actions';
import { Injectable , Inject } from '@angular/core';
import { LocationService } from '../services/location.service';
import { tap, catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { TranslateService } from '@ngx-translate/core';
import { throwError } from 'rxjs';
import { WhitelabelState } from './whitelabel.state';
import { PlatformLanguageToken } from '@moduurnv2/libs-orderingapp/src/core/services/tokens';
import * as moment from 'moment';


export class LocationStateModel {
  locationList: Location[];
  selectedLocation: Location;
  locationInfo: LocationInfo;
  locationStatus: LocationStatus;
  locationDistance: string;
  usePicker: boolean;
}

@State<LocationStateModel>({
  name: 'location',
  defaults: {
    selectedLocation: null,
    locationList: undefined,
    locationInfo: undefined,
    locationStatus: undefined,
    locationDistance: undefined,
    usePicker: false,
  },
})
@Injectable()
export class LocationState {
  locationImages = [
    'assets/images/organization_1.png',
    'assets/images/organization_2.png',
    'assets/images/organization_3.png',
  ];

  constructor(private locations: LocationService, private store: Store, private translate : TranslateService,
    @Inject(PlatformLanguageToken) private _lang: string
    ) {}

  @Selector()
  static getLocationsList(state: LocationStateModel) {
    return state.locationList;
  }

  @Selector()
  static getSelectedLocation(state: LocationStateModel) {
    return state.selectedLocation;
  }
  @Selector()
  static getLocationInfo(state: LocationStateModel) {
    return state.locationInfo;
  }
  @Selector()
  static getLocationStatus(state: LocationStateModel) {
    return state.locationStatus;
  }
  @Selector()
  static getTimePicker(state: LocationStateModel) {
    return state.usePicker;
  }

  @Action(FetchLocationInfo)
  fetchLocationInfo(
    { setState }: StateContext<LocationStateModel>,
    { payload }: FetchLocationInfo
  ) {
    return this.locations.fetchLocationInfo(payload).pipe(
      tap((response) => {
        if (response)
          setState(
            patch({
              locationInfo: response,
            })
          );
        else throw response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  @Action(FetchLocationList)
  fetchLocations(
    { setState }: StateContext<LocationStateModel>,
    { payload }: FetchLocationList
  ) {
    setState(
      patch({
        locationList: null,
      })
    );
    return this.locations.fetchLocations(payload).pipe(
      map((locations) => this.formatLocation(locations)),
      tap((response) => {
        if (response) {
          setState(
            patch({
              locationList: response,
            })
          );
        } else throw response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  @Action(SetSelectedLocation)
  setLocation(
    { getState, setState }: StateContext<LocationStateModel>,
    { payload }: SetSelectedLocation
  ) {
    const { locationList, selectedLocation } = getState();
    const newLocation = locationList.find(
      (location) => location._id === payload
    );
    const address: any = newLocation.address;
    let strAddress = '';
    if (address && typeof address != 'string' && typeof address === 'object') {
      for (var p in address) {
        if (address.hasOwnProperty(p)) {
          strAddress += address[p] + ',';
        }
      }
      newLocation.address = strAddress;
    }
    if (newLocation == undefined) return;
    if (selectedLocation && selectedLocation._id !== newLocation._id) {
      this.store.dispatch(new ClearCart(newLocation._id));
      this.store.dispatch(new ClearSelectedDelivery());
      this.store.dispatch(new ClearFutureDate());
      this.store.dispatch(new UpdateDeliverableStatus(false));
      localStorage.removeItem('selectedAdrsLocation');
    }
    if(newLocation && newLocation.language && newLocation.language.textCode){
          switch(newLocation.language.textCode){
            case 'LANGUAGE01':{
              this.translate.use('fr');
              moment.locale('fr');
            }
              break;
            case 'LANGUAGE02':{
              this.translate.use('es');
              moment.locale('es');
            }
              break;
            default:{
              this.translate.use('en');
              moment.locale('en');
            }
              }
    }
    else{
      // let whitelabel = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
      // if(whitelabel && whitelabel.language){
      //   switch(whitelabel.language){
      //     case 'LANGUAGE01':
      //       this.translate.use('fr');
      //       break;
      //     case 'LANGUAGE01':
      //       this.translate.use('es');
      //       break;
      //     default:
      //       return 'en';
      //       }
      // }
      // else{
      //   this.translate.use('en');
      // }
      if (this._lang == 'es' || this._lang == 'fr') this.translate.use(this._lang);
      else this.translate.use('en');
    }
    setState(
      patch({
        selectedLocation: newLocation,
      })
    );
    // Why are we doing this ??
    localStorage.setItem('selectedLocation', JSON.stringify(newLocation));
    this.store.dispatch(new setLocationDistance(payload));
  }

  @Action(setLocationDistance)
  updateLocationDistance(
    { getState, setState }: StateContext<LocationStateModel>,
    { payload }: setLocationDistance
  ) {
    const state = getState();
    return this.locations.updateLocationDistance(payload).pipe(
      tap((response) => {
        if (response && response['data']) {
          setState(
            patch({
              locationDistance: response['data'][0].distanceFromAddress,
            })
          );
          localStorage.setItem(
            'locationDistance',
            JSON.stringify(response['data'][0].distanceFromAddress)
          );

          this.store.dispatch(new FetchCart());
        } else {
          localStorage.removeItem('locationDistance');

          this.store.dispatch(new FetchCart());
        }
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  formatLocation(locations) {
    let organizationId = Config.organizationId;
    if(!locations)
      return;
    if (locations.length && locations.length > 0) {
      locations = locations.map((location, index) => {
        const multiplesOfThree = index > 2 ? Math.floor(index % 3) : index;
        const {
          unitNumber,
          streetAddress,
          city,
          state,
          country,
          postalcode,
        } = location.address;
        location.address = (unitNumber && unitNumber!='') ? `${unitNumber}, `:' ';
        location.shortAddress =  (unitNumber && unitNumber!='') ? `${unitNumber}, `:' ';
        location.address += `${streetAddress}, ${city}, ${state}, ${country}, ${postalcode}`;
        location.shortAddress += `${streetAddress}`;
        location.addressObj = {unitNumber:(unitNumber && unitNumber!='') ? `${unitNumber}, `:' ',
                               streetAddress:`${streetAddress}`,
                               city:`${city}`,
                               state:`${state}`,
                               country:`${country}`,
                               postalcode:`${postalcode}`};
        location.isLocationOpen = true;
        location.servicesSupported = location.deliveryTypes.map(
          (delivery) => {
          if(organizationId == '5f4d46e37e59e325b88dac4e'){
            return this.translateDeliveryType(delivery);
          }
          else{
           return delivery.text;
          }
        }
        );
        location.activeProfile = location.activeProfile;
        return location;
      });
    } else {
      if (locations.address) {
        const {
          unitNumber,
          streetAddress,
          city,
          state,
          country,
          postalcode,
        } = locations.address;
        locations.address = (unitNumber && unitNumber!='') ? `${unitNumber}, `:' ';
        locations.shortAddress = (unitNumber && unitNumber!='') ? `${unitNumber}, `:' ';
        locations.address = locations.address.concat(`${streetAddress}, ${city}, ${state}, ${country}, ${postalcode}`);
        locations.shortAddress = locations.shortAddress.concat(`${streetAddress}`);
        locations.addressObj = {unitNumber:(unitNumber && unitNumber!='') ? `${unitNumber}, `:' ',
        streetAddress:`${streetAddress}`,
        city:`${city}`,
        state:`${state}`,
        country:`${country}`,
        postalcode:`${postalcode}`};
        locations.isLocationOpen = true;
        locations.activeProfile = locations.activeProfile;
        locations.servicesSupported = locations.deliveryTypes.map(
          (delivery) => delivery.text
        );
      }
    }
    return locations;
  }

  @Action(FetchLocationStatus)
  fetchLocationStatus(
    { setState }: StateContext<LocationStateModel>,
    { payload }: FetchLocationStatus
  ) {
    return this.locations.fetchLocationStatus(payload).pipe(
      tap((response) => {
        setState(
          patch({
            locationStatus: response,
          })
        );
      })
    );
  }

  @Action(SetLocationStatusLocal)
  setLocationStatusLocal(
    { setState }: StateContext<LocationStateModel>,
    { payload }: SetLocationStatusLocal
  ) {
        setState(
          patch({
            locationStatus: payload,
          })
    );
  }

  @Action(FetchFreshSelectedLocation)
  fetchFreshSelectedLocation(
    { getState, patchState }: StateContext<LocationStateModel>,
    {}: FetchFreshSelectedLocation
  ) {
    const { selectedLocation } = getState();
    if (selectedLocation && selectedLocation._id) {
      patchState({
        selectedLocation: { ...selectedLocation, loading: true },
      });
      this.locations
        .fetchFreshSelectedLocation(selectedLocation._id)
        .pipe(map((locations) => this.formatLocation(locations)))
        .subscribe((response) => {
          const selected = response as Location;
          const address: any = selected.address;
          let strAddress = '';
          if (
            address &&
            typeof address != 'string' &&
            typeof address === 'object'
          ) {
            for (var p in address) {
              if (address.hasOwnProperty(p)) {
                strAddress += address[p] + ',';
              }
            }
            selected.address = strAddress;
          }
          patchState({
            selectedLocation: selected,
          });
        });
    }
  }
  @Action(ClearSelectedLocation)
  clearSelectedLocation({ setState }: StateContext<LocationStateModel>) {
    setState(
      patch({
        selectedLocation: null,
      })
    );
  }

  @Action(SetCalenderPicker)
  SetCalenderPicker(
    { setState }: StateContext<LocationStateModel>,
    { payload }: SetCalenderPicker
  ) {
    setState(
      patch({
        usePicker: payload,
      })
    );
  }


   translateDeliveryType(delivery){
    switch(delivery.text){
      case 'Cafe Pickup' : {
        return  this.translate.instant('cafe-pickup');
      }break;

      case 'Classroom Delivery' : {
        return this.translate.instant('classroom-delivery');
      }break;

     default : return delivery.text ;
      break;
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {
  BaseComponent,
  FetchMainMenuList,
  MainMenuState,
  MainMenu,
  LocationState,
  Location,
  ChangePopupStatus,
  AddressState,
  Address,
  DeliveryState,
  Delivery,
  SweetAlertService,
  WhitelabelState,
  Whitelabel,
  environment,
  LocationDefaultState,
  LocationDefaults,
  AuthState,
  Auth,
  AuthEmailState
} from '@moduurnv2/libs-orderingapp/src/core';
import { Observable } from 'rxjs/internal/Observable';
import { Store, Select } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { Capacitor } from '@capacitor/core';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { AddressListComponent } from '../address-book/address-list.component';
import { RoomTimeSelectionComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/venue-management/components/room-time-selection/room-time-selection.component';
import { PickupLocationTimeSelectionComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/venue-management/components/pickup-location-time-selection/pickup-location-time-selection.component';
import { AuthService } from '../../core/auth.service';
import { AddressWrapperComponent } from '../address-wrapper/address-wrapper.component';
import { zendeskWebwidgetService } from '../zendesk-webwidget/zendesk-webwidget.service';
import { Subscription } from 'rxjs';
import { UserSpecificationDataService } from '../zendesk-webwidget/user-specification-data/user-specification-data.service';
@Component({
  selector: 'ordering-app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent implements OnInit {
  @Select(MainMenuState.getMainMenuList) mainMenuList$: Observable<MainMenu[]>;
  @Select(LocationState.getSelectedLocation) location$: Observable<Location>;
  // @Select(AddressState.getAddressPopUpStatus)
  // modalChangeAdrsStatus$: Observable<boolean>;
  @Select(DeliveryState.getSelectedDelivery) selectedMethod$: Observable<
    Delivery
  >;
  selectedMethod;
  @Select(WhitelabelState.getWhitelabel) whitelabel$: Observable<Whitelabel>;

  @Select(AddressState.getDeliveryAddress) DeliveryAddress$: Observable<
    Address
  >;
  @Select(LocationState.getLocationsList) locationsList$: Observable<
    Location[]
  >;
  @Select(LocationDefaultState.getLocationDefaults) locationDefaults$: Observable<LocationDefaults>;

  @Select(AuthState.getCommonAuthDetails)
  loggedInUserDetails$: Observable<Auth>;
  // viewChangeAdrsModalStatus: boolean;
  addressBookStatus: boolean = false;
  isMenuVisible: boolean = false;
  menuItems: any[];
  location: Location;
  isHomePage: Boolean = false;
  openAddressPopup: boolean = false;
  isSingleLocation: boolean = false;
  organizationLogo:string;
  appVersion: string = Config.appVersion;

  popupToShow: string;
  popupSelectionTypes = {
    ADDRESS_SELECTION: 'ADDRESS_SELECTION',
    ROOM_TIME_SELECTION: 'ROOM_TIME_SELECTION',
    PICKUP_LOCATION_TIME_SELECTION: 'PICKUP_LOCATION_TIME_SELECTION',
  };
  feedbackEmail:string;
  deliveryPartners=[];
  logoIcon: string = environment.image_basepath + environment.images.logo_big;
  aboutUsLink: string = '';
  sendFeedpackLink: string = '';
  privacyPolicyLink: string = '';
  termsServiceLink: string = '';
  appStoreLink: string = '';
  playStoreLink: string = '';
  twitterLink: string = '';
  facebookLink: string = '';
  instagramLink: string = '';
  loggedInUserDetails: any;
  authSubscription: Subscription;
  chatCount: number=0;
  userEmail: string;
  userName: any;
  chatSentStatus: boolean;
  routePath: string;
  
  constructor(
    private _NgxZendeskWebwidgetService: zendeskWebwidgetService,
    private router: Router,
    private store: Store,
    private sweetAlert: SweetAlertService,
    public modal: ModalService,
    private authService: AuthService,
    private userSpecificationDataService: UserSpecificationDataService
  ) {
    super();
    this.checkRoute();
    this.subscribeLocation();
    this.selectedMethod$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedMethod) => {
        if (selectedMethod) {
          this.selectedMethod = selectedMethod;
        }
      });
    let whitelabel = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
    this.feedbackEmail = whitelabel.feedbackEmailAddress;
    this.organizationLogo = environment.imagebaseurl + whitelabel.restaurantLogo;
    this.appStoreLink = whitelabel.appStoreURL;
    this.facebookLink = whitelabel.facebook;
    this.instagramLink = whitelabel.instagram;
    this.playStoreLink = whitelabel.playStoreURL;
    this.twitterLink = whitelabel.twitter;
    this.feedbackEmail = whitelabel.feedbackEmailAddress;
    this.locationDefaults$.pipe(takeUntil(this.destroy$)).subscribe((selectedLocation) => {
      if (selectedLocation) {
        this.deliveryPartners = selectedLocation.locationData ? selectedLocation.locationData.deliveryPartnerPhoneNumbers : [];
      }
    });
  }

  ngOnInit() {

    this.authSubscription = this.loggedInUserDetails$.subscribe((res) => {
      this.loggedInUserDetails = res;
    });
    this.isHomePage = window?.location?.pathname.split('/')?.['3'] && window.location.pathname.split('/')[3] === 'home';
    this.locationsList$
      .pipe(takeUntil(this.destroy$))
      .subscribe((locationList) => {
        if (locationList && locationList.length == 1)
          this.isSingleLocation = true;
      });
      this.chatSentStatus = this._NgxZendeskWebwidgetService.getSentStatus();
  }
  getLogo(whitelabel) {
    if (whitelabel) {
      const { restaurantLogo } = whitelabel;
      return `${environment.imagebaseurl}${restaurantLogo}`;
    }
    return this.logoIcon;
  }

  openMenu() {
    this.store.dispatch(new FetchMainMenuList());
    this.mainMenuList$
      .pipe(takeUntil(this.destroy$))
      .subscribe((mainMenuList) => {
        this.menuItems = mainMenuList;
        this.isMenuVisible = true;
      });
  }
  async onMenuClick(item) {
    if (this.selectedMethod && this.selectedMethod.textCode == item._id) return;

    this.isMenuVisible = false;
  }
  closeMenu() {
    this.isMenuVisible = false;
  }
  organizationLocation() {
    if (this.isHomePage && !this.isSingleLocation) {
      this.router.navigate(['locations']);
    }
  }
  async navigateToModuurn(){
    let orgName;
    let whitelabel = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
    if (whitelabel) {
      orgName = whitelabel.restaurantName.replace(/ /g,'_');
    }
    orgName = encodeURIComponent(orgName);
    const res = await this.authService.getDeviceType();
    let url='';
    if(res.operatingSystem=='android')
    url =`https://moduurn.com/?utm_source=AndroidApp&utm_medium=${orgName}&utm_campaign=Merchant-Referrals`;
    else if(res.operatingSystem=='ios')
    url =`https://moduurn.com/?utm_source=iOSApp&utm_medium=${orgName}&utm_campaign=Merchant-Referrals`;
    else
    url =`https://moduurn.com/?utm_source=WebApp&utm_medium=${orgName}&utm_campaign=Merchant-Referrals`;
    window.open(url);
  }
  subscribeLocation() {
    this.location$.pipe(takeUntil(this.destroy$)).subscribe((location) => {
      if (location) {
        this.location = location;
      }
    });
  }
  checkRoute() {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        // this.isHomePage = val.url.split('/').pop() === 'home';
        this.isHomePage = window?.location?.pathname.split('/')?.['3'] && window.location.pathname.split('/')[3] === 'home';
      }
    });
  }

  openAddressBook() {
    this.modal.showSmallModal(AddressWrapperComponent);
  }

  openVenueRoomTimeSelectionPopup() {
    this.modal.showModal(RoomTimeSelectionComponent);

  }

  openVenuePickupStationTimeSelectionPopup() {
    this.modal.showModal(PickupLocationTimeSelectionComponent);
  }

  get checkShowDelivery() {
    const deliveryList = this.store.selectSnapshot(DeliveryState.getDeliveryList);
    return !!deliveryList.find( delivery => (delivery.textCode === 'DELIVERYTYPE01' || delivery.textCode === 'DELIVERYTYPE03' || delivery.textCode === 'DELIVERYTYPE05' || delivery.textCode === 'DELIVERYTYPE08' || delivery.textCode === 'DELIVERYTYPE09' || delivery.textCode === 'DELIVERYTYPE10'));
  }

  get checkWeb() {
    return Capacitor.getPlatform() === 'web';
  }
  
  blockLocationChange(){
    const selectedDelivery = this.store.selectSnapshot(DeliveryState.getSelectedDelivery);
    const selectedLocation = this.store.selectSnapshot(LocationState.getSelectedLocation);
    const qrScanDetails = this.store.selectSnapshot(WhitelabelState.getQRScanDetails);
    if(qrScanDetails?.venueName && selectedDelivery.textCode == qrScanDetails.deliveryType
      && selectedLocation?._id == qrScanDetails.locationId){
      return true;
      } else
      return false;
  }
  
  /**
   * Start the Zendek Chat
   */
   startWebChat() {
    const userDetails = this.getUserInfo(); //get the user information
    this._NgxZendeskWebwidgetService.activate(); // popout the widget
    if (this.loggedInUserDetails) {
      this._NgxZendeskWebwidgetService.zE('webWidget', 'identify', {
        name: this.userName,
        email: this.userEmail,
      });

      this._NgxZendeskWebwidgetService.zE('webWidget', 'prefill', {
        name: {
          value: this.userName,
          readOnly: false, // optional
        },
        email: {
          value: this.userEmail,
          readOnly: false, // optional
        },
      });
    }
    this.sentUserInfoChat(userDetails);
    this.closeWidget();
    this.alertChatNotification();
  
  }

    /**
   * function for setting the user Information for Agent
   * @returns
   */
     getUserInfo() {
      let user = this.store.selectSnapshot(AuthEmailState.getUserDetails);
      const userPhone = this.loggedInUserDetails ? user.primaryPhone : '';
      this.userEmail = this.loggedInUserDetails ? user.email : '';
      this.userName = this.loggedInUserDetails
        ? this.loggedInUserDetails.userName
        : '';
      const customerId = this.loggedInUserDetails
        ? this.loggedInUserDetails.customerId
        : '';
      const userOs = this.userSpecificationDataService.getOsName();
      const userBrowser = this.userSpecificationDataService.getBrowserName();
      const userScreenWidth = this.userSpecificationDataService.getScreenWidth();
      const userScreenHeight = this.userSpecificationDataService.getScreenHeight();
      const deviceType = this.userSpecificationDataService.getdeviceType();
      const customer = customerId ? 'CustomerId:' + customerId + '\n' : '';
      const customerName = this.userName ? 'Name:' + this.userName + '\n' : '';
  
      const userDetails =
        customer +
        customerName +
        'OS:' +
        userOs +
        '\nBrowser:' +
        userBrowser +
        '\nScreen:' +
        userScreenWidth +
        'W*' +
        userScreenHeight +
        'H\nDeviceType:' +
        deviceType +
        '\nApp Version:' +
        this.appVersion;
      return userDetails;
    }

    
  /**
   * Function for popout the widget when unread message from the agent
   */
  alertChatNotification() {
    this._NgxZendeskWebwidgetService.zE(
      'webWidget:on',
      'chat:unreadMessages',
      (number) => {
        this.routePath=this.router.url.split('/')[3]?this.router.url.split('/')[3]:'';
        if(this.routePath!='order')
        this._NgxZendeskWebwidgetService.activate();
      }
    );
  }

  /**
   * function for close the widget when customer click end chat
   */
  closeWidget() {
    this._NgxZendeskWebwidgetService.zE('webWidget:on', 'chat:end', () => {
      this._NgxZendeskWebwidgetService.zE('webWidget', 'close');
    });
  }

  /**
   * function for sending the user details to agent
   * @param userDetails
   */
  sentUserInfoChat(userDetails) {
    this._NgxZendeskWebwidgetService.zE(
      'webWidget:on',
      'userEvent',
      (event) => {
        // console.log('Event---', event.action);
        if (event.action === 'Chat Shown') {
          this.chatCount++;
          if (this.chatCount == 1 && this.chatSentStatus===false)
            this._NgxZendeskWebwidgetService.zE(
              'webWidget',
              'chat:send',
              userDetails
            );
 // we could replace the this._NgxZendeskWebwidgetService.setSentStatusFalse() function with
          // just false and see if there is an issue
          this.chatSentStatus = this._NgxZendeskWebwidgetService.setSentStatusTrue();
        }
      }
    );
  }
}

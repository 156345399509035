// angular
import { Injectable } from '@angular/core';

// app
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthEmailService {
  constructor(private http: HttpClient) { }
  loginUserEmail(payload) {
    const isExternalAuth = false ;//(Config.other && Config.other.isExternalAuth && Config.other.isExternalAuth.isAllow) ? Config.other.isExternalAuth : { isAllow: false };
    const url = `${environment.customerApiUrl}customers/login?isExternalAuth=${isExternalAuth}`;
    return this.http.post(url,payload);
  }

  signUpUserEmail(payload) {
    const url = `${environment.customerApiUrl}customers`;
    return this.http.post(url,payload);
  }

  forgotPasswordRequest(payload) {
    const url = `${environment.customerApiUrl}customers/reset`;
    return this.http.post(url,payload);
  }

  checkResetLinkValidity(payload) {
    const url = `${environment.customerApiUrl}customers/linkValid`;
    return this.http.post(url,payload);
  }

  resetPassword(payload) {
    const url = `${environment.customerApiUrl}customers/reset`;
    return this.http.put(url,payload);
  }

  updateProfileData(payload,userId) {
    const url = `${environment.customerApiUrl}customers/${userId}`;
    return this.http.put(url,payload);
  }
  checkUserExists(payload){
    const url = `${environment.customerApiv3Url}customers/validate`;
    return this.http.post(url,payload);
  }
}

// Common
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
// Services
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { Store } from '@ngxs/store';
import { WhitelabelState } from '@moduurnv2/libs-orderingapp/src';
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  flicker = '0';
  whiteLabel$: any = this._store.select(WhitelabelState.getWhitelabel);
  constructor(private _router: Router, private _store: Store) {
    setInterval(() => {
      setTimeout(() => {
        this.flicker = Math.random().toFixed(2);
      }, parseFloat(this.flicker) * 900);
    }, 250)
  }

  ngOnInit() {
  }

  callRefresh() {
    this._router.navigate([''])

  }

}

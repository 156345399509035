import { Router } from '@angular/router';
import { OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import {
  BaseComponent,
  environment,
  Regex,
  AuthEmailSignUp,
  AuthEmailState,
  UpdateProfileResponse,
  SharedService,
  Provinces,
  UpdateUserProfileData,
  UpdateProfileData,
  WhitelabelState,
  ClearUpdateProfileData
} from '@moduurnv2/libs-orderingapp/src/core';
import { ModalService } from '../../../../../../apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';

export class EditProfileBaseComponent extends BaseComponent implements OnInit {
  stepper: number = 1;
  loggedInData: any;
  showOldPassword: boolean = true;
  result: any;
  isMobileView: boolean;
  updateErrorFlag: boolean = false;
  config: any = {
    format: 'YYYY/MM/DD',
    disableKeypress: true,
  };
  loginLeftArrowIcon: string =
    environment.image_basepath + environment.images.arrow_left_icon;
  @Output() closeEvent = new EventEmitter();
  @Output() modalValue: EventEmitter<any> = new EventEmitter<any>();
  user: AuthEmailSignUp = {
    email: '',
    password: '',
    dateOfBirth: '',
    firstName: '',
    lastName: '',
    organizationId: '',
    phone: '',
    primaryPhone: '',
    title: 1,
    username: '',
    isTermsAccepted: false
  };
  updateUser: UpdateProfileData = {
    email: '',
    dateOfBirth: '',
    phone: '',
    organizationId: '',
    fname: '',
    lname: '',
  };
  phoneNumber = {
    international: '',
    valid: undefined,
  };
  emailRegex = Regex.email;
  authSignUpSubscription: Subscription;
  formSubmitted: boolean = false;
  errorInSignUp: string = '';
  invalidDate = false;
  signUpForm1 = this.fb.group(
    {
      email: [
        '',
        Validators.compose([
          Validators.required,
          this._shared.emailpatternValidator(),
        ]),
      ],
      password: ['', this._shared.passwordValidator.bind(this._shared)],
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      dateOfBirth: new FormControl(''),
    },
    {}
  );

  @Select(AuthEmailState.getUpdateProfileData) updateUserProfile$: Observable<
    UpdateProfileResponse
  >;
  countriesSupported = Provinces.countries;
  signUpResponseText: string = '';
  showDOB: boolean;
  disableUpdateDOB: boolean;
  constructor(
    public store: Store,
    public router: Router,
    private _shared: SharedService,
    private fb: FormBuilder,
    public datepipe: DatePipe,
    public modal: ModalService,
    private translate: TranslateService
  ) {
    super();
    let whiteLabel = this.store.selectSnapshot(WhitelabelState.getOther);
    console.log(whiteLabel);
    this.showDOB = !whiteLabel.hideDOB;
  }

  ngOnInit() {
    this.setUserDetails();
    this.signUpForm1.patchValue({
      firstName: this.loggedInData.fname,
      lastName: this.loggedInData.lname,
      dateOfBirth: this.loggedInData.dateOfBirth
        ? this.datepipe
            .transform(this.loggedInData.dateOfBirth, 'yyyy/MM/dd')
            .toString()
            .slice(5, 10)
        : '',
      email: this.loggedInData.email,
    });
    console.log('this.signUpForm1',this.signUpForm1)
    this.phoneNumber.international = this.loggedInData.primaryPhone;
    
   
    this._shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );

    let whiteLabel = this.store.selectSnapshot(WhitelabelState.getOther);
    this.disableUpdateDOB =
      whiteLabel?.loyaltyAndGiftCards?.isAllow &&
      !!this.loggedInData?.dateOfBirth;
    this.config.disableKeypress = false;
  }
  setUserDetails() {
    this.loggedInData = this.store.selectSnapshot(
      AuthEmailState.getUserDetails
    );
  }
  ngOnDestroy() {
    if (this.authSignUpSubscription) this.authSignUpSubscription.unsubscribe();
  }
  goBack() {
    if (this.stepper === 2) {
      this.stepper = 1;
    } else {
      this.router.navigate([`/user-profile`]);
    }
  }
  proceed(editProfileBtn) {
    this.formSubmitted = true;
    let fields = this.signUpForm1.value;
    this.user.email = fields.email;
    this.user.username = fields.email;
    this.user.password = fields.password;
    this.formSubmitted = false;
    this.invalidDate = false;
    let updatedDOB = this.signUpForm1.value.dateOfBirth;
    if (this.signUpForm1.value.dateOfBirth) {
      if(this.signUpForm1.value.dateOfBirth.split('/')?.length && this.signUpForm1.value.dateOfBirth.split('/')?.length==2){
        updatedDOB = '2001/'+ this.signUpForm1.value.dateOfBirth;
        let dateParsed = Date.parse(updatedDOB);
        this.invalidDate = isNaN(dateParsed);
      } else {
        this.invalidDate = isNaN(Date.parse(this.signUpForm1.value.dateOfBirth));
      }
      if (!this.invalidDate) {
        this.signUpForm1.value.dateOfBirth = new Date(
          updatedDOB
        ).toISOString();
      }
    } else {
      this.signUpForm1.controls['dateOfBirth'].setErrors(null);
    }
    if (
      (this.signUpForm1.value.dateOfBirth && this.invalidDate == false) ||
      this.signUpForm1.value.dateOfBirth == '' ||
      this.signUpForm1.value.dateOfBirth == null
    ) {
      let fields = this.signUpForm1.value;
      this.user.firstName = fields.firstName;
      this.user.lastName = fields.lastName;
      if(fields.dateOfBirth.split('/')?.length && fields.dateOfBirth.split('/')?.length==2){
        this.user.dateOfBirth = new Date(updatedDOB).toISOString();
      } else {
      this.user.dateOfBirth = fields.dateOfBirth
        ? fields.dateOfBirth
        : undefined;
      }
      this.formSubmitted = false;
      if (
        this.phoneNumber.international == '' ||
        this.phoneNumber.valid == true
      ) {
        this.user.phone = this.phoneNumber.international
          ? this.phoneNumber.international
          : '';
        if(fields.firstName.trim().length > 0 && fields.lastName.trim().length > 0){
          this.updateProfile(editProfileBtn);
        }
      }
    }
  }

  get signUpFormControl1() {
    return this.signUpForm1.controls;
  }

  formattedPhone(event) {
    this.phoneNumber = event;
  }

  updateProfile(editProfileBtn) {
    this.updateUser.email = this.user.email;
    this.updateUser.dateOfBirth = this.user.dateOfBirth;
    this.updateUser.phone = this.user.phone;
    this.updateUser.organizationId = this.user.organizationId;
    this.updateUser.fname = this.user.firstName;
    this.updateUser.lname = this.user.lastName;
    const updateProfileData: any = Object.assign({}, this.updateUser);
    this.store.dispatch(new ClearUpdateProfileData());
    this.startLoader(editProfileBtn.id,editProfileBtn.innerText);
    this.store.dispatch(new UpdateUserProfileData(updateProfileData));
    this.authSignUpSubscription = this.updateUserProfile$.subscribe(
      (response) => {
        if (response) {
          if (response.status == 200) {
            this.updateErrorFlag = false;
            this.errorInSignUp =
              this.translate.currentLang === 'en'
                ? response.msg
                : this.translate.instant('customer-update');
            this.setUserDetails();
          } else {
            this.updateErrorFlag = true;
            this.errorInSignUp = response.msg;
          }
          this.stopLoader(editProfileBtn.id);
        }
      },
      (error) => {
        this.updateErrorFlag = true;
        this.errorInSignUp = error.msg;
        this.stopLoader(editProfileBtn.id);
      }
    );
  }

  proceedToHome() {
    this.closeEvent.emit();
  }
}

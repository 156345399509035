import { Component } from '@angular/core';
import { PaymentMethodBaseComponent } from '@moduurnv2/libs-orderingapp/src';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SweetAlertService } from '@moduurnv2/libs-orderingapp/src/core';
import { SharedService } from '@moduurnv2/libs-orderingapp/src/core';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { AddPaymentCardDesktopComponent } from '../add-payment-card-desktop/add-payment-card-desktop.component';

@Component({
  selector: 'moduurnv2-payment-card-desktop',
  templateUrl: './payment-card-desktop.component.html',
  styleUrls: ['./payment-card-desktop.component.scss'],
})
export class PaymentCardDesktopComponent extends PaymentMethodBaseComponent {
  constructor(
    store: Store,
    router: Router,
    sweetAlert: SweetAlertService,
    shared: SharedService,
    modal: ModalService,
    translate: TranslateService,
  ) {
    super(store, router, sweetAlert, shared, modal, translate);
  }
  currentlyselectedCard: any = undefined;
  addCreditCard() {
    this.modal.showMediumModalDesktop(AddPaymentCardDesktopComponent, {
      needHeader: true,
      headerTitle: this.translate.instant('add-payment-card'),
    });
  }
  continue() {
    if (this.currentlyselectedCard) {
      super.selectCard(this.currentlyselectedCard);
      this.modal.closeAllModals();
    }
  }
  selectCard(card) {
    this.currentlyselectedCard = card;
  }
}

import { State, Selector, StateContext, Action, Store } from '@ngxs/store';
import { Venue } from '../models';
import {
  SetSelectedPickUpLocation,
  FetchVenues,
  SetSelectedTakeOut,
  SetSelectedRoom,
  SetSelectedVenue,
  ClearAllVenueDatas,
  SetCustomerPerTable,
  ResetCustomerPerTable,
  SetLocationAccess
} from '../actions';
import { Injectable } from '@angular/core';
import { tap, catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { VenueManagementService } from './../services';
import { patch } from '@ngxs/store/operators';
import { LocationState } from './location.state';
import { throwError } from 'rxjs';

export class VenueModel {
  venues: Venue[];
  selectedPickUpLocation: Venue;
  selectedTakeOut: Venue;
  selectedRoom: Venue;
  selectedVenue: Venue;
  totalPersonPerTable:Number;
  isLocationAccess:boolean;
}

@State<VenueModel>({
  name: 'venuemanagement',
  defaults: {
    venues: null,
    selectedPickUpLocation: undefined,
    selectedTakeOut: undefined,
    selectedRoom: undefined,
    selectedVenue: undefined,
    totalPersonPerTable:undefined,
    isLocationAccess:false,
  },
})
@Injectable()
export class VenueManagementState {
  constructor(
    private venueManagementService: VenueManagementService,
    private store: Store
  ) {}

  @Action(ClearAllVenueDatas)
  ClearAllVenueDatas(  { getState, patchState }: StateContext<VenueModel>,
    {}: any){
      patchState({
        venues: null,
        selectedPickUpLocation: undefined,
        selectedTakeOut: undefined,
        selectedRoom: undefined,
        selectedVenue: undefined,
        }
      );
  }

  @Selector()
  static getVenues(state: VenueModel) {
    return state.venues;
  }

  @Action(FetchVenues)
  fetchVenues(
    { setState }: StateContext<VenueModel>,
    { payload }: FetchVenues
  ) {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    let locationId = selectedLocation._id;
    let orgId = Config.organizationId;
    let filters = payload;
    return this.venueManagementService
      .fetchAllVenues(locationId, orgId, filters)
      .pipe(
        tap((response) => {
          if (response) {
            setState(
              patch({
                venues: response,
              })
            );
          } else throw response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  @Selector()
  static getSelectedRoom(state: VenueModel) {
    return state.selectedRoom;
  }

  @Action(SetSelectedRoom)
  setSelectedRoom(
    { patchState }: StateContext<VenueModel>,
    { payload }: SetSelectedRoom
  ) {
    patchState({
      selectedRoom: payload,
    });
  }

  @Selector()
  static getSelectedTakeOut(state: VenueModel) {
    return state.selectedTakeOut;
  }

  @Action(SetSelectedTakeOut)
  setSelectedTakeOut(
    { patchState }: StateContext<VenueModel>,
    { payload }: SetSelectedTakeOut
  ) {
    patchState({
      selectedTakeOut: payload,
    });
  }

  @Selector()
  static getSelectedPickUpLocation(state: VenueModel) {
    return state.selectedPickUpLocation;
  }

  @Action(SetSelectedPickUpLocation)
  setSelectedPickUpLocation(
    { patchState }: StateContext<VenueModel>,
    { payload }: SetSelectedPickUpLocation
  ) {
    patchState({
      selectedPickUpLocation: payload,
    });
  }
  @Selector()
  static getSelectedVenue(state: VenueModel) {
    return state.selectedVenue;
  }

  @Action(SetSelectedVenue)
  setSelectedVenue(
    { patchState }: StateContext<VenueModel>,
    { payload }: SetSelectedVenue
  ) {
    patchState({
      selectedVenue: payload,
    });
  }

  @Selector()
  static getCustomersPerTable(state: VenueModel) {
    return state.totalPersonPerTable;
  }

  @Action(SetCustomerPerTable)
  setNoOfCustomersPerTable(
    { patchState,getState }: StateContext<VenueModel>,
    { payload }: SetCustomerPerTable
  ) {
    let { selectedVenue } = getState();
    selectedVenue['guestCount'] = payload.count;
    patchState({
      selectedVenue: selectedVenue,
      totalPersonPerTable: payload
    });
  }

  @Action(ResetCustomerPerTable)
  resetfCustomersPerTable(
    { patchState,getState }: StateContext<VenueModel>,
    { payload }: ResetCustomerPerTable
  ) {
    let { selectedVenue } = getState();
    patchState({
      selectedVenue: selectedVenue,
      totalPersonPerTable: undefined
    });
  }
  @Selector()
  static getLocationAccessEnabled(state: VenueModel) {
    return state.isLocationAccess;
  }

  @Action(SetLocationAccess)
  setLocationAccessEnabled(
    { patchState }: StateContext<VenueModel>,
    { payload }: SetLocationAccess
  ) {
    patchState({
      isLocationAccess: payload,
    });
  }
}

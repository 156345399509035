// angular
import { Injectable } from '@angular/core';

// app
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { Store } from '@ngxs/store';
@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(private http: HttpClient,private store:Store) {}

  // fetchAddress(_id) {
  //   const url = `${environment.customerApiUrl}customers/` + _id + `/address`;
  //   return this.http.get(url); //5a5480ee2c393854d50189e4
  // }
  fetchDeliveryAddress(_id) {
    const url = `${environment.customerApiUrl}customers/` + _id + `/address`;
    return this.http.get(url);
  }
  saveDeliveryAddress(_id, payload) {
    const url = `${environment.customerApiUrl}customers/` + _id + `/address?isV2=true`;
    return this.http.put(url, payload);
  }
  updateDeliveryAddress(customerId, _id, payload) {
    const url =
      `${environment.customerApiUrl}customers/` + customerId + `/address/` + _id +'?isV2=true';
    return this.http.put(url, payload);
  }
  deleteDeliveryAddress(customerId, payload) {
    const url =
      `${environment.customerApiUrl}customers/` +
      customerId +
      `/address/` +
      payload;
    return this.http.delete(url, payload);
  }
  fetchDeliveryAddressDetails(payload) {
    const selectedDelivery = this.store.selectSnapshot(
      (app) => app && app.delivery && app.delivery.selectedDelivery
    );
    const auth = this.store.selectSnapshot((app) => app.auth);
    const enteredAddress = this.store.selectSnapshot((app) => app && app.address && app.address.enteredAddress);
    const { loggedInUser } = auth;

    let selectedFutureDate = this.store.selectSnapshot(
      (app) => app && app.cart && app.cart.futureDate
    );
    let body;
    body = {
      locationId: payload.locationId,
      locationCoordinates: payload.locationCoordinates,
      addressCoordinates: payload.addressCoordinates,
      organizationId: Config.organizationId,
      deliveryType: selectedDelivery ? selectedDelivery.textCode : undefined,
      deliveryTime: selectedFutureDate ? selectedFutureDate : new Date().toISOString()
    };
    // if((!loggedInUser || !loggedInUser.isLoggedIn) && enteredAddress){
      if(enteredAddress){
        body['enteredAddress'] = enteredAddress;
        if(!(payload.addressCoordinates.find(coord=> coord.id == enteredAddress.id))){
          body['addressCoordinates'].push({id:enteredAddress.id,loc:{}})
        }
      }
    // }
    const url = `${environment.customerApiv3Url}customers/addressCheck`;
    return this.http.post(url, body);
  }
}

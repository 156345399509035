import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  //orgId : string = '5a5480ee2c393854d50189e4'
  constructor(private http: HttpClient) {
   }
  public logData(logDetails){
    // const body = logDetails;
    // const loggingUrl = environment.baseurl + 'logging/organization/' + this.orgId;
    // return this.http.post(loggingUrl,body);
  }
}

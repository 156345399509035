<ng-container>
  <div
    class="special-instructions"
    data-qa="special-instruction-button"
    aria-label="special instruction"
    >
    <!-- (click)="!isSpecialInstructionPresent ? open.emit() : ''" -->
    <img [src]="icon.special_instructions" class="icon" alt="special instructions"/>
    <label
      class="text"
      >  
      <!-- [ngStyle]="{ cursor: isSpecialInstructionPresent ? 'auto' : 'pointer'}"       -->
      {{
        specialInstructions
          ? ''
          : isSpecialInstructionPresent
          ? ('edit' | translate)
          : ('add' | translate)
      }}
      {{
        (specialInstructions ? specialInstructions : 'SpecialInstructions')
          | translate
      }}
      <span class="sub-text">&nbsp;({{ 'optional' | translate }})</span>
    </label>
    <!-- <label class="sub-text">&nbsp;({{ 'optional' | translate }})</label> -->
  </div>
  <div
    class="show-instruction"
    *ngIf="isSpecialInstructionPresent && !isSpecialInstruction"
    >
    <!-- (click)="showTheSpecialInstructionField()" -->
    <div class="instruction">
      <label>{{ specialInstructionText }}</label>

      <div (click)="showTheSpecialInstructionField()">
        <button
          class="edit-instruction"
          data-qa="edit-special-instruction-button"
          aria-label="edit"
          (click)="open.emit()"
        >
          {{ 'edit-btn' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>

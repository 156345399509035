export class FetchOrderLevelModifiers {
    static readonly type = '[ORDERLEVELMODIFIERS] FetchOrderLevelModifiers';
    
    constructor(public payload) {}
}

export class SetPortion {
    static readonly type = '[ORDERLEVELMODIFIERS] SetPortion';

    constructor(public payload) {}
}

export class SetOrderLevelModifierItem {
    static readonly type = '[ORDERLEVELMODIFIERS] SetOrderLevelModifierItem';

    constructor(public id, public payload) {}
}
export class SetSelectedModifier {
    static readonly type = '[ORDERLEVELMODIFIERS] SetSelectedModifier';

    constructor(public id) {}
}
export class SetPaymentSelected {
    static readonly type = '[ORDERLEVELMODIFIERS] SetPaymentSelected';

    constructor(public payload) {}
}
export class RemoveOrderLevelModifierItem {
    static readonly type = '[ORDERLEVELMODIFIERS] RemoveOrderLevelModifierItem';

    constructor(public id, public payload) {}
}

export class GetGenModifierItem {
    static readonly type = '[ORDERLEVELMODIFIERS] GetGenModifierItem';

    constructor(public id, public payload) {}
}

export class SetOrderLevelSubModifierItem {
    static readonly type = '[ORDERLEVELMODIFIERS] SetOrderLevelSubModifierItem';

    constructor(public id, public subId, public payload) {}
}

export class RemoveOrderLevelSubModifierItem {
    static readonly type = '[ORDERLEVELMODIFIERS] RemoveOrderLevelSubModifierItem';

    constructor(public id, public subId, public payload) {}
}

export class SubmitOrderLevelModifierItem {
    static readonly type = '[ORDERLEVELMODIFIERS] SubmitOrderLevelModifierItem';

    constructor(public payload?: any) {}
}

export class UpdateItemCount {
    static readonly type = '[ORDERLEVELMODIFIERS] UpdateItemCount';

    constructor(public payload:number) {}
}

export class SetItemDetails {
    static readonly type = '[ORDERLEVELMODIFIERS] SetItemDetails';

    constructor(public payload:any) {}
}

export class UpdateCustomItemCount {
    static readonly type = '[ORDERLEVELMODIFIERS] UpdateCustomItemCount';

    constructor(public payload:number) {}
}

import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import {
  CartState,
  LocationState,
  SweetAlertService,
  SetFutureDate,
  BaseComponent,
  LocationDefaultState,
  environment
} from '@moduurnv2/libs-orderingapp/src';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { FuturePickerComponent } from '../future-picker/future-picker.component';
import { ModalService } from '../modal/modal.service';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'moduurnv2-inline-plain-time-picker',
  templateUrl: 'inline-plain-time-picker.component.html',
  styleUrls: ['inline-plain-time-picker.component.scss'],
})
export class InlinePlainTimePickerComponent extends BaseComponent implements OnInit {
  @Select(CartState.getFutureDate) futureDate$: Observable<any>;
  @Select(CartState.getFutureEndDateTime) futureEndDate$: Observable<any>;
  @Input('static') static: boolean = true;
  @Input('venueChanged') venueChanged : boolean;
  @Input('selectedVenue') selectedVenue;
  @Input('validationError') validationError: boolean;
  @Output() dateSelectedEvent = new EventEmitter();
  @Input() isClassRoomOrder : boolean;
  @Input() openTimePicker : boolean = false;
  calendarIcon:string = environment.image_basepath + environment.images.calendar;
  clockIcon:string = environment.image_basepath + environment.images.clock;
  tickIcon:string = environment.image_basepath + environment.images.timeline_tick;
  isAsap:boolean = true;
  futureDate: string = undefined;
  allowAsap : boolean;
  allowFuture:boolean;

  constructor(
    private store: Store,
    private modal: ModalService,
    public sweetAlert: SweetAlertService,
    public router: Router,
    private translate: TranslateService,
  ) {
    super();
  
  }

  ngOnInit() {
    if(this.openTimePicker){
      this.openDateTimePicker({allowOverlayClick : false})
      this.openTimePicker = false;
    }
    this.futureDate$.pipe(takeUntil(this.destroy$)).subscribe((startDate) => {
      if(startDate){
        this.futureDate = this.displayTime(startDate);
        let changeInFutureStart = true;
        this.futureEndDate$.pipe(takeUntil(this.destroy$)).subscribe((endDate) => {
          if(endDate && changeInFutureStart){
            console.log(new Date(startDate))
            console.log(new Date(endDate))
            this.futureDate = this.displayUpdatedTime(startDate,endDate);
            changeInFutureStart = false;
          }
        });
      }
    });



    const selectedLocation = this.store.selectSnapshot(LocationState.getSelectedLocation);
    this.allowAsap = selectedLocation?.orderSettings?.asapOrder.isAllow;
    this.allowFuture =  selectedLocation?.orderSettings?.futureOrder?.isAllow;
    let selectedFutureDate = this.store.selectSnapshot(CartState.getFutureDate);
    if(!this.allowFuture){
      this.assignASAPOrder();
      this.allowAsap = true;
      this.isAsap = true;
    }  else {
      this.isAsap = selectedFutureDate ? false :true;
    }
  }

  displayTime(futureDate) {
    if (futureDate) {
      let timeFormat = 'hh:mm A';
      if(this.translate && this.translate.currentLang == 'fr'){
        timeFormat   = 'HH:mm'
       }
      const futureTime = moment(futureDate);
      let timeText = futureTime.format('MMM DD '+ timeFormat);
      const usePicker = this.store.selectSnapshot(LocationState.getTimePicker);
      const locationDefaultsDetails = this.store.selectSnapshot(LocationDefaultState.getLocationDefaults);
      const timeSlot = locationDefaultsDetails.locationData?.orderSettings?.futureOrder?.overrideTimeSlot?.isAllow ? 
                        locationDefaultsDetails.locationData?.orderSettings?.futureOrder?.overrideTimeSlot?.timeSlot : 30;
  
      if (usePicker === false)
      timeText += ` - ${futureTime.add({ minutes: timeSlot }).format(timeFormat)}`;
      return timeText;
    }
  }

  displayUpdatedTime(futureStartDate,futureEndDate) {
    if (futureStartDate && futureEndDate) {
      let timeFormat = 'hh:mm A';
      if(this.translate && this.translate.currentLang == 'fr'){
        timeFormat   = 'HH:mm'
       }
      let timeText = moment(futureStartDate).format('MMM DD '+ timeFormat);
      const usePicker = this.store.selectSnapshot(LocationState.getTimePicker);
      if (usePicker === false)
      timeText += ` - ${moment(futureEndDate).format(timeFormat)}`;
      return timeText;
    }
  }

  openDateTimePicker(extraOptions = {allowOverlayClick : true}){
    const location = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    this.modal.showFuture(FuturePickerComponent, {
       data: {
        orderSettings: location.orderSettings,
        locationName: location.name,
        location: location,
        selectedVenue:this.selectedVenue,
        isClassRoomOrder: this.isClassRoomOrder,
        allowOverlayClick: extraOptions?.allowOverlayClick
       }
      ,
      whenClosed: (data) => {
        if(data?.futureTime){
          this.isAsap = false;
          this.venueChanged = false;
          this.dateSelectedEvent.emit(true);
        } else if(data){
          this.venueChanged = false;
          this.isAsap = true;
          this.futureDate = undefined;
          this.dateSelectedEvent.emit(true);
        }
      },
    });
  }

  assignASAPOrder(){
    this.isAsap = true;
    const location = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    const futureData = {
      location: location,
      futureTime: null,
    };
    this.store.dispatch(new SetFutureDate(futureData));
  }
}

import { NgModule, ModuleWithProviders, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { zendeskWebwidgetConfig } from './zendesk-webwidget.model';
import { zendeskWebwidgetService } from './zendesk-webwidget.service';

@NgModule({
  imports: [
    CommonModule
  ]
})
export class zendeskWebwidgetModule {
  static forRoot(zendeskConfig: Type<zendeskWebwidgetConfig>): ModuleWithProviders {
    return {
      ngModule: zendeskWebwidgetModule,
      providers: [
        {provide: zendeskWebwidgetConfig, useClass: zendeskConfig },
        {provide: zendeskWebwidgetService, useClass: zendeskWebwidgetService, deps: [zendeskWebwidgetConfig] }
      ]
    };
  }
}

export {
  zendeskWebwidgetService,
  zendeskWebwidgetConfig
};
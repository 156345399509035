<div
  [class]="requireOverlay ? 'common-popup overlay' : 'common-popup'"
  [ngClass]="{ show: modalStatus }"
>
  <div class="popup-content" [style.margin-top]="topMargin">
    <div class="header">
      <div class="title">{{ heading }}</div>
      <img
        src="/assets/images/Close_Icon_white_bg.svg"
        (click)="closePopupEvent.emit()"
        data-qa="close-button"
        class="close"
        aria-label="close"
        alt="close"
      />
    </div>
    <div class="body">
      <ng-content></ng-content>
    </div>
  </div>
</div>

<div class="email-verification-wrapper">
  <div class="banner-text" *ngIf="isMobileView">
    {{ giftLoyaltyCard }}
  </div>
  <div class="sub-heading">{{ giftCardVerify }}</div>
  <div>
    We’ve sent you a verification link at
    <span class="email">{{ userEmail }}</span
    >. {{ checkEmail }}
  </div>
  <moduurnv2-alert
    *ngIf="!this.isEmailValidated && !this.isMobileView"
  ></moduurnv2-alert>
  <div class="row tag-container">
    <span
      >{{noReceiveEmail}}
      <button
        class="Resend-tag"
        data-qa="Resend"
        type="button"
        (click)="resendVerificationMail()"
        attr.aria-label="{{ resend }}"
      >
        {{ resend }}
      </button></span
    >
  </div>
  <div class="row button-container">
    <button class="btn-submit" (click)="onFinish()" data-qa="continue-step">
      {{ finish }}
    </button>
  </div>
</div>

<div class="row ">
  <ng-container>
    <div class="auth-header-container" [hidden]="stepper ==2 || stepper ==3">
      <div class="head-icon-outer">
        <img
          type="button"
          data-qa="back-signup-start"
          (click)="goBack(3)"
          [src]="loginLeftArrowIcon"
          class="left-icon"
          alt="go back button"
        />
      </div>
      <span class="auth-header">{{ emailSignup }}</span>
      <span class="auth-sub-header">{{enterDetails}}</span>
      <span
        class="auth-header-stepper"
        [ngClass]="{ 'stepper-desktop': !isMobileView }"
        >{{ stepper }}{{ '/2' }}</span
      >
    </div>
    <div class="sign-up-outer-container" [hidden]="stepper ==2 || stepper ==3">
      <form [formGroup]="signUpForm1" (ngSubmit)="proceed(1)">
        <label for="email" class="email-container">
          {{ emailId}}
          <p class="mandatory">{{ ' *' }}</p>
        </label>
        <div class="row form-group">
          <input
            type="email"
            id="email"
            name="email"
            (ngModelChange)="clearValue()"
            class="email-text"
            placeholder="{{sampleEmail}}"
            formControlName="email"
            autofocus
          />
          <div
            class="text-danger"
            *ngIf="
              (signUpFormControl1.email.touched || formSubmitted) &&
              signUpFormControl1.email.errors?.required
            "
          >
            {{emailRequired}}
          </div>
          <div
            class="text-danger"
            *ngIf="
              signUpFormControl1.email.touched &&
              signUpFormControl1.email.errors?.inValidEmail
            "
          >
            {{validEmail}}
          </div>
          <div
            class="text-danger"
            *ngIf="signUpFormControl1.email.touched && errorInSignUp"
          >
            {{ errorInSignUp}}
          </div>
        </div>
        <label for="password" class="email-container">
          {{ password }}
          <p class="mandatory">{{ ' *' }}</p>
        </label>
        <div class="row form-group password-input">
          <input
            type="password"
            id="password"
            name="password"
            class="form-control md-input"
            class="password-text"
            placeholder="{{ passwordMinChars }}"
            formControlName="password"
            password-toggle
          />
          <div
            class="text-danger"
            *ngIf="
              (signUpFormControl1.password.touched || formSubmitted) &&
              signUpFormControl1.password.errors?.required
            "
          >
            {{ passwordReqd }}
          </div>
          <span
            class="text-danger"
            id="passwordValidation"
            *ngIf="
              (signUpFormControl1.password.touched || formSubmitted) &&
              signUpFormControl1.password.errors?.passwordValidationError
            "
          >
            {{ passwordMinChars }}
          </span>
        </div>
        <label for="confirmPassword" class="email-container">
          {{ confirmPassword }}
          <p class="mandatory">{{ ' *' }}</p>
        </label>
        <div class="row form-group password-input">
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            class="form-control md-input"
            class="password-text"
            placeholder=""
            formControlName="confirmPassword"
            password-toggle
          />
          <div
            class="text-danger"
            *ngIf="
              (signUpFormControl1.confirmPassword.touched || formSubmitted) &&
              formSubmitted &&
              signUpFormControl1.confirmPassword.errors?.required
            "
          >
            {{ confirmPasswordReqd }}
          </div>
          <span
            class="text-danger"
            *ngIf="
              signUpFormControl1.confirmPassword.touched &&
              signUpFormControl1.confirmPassword.errors?.passwordMismatch
            "
          >
            {{ passwordNotMatch }}
          </span>
        </div>
        <div class="row sign-up-button-container">
          <button class="btn-submit" data-qa="continue-step" type="submit">
            {{ continueAddress }}
          </button>
        </div>
      </form>
    </div>
  </ng-container>
  <ng-container>
    <div class="auth-header-container" [hidden]="stepper == 1 || stepper ==3">
      <div class="head-icon-outer">
        <img
          type="button"
          [src]="loginLeftArrowIcon"
          data-qa="back-from-personal"
          (click)="goBack(1)"
          class="left-icon"
          alt="go back button"
        />
      </div>
      <span class="auth-header">{{ emailSignup }}</span>
      <span class="auth-sub-header">{{ followPrivacy }}</span>
      <span
        class="auth-header-stepper"
        [ngClass]="{ 'stepper-desktop': !isMobileView }"
        >{{ stepper }}{{ '/2' }}</span
      >
    </div>
    <div class="personal-info-container" [hidden]="stepper == 1 || stepper ==3">
      <form [formGroup]="signUpForm2" (ngSubmit)="proceed(2,signUpBtn)">
        <div class="name-container">
          <div class="first-name form-group">
            <label for="firstName" class="title">
              {{ firstName }}
              <p class="mandatory">*</p>
            </label>
            <input
              class="box-size"
              size="15"
              id="firstName"
              placeholder="john"
              formControlName="firstName"
              autofocus
              name="firstName"
            />
          </div>
          <div class="last-name form-group">
            <label for="lastName" class="title">
              {{ lastName }}
              <p class="mandatory">*</p>
            </label>
            <input
              class="box-size"
              size="15"
              id="lastName"
              name="lastName"
              placeholder="doe"
              formControlName="lastName"
            />
          </div>
          <div class="name-validator">
            <div
              class="left-side text-danger"
              *ngIf="
                (signUpFormControl2.firstName.touched || formSubmitted) &&
                signUpFormControl2.firstName.errors?.required
              "
            >
              {{ firstNameReqd }}
            </div>
            <div
              class="right-side text-danger"
              *ngIf="
                (signUpFormControl2.lastName.touched || formSubmitted) &&
                signUpFormControl2.lastName.errors?.required
              "
            >
              {{ lastNameReqd }}
            </div>
          </div>
        </div>
        <div class="date-of-birth-container">
          <div class="dob" *ngIf="showDOB">
            <label for="dateOfBirth" class="date-of-birth-title">
              {{ dateOfBirth }}
              <span class="optional">
                {{ optionalText }}</span
              ></label
            >
            <div class="row form-group input-container">
              <input
                type="tel"
                id="dateOfBirth"
                class="form-control box-size"
                placeholder="MM/DD"
                name="dateOfBirth"
                format="MM/DD"
                (keyup)="formatDate($event)"
                formControlName="dateOfBirth"
              />
              <div class="text-danger" *ngIf="invalidDate">
                {{ invalidDateText }}
              </div>
            </div>
          </div>
          <div class="mobile-number-container">
            <label for="phoneNumber" class="mobile-number-title">
              {{ mobileNumber }}
              <span class="optional"> {{ optionalText }}</span>
            </label>
            <div class="row form-group input-container">
              <phone-input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                [submitted]="formSubmitted"
                (ngModelChange)="clearValue()"
                [phone]="user.primaryPhone"
                (Formatted)="formattedPhone($event)"
              ></phone-input>
              <!-- <select class="country-code" name="country">
            <option *ngFor="let country of countriesSupported" [value]="country.countryCode">{{country.phone}}</option>
          </select>
          <input
            type="text"
            class="form-control md-input"
            class="box-size"
            formControlName="primaryPhone"
          /> -->
            </div>
            <div class="text-danger" *ngIf="errorInPhone.length">
              {{ errorInPhone }}
            </div>
          </div>
          <!-- <pre>{{countriesSupported|json}}</pre> -->
          <div class="description-container">
            {{ agreeTerms }}
          </div>
          <div class="row sign-up-button-container">
            <button class="btn-submit" data-qa="finish-step" type="submit" #signUpBtn id="sign-up-submit">
              {{ finish }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </ng-container>
  <ng-container>
    <div class="final-step-outer-container" [hidden]="stepper ==2 || stepper ==1">
      <div class="auth-header-container">
        <!-- <div class="head-icon-outer" (click)="goBack(2)">
          <img [src]="loginLeftArrowIcon" class="left-icon" alt="go back button"/>
        </div> -->
        <span class="auth-header">{{ emailSignup }}</span>
        <span class="auth-sub-header">{{ justLastStep }}</span>
        <!-- <span class="auth-header-stepper">{{ stepper }}{{ '/3' }}</span> -->
      </div>
      <span class="final-header">{{ accountVerification }}</span>
      <span class="final-description">{{signUpResponseText}}</span>
      <div class="row sign-up-button-container">
        <button
          class="btn-submit"
          type="button"
          data-qa="finish-final"
          (click)="proceedToHome()"
        >
          {{ finish }}
        </button>
      </div>
    </div>
  </ng-container>
</div>

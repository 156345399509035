import { Component, OnInit } from '@angular/core';
import { VenueUpdateSelectorBaseComponent,DeliveryState } from '@moduurnv2/libs-orderingapp/src';
import { PickupLocationUpdateSelectionComponent } from './../pickup-location-update-selection/pickup-location-update-selection.component';
import { RoomUpdateSelectionComponent } from './../room-update-selection/room-update-selection.component';
import { LoungeUpdateSelectionComponent } from './../lounge-update-selection/lounge-update-selection.component';

@Component({
  selector: 'moduurnv2-venue-update-selector',
  templateUrl: './venue-update-selector.component.html',
  styleUrls: ['./venue-update-selector.component.scss']
})
export class VenueUpateSelectorComponent extends VenueUpdateSelectorBaseComponent {

  openUpdateVenueModal() {
    const selectedDelivery = this.store.selectSnapshot(
      DeliveryState.getSelectedDelivery
    );
    switch (selectedDelivery.textCode) {
      case 'DELIVERYTYPE08':
       this.modal.showModal(LoungeUpdateSelectionComponent)
        break;
      case 'DELIVERYTYPE09':
        this.modal.showModal(PickupLocationUpdateSelectionComponent)
        break;
      case 'DELIVERYTYPE10':
        this.modal.showModal(RoomUpdateSelectionComponent)
        break;
      default:
        break;
    }
  }


}

// angular
import { Injectable } from '@angular/core';

// app
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { environment } from '../environments/environment';
import { WhitelabelState } from './../state/whitelabel.state';
import { LocationState } from './../state/location.state';
@Injectable({
  providedIn: 'root',
})
export class VenueManagementService {
  constructor(private http: HttpClient,private store:Store) {}

  fetchAllVenues(locationId,organizationId,filter?) {
    let url = `${environment.locationV1ApiUrl}locations/${locationId}/tableManagement?organizationId=${organizationId}&customerApp=true`;
  if(filter.tableType){
      url +=`&tableType=${filter.tableType}`
  }; 
  if(filter.selectedTime){
    url +=`&selectedTime=${filter.selectedTime}`
  };  
  if(filter.timeZoneOffset){
    url +=`&timeZoneOffset=${filter.timeZoneOffset}`
  };
  
    return this.http.get(url);
  }

  isVenueManagementOrder(deliveryTypeTextCode){
    const qrScanDetails = this.store.selectSnapshot(WhitelabelState.getQRScanDetails);
    const selectedLocation = this.store.selectSnapshot(LocationState.getSelectedLocation);
    if((deliveryTypeTextCode === 'DELIVERYTYPE03' || deliveryTypeTextCode === 'DELIVERYTYPE08' || deliveryTypeTextCode === 'DELIVERYTYPE09' || deliveryTypeTextCode === 'DELIVERYTYPE10') || (qrScanDetails?.venueName && deliveryTypeTextCode == qrScanDetails.deliveryType && selectedLocation?._id == qrScanDetails.locationId)){
      return true
  } else {
      return false;
  }
  }

}

import { Component, OnInit } from '@angular/core';
import { SharedService, ScreenSwitchWrapperBaseComponent } from '@moduurnv2/libs-orderingapp/src';

@Component({
  selector: 'moduurnv2-review-and-pay-wrapper',
  templateUrl: './review-and-pay-wrapper.component.html',
})
export class ReviewAndPayWrapperComponent extends ScreenSwitchWrapperBaseComponent {

}

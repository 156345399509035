// angular
import { Injectable } from '@angular/core';

// app
import { Observable } from 'rxjs/internal/Observable';
import { MainMenu } from '../models';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MainMenuService {
  MainMenuinit: MainMenu[] = [
    // {
    //   _id: 'DELIVERYTYPE01',
    //   name: 'delivery',
    //   type: 'DELIVERYTYPE',
    //   icon: 'assets/images/H_Delivery Icon.png',
    //   needIcon: true,
    // },
    // {
    //   _id: 'DELIVERYTYPE02',
    //   name: 'take out',
    //   type: 'DELIVERYTYPE',
    //   icon: 'assets/images/H_Take Out Icon.png',
    //   needIcon: true,
    // },
    // {
    //   _id: 'DELIVERYTYPE03',
    //   name: 'table service',
    //   type: 'DELIVERYTYPE',
    //   icon: 'assets/images/H_Table Service Icon.png',
    //   needIcon: true,
    // },
    // {
    //   _id: 'DELIVERYTYPE07',
    //   name: 'curbside delivery',
    //   type: 'DELIVERYTYPE',
    //   icon: 'assets/images/H_Curbside Delivery Icon.png',
    //   needIcon: true,
    // },
    // {
    //   _id: 'DELIVERYTYPE04',
    //   name: 'postal',
    //   type: 'DELIVERYTYPE',
    //   icon: 'assets/images/H_Postal Delivery Icon.png',
    //   needIcon: true,
    // },
    // {
    //   _id: 'DELIVERYTYPE05',
    //   name: 'events',
    //   type: 'DELIVERYTYPE',
    //   icon: 'assets/images/H_Events Icon.png',
    //   needIcon: true,
    // },
    // {
    //   _id: '1',
    //   name: 'About Us',
    //   type: 'MENU',
    //   icon: 'assets/images/H_About Us Icon.png',
    //   needIcon: true,
    // },
    // {
    //   _id: '2',
    //   name: 'Send Feedback',
    //   type: 'MENU',
    //   icon: 'assets/images/H_Feedback Icon.png',
    //   needIcon: true,
    // },
    // {
    //   _id: '3',
    //   name: 'Help',
    //   icon: 'assets/images/H_Help Icon.png',
    //   type: 'MENU',
    //   needIcon: true,
    // },
    // {
    //   _id: '4',
    //   name: 'Privacy Policy',
    //   type: 'MENU',
    //   icon: '',
    //   needIcon: false,
    // },
    // {
    //   _id: '5',
    //   name: 'Terms of Service',
    //   type: 'MENU',
    //   icon: 'assets/images/H_Help Icon.png',
    //   needIcon: false,
    // },
  ];

  constructor() {}

  fetchMainMenu(deliveryLists: any[]): Observable<any> {
    const filteredMenuItems = this.MainMenuinit.filter((mainMenu) => {
      return deliveryLists.some((deliveryList) => {
        if (
          deliveryList.textCode === mainMenu._id &&
          mainMenu.type === 'DELIVERYTYPE'
        ) {
          mainMenu.name = deliveryList.text;
          return mainMenu;
        } else if (mainMenu.type !== 'DELIVERYTYPE') return mainMenu;
      });
    });

    return of(filteredMenuItems).pipe();
  }
}

import { State, Selector, StateContext, Action, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { MainMenu } from '../models';
import { FetchMainMenuList } from '../actions';
import { Injectable } from '@angular/core';
import { MainMenuService } from '../services';
import { tap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { DeliveryState } from './delivery-methods.state';
import { throwError } from 'rxjs';

export class MainMenuModel {
  mainMenuList: MainMenu[];
  selectedMainMenu: MainMenu;
}

@State<MainMenuModel>({
  name: 'mainmenu',
  defaults: {
    selectedMainMenu: null,
    mainMenuList: [],
  },
})
@Injectable()
export class MainMenuState {
  constructor(private _mainmenu: MainMenuService, private _store: Store) {}

  @Selector()
  static getMainMenuList(state: MainMenuModel) {
    return state.mainMenuList;
  }

  @Action(FetchMainMenuList)
  fetchMainMenu({ setState }: StateContext<MainMenuModel>) {
    const deliveryList = this._store.selectSnapshot(
      DeliveryState.getDeliveryList
    );
    return this._mainmenu.fetchMainMenu(deliveryList).pipe(
      tap((response) => {
        if (response)
          setState(
            patch({
              mainMenuList: response,
            })
          );
        else throw response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
}

import { Router } from '@angular/router';
import { OnInit, Output, EventEmitter } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';

import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import {
  BaseComponent,
  environment,
  Regex,
  AuthEmailSignUp,
  AuthEmailState,
  SetUserDetailsOnSignUp,
  AuthEmailSignUpResponse,
  LocationState,
  SharedService,
  Provinces,
  ClearSignupData,
  checkUserAvailability,
  UserAvailabilityResponse,
  WhitelabelState,SetLoginFrom,AuthState
} from '@moduurnv2/libs-orderingapp/src/core';
import { ModalService } from '../../../../../../apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import {TranslateService} from '@ngx-translate/core';
import { Component } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
@Component({
  template: ''
})
export class EmailSignUpBaseComponent extends BaseComponent implements OnInit {
  stepper: number = 1;
  _modalClose: any = null;
  isMobileView:boolean;
  _modalCloseall: any = null;
  showDOB: boolean;
  userAvailabilityCheck: any;
  config: any = {
    format: 'YYYY/MM/DD',
    disableKeypress: true,
  };
  loginLeftArrowIcon: string =
    environment.image_basepath + environment.images.arrow_left_icon;
  @Output() modalValue: EventEmitter<any> = new EventEmitter<any>();
  signUpSuccessModal:any=undefined;
  signupSuccessModalTitle:string = "";
  user: AuthEmailSignUp = {
    email: '',
    password: '',
    dateOfBirth: '',
    firstName: '',
    lastName: '',
    organizationId: '',
    primaryPhone: '',
    title: 1,
    username: '',
    isTermsAccepted: false
  };
  phoneNumber = {
    international: '',
    valid: undefined,
  };
  emailRegex = Regex.email;
  authSignUpSubscription: Subscription;

  formSubmitted: boolean = false;
  errorInSignUp: string = '';
  errorInPhone: string = '';
  invalidDate = false;
  signUpForm1 = this.fb.group(
    {
      email: [
        '',
        Validators.compose([
          Validators.required,
          this._shared.emailpatternValidator(),
        ]),
      ],
      password: [
        '',
        [Validators.required],
        this._shared.passwordValidator.bind(this._shared),
      ],
      confirmPassword: ['', [Validators.required]],
    },
    {
      validator: this._shared.MatchPassword('password', 'confirmPassword'),
    }
  );

  signUpForm2 = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    dateOfBirth: new FormControl(''),
    // primaryPhone: new FormControl('')
  });
  @Select(AuthEmailState.getSignUpData) signUpResp$: Observable<
    AuthEmailSignUpResponse
  >;
  @Select(AuthEmailState.getUserAvailabilityResponse)
  userAvailable$: Observable<UserAvailabilityResponse>;
  countriesSupported = Provinces.countries;
  signUpResponseText: string = '';
  constructor(
    public store: Store,
    public router: Router,
    private _shared: SharedService,
    private fb: FormBuilder,
    public modal: ModalService,
    public translate: TranslateService
  ) {
    super();
    let whiteLabel = this.store.selectSnapshot(WhitelabelState.getOther);
    this.showDOB = !whiteLabel.hideDOB;
  }

  ngOnInit() {
    const loggedInData = this.store.selectSnapshot(AuthEmailState.getLoginData);
   

    this._shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
  }

  ngOnDestroy() {
    if (this.authSignUpSubscription) this.authSignUpSubscription.unsubscribe();
  }

  goBack(value) {
    if (value === 1) this.stepper = 1;
    else if (value === 2) this.stepper = 2;
    else if (value === 3) this.modalValue.emit('REGISTER');
    else this.modalValue.emit('LOGIN');
  }
  clearValue() {
    this.errorInSignUp = '';
    this.errorInPhone = '';
    this.phoneNumber.international = '';
    this.phoneNumber.valid = undefined;
  }

  formatDate(event) {
    let birthDate = event.target['value'];
    let formattedDate = '';
    let cardDate = '';
    let numbersPattern = new RegExp(/^[0-9/]*$/);
    if(birthDate.length==5) {
      let dateSplit = birthDate.split("/");
      if((Number(dateSplit[0])>=1 && Number(dateSplit[0]<=12)) && (Number(dateSplit[1]>=1 && Number(dateSplit[1]<=31)))) {
        this.invalidDate = false;
      } else {
        this.signUpForm2.value.dateOfBirth = '';
        return (event.target.value = '');}
    } else if(birthDate.length>5) {
      this.signUpForm2.value.dateOfBirth = '';
      return (event.target.value = '');
    } else if(!birthDate.length)this.invalidDate = false;
    setTimeout(() => {
      birthDate = event.target['value'];
      if (numbersPattern.test(birthDate)) {
        for (let pos = 0; pos < birthDate.length; pos++) {
          if (pos == 0) {
            cardDate = birthDate.charAt(pos);
          } else if (pos == 2 && !birthDate.includes('/')) {
            cardDate = cardDate + '/' + birthDate.charAt(pos);
          } else {
             this.invalidDate = false
            cardDate = cardDate + birthDate.charAt(pos);
          }
        }
        formattedDate = cardDate;
        return (event.target.value = formattedDate);
      } else {
        this.signUpForm2.value.dateOfBirth = '';
        return (event.target.value = '');
      }
    });
  }
  proceed(stepper,signUpBtn?) {
    this.formSubmitted = true;
    this.stepper = stepper;
    if (stepper === 1) {
      if (this.signUpForm1.valid) {
        let fields = this.signUpForm1.value;
        this.user.email = fields.email;
        this.user.username = fields.email;
        this.user.password = fields.password;
        this.formSubmitted = false;
        this.invalidDate = false;
        const request = {
          username: this.user.email,
          organizationId : Config.organizationId
        };
        this.store.dispatch(new checkUserAvailability(request));
        this.userAvailabilityCheck = this.userAvailable$.subscribe(
          (response) => {
            if (response && response.errorCode != 4000) {
              this.errorInSignUp = '';
              this.stepper = 2;
            } else if (response) {
              this.errorInSignUp = response.error;
              this.stepper = 1;
            }
          },
          (error) => {
          }
        );
      }
    } else if (stepper === 2) {
      const termsAccepted = this.store.selectSnapshot(AuthState.getAgreeToTerms);
      if(!termsAccepted)
        return;
      this.user.isTermsAccepted = termsAccepted;
      
        if(typeof(this.signUpForm2.value.dateOfBirth)=='string'){
          this.dateCheck(signUpBtn, this.signUpForm2.value.dateOfBirth);
        }else{
          this.dateCheck(signUpBtn, moment(this.signUpForm2.value.dateOfBirth).format('MM/DD'));
        }
    }
  }

  dateCheck(signUpBtn, dateOfBirthValue){
    if(dateOfBirthValue && !dateOfBirthValue.includes("/")) {
      dateOfBirthValue = [dateOfBirthValue.slice(0, 2), "/", dateOfBirthValue.slice(2)].join('');
    }
    let dateSplit = dateOfBirthValue?dateOfBirthValue.split("/"):null;
    if (dateOfBirthValue && (Number(dateSplit[0])>=1 && Number(dateSplit[0]<=12)) && (Number(dateSplit[1]>=1 && Number(dateSplit[1]<=31)))) {
      dateOfBirthValue = moment("1975/"+dateOfBirthValue, 'YYYY/MM/DD')
    } else {
      if(dateOfBirthValue)
      this.invalidDate = true;
      else  this.invalidDate = false;
      this.signUpForm2.controls['dateOfBirth'].setErrors(null);
    }
    if (
      this.signUpForm2.valid &&
      ((dateOfBirthValue && this.invalidDate == false) ||
        dateOfBirthValue == '')
    ) {
      let fields = this.signUpForm2.value;
      this.user.firstName = fields.firstName;
      this.user.lastName = fields.lastName;
      this.user.dateOfBirth = fields.dateOfBirth
        ? fields.dateOfBirth
        : undefined;
      this.formSubmitted = false;
      if (
        this.phoneNumber.international == '' ||
        this.phoneNumber.valid == true
      ) {
        this.user.primaryPhone = this.phoneNumber.international
          ? this.phoneNumber.international
          : '';
          if(fields.firstName.trim().length > 0 && fields.lastName.trim().length > 0){
          this.signUp(signUpBtn);
          }
        
      }
    }
  }

  get signUpFormControl1() {
    return this.signUpForm1.controls;
  }
  get signUpFormControl2() {
    return this.signUpForm2.controls;
  }

  formattedPhone(event) {
    this.phoneNumber = event;
  }

  signUp(signUpBtn) {
    const signUpData: any = Object.assign({}, this.user);
    this.store.dispatch(new ClearSignupData());
    this.startLoader(signUpBtn.id,signUpBtn.innerText);
    this.store.dispatch(new SetUserDetailsOnSignUp(signUpData));
    this.authSignUpSubscription = this.signUpResp$.subscribe(
      (response) => {
        if (response) {
          if (response.status == 200) {
            if (response.allowLogin) {
              this.closeSignup();
              this.proceedToHome();
            } else {
              this.stepper = 3;
              if (response.validationSent) {
                this.signUpResponseText = this.translate.instant('sign-up-link-verification');
              }
              if (!response.allowLogin) {
                this.signUpResponseText = this.translate.instant('not-allow-login');
              }
            }

          } else if (response.msg) {
            this.errorInPhone = response.msg;
          }
          this.stopLoader(signUpBtn.id);
        }
      },
      (error) => {
        this.errorInPhone = error;
        this.stopLoader(signUpBtn.id);
      }
    );
  }

  proceedToHome() {
    let loginFrom = this.store.selectSnapshot(AuthState.getLoginFrom);
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if (!this.signUpSuccessModal) {
      if(this.isMobileView){
      if (selectedLocation){
        this.router.navigate([
          `/locations/${selectedLocation._id}/home`,
        ]);
      }
      else{
        this.router.navigate([
          `/locations`,
        ]);
      }
    }
    }
    if (loginFrom == 'giftAndLoyalty') {
      if (this.isMobileView) {
        this.router.navigate([`/payment/resend-email`]);
      } else {
        this.modal.showMediumModalDesktop(this.signUpSuccessModal, {
          needHeader: true,
          headerTitle: this.signupSuccessModalTitle,
        });
      }
    }
    else if(loginFrom == 'deals' || loginFrom == 'deals-listing' || loginFrom == 'menu' || loginFrom == 'order'){
      if(this.isMobileView){
        this.router.navigate([
          `/locations/${selectedLocation._id}/${loginFrom}`,
        ]);
      } else if(loginFrom == 'order'){
        this.router.navigate([
          `/locations/${selectedLocation._id}/${loginFrom}`,
        ]);
      }
    }  else this.closeSignup();
  }

  closeSignup() {
    if (this._modalClose) this._modalCloseall();
  }
  setSignupSuccessModal(title,signUpSuccessModal=undefined)
  {
    this.signUpSuccessModal = signUpSuccessModal;
    this.signupSuccessModalTitle = title;
    if(!signUpSuccessModal)
    {
      this.signUpSuccessModal = undefined;
      const previousPage = this.store.selectSnapshot(AuthState.getLoginFrom);
      if(previousPage != 'order')
      this.store.dispatch(new SetLoginFrom(undefined));
    }
  }
}

import { OnDestroy } from '@angular/core';

// libs
import { Subject } from 'rxjs';

export abstract class BaseComponent implements OnDestroy {
  private _destroy$: Subject<any>;
  buttonInitialText='';
  get destroy$() {
    if (!this._destroy$) {
      // Perf optimization:
      // since this is likely used as base component everywhere
      // only construct a Subject instance if actually used
      this._destroy$ = new Subject();
    }
    return this._destroy$;
  }
  ngOnDestroy() {
    if (this._destroy$) {
      this._destroy$.next(true);
      this._destroy$.complete();
    }
  }

  trackById(index: number, item: any): number {
    return item._id;
  }

  startLoader(id,initialText){
    this.buttonInitialText = initialText;
    let elementRef = document.getElementById(id);
    if(elementRef){
      elementRef.innerHTML = '';
      elementRef.insertAdjacentHTML('beforeend', '<div class="three-dot-loader"></div>')
      elementRef['disabled'] = true;
      elementRef.classList.add('disabled-button')
    }
  }

  stopLoader(id){
    let elementRef = document.getElementById(id);
    if(elementRef){
      elementRef.innerHTML = this.buttonInitialText;
      elementRef['disabled'] = false;
      elementRef.classList.remove('disabled-button')
    }
  }
}

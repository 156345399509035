import { Whitelabel } from '../models';

export class SetWhitelabel {
    static readonly type = '[WHITELABEL] Set';

    constructor(public payload: Whitelabel){}
}
export class SetOther {
    static readonly type = '[WHITELABEL] SetOther';

    constructor(public payload){}
}
export class SetLandingPage {
    static readonly type = '[WHITELABEL] SetLandingPage';

    constructor(public payload){}
}
export class SetAppVersion {
    static readonly type = '[WHITELABEL] SetAppVersion';

    constructor(public payload){}
}

export class SetQRScannedDetails {
    static readonly type = '[WHITELABEL] SetQRScannedDetails';

    constructor(public payload){}
}

export class ClearQRScannedDetails {
    static readonly type = '[WHITELABEL] ClearQRScannedDetails';
    constructor(){}
}
<div class="row">
  <div class="auth-header-container">
    <div
      class="head-icon-outer"
      data-qa="go-back"
      (click)="goBack()"
      aria-label="back button"
    >
      <img [src]="loginLeftArrowIcon" class="left-icon" alt="left icon"/>
    </div>
    <span class="auth-header">{{ 'forgot-password' | translate }}</span>
    <span class="auth-sub-header">{{ 'send-reset-text' | translate }}</span>
  </div>
  <div class="sign-in-outer-container">
    <!-- <form (ngSubmit)="loginUsingEmail(emailLoginForm)" #emailLoginForm="ngForm"> -->
    <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPasswordSubmit(sendResetLinkBtn)">
      <label for="email" class="email-container">{{
        'enter-mail-id' | translate
      }}</label>
      <div class="email-box form-group">
        <input
          type="email"
          class="email-text"
          name="email"
          id="email"
          placeholder="{{ 'sample-email' | translate }}"
          formControlName="email"
          autofocus
        />
        <div
          class="text-danger"
          *ngIf="
            (forgotPasswordFormControl.email.touched || formSubmitted) &&
            forgotPasswordFormControl.email.errors?.required
          "
        >
          {{ 'email-required' | translate }}
        </div>
        <div
          class="text-danger"
          *ngIf="
            forgotPasswordFormControl.email.touched &&
            forgotPasswordFormControl.email.errors?.inValidEmail
          "
        >
          {{ 'valid-email' | translate }}
        </div>
        <div class="text-success" *ngIf="forgotPasswordSuccessMsg">
          {{ forgotPasswordSuccessMsg| translate }}
        </div>
        <div class="text-danger" *ngIf="errorInForgotPassword">
          {{ errorInForgotPassword| translate }}
        </div>
      </div>

      <div class="row sign-in-button-container">
        <button class="btn-submit" #sendResetLinkBtn id="forgot-password-submit" data-qa="reset-link" type="submit">
          {{ 'send-reset-link' | translate }}
        </button>
      </div>
      <div class="row signup-tag-container">
        <span
          >{{ 'back-to' | translate }}
          <span
            class="signup-tag"
            (click)="goBack()"
            data-qa="back-to-signin"
            >{{ 'sign-in'| translate }}</span
          ></span
        >
      </div>
    </form>
  </div>
</div>

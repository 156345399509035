import { Component, OnInit, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import {
 FetchOrderLevelModifiers,
 OrderLevelModifierState,
 environment,
 SweetAlertService,
 LocationDefaultState,
 LocationState,
 Location,
 DeliveryState,
 SetSelectedModifier,
 SharedService,
 RemoveOrderLevelModifierFromCart,
 CartState, 
 Cart,
} from '@moduurnv2/libs-orderingapp/src';
import { Observable } from 'rxjs/internal/Observable';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '../modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ThemeOptions } from '@moduurnv2/features/ui/directives';
import {LanguageLocalize} from '@moduurnv2/features/ui/pipes/index';
import {OrderModifierComponent} from './modifier/modifier.component'
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'moduurnv2-order-level-modifiers',
  templateUrl: 'modifier.component.html',
  styleUrls: ['modifier.component.scss'],
})
export class OrderLevelModifierComponent implements OnInit {
  @Select(OrderLevelModifierState.getModifiers) getModifiers$: Observable<any>;
  @Select(OrderLevelModifierState.getPaymentSelected) getPaymentSelected$: Observable<any>;
  // @Select(OrderLevelModifierState.getMenuItemPrice) price$: Observable<any>;
  // @Select(OrderLevelModifierState.getGeneratedCount) count$: Observable<any>;
  // @Select(OrderLevelModifierState.getItemDetails) details$: Observable<any>;
  @Select (LocationState.getLocationStatus)location$ : Observable<Location>
  @Select(CartState.getCart) cart$: Observable<Cart>;

  @Input() data: any = null;
  imageBase = environment.imagebaseurl;
  close = environment.image_basepath + environment.images.close;
  checkIfEdit: boolean = false;
  submitClick: boolean = false;
  @Input() _modalClose: any;
  allowNoPaymentOrdering: boolean = false;
  themeOptions: ThemeOptions = {
    appPrimaryColor: true,
    appFontFamily:true
  };
  modifierslist: any;
  selectedModifier: any;
  portionCheck: boolean = false;
  fromCombo: string;
  loading: boolean = false;
  loader:any;
  fromComboDetails;
  menuItemIdInGroup: string;
  private _paymentSubscriprion: Subscription;
  isPaymentSelected;
  isMobileView: boolean;
  destroy$: Subject<boolean> = new Subject<boolean>();
  isShowCalorieDetails:boolean;
  isHideDescription:boolean;
  cartSubscription: any;
  cartDetails: any;


  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private modal: ModalService,
    private translate: TranslateService,
    private sweetAlert:SweetAlertService,
    private shared:SharedService
  ) {}

  ngOnInit() {
    const selectedLocation = this.store.selectSnapshot(
      LocationDefaultState.getLocationDefaults
    );
    const selectedDelivery = this.store.selectSnapshot(
      DeliveryState.getSelectedDelivery
    );
    let data = {menuProfileId: selectedLocation.activeProfile._id, deliveryType: selectedDelivery._id};
    this.store.dispatch(new FetchOrderLevelModifiers(data));
    this._paymentSubscriprion = this.getPaymentSelected$.subscribe(
      (isPaymentSelected) => {
        this.isPaymentSelected = isPaymentSelected;
      }
    );
    this.loader = {
      id:''
    };
    this.location$.subscribe(data=>{
      this.isShowCalorieDetails=data.activeProfile.showCalorieInformation;
      
      this.isHideDescription=data.activeProfile.hideDescription;
    })
    this.cartSubscription = this.cart$.pipe(takeUntil(this.destroy$)).subscribe((cart) => {
      if (cart) {
        this.cartDetails = cart;
      }
    });
  }
  ngOnDestroy() {
    this._paymentSubscriprion.unsubscribe();
  }


  set modalClose(modalRef: any) {
    this._modalClose = () => {
      this.modal.closeModal(modalRef);
    };
  }

  choiceText(modifierItem) {
    const { isForced, maxLimit, minLimit } = modifierItem;
    let choiceOption = this.translate.instant('please-select');
    let endText = this.translate.instant('optional');
    if (isForced) endText = this.translate.instant('required');

    if (maxLimit === 1) return `${choiceOption} 1 `+this.translate.instant('option')+ ` (${endText})` ;
    else if (maxLimit > 0)
      if (!minLimit || minLimit === 0) return `${choiceOption} ${this.translate.instant('upto')} ${maxLimit} `+this.translate.instant('option')+'s'+ ` (${endText})`;
      else if (minLimit === maxLimit)
        return `${choiceOption} ${maxLimit} `+this.translate.instant('option')+'s'+ ` (${endText})`;
      else return `${choiceOption} ${this.translate.instant('between')} ${minLimit} ${this.translate.instant('and')} ${maxLimit} `+this.translate.instant('option')+'s'+ ` (${endText})`;
  }

  headerErrors(modifierValid, id = null) {
    const submitted = this.store.selectSnapshot(OrderLevelModifierState.getSubmitted);
    if (this.submitClick && submitted && modifierValid === false) {
      var element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
        this.submitClick = false;
      }
    }

    return submitted ? modifierValid === false : false;
  }

  submitMenuItem() {
    // this.submitClick = true;
    // const selectedMenuItem = this.store.selectSnapshot(
    //   OrderLevelModifierState.getSelectedMenuItem
    // );
    // const currentCount = this.store.selectSnapshot(OrderLevelModifierState.getGeneratedCount);
    // if(this.validateMaxItemsPerUser(selectedMenuItem._id,currentCount)){
    //     if(this.fromCombo){
    //       if(selectedMenuItem.valid){
    //         this.store.dispatch(new SubmitModifierItem(this.fromCombo, this.menuItemIdInGroup));
    //         return this.closePopup();
    //       }
    //     }
    //     this.store.dispatch(new SubmitModifierItem());
    //     if (selectedMenuItem.valid) this.closePopup();
    //   }
  }

  increaseCounter() {
    this.changeCounter(1);
  }

  decreaseCounter() {
    this.changeCounter(-1);
  }

  changeCounter(count) {
  //  const selectedMenuItem = this.store.selectSnapshot(
  //     OrderLevelModifierState.getSelectedMenuItem
  //   );
  //   const currentCount = this.store.selectSnapshot(OrderLevelModifierState.getGeneratedCount);
  //   if(this.validateMaxItemsPerUser(selectedMenuItem._id,currentCount+count)){
  //   this.store.dispatch(new UpdateItemCount(count));
  //   }
  }

  closePopup() {
    if (this._modalClose) this._modalClose();
  }

  getActionCaption({ actionCaption,translatedactionCaption, name, translation }) {
    let Lang = new LanguageLocalize(this.translate,this.store);
    let nameTrans = Lang.transform(name,translation);
    let captionTrans=Lang.transform(actionCaption,translatedactionCaption);
    if (captionTrans) return `${captionTrans} ${nameTrans}`;
    else return `Please select your ${nameTrans}`;
  }
  // checkForCartEdit() {
  //   const { cartId } = this.store.selectSnapshot(OrderLevelModifierState.getItemDetails);
  //   if(cartId) {
  //     const cartItems = this.store.selectSnapshot(CartState.getCartItems);
  //   }
  // }

  checkNoPaymentOrdering() {
    const locationDefaults = this.store.selectSnapshot(
      LocationDefaultState.getLocationDefaults
    );
    if (
      locationDefaults &&
      locationDefaults?.locationData?.orderSettings?.allowNoPaymentOrdering
    ) {
      this.allowNoPaymentOrdering =
        locationDefaults.locationData.orderSettings.allowNoPaymentOrdering;
    }
  }


    validateMaxItemsPerUser(selectedMenuItemId,selectedMenuCountToUpdate){
    const state = this.store.selectSnapshot((app) => app);
    const { menulist, cart,venuemanagement } = state;
    let guestCount = undefined;
      if(venuemanagement?.totalPersonPerTable?.count){
        guestCount = parseInt(venuemanagement.totalPersonPerTable['count']);
        }
        if(guestCount > 0){
          let selectedCategory;
          let cartItemPrice;

            if(cart.cart && cart.cart.itemPrice){
                  cartItemPrice = cart.cart.itemPrice;
                  let selectedItem = cart.cart.itemPrice.find(item=> item.itemId == selectedMenuItemId);
                  if(selectedItem){
                  let selectedCategoryId = selectedItem.menuSectionId;
                    selectedCategory = menulist.menuList.categories.find(cat =>cat._id == selectedCategoryId);
                  } else if(menulist.selectedCategory){
                    selectedCategory = menulist.selectedCategory;
                  }
            } else if(menulist.selectedCategory){
              selectedCategory = menulist.selectedCategory;
            }
          if(selectedCategory && selectedCategory.maxItemsPerOrder && selectedCategory.maxItemsPerOrder>0){
            if(cartItemPrice){
            let cartItemsFromCurrentCategory = cartItemPrice.filter(item=>item.menuSectionId == selectedCategory._id);
            let selectedMenuDetails = cartItemPrice.find(item=>item.itemId == selectedMenuItemId);
            const totalItemCountPerCategory = cartItemsFromCurrentCategory.reduce((total, eachItem) => {
                  return total + eachItem.count;
              }, 0);
            let totalItemsValidPerCategory = guestCount * selectedCategory.maxItemsPerOrder;

            if(this.isOrderItemCountExceeded(totalItemsValidPerCategory,totalItemCountPerCategory,selectedMenuCountToUpdate,selectedMenuDetails)){
            this.sweetAlert.toast('error', 'Sorry, that’s the most you can order.');
            //  if(selectedMenuDetails?.count) {
            //    this.value = selectedMenuDetails.count;
            //  } else {
            //    this.value = 0;
            //  }
              return false;
            } else {
              return true;
            }
            } else if(selectedMenuCountToUpdate > guestCount * selectedCategory.maxItemsPerOrder){
              this.sweetAlert.toast('error', 'Sorry, that’s the most you can order.');
              return false;
            }
            else {
              return true;
            }
          } else  {
            return true;
          }
          }  else if(guestCount == 0){
            this.sweetAlert.toast('error', 'You cant proceed without entering the number of guests.');
            return false;
        } else {
          return true;
        }
  }

  isOrderItemCountExceeded(totalItemsValidPerCategory,totalItemCountPerCategory,selectedMenuCountToUpdate,selectedMenuDetails){
    let isExceeded;
    if(this.checkIfEdit){
      if(((totalItemsValidPerCategory <= (selectedMenuCountToUpdate + this.checkIfEdit['count'])) && (!selectedMenuDetails || selectedMenuDetails.count < selectedMenuCountToUpdate))||(totalItemsValidPerCategory < selectedMenuCountToUpdate))
      {
        isExceeded = true
      } else isExceeded =  false;
    } else if(
      !this.checkIfEdit
    ) {if(((totalItemsValidPerCategory < (totalItemCountPerCategory+selectedMenuCountToUpdate)))||(totalItemsValidPerCategory < selectedMenuCountToUpdate))
      {
        isExceeded = true
      } else isExceeded = false;
    }

    return isExceeded;
  }

  get checkComboMax() {
    // // if(this.fromComboDetails){
    // //   const { itemCount } = this.fromComboDetails;
    // //   const count = this.store.selectSnapshot(OrderLevelModifierState.getGeneratedCount);
    // //   return itemCount? count >= itemCount: false;
    // // }
    // if(this.fromCombo){
    //   const cartCombo = this.store.selectSnapshot(ComboState.getCartCombo);
    //   if(cartCombo?.comboGroups){
    //     const foundCartCombo = cartCombo.comboGroups.find( comboGroup => comboGroup.groupId === this.fromComboDetails._id );
    //     if(foundCartCombo){
    //          const count = this.store.selectSnapshot(OrderLevelModifierState.getGeneratedCount);
    //       const itemCount = foundCartCombo.groupItems.reduce((total, item) => {
    //         return total + item.count;
    //       }, 0);
    //       return this.fromComboDetails.itemCount && (itemCount+count) >= this.fromComboDetails.itemCount;
    //     }
    //   }
    //     const { itemCount } = this.fromComboDetails;
    //     const count = this.store.selectSnapshot(OrderLevelModifierState.getGeneratedCount);
    //     return count >= itemCount;

    // }
     return false;
  }

  get checkGroupFull(){
    // if(this.fromCombo){
    //   const cartCombo = this.store.selectSnapshot(ComboState.getCartCombo);
    //   if(cartCombo?.comboGroups){
    //     const foundCartCombo = cartCombo.comboGroups.find( comboGroup => comboGroup.groupId === this.fromComboDetails._id );
    //     if(foundCartCombo){
    //       const itemCount = foundCartCombo.groupItems.reduce((total, item) => {
    //         return total + item.count;
    //       }, 0);
    //       return this.fromComboDetails.itemCount && itemCount >= this.fromComboDetails.itemCount;
    //     }
    //   }
    // }
     return false;
  }

getModifierSelectedValue(id)
{
  // this.modifierslist =this.store.selectSnapshot(OrderLevelModifierState.getGeneratedModifiers)
  // this.selectedModifier= this.modifierslist.find(function (item) {
  //   if(item.selected==true)
  //   return (item._id == id && item.selected==true);
  // });

}
getGeneratedPortion(portion)
{
  portion = portion.filter(item => item.selected=== true);
  if(!portion)
  return;
  if(portion.length>0)
    this.portionCheck=true;
}


addModifier(modifier){
  this.store.dispatch(new SetSelectedModifier(modifier._id));
  const selectedLocation = this.store.selectSnapshot(
    LocationDefaultState.getLocationDefaults
  );
  const selectedDelivery = this.store.selectSnapshot(
    DeliveryState.getSelectedDelivery
  );
  let settings = {menuProfileId: selectedLocation.activeProfile._id, deliveryType: selectedDelivery._id};
  this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
  if (this.isMobileView) {
    let vm=this;
    this.modal.showModal(OrderModifierComponent, {
      data: modifier,
      whenClosed: (data) => {
        if(data){
          modifier.loaderStart = true;
          vm.loader ={
            id:modifier._id
          }
        this.store.dispatch(new FetchOrderLevelModifiers(settings))
        // setTimeout(vm.clearLoading, 2000,vm.loader);
      } else {
       modifier.loaderStart = false;
      }
      }
    });
  } else {
    let vm=this;
    this.modal.showSmallModal(OrderModifierComponent,{data:modifier,
      whenClosed: (data) => {
        if(data){
          modifier.loaderStart = true;
          vm.loader ={
            id:modifier._id
          }
        this.store.dispatch(new FetchOrderLevelModifiers(settings))
        // setTimeout(vm.clearLoading, 2000,vm.loader);
      } else {
       modifier.loaderStart = false;
      }
      }

    });

  }

}
deleteModifier(modifier){
  let firstAttempt = true;
  this.modal.showSmallModal(AlertPopupComponent,{data:{
    header:this.translate.instant('are-you-sure-remove'),
    actionable:true
  },
  whenClosed: (data) => {
    if(data && firstAttempt){
      firstAttempt= false;
      this.loader.id='';
      modifier.loaderStart = false;
      this.store.dispatch(new RemoveOrderLevelModifierFromCart(modifier._id))
    };
  },
});
}
clearLoading(modifier){
modifier.id = '';
}
}

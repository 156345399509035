import { LocationStatus } from '../models';

export class FetchLocationList {
  static readonly type = '[LOCATION] FetchList';

  constructor(public payload?: string[]) {}
}
export class FetchLocationInfo {
  static readonly type = '[LocationInfo] FetchInfo';

  constructor(public payload?: string) {}
}
export class FetchLocationStatus {
  static readonly type = '[LocationStatus] FetchStatus';

  constructor(public payload?: string) {}
}
export class SetSelectedLocation {
  static readonly type = '[LOCATION] Set';

  constructor(public payload: string) {}
}
export class setLocationDistance {
  static readonly type = '[LocationDistance] Set';

  constructor(public payload: string) {}
}

export class FetchFreshSelectedLocation {
  static readonly type = '[FetchFreshSelectedLocation] SetFreshLocation';
  
  constructor() {}
}

export class ClearSelectedLocation {
  static readonly type = '[ClearSelectedLocation] Set';

  constructor(public payload?: string) {}
}
export class SetCalenderPicker {
  static readonly type = '[SetCalenderPicker] Set';

  constructor(public payload: boolean) {}
}

export class SetLocationStatusLocal {
  static readonly type = '[LocationStatus] SetLocationStatusLocal';

  constructor(public payload?: LocationStatus) {}
}


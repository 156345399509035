import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserSpecificationDataService {
  constructor() {}

  // function for  return os name

  getOsName() {
    const platform = navigator.platform;
    if (platform.indexOf('Win') !== -1) {
      return 'Windows';
    } else if (platform.indexOf('Mac') !== -1) {
      return 'Mac OS';
    } else if (platform.indexOf('XII') !== -1) {
      return 'Unix';
    } else if (platform.indexOf('Linux') !== -1) {
      return 'Linux';
    } else if (platform.indexOf('iPhone') !== -1) {
      return 'iOS';
    } else if (platform.indexOf('Andriod') !== -1) {
      return 'Andriod';
    } else {
      return platform;
    }
  }
  // function for  return screen width
  getScreenWidth() {
    return window.screen.availWidth;
  }
  // function for  return screen Height
  getScreenHeight() {
    return window.screen.availHeight;
  }
  // function for  return browser name
  getBrowserName() {
    if (
      (navigator.userAgent.indexOf('Opera') ||
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else if (navigator.userAgent.indexOf('MSIE') != -1) {
      return 'Internet Explorer';
    } else {
      return navigator.userAgent;
    }
  }
  // function for  return device type
  getdeviceType() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet';
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'Mobile';
    }
    return 'Desktop';
  }
}

// angular
import { Injectable } from '@angular/core';

// app
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DeliveryMethodService {

  constructor(
    private http: HttpClient,
  ) { }

  fetchDeliveries(locationId?: string): Observable<any> {
    const url = `${environment.deliveriesApiUrl}locations/${locationId}/deliveryTypes`;
    return this.http.get(url);
  }
}

<ng-container
  *ngIf="
    (splash && splash?.fromTemplate && pagesToView == 1) ||
      (splash && splash?.length && splash[splashIndex].fromTemplate && pagesToView > 1);
    else defaultSplashTemplate
  "
>
  <ng-container
    *ngIf="
    splash && splash?.fromTemplate && pagesToView == 1;
      else multipleTemplatedSplash
    "
  >
    <moduurnv2-landing-template
      [landingTemplateObj]="splash"
      (closeClick)="closeLandingModal($event)"
    ></moduurnv2-landing-template>
  </ng-container>
  <ng-template #multipleTemplatedSplash>
    <div *ngIf="splash && splash[splashIndex]" >

      <moduurnv2-landing-template
        [landingTemplateObj]="splash[splashIndex]"
        (closeClick)="closeLandingModal($event)"
      ></moduurnv2-landing-template>
    </div>
  </ng-template>
</ng-container>

<ng-template #defaultSplashTemplate>
  <ng-container *ngIf="splash ; else multipleSplash" >

  
  <div
    class="splash-container"
    [ngClass]="{
      'splash-mobile': isMobileView,
      'splash-2-row': checkNumberOfRows
    }"
    *ngIf="pagesToView == 1; else multipleSplash"
  >
    <div
      class="banner-image"
    >
      <div
      class="banner-image-inner"
      *ngIf="splash && splash.imageUrl"
      [bg-image]="imagePath + splash.imageUrl"
      ></div>
  </div>
    <!-- <div class="restaurant-logo">
    <img [src]="restaurantLogo" alt="restaurant img"/>
  </div> -->
    <div
      class="splash-content hide-scroll"
      [ngClass]="{ 'splash-content-mob': splash.imageUrl }"
      *ngIf="(splash && splash?.title) ||(splash && splash?.message)"
    >
      <header *ngIf="splash && splash?.title">{{ splash?.title }}</header>
      <div class="content-scroll custom-scroll scroll-small" *ngIf="splash && splash?.message">
        {{ splash?.message }}
      </div>
    </div>
    <div class="btn-wrapper">
      <button
        class="btn"
        (click)="close()"
        aria-label="continue"
        data-qa="continue"
      >
        {{ 'continue' | translate }}
      </button>
    </div>
  </div>
</ng-container>

  <ng-template #multipleSplash>
    <div
      class="splash-container"
      [ngClass]="{
        'splash-mobile': isMobileView,
        'splash-2-row': checkNumberOfRows
      }"
    >
      <ng-container *ngIf="loader; else loadContent">
        <div class="banner loader"></div>
        <!-- <div class="logo loader"></div> -->
        <div>
          <div class="line loader"></div>
          <div class="line loader"></div>
          <div class="line loader"></div>
          <div class="line loader"></div>
          <div class="line loader"></div>
          <div class="line loader"></div>
          <div class="line loader"></div>
          <div class="line loader"></div>
          <div class="line loader"></div>
          <div class="line loader"></div>
        </div>
        <!-- <div class="button loader"></div> -->
      </ng-container>
      <ng-template #loadContent>
        <!-- <ng-container
          *ngIf="!splash[splashIndex]?.fromTemplate; else loadNewLandingTemplate"
        > -->
        <div
            class="banner-image">
          <div
            class="banner-image-inner"
            *ngIf="splash && splash[splashIndex]?.imageUrl && checkContent"
            [ngStyle]="{
              'background-image':
                'url(' + imagePath + splash[splashIndex]?.imageUrl + ')'
            }"
          ></div>
        </div>
          <img
            *ngIf="splash && splash[splashIndex]?.imageUrl && !checkContent"
            [src]="imagePath + splash[splashIndex]?.imageUrl"
            class="block-image"
            alt="block-img"
          />
          <!-- <div class="restaurant-logo">
        <img [src]="restaurantLogo" alt="restaurant img"/>
      </div> -->
          <div
            class="splash-content hide-scroll"
            [ngClass]="{
              'splash-content-mob': splash[this.splashIndex]?.imageUrl
            }"
            *ngIf="checkContent"
          >
            <header *ngIf="splash && splash[splashIndex]?.title">
              {{ splash[splashIndex]?.title }}
            </header>
            <div class="content-scroll custom-scroll scroll-small" *ngIf="splash && splash[splashIndex]?.message">
              {{ splash[splashIndex]?.message }}
            </div>
          </div>
          <div class="btn-wrapper">
            <button
              class="btn"
              (click)="changeIndex($event)"
              *ngIf="splash && splashIndex == 0; else continue"
            >
              Next
            </button>
            <ng-template #continue>
              <button
                class="btn"
                (click)="close()"
                aria-label="continue"
                data-qa="continue"
              >
                {{ 'continue' | translate }}
              </button>
            </ng-template>
          </div>
        <!-- </ng-container> -->
      </ng-template>

      <!-- <ng-template #loadNewLandingTemplate>
        <moduurnv2-landing-template
          [landingTemplateObj]="splash[splashIndex]"
          (closeClick)="closeLandingModal($event)"
        >
        </moduurnv2-landing-template>
      </ng-template> -->
    </div>
  </ng-template>
</ng-template>

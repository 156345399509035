import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { VenueManagementState } from '@moduurnv2/libs-orderingapp/src/core';
import { Observable } from 'rxjs';
import { ModalService } from '../../../../apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { PersonCountComponent } from '../../../../apps/orderingapp/web-orderingapp/src/app/features/home/person-count/person-count.component';
@Injectable()
export class PersonPerTableGuardService implements CanActivate {
  personPerTableEntered: Boolean = true;
  CustomerNoTable: Number;
  PersonNumber;
  @Select(VenueManagementState.getCustomersPerTable)
  customerNoPerTable$: Observable<any>;
  constructor(
    public store: Store,
    public router: Router,
    private modal: ModalService
  ) {
    this.customerNoPerTable$.subscribe((persons) => {
      if (persons) {
        this.personPerTableEntered = true;
      } else {
        this.personPerTableEntered = false;
      }
    });
  }

  customerNoPerTable: any = {};
  _modalClose: any = null;
  set modalClose(modalRef: any) {
    this._modalClose = (data) => {
      this.modal.closeComponentModal(modalRef, data);
    };
  }
  canActivate(): any {
    const selectedDelivery = this.store.selectSnapshot(
      (app) => app && app.delivery && app.delivery.selectedDelivery
    );

    if (selectedDelivery?.textCode == 'DELIVERYTYPE08') {
      if (this.personPerTableEntered) {
        return true;
      } else {
        this.modal.showSmallFormModal(PersonCountComponent);
        return false;
      }
    } else {
      return true
    }
  }
}

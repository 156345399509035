import {
  Directive,
  HostBinding,
  OnInit,
  OnDestroy,
  Input,
} from '@angular/core';
import { Select } from '@ngxs/store';
import {
  WhitelabelState,
  Whitelabel,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Observable, Subscription } from 'rxjs';

@Directive({
  selector: '[theme]',
})
export class ThemeDirective implements OnInit, OnDestroy {
  @Input('theme') themeOptions: ThemeOptions;
  @HostBinding('style.background-color') bgColour: string;
  @HostBinding('style.color') fontColour: string;
  @HostBinding('style.font-family') appFontFamily: string;

  @Select(WhitelabelState.getWhitelabel) whitelabel$: Observable<Whitelabel>;
  whitelabelSubscription: Subscription;

  constructor() {}

  ngOnInit() {
    this.whitelabel$.subscribe((response) => {
      if (response) {
        const {
          appPrimaryColor,
          appSecondaryColor,
          appFontColor,
          appFontFamily,
        } = response;
        if (appPrimaryColor && this.themeOptions.appPrimaryColor)
          this.bgColour = appPrimaryColor;

        if (appSecondaryColor && this.themeOptions.appSecondaryColor)
          this.bgColour = appSecondaryColor;

        if (appFontColor && this.themeOptions.appFontColor)
          this.fontColour = appFontColor;

        if (appFontFamily && this.themeOptions.appFontFamily)
          this.appFontFamily = appFontFamily;

        if (appFontFamily && this.themeOptions.appFontFamily)
          this.appFontFamily = appFontFamily;
      }
    });
  }

  ngOnDestroy() {
    if (this.whitelabelSubscription) this.whitelabelSubscription.unsubscribe();
  }
}

export interface ThemeOptions {
  appFontColor?: boolean;
  appFontFamily?: boolean;
  appPrimaryColor?: boolean;
  appSecondaryColor?: boolean;
}

import { Component, OnInit } from '@angular/core';
import {  NotificationBarBaseComponent,LocationDefaultState,
  } from '@moduurnv2/libs-orderingapp';
import { SharedService } from '@moduurnv2/libs-orderingapp';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'moduurnv2-store-close-notification-bar',
  templateUrl: 'store-close-notification.component.html',
  styleUrls: ['store-close-notification.component.scss'],
})
export class StoreCloseNotificationBarComponent extends NotificationBarBaseComponent
  implements OnInit {  
  @Select(LocationDefaultState.getLocationDefaults) locationDefaultStatus$ :Observable<[]>;    
  isMobileView:boolean;
  constructor(
    store: Store,
    private shared: SharedService, 
    ) {
    super(store);   
  }
  ngOnInit(): void {    
    this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );     
  }  
}

import { environment } from '@moduurnv2/libs-orderingapp/src/core/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { LocationState } from '@moduurnv2/libs-orderingapp/src/core';
import { PixelHandler } from '@moduurnv2/core/services/app.fbpixel.setup';

declare let gtag: Function;
let allPagesToTrack = [
  '/confirm',
  '/home',
  '/categories',
  '/combos',
  '/comboGroups',
  '/menu',
  '/order',
  '/payment',
  '/progress',
  '/settings',
];
let pagesToTrack = [
  '/confirm',
  '/home',
  '/categories',
  '/combos',
  '/comboGroups',
  '/menu',
  '/order',
  '/payment',
  '/progress',
  '/settings',
];
@Injectable()
export class AnalyticsHandler {
  googleAnalyticsTrackingId: string;
  googleAnalyticsGlobalTrackingId: string;
  googleAnalyticsEnableTracking: boolean = false;
  org_id = Config.organizationId;
  org_name = Config.name;
  globalAnalyticsProperties: any = [];

  public constructor(private http: HttpClient, private store: Store, public pixel: PixelHandler) {}
  public setupAnalytics() {
    const url =
      environment.organizationApiUrl + `organizations/${this.org_id}/analytics`;
    this.http.get(url).subscribe((resp) => {
      if (
        resp['data'] &&
        resp['data'].analytics &&
        resp['data'].analytics.length
      ) {
        const googleAnalyticsDetails = resp['data'].analytics.find(
          (obj) => obj.type == 'ANALYTICSPROVIDER01'
        );
        const fbPixelDetails = resp['data'].analytics.find(
          (obj) => obj.type == 'ANALYTICSPROVIDER02'
        );
        if (
          googleAnalyticsDetails &&
          googleAnalyticsDetails.isEnabled &&
          googleAnalyticsDetails.configs
        ) {
          const trackingId = googleAnalyticsDetails.configs.trackingId;
          //commented to check seperate trackingId for Moduurn completly and configured organization only
          this.googleAnalyticsTrackingId = trackingId;
          //this.googleAnalyticsGlobalTrackingId = "UA-174285171-2";
          this.getAnalyticsProperties().then(res=>{
            this.googleAnalyticsEnableTracking =
            this.googleAnalyticsTrackingId && googleAnalyticsDetails.isEnabled
              ? true
              : false;
            this.setGTagScripts(trackingId);
            if(res && trackingId != res)
            this.setGTagScripts(res);
          });

        }
        if (
          fbPixelDetails &&
          fbPixelDetails.isEnabled &&
          fbPixelDetails.configs
        ) {
          const trackingId = fbPixelDetails.configs.trackingId;
          this.pixel.organisationPixelCheck(trackingId, fbPixelDetails)
        } else {
          this.pixel.getPixelProperties().then(res=>{
            this.pixel.setPixelScripts(res);
          });
        }
      } else {
        for(let i=0; i<=resp['data'].analytics.length;i++){
          if(resp['data'].analytics[i].type == "ANALYTICSPROVIDER01"){
            this.getAnalyticsProperties().then(res=>{
              if(res){
              this.setGTagScripts(res);
              } 
            })
          }
          if(resp['data'].analytics[i].type == "ANALYTICSPROVIDER02"){
            this.getAnalyticsProperties().then(res=>{
              if(res){
              this.pixel.setPixelScripts(res);
              } 
            })
          }
        }
      }
    });
  }

  setGTagScripts(trackingId){
    let gaScript1 = document.createElement('script');
    gaScript1.innerText = `window.ga = window.ga || function(){(ga.q=ga.q||[]).push(arguments);}ga.l=+new Date;ga(\'create\', ${trackingId},\'auto\');ga(\'send\', \'pageview\');`;
    let gaScript2 = document.createElement('script');
    gaScript2.setAttribute('async', 'true');
    gaScript2.setAttribute(
      'src',
      `https://www.google-analytics.com/analytics.js`
    );

    let gtagScript1 = document.createElement('script');
    gtagScript1.setAttribute('async', 'true');
    gtagScript1.setAttribute(
      'src',
      `https://www.googletagmanager.com/gtag/js?id=${trackingId}`
    );
    let gtagScript2 = document.createElement('script');
    gtagScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${trackingId}\');`;
    document.documentElement.firstChild.appendChild(gtagScript1);
    document.documentElement.firstChild.appendChild(gtagScript2);
  }

  getAnalyticsProperties() {
    return new Promise((resolve,reject)=>{
    const url =
      environment.settingsApiUrl + 'settings/constants/ANALYTICSPROPERTIES';
    this.http.get(url).subscribe((response) => {
      this.globalAnalyticsProperties = response;
      let index = this.globalAnalyticsProperties.findIndex(
        (x) => x.textCode == 'ANALYTICSPROPERTIES01'
      );
      if (index != -1) {
        this.googleAnalyticsGlobalTrackingId = this.globalAnalyticsProperties[
          index
        ].text;
      }
      resolve(this.googleAnalyticsGlobalTrackingId);
    });
  });
  }
  public setPageRedirects(route: string) {
    const location = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    //Added for global trackingid
    if (this.googleAnalyticsGlobalTrackingId) {
      // console.log('trackingId', this.googleAnalyticsGlobalTrackingId);
      gtag('js', new Date());
      gtag('set', {
        custom_map: {
          dimension1: 'org_details',
        },
      });
      gtag('config', this.googleAnalyticsGlobalTrackingId, {
        page_path: route,
        org_details: location?.name ? location.name : undefined,
      });
    }
    if (
      this.googleAnalyticsEnableTracking &&
      this.googleAnalyticsGlobalTrackingId != this.googleAnalyticsTrackingId
    ) {
      // console.log('trackingId', this.googleAnalyticsTrackingId);
      gtag('js', new Date());
      //Call 'set' before config to define mapping
      gtag('set', {
        custom_map: {
          dimension1: 'org_details',
        },
      });
      gtag('config', this.googleAnalyticsTrackingId, {
        page_path: route,
        org_details: location?.name ? location.name : undefined,
      });
    }
    //}
  }
  public setEvents(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: object = null
  ) {
    // if (this.googleAnalyticsEnableTracking) {
      // gtag('event', eventName, {
      //   eventCategory: eventCategory,
      //   eventLabel: eventLabel,
      //   eventAction: eventAction,
      //   eventValue: eventValue,
      // });
    // }
  }
  public setCustomDimension(dimensionIndex: string, dimensionValue: any) {
    // if (this.googleAnalyticsEnableTracking) {
      gtag('set', dimensionIndex, dimensionValue);
    // }
  }
}

import { State, StateContext, Action, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { LocationSelectionAnalytics, 
         ServiceTypeSelectionAnalytics, 
         ItemCheckoutAnalytics } from '../models';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import {
 setAnalyticsData
} from '../actions';
import { Injectable } from '@angular/core';
import { AnalyticsService } from '../services/';
import { tap, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthEmailState } from './auth-email.state';
import { HttpClient } from '@angular/common/http';

export class AnalyticsStateModel {
  locationSelection: LocationSelectionAnalytics;
  serviceTypeSelection: ServiceTypeSelectionAnalytics;
  itemCheckout: ItemCheckoutAnalytics;
  analyticsData: any;
}

@State<AnalyticsStateModel>({
  name: 'analyticsTracking',
})
@Injectable()
export class AnalyticsState {

    fbPixelGlobalTrackingId: any;
    globalPixelProperties: any;
    fbPixelTrackingId: any;
    fbPixelEnableTracking: any;
    org_id = Config.organizationId;

  constructor(private analytics: AnalyticsService, private store:Store, private router:Router, private http: HttpClient) {}

  @Action(setAnalyticsData)
  setAnalyticsValue(
    { setState }: StateContext<AnalyticsStateModel>,
    { payload }: setAnalyticsData
  ) {
    const authDetails = this.store.selectSnapshot(AuthEmailState.getFullLoginDetails);
    let webUrl = this.router.getCurrentNavigation()?.finalUrl;
    payload['webUrl'] = webUrl;
    if(authDetails?.isLoggedIn){
      payload['customerName'] = authDetails?.userData?.fname + authDetails?.userData?.lname;
      payload['customerEmail'] = authDetails?.userData?.email;
      payload['customerId'] = authDetails?.userData?.user_id
    }
    let pixelId = this.fbPixelTrackingId;
    payload['pixelId'] = pixelId;
    return this.analytics.setAnalytics(payload).pipe(
      tap((response) => {
        if (response)
          setState(
            patch({
                analyticsData: response,
            })
          );
        else throw response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

}

import { PlaceOrder, OrderResponse } from '../models';

export class SetOrder {
  static readonly type = '[PlaceOrder] Set';

  constructor(public payload: PlaceOrder) {}
}
export class SetOrderDetails {
  static readonly type = '[SetOrderDetails] Set';

  constructor(public payload: any) {}
}
export class ClearOrderResponseMessage {
  static readonly type = '[ClearOrderResponseMessage] Set';

  constructor() {}
}

export class changeShowStatus {
  static readonly type = '[changeShowStatus] Set';

  constructor(public payload?: boolean) {}
}
export class fromOrderSuccess {
  static readonly type = '[fromOrderSuccess] Set';

  constructor(public payload?: boolean) {}
}
export class FetchMealCodePayment {
  static readonly type = '[CART] FetchMealCodePayment';
  constructor() {}
}
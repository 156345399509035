import { Delivery } from '../models';

export class FetchDeliveryList {
  static readonly type = '[DELIVERY] FetchList';

  constructor() {}
}

export class SetSelectedDelivery {
  static readonly type = '[DELIVERY] Set';

  constructor(public payload: string) {}
}
export class ClearSelectedDelivery {
  static readonly type = '[DELIVERY] ClearSelectedDelivery';

  constructor() {}
}

import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
  Inject,
  ErrorHandler,
} from '@angular/core';
import { NgxsModule ,Store} from '@ngxs/store';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';

import { APP_BASE_HREF, CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// libs
import { TranslateService } from '@ngx-translate/core';
import { throwIfAlreadyLoaded } from '@moduurnv2/utils';

// app
import { environment } from '@moduurnv2/libs-orderingapp/src/core/environments/environment';

import {
  WhitelabelState,
  LocationState,
  DeliveryState,
  SelectedOrderState,
  AddressState,
  DealsState,
  ViewAllOrdersState,
} from '@moduurnv2/libs-orderingapp/src/core/state';
import { PlatformLanguageToken } from '@moduurnv2/libs-orderingapp/src/core/services/tokens';
import { GlobalErrorHandler } from '@moduurnv2/libs-orderingapp/src/core/services/global-error-handler';
import { HttpConfigInterceptor } from '@moduurnv2/libs-orderingapp/src/core/services/http-config-interceptor';
import {
  AppInitService,
  SharedService,
  WindowService,
  ValidationService,
  LogService,
  Provinces,
} from '@moduurnv2/libs-orderingapp/src/core/services';

import * as moment from 'moment';
// import { Storage } from './storage-engine';
// (global as any).localStorage = new Storage();

/**
 * DEBUGGING
 */
LogService.DEBUG.LEVEL_4 = !environment.production;

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forRoot([
      WhitelabelState,
      LocationState,
      DeliveryState,
      SelectedOrderState,
      AddressState,
      DealsState,
      ViewAllOrdersState
    ]),
    // NgxsStoragePluginModule.forRoot({
    //   key: 'whitelabel',
    //   storage: StorageOption.LocalStorage
    // })
  ],
  providers: [],
})
export class LibsOrderingappModule {
  // configuredProviders: *required to configure WindowService and others per platform
  static forRoot(
    configuredProviders: Array<any>
  ): ModuleWithProviders<LibsOrderingappModule> {
    return {
      ngModule: LibsOrderingappModule,
      providers: [
        LogService,
        AppInitService,
        ValidationService,
        WindowService,
        SharedService,
        Provinces,
        {
          provide: APP_BASE_HREF,
          useValue: '/',
        },
        // { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpConfigInterceptor,
          multi: true,
        },
        ...configuredProviders,
      ],
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: LibsOrderingappModule,
    @Inject(PlatformLanguageToken) lang: string,
    translate: TranslateService,
    private store: Store
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
    // ensure default platform language is set
    let selectedLocation = this.store.selectSnapshot(LocationState.getSelectedLocation)
    if(selectedLocation && selectedLocation.language && selectedLocation.language.textCode){
      switch(selectedLocation.language.textCode){
        case 'LANGUAGE01':{
          translate.use('fr');
          moment.locale('fr');
        }
          break;
        case 'LANGUAGE02':{
          translate.use('es');
          moment.locale('es');
        }
          break;
        default:{
          translate.use('en');
          moment.locale('en');
        }
          }
    }
    else if (lang == 'es' || lang == 'fr'){
      translate.use(lang);
      moment.locale(lang);
    }
      
    else {
      translate.use('en');
      translate.use('en');
    }
  }
}

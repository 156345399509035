import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  LocationState,
  SetSelectedLocation,
  Location,
  FetchLocationList,
  WhitelabelState,
  DeliveryState,
  SpinnerTypes,
  AuthState
} from '@moduurnv2/libs-orderingapp/src/core';
import { Observable } from 'rxjs/internal/Observable';
import { Store, Select } from '@ngxs/store';
import { SetLoginFrom } from '../core/actions/auth.actions';
@Injectable()
export class LocationGuardService implements CanActivate {


  @Select(LocationState.getLocationsList) locationsList$: Observable<
    Location[]
  >;

  constructor(public store: Store, public router: Router,private spinner: NgxSpinnerService) {}
  canActivate(route:ActivatedRouteSnapshot): boolean {   
    let selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    let url = window.location.href;
    const qrData = this.store.selectSnapshot(
      WhitelabelState.getQRScanDetails
    );

    if(qrData?.venueName){
      this.spinner.show(SpinnerTypes.QR_SCAN_INITIAL);
    }
    if(url){
      let queryParams = url.split('?')[1];
      if(queryParams){
        let queryParam = queryParams.split('&');
        let qrScanParams = queryParam.find(query => query.includes('qrScanDetails'));
        let params;
        let b64String;
        if(qrScanParams && selectedLocation && qrData?.venueName){
            params = qrScanParams.slice(14);
            b64String = decodeURIComponent(params);
            this.router.navigate([
              `locations/${selectedLocation._id}/home`,
            ],{ queryParams: { qrScanDetails: b64String }});
          return false;
        } else if(qrScanParams){
          return true;
        }
      }
    }

    let orgOtherConfig = this.store.selectSnapshot(
      WhitelabelState.getOther
    );
    if(orgOtherConfig && orgOtherConfig.enableLocationSelectionAsDefault) {
      return true; // This will always enable showing locations screen while loading application(no default location wil be set)
    } else {
    let currentUrl = this.router.url;
    let isHomePage = currentUrl.indexOf('/home') == -1 ? false : true;

    if (!isHomePage) {
      if (selectedLocation && selectedLocation._id) {        
        this.store.dispatch(new FetchLocationList());
        const previousPage = this.store.selectSnapshot(AuthState.getLoginFrom);        
        if(previousPage =='locations'){
          this.store.dispatch(new SetLoginFrom(""))
          return true
        }
        this.locationsList$.subscribe((response) => {
          if (response) {
            //Note : Dont delete this. Deleting can result in location switching automatically to previous selected location
            let updatedSelectedLocation = this.store.selectSnapshot(
              LocationState.getSelectedLocation
            );
            if(response.findIndex(location => location._id == updatedSelectedLocation._id ) > -1){
              this.store.dispatch(
              new SetSelectedLocation(updatedSelectedLocation._id)
            );
            }
            else{
              this.router.navigate([`locations/`],{
                queryParamsHandling:"preserve"
              });
              return false;
            }

        }
        });
        this.router.navigate([
          `locations/${selectedLocation._id}/home`,
        ],{
          queryParamsHandling:"preserve"
        });
        return false;
      } else {
        // selectedLocation = localStorage.getItem('selectedLocation')
        //   ? JSON.parse(localStorage.getItem('selectedLocation'))
        //   : undefined;
        if (!selectedLocation) return true;
        else {
          this.store.dispatch(new FetchLocationList());
          this.locationsList$.subscribe((response) => {
            if (response) {
              if (selectedLocation && selectedLocation._id) {
                 //Note : Dont delete this. Deleting can result in location switching automatically to previous selected location
                  let updatedSelectedLocation = this.store.selectSnapshot(
                    LocationState.getSelectedLocation
                  );
                if(response.findIndex(location => location._id == updatedSelectedLocation._id ) > -1){
                this.router.navigate([
                  `locations/${updatedSelectedLocation._id}/home`,
                ],{
                  queryParamsHandling:"preserve"
                });
                this.store.dispatch(
                  new SetSelectedLocation(updatedSelectedLocation._id)
                );
                return false;
                }
                else{
                  this.router.navigate([`locations/`],{
                    queryParamsHandling:"preserve"
                  });
                  return false;
                }

              }
            }
          });
        }
      }
      return false;
    }

    return true;
  }

}

}

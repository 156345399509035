import { BaseComponent,LocationDefaultState } from '@moduurnv2/libs-orderingapp/src/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
export class NotificationBarBaseComponent extends BaseComponent {
  allowNoPaymentOrdering:boolean = false;
  constructor(public store:Store) {
    super();
    this.checkNoPaymentOrdering();
  }
  getImageURL(icon) {
    return `assets/images/${icon.filename}.svg`;
  }

  checkNoPaymentOrdering(){
    const locationDefaults = this.store.selectSnapshot(
      LocationDefaultState.getLocationDefaults
    );
    if (
      locationDefaults &&
      locationDefaults?.locationData?.orderSettings?.allowNoPaymentOrdering
    ) {
      this.allowNoPaymentOrdering =
        locationDefaults.locationData.orderSettings.allowNoPaymentOrdering;
    }
  }


}

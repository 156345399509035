import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  DealsBaseComponent,
  environment,
} from '@moduurnv2/libs-orderingapp/src';
import { AuthState,SharedService,CartState,AnalyticsTrackingType,
  DealSelectionAnalytics,
  setAnalyticsData, DealsState, Deals} from '@moduurnv2/libs-orderingapp';
import { LoginDesktopComponent } from '../../../login/components/login-desktop/login-desktop.component';
import { AnalyticsHandler } from '@moduurnv2/core/services';
import { Store, Select } from '@ngxs/store';
import { Router } from '@angular/router';
import { ModalService } from '../../../shared/modal/modal.service';
import { Observable } from 'rxjs';
@Component({
  selector: 'moduurnv2-deal-view-details',
  templateUrl: './deal-view-details.component.html',
  styleUrls: ['./deal-view-details.component.scss'],
})
export class DealviewDetailsComponent extends DealsBaseComponent {
  @Select(DealsState.getSelectedDeal) selectedDeal$: Observable<Deals>;
  selectedDeal: Deals;
  constructor(
    store: Store,
    router: Router,
    shared: SharedService,
    modal: ModalService,
    public analyticsHandler: AnalyticsHandler,
  ) {
    super(shared, store, modal, router, analyticsHandler);
    let cart = store.selectSnapshot(CartState.getCart);    
  }
  @Input() _modalClose: any;
  @Input() deal: any;
  @Output() closeEvent = new EventEmitter();
  imageBase = environment.imagebaseurl;
  close = environment.image_basepath + environment.images.desktop_modal_close;
  dataPassedToModal: any;

  ngOnInit() {
    super.ngOnInit();
    if (this.deal) this.dataPassedToModal = this.deal;
  }

  set modalClose(modalRef: any) {
    this._modalClose = () => {
      this.modal.closeModal(modalRef);
    };
  }

  set data(data: any) {
    this.dataPassedToModal = data.deal;
  }

  closePopup() {
    if (this._modalClose) this._modalClose();
  }

  copyPromoCode(deal) {
    const auth = this.store.selectSnapshot(AuthState);
    this.trackAnalyticsDealsApply(deal);
    if (auth?.loggedInUser?.isLoggedIn) {
      this.selectDeal(deal);
      this.closePopup();
      this.closeEvent.emit();
    }else{
      if(!this.isMobileView){
        this.modal.showMediumModalDesktop(LoginDesktopComponent);
      }else{
        this.continue();
      }
    }  
  }

  trackAnalyticsDealsApply(deal){
    console.log("deal", deal, ",")
    if(deal){

      const dealSelectionAnalytics: DealSelectionAnalytics = {
        _id: deal?._id,
        name: deal?.title,
        analyticsTrackingType: AnalyticsTrackingType.DEAL_APPLY,
        discountAmount:deal?.details[0],
        discount:deal?.promoCode,
      };
      console.log("dealSelectionAnalytics:", dealSelectionAnalytics)
    this.store.dispatch(new setAnalyticsData(dealSelectionAnalytics));}
  }

  removePromoCode() {
    this.removeDeal();
    this.closeEvent.emit();
    this.closePopup();
  }
}

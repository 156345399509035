import { Component, OnInit,Input } from '@angular/core';
import { ClearPaymentDetails, PaymentMethodState } from '@moduurnv2/libs-orderingapp/src';
// import { Router } from '@angular/router';
// import { MessagingService } from '@moduurnv2/web/core';
// import { SharedService } from '@moduurnv2/libs-orderingapp/src/core';
import { ReviewPayBaseComponent } from '@moduurnv2/libs-orderingapp/src/features';

@Component({
  selector: 'moduurnv2-review-and-pay-desktop',
  templateUrl: './review-and-pay-desktop.component.html',
  styleUrls: ['./review-and-pay-desktop.component.scss'],
})
export class ReviewAndPayDesktopComponent extends ReviewPayBaseComponent  implements OnInit {
    // @Select(LocationState.getSelectedLocation) location$: Observable<Location>;
  //   selectedLocation;
  //   constructor(
  //     public store: Store,
  //     public router: Router,
  //     public sweetAlert: SweetAlertService,
  //     public messagingService: MessagingService,
  //      public spinner: NgxSpinnerService
  //   ) {
  //     super(store, router, sweetAlert,spinner);
  //     this.selectedLocation = this.store.selectSnapshot(
  //       LocationState.getSelectedLocation
  //     );
  //   }
  // selectLocation(location) {
  //   this.store.dispatch(new SetSelectedLocation(location._id));
  // }

  ngOnInit(): void {
    super.ngOnInit();
    this.ClearAckrooPaymentDetails();
    this.ClearMealPaymentDetails();
    this.dealActions();
    this.store.dispatch(new ClearPaymentDetails());
  }

  isAckrooPayment()
  {
    if(this.ackrooPaymentDetails && this.ackrooPaymentDetails.amountToPay)
    {
      if(!(this.ackrooPaymentDetails['isAllAmountPayedFromGiftCard']))
      {
        if(!this.selectedPayment)
          return false;
        if(this.selectedPayment != "Credit Card")
          return false;
      }
      return true;
    }
    return false;
  }

  isMealPayment() {
    const mealPaymentDetails = this.store.selectSnapshot(PaymentMethodState.getMealPaymentDetails);
    if(mealPaymentDetails) {
      return true;
    }
    return false;
  }

  get isPayLocation() {
    const paymentDetails = this.store.selectSnapshot(PaymentMethodState.getSelectedPaymentType);
    return paymentDetails === 'payLater';
  }
}

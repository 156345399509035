<div class="button-dropdown-container" tabindex="0" (blur)="closeDropDown()">
  <div
    class="button-dropdown-btn"
    (click)="toggleDropDown()"
    [ngClass]="{ 'button-border': isBorderVisible ,'if-selected-location':seletedLocationId}"
  >
    <img
      *ngIf="buttonImage; else letterImage"
      class="item-image"
      [src]="buttonImage"
      [ngStyle]="{ height: imageHeight }"
      alt="button"
    />
    <ng-template #letterImage>
      <div class="letterImage" *ngIf="letterForImage">{{ letterForImage }}</div>
    </ng-template>
    <label class="item-text"> {{ buttonText }}</label>
  </div>

  <div
    class="button-dropdown"
    *ngIf="isDropDownVisible"
    (mouseleave)="closeDropDown()"
    
  >
    <ng-container *ngFor="let item of items">
      <div *ngIf="(item.text=='Gift/Loyalty Card' && enabledGiftandLoyality)|| item.text!='Gift/Loyalty Card'"
        class="item"
        (click)="clickItem(item)"
        [ngClass]="{ 'hover-effect': isHoverEffect }"
      >      
        <img
          [src]="item.image"
          class="item-image"
          *ngIf="item.image"
          [ngClass]="{ 'image-status': item.imageActive }"
          alt="item"
        />
        <img
          [src]="item.imageActive"
          class="item-image-active"
          *ngIf="item.imageActive"
          alt="item"
        />
        <label class="item-text"> {{ item.text | translate }}</label>
      </div>
    </ng-container>
  </div>
</div>

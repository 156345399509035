import {
  BaseComponent,
  ClearAllVenueDatas,
  environment,
  FetchLocationDefaults,
  SetPaymentMethod,
} from '@moduurnv2/libs-orderingapp/src/core';
import { OnInit, Input } from '@angular/core';
import {
  SetSelectedLocation,
  ClearCart,
  WhitelabelState,
  CartState,
  Cart,
  LocationState,
  ClearMenuList,
  ClearFutureDate,
  ClearQRScannedDetails,
  AnalyticsTrackingType,
  LocationSelectionAnalytics, 
  setAnalyticsData
} from '@moduurnv2/libs-orderingapp/src/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SweetAlertService } from '@moduurnv2/libs-orderingapp/src/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { RoomUpdateSelectionComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/venue-management/components/room-update-selection/room-update-selection.component';
export class LocationBaseComponent extends BaseComponent {
  constructor(
    public router: Router,
    public store: Store,
    public sweetAlert: SweetAlertService,
    public translate: TranslateService,
    public modal:ModalService
  ) {
    super();
  }
  @Input() location;
  _modalClose: any = null;
  // showLocationInfoModal: boolean = false;
  imgBasePath: string = environment.image_basepath;
  icon = {
    orderHere: this.imgBasePath + environment.images.order_here,
    orderHereWhite: this.imgBasePath + environment.images.order_here_white,
    favoriteIcon: this.imgBasePath + environment.images.favorite_icon,
    nonFavoriteIcon: this.imgBasePath + environment.images.nonFavorite_icon,
    orderForLater: this.imgBasePath + environment.images.order_for_later,
    orderForLaterInactive:
      this.imgBasePath + environment.images.order_for_later_inactive,
    phone: this.imgBasePath + environment.images.phone,
  };
  unitOfDistance: string = 'miles';
  ngOnInit(): void {
    let whiteLabel = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
    this.unitOfDistance = whiteLabel.distance;
  }
  toggleLocationFavorite(locId, isFavorite) {
    //ADD API TO MAKE LOCATION FAVORITE OR NOT
  }
  async orderFromOrganization(location, data?) {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if (location.isLocationOpen) {
      if (selectedLocation && selectedLocation._id != location._id) {
        let selectedCart = this.store.selectSnapshot(CartState.getCart);
        if (selectedCart) {
          let vm = this;
          await this.sweetAlert
            .confirm(
              this.translate.instant('are-you-sure'),
              this.translate.instant('loc-change-clear-cart')
            )
            .then((choice) => {
              if (choice) {
                this.actionOnLocationSelection(location,data);
                this.store.dispatch(new ClearCart());
                this.store.dispatch(new ClearAllVenueDatas());
                this.store.dispatch(new SetPaymentMethod({selectedPayment: null, selectedPaymentType: null}));
              } else {
                return;
              }
            });
        } else {
          this.store.dispatch(new SetPaymentMethod({selectedPayment: null, selectedPaymentType: null}));
          this.actionOnLocationSelection(location,data);
          this.store.dispatch(new ClearAllVenueDatas());
        }
      } else {
        this.actionOnLocationSelection(location,data);
        this.store.dispatch(new ClearCart());
      }
    }
    if(!data?.isSelectedClassroomDelivery){
      this.store.dispatch(new ClearFutureDate());
    }
  }

  actionOnLocationSelection(location,data){
    this.closePopup();
    this.store.dispatch(new ClearQRScannedDetails());
    this.store.dispatch(new SetSelectedLocation(location._id));
    this.store.dispatch(new FetchLocationDefaults(data));
    this.store.dispatch(new ClearMenuList());
    this.trackAnalyticsLocation(location, data);
    if(data?.isSelectedClassroomDelivery){
      this.showVenueRoomDeliveryPopup(location)
    } else {
    this.router.navigate([`locations/${location._id}/home`]);
    }
  }

  trackAnalyticsLocation(location, extraDetails){
    if(location){
      const locationSelectionAnalytics: LocationSelectionAnalytics = { 
        _id: location?._id, 
        name: location?.name,
        analyticsTrackingType: AnalyticsTrackingType.LOCATION_SELECTION,
      };
    this.store.dispatch(new setAnalyticsData(locationSelectionAnalytics));}
  }

  showVenueRoomDeliveryPopup(location) {
    setTimeout(() => 
    this.modal.showMediumModalDesktop(RoomUpdateSelectionComponent, {
      whenClosed: (data) => {
      if (data) {
        this.router.navigate([`locations/${location._id}/home`]);
      }
    }})
    , 1000);
  }

  closePopup() {
    if (this._modalClose) this._modalClose();
  }

  set modalClose(modalRef: any) {
    this._modalClose = (data) => {
      this.modal.closeComponentModal(modalRef, data);
    };
  }
}
<div class="modal-content">
  <div class="header">
    <span class="close" (click)="closeDetailModalOnly($event)">
      <img *ngIf = "listNotEmpty" src="assets/images/back-arrow.svg" alt="back"/>
    </span>
    <span class="header-text">{{'enter-address'| translate}}</span>
    <img
          [src]="icon.close"
          data-qa="address-modal-close-button"
          class="close-button"
          aria-label="address-modal-close"
          alt="close"
          (click)="closePage()"
        />
  </div>
  <div class="row adrs-details-container">
    <div class="adrs-card">
      <div class="complete-adrs">
        <span>{{ 'completeadrs' | translate }}</span
          >
          <!-- <span class="required"> *</span> -->
          <div>
            <input
              type="text"
              [(ngModel)]="houseNo"
              class="addressdetails"
              placeholder="{{ 'house-details' | translate }}"
              />
              <!-- (ngModelChange)="adrsvalidate(this.houseNo)" -->
          </div>
          <hr class="border" />
          <span class="required" *ngIf="!isaddresDetails"
            >Please enter complete address</span
          >
      </div>
      <div class="complete-adrs address-search">
        <!-- <span>Search your address</span> -->
        <div id="geocoderSearch"></div>
        <!-- <pre id="geocoderResult"></pre> -->
      </div>
      <div class="complete-adrs">
        <span>{{ 'streetAddress_c' | translate }}</span
          >
          <span class="required"> *</span>
          <div>
            <input
              type="text"
              [(ngModel)]="streetAddress"
              class="addressdetails"
              placeholder="{{ 'streetAddress_s' | translate }}"
              (ngModelChange)="validateStreet(this.streetAddress)"
              />
              <!-- (ngModelChange)="adrsvalidate(this.houseNo)" -->
          </div>
          <hr class="border" />
          <span class="required" *ngIf="!isValidStreet"
          >Please enter street</span
        >
      </div>
      <div class="complete-adrs">
        <span>{{ 'city_c' | translate }}</span
          >
          <span class="required"> *</span>
          <div>
            <input
              type="text"
              [(ngModel)]="city"
              class="addressdetails"
              placeholder="{{ 'city_s' | translate }}"
              (ngModelChange)="validateCity(this.city)"
              />
          </div>
          <hr class="border" />
          <span class="required" *ngIf="!isValidCity"
          >Please enter city</span
        >
      </div>

      <div class="complete-adrs">
        <span>{{ 'province_c' | translate }}</span>
          <span class="required"> *</span>
          <div>
            <select class="md-input countryProvince" [(ngModel)]="selectedState">
              <option *ngFor="let state of states">{{ state.n }}</option>
            </select>
          </div>
          <hr class="border" />
          <span class="required" *ngIf="!isaddresDetails"
            >Please enter complete address</span
          >
      </div>
      <div class="complete-adrs">
        <div>
          <span *ngIf="country == 'United States'">{{
            'zipcode' | translate
          }}</span>
          <span *ngIf="country != 'United States'">{{
            'postalcode' | translate
          }}</span
          ><span class="required"> *</span>
        </div>
        <input
          type="text"
          class="addressdetails"
          placeholder="{{ 'zipcode_s' | translate }}"
          [(ngModel)]="zipCode"
          (ngModelChange)="validate(this.zipCode)"
        />
        <hr class="border" />
        <span class="required" *ngIf="!isValid && country != 'United States'">{{
          'postalcodeerror' | translate
        }}</span>
        <span class="required" *ngIf="!isValid && country == 'United States'">{{
          'zipcodeerror' | translate
        }}</span>
      </div>
      <div class="complete-adrs">
        <span>{{ 'country_c' | translate }}</span>  <span class="required"> *</span>
          <div>
            <select class="md-input countryProvince"  (ngModelChange)="fetchStates($event)" [(ngModel)]="selectedCountry">
              <option *ngFor="let country of countries">{{ country.code }}</option>
            </select>
          </div>
          <hr class="border" />
          <span class="required" *ngIf="!isaddresDetails"
            >Please enter complete address</span
          >
      </div>
      <div class="complete-adrs">
        <span>{{ 'buzzerNumber_c' | translate }}</span
          >
          <!-- <span class="required"> *</span> -->
          <div>
              <input
              type="text"
              [(ngModel)]="buzzerNo"
              class="addressdetails"
              placeholder="{{ 'buzzerNumber_s' | translate }}"
              />
              <!-- (ngModelChange)="adrsvalidate(this.houseNo)" -->
          </div>
      </div>
      <!-- <div class="complete-adrs">
        <span>{{ 'curr-loc' | translate }}</span>
      </div>
      <div class="current-adrs">
        <div class="firstcolumn">
          <span class="adrs"> {{ deliveryAddress }}</span>
        </div>

        <div class="secondcolumn"></div>
      </div> -->
      <hr class="border" />
      <div class="complete-adrs">
        <span>{{ 'delivery-instrctn' | translate }}</span>
        <input
          type="text"
          class="addressdetails"
          [(ngModel)]="instructions"
          placeholder="{{ 'special-instrctn' | translate }}"
        />
        <hr class="border" />
        <!-- <div class="instrctn-text">{{ 'Eg-instruction' | translate }}</div> -->
        <div class="labeltext">
          {{ 'taglocation' | translate }}
        </div>
        <div class="btn-grp" [ngClass]="{ btnselected: adrsType == 3 }">
          <button
            class="btn"
            *ngIf="adrsType != 3"
            [ngClass]="{ selected: adrsType == 1 }"
            (click)="selectAdrsType(1)"
          >
            {{ 'Home' | translate }}
          </button>
          <button
            class="btn"
            *ngIf="adrsType != 3"
            [ngClass]="{ selected: adrsType == 2 }"
            (click)="selectAdrsType(2)"
          >
            {{ 'Office' | translate }}
          </button>
          <button
            class="btn"
            [ngClass]="{ selected: adrsType == 3 }"
            (click)="selectAdrsType(3)"
          >
            {{ 'Other' | translate }}
          </button>
        </div>
        <div
          class="adrs-type"
          [ngClass]="{ adrstypeactive: adrsType == 3 }"
          *ngIf="adrsType == 3"
        >
          <input
            type="text"
            class="othertype"
            placeholder="{{ 'save-as' | translate }}"
            [(ngModel)]="otherAdrsType"
            (ngModelChange)="adrstagvalidate(this.otherAdrsType)"
          />
          <hr class="border-adrs-type" />
          <span class="required" *ngIf="!isAdrsTagValid">{{
            'addresstageerror' | translate
          }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="btn-save">
    <button
      [disabled]="!isValid || !isValidCity || !isValidStreet || zipCode =='' || selectedState=='Select Province'"
      class="save"
      (click)="saveAddress(saveAddressBtn)"
      #saveAddressBtn
      id="save-address-submit"
      [ngClass]="{
        btnactive:
          isValid &&
          city &&
          streetAddress &&
          isaddresDetails &&
          (zipCode != '' && zipCode!=null) &&
          isAdrsTagValid &&
           selectedState !='Select Province'
      }"
    >
      {{ 'saveadrses' | translate }}
    </button>
  </div>
</div>

// angular
import { Injectable } from '@angular/core';

// app
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Store } from '@ngxs/store';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';

@Injectable({
  providedIn: 'root',
})
export class MenuListService {
  constructor(private http: HttpClient, private store: Store) {
  }

  fetchMenuList() {
    const selectedDelivery = this.store.selectSnapshot(
      (app) => app && app.delivery && app.delivery.selectedDelivery
    );
    const { _id: locationId } = this.store.selectSnapshot(
      (app) => app.location.selectedLocation
    );
    let url = `${environment.mobileStoreApiv3Url}stores/${locationId}/menu`;

    if (selectedDelivery && selectedDelivery.textCode)
      url += `?delivery=${selectedDelivery.textCode}`;

    let deliveryLocation = (localStorage.getItem('selectedAdrsLocation') && localStorage.getItem('selectedAdrsLocation') != "undefined")
      ? JSON.parse(localStorage.getItem('selectedAdrsLocation'))
      : undefined;
    if (deliveryLocation && selectedDelivery && selectedDelivery.textCode == 'DELIVERYTYPE01')
      url += `&lat=${deliveryLocation['loc'][1]}&lng=${deliveryLocation['loc'][0]}`;

    const futureTime = this.store.selectSnapshot(
      (app) => app.cart && app.cart.futureDate
    );
    if (futureTime) {
      const selectedTime = new Date(futureTime).toISOString();
      url += `&selectedTime=${selectedTime}`;
    }
    url+= `&organizationId=${Config.organizationId}`
    return this.http.get(url);
  }

  fetchCombo(comboId) {
    // const url = 'https://q2wso2.moduurn.ca/menuCombosApi/v1/comboModule/5eb29f46f87285639170acfe/groups?locationId=5ec556375cb9bf477ab5788a&menuProfileId=5eb29f42f87285639170aa18';
    const { _id: locationId,activeProfile :activeProfile } = this.store.selectSnapshot(
      (app) => app.location.selectedLocation
    );
    let url = `${environment.menuCombosApiUrl}comboModule/${comboId}/groups?locationId=${locationId}`;
    url += activeProfile?._id ? `&menuProfileId=${activeProfile._id}` : ''; // activeProfile to be passed to filter out unavailable items from combos
    return this.http.get(url);
  }

}

import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpRequest, HttpHandler,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { LogService } from './log.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(private logService: LogService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      // retry(1),
      // catchError((error: HttpErrorResponse) => {
      //   if (error.status === 401) {
      //     // refresh tokens
      //   } else {
      //     this.logService.error(error.message, {}, 'UNHANDLED_SERVER');
      //     return new Observable<HttpEvent<any>>();
      //   }
      // })
    );
  }
}
<div style="text-align: center;">
  <!-- <h2>{{ 'welcome' | translate }}</h2> -->
  <button (click)="changeLang('en')" class="language-button">English</button>
  <button (click)="changeLang('fr')" class="language-button">Français</button>
  <button (click)="changeLang('es')" class="language-button">Español</button>
</div>
<style>
  .language-button {
    margin-right: 1em;
    display: inline-block;
    padding: 0.7em 1.7em;
    margin: 0 0.3em 0.3em 0;
    border-radius: 0.2em;
    box-sizing: border-box;
    text-decoration: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    background-color: #cccccc;
    color: #000000;
    border: none;
    box-shadow: inset 0 -0.6em 1em -0.35em rgba(0, 0, 0, 0.17),
      inset 0 0.6em 2em -0.3em rgba(255, 255, 255, 0.15),
      inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
    text-align: center;
    position: relative;
  }
  .language-button:active {
    box-shadow: inset 0 0.6em 2em -0.3em rgba(0, 0, 0, 0.15),
      inset 0 0 0em 0.05em rgba(255, 255, 255, 0.12);
    border: none;
  }
</style>

const baseurl = 'https://b1wso2.moduurn.ca/';
// const imagebaseurl = 'https://s3-us-west-2.amazonaws.com/betamodimages/';
const imagebaseurl = 'https://images.moduurn.com/';
const locationApiUrl = `${baseurl}locationapi/v3/`;

import { common, apigenerator } from './environment.common';

const urls = apigenerator(baseurl);

export const environment = {
  production: true,
  ...urls,
  ...common,
  imagebaseurl: imagebaseurl,
  locationApiUrl: locationApiUrl,
  federatedApi: baseurl + 'federatedapi/v1/',
  wso2URL: 'https://sso.moduurn.ca',
  azureSSO: {
    // clientId: '35hg3QmJ1h8sLJfDhWQRDS5fHwAa',
    clientId: 'a223JamNMD47cuXgitQjcZTepfUa',
    // secret: 'NPz8vKDmJumsBlXpfbvdiT_Ovfga'
    secret: 'K3Ly3xf6Nf5g6TWPY03ieNSZaCoa',
  },
};

// angular
import { Injectable } from '@angular/core';

// app
import { HttpClient } from '@angular/common/http';
import { environment } from '../../core/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { SetWhitelabel, SetOther, SetLandingPage,ClearSavedCards, SetAppVersion } from '../actions';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  constructor(private http: HttpClient, private store: Store, private router: Router) {}

  load(organizationId: string, appVersionNumber:string): Observable<any> {
    const url = `${environment.organizationApiUrl}organizations/${organizationId}/configuration?appVersion=${appVersionNumber}`;
    return this.http.get(url).pipe(      
        tap( response => {
          const { whiteLabel , other, landingPage, appVersion } = response;
          const deliveryMethods = response.deliveryMethods;
          if(whiteLabel) {
            if(other){
              whiteLabel["currency"] = other.currencyType.text;
              whiteLabel["distance"] = other.distanceType.text;
              whiteLabel["deliveryMethods"] = deliveryMethods ? deliveryMethods : undefined;
              if (other.language) {
                whiteLabel['language'] = other.language.textCode;
              }
              else {
                whiteLabel['language'] = undefined;
              }
              if(other.paymentGateway && (other.paymentGateway.textCode == 'PAYMENTGATEWAY04' || other.paymentGateway.textCode == 'PAYMENTGATEWAY05')){
                this.store.dispatch(new ClearSavedCards());
              }
            }
            if(response.feedbackEmailAddress){
              whiteLabel['feedbackEmailAddress'] = response.feedbackEmailAddress;
            }
            this.store.dispatch(new SetWhitelabel(whiteLabel));
            this.store.dispatch(new SetOther(other));
            this.store.dispatch(new SetLandingPage(landingPage));
            
          }
          if(Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios'){
            const updatePageShown = sessionStorage.getItem('showedUpdatePage');
            if(appVersion && (appVersion.isForceUpdate || (appVersion.isRecommendUpdate && !updatePageShown))){
              this.store.dispatch(new SetAppVersion(appVersion));
              this.router.navigate(['update']);
            }
          }
        
      })
    );
  }
}

import { Discount } from '../models';

export class SetDiscounts {
  static readonly type = '[DISCOUNTS] SetList';

  constructor(public payload: Discount[]) {}
}

export class SetSelectedDiscount {
  static readonly type = '[DISCOUNTS] SetDiscount';

  constructor(public payload: Discount) {}
}

export class FetchDiscountsList {
  static readonly type = '[DISCOUNTS] FetchDiscounts';

  constructor(public payload?: string) {}
}

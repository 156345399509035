import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WhitelabelState } from '@moduurnv2/libs-orderingapp/src';
import { Select, Store } from '@ngxs/store';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'moduurnv2-force-update',
  templateUrl: './force-update.component.html',
  styleUrls: ['./force-update.component.scss']
})
export class ForceUpdateComponent implements OnInit {
  @Select(WhitelabelState.getAppVersion) appVersion$;

  constructor(private store: Store, private router: Router) {
  }
  ngOnInit(): void {
    sessionStorage.setItem('showedUpdatePage','true');
  }
  
  navgateToStore(){
    const whiteLabel = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
    if(whiteLabel){
      if (Capacitor.getPlatform() === 'ios' && whiteLabel.appstoreId) {
        window.open(whiteLabel.appstoreId, '_system', 'location=yes');
      } else if (Capacitor.getPlatform() === 'android' && whiteLabel.playstoreId) {
        window.open(whiteLabel.playstoreId, '_system', 'location=yes');
      }
    }
  }

  continue(){
    this.router.navigate(['locations']);
  }
}

import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Selector, State, StateContext, Action } from '@ngxs/store';
import { GetYepdeskEventAction } from '@moduurnv2/libs-orderingapp/src/core/actions';
import { YepdeskApiService } from '@moduurnv2/libs-orderingapp/src/core/services';


export class EventStateModel {
    events: any[];
  }

  @State<EventStateModel>({
    name: 'events',
    defaults: {
      events: [],
    },
  })

  @Injectable()
export class YepdeskEventState {
  eventData: any;

  constructor(private yepdeskEventService: YepdeskApiService) {}

  @Selector()
  static getEvents(state: EventStateModel) {
    return state.events;
  }



  @Action(GetYepdeskEventAction)
  getYepdeskEvents({patchState }: StateContext<EventStateModel>) {
    return this.yepdeskEventService.getAllEvents().pipe(
      tap((result) => {
        console.log(result['result']['content'])
        this.eventData = result;

        patchState({
          events: this.eventData['result'] &&  this.eventData['result']['content']  ? this.eventData['result']['content'] : []
        });
      })
    );
  }
}
import { Component } from '@angular/core';
import { AddressCardBaseComponent,AddressComponentEnums } from '@moduurnv2/libs-orderingapp';
import {AddressWrapperComponent} from './../../../address-wrapper/address-wrapper.component';
import {AuthState,AddressState,ChangeTempToDeliveryAddress,ClearEnteredAddress} from '@moduurnv2/libs-orderingapp/src/core';
@Component({
  selector: 'moduurnv2-address-card-desktop',
  templateUrl: './address-card.component-desktop.html',
  styleUrls: ['./address-card.component-desktop.scss'],
})
export class AddressCardDesktopComponent extends AddressCardBaseComponent {


  //DONT MOVE THIS TO BASE( CIRCULAR DEPENDENCY CAN OCCUR)
  editAdrs(event,_id) {
    if(event){
    event.stopPropagation();
    event.preventDefault();
    }
    this.store.dispatch(new ClearEnteredAddress());
    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    if (customer && customer.isLoggedIn) {
      let locations = this.store.selectSnapshot(AddressState.getAddressList);
      let location = locations.find((adrs) => adrs._id == _id);
      if(location)
      localStorage.setItem(
        'tempSelectedAdrsLocation',
        JSON.stringify(location)
      );
    } else {
      let locations = JSON.parse(localStorage.getItem('locationList'));
      // let locations = this.store.selectSnapshot(AddressState.getAddressListFromLocal);
      let location = locations.find((adrs) => adrs._id == _id);
      if(location){
      localStorage.setItem(
        'tempSelectedAdrsLocation',
        JSON.stringify(location)
      );
      this.store.dispatch(new ChangeTempToDeliveryAddress(location));
      }
    }
    if(this.isAddressBook){
      this.modal.showMediumModalDesktop(AddressWrapperComponent,{data:{isAddressBook:true,pageToShow:AddressComponentEnums.ADDRESS_DETAILS}})
    } else {
      this.openDetails();
    }
  }
}

import { Component, OnInit } from '@angular/core';
import {
  VenuePickupLocationTimeSelectionBaseComponent
} from '@moduurnv2/libs-orderingapp/src/features/ui/base';
import { TranslateService } from '@ngx-translate/core';
import { Store} from '@ngxs/store';
import {ModalService} from './../../../shared/modal/modal.service';
@Component({
  selector: 'moduurnv2-pickup-location-time-selection',
  templateUrl: './pickup-location-time-selection.component.html',
  styleUrls: ['./pickup-location-time-selection.component.scss','./../../common-styles/venue-selection.scss']
})
export class PickupLocationTimeSelectionComponent extends VenuePickupLocationTimeSelectionBaseComponent implements OnInit {
  hideTimeSelection:boolean = true;
}

import { OnInit, Output } from '@angular/core';
import {
  BaseComponent,
  PaymentMethodState,
  SetPaymentMethod,
  NewCard,
  TokenizeCard,
  SetNewCard,
  LocationState,
  TokenizeProfile,
  AuthEmailState,
  CartState,
  WhitelabelState,
  LocationDefaultState,
} from '@moduurnv2/libs-orderingapp/src/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from '@angular/forms';

import { Router,NavigationExtras } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { paymentCardType, paymentMethods } from '../../../core/services';
import { EventEmitter } from '@angular/core';
import { SharedService } from '@moduurnv2/libs-orderingapp/src/core';
import { takeUntil } from 'rxjs/operators';
export class AddPaymentCardBaseComponent extends BaseComponent
  implements OnInit {
  @Output() addCardDesktopView = new EventEmitter();
  numbers = new RegExp(/^[0-9]*$/);
  isMobileView: boolean = true;
  cardDetailsForm: FormGroup;
  newCard: NewCard;
  paymentMethods = paymentMethods;
  saveToAccount;
  cardType;
  user;
  paymentGateway;
  locationDefaults;
  isStreetNameValidationRequired : boolean= false ;
  constructor(
    public fb: FormBuilder,
    public store: Store,
    public router: Router,
    public shared: SharedService
  ) {
    super();
  }

  ngOnInit() {
    let other = this.store.selectSnapshot(WhitelabelState.getOther);
    this.paymentGateway = other.paymentGateway;
    this.locationDefaults = this.store.selectSnapshot(
      LocationDefaultState.getLocationDefaults
    );

    this.isStreetNameValidationRequired = this.checkStreetAddressValidationRequired();
    
    this.cardDetailsForm = this.fb.group({
      name: ['', [Validators.required, Validators.pattern(/^[A-Za-z- ]+$/)]],
      number: ['', this.cardNumberValidator],
      expiry: ['', this.dateValidator],
      cvd: [
        '',
        [
          Validators.required,
          Validators.minLength(this.cardType ? this.cardType.cvvLength : 3),
        ],
      ],
      address: [
        '',
        (this.paymentGateway.textCode == 'PAYMENTGATEWAY04' || ( this.paymentGateway.textCode == 'PAYMENTGATEWAY02' &&  this.isStreetNameValidationRequired))
          ? [Validators.required]
          : [],
      ],
      zipcode: [
        '',
       ( this.paymentGateway.textCode == 'PAYMENTGATEWAY04' || this.paymentGateway.textCode == 'PAYMENTGATEWAY02' || this.paymentGateway.textCode == 'PAYMENTGATEWAY05')
          // ? [Validators.required, Validators.pattern(/^[A-Za-z0-9 ]+$/)]
          ? this.zipCodeValidator
          : [],
      ],
    });
     this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
    this.user = this.store.selectSnapshot(AuthEmailState.getUserDetails);
  }

  formatDate(event) {
    let expiryDate = event.target['value'];
    let formattedDate = '';
    let cardDate = '';
    let numbersPattern = new RegExp(/^[0-9/]*$/);

    setTimeout(() => {
      expiryDate = event.target['value'];
      if (numbersPattern.test(expiryDate)) {
        for (let pos = 0; pos < expiryDate.length; pos++) {
          if (pos == 0) {
            cardDate = expiryDate.charAt(pos);
            // }else if(pos == 2 && expiryDate.charAt(pos)!='/'){
          } else if (pos == 2 && !expiryDate.includes('/')) {
            cardDate = cardDate + '/' + expiryDate.charAt(pos);
          } else {
            cardDate = cardDate + expiryDate.charAt(pos);
          }
        }
        formattedDate = cardDate;
        return (event.target.value = formattedDate);
      } else {
        return (event.target.value = '');
      }
    });
  }

  dateValidator(control: AbstractControl): { [key: string]: boolean } | null {
    let currentDate = '';
    let today = new Date();
    currentDate = today.getMonth() + 1 < 10 ? '0' : '';
    let currentMM = currentDate + (today.getMonth() + 1);
    let currentYY = today.getFullYear();
    let expiryDatePattern = new RegExp(/^[\d]{2}\/[\d]{4}/);

    if (control.value !== '' && expiryDatePattern.test(control.value)) {
      let splitDate = control.value.split('/');
      if (
        splitDate[1] >= currentYY &&
        Number(splitDate[1]) <= Number(currentYY + 50)
      ) {
        if (splitDate[1] == currentYY && splitDate[0] < currentMM) {
          return { dateValidator: false };
        } else if (Number(splitDate[0]) > 12) {
          return { dateValidator: false };
        } else {
          return null;
        }
      } else {
        return { dateValidator: false };
      }
    } else {
      return { dateValidator: false };
    }
    return null;
  }

  checkFormat(event) {
    if (!this.numbers.test(event.target.value)) {
      return (event.target.value = '');
    }
  }

  onSubmit(fromGiftAndLoyalty = false) {
    this.addCardDesktopView.emit(!this.isMobileView);
    let other = this.store.selectSnapshot(WhitelabelState.getOther);
    const paymentGateway = other.paymentGateway;
    if (this.cardDetailsForm.valid) {
      let expiry = this.cardDetailsForm.value.expiry.split('/');
      this.newCard = Object.assign(this.cardDetailsForm.value);
      this.newCard.expiry_month = expiry[0];
      let year = expiry[1].match(/.{1,2}/g);
      this.newCard.expiry_year = year[1];
      this.newCard.saveToAccount = this.saveToAccount;
      if (this.newCard.address && (paymentGateway.textCode == 'PAYMENTGATEWAY04' || paymentGateway.textCode == 'PAYMENTGATEWAY05')) {
        let address = this.newCard.address.replace(/[^A-Z0-9]/gi, '');
        let length = address.length;
        this.newCard.address = address.substring(0, Math.min(length, 21));
      }
      if (this.newCard.address && paymentGateway.textCode == 'PAYMENTGATEWAY02') {
          let length = this.newCard.address.length;
          this.newCard.address = this.newCard.address.substring(0, Math.min(length, 64));
      }
      else{
        this.newCard.address = '1';
      }
      if (this.newCard.zipcode) {
        let zipCode = this.newCard.zipcode;
        if (this.newCard.address && (paymentGateway.textCode == 'PAYMENTGATEWAY04' || paymentGateway.textCode == 'PAYMENTGATEWAY05')) {
          zipCode = this.newCard.zipcode.replace(/[^A-Z0-9]/gi, '');
        }
        if (zipCode.length) {
          this.newCard.address = this.newCard.address + '|' + zipCode;
        }
      }
      this.newCard.card_type = this.verifyCardType(this.newCard.number).type;
      this.store.dispatch(new SetNewCard(this.newCard));
      let card = JSON.parse(JSON.stringify(this.newCard));
      card.number = card.number.replace(/\s/g, '');
     
      if (paymentGateway.textCode == 'PAYMENTGATEWAY02') {
        this.store.dispatch(new TokenizeCard(card));
        if (this.saveToAccount) {
          this.store.dispatch(new TokenizeProfile(card));
        }
      }

      let paymentMethod = this.paymentMethods.find((method) => method.id == 3);
      this.store.dispatch(new SetPaymentMethod(paymentMethod));
      if (!fromGiftAndLoyalty) {
        const selectedLocation = this.store.selectSnapshot(
          LocationState.getSelectedLocation
        );
        if (selectedLocation._id) {
          let navigationExtras: NavigationExtras = {
            queryParams: {
                "paymentId": 3
            }
          };
          this.router.navigate([`/locations/${selectedLocation._id}/order`],navigationExtras);
        }
      }
    }
  }
  verifyCardType(number) {
    // visa
    number = number.split(' ').join('');
    let re = new RegExp(paymentCardType.visa.regex);
    if (number.match(re) != null) {
      return paymentCardType.visa;
    }

    // Mastercard
    re = new RegExp(paymentCardType.mastercard.regex);
    if (number.match(re) != null) {
      return paymentCardType.mastercard;
    }

    // AMEX
    re = new RegExp(paymentCardType.amex.regex);
    if (number.match(re) != null) {
      return paymentCardType.amex;
    }

    // Discover
    re = new RegExp(paymentCardType.discover.regex);
    if (number.match(re) != null) {
      return paymentCardType.discover;
    }
    return null;
  }
  clearvalues() {
    this.cardDetailsForm.controls['cvd'].setValue('');
  }
  cardNumberValidator = (
    control: AbstractControl
  ): { [key: string]: boolean } | null => {
    if (control.value !== '') {
      this.cardType = this.verifyCardType(control.value);
      if (this.cardType) {
        if (!/\s/.test(control.value)) {
          if (control.value.length === this.cardType.minlength - 3) {
            return null;
          } else {
            return { cardNumberValidator: false };
          }
        } else if (control.value.length === this.cardType.minlength) {
          return null;
        } else {
          return { cardNumberValidator: false };
        }
      }
    }
    return null;
  };

  zipCodeValidator = (
    control: AbstractControl
  ): { [key: string]: boolean } | null => {
    if (control.value !== '') {
      if (/\s/.test(control.value)) {
        if (control.value.length === 7) {
          return null;
        } else {
          return { zipCodeValidator: false };
        }
      } else {
        if (control.value.length === 4 || control.value.length > 4) {
          return null;
        } else {
          return { zipCodeValidator: false };
        }
      }
    }
    return null;
  };

  checkStreetAddressValidationRequired(){
    if(this.locationDefaults && this.locationDefaults.locationData && this.locationDefaults.locationData.orderSettings &&this.locationDefaults.locationData.orderSettings.paymentModes && this.locationDefaults.locationData.orderSettings.paymentModes.isStreetNameValidationRequired){
      return true;
    }
    else{
      return false;
    }
  }
}

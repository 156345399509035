import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent, environment, UpdateCurbsideNotification,LocationDefaultState,LocationData } from '@moduurnv2/libs-orderingapp/src';
import { Store } from '@ngxs/store';
import { ModalService } from '../modal/modal.service';

@Component({
  selector: 'moduurnv2-curbside-notification',
  templateUrl: './curbside-notification.component.html',
  styleUrls: ['./curbside-notification.component.scss'],
})
export class CurbsideNotificationComponent extends BaseComponent implements OnInit {
    notificationMessage: string = '';
    pickupSpot:string = '';
    submitted:Boolean = false;
    selectedLocationDefaults:LocationData;
    @Input() data: any = null;
    @Input() _modalClose: any;
    orderId: string;
    backButton: string = environment.image_basepath + environment.images.arrow_left_icon;
    closeButton: string = environment.image_basepath + environment.images.close_update_modal;

    constructor(private store: Store, private modal: ModalService) {
        super();
    }

    ngOnInit() {
        if (this.data) this.loadInitialData(this.data);
        const locationDefaultsDetails = this.store.selectSnapshot(LocationDefaultState.getLocationDefaults);
        this.selectedLocationDefaults = locationDefaultsDetails.locationData;

    }

    loadInitialData(data: any) {
        const { orderId } = data;
        this.orderId = orderId;
    }

    set modalClose(modalRef: any) {
        this._modalClose = (data) => {
          this.modal.closeComponentModal(modalRef, data);
        };
    }

    closePopup() {
        if (this._modalClose) this._modalClose();
    }

    notifyLocation(){
      this.submitted=true;
      if(!this.orderId || (this.notificationMessage.trim()=='' && this.submitted==true))
        return;
      if(this.selectedLocationDefaults?.other?.curbsideDeliveryPointEntryEnable && this.pickupSpot.trim()=='')
        return;
        const notification  = {
          orderId : this.orderId,
          message : this.notificationMessage,
          pickupSpot : this.pickupSpot ? this.pickupSpot : undefined
        };
        this.store.dispatch(new UpdateCurbsideNotification(notification)).subscribe(data => {
          this.closeSelectionModal();
          console.log(data);
        })
        // this.orderProgress.updateNotification(notification).subscribe( response =>{
        //   const result = response as any;
        //   if(result.error){
        //     return console.error(result.error)
        //   } else {
        //     // this.fetchInProgressOrders();
        //   }
        // }, err => {
        //   console.log(err);
        // });
    }

    closeSelectionModal(data?) {
        if (this._modalClose) this._modalClose(data);
    }
 }

import { Component, OnInit } from '@angular/core';
import { AddressSelectorBaseComponent, AddressState, Address, LocationState } from '@moduurnv2/libs-orderingapp/src';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import {PickupLocationUpdateSelectionComponent} from './../../../../venue-management/components/pickup-location-update-selection/pickup-location-update-selection.component';
import {RoomUpdateSelectionComponent} from './../../../../venue-management/components/room-update-selection/room-update-selection.component';
import { AddressWrapperComponent } from '../../../../shared/address-wrapper/address-wrapper.component';

@Component({
  selector: 'moduurnv2-address-selector-desktop',
  templateUrl: './address-selector-desktop.component.html',
  styleUrls: ['./address-selector-desktop.component.scss']
})
export class AddressSelectorDesktopComponent extends AddressSelectorBaseComponent{

  openModal(){
    // this.openChangeAdrs();
    this.modal.showMediumModalDesktop(AddressWrapperComponent)
  }

  showVenuePickupPopup(){
    this.modal.showMediumModalDesktop(PickupLocationUpdateSelectionComponent)
  }

  showVenueRoomDeliveryPopup(){
    this.modal.showMediumModalDesktop(RoomUpdateSelectionComponent)
  }

}

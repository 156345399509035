import { Injectable } from '@angular/core';
import { HttpHeaders as Headers } from '@angular/common/http';
import { Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable()
export class GiftAndLoyaltyCardService {
  constructor(private http: HttpClient) {
    ////console.log('Config.organizationId', Config.organizationId);
  }

  public getCards(customerEmail, organizationId) {
    let encodedEmail = customerEmail?encodeURIComponent(customerEmail):null;
    if (encodedEmail)
    return Observable.create((observer) => {
        if (encodedEmail) {
          this.http
            .get(
              environment.paymentApiUrl +
                'payment/loyalty/' +
                organizationId +
                '/getCards/' +
                encodedEmail
            )
            .subscribe(
              (result) => {
                observer.next(result);
                observer.complete();
              },
              (error) => {
                //console.log(error);
                observer.next(error);
              }
            );
        } else observer.next();
      });
  }
  validateEmail(reqData) {
    return Observable.create((observer) => {
      this.http
        .post(environment.customerApiUrl + 'customers/validate', reqData)
        .subscribe(
          (result) => {
            observer.next(result);
            observer.complete();
          },
          (error) => {
            //console.log(error);
            observer.next(error);
          }
        );
    });
  }
    public getGivexCards(customerId,organizationId) {
       
        return Observable.create(observer => {
            this.http.get(environment.paymentApiUrl  + 'payment/loyalty/' + organizationId + "/getGivexCards/" + customerId).subscribe(
                result => {
                    observer.next(result);
                    observer.complete();
                }, error => {
                    //console.log(error);
                    observer.next(error);
                });
        });
    }
  sendVerificationMail(reqData) {
    return Observable.create((observer) => {
      this.http
        .post(environment.customerApiUrl + 'customers/sendValidation', reqData)
        .subscribe(
          (result) => {
            observer.next(result);
            observer.complete();
          },
          (error) => {
            //console.log(error);
            observer.next(error);
          }
        );
    });
  }
  fetchGiftCards(organizationId, customerEmail) {
    let encodedEmail = encodeURIComponent(customerEmail);
    //let  url = environment.paymentApiUrl + 'payment/bambora/' + locationId + "/getCustomer/" + customerId
    let url =
      environment.paymentApiUrl +
      'payment/loyalty/' +
      organizationId +
      '/getCards/' +
      encodedEmail;
    return this.http.get(url);
  }
  public createProfileAndSave(reqData) {
        return Observable.create(observer => {
         
        this.http.post(environment.paymentApiUrl + 'payment/loyalty/createProfile',reqData).subscribe(
            result => {
                observer.next(result);
                observer.complete();
            }, error => {
                //console.log(error);
                observer.next(error);
            });
    });
    }

  public addCardToProfile(reqData) {
    return Observable.create((observer) => {
      this.http
        .post(
          environment.paymentApiUrl + 'payment/loyalty/addCardToProfile',
          reqData
        )
        .subscribe(
          (result) => {
            observer.next(result);
            observer.complete();
          },
          (error) => {
            //console.log(error);
            observer.next(error);
          }
        );
    });
  }
    public addGivexCardToProfile(reqData) {
        return Observable.create(observer => {
            this.http.post(environment.paymentApiUrl + 'payment/loyalty/addGivexCardToProfile',reqData).subscribe(
                result => {
                    observer.next(result);
                    observer.complete();
                }, error => {
                    //console.log(error);
                    observer.next(error);
                });
        });
    }
  // public addCardToProfile(reqData) {
  //     return Observable.create(observer => {
  //         this.http.post(environment.paymentApiUrl + 'payment/loyalty/addCardToProfile', reqData, new Headers()).subscribe(
  //             result => {
  //                 observer.next(result);
  //                 observer.complete();
  //             }, error => {
  //                 //console.log(error);
  //                 observer.next(error);
  //             });
  //     });
  // }

  public redeem(reqData) {
    return Observable.create((observer) => {
      this.http.post(
        environment.paymentApiUrl + 'payment/loyalty/redeem',
        reqData
      );
    });
  }
  public remove(reqData) {
    return Observable.create((observer) => {
           
            this.http.post(environment.paymentApiUrl +   'payment/loyalty/remove',reqData).subscribe(
                result => {
                    observer.next(result);
                    observer.complete();
                }, error => {
                    //console.log(error);
                    observer.next(error);
                });
    });
  }
}

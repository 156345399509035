import {
  BaseComponent,
} from '@moduurnv2/libs-orderingapp/src/core/base/base-component';
import {
  FetchVenues,
  SetSelectedPickUpLocation} from '@moduurnv2/libs-orderingapp/src/core/actions';
  import {
    Venue} from '@moduurnv2/libs-orderingapp/src/core/models';
import { Input, OnInit, Output, EventEmitter } from '@angular/core';
import { environment } from './../../../core/environments/environment';
import { Store, Select } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';

import { VenueManagementState } from './../../../core/state/venue-management.state';
import { Observable } from 'rxjs/internal/Observable';
import { TranslateService } from '@ngx-translate/core';
import {ModalService} from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
export class VenuePickupLocationTimeSelectionBaseComponent extends BaseComponent
  implements OnInit {
  backButton: string =
    environment.image_basepath + environment.images.arrow_left_icon;
  closeButton: string =
    environment.image_basepath + environment.images.close_update_modal;
  selectedPickUpStation: Venue;
  showPickUpStationList: boolean = false;
  requiredError: string = '';
  pickUpStations: Venue[] = [];
  noPickupStationError: boolean = false;
  @Output() closeEvent = new EventEmitter();
  _modalClose: any = null;
  @Select(VenueManagementState.getVenues) venues$: Observable<Venue[]>;
  constructor(protected store: Store, public translate:TranslateService,private modal:ModalService) {
    super();
  }

  ngOnInit() {
    this.populateInitialData();
  }

  populateInitialData() {
    let filter = {
      tableType: 'TABLETYPE06'
    };
    this.store.dispatch(new FetchVenues(filter));
    this.venues$.pipe(takeUntil(this.destroy$)).subscribe((venues) => {
      if (venues && venues.length > 0) {
        this.pickUpStations = venues;
        let selectedPickUpLocation = this.store.selectSnapshot(
          VenueManagementState.getSelectedPickUpLocation
        );
        if(this.pickUpStations.length == 1){
          selectedPickUpLocation = this.pickUpStations[0];
        }
        if (selectedPickUpLocation) {
          this.pickUpStationSelection(selectedPickUpLocation);
        }
      } else {
        this.noPickupStationError = true;
      }
    });
  }

  openPickUpSelectionList(event) {
    event.stopPropagation();
    event.preventDefault();
    this.showPickUpStationList = !this.showPickUpStationList;
  }

  pickUpStationSelection(station, event?) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.requiredError = '';
    this.selectedPickUpStation = station;
    this.showPickUpStationList = false;
  }

  proceed() {
    if (this.selectedPickUpStation) {
      this.store.dispatch(
        new SetSelectedPickUpLocation(this.selectedPickUpStation)
      );
      this.closeSelectionModal();
    } else {
      this.requiredError = this.translate.instant('select-pickup');
    }
  }

  closeSelectionModal() {
    // this.closeEvent.emit();
    if (this._modalClose) this._modalClose();
  }

  set modalClose(modalRef: any) {
    this._modalClose = () => {
      this.modal.closeModal(modalRef);
    };
  }
}

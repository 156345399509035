import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import {
  LocationState,
  SetSelectedLocation,
  Location,
  FetchLocationList,
  SetSelectedOrderId,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Observable } from 'rxjs/internal/Observable';
import { Store, Select } from '@ngxs/store';
import { ActivatedRoute } from '@angular/router';
@Injectable()
export class NotificationGuardService implements CanActivate {
  @Select(LocationState.getLocationsList) locationsList$: Observable<
    Location[]
  >;
  constructor(
    public store: Store,
    public router: Router,
    public route: ActivatedRoute
  ) {}
  canActivate(): boolean {
    let selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    this.store.dispatch(new FetchLocationList());
    this.locationsList$.subscribe((response) => {
      if (response) {
        const type = this.route.queryParams['value']['type'];
        if (type === 'order') {
          const locationId = this.route.queryParams['value']['location'];
          const orderId = this.route.queryParams['value']['order'];
          if (locationId && orderId) {
            this.store.dispatch(new SetSelectedLocation(locationId));
            this.store.dispatch(new SetSelectedOrderId(orderId));
            this.router.navigate([`locations/${locationId}/order-details`]);
            return false;
          }
        }
      }
    });

    return true;
  }
}

<div class="row address-card">
  <div class="firstcolumn">
    <div class="content">
      <span class="header" aria-label="delivering to">
        {{ 'delivering-to' | translate }}
      </span>
      <br />
      <!-- <span class="header">{{ 'delivery-adrs' | translate }}</span> -->
      <span class="description">
        <span class="address-type" aria-label="delivery address">{{ deliveryLocation?.addressType }}</span>
        {{ deliveryAddress }}</span
      >
      <br />
      <!-- <span class="time">ETA: 32 mins. With live tracking.</span> -->
    </div>
  </div>
  <div class="secondcolumn">
    <!-- <button class="change" (click)="openChangeAdrs()" data-qa="change-adrs">{{ 'change' | translate  }}</button> -->
    <button
      class="change"
      (click)="openChangeAdrs()"
      data-qa="change-address"
      aria-label="change address"
    >
      {{ 'change' | translate }}
    </button>
    <!-- <moduurnv2-address-list
      [isAddressBook]="addressBookStatus"
      [modalChangeAdrsStatus]="viewChangeAdrsModalStatus"
      (closeEvent)="closeChangeAdrs()"
    >
    </moduurnv2-address-list> -->
  </div>
</div>

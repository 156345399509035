import { State, Selector, StateContext, Action } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Discount } from '@moduurnv2/libs-orderingapp/src/core/models';
import {
  SetDiscounts,
  FetchDiscountsList,
} from '@moduurnv2/libs-orderingapp/src/core/actions';
import { Injectable } from '@angular/core';

import { DiscountsService } from '@moduurnv2/libs-orderingapp/src/core/services';
import { tap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs';

export class DiscountStateModel {
  discountsList: Discount[];
  selectedDiscount: Discount;
}

@State<DiscountStateModel>({
  name: 'discounts',
  defaults: {
    selectedDiscount: null,
    discountsList: undefined,
  },
})
@Injectable()
export class DiscountsState {
  constructor(private _discountsService: DiscountsService) {}

  @Selector()
  static getDiscountsList(state: DiscountStateModel) {
    return state.discountsList;
  }

  @Action(FetchDiscountsList)
  fetchDiscounts(
    { setState }: StateContext<DiscountStateModel>,
    { payload }: FetchDiscountsList
  ) {
    return this._discountsService.fetchDiscounts(payload).pipe(
      tap((response) => {
        if (response) {
          setState(
            patch({
              discountsList: response,
            })
          );
        } else throw response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  @Action(SetDiscounts)
  setDiscounts(
    { setState }: StateContext<DiscountStateModel>,
    { payload }: SetDiscounts
  ) {
    setState(
      patch({
        discountsList: payload,
      })
    );
  }
}

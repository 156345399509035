/**
 * Usage: value | distanceConverter :value  *
 **/

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  WhitelabelState,
  Whitelabel,
  LocationDefaultState,
  LocationDefaults,
} from '@moduurnv2/libs-orderingapp/src/core';
import { formatNumber } from '@angular/common';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
@Pipe({
  name: 'distanceConverter',
  pure: false,
})
export class DistanceConverter implements PipeTransform {
  whitelabel;
  allowNoPaymentOrdering;
  @Select(WhitelabelState.getWhitelabel) whitelabel$: Observable<Whitelabel>;
  @Select(LocationDefaultState.getLocationDefaults)
  locationDefaults$: Observable<LocationDefaults>;
  constructor(private translate: TranslateService) {
    this.whitelabel$.subscribe((response) => {
      this.whitelabel = response;
    });
  }
  transform(
    value: number,
    distance: string = this.whitelabel.distance
      ? this.whitelabel.distance
      : 'KM '
  ): string | null {
    if (distance == 'KM') {
      if (typeof value === 'string' && value === '') distance = '0.00 km';
      else distance = value.toFixed(2) + ' km';
      return distance;
    } else {
      if (typeof value === 'string' && value === '') distance = '0.00 km';
      else {
        value = value * 0.621371; // miles
        distance = value.toFixed(2) + ' Mi';
      }
      return distance;
    }
  }
}

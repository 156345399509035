
export class Provinces {
    static states = {
        Canada: [
            {
                "n": "Alberta",
                "v": "AB"
            },
            {
                "n": "British Columbia",
                "v": "BC"
            },
            {
                "n": "Manitoba",
                "v": "MB"
            },
            {
                "n": "New Brunswick",
                "v": "NB"
            },
            {
                "n": "Newfoundland",
                "v": "NL"
            },
            {
                "n": "Northwest Territories",
                "v": "NT"
            },
            {
                "n": "Nova Scotia",
                "v": "NS"
            },
            {
                "n": "Nunavut",
                "v": "NU"
            },
            {
                "n": "Ontario",
                "v": "ON"
            },
            {
                "n": "Prince Edward Island",
                "v": "PE"
            },
            {
                "n": "Quebec",
                "v": "QC"
            },
            {
                "n": "Saskatchewan",
                "v": "SK"
            },
            {
                "n": "Yukon Territory",
                "v": "YT"
            }
        ],
        USA:[
            {
                "n": "Alabama",
                "v": "AL"
            },
            {
                "n": "Alaska",
                "v": "AK"
            },
            {
                "n": "American Samoa",
                "v": "AS"
            },
            {
                "n": "Arizona",
                "v": "AZ"
            },
            {
                "n": "Arkansas",
                "v": "AR"
            },
            {
                "n": "California",
                "v": "CA"
            },
            {
                "n": "Colorado",
                "v": "CO"
            },
            {
                "n": "Connecticut",
                "v": "CT"
            },
            {
                "n": "Delaware",
                "v": "DE"
            },
            {
                "n": "District Of Columbia",
                "v": "DC"
            },
            {
                "n": "Federated States Of Micronesia",
                "v": "FM"
            },
            {
                "n": "Florida",
                "v": "FL"
            },
            {
                "n": "Georgia",
                "v": "GA"
            },
            {
                "n": "Guam",
                "v": "GU"
            },
            {
                "n": "Hawaii",
                "v": "HI"
            },
            {
                "n": "Idaho",
                "v": "ID"
            },
            {
                "n": "Illinois",
                "v": "IL"
            },
            {
                "n": "Indiana",
                "v": "IN"
            },
            {
                "n": "Iowa",
                "v": "IA"
            },
            {
                "n": "Kansas",
                "v": "KS"
            },
            {
                "n": "Kentucky",
                "v": "KY"
            },
            {
                "n": "Louisiana",
                "v": "LA"
            },
            {
                "n": "Maine",
                "v": "ME"
            },
            {
                "n": "Marshall Islands",
                "v": "MH"
            },
            {
                "n": "Maryland",
                "v": "MD"
            },
            {
                "n": "Massachusetts",
                "v": "MA"
            },
            {
                "n": "Michigan",
                "v": "MI"
            },
            {
                "n": "Minnesota",
                "v": "MN"
            },
            {
                "n": "Mississippi",
                "v": "MS"
            },
            {
                "n": "Missouri",
                "v": "MO"
            },
            {
                "n": "Montana",
                "v": "MT"
            },
            {
                "n": "Nebraska",
                "v": "NE"
            },
            {
                "n": "Nevada",
                "v": "NV"
            },
            {
                "n": "New Hampshire",
                "v": "NH"
            },
            {
                "n": "New Jersey",
                "v": "NJ"
            },
            {
                "n": "New Mexico",
                "v": "NM"
            },
            {
                "n": "New York",
                "v": "NY"
            },
            {
                "n": "North Carolina",
                "v": "NC"
            },
            {
                "n": "North Dakota",
                "v": "ND"
            },
            {
                "n": "Northern Mariana Islands",
                "v": "MP"
            },
            {
                "n": "Ohio",
                "v": "OH"
            },
            {
                "n": "Oklahoma",
                "v": "OK"
            },
            {
                "n": "Oregon",
                "v": "OR"
            },
            {
                "n": "Palau",
                "v": "PW"
            },
            {
                "n": "Pennsylvania",
                "v": "PA"
            },
            {
                "n": "Puerto Rico",
                "v": "PR"
            },
            {
                "n": "Rhode Island",
                "v": "RI"
            },
            {
                "n": "South Carolina",
                "v": "SC"
            },
            {
                "n": "South Dakota",
                "v": "SD"
            },
            {
                "n": "Tennessee",
                "v": "TN"
            },
            {
                "n": "Texas",
                "v": "TX"
            },
            {
                "n": "Utah",
                "v": "UT"
            },
            {
                "n": "Vermont",
                "v": "VT"
            },
            {
                "n": "Virgin Islands",
                "v": "VI"
            },
            {
                "n": "Virginia",
                "v": "VA"
            },
            {
                "n": "Washington",
                "v": "WA"
            },
            {
                "n": "West Virginia",
                "v": "WV"
            },
            {
                "n": "Wisconsin",
                "v": "WI"
            },
            {
                "n": "Wyoming",
                "v": "WY"
            }
        ],
        Saudi:[
            {
                "n": "Qassim Province",
                "v": "Qassim Province"
            },
            {
                "n": "Riyadh Province",
                "v": "Riyadh Province"
            },
            {
                "n": "Tabuk Province",
                "v": "Tabuk Province"
            },
            {
                "n": "Madinah Province",
                "v": "Madinah Province"
            },
            {
                "n": "Makkah Province",
                "v": "Makkah Province"
            },
            {
                "n": "Northern Borders Province",
                "v": "Northern Borders Province"
            },
            {
                "n": "Jawf Province",
                "v": "Jawf Province"
            },
            {
                "n": "Ha'il Province",
                "v": "Ha'il Province"
            },
            {
                "n": "Bahah Province",
                "v": "Bahah Province"
            },

            {
                "n": "Jizan Province",
                "v": "Jizan Province"
            },
            {
                "n": "'Asir Province",
                "v": "Asir Province"
            },
            {
                "n": "Najran Province",
                "v": "Najran Province"
            },
            {
                "n": "Eastern Province",
                "v": "Eastern Province"
            }
        ]
    };
    static countries = [
        {
            name:"Canada",
            code: "Canada",
            phone: "CA",
            countryCode: "CA (+1)"
        },
        {
            name:"USA",
            code: "USA",
            phone: "US",
            countryCode: "US (+1)"
        },
        {
            name:"Saudi Arabia",
            code: "Saudi",
            phone:'SA',
            countryCode: "SA (+966)"
        }
    ]
}
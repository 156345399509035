import { OnInit, EventEmitter, Output } from '@angular/core';

import {
  AuthState,
  BaseComponent,
  environment,
  LocationState,
  SetAgreeToTerms,
  SetLoginFrom,
  WhitelabelState,
} from '@moduurnv2/libs-orderingapp/src/core';

import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ThemeOptions } from '@moduurnv2/features/ui/directives';
import { Location } from '@angular/common'
import { NavigationBackService } from '@moduurnv2/libs-orderingapp/src/core/services/navigation-back.service';

export class LoginBaseComponent extends BaseComponent {
  @Output() showEmailAuth: EventEmitter<any> = new EventEmitter<any>();
  pageToShow: string;
  setTerms: boolean = false;
  isSignupClicked: boolean = false;
  options: ThemeOptions = {
    // appFontFamily: true,
  };
  bannerText:string;
  loginHeaderImg: string =
    environment.image_basepath + environment.images.login_head;
  loginRightArrowIcon: string =
    environment.image_basepath + environment.images.arrow_right_icon;
  loginLeftArrowIcon: string =
    environment.image_basepath + environment.images.arrow_left_icon;
  loginRightArrowIconPng: string =
    environment.image_basepath + environment.images.arrow_right_icon_png;
  loginLeftArrowIconPng: string =
    environment.image_basepath + environment.images.arrow_left_icon_png;

  loginProviders = [
    // {
    //   textCode: 'LOGINPROVIDER01',
    //   text: 'Phone',
    //   textTagLine: 'Verification Code',
    //   iconUrl: environment.image_basepath + environment.images.phone_icon,
    //   gradient: {
    //     primary: '#E2AB2E',
    //     secondary: '#F4D388',
    //   },
    // },
    // {
    //   textCode: 'LOGINPROVIDER02',
    //   text: 'Facebook',
    //   textTagLine: 'Sign up with Facebook',
    //   iconUrl: environment.image_basepath + environment.images.facebook_icon,
    //   gradient: {
    //     primary: '#3477D0',
    //     secondary: '#5AA1FF',
    //   },
    // },
    // {
    //   textCode: 'LOGINPROVIDER03',
    //   text: 'Google',
    //   textTagLine: 'Sign up with Google',
    //   iconUrl: environment.image_basepath + environment.images.google_icon,
    //   gradient: {
    //     primary: '#F5F5F5',
    //     secondary: '#DEDEDE',
    //   },
    // },
    {
      textCode: 'LOGINPROVIDER04',
      text: 'Email',
      textTagLine: 'email-signup',
      iconUrl: environment.image_basepath + environment.images.email_icon,
      gradient: {
        primary: '#877AD6',
        secondary: '#BAB0FA',
      },
    },
    {
      textCode: 'LOGINPROVIDER05',
      text: 'Microsoft',
      textTagLine: 'Sign in with your email account',
      iconUrl: environment.image_basepath + environment.images.microsoft_icon,
      gradient: {
        primary: '#F5F5F5',
        secondary: '#DEDEDE',
      },
    },
  ];

  constructor(private router: Router, public store: Store,private navigationService:NavigationBackService) {
    super();
    let whitelabel = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
    if (whitelabel && whitelabel.loginPage){
      if(whitelabel.loginPage.phone)
        this.loginHeaderImg = environment.imagebaseurl + whitelabel.loginPage.phone;
      this.bannerText = whitelabel.loginPage.heading;
    }  
   }

  goBack() {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    const previousPage = this.store.selectSnapshot(AuthState.getLoginFrom);     
    if (selectedLocation?._id) {    
        if(previousPage){
          previousPage=='locations' ? this.router.navigate(['locations']) : this.navigationService.back()
        }    
       else {
        this.navigationService.back()
        // this.router.navigate([`/locations/${selectedLocation._id}/home`]);
      }
    }else{
      this.router.navigate([`locations`])
    }
    if(previousPage != 'order'){
      previousPage != 'locations'? this.store.dispatch(new SetLoginFrom('')):this.store.dispatch(new SetLoginFrom('locations'));      
    }
  }
  showEmailAuthModal($event) {
    if ($event == false) this.pageToShow = undefined;
  }

  changeTerms(event){
    this.setTerms = !this.setTerms;
    this.store.dispatch(new SetAgreeToTerms(this.setTerms));
  }
}

import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AddressListDesktopComponent } from './address-list.component-desktop';
import { AddressDetailsDesktopComponent } from './components/address-details-desktop/address-details-desktop.component';
import { AddressCardDesktopComponent } from '../address-book-desktop/components/address-card-desktop/address-card.component-desktop';
import { AddressMapDesktopComponent } from '../address-book-desktop/components/address-map-desktop/address-map.component-desktop';
import { UIModule } from '@moduurnv2/web';
import { FormsModule } from '@angular/forms';
import { AddressBookComponent } from './components/address-book-desktop/address-book-desktop.component';

@NgModule({
  imports: [UIModule, FormsModule,CommonModule],
  declarations: [
    AddressListDesktopComponent,
    AddressDetailsDesktopComponent,
    AddressCardDesktopComponent,
    AddressMapDesktopComponent,
    AddressBookComponent,
  ],
  exports: [
    UIModule,
    FormsModule,
    AddressListDesktopComponent,
    AddressDetailsDesktopComponent,
    AddressCardDesktopComponent,
    AddressMapDesktopComponent,
    AddressBookComponent,
  ],
})
export class AddressBookModule {}

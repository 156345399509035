import { environment } from '@moduurnv2/libs-orderingapp/src/core/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

@Injectable()
export class PixelHandler {

    fbPixelGlobalTrackingId: any;
    globalPixelProperties: any;
    fbPixelTrackingId: any;
    fbPixelEnableTracking: any;

    public constructor(private http: HttpClient, private store: Store) {}
  
    setPixelScripts(trackingId){
        let fbPixelScript1 = document.createElement('script');
        fbPixelScript1.innerText = `!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version=\'2.0\';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,\'script\',
        \'https://connect.facebook.net/en_US/fbevents.js\');
        fbq(\'init\', ${trackingId});
        fbq(\'track\', \'PageView\');`;

        let fbPixelScript2 = document.createElement('noscript');
        fbPixelScript2.setAttribute('async', 'true');
        fbPixelScript2.setAttribute(
        'src',
        `https://www.facebook.com/tr?id=${trackingId}&ev=PageView&noscript=1`
        );

        let pixelScript1 = document.createElement('script');
        pixelScript1.setAttribute('async', 'true');
        pixelScript1.setAttribute(
        'src',
        `https://www.facebook.com/tr?id=${trackingId}&ev=PageView&noscript=1`
        );
        let pixelScript2 = document.createElement('script');
        pixelScript2.innerText = `!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version=\'2.0\';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,\'script\',
        \'https://connect.facebook.net/en_US/fbevents.js\');
        fbq(\'init\', ${trackingId});
        fbq(\'track\', \'PageView\');`;
        document.documentElement.firstChild.appendChild(pixelScript1);
        document.documentElement.firstChild.appendChild(pixelScript2);

  }
 
    getPixelProperties() {
        return new Promise((resolve,reject)=>{
        const url =
        environment.settingsApiUrl + 'settings/constants/ANALYTICSPROPERTIES';
        this.http.get(url).subscribe((response) => {
        this.globalPixelProperties = response;
        let index = this.globalPixelProperties.findIndex(
            (x) => x.textCode == 'ANALYTICSPROPERTIES02'
        );
        if (index != -1) {
            this.fbPixelGlobalTrackingId = this.globalPixelProperties[
            index
            ].text;
        }
        resolve(this.fbPixelGlobalTrackingId);
        });
    });
    }

    organisationPixelCheck(trackingId, fbPixelDetails){
        this.fbPixelTrackingId = trackingId;
          this.getPixelProperties().then(res=>{
            this.fbPixelEnableTracking =
            this.fbPixelTrackingId && fbPixelDetails.isEnabled
              ? true
              : false;
            this.setPixelScripts(trackingId);
            if(res && trackingId != res)
            this.setPixelScripts(res);
          });
    }
}

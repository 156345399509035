<ng-container *ngFor="let portion of portions">
  <div class="portion-list">
    <label [attr.for]="portion._id" class="portion-label">
      <span>{{ portion.name | formattedLanguage: portion.translation }}</span>
      <span *ngIf="portion.price">
        <span  [ngClass]="{ 'strike': (portion.discountedPrice >= 0) }">
            {{ portion.price | formattedCurrency }}
        </span>
        <ng-container *ngIf="(portion.discountedPrice >= 0)">
          {{ portion.discountedPrice | formattedCurrency }}
        </ng-container>
        <span  class="loyalty" *ngIf="portion.employeeLoyaltyPoints && isLoyaltyEnabled">
          {{ portion.employeeLoyaltyPoints }} PTS
        </span>
      </span>
      <span  class="loyalty" *ngIf="portion.employeeLoyaltyPoints && !portion.price && isLoyaltyEnabled">
        {{ portion.employeeLoyaltyPoints  }} PTS
      </span>

    </label>
    <!-- <input class="custom-radio" type="radio" [attr.id]="portion._id" name="portion" [value]="portion._id"
      [ngModel]="selectedPortion" (change)="portionChange($event, portion)" [attr.data-qa]="portion._id + '_input'" /> -->
    <label class="container">
      <input
        class="custom-radio"
        type="radio"
        [attr.id]="portion._id"
        name="portion"
        [value]="portion._id"
        [ngModel]="selectedPortion"
        (change)="portionChange($event, portion)"
        [attr.data-qa]="portion._id + '_input'"
        [ngClass]="{ selected: portion.selected }"
      />
      <span class="checkmark"></span>
    </label>
  </div>
</ng-container>

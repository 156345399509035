import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import {
  AuthState,
  WhitelabelState
} from '@moduurnv2/libs-orderingapp/src/core';
import { Store } from '@ngxs/store';
@Injectable()
export class AuthGuard implements CanActivate {
  configuration = this.store.selectSnapshot(WhitelabelState.getOther);
  constructor(private router: Router,private store:Store) { }
  canActivate() {
    const customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    if(customer?.isLoggedIn){
      return true;
    } else {
      this.router.navigate([`/login`]);
     return false;
    }
  }
}
import { Component } from '@angular/core';
import { AddressDetailsBaseComponent } from '@moduurnv2/libs-orderingapp/src/features/ui/base/address-card-details-component';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {SweetAlertService} from '@moduurnv2/libs-orderingapp/src/core/services/sweet-alert-service';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '@moduurnv2/libs-orderingapp/src/core/services/shared.service';
@Component({
  selector: 'moduurnv2-address-details-desktop',
  templateUrl: './address-details-desktop.component.html',
  styleUrls: ['./address-details-desktop.component.scss'],
})
export class AddressDetailsDesktopComponent extends AddressDetailsBaseComponent {
  // _modalClose: any = null;
  isFromAddressBook: Boolean = false;
  constructor( public store: Store, public modal: ModalService,    public sweetAlert: SweetAlertService,
    public translate: TranslateService,public router: Router,shared:SharedService)
  {
    super(store,modal,sweetAlert,translate,router,shared);
  }
  // closeDetails() {
  //   if (this._modalClose) this._modalClose();
  // }

  // set modalClose(modalRef: any) {
  //   this._modalClose = () => {
  //     this.modal.closeUserDefinedComponentModals(2);
  //   };
  // }

  saveAddress(saveAddressBtn){
    this.saveAdrs(saveAddressBtn);
  }

}

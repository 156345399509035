// angular
import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
// app
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Store } from '@ngxs/store';


@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private http: HttpClient, private store: Store, private router:Router) {}

  setAnalytics(payload) {

    let url = `${environment.locationApiUrl}analytics/analyticsTracking`;
    
    return this.http.post(url, payload);
  }
  
}
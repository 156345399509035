import { Component, OnInit } from '@angular/core';
import {
  VenueRoomTimeSelectionBaseComponent
} from '@moduurnv2/libs-orderingapp/src/features/ui/base/venue-room-time-selection-base.component';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import {ModalService} from './../../../shared/modal/modal.service';
@Component({
  selector: 'moduurnv2-room-time-selection',
  templateUrl: './room-time-selection.component.html',
  styleUrls: ['./room-time-selection.component.scss','./../../common-styles/venue-selection.scss']
})
export class RoomTimeSelectionComponent extends VenueRoomTimeSelectionBaseComponent implements OnInit {

  constructor(store:Store, translate:TranslateService,router:Router,modal:ModalService) {
    super(store,translate,router,modal)
   }
}

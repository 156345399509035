import { OnInit, Output, Input } from '@angular/core';
import {
  BaseComponent,
  NewCard,
  LocationState,
  AuthEmailState,
  GiftAndLoyaltyCardService,
  OrderService,
  CartItems,
  CartState,
  Cart,
  GiftLoyaltyMethodState,
  GiftCard,
  SweetAlertService,
  SetAckrooPaymentDetails,
  PaymentMethodState,
  ackrooPaymentCard,
  ClearSelectedCard,
  SetPaymentMethod,
  WhitelabelState,
  FetchGiftLoyaltyCards,
  AuthEmailLoginResponse,
} from '@moduurnv2/libs-orderingapp/src/core';

import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormControl,
} from '@angular/forms';

import { Router ,NavigationExtras} from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { paymentCardType, paymentMethods } from '../../../core/services';
import { EventEmitter } from '@angular/core';
import { environment } from '@moduurnv2/libs-orderingapp/src/core';
import { Observable, Subject } from 'rxjs';

import { AddressListBaseComponent } from '@moduurnv2/libs-orderingapp/src/features';
import { HttpClient } from '@angular/common/http';
import { debug } from 'util';
import { takeUntil } from 'rxjs/operators';

import { AuthState, SharedService } from '@moduurnv2/libs-orderingapp/src/core';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import {TranslateService} from '@ngx-translate/core';
interface Credential {
  number: string;
  giftCardPIN: string;
}

export class GiftCardBaseComponent extends BaseComponent implements OnInit {
  cartItems: CartItems[];
  @Output() complete = new EventEmitter();
  @Output() isCardVerificationEnabled = new EventEmitter();
  @Output() isCardVerificationFinished = new EventEmitter();
  @Select(CartState.getCartItems) cartItems$: Observable<CartItems[]>;
  giftCards: any;
  giftCardAmount;
  totalBalanceInCards = 0.0;
  pendingAmount = 0.0;
  balanceToPay = 0.0;
  isShowForm: Boolean = false;
  newCardNumber;
  ackrooId;
  isDisabled: Boolean = false;
  isRegistered: Boolean = false;
  showHelpAckrooId: Boolean = false;
  emailValidated: Boolean = false;
  isEmailValidated: Boolean = true;
  pageCount: number = 0;
  isOtherPayment: Boolean = false;
  cartPrices: any = [];
  cartPrice: any = [];
  paymentDetails: any;
  registeredUser: boolean = false;
  @Select(GiftLoyaltyMethodState.getSavedCards) savedCards$: Observable<
    GiftCard[]
  >;
  @Select(PaymentMethodState.getAckrooPaymentDetails)
  ackrooPaymentDetails$: Observable<ackrooPaymentCard>;
  card: GiftCard;
  @Select(AuthEmailState.getUserDetails)
  userDetails$: Observable<AuthEmailLoginResponse>;

  @Output() addCardDesktopView = new EventEmitter();
  numbers = new RegExp(/^[0-9]*$/);
  isMobileView: boolean = true;
  giftCardForm = new FormGroup({
    number: new FormControl('', Validators.compose([Validators.required])),
    giftCardPIN: new FormControl(''),
  });
  giftCardDetails = new FormGroup({
    amount: new FormControl('', Validators.compose([Validators.required])),
  });
  credential: Credential = {
    number: '',
    giftCardPIN: '',
  };
  newCard: NewCard;
  paymentMethods = paymentMethods;
  saveToAccount;
  cardType;
  user;
  userEmail: string = '';
  orderService: OrderService;
  counter = 5;
  verified;
  timerInstance;
  whiteLabel: any = undefined;
  imageBaseUrl = environment.imagebaseurl;
  giftCardPaymentSelection = false;
  enableCardVerification = false;
  enableCardBalance = false;
  changeCardAmt = false;
  icon = {
    alert: 'assets/images/alert-icon-new.svg',
  };
  giftCardPaymentAmt;
  _modalClose: any = null;
  cardDetails: any;
  loyaltyProgram : any;
  ackrooUrls : any;
  constructor(
    private fb: FormBuilder,
    public store: Store,
    private router: Router,
    private shared: SharedService,
    private loyaltyService: GiftAndLoyaltyCardService,
    public modal: ModalService,
    public sweetAlert: SweetAlertService,
    public translate: TranslateService
  ) {
    super();
    this.userDetails$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      this.user = user;
      this.userEmail = this.user && this.user.email ? this.user.email : '';
    });
    this.savedCards$.pipe(takeUntil(this.destroy$)).subscribe((giftCards) => {
      let errorResponse = this.store.selectSnapshot(
        GiftLoyaltyMethodState.getErrorResponse
      );
     console.log('Card Subscription',giftCards);
      if (giftCards) {
        this.giftCards = undefined;
        if (giftCards.length && giftCards.length >= 0) {
          // this.card = giftCards.find((c) => c.active == true);
          this.cardDetails = giftCards.filter((c) => c.active == true);
          this.cardDetails.forEach((cardElem, indx) => {
                   cardElem['selected'] = !indx ? true : false;
                    cardElem['cardBalance']= parseFloat(cardElem.gift_balance) +
            parseFloat(cardElem.loyalty_balance); 
          });
          this.card = this.cardDetails.length ? this.cardDetails[0] : undefined;
          this.getTotalCardAmount();
          if(this.card){
            this.setGiftCardPaymentAmount();
          }
          this.registeredUser = true;
          this.emailValidated = true;
        } else if (
          errorResponse &&
          errorResponse.error &&
          errorResponse.error === 2103
        ) {
          this.emailValidated = false;
          this.giftCards = undefined;
          this.card = undefined;
          this.registeredUser = false;
        } else {
          this.giftCards = undefined;
          this.card = undefined;
          this.emailValidated = true;
          this.registeredUser = false;
        }
      }
    });
  }

  ngOnInit() {
    this.isCardVerificationEnabled.emit(this.enableCardVerification);
    this.isCardVerificationFinished.emit(this.enableCardVerification);
     this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
    this.cartPrice = this.store.selectSnapshot(CartState.getCart);
    this.cartItems = this.store.selectSnapshot(CartState.getCartItems);
    this.giftCards = this.store.selectSnapshot(GiftLoyaltyMethodState.getSavedCards);

    this.whiteLabel = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
    // if (this.giftCards)
    //   this.card = this.giftCards.find((c) => c.active == true);
    this.getGiftCards();
  }
  get giftCardFormControl() {
    return this.giftCardForm.controls;
  }
  populateRememberedData(giftCardData) {
    this.giftCardForm.controls['giftcardNumber'].setValue(
      giftCardData.giftcardNumber
    );
  }
  addGiftCard() {
    localStorage.setItem('isFromRewardPage', 'False');
    this.router.navigate([`/payment/add-gift-card`]);
  }
  payTotalClick() {
    this.isOtherPayment = false;
    this.pageCount = 1;
    this.giftCardPaymentSelection = true;
  }
  payOtherClick() {
    this.isOtherPayment = true;
    // this.pageCount = 1;
    this.giftCardPaymentSelection = true;
  }

  SetAckooPaymentDetails(amountToPay, isAllAmountPayedFromGiftCard) {
    let userData = this.store.selectSnapshot(AuthEmailState.getUserDetails);
    let userEmail = userData && userData.email ? userData.email : '';

    let ackrooPaymentDetails = {
      amountToPay: amountToPay,
      userEmail: userEmail,
      locationId: this.paymentDetails.locationId,
      isGuestUser: !userEmail,
      isAllAmountPayedFromGiftCard: isAllAmountPayedFromGiftCard,
      organizationId: this.paymentDetails.organizationId,
      selectedCard : this.card.cardnumber,
      loyaltyProgram: this.loyaltyProgram,
      cardIndex : this.card.id ? this.card.id : 0,
      customerId : userData.user_id,
    };
    this.store.dispatch(new SetAckrooPaymentDetails(ackrooPaymentDetails));

    let paymentMethod = this.paymentMethods.find((method) => method.id == 4);
    this.store.dispatch(new ClearSelectedCard());
    this.store.dispatch(new SetPaymentMethod(paymentMethod));
    if (ackrooPaymentDetails && ackrooPaymentDetails.amountToPay) {
      if (!ackrooPaymentDetails.isAllAmountPayedFromGiftCard) {
        if (this.isMobileView) {
          this.router.navigate([`/payment`]);
          return;
        } else return;
      }
      this.modal.closeAllModals();

      if (this.isMobileView) {
        const selectedLocation = this.store.selectSnapshot(
          LocationState.getSelectedLocation
        );
        if (selectedLocation._id) {
          let navigationExtras: NavigationExtras = {
            queryParams: {
                "paymentId": 4
            }
          };
          this.router.navigate([`/locations/${selectedLocation._id}/order`],navigationExtras);
        }
      }
    }
  }

  // isLoyaltyBalanceAccepted(){
  //   if(this.card.loyalty_balance && parseFloat(this.card.loyalty_balance) >= 5){
  //     return true;
  //   }
  //   return false;
  // }

  isPayFullFromGiftCard(){
    if(this.giftCardPaymentAmt >= parseFloat(this.paymentDetails.amountToPay)){
      this.isOtherPayment = false;
    } else {
      this.isOtherPayment = true;
    }
  }

  setGiftCardPaymentAmount(){
    if(this.paymentDetails && this.paymentDetails.amountToPay){

    // let loyaltyAccepted = this.isLoyaltyBalanceAccepted();
    const giftCardBalance = parseFloat(this.card.gift_balance);
    const loyaltyBalance = parseFloat(this.card.loyalty_balance);
    const amountToPay = parseFloat(this.paymentDetails.amountToPay);
    if(amountToPay <= giftCardBalance){
      this.giftCardPaymentAmt = amountToPay;
    }
    else if(amountToPay > giftCardBalance){
      this.giftCardPaymentAmt = giftCardBalance;
      if(loyaltyBalance){
        if(amountToPay < (giftCardBalance + loyaltyBalance)){
          this.giftCardPaymentAmt = amountToPay;
        } else {
          this.giftCardPaymentAmt = giftCardBalance + loyaltyBalance;
        }
      }
    }
    this.payOtherClick();
 
  }
  }

  setCardDetails(card){
    this.pageCount = 0;
    this.card = card;
    this.getTotalCardAmount();
    this.setGiftCardPaymentAmount();
    this.cardDetails.forEach((c, indx) => {
      if (this.card.cardnumber == c.cardnumber) {
        c['selected'] = true;
      } else {
        c['selected'] = false;
      }
    });
    
  }

  continue() {
    if(!this.card){
      if(this.isMobileView)
      {
        this.sweetAlert.toast(
          'error',
          "Please select the Card"
        );

      }
      else{
        this.sweetAlert.simpleAlert(
          '',
          "Please select the Card"
        );
      }
    }
    if(this.pageCount == 0){
      this.setGiftCardPaymentAmount();
    } else{
      this.isPayFullFromGiftCard();
    }
    if(!this.changeCardAmt)
      this.pageCount++;
    else
    {
      this.pageCount = 2;
      this.changeCardAmt = false;
    }
    if (this.pageCount == 2) {
      let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
      if (this.isOtherPayment && !this.giftCardDetails.valid) {
        this.giftCardDetails.get('amount').markAsTouched();
        this.pageCount--;
        return;
      } else if (this.isOtherPayment) {
        if (this.totalBalanceInCards < this.giftCardDetails.value.amount) {
          if(this.isMobileView)
          {
            this.sweetAlert.toast(
              'error',
              "You don't have required Gift/Loyalty card balance"
            );

          }
          else{
            this.sweetAlert.simpleAlert(
              '',
              "You don't have required Gift/Loyalty card balance."
            );
          }

          this.pageCount--;
          return;
        }
        if (this.giftCardDetails.value.amount <= 0) {

          if(this.isMobileView)
          {
            this.sweetAlert.toast(
              'error',
              "Gift/Loyalty card amount should be greater than 0."
            );

          }
          else{
            this.sweetAlert.simpleAlert(
              '',
              "Gift/Loyalty card amount should be greater than 0."
            );
          }
          this.pageCount--;
          return;
        }
        if(this.paymentDetails.amountToPay - this.giftCardDetails.value.amount < 0)
        {
          if(this.isMobileView)
          {
            this.sweetAlert.toast(
              'error',
              "Invalid amount."
            );

          }
          else{
            this.sweetAlert.simpleAlert(
              '',
              "Invalid amount."
            );
          }

          this.pageCount--;
          return;
        }
        this.pendingAmount =
          this.paymentDetails.amountToPay - this.giftCardDetails.value.amount;
        return;
      } else {
        this.SetAckooPaymentDetails(this.paymentDetails.amountToPay, true);
      }
    } else if (this.pageCount > 2 && this.isOtherPayment) {
      this.SetAckooPaymentDetails(this.giftCardDetails.value.amount, false);
    }
  }
  showAddCardForm() {
    this.isShowForm = true;
  }
  logData(data) {
    // this.orderService.logOrder(data).subscribe(response => {
    // });
  }
  ackrooIdHelp() {
    this.showHelpAckrooId = !this.showHelpAckrooId;
  }
  getGiftCards() {
    if (this.cartPrice && this.cartItems) {
      let userData = this.store.selectSnapshot(AuthEmailState.getUserDetails);
      let userEmail = userData && userData.email ? userData.email : '';
      var giftCradRedeemed = this.cartPrice['giftCardAmount']
        ? this.cartPrice['giftCardAmount']
        : 0;
      var amountToPay =
        parseFloat(this.cartPrice['price'].toFixed(2)) +
        parseFloat(giftCradRedeemed.toFixed(2));
      let organizationId = this.cartItems[0].organizationId;
      let locationId = this.cartItems[0].locationId;
      let config = this.store.selectSnapshot(WhitelabelState.getOther);
      this.ackrooUrls = config.loyaltyAndGiftCards.ackroo;
      this.paymentDetails = {
        amountToPay: amountToPay,
        userEmail: userEmail,
        isGuestUser: !userEmail,
        organizationId: organizationId,
        locationId: locationId,
        user_id : userData.user_id,
      };
      if(config && config.loyaltyAndGiftCards && config.loyaltyAndGiftCards.loyaltyProgram){
        this.loyaltyProgram = config.loyaltyAndGiftCards.loyaltyProgram;
        this.ackrooUrls = config.loyaltyAndGiftCards.ackroo;
        switch(config.loyaltyAndGiftCards.loyaltyProgram){
          case 'LOYALTYPROGRAM01' : {
            this.loyaltyService
            .getCards(
              this.paymentDetails.userEmail,
              this.paymentDetails.organizationId
            )
            .subscribe((giftCardDetails) => {
              this.processCardDetails(giftCardDetails);
            });
          }break;
          case 'LOYALTYPROGRAM02' : {
            this.loyaltyService
            .getGivexCards(
              this.paymentDetails.user_id,
              this.paymentDetails.organizationId
            )
            .subscribe((giftCardDetails) => {
              this.processCardDetails(giftCardDetails.cards);
            });
         }break;
        }
      }
    }
  }

  getGiftCardAmount() {
    if (this.totalBalanceInCards > this.paymentDetails.amountToPay) {
      this.giftCardAmount = this.paymentDetails.amountToPay;
    } else {
      this.giftCardAmount = this.totalBalanceInCards;
    }
    this.balanceToPay = this.paymentDetails.amountToPay - this.giftCardAmount;
    this.giftCardAmount = this.giftCardAmount.toFixed(2);
  }
  getTotalAmountInAllCards() {
    if (this.giftCards) {
      this.totalBalanceInCards = 0.0;
      this.giftCards.forEach((element) => {
        if (element.active) {
          this.totalBalanceInCards +=
            parseFloat(element.gift_balance) +
            parseFloat(element.loyalty_balance);
        }
      });
    }
    this.getGiftCardAmount();
    this.getTotalCardAmount()
  }
  changeDeductedAmount(value) {
    if (
      this.paymentDetails.amountToPay - value >= 0 &&
      value < this.totalBalanceInCards
    ) {
      this.balanceToPay = this.paymentDetails.amountToPay - value;
    } else {
      this.getGiftCardAmount();
      this.balanceToPay = this.paymentDetails.amountToPay - this.giftCardAmount;
    }
  }
  saveCard(addGiftCardBtn) {
    var isFromRewardPage = localStorage.getItem('isFromRewardPage');
    let config = this.store.selectSnapshot(WhitelabelState.getOther);
    if (!this.giftCardForm.valid) {
      this.giftCardForm.get('giftCardPIN').markAsTouched();
      this.giftCardForm.get('number').markAsTouched();
      return;
    }

    var cardNumber = this.giftCardForm.value.number.trim();
    var cardPin = this.giftCardForm.value.giftCardPIN.trim();
    var data = {
      email: this.user.email,
      cardNumber: cardNumber,
      ackroo_id: cardPin,
      // locationId: this.paymentDetails.locationId,
      organizationId:  Config.organizationId,
      user_id : this.user.user_id,
      cardPin : cardPin,
    };
    // this.logData({
    //   "msg": "Trying to add new card",
    //   "customerEmail": this.paymentDetails.userEmail ? this.paymentDetails.userEmail : '',
    //   "additionalInfo": {
    //     "cardData": data
    //   }
    // });
    this.startLoader(addGiftCardBtn.id,addGiftCardBtn.innerText);
    if (
      config &&
      config.loyaltyAndGiftCards &&
      config.loyaltyAndGiftCards.loyaltyProgram
    ) {
      switch (config.loyaltyAndGiftCards.loyaltyProgram) {
        case 'LOYALTYPROGRAM01':
          {

            if (this.registeredUser) {
              this.loyaltyService.addCardToProfile(data).subscribe((result) => {
                if (result && result && result && result.cardnumber) {
                  // this.getGiftCards();
                  this.cancelAddCard();
                  this.store.dispatch(new FetchGiftLoyaltyCards());
                  if (this.isMobileView && isFromRewardPage == 'False'){
                    this.router.navigate([`payment/gift-card`]);
                  } else if(this.isMobileView && isFromRewardPage == 'True'){
                    this.router.navigate([`user-rewards`]);
                  }
                  else {
                    this.closePopup();
                    const selectedLocation = this.store.selectSnapshot(
                      LocationState.getSelectedLocation
                    );
                    if (selectedLocation._id) {
                      // this.router.navigate(['user-rewards']);
                    }
                  }
        
                  //   this.logData({
                  //     "msg": "Successfully added new card",
                  //     "customerEmail": this.paymentDetails.userEmail ? this.paymentDetails.userEmail : '',
                  //     "additionalInfo": {
                  //       "cardResponse": result
                  //     }
                  //   });
                } else {
                  if(this.isMobileView)
                  {
                    this.sweetAlert.toast(
                      'error',
                      result.message ? result.message : 'Failed to add Gift Card'
                    );
        
                  }
                  else{
                    this.sweetAlert.simpleAlert(
                      '',
                      result.message ? result.message : 'Failed to add Gift Card'
                    );
                  }
        
        
                  //   this.logData({
                  //     "msg": "Failed to add Gift Card",
                  //     "customerEmail": this.paymentDetails.userEmail ? this.paymentDetails.userEmail : '',
                  //     "additionalInfo": {
                  //       "cardResponse": result.error
                  //     }
                  //   });
                }
                this.stopLoader(addGiftCardBtn.id);
              });
            } else {
              this.loyaltyService.createProfileAndSave(data).subscribe((result) => {
                if (result && result && result && result.cardnumber) {
                  // this.getGiftCards();
                  this.cancelAddCard();
                  this.store.dispatch(new FetchGiftLoyaltyCards());
                  if (this.isMobileView && isFromRewardPage == 'False'){
                    this.router.navigate([`payment/gift-card`]);
                  } else if(this.isMobileView && isFromRewardPage == 'True'){
                    this.router.navigate([`user-rewards`]);
                  }
                  else {
                    this.closePopup();
                    const selectedLocation = this.store.selectSnapshot(
                      LocationState.getSelectedLocation
                    );
                    if (selectedLocation._id) {
                      // this.router.navigate(['user-rewards']);
                    }
                  }
        
                  //   this.logData({
                  //     "msg": "Successfully added new card",
                  //     "customerEmail": this.paymentDetails.userEmail ? this.paymentDetails.userEmail : '',
                  //     "additionalInfo": {
                  //       "cardResponse": result
                  //     }
                  //   });
                } else {
                  if(this.isMobileView)
                  {
                    this.sweetAlert.toast(
                      'error',
                      result.message ? result.message : 'Failed to add Gift Card'
                    );
        
                  }
                  else{
                    this.sweetAlert.simpleAlert(
                      '',
                      result.message ? result.message : 'Failed to add Gift Card'
                    );
                  }
        
        
                  //   this.logData({
                  //     "msg": "Failed to add Gift Card",
                  //     "customerEmail": this.paymentDetails.userEmail ? this.paymentDetails.userEmail : '',
                  //     "additionalInfo": {
                  //       "cardResponse": result.error
                  //     }
                  //   });
                }
                this.stopLoader(addGiftCardBtn.id);
              });
            }

          }
          break;
        case 'LOYALTYPROGRAM02':
          {
            this.loyaltyService.addGivexCardToProfile(data).subscribe((result) => {
              if (result && result && result && result.cardnumber) {
                // this.getGiftCards();
                this.cancelAddCard();
                this.store.dispatch(new FetchGiftLoyaltyCards());
                if (this.isMobileView && isFromRewardPage == 'False'){
                  this.router.navigate([`payment/gift-card`]);
                } else if(this.isMobileView && isFromRewardPage == 'True'){
                  this.router.navigate([`user-rewards`]);
                }
                else {
                  this.closePopup();
                  const selectedLocation = this.store.selectSnapshot(
                    LocationState.getSelectedLocation
                  );
                  if (selectedLocation._id) {
                    // this.router.navigate(['user-rewards']);
                  }
                }
      
                //   this.logData({
                //     "msg": "Successfully added new card",
                //     "customerEmail": this.paymentDetails.userEmail ? this.paymentDetails.userEmail : '',
                //     "additionalInfo": {
                //       "cardResponse": result
                //     }
                //   });
              } else {
                if(this.isMobileView)
                {
                  this.sweetAlert.toast(
                    'error',
                    result.message ? result.message : 'Failed to add Gift Card'
                  );
      
                }
                else{
                  this.sweetAlert.simpleAlert(
                    '',
                    result.message ? result.message : 'Failed to add Gift Card'
                  );
                }
      
      
                //   this.logData({
                //     "msg": "Failed to add Gift Card",
                //     "customerEmail": this.paymentDetails.userEmail ? this.paymentDetails.userEmail : '',
                //     "additionalInfo": {
                //       "cardResponse": result.error
                //     }
                //   });
              }
              this.stopLoader(addGiftCardBtn.id);
            });


          }
          break;
        default: {
          break;
        }
      }
    } else {
    }

  }
  cancelAddCard() {
    this.newCardNumber = '';
    this.ackrooId = '';
    this.isShowForm = false;
    this.showHelpAckrooId = false;
  }

  checkFormat(event) {
    if (!this.numbers.test(event.target.value)) {
      return (event.target.value = '');
    }
  }

  verifyCardType(number) {
    // visa
    number = number.split(' ').join('');
    let re = new RegExp(paymentCardType.visa.regex);
    if (number.match(re) != null) {
      return paymentCardType.visa;
    }

    // Mastercard
    re = new RegExp(paymentCardType.mastercard.regex);
    if (number.match(re) != null) {
      return paymentCardType.mastercard;
    }

    // AMEX
    re = new RegExp(paymentCardType.amex.regex);
    if (number.match(re) != null) {
      return paymentCardType.amex;
    }

    // Discover
    re = new RegExp(paymentCardType.discover.regex);
    if (number.match(re) != null) {
      return paymentCardType.discover;
    }
    return null;
  }
  clearvalues() {
    this.giftCardForm.controls['cvd'].setValue('');
  }
  cardNumberValidator = (
    control: AbstractControl
  ): { [key: string]: boolean } | null => {
    if (control.value !== '') {
      this.cardType = this.verifyCardType(control.value);
      if (this.cardType) {
        if (!/\s/.test(control.value)) {
          if (control.value.length === this.cardType.minlength - 3) {
            return null;
          } else {
            return { cardNumberValidator: false };
          }
        } else if (control.value.length === this.cardType.minlength) {
          return null;
        } else {
          return { cardNumberValidator: false };
        }
      }
    }
    return null;
  };

  sendVerificationEmail(successModal, isFromProfilePage = false) {
    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    if (customer) {
      var data = {
        type: 'email',
        customerId: customer.customerId,
        organizationId: Config.organizationId,
      };
      this.loyaltyService.sendVerificationMail(data).subscribe((result) => {
        if (result && result.success) {
          if (!isFromProfilePage) {
            if (this.isMobileView)
              this.router.navigate([`payment/resend-email`]);
            else
              this.modal.showMediumModalDesktop(successModal, {
                needHeader: true,
                headerTitle: 'Gift/Loyalty Card',
              });
          } else {
            this.enableCardVerification = true;
            this.isCardVerificationEnabled.emit(this.enableCardVerification);
          }
        }
      });
    }
  }
  confirmEmailVerification(successModal, isFromProfilePage = false) {
    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    if (customer) {
      // var data = {
      //   type: "email",
      //   customerId: customer.customerId,
      //   organizationId: this.paymentDetails.organizationId,
      // };
      this.store.dispatch(new FetchGiftLoyaltyCards());
      this.loyaltyService
        .getCards(this.userEmail, Config.organizationId)
        .subscribe((giftCardDetails) => {
          this.card = undefined;
          if (
            giftCardDetails &&
            giftCardDetails.length &&
            giftCardDetails.length >= 0
          ) {
            // this.card = giftCardDetails.find((c) => c.active == true);
            this.registeredUser = true;
            this.emailValidated = true;
            this.isEmailValidated = true;
            this.getTotalCardAmount();
            if (this.isMobileView) {
              if (!isFromProfilePage)
                this.router.navigate([`payment/gift-card`]);
              else {
                this.enableCardBalance = true;
                this.isCardVerificationFinished.emit(this.enableCardBalance);
              }
            } else {
              if (!isFromProfilePage)
                this.modal.showMediumModalDesktop(successModal, {
                  needHeader: true,
                  headerTitle: 'Gift/Loyalty Card',
                });
              else {
                this.enableCardBalance = true;
                this.isCardVerificationFinished.emit(this.enableCardBalance);
              }
            }
          } else if (giftCardDetails.error === 2103) {
            this.registeredUser = false;
            this.emailValidated = false;
            this.isEmailValidated = false;
            if(this.isMobileView)
            {
              this.sweetAlert.toast(
                'error',
                "We noticed you haven’t verified your email yet. Please verify your email to begin using your card."
              );
            }

          } else {
            this.card = undefined;
            this.registeredUser = false;
            this.emailValidated = true;
            this.isEmailValidated = true;
            if (this.isMobileView) {
              if (!isFromProfilePage)
                this.router.navigate([`payment/gift-card`]);
              else {
                this.enableCardBalance = true;
                this.isCardVerificationFinished.emit(this.enableCardBalance);
              }
            } else {
              if (!isFromProfilePage)
                this.modal.showMediumModalDesktop(successModal, {
                  needHeader: true,
                  headerTitle: 'Gift/Loyalty Card',
                });
              else {
                this.enableCardBalance = true;
                this.isCardVerificationFinished.emit(this.enableCardBalance);
              }
            }
          }

          // this.loyaltyService.sendVerificationMail(data).subscribe((result) => {
          //   if (result && result.success) {
          //     if (this.isMobileView)
          //       this.router.navigate([`payment/gift-card`]);
          //     else
          //     this.modal.showMediumModalDesktop(successModal, {
          //       needHeader: true,
          //       headerTitle: 'Gift/Loyalty Card',
          //     });

          //   }
        });
    }
  }
  resendVerificationMail() {
    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    if (customer) {
      var data = {
        type: 'email',
        customerId: customer.customerId,
        organizationId: Config.organizationId,
      };
      this.loyaltyService.sendVerificationMail(data).subscribe((result) => {
        if (result && result.success) {
          let message = this.translate.instant('email-verification-send-success');
          if(this.isMobileView)
          {
            this.sweetAlert.toast(
              'success',
              message
            );

          }
          else{
            this.sweetAlert.simpleAlert(
              '',
              message
            );
          }


        }
      });
    }
  }
  validateTheCustomer(encrypted) {
    const data = { encrypted: encrypted };
    this.loyaltyService.validateEmail(data).subscribe((result) => {
      if (result) {
        this.verified = result;
        if (!this.verified.error) {
          this.countDownAndRedirect();
        }
      }
    });
  }

  countDownAndRedirect() {
    this.timerInstance = setTimeout(() => {
      this.counter--;
      if (this.counter > 0) {
        this.countDownAndRedirect();
      } else {
        clearInterval(this.timerInstance);
        this.router.navigate(['/']);
      }
    }, 1000);
  }
  checkEncryptedAndValidate(encrypted) {
    if (encrypted) {
      this.validateTheCustomer(encrypted);
    }
  }

  closePopup() {
    if (this._modalClose) this._modalClose();
  }

  set modalClose(modalRef: any) {
    this._modalClose = (data) => {
      this.modal.closeComponentModal(modalRef, data);
    };
  }
  
  onChangeCardAmt()
  {
    this.changeCardAmt = true;
  }

  giftAndLoyaltyAmountPresent(card){
    if(parseFloat(card.gift_balance) && parseFloat(card.gift_balance)>0 && parseFloat(card.loyalty_balance) && parseFloat(card.loyalty_balance)>0){
      return true;
    }
    return false;
  }

  getTotalCardAmount(){
    const giftCardBalance = this.card?.gift_balance ? parseFloat(this.card.gift_balance) : 0;
    const loyaltyBalance = this.card?.loyalty_balance ? parseFloat(this.card.loyalty_balance) : 0; 
    let finalAmount=0;
    if(giftCardBalance)
    finalAmount +=giftCardBalance;
    if(loyaltyBalance)
    finalAmount +=loyaltyBalance;
    this.totalBalanceInCards = finalAmount;
  }
  processCardDetails(giftCardDetails) {
    if (
      giftCardDetails &&
      giftCardDetails.length &&
      giftCardDetails.length >= 0 &&
      !giftCardDetails.error
    ) {
      this.giftCards = giftCardDetails;
      this.isRegistered = true;
      this.emailValidated = true;
      // this.card = this.giftCards.find((c) => c.active == true);
      this.getTotalCardAmount();
    } else if (
      giftCardDetails &&
      giftCardDetails.error &&
      giftCardDetails.error === 2103
    ) {
      this.emailValidated = false;
      this.giftCards = undefined;
      this.isRegistered = false;
    } else {
      this.emailValidated = true;
      this.isRegistered = false;
    }
    this.getTotalAmountInAllCards();
  }
}

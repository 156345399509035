<div class="pay-details-container">
  <div class="pay-type" *ngIf="paymentState.ackrooPaymentDetails">
    <span aria-label="credit/debit card">{{
      'Gift/Loyalty Card' | translate
    }}</span
    ><span class="change-pay-type" (click)="changeView()" data-qa="change">{{
      'change' | translate
    }}</span>
  </div>
  <div class="pay-type" *ngIf="paymentState.mealPaymentDetails">
    <span aria-label="credit/debit card">{{
      'meal-title' | translate
    }}</span
    ><span class="change-pay-type" (click)="changeView()" data-qa="change">{{
      'change' | translate
    }}</span>
  </div>

  <div class="pay-type" *ngIf="(!paymentState.ackrooPaymentDetails && !paymentState.mealPaymentDetails && !isPayLocation)">
    <span aria-label="credit/debit card">{{
      'credit-debit-card' | translate
    }}</span
    ><span class="change-pay-type" (click)="changeView()" data-qa="change">{{
      'change' | translate
    }}</span>
  </div>

  <div class="pay-type" *ngIf="isPayLocation">
    <span aria-label="credit/debit card">{{
      'pay-at-location' | translate
    }}</span
    ><span class="change-pay-type" (click)="changeView()" data-qa="change">{{
      'change' | translate
    }}</span>
  </div>
  <ng-container *ngIf="paymentState && paymentState.selectedCard">
    <span class="payee-name-container"
      ><p class="header" aria-label="name on card">{{ 'name' | translate }}</p>
      <p class="name">
        {{
          paymentState
            ? paymentState.selectedCard
              ? paymentState.selectedCard.name
              : paymentState.newCard.name
            : ''
        }}
      </p></span
    >
    <span class="payee-number-container"
      ><p class="header" aria-label="card number">
        {{ 'card-num' | translate }}
      </p>
      <p class="name">
        {{
          paymentState.selectedCard
            ? '**** **** **** ' +
              paymentState.selectedCard.number.slice(
                paymentState.selectedCard.number.length - 4
              )
            : '**** **** **** ' +
              paymentState.newCard.number.slice(
                paymentState.newCard.number.length - 4
              )
        }}
      </p></span
    ></ng-container
  >
</div>

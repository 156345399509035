import { Component, OnInit, Input } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { ModalService } from '../modal/modal.service';
import {SharedService} from '@moduurnv2/libs-orderingapp';
import { MenuBaseComponent } from '@moduurnv2/libs-orderingapp/src/features/ui/base/menu-base.component';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'moduurnv2-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent extends MenuBaseComponent implements OnInit {
  constructor(router: Router,store: Store,route:ActivatedRoute,modal:ModalService,sharedService:SharedService,translate:TranslateService) {
    super(router, store, route,modal,sharedService,translate); 
  }

}

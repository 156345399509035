import { Component } from '@angular/core';
import { AddressListBaseComponent } from '@moduurnv2/libs-orderingapp/src/features';
// import { AddressMapDesktopComponent } from '../../components/address-map-desktop/address-map.component-desktop';
// import { AddressDetailsDesktopComponent } from '../address-details-desktop/address-details-desktop.component';
import { ModalService } from './../../../modal/modal.service';
import { Store, Select } from '@ngxs/store';
import { SharedService,AddressComponentEnums } from '@moduurnv2/libs-orderingapp/src/core';
import { TranslateService } from '@ngx-translate/core';
import { AddressWrapperComponent } from './../../../address-wrapper/address-wrapper.component';
@Component({
  selector: 'moduurnv2-address-book-desktop',
  templateUrl: './address-book-desktop.component.html',
  styleUrls: ['./address-book-desktop.component.scss'],
})
export class AddressBookComponent extends AddressListBaseComponent {
  constructor(
    store: Store,
    translate: TranslateService,
    sharedService: SharedService,
    private modal:ModalService
  ) {
    super(store,translate,sharedService)
  }
  // _modalClose: any = null;
  closePopup() {
    // if (this._modalClose) this._modalClose();
    this.closePage();
  }

  // set modalClose(modalRef: any) {
  //   this._modalClose = () => {
  //     this.modal.closeModal(modalRef);
  //   };
  // }
  // openMapPopup() {
  //   // this.openMap();
  //   // this.modal.showMediumModalDesktop(AddressDetailsDesktopComponent);
  //   this.openMapPage();
  // }

  //DONT MOVE TO BASE
  addAddress(){
    localStorage.removeItem('tempSelectedAdrsLocation');
    this.modal.showMediumModalDesktop(AddressWrapperComponent,{data:{isAddressBook:true,pageToShow:AddressComponentEnums.ADDRESS_DETAILS}});
  }
  checkAdrNotEmpty(){
    let addrListLength = this.adrsList.length;
    return addrListLength>0 ? true :false
  }
}

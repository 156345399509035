// angular
import { Injectable } from '@angular/core';
import {
  setCategories,
  enable,
  categories,
  messageType,
  disable,
  isEnabled,
} from "@nativescript/core/trace";
import { isUndefined } from "@nativescript/core/utils/types";
import { SharedService } from './shared.service';
export interface IDebug {
  LEVEL_1: boolean;
  LEVEL_2: boolean;
  LEVEL_3: boolean;
  LEVEL_4: boolean;
  LEVEL_5: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class LogService {
  constructor(private sharedService: SharedService) {
  }
  isEnabled = isEnabled();
  public static DEBUG: IDebug = {
    LEVEL_1: false, // .warn only
    LEVEL_2: false, // .error only
    LEVEL_3: false, // .log + all the above
    LEVEL_4: false, // .log + all the above + info
    LEVEL_5: false // just info (excluding all else)
  };

  // info (extra messages like analytics)
  // use LEVEL_5 to see only these
  public info(msg: string, options = {}, category?: 'GENERAL' | 'UNHANDLED' | 'UNHANDLED_SERVER') {
    if (LogService.DEBUG.LEVEL_5 || LogService.DEBUG.LEVEL_4) {
      this.logData(msg, messageType.info, options, category);
    }
  }

  // debug (standard output)
  public debug(msg: string, options = {}, category?: 'GENERAL' | 'UNHANDLED' | 'UNHANDLED_SERVER') {
    if (LogService.DEBUG.LEVEL_4 || LogService.DEBUG.LEVEL_3) {
      // NOTE : UNCOMMENT BELOW LINE TO GET DEBUG LOGS DURING DEVELOPMENT . DONT UNCOMMENT AND COMMIT
      //  this.logData(msg, messageType.log, options, category);
    }
  }

  // error
  public error(msg:string, options = {}, category?: 'GENERAL' | 'UNHANDLED' | 'UNHANDLED_SERVER') {
    if (
      LogService.DEBUG.LEVEL_4 ||
      LogService.DEBUG.LEVEL_3 ||
      LogService.DEBUG.LEVEL_2
    ) {
      this.logData(msg, messageType.error, options, category);
    }
  }

  // warn
  public warn(msg: string, options = {}, category?: 'GENERAL' | 'UNHANDLED' | 'UNHANDLED_SERVER') {
    if (
      LogService.DEBUG.LEVEL_4 ||
      LogService.DEBUG.LEVEL_3 ||
      LogService.DEBUG.LEVEL_1
    ) {
      this.logData(msg, messageType.warn, options, category);
    }
  }

  public logData(message: string, type: number, options, category: 'GENERAL' | 'UNHANDLED' | 'UNHANDLED_SERVER' = 'GENERAL') {
    let msgType = isUndefined(type) ? messageType.log : type;
    let loggingData;
    switch (msgType) {
      case messageType.log:
        loggingData = {
          "messageType": "log",
          "message": message,
          "options": options,
          "category": category
        };
        break;
      case messageType.info:
        loggingData = {
          "messageType": "info",
          "message": message,
          "options": options,
          "category": category
        };
        break;
      case messageType.warn:
        loggingData = {
          "messageType": "warn",
          "message": message,
          "options": options,
          "category": category
        };
        break;
      case messageType.error:
        loggingData = {
          "messageType": "error",
          "message": message,
          "options": options,
          "category": category
        };
        break;
      default:
        loggingData = {
          "messageType": "log",
          "message": message,
          "options": options,
          "category": category
        };
        break;
    }
  }
}

import {
  BaseComponent,
  ChangeCategory,
  MenuListState,
  CategoryList,
  LocationState,
  ClearComboCart,
  AnalyticsTrackingType,
  CategorySelectionAnalytics,
  setAnalyticsData,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

export class HorizontalScrollableMenuBaseComponent extends BaseComponent {
  @Select(MenuListState.getMenuListCategory) selectedCategory$: Observable<
    CategoryList
  >;
  constructor(
    public store: Store,
    public router: Router,
    public route: ActivatedRoute
  ) {
    super();
  }

  selectCategory(category) {
    const selectedCategory = this.store.selectSnapshot(
      MenuListState.getMenuListCategory
    );
    this.trackAnalyticsCategorySelection(category)
    this.store.dispatch(new ChangeCategory(category));
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if (selectedLocation._id) {
      this.store.dispatch(new ClearComboCart());
      this.router.navigate([`/locations/${selectedLocation._id}/menu/${category._id}`]);
    // this.router.navigate([`../${category._id}`], { relativeTo: this.route });
    }
  }

  trackAnalyticsCategorySelection(category){
    if(category){
      const categorySelectionAnalytics: CategorySelectionAnalytics = {
        _id: category?._id,
        name: category?.menuTitle,
        analyticsTrackingType: AnalyticsTrackingType.CATEGORY_SELECTION,
      };
    this.store.dispatch(new setAnalyticsData(categorySelectionAnalytics));}
  }

}

import { Component, OnInit, Input } from '@angular/core';
import { AddressCardBaseComponent } from '@moduurnv2/libs-orderingapp';
import {AddressWrapperComponent} from '../../../address-wrapper/address-wrapper.component'
import {AuthState,AddressState,ChangeTempToDeliveryAddress,AddressComponentEnums,ClearEnteredAddress} from '@moduurnv2/libs-orderingapp/src/core';

@Component({
  selector: 'moduurnv2-address-card',
  templateUrl: './address-card.component.html',
  styleUrls: ['./address-card.component.scss']
})
export class AddressCardComponent extends AddressCardBaseComponent {

   //DONT MOVE THIS TO BASE( CIRCULAR DEPENDENCY CAN OCCUR)
   editAdrs(event,_id) {
    event.stopPropagation();
    event.preventDefault();
  //  this.store.dispatch(new ChangeadrsDetailsPopupStatus(true));
  //  this.store.dispatch(new ChangeMapPopupStatus(true));
    this.store.dispatch(new ClearEnteredAddress());
    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    let locations =[];
    if (customer?.isLoggedIn) {
      locations = this.store.selectSnapshot(AddressState.getAddressList);
      if(locations){
          let location = locations.find((adrs) => adrs._id == _id);
          if(location)
          localStorage.setItem(
            'tempSelectedAdrsLocation',
            JSON.stringify(location)
          );
      }
    } else {
      locations = JSON.parse(localStorage.getItem('locationList'));
      if(locations){
        let location = locations.find((adrs) => adrs._id == _id);
        if(location){
        localStorage.setItem(
          'tempSelectedAdrsLocation',
          JSON.stringify(location)
        );
        this.store.dispatch(new ChangeTempToDeliveryAddress(location));
        }
      }
    }
    if(this.isAddressBook){
      this.modal.showMediumModalDesktop(AddressWrapperComponent,{data:{isAddressBook:true,pageToShow:AddressComponentEnums.ADDRESS_DETAILS}})
    } else {
      this.openDetails();
    }
  }
}


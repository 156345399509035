import { Router } from '@angular/router';
import { OnInit, EventEmitter, Output } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';

import {
  BaseComponent,
  environment,
  Regex,
  setUserDetailsOnLogin,
  ClearLoginDetails,
  AuthEmailState,
  AuthEmailLoginResponse,
  setExistingLoginData,
  CartState,
  LocationState,
  SharedService,
  ClearExistingLoginData,
  FetchSavedCards,
  AuthState,
  FetchGiftLoyaltyCards,
  WhitelabelState,
  GiftLoyaltyMethodState,
  GiftCard,
  SetLoginFrom,
  SetEmptyLoginDetails,
  FetchLocationList,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import * as CryptoJS from 'crypto-js';
import { Subscription } from 'rxjs';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { ModalService } from '../../../../../../apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { takeUntil } from 'rxjs/operators';
import { AnalyticsHandler } from '@moduurnv2/core/services';

interface Credential {
  email: string;
  password: string;
}

export class EmailLoginBaseComponent extends BaseComponent implements OnInit {
  isRememberMe: Boolean = false;
  loginLeftArrowIcon: string =
    environment.image_basepath + environment.images.arrow_left_icon;
  emailRegex = Regex.email;
  userName: string = '';
  userLoggedIn: boolean = false;
  decryptedUserData;
  cipher = Config.cipher;
  authEmailSubscription: Subscription;
  existingDataSubscription: Subscription;
  formSubmitted: boolean = false;
  errorInLogin: string = '';
  _modalClose: any = null;
  loginSuccessModal: any = undefined;
  emailVerificationModal: any = undefined;
  loginSuccessModalTitle: string = undefined;
  loginFrom: any = undefined;
  isMobileView: boolean ;
  loginForm = new FormGroup({
    email: new FormControl(
      '',
      Validators.compose([
        Validators.required,
        this._shared.emailpatternValidator(),
      ])
    ),
    password: new FormControl('', Validators.required),
    isRememberMe: new FormControl(this.isRememberMe),
  });
  credential: Credential = {
    email: '',
    password: '',
  };
  existinguserData;
  @Output() modalValue: EventEmitter<any> = new EventEmitter<any>();
  @Select(AuthEmailState.getFullLoginDetails) authEmail$: Observable<any>;
  @Select(AuthEmailState.getLoginData) loginData$: Observable<string>;
  @Select(GiftLoyaltyMethodState.getSavedCards) GiftCards$: Observable<
    GiftCard[]
  >;
  giftCard: any = undefined;
  emailValidated: boolean = false;
  registeredUser: boolean = false;
  constructor(
    public store: Store,
    public router: Router,
    private _shared: SharedService,
    public modal: ModalService,
    public analyticsHandler: AnalyticsHandler
  ) {
    super();
    this.existingDataSubscription = this.loginData$.subscribe((res) => {
      this.existinguserData = res;
      if (this.existinguserData) {
        this.populateRememberedData(this.existinguserData);
      }
    });
    this.GiftCards$.pipe(takeUntil(this.destroy$)).subscribe((giftCards) => {
      let errorResponse = this.store.selectSnapshot(
        GiftLoyaltyMethodState.getErrorResponse
      );
      if (giftCards) {
        this.giftCard = undefined;
        if (giftCards.length && giftCards.length >= 0) {
          this.giftCard = giftCards.find((c) => c.active == true);
          this.registeredUser = true;
          this.emailValidated = true;
          this.loginSuccessAction();
        } else if (
          errorResponse &&
          errorResponse.error &&
          errorResponse.error === 2103
        ) {
          this.emailValidated = false;
          this.giftCard = undefined;
          this.registeredUser = true;
          this.loginSuccessAction();
        } else {
          this.giftCard = undefined;
          this.registeredUser = false;
          this.giftCard = undefined;
        }
      }
    });
  }

  populateRememberedData(loggedInData) {
    let loginData = this.decryptUserCredentials(loggedInData);
    this.loginForm.controls['email'].setValue(loginData.username);
    this.loginForm.controls['password'].setValue(loginData.password);
    this.loginForm.controls['isRememberMe'].setValue(true);
  }

  ngOnInit() {
    this._shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
  }

  ngOnDestroy() {
    if (this.authEmailSubscription) this.authEmailSubscription.unsubscribe();
    if (this.existingDataSubscription)
      this.existingDataSubscription.unsubscribe();
  }

  get loginFormControl() {
    return this.loginForm.controls;
  }

  loginUsingEmail(loginBtn) {
    let forcedLogin = this.store.selectSnapshot(WhitelabelState.getOther)
      .isForceLogin;
    const loginFrom = this.store.selectSnapshot(AuthState.getLoginFrom);
    this.formSubmitted = true;
    this.errorInLogin = '';
    this.store.dispatch(new SetEmptyLoginDetails());
    if (this.loginForm.valid) {
      this.credential = this.loginForm.value;
      const loginData: any = Object.assign({}, this.credential);
      if (this.loginForm.value.isRememberMe) {
        this.saveUserCredentials(
          this.credential.email,
          this.credential.password
        );
      } else {
        this.store.dispatch(new ClearExistingLoginData());
      }
      loginData.username = loginData.email.toLowerCase();
      const selectedCart = this.store.selectSnapshot(CartState.getCart);
      if (selectedCart && selectedCart.cart_id) {
        loginData.cart_id = selectedCart.cart_id;
      }
      this.startLoader(loginBtn.id,loginBtn.innerText);
      this.store.dispatch(new setUserDetailsOnLogin(loginData));
      this.authEmailSubscription = this.authEmail$.subscribe(
        (response) => {
          if (response) {
            if (
              response.status !== 401 &&
              response.status !== 409 &&
              response.status !== 400 &&
              response.status !== 404
            ) {
              this.userName = '';
              if (response.fname || response.lname) {
                this.userName += response.fname ? response.fname : '';
                this.userName += response.lname ? ' ' + response.lname : '';
              } else {
                this.userName = response.username;
              }
              this.userLoggedIn = response.isLoggedIn;
              if(response.isLoggedIn)  this.store.dispatch(new FetchLocationList());
              response.username = this.credential.email;
              this.store.dispatch(new FetchGiftLoyaltyCards());
              const selectedLocation = this.store.selectSnapshot(
                LocationState.getSelectedLocation
              );
              if (!loginFrom) {
                this.modal.closeAllModals();
                // this.store.dispatch(new ClearSelectedAddress());
                if (forcedLogin) {
                  this.router.navigate([`/locations`]);
                } else if (this.isMobileView) {
                  if(selectedLocation?._id){
                  // this.router.navigate([
                  //   `/locations/${selectedLocation._id}/home`,
                  // ]);
                // } else {
                  
                    //after force login page opened during order placing and on login redirect to review page in desktop
                    this.router.navigate([
                      `/locations/${selectedLocation._id}/order`,
                    ]);
                  }else{
                    this.router.navigate([`/locations`]);
                  }
                }
              } else if(loginFrom == 'order' && !this.isMobileView){
                if(selectedLocation?._id){
                    //after force login page opened during order placing and on login redirect to review page in desktop
                    this.router.navigate([
                      `/locations/${selectedLocation._id}/order`,
                    ]);
                }
              } else {
                if (this.isMobileView) this.modalValue.emit('CLOSE');
                else this.modal.closeAllModals();
                // this.loginSuccessAction(loginFrom);
              }
              this.loginFrom = loginFrom;
              this.analyticsHandler.setEvents("login", "customer", "login", response.username, response.username);
            } else if(response['status']==401 || response['status']==400 ) {
              if(response['message'])
              this.errorInLogin = response['message'];
              else
              this.errorInLogin = 'Email ID/Password is incorrect';
            } else {
              this.errorInLogin = response['msg'];
            }
            if (response.status === 200) {
              let other = this.store.selectSnapshot(WhitelabelState.getOther);
              if (
                other &&
                other.paymentGateway &&
                other.paymentGateway.textCode 
              ) {
                this.store.dispatch(new FetchSavedCards(other.paymentGateway.textCode));
              }
            }
            
            this.stopLoader(loginBtn.id);
          }
        },
        (error) => {
          this.errorInLogin = error['msg'];
          this.stopLoader(loginBtn.id);
        }
      );
    }
  }

  saveUserCredentials(username, password) {
    let userdetails = {
      username: username,
      password: password,
    };
    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(userdetails),
      this.cipher
    );
    this.store.dispatch(new setExistingLoginData(ciphertext.toString()));
  }

  decryptUserCredentials(res) {
    let userDetailsStored = res;
    let bytes = CryptoJS.AES.decrypt(userDetailsStored, this.cipher);
    let userDetailsStr = bytes.toString(CryptoJS.enc.Utf8);
    let userDetails = JSON.parse(userDetailsStr);
    return userDetails;
  }

  //to be added to user profiel page
  userLogout() {
    this.store.dispatch(new ClearLoginDetails());
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );

   (selectedLocation?._id) ? this.router.navigate([`/locations/${selectedLocation._id}/home`]) : this.router.navigate([`/locations`]);;
  }
  closLogin() {
    this.modal.closeAllModals();
    if (!this.isMobileView) this.modalValue.emit('CLOSE');
  }
  goBack() {
    this.modalValue.emit('CLOSE');
    this.closLogin();
  }
  setloginSuccessModal(
    loginSuccessModal,
    title,
    emailVerificationModal = undefined
  ) {
    this.loginSuccessModal = loginSuccessModal;
    this.loginSuccessModalTitle = title;
    this.emailVerificationModal = emailVerificationModal;
    if (!loginSuccessModal) {
      this.loginFrom = undefined;
      const previousPage = this.store.selectSnapshot(AuthState.getLoginFrom);
      if(previousPage != 'order')
      this.store.dispatch(new SetLoginFrom(undefined));
    }
  }

  isGuestUser() {
    let userData = this.store.selectSnapshot(AuthEmailState.getUserDetails);
    let isGuest = userData && userData.user_id ? false : true;
    return isGuest;
  }

  loginSuccessAction() {
    if (!this.isGuestUser()) {
      if (this.loginFrom == 'giftAndLoyalty') {
        // this.store.dispatch(new FetchGiftLoyaltyCards());
        if (this.isMobileView) {
          if (this.registeredUser && !this.emailValidated)
            this.router.navigate([`/payment/resend-email`]);
          else this.router.navigate([`/payment/gift-card`]);
        } else if (this.loginSuccessModal) {
          if (
            this.emailVerificationModal &&
            this.registeredUser &&
            !this.emailValidated
          ) {
            this.modal.showMediumModalDesktop(this.emailVerificationModal, {
              needHeader: true,
              headerTitle: this.loginSuccessModalTitle,
            });
          } else
            this.modal.showMediumModalDesktop(this.loginSuccessModal, {
              needHeader: true,
              headerTitle: this.loginSuccessModalTitle,
            });
        }
        this.store.dispatch(new SetLoginFrom(undefined));
        this.loginFrom = undefined;
      }
    }
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { SharedService} from '@moduurnv2/libs-orderingapp';
import { MenuBaseComponent } from '@moduurnv2/libs-orderingapp/src/features/ui/base/menu-base.component';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'moduurnv2-menu-desktop',
  templateUrl: './menu-desktop.component.html',
  styleUrls: ['./menu-desktop.component.scss'],
})
export class MenuDesktopComponent extends MenuBaseComponent {
  @Input() topMargin: string = '0em';
  @Input() dynamincMenuItems: any = [];
  constructor(router: Router, store: Store, route: ActivatedRoute,modal:ModalService,sharedService:SharedService,translate:TranslateService) {
    super(router, store, route,modal,sharedService,translate);
  }
}

import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import {
  AuthState,
  LocationState
} from '@moduurnv2/libs-orderingapp/src/core';
import { Store, Select } from '@ngxs/store';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';

@Injectable()
export class GuestUserGuard implements CanActivate {
  constructor(private router: Router,private store:Store) { }
  canActivate() {
    const customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    if(customer && customer.isLoggedIn){
      this.router.navigate([`/user-profile`]);
      return false;
    } else {
      return true;
    }
  }
}
<!-- Modifier wrapper -->
<div class="modifier-list">
  <!-- Sub modifier items template -->
  <ng-container
    *ngIf="modifierItem?.items?.length > 0; else modifierItemTemplate"
  >
    <!-- Modifier header -->
    <div
      class="sub-modifier-header"
      [ngClass]="{ 'option-error': headerErrors(modifierItem.valid) }"
    >
      {{ modifierItem.name | formattedLanguage: modifierItem.translation }}
      <span
        class="choice option-requirements"
        *ngIf="choiceText(modifierItem)"
        >{{ choiceText(modifierItem) }}</span
      >
    </div>

    <!-- Modifier List -->
    <div class="sub-modifier-list">
      <!-- Modifier item -->

      <ng-container *ngFor="let subItem of modifierItem.items">
        <div class="sub-modifier-item">
          <label
            [for]="subItem._id"
            [attr.data-qa]="subItem + '_label'"
            class="portion-label"
          >
            <span>{{
              subItem.name | formattedLanguage: subItem.translation
            }}</span>
            <span>
              <ng-container *ngIf="subItem.costDifference">
                {{ subItem.costDifference | formattedCurrency }}
              </ng-container>
            </span>
          </label>
          <ng-container *ngIf="!modifier.isCounter">
            <div
              class="options"
              *ngIf="!checkInput(modifier, modifierItem); else radioInput"
            >
              <label class="options-label">
                <input
                  type="checkbox"
                  class="custom-check"
                  [id]="subItem._id"
                  [attr.name]="modifierItem._id"
                  [value]="subItem._id"
                  [checked]="subItem.selected"
                  (change)="
                    subModifierItemChange($event, subItem, modifierItem)
                  "
                  [attr.data-qa]="subItem + '_input'"
                  [ngClass]="{ selected: subItem.selected }"
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </ng-container>
          <div *ngIf="modifier.isCounter" class="options">
            <div class="counter">
              <button
                (click)="
                  decreaseCounter(subItem, modifierItem, 'subModifierItem')
                "
                [ngClass]="{
                  disabled:
                    (subItem.count == subItem.minLimit &&
                      subItem.minLimit != 0) ||
                    subItem.count == 0
                }"
                data-qa="modifier-count-dec"
              >
                -
              </button>
              <div class="count" data-qa="modifier-count">
                {{ subItem.count }}
              </div>
              <button
                (click)="
                  increaseCounter(subItem, modifierItem, 'subModifierItem')
                "
                [ngClass]="{
                  disabled:
                    subItem.count == subItem.maxLimit && subItem.maxLimit != 0
                }"
                data-qa="modifier-count-inc"
              >
                +
              </button>
            </div>
          </div>
          <ng-template #radioInput>
            <label class="container">
              <input
                class="custom-check"
                type="radio"
                [id]="subItem._id"
                [attr.name]="modifierItem._id"
                [value]="subItem._id"
                [checked]="subItem.selected"
                (change)="subModifierItemChange($event, subItem, modifierItem)"
                [attr.data-qa]="subItem + '_input'"
                [ngClass]="{ selected: subItem.selected }"
              />
              <span class="checkmark"></span>
            </label>
            <!-- <input
              class="custom-check"
              type="radio"
              [id]="subItem._id"
              [attr.name]="modifierItem._id"
              [value]="subItem._id"
              [(ngModel)]="subItem.selected"
              [checked]="subItem.selected"
              (change)="subModifierItemChange($event, subItem, modifierItem)"
              [attr.data-qa]="subItem + '_input'"
            /> -->
          </ng-template>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <!-- Single Modifier item template -->
  <ng-template #modifierItemTemplate>
    <div class="singe-modifier">
      <!-- Modifier details -->
      <label
        [attr.for]="modifierItem._id"
        class="modifier-label"
        [attr.data-qa]="modifierItem + '_label'"
      >
        <!-- Modifier name -->
        <span>{{
          modifierItem.name | formattedLanguage: modifierItem.translation
        }}</span>

        <!-- Modifier price -->
        <span>
          <ng-container *ngIf="modifierItem.costDifference">
            {{ modifierItem.costDifference | formattedCurrency }}
          </ng-container>
        </span>
      </label>

      <!-- Modifier input  -->
      <ng-container *ngIf="!modifier.isCounter">
        <div class="options" *ngIf="!checkInput(modifier); else radioInput">
          <label class="options-label">
            <input
              type="checkbox"
              class="custom-check"
              [id]="modifierItem._id"
              [attr.name]="modifier._id"
              [value]="modifierItem._id"
              [checked]="modifierItem.selected"
              (change)="modifierItemChange($event, modifierItem, modifier)"
              [attr.data-qa]="modifierItem + '_input'"
              [ngClass]="{ selected: modifierItem.selected }"
            />
            <!-- <input
            type="checkbox"
            class="custom-check"
            [id]="modifierItem._id"
            [attr.name]="modifier._id"
            [value]="modifierItem._id"
            [(ngModel)]="modifierItem.selected"
            [checked]="modifierItem.selected"
            (change)="modifierItemChange($event, modifierItem, modifier)"
            [attr.data-qa]="modifierItem + '_input'"
          /> -->
            <span class="checkmark"></span>
          </label>
        </div>
      </ng-container>
      <div class="options" *ngIf="modifier.isCounter">
        <div class="counter">
          <button
            (click)="decreaseCounter(modifierItem, modifier, 'item')"
            [ngClass]="{
              disabled:
                (modifierItem.count == modifierItem.minLimit &&
                  modifierItem.minLimit != 0) ||
                modifierItem.count == 0
            }"
            data-qa="modifier-count-dec"
          >
            -
          </button>
          <div class="count" data-qa="modifier-count">
            {{ modifierItem.count }}
          </div>
          <button
            (click)="increaseCounter(modifierItem, modifier, 'item')"
            [ngClass]="{
              disabled:
                modifierItem.count == modifierItem.maxLimit &&
                modifierItem.maxLimit != 0
            }"
            data-qa="modifier-count-inc"
          >
            +
          </button>
        </div>
      </div>
      <ng-template #radioInput>
        <label class="container">
          <input
            class="custom-check"
            type="radio"
            [id]="modifierItem._id"
            [attr.name]="modifier._id"
            [value]="modifierItem._id"
            [checked]="modifierItem.selected"
            (change)="modifierItemChange($event, modifierItem, modifier)"
            [attr.data-qa]="modifierItem + '_input'"
            [ngClass]="{ selected: modifierItem.selected }"
          />
          <span class="checkmark"></span>
        </label>
        <!-- <input
          class="custom-check"
          type="radio"
          [id]="modifierItem._id"
          [attr.name]="modifier._id"
          [value]="modifierItem._id"
          [checked]="modifierItem.selected"
          (change)="modifierItemChange($event, modifierItem, modifier)"
          [attr.data-qa]="modifierItem + '_input'"
        /> -->
      </ng-template>
    </div>
  </ng-template>
</div>

import { HomeModel } from '../models';

export class FetchHomeData {
    static readonly type = '[HOME] FetchHomeData';
    
    constructor() {}
}

export class ReOrderItems {
    static readonly type = '[HOME] ReOrderItems';
    
    constructor(public payload: number) {}
}

export class SetHomeData {
    static readonly type = '[HOME] SetHomeData';

    constructor(public payload: HomeModel) {}
}

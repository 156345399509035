import { Router } from '@angular/router';
import {
  AuthState,
  BaseComponent,
  Deals,
  DealsState,
  RemoveDeal,
  SelectDeal,
  SetLoginFrom,
  SharedService,
  CartState,
  Whitelabel,
  WhitelabelState,
  AnalyticsTrackingType,
  DealSelectionAnalytics,
  setAnalyticsData,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Select, Store } from '@ngxs/store';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from './../../../core/environments/environment';
import { AnalyticsHandler } from '@moduurnv2/core/services';
export class DealsBaseComponent extends BaseComponent {
  @Select(DealsState.getSelectedDeal) selectedDeal$: Observable<Deals>;
  @Select(DealsState.getQueueDeal) queuedDeal$: Observable<Deals>;
  @Select(WhitelabelState.getWhitelabel) whiteLabel$: Observable<Whitelabel>;
  selectedDeal: Deals;
  queuedDeal: Deals;
  checkDeal: Deals;
  whiteLabel: Whitelabel;
  isMobileView: boolean;
  isShowAuth: boolean = false;
  pageToShow: string;
  path: any;
  imageBasePath: any = environment.imagebaseurl;
  public bannerImage:string;
  loading: boolean = false;
  isEmptyDealScreen:boolean = false;

  constructor(
    public shared: SharedService,
    public store: Store,
    public modal: ModalService,
    public router: Router,
    public analyticsHandler: AnalyticsHandler,
  ) {
    super();
     this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
    this.path = router.url.split('/');
    let cart = store.selectSnapshot(CartState.getCart);
  }

  ngOnInit() {
    this.selectedDeal$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedDeal) => {
        if (selectedDeal) {
          this.selectedDeal = selectedDeal;
          this.checkDeal = this.selectedDeal;
        } else {
          this.selectedDeal = null;
        }
        if (!this.selectedDeal && !this.queuedDeal) {
          this.checkDeal = null;
        }
      });
    this.queuedDeal$.pipe(takeUntil(this.destroy$)).subscribe((queuedDeal) => {
      if (queuedDeal) {
        this.queuedDeal = queuedDeal;
        this.checkDeal = this.queuedDeal;
      } else {
        this.queuedDeal = null;
      }
      if (!this.selectedDeal && !this.queuedDeal) {
        this.checkDeal = null;
      }
    });
    this.whiteLabel$.pipe(takeUntil(this.destroy$)).subscribe((whiteLabel) => {
      // console.log(whiteLabel);
      if (whiteLabel) {
        this.whiteLabel = whiteLabel;
        this.bannerImage = environment.image_basepath + 'deals-banner.png';

        // console.log(this.whiteLabel);
        if (this.whiteLabel && this.whiteLabel.dealsPage) {
         if(this.isMobileView)
          this.bannerImage = this.whiteLabel.dealsPage.phone
          ? this.imageBasePath + this.whiteLabel.dealsPage.phone
          : null;
          else
          this.bannerImage = this.whiteLabel.dealsPage.desktop
          ? this.imageBasePath + this.whiteLabel.dealsPage.desktop
          : null
          // console.log(this.bannerImage);
        }
      }
    });
  }

  continue() {
    const auth = this.store.selectSnapshot(AuthState);
    if (auth?.loggedInUser?.isLoggedIn) {
      this.isShowAuth = false;
    } else {
      this.isShowAuth = true;
      this.showLogin();
    }
  }
  showLogin() {
    this.store.dispatch(new SetLoginFrom(this.path[this.path.length - 1]));
    if (this.isMobileView) {
      this.router.navigate(['/login']);
    }
  }
  showEmailAuthModal($event) {
    if ($event == false) {
      this.pageToShow = undefined;
      this.isShowAuth = false;
    }
  }

  selectDeal(deal: Deals) {
    this.trackAnalyticsDealsApply(deal)
    let cart = this.store.selectSnapshot(CartState.getCart);
    this.loading = true;
    if(cart && cart.cart_id)
    this.analyticsHandler.setEvents("deals_apply", "deals", "apply",  cart.cart_id, deal);
    this.store.dispatch(new SelectDeal(deal));
    setTimeout(() => {
      this.loading = false;
    }, 1500);
  }

  trackAnalyticsDealsApply(deal){
    if(deal){
      const dealSelectionAnalytics: DealSelectionAnalytics = {
        _id: deal?._id,
        name: deal?.title,
        analyticsTrackingType: AnalyticsTrackingType.DEAL_APPLY,
        discountAmount:deal?.details[0],
        discount:deal?.promoCode,
      };
    this.store.dispatch(new setAnalyticsData(dealSelectionAnalytics));}
  }

  removeDeal() {
    this.loading = true;
    let cart = this.store.selectSnapshot(CartState.getCart);
    this.selectedDeal = this.store.selectSnapshot(DealsState.getSelectedDeal);
    if(cart?.cart_id)
    this.analyticsHandler.setEvents("deals_remove", "deals", "remove",  cart.cart_id, this.selectedDeal);
    this.store.dispatch(new RemoveDeal());
    setTimeout(() => {
      this.loading = false;
    }, 1500);
  }
  counter(i: number) {
    return new Array(i);
  }

  checkIsEmptyDealScreen(allDeals){
    if(allDeals.find(dl => dl['show'] == true))
    this.isEmptyDealScreen = false;
    else
    this.isEmptyDealScreen = true;
  }
}

import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { SetPortion } from '@moduurnv2/libs-orderingapp/src';

@Component({
  selector: 'moduurnv2-portion-list',
  templateUrl: 'portion-list.component.html',
  styleUrls: ['portion-list.component.scss'],
})
export class PortionListComponent {
  @Input('portions') portions;
  @Input('isLoyaltyEnabled') isLoyaltyEnabled;

  constructor(private store: Store) {}
  ngAfterViewInit() {
    // this.portions.forEach((portion) => {
    //   if (portion.isDefault) this.store.dispatch(new SetPortion(portion._id));
    // });
  }
  portionChange(event, portion) {
    this.store.dispatch(new SetPortion(portion._id));
  }

  get selectedPortion() {
    const foundSelected = this.portions.find((portion) => portion.selected);
    return foundSelected._id;
  }
}

import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Store, Select } from '@ngxs/store';
@Injectable()
export class BrowserGuardService implements CanActivate {
  constructor(
    public store: Store,
    public router: Router
  ) {}
  canActivate(): boolean {
    var browser = (function (agent) {
        switch (true) {
            //case agent.indexOf("edge") > -1: return "edge";//edge
            case agent.indexOf("edg/") > -1: return "chromium based edge (dev or canary)"; // Match also / to avoid matching for the older Edge
           // case agent.indexOf("opr") > -1 && !!window.opr: return "opera";
            //case agent.indexOf("chrome") > -1 && !!window.chrome: return "chrome";
            case agent.indexOf("trident") > -1: return "ie";
            //case agent.indexOf("firefox") > -1: return "firefox";
            //case agent.indexOf("safari") > -1: return "safari";
            default: return "other";
        }
    })(window.navigator.userAgent.toLowerCase());
    if(browser=="ie")
    {
        var ua = window.navigator.userAgent; var msie = ua.indexOf("MSIE "); 
        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) // If Internet Explorer, return version number 
        this.router.navigate(['notsupport']); 
        else // If another browser, 
        return true
    }
    else if(browser=="edg/")
    {
    this.router.navigate(['notsupport']);
    }
    else
    return true
  }
}

import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { ThemeOptions } from '@moduurnv2/features/ui/directives';
@Component({
  selector: 'moduurnv2-common-popup',
  templateUrl: './common-popup.component.html',
  styleUrls: ['./common-popup.component.scss'],
})
export class CommonPopupComponent implements OnInit {
  @Input() modalStatus: boolean = false;
  @Input() heading;
  @Input() requireOverlay: boolean = true;
  @Input() topMargin: string = "'0'";
  @Output() closePopupEvent = new EventEmitter();
  constructor() {}
  themeOptions: ThemeOptions = {
    appPrimaryColor: true,
    // appFontFamily: true,
  };

  ngOnInit(): void {}
}

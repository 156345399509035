export interface Address {
  addressType: string;
  instructions: string;
  buzzerNumber: string;
  unitNumber: string;
  postalcode: string;
  country: string;
  state: string;
  city: string;
  streetAddress: string;
  _id?: string;
  loc?: any[];
  distance: string;
  deliverable?: boolean;
  regionCode?: string;
}

export interface AddressShort {
  unitNumber: string;
  postalcode: string;
  country: string;
  state: string;
  city: string;
  streetAddress: string;
  loc?:loc;
}
export interface AddressData {
  locationId: string;
  locationCoordinates: [string, string];
  addressCoordinates: Array<addressCordinates>;
  enteredAddress?: object;
}
export interface addressCordinates {
  id: string;
  loc: loc;
  details:AddressShort
}
export interface loc {
  lat: string;
  lng: string;
}

export enum AddressComponentEnums {
  ADDRESS_LIST = 'ADDRESS_LIST',
  ADDRESS_DETAILS = 'ADDRESS_DETAILS',
  ADDRESS_MAP = 'ADDRESS_MAP',
  NONE = 'NONE',
}
export interface AddressComponentCloseModel {
  toPage: AddressComponentEnums;
  fromPage: AddressComponentEnums
  dataToPass?: any;
}
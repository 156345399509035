import { Component,OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  SharedService,
  GiftAndLoyaltyCardService,
  SweetAlertService,
} from '@moduurnv2/libs-orderingapp/src/core';
import { GiftCardBaseComponent } from '@moduurnv2/libs-orderingapp/src';

import { FormBuilder } from '@angular/forms';
import { GiftLoyaltyCardComponent } from '../card-details/gift-loyalty-card.component';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { ActivatedRoute } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'moduurnv2-validate-email',
  templateUrl: './validate-email.component.html',
  styleUrls: ['./validate-email.component.scss']
})
export class ValidateEmailComponent  extends GiftCardBaseComponent  implements OnInit{

  constructor(
    store: Store,
    router: Router,
    _shared: SharedService,
    fb: FormBuilder,
    loyaltyService: GiftAndLoyaltyCardService,
    modal: ModalService,
    public sweetAlert: SweetAlertService,private route: ActivatedRoute,public translate: TranslateService
  ) {
    super(fb, store, router, _shared, loyaltyService, modal, sweetAlert,translate);
  }
  ngOnInit() {
    super.ngOnInit();
    const { validateId } = this.route.snapshot.params;
    this.checkEncryptedAndValidate(validateId);
  }

}

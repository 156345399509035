<div class="address-container">  
  <ng-container *ngIf="adrsList; else loadingAddrsList">
    <ng-container *ngIf="checkAdrNotEmpty() ; else noAdrToDisplay">
      <moduurnv2-address-card-desktop
      [isAddressBook]="isAddressBook"
      *ngFor="let item of adrsList"
      [items]="item"
      (closeNewScreen)="closeCurrentScreen($event)"
      >
    </moduurnv2-address-card-desktop>
    </ng-container>
    <ng-template #noAdrToDisplay>
      <div class="no-orders">
        No address to display
      </div>
    </ng-template>
  </ng-container>
  <ng-container #loadingAddrsList>
    <div *ngIf="!adrsList" class="address-container-loading" >
      <div class="address">
        <div class="distance"></div>
        <div class="right-container">
          <div class="btn-container"></div>
          <div class="address-content"></div>
        </div>
      </div>
      <div class="address">
        <div class="distance"></div>
        <div class="right-container">
          <div class="btn-container"></div>
          <div class="address-content"></div>
        </div>
      </div>
    </div>
  </ng-container>
<br/>
<button type="button" (click)="addAddress()" class="add-address">
  <!-- <img src="assets/images/add-icon-red.svg" class="icon" alt="add icon"/>
  <span data-qa="address-list-add" aria-label="addresslist-add"> -->
    {{ 'add-address' | translate }}
  <!-- </span> -->
</button>
</div>

import {
  BaseComponent,
  environment,
  LocationState,
  DeliveryState,
  Delivery,
  CartState,
} from '@moduurnv2/libs-orderingapp/src/core';
import { OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import {
  AddressState,
  Address,
  FetchDeliveryAddress,
  LocationDefaultState,
  VenueManagementState,
  Venue,
  WhitelabelState,
  QRScanDetails
} from '@moduurnv2/libs-orderingapp/src/core';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil } from 'rxjs/operators';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import * as moment from 'moment';
import { SharedService } from './../../../core/services/shared.service';
import { TranslateService } from '@ngx-translate/core';
// import { AddressWrapperComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/address-wrapper/address-wrapper.component';

export class AddressSelectorBaseComponent extends BaseComponent
  implements OnInit {
  // viewChangeAdrsModalStatus: boolean;
  addressBookStatus: boolean = false;
  selectedLocation: any;
  selectedDelivery: any;
  deliveryLocation: any;
  selectedVenue: Venue = undefined;
  @Select(AddressState.getDeliveryAddress) DeliveryAddress$: Observable<
    Address
  >;
  // @Select(AddressState.getAddressPopUpStatus)
  // modalChangeAdrsStatus$: Observable<boolean>;
  @Select(DeliveryState.getSelectedDelivery) selectedDelivery$: Observable<
    Delivery
  >;
  @Select(CartState.getFutureDate) futureDate$: any;
  @Select(CartState.getETA) eta$: any;
  @Select(VenueManagementState.getSelectedPickUpLocation)
  selectedPickUpLocation$: Observable<Venue>;
  @Select(VenueManagementState.getSelectedRoom) selectedRoom$: Observable<
    Venue
  >;
  @Select(VenueManagementState.getSelectedVenue) selectedVenue$: Observable<
  Venue
  >;
  @Select(CartState.getFutureEndDateTime) futureEndDate$: Observable<any>;
  deliveryAddress: String;
  futureDate: string = '';
  futureEndDate : string = '';
  eta: any;
  isMobileView: boolean;
  qrScanDetails:QRScanDetails;
  constructor(
    public store: Store,
    public modal: ModalService,
    private shared: SharedService,
    private translate :  TranslateService,
  ) {
    super();
    this.qrScanDetails = this.store.selectSnapshot(WhitelabelState.getQRScanDetails);
    const selectedLocation = this.store.selectSnapshot(LocationState.getSelectedLocation);
    
    this.store.dispatch(new FetchDeliveryAddress());

    this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
    this.selectedDelivery$
      .pipe(takeUntil(this.destroy$))
      .subscribe((delivery) => {
        if (delivery) {
          this.selectedDelivery = delivery;
          if (
            !this.isMobileView &&
            ((this.selectedDelivery?.textCode == 'DELIVERYTYPE09' ||
              this.selectedDelivery?.textCode == 'DELIVERYTYPE10') ||
               this.qrScanDetails?.venueName && this.selectedDelivery.textCode == this.qrScanDetails.deliveryType
                && selectedLocation?._id == this.qrScanDetails.locationId)
          ) {
            this.setSelectedVenuesToDisplay(this.selectedDelivery.textCode);
          }
        }
      });
    this.futureDate$.pipe(takeUntil(this.destroy$)).subscribe((date) => {
      if (date) {
        this.futureDate = date;
        this.futureEndDate$.pipe(takeUntil(this.destroy$)).subscribe((endDate) => {
          if(endDate){
            this.futureEndDate = endDate;
          }
        });
      }
    });
    this.eta$.pipe(takeUntil(this.destroy$)).subscribe((eta) => {
      if (eta) {
        this.eta = eta;
      }
    });
  }
  ngOnInit() {
    this.subscribeAdrs();
    // this.subscribePopupStatus();
    this.selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
  }
  // openChangeAdrs() {
  //   // this.store.dispatch(new ChangePopupStatus(true));
  //   this.modal.showSmallModal(AddressWrapperComponent);
  // }
  // closeChangeAdrs() {
  //   this.store.dispatch(new ChangePopupStatus(false));
  // }
  subscribeAdrs() {
    this.DeliveryAddress$.pipe(takeUntil(this.destroy$)).subscribe(
      (address) => {
        if (address) {
          this.deliveryLocation = address;
          this.deliveryAddress = '';
          if (address?.unitNumber && address.unitNumber != '')
            this.deliveryAddress = address.unitNumber + ', ';
          if (address?.buzzerNumber && address.buzzerNumber != '')
            this.deliveryAddress = address.buzzerNumber + ', ';
          if (address?.streetAddress && address.streetAddress != '')
            this.deliveryAddress += address.streetAddress;
          if (address?.city && address.city != '')
            this.deliveryAddress += ',' + address.city;
          if (address?.state && address.state != '')
            this.deliveryAddress += ',' + address.state;
          if (address?.country && address.country != '')
            this.deliveryAddress += ',' + address.country;
        } else {
          this.deliveryAddress = '';
        }
      }
    );
  }
  // subscribePopupStatus() {
  //   this.modalChangeAdrsStatus$
  //     .pipe(takeUntil(this.destroy$))
  //     .subscribe((status) => {
  //       this.viewChangeAdrsModalStatus = status;
  //     });
  // }
  setDisplayTime(futureDate) {
    if (futureDate) {
      const futureTime = moment(futureDate);
      let timeFormat = 'hh:mm A';
      if(this.translate && this.translate.currentLang == 'fr'){
        timeFormat   = 'HH:mm'
       }
      let timeText = futureTime.format('MMM DD '+ timeFormat);
      const locationDefaultsDetails = this.store.selectSnapshot(LocationDefaultState.getLocationDefaults);
      const timeSlot = locationDefaultsDetails.locationData?.orderSettings?.futureOrder?.overrideTimeSlot?.isAllow ? 
                        locationDefaultsDetails.locationData?.orderSettings?.futureOrder?.overrideTimeSlot?.timeSlot : 30;
  
      timeText += ` - ${futureTime.add({ minutes: timeSlot }).format(timeFormat)}`;
      return timeText;
    }
    return 'ASAP';
  }

  setSelectedVenuesToDisplay(selectedDeliveryTextCode) {
    const selectedDelivery = this.store.selectSnapshot(DeliveryState.getSelectedDelivery);
    const selectedLocation = this.store.selectSnapshot(LocationState.getSelectedLocation);
    if(this.qrScanDetails?.venueName && selectedDelivery.textCode == this.qrScanDetails.deliveryType
      && selectedLocation?._id == this.qrScanDetails.locationId){
      this.selectedVenue$.pipe(takeUntil(this.destroy$)).subscribe((venue) => {
        if (venue) {
          this.selectedVenue = venue;
        }
      });
     } else {
        switch (selectedDeliveryTextCode) {
          case 'DELIVERYTYPE09':
            this.selectedPickUpLocation$
              .pipe(takeUntil(this.destroy$))
              .subscribe((venue) => {
                if (venue) {
                  this.selectedVenue = venue;
                }
              });
            break;
          case 'DELIVERYTYPE10':
            this.selectedRoom$.pipe(takeUntil(this.destroy$)).subscribe((venue) => {
              if (venue) {
                this.selectedVenue = venue;
              }
            });
            break;
          default:
            break;
        }
    }
  }

  get getDisplayTime() {
    let timeFormat = 'hh:mm A';
    if(this.translate && this.translate.currentLang == 'fr'){
      timeFormat   = 'HH:mm'
     }
    if (this.futureDate) {
      const futureTime = moment(this.futureDate);
      let timeText = futureTime.format('MMM DD ' + timeFormat);
      const usePicker = this.store.selectSnapshot(LocationState.getTimePicker);
      const locationDefaultsDetails = this.store.selectSnapshot(LocationDefaultState.getLocationDefaults);
      const timeSlot = locationDefaultsDetails.locationData?.orderSettings?.futureOrder?.overrideTimeSlot?.isAllow ? 
                        locationDefaultsDetails.locationData?.orderSettings?.futureOrder?.overrideTimeSlot?.timeSlot : 30;
  
      if (usePicker === false){
        if(this.futureEndDate && this.selectedDelivery.textCode == 'DELIVERYTYPE10'){
          timeText += ` - ${moment(this.futureEndDate).format(timeFormat)}`;
        } else {
          timeText += ` - ${futureTime.add({ minutes: timeSlot }).format(timeFormat)}`;
        }
      }
      return timeText;
    }
    return (!this.eta?.minPrepTime && !this.eta?.maxPrepTime) ? '' : (this.eta?.minPrepTime ? this.eta?.message : `Less than ${this.eta.maxPrepTime} mins`);//this.eta;
  }
}

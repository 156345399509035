<!-- Modifier wrapper -->
<div
  class="modifier-wrapper"   *ngIf="selectedModifier$ | async as modifier; else modifierLoader"
>
  <button class="close-button" (click)="closePopup()">
    <img [src]="close" data-qa="close-button" aria-label="close" alt="close"/>
  </button>
  <!-- image container -->
  <div class="scrollable custom-scroll ">
    <!-- Item options list -->
    <div class="option-list">
      <!-- Portion -->
      <!-- Modifiers -->
          <div
            class="option-item accordion-modifier"
            [attr.data-qa]="modifier._id"
          >

            <div class="box">
              <label
                class="box-title"

                [ngClass]="{
                  'label-error': headerErrors(modifier.valid, modifier._id)||showError
                }"
              >
                <div class="option-header" [id]="modifier._id">
                  <div class="option-heading accordion-modifier-head">
                    {{ getActionCaption(modifier) }}
                  </div>
                  <div class="option-requirements accordion-modifier-req desc-scroll">
                    {{ choiceText(modifier) }}<br>
                    <ng-container *ngIf="!isHideDescription">
                      {{modifier.description | formattedLanguage: modifier.descriptionTranslation}}<br>
                    </ng-container>
                    <ng-container *ngIf="isShowCalorieDetails">
                      <ng-container *ngIf="modifier.minCalorie && modifier.maxCalorie" >
                        {{modifier.minCalorie}} - {{modifier.maxCalorie}} Kcal  
                      </ng-container>
                      <ng-container *ngIf="modifier.minCalorie && !modifier.maxCalorie" >
                          {{modifier.minCalorie}} Kcal
                      </ng-container>
                      <ng-container *ngIf="modifier.maxCalorie && !modifier.minCalorie" >
                          {{modifier.maxCalorie}} Kcal
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
                <!-- <img
                  src="assets/images/Bullet-Chevron-Down.svg"
                  class="imgicon"
                  alt="chevron down"
                /> -->
              </label>
              <label class="box-close" for="acc-close"></label>

              {{ getModifierSelectedValue(modifier._id) }}
              <p
                class="tick-mark"
                *ngIf="modifier.valid && selectedModifier?._id == modifier._id"
              ></p>

              <div class="modifier-lists custom-scroll" [ngClass]="isMobileView ? 'scroll-small' : ''">
                <ng-container
                  *ngFor="let modifierItem of modifier.modifierItems"
                >

                  <moduurnv2-order-level-modifier-list
                    [modifierItem]="modifierItem"
                    [modifier]="modifier"
                  >
                  </moduurnv2-order-level-modifier-list>
                </ng-container>
              </div>
            </div>
            <input type="radio" name="accordion" id="acc-close" />
          </div>

      <ng-template #noModifiers>
        <!-- <div class="unavailable">
        Sorry, no modifiers available.
      </div> -->
      </ng-template>
    </div>
  </div>
  <div class="modifier-footer">
    <!-- <div class="counter" *ngIf="count$ | async as count">
      <button (click)="decreaseCounter()" data-qa="modifier-count-dec">
        -
      </button>
      <div class="count" data-qa="modifier-count">{{ count }}</div>
      <button
        (click)="increaseCounter()"
        data-qa="modifier-count-inc"
        [ngClass]="{ 'disable-button': checkComboMax }"
      >
        +
      </button>
    </div> -->
    <div class="add-button-wrapper">
      <button
        (click)="submitItem()"
        [theme]="themeOptions"

      >
      {{
        'add-to-order' | translate
      }}



      </button>
    </div>
  </div>
</div>

<ng-template #modifierLoader>
  <div class="modifier-wrapper loading">
    <div class="image-container loader">
      <button class="close-button" (click)="closePopup()">
        <img [src]="close" data-qa="close-button" aria-label="close" alt="close"/>
      </button>
    </div>
    <div class="details-container">
      <div class="item-details">
        <div class="item-name loader"></div>
        <div class="item-description loader"></div>
      </div>
    </div>
    <div class="content-container">
      <div class="option-list">
        <div class="option-item">
          <div class="option-header">
            <div class="option-heading loader"></div>
            <div class="option-requirements loader"></div>
          </div>
          <div class="content-item loader"></div>
          <div class="content-item loader"></div>
        </div>
      </div>
    </div>
    <div class="footer-container">
      <div class="button loader"></div>
    </div>
  </div>
</ng-template>

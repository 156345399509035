import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { State, Selector, Action, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { FetchCart } from '../actions/cart.actions';
import {
  SelectDeal,
  RemoveDeal,
  FetchDeals,
  ClearDeals,
  ValidateDeal,
  ClearAllDeals,
} from '../actions/deals.actions';
import { Deals } from '../models/deals.interface';
import { DealsService } from '../services/deals.service';
import { SweetAlertService } from './../services/sweet-alert-service';
import {LocationState} from './location.state';
import {AuthState} from './auth.state';
export class DealsStateModel {
  allDeals: Deals[];
  selectedDeal: Deals;
  dealQueue: Deals;
}

@State<DealsStateModel>({
  name: 'deals',
  defaults: {
    allDeals: null,
    selectedDeal: null,
    dealQueue: null,
  },
})
@Injectable()
export class DealsState {
  constructor(
    private dealsService: DealsService,
    private store: Store,
    private sweetAlert: SweetAlertService,
    private router: Router
  ) {}

  @Selector()
  static getSelectedDeal(state: DealsStateModel) {
    return state.selectedDeal;
  }
  @Selector()
  static getAllDeals(state: DealsStateModel) {
    return state.allDeals;
  }
  @Selector()
  static getQueueDeal(state: DealsStateModel) {
    return state.dealQueue;
  }

  @Action(FetchDeals)
  FetchDeals(
    { getState,setState }: StateContext<DealsStateModel>,
    { cartId, subTotal }: FetchDeals
  ) {
    const deals = getState();
    if(deals.allDeals?.length>0){
      this.store.dispatch(new ClearAllDeals());
    }
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    const customer = this.store.selectSnapshot(
      AuthState.getCommonAuthDetails
    );
    return this.dealsService.fetchDeals(cartId, subTotal,selectedLocation._id,customer).pipe(
      tap((response) => {
        if (response) {
          setState(
            patch({
              allDeals: response['data'],
            })
          );
        } else throw response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  @Action(SelectDeal)
  SelectDeal(
    { getState, setState }: StateContext<DealsStateModel>,
    { coupon }: SelectDeal
  ) {
    const state = getState();
    const appState = this.store.selectSnapshot((app) => app.cart);
    // console.log(appState)
    if (appState.cart) {
      return this.dealsService.cartCoupon(appState.cart, coupon).pipe(
        tap((response) => {
          if (response['errorCode']) {
            this.sweetAlert.toast('error', response['message']);
          } else if (response && state.allDeals) {
            setState(
              patch({
                selectedDeal: state.allDeals.find(
                  (deal) => deal._id === coupon._id
                ),
                dealQueue: null,
              })
            );
            this.store.dispatch(new FetchCart());
          }
        })
      );
    } else {
      setState(
        patch({
          dealQueue: coupon,
        })
      );
    }
  }

  @Action(ValidateDeal)
  ValidateDeal(
    { getState, setState }: StateContext<DealsStateModel>,
    { cartId, subTotal }: ValidateDeal
  ) {
    const deal = getState();
    const selectedDeal = deal.selectedDeal;
    const queueDeal = deal.dealQueue;
    const cart = this.store.selectSnapshot(app => app.cart);
    const cartIdToCheck = cartId ? cartId : cart.cart_id;
    const subTotalToCheck = subTotal ? subTotal : cart.subTotal;
    console.log('++++++++++++++++++++++++')
    console.log('++++++',selectedDeal,'/////////',cartIdToCheck,'======',queueDeal)
    console.log('++++++++++++++++++++++++')
    if((selectedDeal || queueDeal) && cartIdToCheck)
    return this.dealsService.validateCoupon(cartIdToCheck, subTotalToCheck).pipe(
      tap((response) => {
        if (response['errorCode']) {
          if(this.router.url.indexOf('deals') > -1 || this.router.url.indexOf('deals-listing') > -1 || this.router.url.indexOf('order') > -1){
            this.store.dispatch(new RemoveDeal());
            this.sweetAlert.toast('error', response['message']);
          } else if(this.router.url.indexOf('menu') > -1){
            this.store.dispatch(new RemoveDeal());
          }
        }
      })
    );
    else if(queueDeal)
    this.store.dispatch(new RemoveDeal());
  }

  @Action(RemoveDeal)
  RemoveDeal({ setState }: StateContext<DealsStateModel>) {
    const appState = this.store.selectSnapshot((app) => app.cart);
    if (appState.cart) {
      return this.dealsService.cartCoupon(appState.cart).pipe(
        tap((response) => {
          if (response['errorCode']) {
            this.sweetAlert.toast('error', response['message']);
          } else if (response) {
            setState(
              patch({
                selectedDeal: null,
                dealQueue: null,
              })
            );
            this.store.dispatch(new FetchCart());
          }
        })
      );
    } else {
      setState(
        patch({
          dealQueue: null,
        })
      );
    }
  }

  @Action(ClearDeals)
  ClearDeals({ setState }: StateContext<DealsStateModel>) {
    setState(
      patch({
        selectedDeal: null,
        allDeals: null,
        dealQueue: null,
      })
    );
  }
  @Action(ClearAllDeals)
  ClearAllDeals({ setState }: StateContext<DealsStateModel>) {
    setState(
      patch({
        allDeals: null
      })
    );
  }
}

<div class="payment-card-wrapper">
  <ng-container >
    <ng-container *ngIf="paymentDetails.length">
      <ng-container  *ngFor="let card of paymentDetails; let i = index">
        <div
          class="payment-method"
          *ngIf="card.card_id != selectedCard.card_id"
          data-qa="card-details"
          (click)="selectCard(card)"
        >
        <input type="radio" class="payment-method-radio" name="cardSelection" (click)="selectCard(card)" [checked]="currentlyselectedCard && card && currentlyselectedCard.card_id === card.card_id" [value]="card.card_id">
           <table class="card-view card-single-view" [ngStyle]="getCardStyle(i)">
            <tr>
              <td><img [src]="getCard(card.card_type).icon" alt="card type"/></td>
              <!-- <td></td> -->
              <td>{{ card.number }}</td>
              <td>EXP {{ card.expiry_month + '/' + card.expiry_year }}</td>
            </tr>
          </table>
        </div>
        <div
          class="payment-method card"
          (click)="unselectCard(card)"
          *ngIf="card.card_id == selectedCard.card_id"
          data-qa="unselect-card"  >
          <div class="card-view card-expanded-view" [ngStyle]="getCardStyle(i)">
            <div class="bank-logo">
              <img [src]="getCard(card.card_type).icon" alt="card type"/>
            </div>
            <div class="card-number">{{ card.number }}</div>
            <table class="card-details">
              <tr class="head-row">
                <td>{{ 'CARD HOLDER' | translate }}</td>
                <td class="center">{{ 'EXPIRES' | translate }}</td>
                <!-- <td>CVV</td> -->
              </tr>
              <tr class="detail-row">
                <th>{{ card.name }}</th>
                <th class="center">
                  {{ card.expiry_month + '/' + card.expiry_year }}
                </th>
                <!-- <th>{{ card.card_details.cvv }}</th> -->
              </tr>
            </table>
          </div>
          <div class="delete-info">
            <button
              type="button"
              class="delete-card"
              (click)="deleteCard(card)"
              data-qa="delete-card"
            >
              <img src="assets/images/Delete-Icon-Small.svg" alt="delete icon"/>
              {{ 'Delete Card' | translate }}
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container></ng-container>

    <ng-container *ngIf="!paymentDetails.length">
      <div class="no-info">{{ 'no-payments'| translate }}</div>
    </ng-container>
    <div class="row button-container"  >
      <button type="button" (click)="addCreditCard()" class="add-card">
        <img src="assets/images/add-icon-black.svg" class="icon" alt="add icon"/>
        <span data-qa="card-list-add" aria-label="card-add">
          {{ 'add-new-credit-card' | translate }}
        </span>
      </button>
      <button  class="btn-submit" (click)="continue()" data-qa="continue-step" type="submit"   [disabled]="!currentlyselectedCard">
        {{ 'continue-adrs' | translate }}
      </button>
    </div>
  </div>



import { Component, OnInit, Input } from '@angular/core';
import {
  BaseComponent,
  environment,
  WhitelabelState,
} from '@moduurnv2/libs-orderingapp';
import { Store } from '@ngxs/store';

@Component({
  selector: 'moduurnv2-table-empty',
  templateUrl: 'table-empty.component.html',
  styleUrls: ['table-empty.component.scss'],
})
export class TableEmptyComponent extends BaseComponent implements OnInit {
  locImage = 'assets/images/home-background-image.jpg';
  organizationLogo: string;
  organizationName: string;

  constructor(private store: Store) {
    super();
  }

  ngOnInit() {
    let whitelabel = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
    this.organizationLogo =
    environment.imagebaseurl + whitelabel.restaurantLogo;
    this.organizationName = whitelabel.restaurantName;
  }
}

/*
usage:
 <moduurnv2-notification [heading]="'Get Order Notifications from Moduurn'"
      >Get a push notification on your mobile if there is a change or
      cancellation of your order. </moduurnv2-notification
    >

*/

import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@moduurnv2/libs-orderingapp/src/core';
import { MessagingService } from '@moduurnv2/web/core';
@Component({
  selector: 'moduurnv2-notification',
  templateUrl: 'notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent extends BaseComponent implements OnInit {
  @Input() heading = 'Get Notifications from Moduurn';
  @Input() notificationType = 'Promotion';
  message;
  isHidden: boolean = false;

  constructor(public messagingService: MessagingService) {
    super();
  }
  ngOnInit() {
    if (!('Notification' in window)) {
      this.isHidden = true;
    } else if (Notification.permission === 'granted') {
      this.messagingService.requestPermission();
      this.messagingService.receiveMessage();
      this.message = this.messagingService.currentMessage;
      //check for notificationType
      //here we need to check server whether the user already subscribed for messages (notificationType). Then we don't need to show the notification box.
      this.isHidden = true;
    } else if (Notification.permission == 'denied') {
      this.isHidden = true;
    }
  }

  enableNotification(evt) {
    let isChecked = evt.target.checked;
    if (isChecked) {
      if (Notification.permission === 'granted') {
        this.messagingService.requestPermission();
        this.messagingService.receiveMessage();
        this.message = this.messagingService.currentMessage;
      } else if (Notification.permission !== 'denied') {
        let vm = this;
        Notification.requestPermission().then(
          function (permission) {
            if (permission === 'granted') {
              this.messagingService.requestPermission();
              this.messagingService.receiveMessage();
              this.message = this.messagingService.currentMessage;
            }
            this.isHidden = true;
          }.bind(this)
        );
      }
    }
  }
}

import { Injectable } from '@angular/core';
import { Router, CanActivate,ActivatedRoute } from '@angular/router';
import {
  LocationState,
  SetSelectedLocation,
  Location,
  FetchLocationList,
  SharedService,
  DeliveryState,
  VenueManagementState,
  SetLocationAccess
} from '@moduurnv2/libs-orderingapp/src/core';
import { Observable } from 'rxjs/internal/Observable';
import { Store, Select } from '@ngxs/store';
import { map, takeUntil } from 'rxjs/operators';
import { ModalService } from '../../../../apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { LocationAccessComponent } from '../../../../apps/orderingapp/web-orderingapp/src/app/features/home/location-access/location-access.component';
import { Subject } from 'rxjs';
@Injectable()
export class LocationAccessGuardService implements CanActivate {
  @Select(LocationState.getLocationsList) locationsList$: Observable<
    Location[]
  >;
  @Select(LocationState.getSelectedLocation) selectedLocation$: Observable<any>;
  @Select(DeliveryState.getSelectedDelivery)selectedDelivery$: Observable<any>;
  @Select(VenueManagementState.getLocationAccessEnabled)locationEnabled$: Observable<any>;
  isMobileView;
  deliveryType;
  locationEnabled;
  deliverySubscription;
  locationSubscription;
  checkValue;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(public store: Store, public router: Router, private modal: ModalService, private _shared: SharedService,private activatedRoute:ActivatedRoute) {
    this._shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
   this.deliverySubscription =  this.selectedDelivery$.subscribe((delivery) => {
      if (delivery) {
        this.deliveryType = delivery.textCode;
      }
    });
    this.locationSubscription = this.locationEnabled$.subscribe((persons) => {
      if (persons) {
        this.locationEnabled = true;
      } else {
        this.locationEnabled = false;
      }
    });
  }
  canActivate(): any {
   return this.selectedLocation$.pipe(
      map((selLoc) => {
      if(selLoc?.orderSettings?.geoFencing?.isAllow){
            const { queryParams } = this.router.getCurrentNavigation().finalUrl;
            let tableNumber;
            if (queryParams && queryParams.tableNumber)
              tableNumber = queryParams.tableNumber;
              const isAccessed = this.store.selectSnapshot(
                VenueManagementState.getLocationAccessEnabled
              );
              console.log('isAccessed',isAccessed)

            if(this.isMobileView && this.deliveryType =='DELIVERYTYPE08' && tableNumber)
            {
              // return this.checkLocPermission().then(res=>{
              if(this.locationEnabled){
                return true;
              } else {
                this.router.navigate([`locationAccess`],{ queryParams: { 'tableNumber':tableNumber }}) 
                return false;
            }
          //  })
            }  else
            return true;
                
        } else if(selLoc?.orderSettings){
          return true;
        } else {
          this.router.navigate([`locations`]) 
          return false;
        }

  }))
  }
  ngOnDestroy() {
    this.locationSubscription.unsubscribe();
    this.deliverySubscription.unsubscribe();
  }

  checkLocPermission(){
    return new Promise<boolean>(async (resolve, reject) => {
     let PermissionStatus = await navigator.permissions.query({name: 'geolocation'});
          if (PermissionStatus['state'] == 'granted') {
            resolve(true)
          } else {
            resolve(false)
          }
        })
  }
}

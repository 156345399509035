<div class="time-disp-container" *ngIf="allowAsap && !allowFuture && selectedVenue && !selectedVenue.allowSchedule">
  <img [src]="clockIcon" class="time" alt="clock"/>
  <div class="time-text">
    Order For Now
  </div>
  <img [src]="tickIcon" *ngIf="isAsap" class="tick" alt="tick icon"/>
</div>

<div class="time-disp-err-container" *ngIf="allowAsap && !allowFuture && selectedVenue && selectedVenue.allowSchedule">
  Not accepting future orders right now
</div>
<div class="time-selection-container" (click)="openDateTimePicker()" *ngIf="allowFuture">
  <img [src]="calendarIcon" class="calendar" alt="calendar"/>
  <div class="time-text" [class.timeinvalid]="validationError">
    <ng-container *ngIf="(futureDate && !venueChanged); else timeSelectedNotFuture">
      {{ futureDate }}
    </ng-container>
    <ng-template #timeSelectedNotFuture>
      <ng-container *ngIf="!futureDate && !venueChanged;else timeNotSelected">
        {{"order-for-now" | translate}}
      </ng-container>
      <ng-template #timeNotSelected>
        {{'schedule-delivery' | translate}}
      </ng-template>
    </ng-template>
  </div>
  <!-- <img [src]="tickIcon" *ngIf="!isAsap && !venueChanged" class="tick" alt="tick"/> -->
  <img src="assets/images/Chevron_Down.svg" alt="selectDate" class="tick">
</div>
import { Component} from '@angular/core';
import { AddressListBaseComponent } from '@moduurnv2/libs-orderingapp/src/features/ui/base/address-list-base-component';
import {
  AddressComponentEnums
} from '@moduurnv2/libs-orderingapp/src/core/models';
@Component({
  selector: 'moduurnv2-address-list-desktop',
  templateUrl: './address-list.component-desktop.html',
  styleUrls: ['./address-list.component-desktop.scss'],
})
export class AddressListDesktopComponent extends AddressListBaseComponent {

  openDetailsScreen(event) {
    event.stopPropagation();
    event.preventDefault();
    localStorage.removeItem('tempSelectedAdrsLocation');
    //this.openMap(); we need to open address list
    // this.modal.showMediumModalDesktop(AddressDetailsDesktopComponent);

    //DONT MOVE THIS TO BASE
    this.closeCurrentScreen({toPage:AddressComponentEnums.ADDRESS_DETAILS,fromPage:AddressComponentEnums.ADDRESS_LIST});
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  SetModifierItem,
  RemoveModifierItem,
  SetSubModifierItem,
  BaseComponent,
  RemoveSubModifierItem,
} from '@moduurnv2/libs-orderingapp/src';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'moduurnv2-modifier-list',
  templateUrl: 'modifier-list.component.html',
  styleUrls: ['modifier-list.component.scss']
})
export class ModifierListComponent extends BaseComponent implements OnInit {
  @Input('modifierItem') modifierItem;
  @Input('modifier') modifier;
  @Input('isLoyaltyEnabled') isLoyaltyEnabled;
  generatedMenuItem;

  constructor(private store: Store, private translate: TranslateService) {
    super();
  }

  ngOnInit() {}

  checkInput(modifier, modifierItem?) {
    // return false for input and true for radio

    // cases for radio
    // if forced modifier and modifier item is forced
    // if forced modifier item && max limit is 1

    // if (modifierItem && modifierItem.maxLimit === 1) {
    //   return modifierItem.isForced;
    // } else if (modifier.isForced && modifier.maxLimit === 1) return true;
    // return false;

    // for radio modifier is forced and modifier item is forced
    // or modifier is forced and is modifier item
    if (modifierItem) {
      if (modifier.isForced)
        if (modifierItem.maxLimit === 1) return modifierItem.isForced;
    } else return modifier.maxLimit === 1 && modifier.isForced;
    return false;
  }

  choiceText(modifierItem) {
    const { isForced, maxLimit, minLimit } = modifierItem;
    let choiceOption = this.translate.instant('please-select');
    let endText = this.translate.instant('optional');
    if (isForced) endText = this.translate.instant('required');
    if (maxLimit === 1)
      return (
        `${choiceOption} 1 ` +
        this.translate.instant('option') +
        ` (${endText})`
      );
    else if (maxLimit > 0)
      if (minLimit === 0 || minLimit == undefined)
        return (
          `${choiceOption} upto ${maxLimit} ` +
          this.translate.instant('option') +
          's' +
          ` (${endText})`
        );
      else if (minLimit === maxLimit)
        return (
          `${choiceOption} ${maxLimit} ` +
          this.translate.instant('option') +
          's' +
          ` (${endText})`
        );
      else
        return (
          `${choiceOption} between ${minLimit} and ${maxLimit} ` +
          this.translate.instant('option') +
          's' +
          ` (${endText})`
        );
  }

  modifierItemChange(event, modifierItem, modifier) {
    if (event && event.target) {
      const { modifierItems, maxLimit } = modifier;
      const selectedItems = modifierItems.filter(
        (modifierItem) => modifierItem.selected === true
      );
      // if checkbox
      if (this.checkInput(modifier) === false) {
        if (event.target.checked === true) {
          if (
            (maxLimit > 0 && maxLimit > selectedItems.length) ||
            maxLimit === 0
          )
            this.store.dispatch(
              new SetModifierItem(this.modifier._id, {
                id: modifierItem._id,
                count: 1
              })
            );
          else {
            event.target.checked = false;
            modifierItem.selected = false;
          }
        } else if (event.target.checked === false) {
          this.store.dispatch(
            new RemoveModifierItem(this.modifier._id, {
              id: modifierItem._id,
              count: 1
            })
          );
        } else {
          event.target.checked = false;
          modifierItem.selected = false;
        }
      } else {
        if (event.target.checked === true)
          if (
            (maxLimit > 0 && maxLimit >= selectedItems.length) ||
            maxLimit === 0
          )
            this.store.dispatch(
              new SetModifierItem(this.modifier._id, {
                id: modifierItem._id,
                count: 1
              })
            );
          else {
            event.target.checked = false;
            modifierItem.selected = false;
          }
      }
    }
  }

  subModifierItemChange(event, subModifierItem, subModifier) {
    if (event && event.target) {
      const { maxLimit: modifierMax, modifierItems } = this.modifier;
      const { items, maxLimit } = subModifier;
      const selectedItems = items.filter((item) => item.selected === true);
      if (this.checkInput(subModifier) === false) {
        if (event.target.checked === true) {
          if (
            (maxLimit > 0 && maxLimit >= selectedItems.length) ||
            maxLimit === 0
          )
            this.store.dispatch(
              new SetSubModifierItem(this.modifier._id, subModifier._id, {
                id: subModifierItem._id,
                count: 1
              })
            );
          else {
            event.target.checked = false;
            subModifierItem.selected = false;
          }
        } else if (event.target.checked === false) {
          this.store.dispatch(
            new RemoveSubModifierItem(this.modifier._id, subModifier._id, {
              id: subModifierItem._id,
              count: 1
            })
          );
        } else {
          event.target.checked = false;
          subModifierItem.selected = false;
        }
      } else {
        if (event.target.checked === true)
          if (
            (maxLimit > 0 && maxLimit >= selectedItems.length) ||
            maxLimit === 0
          ) {
            event.target.checked = false;
            this.store.dispatch(
              new SetSubModifierItem(this.modifier._id, subModifier._id, {
                id: subModifierItem._id,
                count: 1
              })
            );
          } else {
            this.store.dispatch(
              new RemoveSubModifierItem(this.modifier._id, subModifier._id, {
                id: subModifierItem._id,
                count: 1
              })
            );
            event.target.checked = false;
            subModifierItem.selected = false;
          }
        else
          this.store.dispatch(
            new RemoveSubModifierItem(this.modifier._id, subModifier._id, {
              id: subModifierItem._id,
              count: 1
            })
          );
      }
    
  }
  }

  headerErrors(subModifierValid) {
    const submitted = this.store.selectSnapshot(
      app => app && app.menuItem && app.menuItem.submitted
    );
    return submitted ? subModifierValid == false : false;
  }
  increaseCounter(item, parent, type) {
    if((this.modifier.maxLimit > this.modifier.count)||(this.modifier.maxLimit == this.modifier.count && (item.count || parent.count)) || this.modifier.maxLimit === 0){
    if (type == 'item') {
      const { modifierItems, maxLimit } = parent;
      let count = 0;
      const selectedItems = modifierItems.filter(modifierItem => {
        if (modifierItem.count > 0) {
          count += modifierItem.count;
          return modifierItem;
        }
      });
      // if checkbox
      // if (this.checkInput(parent) === false) {
        if ((maxLimit > 0 && maxLimit > parent.count ) || maxLimit === 0 || item.count) {
          let itemMaxLimit = item.maxLimit ? item.maxLimit : 0;
          if (itemMaxLimit) {
            if (item.count + 1 <= itemMaxLimit ) {
              item.count++;
              this.store.dispatch(
                new SetModifierItem(this.modifier._id, {
                  id: item._id,
                  count: item.count
                })
              );
            }
          } else {
            item.count++;
            this.store.dispatch(
              new SetModifierItem(this.modifier._id, {
                id: item._id,
                count: item.count
              })
            );
          }
        }
      // } else {
      //   if ((maxLimit > 0 && maxLimit >= count) || maxLimit === 0) {
      //     item.count++;
      //     this.store.dispatch(
      //       new SetModifierItem(this.modifier._id, {
      //         id: item._id,
      //         count: item.count
      //       })
      //     );
      //   }
      // }
    }
    if (type == 'subModifierItem') {
      const { items, maxLimit } = parent;
      let itemMaxLimit = item.maxLimit ? item.maxLimit : 0;
      let count = 0;
      let isAlreadySelected = false;
      items.forEach(i => {
        if (i.count > 0) {
          count += 1;
        }
        if(i._id == item._id && i.count > 0){
          isAlreadySelected = true;
        }
      });
      if (this.checkInput(parent) === false) {
        if ((maxLimit > 0 && maxLimit > count) || maxLimit === 0 || (maxLimit == count && isAlreadySelected)) {
          if (itemMaxLimit) {
            if (item.count + 1 <= itemMaxLimit || itemMaxLimit === 0) {
              item.count++;
          this.store.dispatch(
            new SetSubModifierItem(this.modifier._id, parent._id, {
              id: item._id,
              count: item.count
            })
          );
            }
          }
          else{
            item.count++;
            this.store.dispatch(
              new SetSubModifierItem(this.modifier._id, parent._id, {
                id: item._id,
                count: item.count
              })
            );
          }
          
        }
      } else {
        if ((maxLimit > 0 && maxLimit > parent.count) || maxLimit === 0 || (maxLimit == parent.count && isAlreadySelected)) {
          if (itemMaxLimit) {
            if (item.count + 1 <= itemMaxLimit || itemMaxLimit === 0) {
              item.count++;
          this.store.dispatch(
            new SetSubModifierItem(this.modifier._id, parent._id, {
              id: item._id,
              count: item.count
            })
          );
            }
          }
          else{
            item.count++;
            this.store.dispatch(
              new SetSubModifierItem(this.modifier._id, parent._id, {
                id: item._id,
                count: item.count
              })
            );
          }
        }
      }
    }
  }
  }

  decreaseCounter(item, parent, type) {
    if (item.count > 0) {
      let minLimit = item.minLimit;
      if(minLimit && minLimit > (item.count -1)){
        return;
      }
      item.count--;
    } else {
      return;
    }
   
    if(item.count >= 0 ){
      if (type == 'subModifierItem') {
        this.store.dispatch(
        new RemoveSubModifierItem(this.modifier._id, parent._id, {
          id: item._id,
          count: item.count
        }));
      }
      if (type == 'item') {
        this.store.dispatch(
          new RemoveModifierItem(this.modifier._id, { id: item._id, count:item.count })
        );
      }
    }
   
  }
}

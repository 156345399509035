import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  ClearAllDeals,
  ClearDeals,
  LocationState,
  RemoveDeal,
  WhitelabelState,
} from '../core';

@Injectable()

export class DealsGuard implements CanActivate {
  constructor(private router: Router, private store: Store) {}

  canActivate() {
    const otherConfig = this.store.selectSnapshot(WhitelabelState.getOther);
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if (otherConfig?.enableCoupons && selectedLocation) {
      return true;
    } else if (selectedLocation) {
      this.clearStore();
      this.router.navigate([`/locations/${selectedLocation._id}/home`]);
    } else {
      this.clearStore();
      this.router.navigate([`/locations`]);
      return false;
    }
  }

  clearStore() {
    this.store.dispatch(new ClearAllDeals());
    this.store.dispatch(new ClearDeals());
    this.store.dispatch(new RemoveDeal());
  }
}

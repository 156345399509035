import { Component } from "@angular/core";
import { DealsBaseComponent } from "@moduurnv2/libs-orderingapp/src";

@Component({
  selector: 'moduurnv2-no-deal',
  templateUrl: './no-deal.component.html',
  styleUrls: ['./no-deal.component.scss'],
})

export class NoDealComponent extends DealsBaseComponent{

}

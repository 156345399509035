import { Component, OnInit } from '@angular/core';
import { AddressSelectorBaseComponent } from '@moduurnv2/libs-orderingapp/src';
import {AddressWrapperComponent} from './../../../shared/address-wrapper/address-wrapper.component'
@Component({
  selector: 'moduurnv2-address-selector',
  templateUrl: './address-selector.component.html',
  styleUrls: ['./address-selector.component.scss']
})
export class AddressSelectorComponent extends AddressSelectorBaseComponent {

  openChangeAdrs() {
    // this.store.dispatch(new ChangePopupStatus(true));
    this.modal.showSmallModal(AddressWrapperComponent);
  }
}

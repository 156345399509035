<div class="background-overlay">
  <div class="content-modal">
    <div class="modal-content">
      <ng-container>

<div class="description-container close-position">
  <img [src]="location_access" data-qa="order-details-back" class="banner" alt="location access"/>
  <!-- <img
  src="/assets/images/Close.svg"
  (click)="locationAccessDenied()"
  data-qa="close-button"
  class="close"
  aria-label="close"
  alt="close"
/> -->
</div>
<div class="description-container"> <img [src]="organizationLogo" data-qa="order-details-back" class="logo" alt="organization-img"/></div>
<div class="description-container denied" [theme]="themeOptionDescription" *ngIf="!isLocationAccessLanding"> {{'Please enable location services on your device to use WestJet Lounge Services.'}}</div>
<div class="description-container elevation" [theme]="themeOptionDescription" *ngIf="isLocationAccessLanding">{{selectedLocationName +' would like to access your location.'}}</div>
<div class="description-container access" [theme]="themeOptionDescription" *ngIf="isLocationAccessLanding"> {{'This website will use your precise location to access lounge services.'}}</div>
<div class="button-container" *ngIf="isLocationAccessLanding"><div><button type="button" [theme]="themeOptions" (click)="continue()" class="btn-primary">{{ 'continue-adrs' | translate}}</button></div></div>
      </ng-container>
      <ng-template #futureLoader>
        <div class="loader-date loader"></div>
        <div class="loader-time loader"></div>
      </ng-template>
    </div>
  </div>
</div>

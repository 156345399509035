import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import {
  SharedService,
  GiftAndLoyaltyCardService,
  SweetAlertService,
  PaymentMethodState,
  FetchGiftLoyaltyCards
} from '@moduurnv2/libs-orderingapp/src/core';
import { GiftCardBaseComponent } from '@moduurnv2/libs-orderingapp/src';

import { FormBuilder } from '@angular/forms';
import { AddGiftCardComponent } from '../add-gift-card/add-gift-card.component';
import { PaymentCardDesktopComponent } from '../payment-card-desktop/payment-card-desktop.component';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { AddPaymentCardDesktopComponent } from '../add-payment-card-desktop/add-payment-card-desktop.component';

@Component({
  selector: 'moduurnv2-giftloyalty-card',
  templateUrl: './gift-loyalty-card.component.html',
  styleUrls: ['./gift-loyalty-card.component.scss'],
})
export class GiftLoyaltyCardComponent extends GiftCardBaseComponent {
  bgColor: string;
  selectedBgColor: string;
  constructor(
    store: Store,
    router: Router,
    _shared: SharedService,
    fb: FormBuilder,
    loyaltyService: GiftAndLoyaltyCardService,
    modal: ModalService,
    public sweetAlert: SweetAlertService,
    public translate: TranslateService
  ) {
    super(fb, store, router, _shared, loyaltyService, modal, sweetAlert,translate);
    this.bgColor = 'transparent linear-gradient(115deg, #000000 0%, #444444 100%) 0% 0% no-repeat padding-box';
    this.selectedBgColor = 'transparent linear-gradient(111deg, #71262600 0%, #D63F4000 100%) 0% 0% no-repeat padding-box';
  }
  addGiftCard() {
    if (!this.isMobileView)
      this.modal.showMediumModalDesktop(AddGiftCardComponent, {
        needHeader: true,
        headerTitle: 'Gift/Loyalty Card',
        whenClosed:()=>{
          this.store.dispatch(new FetchGiftLoyaltyCards());
        }
      });
    else super.addGiftCard();
  }
  continue() {
    super.continue();
    let savedCreditCards = this.store.selectSnapshot(PaymentMethodState.getSavedCards);
    console.log('savedCreditCards',savedCreditCards);
    if(this.pageCount > 2 && this.isOtherPayment && !this.isMobileView && savedCreditCards && savedCreditCards.length == 0){
      this.modal.showMediumModalDesktop(AddPaymentCardDesktopComponent, {
        needHeader: true,
        headerTitle: this.translate.instant('add-payment-card'),
      });
    } else
    if (this.pageCount > 2 && this.isOtherPayment && !this.isMobileView) {
      this.modal.showMediumModalDesktop(PaymentCardDesktopComponent, {
        needHeader: true,
        headerTitle: 'Credit Card'
      });
    }
  }

}

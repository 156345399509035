import { Component, OnInit } from '@angular/core';
import { VenueTableSelectionBaseComponent } from '@moduurnv2/libs-orderingapp';
import { Store } from '@ngxs/store';
@Component({
  selector: 'moduurnv2-lounge-table-selection',
  templateUrl: './lounge-table-selection.component.html',
  styleUrls: [
    './lounge-table-selection.component.scss',
    './../../common-styles/venue-selection.scss',
  ],
})
export class LoungeTableSelectionComponent extends VenueTableSelectionBaseComponent implements OnInit {
}

import { Injectable,NgZone } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject,Observable, throwError } from 'rxjs';
import { retry, catchError,map,retryWhen, delay, take ,shareReplay} from 'rxjs/operators';
const navigator :any = window.navigator;
import {SweetAlertService,environment} from '@moduurnv2/libs-orderingapp/src/core';
import { interval,of } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { StateResetAll,StateReset } from 'ngxs-reset-plugin';
import { Router } from '@angular/router';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { ServiceUnavailableComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/service-unavailable/service-unavailable.component';
import {AddressState,HomeState,PaymentMethodState,VenueManagementState,AuthEmailState,AuthState,CartState,CategoryState,CustomerFavouritesState,DealsState,DeliveryState,DiscountsState,GeoLocationState,GiftLoyaltyMethodState,LocationDefaultState,LocationState,MainMenuState,MenuItemState,MenuListState,OrderState,PromotionalCategoryState,PushNotificationState,SelectedOrderState,ViewAllOrdersState,WhitelabelState} from '@moduurnv2/libs-orderingapp/src/core/state'
@Injectable({
    providedIn: 'root'
  })
export class PWAService {

    pwaDisplay: BehaviorSubject<'header'|'banner'> = new BehaviorSubject(null);
    offline: BehaviorSubject<boolean> = new BehaviorSubject(false);
    deferredAlert;
    ua = navigator.userAgent;

    constructor(public workerUpdate: SwUpdate,public sweetAlert: SweetAlertService,ngZone: NgZone,public translate: TranslateService,private store:Store,private router: Router,private modal:ModalService,private http: HttpClient) {
        console.log('Servicee worker update called');
        workerUpdate.available.subscribe(event => {
          console.log('current version is', event.current);
          console.log('available version is', event.available);
          workerUpdate.activateUpdate().then(() => this.showAppUpdateAlert());
        });
        workerUpdate.activated.subscribe(event => {
          console.log('old version was', event.previous);
          console.log('new version is', event.current);
        });
        ngZone.runOutsideAngular(() => {
          interval(60 * 1000 * 15).subscribe(() => {
            console.log('Inside Interval')
            ngZone.run(() => workerUpdate.checkForUpdate());
          });
        });
      }
     async showAppUpdateAlert() {
        const header = this.translate.instant('app-update-available');
        const message = 'Updating now will clear any unsaved data. We recommend you to update for secure ordering';
        await this.sweetAlert.confirm(header, message)
        .then((choice) => {
          if (choice) {
            this.doAppUpdate();
          } else {
            return;
          }
        });

      }
      doAppUpdate() {
        console.log('before updating',new Date())
           this.workerUpdate.activateUpdate().then(() => {

             // excluded states = HomeState,CategoryState,CustomerFavouritesState,VenueManagementState,GiftLoyaltyMethodState,WhitelabelState,ViewAllOrdersState
             if(this.router.url !='/locations')
             this.router.navigate([`locations`]);
             this.modal.closeAllModals();
              setTimeout(()=>{ 
               const state = this.store.selectSnapshot((app) => app);
                localStorage.removeItem('refinedLocation');
                localStorage.clear();
                sessionStorage.clear();                   
                console.log('1')
                if(state.paymentMethods && Object.keys(state.paymentMethods).length > 0)
                this.store.dispatch(new StateReset(PaymentMethodState));
                console.log('2')
                if(state.authEmail && Object.keys(state.authEmail).length > 0)
                this.store.dispatch(new StateReset(AuthEmailState));
                console.log('3')
                if(state.auth && Object.keys(state.auth).length > 0)
                this.store.dispatch(new StateReset(AuthState));
                console.log('4')
                if(state.address && Object.keys(state.address).length > 0)
                this.store.dispatch(new StateReset(AddressState));
                console.log('5')
                if(state.cart && Object.keys(state.cart).length > 0)
                this.store.dispatch(new StateReset(CartState));
                console.log('6')
                if(state.deals && Object.keys(state.deals).length > 0)
                this.store.dispatch(new StateReset(DealsState));
                console.log('7')
                if(state.delivery && Object.keys(state.delivery).length > 0)
                this.store.dispatch(new StateReset(DeliveryState));
                console.log('8')
                if(state.discounts && Object.keys(state.discounts).length > 0)
                this.store.dispatch(new StateReset(DiscountsState));
                console.log('9')
                if(state.geolocation && Object.keys(state.geolocation).length > 0)
                this.store.dispatch(new StateReset(GeoLocationState));
                console.log('10')
                if(state.locationDefaults && Object.keys(state.locationDefaults).length > 0)
                this.store.dispatch(new StateReset(LocationDefaultState));
                console.log('11')
                if(state.location && Object.keys(state.location).length > 0)
                this.store.dispatch(new StateReset(LocationState));
                console.log('12')
                if(state.mainmenu && Object.keys(state.mainmenu).length > 0)
                this.store.dispatch(new StateReset(MainMenuState));
                console.log('13')
                if(state.menuItem && Object.keys(state.menuItem).length > 0)
                this.store.dispatch(new StateReset(MenuItemState));
                console.log('14')
                if(state.menulist && Object.keys(state.menulist).length > 0)
                this.store.dispatch(new StateReset(MenuListState));
                console.log('15')
                if(state.order && Object.keys(state.order).length > 0)
                this.store.dispatch(new StateReset(OrderState));
                console.log('16')
                if(state.promotionalcategory && Object.keys(state.promotionalcategory).length > 0)
                this.store.dispatch(new StateReset(PromotionalCategoryState));
                console.log('17')
                if(state.pushnotification && Object.keys(state.pushnotification).length > 0)
                this.store.dispatch(new StateReset(PushNotificationState));
                console.log('18')
                if(state.selectedOrderIdToView && Object.keys(state.selectedOrderIdToView).length > 0)
                this.store.dispatch(new StateReset(SelectedOrderState));
                console.log('19')
                this.store.dispatch(new StateResetAll());
                // this.store.dispatch(new StateReset(HomeState,CategoryState,CustomerFavouritesState,VenueManagementState,GiftLoyaltyMethodState,WhitelabelState,ViewAllOrdersState))
                setTimeout(()=>{ 
                document.location.reload();
                console.log('New version loaded',new Date());
               }, 1000);
              }, 500);

            });
        }
    watchForSWUpdate() {
        this.workerUpdate.available.subscribe(event => {
            this.workerUpdate.activateUpdate().then(() => document.location.reload());
        });
    }

    addToHomeScreenExtender() {
        this.initDisplayState();
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            this.deferredAlert = e;

            // If android and the banner was not already dismissed to header
            if(this.checkAndroid)
                if(this.pwaDisplay.value !== 'header')
                    this.pwaDisplay.next('banner');
                else
                    this.pwaDisplay.next('header');
            else
                this.pwaDisplay.next('header');
                
        });
    }

    saveToHome() {
        this.deferredAlert.prompt();

        if(this.checkSamsung){
            this.deferredAlert = null;
            this.pwaDisplay.next(null);
        }else{
            this.deferredAlert.userChoice
            .then((choiceResult) => {                
                if(choiceResult && choiceResult.outcome === "accepted"){
                    this.deferredAlert = null;
                    this.pwaDisplay.next(null);
                }
            })
            .catch(console.error);
        }
    }

    initOfflineListeners(){
        window.addEventListener('online',  (event) => {
            const condition = navigator.onLine;
            this.offline.next(!condition);
        });
        window.addEventListener('offline', (event) => {
            const condition = navigator.onLine;
            this.offline.next(!condition);
        });
    }

    checkServerHealth(){
      this.checkHealth().subscribe((x)=>{
        console.log('data: ', x);
    },err=>{
      console.log('err',err)
      this.modal.showFullModal(ServiceUnavailableComponent);
    })
    }

    checkHealth(): Observable<any> {
      const healthCheckUrl = environment.healthCheckApiUrl +'healthCheck';
      return this.http.get<any>(healthCheckUrl)
        .pipe(
          map((res: any) => {
            return res;
          }),
          // retryWhen(errors => 
          //   errors.pipe(take(3))
          //   ),
          catchError(err => {
              console.log(err);
              if(navigator.onLine){
                this.modal.showFullModal(ServiceUnavailableComponent);
              }
              return of(null);
             })
        ) 
    }

    initDisplayState(){
        if(this.checkIOS && !this.checkStandAlone && this.checkSafari)
            this.pwaDisplay.next('header');
        else if (this.checkFirefoxAndroid)
            this.pwaDisplay.next('header');
        else
            this.pwaDisplay.next(null);
    }

    get checkStandAlone(){
		const navigator:any = window.navigator;
        return window.matchMedia('(display-mode: standalone)').matches || (('standalone' in navigator) && (navigator.standalone));
	}
	
	get checkSafari(){
        const ua = this.ua.toLowerCase(); 
		return (ua.indexOf('safari') != -1) && ((ua.indexOf('chrome') == -1) && ua.indexOf('crios') == -1);
    }
    
    get checkSamsung(){
        const ua = this.ua.toLowerCase(); 
        return ua.indexOf('samsungbrowser') !== -1;
    }

    get checkIOS(){
		return (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' &&  navigator.maxTouchPoints > 1)) && !(window as any).MSStream;
    }
    
    get checkFirefoxAndroid(){
        return /Firefox|firefox/i.test(this.ua) && this.checkAndroid;
    }

    get checkAndroid(){
        return /Android/i.test(this.ua);
    }

}

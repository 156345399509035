<ng-container *ngIf="cart$ | async as cart">
  <div
    class="view-cart"
    *ngIf="cart && cart.count"
    (click)="proceedToNextStep()"
    [theme]="themeOptions"
    attr.aria-label="{{continueBtnText}}"
  >
    <div class="view-cart-grid">
      <span class="item-count"
        >{{ cart?.count }} {{ itemText(cart.count) | translate }}</span
      >
      <span class="item-price"
        >{{ cart?.subTotal | formattedCurrency }} <span class="loyalty" *ngIf="cart && cart.employeeLoyaltyPoints &&  cart.employeeLoyaltyPoints.isEmployeeLoyalty && cart.employeeLoyaltyPoints.totalLoyaltyPointsInCart">{{cart.employeeLoyaltyPoints.totalLoyaltyPointsInCart}} PTS</span>
        <span class="tax-container" *ngIf="!allowNoPaymentOrdering"
          >{{ 'plus-taxes' | translate }}
        </span></span
      >
    </div>
    <ng-container *ngIf="locationStatus$ | async as locationStatus">
      <div
        class="view-order"
        *ngIf="
          (locationStatus &&
            locationStatus.isOpenToday &&
            locationStatus?.isCurrentlyOpen) ||
          virtual == true
        "
      >
        <span
          class="view-order-button"
          (click)="proceedToNextStep()"
          id="viewOrderBtn"
          >{{ continueBtnText }}</span
        >
      </div>
      <div
        class="view-order-text"
        *ngIf="
          locationStatus &&
          (!locationStatus.isOpenToday || !locationStatus.isCurrentlyOpen) &&
          virtual == false
        "
      >
        <span>
          {{ locCloseMsg | translate }}
        </span>
      </div>
    </ng-container>
  </div>
</ng-container>

import { Router,ActivatedRoute } from '@angular/router';
import { OnInit } from '@angular/core';
import {
  SharedService,
  Provinces,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { FormBuilder,Validators } from '@angular/forms';
import {
  BaseComponent,
  environment,
  Regex,
  AuthEmailSignUp,
  AuthEmailState,
  SetUserDetailsOnSignUp,
  AuthEmailGenericResponse,
  LocationState,
  CheckResetLinkValid,
  ClearResetLinkValid
} from '@moduurnv2/libs-orderingapp/src/core';


export class EmailResetLinkBaseComponent extends BaseComponent implements OnInit {
  authLinkCheckSubscription: Subscription;
  linkMessage;
  linkValid =undefined;
  @Select(AuthEmailState.getResetLinkValidityResponse) linkValidity$: Observable<
  AuthEmailGenericResponse
  >;
  constructor(public store: Store,
     public router: Router
      ) {
    super();
    this.store.dispatch(new ClearResetLinkValid())
    this.checkLinkValidity()
  }

  ngOnInit() {

  }
  ngOnDestroy() {
    if (this.authLinkCheckSubscription) this.authLinkCheckSubscription.unsubscribe();
  }

  checkLinkValidity() {
    this.authLinkCheckSubscription = this.linkValidity$.subscribe(
      (response) => {
        if(response?.status){
          this.linkValid = response.status ==200 ? true : false;
          this.linkMessage = response.status ==200 ? "" :  "link-message";
        }
      },
      (error) => {
      }
    );
  }


  returnToHome() {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if(selectedLocation && selectedLocation._id)
    this.router.navigate([`/locations/${selectedLocation._id}/home`]);
    else
    this.router.navigate([`/locations`]);
  }

}

import { Component, OnInit,Input } from '@angular/core';
import {
  VenueRoomTimeSelectionBaseComponent
} from '@moduurnv2/libs-orderingapp/src/features/ui/base/venue-room-time-selection-base.component';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import {ModalService} from './../../../shared/modal/modal.service';
import { Router } from '@angular/router';
@Component({
  selector: 'moduurnv2-room-update-selection',
  templateUrl: './room-update-selection.component.html',
  styleUrls: ['./room-update-selection.component.scss','./../../common-styles/venue-update-selection.scss']
})
export class RoomUpdateSelectionComponent extends VenueRoomTimeSelectionBaseComponent implements OnInit {
  

  constructor(store:Store, translate:TranslateService,router:Router, modal:ModalService) { 
    super(store,translate,router,modal)
  }

}

export class FetchGiftLoyaltyCards{
    static readonly type = 'FetchGiftLoyaltyCards';

    constructor() {}
}

export class SetSelectedGiftCard {
    static readonly type = '[GIFTANDLOYALTY] SetSelectedGiftCard';
  
    constructor(public payload: SetSelectedGiftCard) {}
  }
export class RemoveGiftCard {
    static readonly type = '[GIFTANDLOYALTY] RemoveGiftCard';
  
    constructor(public payload) {}
  }
import { Component, Input, OnInit, Output,EventEmitter ,SimpleChanges } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BaseComponent, environment, LocationState, SharedService, WhitelabelState } from '@moduurnv2/libs-orderingapp/src';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalService } from '../../../shared/modal/modal.service';

@Component({
  selector: 'moduurnv2-landing-template',
  templateUrl: './landing-template.component.html',
  styleUrls: ['./landing-template.component.scss']
})
export class LandingTemplateComponent extends BaseComponent implements OnInit {
  // @Select(WhitelabelState.getLandingPage) landingPage$: Observable<any>;
  // @Select(LocationState.getSelectedLocation) selectedLocation$: Observable<any>;

  orgWhiteLabelDetails = this.store.selectSnapshot(
    WhitelabelState.getWhitelabel
  );

  @Output() closeClick = new EventEmitter();

  locSplash:boolean;
  isMobileView:boolean;
  // locationInfo: any;
  isPrevEnable : boolean = false;
  isNextEnable : boolean = true;

  templateViewDetails: any;
  templateIndx: number = 0;
  // _modalClose: any = null;
  // landingTemplateObj: any;
  imgBaseUrl:string = environment.imagebaseurl;
  restaurantLogo: string;

   @Input('landingTemplateObj') landingTemplateObj;
  templateDetails: any;
  
  constructor(private store: Store, private modal: ModalService, private shared: SharedService) {
    super();
    let whiteLabel = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
    if (whiteLabel) {
      this.restaurantLogo = this.imgBaseUrl + whiteLabel.restaurantLogo;
    }
   }

    // set modalClose(modalRef: any) {
    //   this._modalClose = () => {
    //     this.modal.closeModal(modalRef);
    //   };
    // }

  ngOnInit(): void {
    this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
    // this.isMobileView = this.shared.isMobileScreen();
    // this.selectedLocation$
    // .pipe(takeUntil(this.destroy$))
    // .subscribe((location) => {
    //   if (location && location.landingPage) {
    //     this.locationInfo = location.landingPage.find(page => page.isPublished);
    //     this.locSplash = !Object.keys(sessionStorage).includes(`Splash ${location._id}`);
    //     if(this.locationInfo && this.locationInfo.isPublished && this.locSplash){
    //       sessionStorage.setItem(`Splash ${location._id}`, 'true');
    //     }        
    //   }
    // });
    
    this.templateDetails = this.landingTemplateObj.template.templateDetails;
    this.templateViewDetails = this.templateDetails.pages[this.getCurrentPageIndex(1)].templateBlocks;
    this.isPrevEnable = false;
  }



  ngOnChanges(changes: SimpleChanges): void {
    // console.log("changes", changes);
    // console.log("value changed", this.landingTemplateObj);
    this.templateDetails = this.landingTemplateObj.template.templateDetails;
    this.templateViewDetails = this.templateDetails.pages[this.getCurrentPageIndex(1)].templateBlocks;
    this.isNextEnable = true;
    this.isPrevEnable = false;
  }

  templateNavigation(event,navState,tempIndx){
    if(event){
      event.stopPropagation();
      event.preventDefault();
    }
     if(navState){
        this.templateIndx = this.getTemplateIndex(navState) ;
    } else{
        this.templateIndx = tempIndx;
    }  
    this.templateViewDetails = this.templateDetails.pages[this.templateIndx].templateBlocks;
    this.isPrevEnable = this.templateIndx > 0;
    this.isNextEnable = this.templateIndx != this.templateDetails.pages.length-1;
  }

  getTemplateIndex(state){
      if(state == 'Prev'){
       return this.templateIndx == 0 ? this.templateDetails.pages.length - 1 : this.templateIndx - 1;
      } else {
          return (this.templateIndx == this.templateDetails.pages.length - 1) ? 0 : this.templateIndx + 1;
      }
  }

  goToUrl(navUrl){
    // window.location.href = navUrl;
    if(navUrl.includes(this.orgWhiteLabelDetails.webOrderingUrl)){
      window.open(navUrl, '_self').focus();
    }else
    {
      window.open(navUrl, '_blank').focus();
    }
  }
  

   close(event) {
    if(event){
      event.stopPropagation();
      event.preventDefault();
    }
    this.closeClick.emit(this.landingTemplateObj.level);
    // window.history.back();
    // if (this._modalClose) this._modalClose();
  }

  getImage(imgObject){
    let imgurl = this.imgBaseUrl + imgObject.url;
    if(!this.isMobileView && imgObject.desktopUrl)
    imgurl = this.imgBaseUrl + imgObject.desktopUrl;
    return imgurl;
  }

  setNavDotsStyle(navIndx) {
    let color = this.getPageFontColor();
    if(this.templateIndx === navIndx){
      return {
        'background':`${color} 0% 0% no-repeat padding-box`,
        'box-shadow': `0px 0px 8px ${color}`
      }
    } else {
      return {
        'background':`${color} 0% 0% no-repeat padding-box`,
        'opacity':'0.5'
      }
    }

  }

  getCurrentPageIndex(currentPageNumber) {
    if (
      currentPageNumber &&
      this.templateDetails.pages &&
      this.templateDetails.pages.length
    ){
      this.templateIndx = this.templateDetails.pages.findIndex(
        (page) => page.pageNo == currentPageNumber
      );
      return this.templateIndx;
    }
  }

  getColorForBlocks(templateData){
    if(templateData.data && templateData.data.color){
      return templateData.data.color;
    } else {
      return this.templateDetails.fontColor;
    }
  }

  getBackgroundColorForBlocks(templateData){
    if(templateData.data && templateData.data.bgcolor){
      return templateData.data.bgcolor;
    } else {
      return '#fff';
    }
  }

  getPageFontColor(){
    return this.templateDetails.pages[this.templateIndx]?.color ?
     this.templateDetails.pages[this.templateIndx].color : this.templateDetails.fontColor
  }
}

// Common
import { Component, OnInit, Input } from '@angular/core';
// Services
import { Store } from '@ngxs/store';
import { ModalService } from '../modal/modal.service';

@Component({
  selector: 'service-unavailable',
  templateUrl: './service-unavailable.component.html',
  styleUrls: ['./service-unavailable.component.scss']
})
export class ServiceUnavailableComponent implements OnInit {
  flicker = '0';
  @Input() data:any = null;
  @Input() _modalClose: any;
  constructor(private modal:ModalService) {
    setInterval(() => {
      setTimeout(() => {
        this.flicker = Math.random().toFixed(2);
      }, parseFloat(this.flicker) * 900);
    }, 250)
  }

  ngOnInit() {
  }

  getData(){
    this._modalClose(true);
  }

  closePopup() {
    if (this._modalClose) this._modalClose();
  }

  set modalClose(modalRef: any) {
    this._modalClose = (data) => {
        this.modal.closeAllComponentModals(modalRef, data);
    };
  }

  checkForApp(){
    document.location.reload();
  }

}

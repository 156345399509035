import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  AuthState,
  Deals,
  DealsBaseComponent,
  CartState,
  SharedService
} from '@moduurnv2/libs-orderingapp/src';
// import { LoginDesktopComponent } from '../../../login/components/login-desktop/login-desktop.component';
import { DealviewDetailsComponent } from '../deal-view-details/deal-view-details.component';

import { AnalyticsHandler } from '@moduurnv2/core/services';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { ModalService } from '../../../shared/modal/modal.service';

@Component({
  selector: 'moduurnv2-deals-list',
  templateUrl: './deals-list.component.html',
  styleUrls: ['./deals-list.component.scss'],
})
export class DealsListComponent extends DealsBaseComponent {
  constructor(
    store: Store,
    router: Router,
    shared: SharedService,
    modal: ModalService,
    public analyticsHandler: AnalyticsHandler,
  ) {
    super(shared, store, modal, router, analyticsHandler);
    let cart = store.selectSnapshot(CartState.getCart);    
  }
  @Input() deal: Deals;
  displayDetails: boolean = false;
  @Output() close = new EventEmitter();

  applyPromoCode(coupon: any) {
    const auth = this.store.selectSnapshot(AuthState);
    if (auth?.loggedInUser?.isLoggedIn) {
      this.selectDeal(coupon);
      this.close.emit();
    }   
    // else {
    //   if(!this.isMobileView){
    //     this.modal.showMediumModalDesktop(LoginDesktopComponent);
    //   }else{
    //     this.continue();
    //   }
    // }
  }
  viewDealDetails(deal) {
    if (!this.isMobileView)
      this.modal.showMediumModalDesktop(DealviewDetailsComponent, {
        data: { deal: deal },
      });
    else {
      this.modal.showMobileModal(DealviewDetailsComponent,{
        data:{deal:deal}
      })
      // this.displayDetails = true;
    }
    let cart = this.store.selectSnapshot(CartState.getCart);
    if(cart?.cart_id)
    this.analyticsHandler.setEvents("deals_view", "deals", "view",  cart.cart_id, deal);
  } 
  // closeDealDetails() {
  //   this.displayDetails = false;
  // }
}

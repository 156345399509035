<div class="grey-container">
<div #overlay class="overlay" id="custom-overlay">
  <!-- <button #closeButton (click)="closeModal($event)" class="close-button">
          <span aria-hidden="true">X</span>
      </button> -->
  <div class="modal-container" id="custom-overlay-container">
    <ng-template appModalContent></ng-template>
  </div>
  </div>
</div>

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LoginBaseComponent } from '@moduurnv2/libs-orderingapp';
import { AuthService } from '@moduurnv2/web/core';
import { environment, LogService, SharedService, WhitelabelState } from '@moduurnv2/libs-orderingapp/src/core';
import { Store } from '@ngxs/store';
import { AuthService as AzureAuthService } from '../../core/auth.service';
import { takeUntil } from 'rxjs/operators';
import { NavigationBackService } from '@moduurnv2/libs-orderingapp';
@Component({
  selector: 'moduurnv2-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends LoginBaseComponent implements OnInit {
  allowEmailAuth:boolean;
  isMobile:boolean;
  organizationLogo: any;
  constructor(
    private _authService: AuthService,
    router: Router,
    private _logService: LogService,
    public store: Store,
    private authService: AzureAuthService,
    private shareService: SharedService,  
    navigationService: NavigationBackService  
  ) {
    super(router, store,navigationService);
  }

  ngOnInit(){
   
    this.shareService.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobile= isMobileView)
    );
    let whitelabel = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
    this.organizationLogo =
      environment.imagebaseurl + whitelabel.restaurantLogo;
    const { allowOnlyGuestUsers, isAzureSSO, allowEmailAuth } = this.store.selectSnapshot(WhitelabelState.getOther);
    this.allowEmailAuth = allowEmailAuth;
    this.loginProviders = this.loginProviders.filter( provider => {
      if(provider.textCode === 'LOGINPROVIDER04' && allowOnlyGuestUsers === false && allowEmailAuth !== false)
        return true;
      if(provider.textCode === 'LOGINPROVIDER05' && isAzureSSO.isAllow)
        return true;
    });
  }

  googleLogin() {
    this._authService
      .googleSignIn()
      .then((user) => {
        if (user) {
          this._authService.userDetails = user;
          localStorage.setItem('loggedInUser', JSON.stringify(user));
          this.goBack();
        }
      })
      .catch((err) => {
        this._logService.error(err);
      });
  }

  googleUserDetails() {
    this._authService.googleLoggedInUserDetails();
  }

  setImageGradientStyle(gradient) {
    return {
      'background-image': `linear-gradient(${gradient.primary}, ${gradient.secondary})`,
    };
  }

  emailLogin() {
    this.pageToShow = 'LOGIN';
  }

  signUp(providerTextCode?) {
    this.isSignupClicked = true;
    if(!this.setTerms)
      return;
    // if (this.allowEmailAuth === false || this.allowEmailAuth && this.setTerms === true) {
    if (this.allowEmailAuth === false || this.allowEmailAuth) {
      switch (providerTextCode) {
        case 'LOGINPROVIDER03':
          break;
        case 'LOGINPROVIDER04':
          this.emailSignUp();
          break;
        case 'LOGINPROVIDER05':
          this.azureSSO();
          break;
        default:
          break;
      }
    }
  }

  emailSignUp() {
    this.pageToShow = 'REGISTER';
  }

  showEmailAuthModal($event) {
    if ($event == false) this.pageToShow = undefined;
  }
  
  azureSSO() {
    console.log('azure');
    this.authService.login('locations');
  }

}

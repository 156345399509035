<div
  class="review-and-pay-container"
  [ngClass]="{ show: true }"
  *ngIf="cartItems && cartItems[0] && cartPrice && locationDefaults"
>
  <div class="header">
    <div class="back-btn-outer">
      <!-- <div class="back-btn" (click)="closeReview()" aria-label="back button">  -->
      <img
        src="assets/images/Chevron-Back.svg"
        class="left-icon"
        aria-label="back button"
        (click)="closeReview()"
        alt="chevron back"
      />
      <!-- </div> -->
    </div>
    <div class="heading">
      {{
        selectedDelivery && selectedDelivery.textCode == 'DELIVERYTYPE08'
          ? ('ReviewOrder' | translate)
          : ('ReviewPay' | translate)
      }}
    </div>
    <!-- <img
      [src]="icon.close"
      data-qa="close-button"
      class="close"
      aria-label="close"
      (click)="closeReview()"
      alt="close"
      /> -->
    <moduurnv2-address-selector
      *ngIf="
        selectedDelivery &&
        (selectedDelivery.textCode == 'DELIVERYTYPE01' ||
          selectedDelivery.textCode == 'DELIVERYTYPE04')
      "
      class="delevering-to"
    ></moduurnv2-address-selector>
    <moduurnv2-venue-update-selector
      *ngIf="
        selectedDelivery &&
        ((selectedDelivery.textCode == 'DELIVERYTYPE08' ||
          selectedDelivery.textCode == 'DELIVERYTYPE09' ||
          selectedDelivery.textCode == 'DELIVERYTYPE10')||
          isQRScanOrder())
      "
      class="delevering-to"
    ></moduurnv2-venue-update-selector>
  </div>
  <div
    class="review-and-pay-content"
    [style.margin-top]="
      selectedDelivery &&
      ((selectedDelivery.textCode == 'DELIVERYTYPE01' ||
        selectedDelivery.textCode == 'DELIVERYTYPE04' ||
        selectedDelivery.textCode == 'DELIVERYTYPE08' ||
        selectedDelivery.textCode == 'DELIVERYTYPE09' ||
        selectedDelivery.textCode == 'DELIVERYTYPE10')||
        isQRScanOrder())
        ? '14.5em'
        : '7.5em'
    "
    [style.height]="
      selectedDelivery &&
      ((selectedDelivery.textCode == 'DELIVERYTYPE01' ||
        selectedDelivery.textCode == 'DELIVERYTYPE04' ||
        selectedDelivery.textCode == 'DELIVERYTYPE08' ||
        selectedDelivery.textCode == 'DELIVERYTYPE09' ||
        selectedDelivery.textCode == 'DELIVERYTYPE10')||
        isQRScanOrder())
        ? 'calc(100% - 13.5em)'
        : 'calc(100% - 7.5em)'
    "
  >
    <div class="review-and-pay">
      <ng-container *ngIf="!isQRScanOrder()">
      <moduurnv2-inline-time-picker
        *ngIf="
          selectedDelivery && selectedDelivery.textCode !== 'DELIVERYTYPE08';
          else deliveryETA
        "
      ></moduurnv2-inline-time-picker>
    </ng-container>
      <ng-template #deliveryETA>
        <div class="deliveryETA">
          <img
            src="assets/images/Calendar_Icon_Small.svg"
            class="calendar-icon"
            alt="calendar"
          />
          <label
            >{{ 'estimated-time' | translate }}:
            <span class="eta-time"
              >{{ orderSettings?.minPrepTime }} -
              {{ orderSettings?.maxPrepTime }} mins</span
            >
          </label>
        </div>
      </ng-template>
      <div class="border"></div>
      <div class="combo-container" [class.dash-border]="cartItems[0].combos.length > 0 && cartItems[0].menuItems.length > 0" *ngIf="cartItems[0].combos.length > 0">
      <moduurnv2-combo-list-cart></moduurnv2-combo-list-cart>
      </div>
      <!-- <span class="seperator-line" *ngIf="cartItems[0].combos.length > 0"></span> -->
      <moduurnv2-list-cart
        [items]="cartItems[0].menuItems"
        *ngIf="cartItems[0].menuItems.length > 0"
      >
      </moduurnv2-list-cart>
      <ng-container  *ngIf="getModifiers$ | async as modifiers;">
      <div class="order-level-modifier" *ngIf="orderSettings.isAllowOrderLevelModifier && modifiers.length">
        <hr>
      
        <h4 *ngIf="locationDefaults.activeProfile && locationDefaults.activeProfile.orderLevelModifierConfigurations && locationDefaults.activeProfile.orderLevelModifierConfigurations.name">{{locationDefaults?.activeProfile?.orderLevelModifierConfigurations?.name | formattedLanguage : locationDefaults.activeProfile.orderLevelModifierConfigurations.translation}}</h4>
        <moduurnv2-order-level-modifiers>
                
        </moduurnv2-order-level-modifiers>
      </div>
    </ng-container>    
      <div
        class="special-instructions"
        (click)="openSpecialInstruction()"
        data-qa="special-instruction-button"
        aria-label="special instruction"
        *ngIf="hideSpecialInstructions==false"
        >
        <img [src]="icon.special_instructions" class="icon" alt="special instruction"/>
        <label class="text">
          {{
            specialInstructions
              ? ''
              : isSpecialInstructionPresent
              ? ('edit' | translate)
              : ('add' | translate)
          }}
          {{
            (specialInstructions ? specialInstructions : 'SpecialInstructions')
              | translate
          }}
          <span class="sub-text" aria-label="optional"
            >({{ 'optional' | translate }})</span
          >
        </label>
        <!-- <label class="sub-text option-btn" aria-label="optional"
          >&nbsp;({{ 'optional' | translate }})</label> -->
      </div>
      <div class="show-special-instruction" *ngIf="isSpecialInstructionPresent && hideSpecialInstructions==false" >
        <label>{{ specialInstructionText }}</label>
      </div>
      <ng-container *ngIf="!hideInstructions">
        <moduurnv2-alert
          *ngIf="selectedDelivery.textCode !== 'DELIVERYTYPE08'"
        ></moduurnv2-alert>
      </ng-container>

      <moduurnv2-price-split-up
        *ngIf="!allowNoPaymentOrdering"
      ></moduurnv2-price-split-up>
    </div>
    <div class="order-container">
      <div
        class="place-order"
        *ngIf="(!allowNoPaymentOrdering && !zeroPriceOrder); else zeroPaymentOrder"
      >
       <!-- commenting as part of MV2-1541 to avoid paymetn selection part on left side-->
        <!-- <img
          [src]="getPaymentMethod()"
          [ngClass]="{
            icon: selectedPayment != 'Credit Card',
            'icon-small': selectedPayment == 'Credit Card'
          }"
          data-qa="pay-using-icon"
          alt="credit card"
        />
        <div
          class="pay-using"
          data-qa="pay-using-button"
          (click)="openPaymentView()"
          aria-label="pay using"
        >
          {{ 'PayUsing' | translate }}
        </div>
        <div class="arrow" data-qa="pay-using-icon"></div>
        <div
          class="payment-mode"
          data-qa="payment-mode-button"
          aria-label="payment mode"
          (click)="openPaymentView()"
        >
          {{
            this.selectedPayment
              ? this.selectedPayment
              : ('PaymentMode' | translate)
          }}
        </div> -->
        
        <!-- [ngClass]="{
          disabled:
            this.selectedPayment == null ||
            cartItems[0].isDeliverable === false ||
            (ackrooPaymentDetails &&
              !ackrooPaymentDetails['isAllAmountPayedFromGiftCard'] &&
              !selectedCard && !newCard)
        }" -->
        <button
          class="place-order"
          (click)="checkAndPlaceOrder()"
          data-qa="place-order-button"
          aria-label="place order"
        >
          {{ paymentBtnText }}
          <span class="price">{{
            cartPrice.totalAmount | formattedCurrency
          }}</span> <span *ngIf="cartPrice && cartPrice.employeeLoyaltyPoints &&  cartPrice.employeeLoyaltyPoints.isEmployeeLoyalty && cartPrice.employeeLoyaltyPoints.totalLoyaltyPointsInCart"><span *ngIf="cartPrice.totalAmount">+</span>{{cartPrice.employeeLoyaltyPoints.totalLoyaltyPointsInCart}} PTS</span>
        </button>
      </div>
      <ng-template #zeroPaymentOrder>
        <button
          [ngClass]="{
            disabled: cartItems[0] && cartItems[0].menuItems.length < 1
          }"
          class="place-zero-payorder"
          (click)="checkAndPlaceOrder()"
          data-qa="place-order-button"
          attr.aria-label="{{ 'PlaceOrder' | translate }}"
          aria-label="place order"
          [theme]="themeOptions"
        >
          {{ 'PlaceOrder' | translate }}
        </button>
      </ng-template>
    </div>
  </div>

  <moduurnv2-common-popup
    [modalStatus]="isSpecialInstruction"
    [heading]="
      specialInstructionPopupHeading
        ? specialInstructionPopupHeading
        : 'Special Instructions'
    "
    [topMargin]="'12%'"
    class="special-instructions-window"
    (closePopupEvent)="closeSpecialInstructionView()"
    *ngIf="isSpecialInstruction"
  >
    <textarea
      class="text"
      rows="4"
      custom-autofocus
      tabindex="1"
      #specialInstruction
      data-qa="special-instruction-text"
      aria-label="special instruction text"
      [placeholder]="
        orderSettings?.specialInstructionPopupPlaceholder
          ? orderSettings.specialInstructionPopupPlaceholder
          : 'Please enter instructions here'
      "
      >{{ specialInstructionText }}</textarea
    >
    <!-- <label class="text-limit">0/100</label> -->
    <div class="instruction">{{ specialInstructionPopupMessage }}</div>
    <button
      class="addButton"
      [theme]="themeOptions"
      (click)="saveSpecialInstruction(specialInstruction?.value)"
      data-qa="add-special-instruction-button"
      attr.aria-label="{{
        isSpecialInstructionPresent ? ('save' | translate) : ('add' | translate)
      }}"
    >
      {{
        isSpecialInstructionPresent
          ? ('save-instruction' | translate)
          : ('add-instruction' | translate)
      }}
    </button>
  </moduurnv2-common-popup>
</div>

import {
  BaseComponent,
  environment,
  LocationState,
  FetchLocationStatus,
  DeliveryState,
  Delivery,
  WhitelabelState,
  CartState,
  Whitelabel,
  FetchLocationDefaults,
  LocationDefaultState,
  FetchOrderLevelModifiers,
  OrderLevelModifierState,
} from '@moduurnv2/libs-orderingapp/src/core';
import { OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil } from 'rxjs/operators';

export class OrderLevelModifierCardBaseComponent extends BaseComponent
  implements OnInit {
  @Select(DeliveryState.getSelectedDelivery) delivery$: Observable<Delivery>;
  @Select(WhitelabelState.getWhitelabel) whitelabel$: Observable<Whitelabel>;
  @Select(OrderLevelModifierState.getModifiers)
  getOrderLevelModifiers$: Observable<any>;
  delivery: Delivery;
  bannerImg: string;
  orderLevelModifiers: any;
  constructor(public store: Store) {
    super();
    this.fetchOrderLevelModifiers();
    this.getOrderLevelModifiers$
      .pipe(takeUntil(this.destroy$))
      .subscribe((orderLevelModifiers) => {
        this.orderLevelModifiers = orderLevelModifiers;
      });
    this.delivery$.pipe(takeUntil(this.destroy$)).subscribe((delivery) => {
      if (delivery) {
        this.delivery = delivery;
      }
    });

    this.whitelabel$.pipe(takeUntil(this.destroy$)).subscribe((whitelabel) => {
      if (whitelabel) {
        let image =
          whitelabel.menuPage && whitelabel.menuPage.desktop
            ? whitelabel.menuPage.desktop
            : whitelabel.appSliderImages[0];
        this.bannerImg = environment.imagebaseurl + image;
      }
    });
  }

  ngOnInit() {
    this.getShifts();
    this.store.dispatch(new FetchLocationDefaults());
  }

  public getShifts() {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    const locationId = selectedLocation?._id;
    const orderType = this.delivery?.textCode;
    const futureDate = this.store.selectSnapshot(CartState.getFutureDate);

    let currTime = new Date();
    let selectedDate = new Date();
    if (futureDate) selectedDate = new Date(futureDate);
    let url =
      environment.mobileStoreApiUrl +
      'stores/location/' +
      locationId +
      '/shifts?type=' +
      orderType +
      '&currentTime=' +
      currTime.toISOString() +
      '&timezoneOffset=' +
      currTime.getTimezoneOffset();
    if (futureDate) {
      url =
        environment.mobileStoreApiUrl +
        'stores/location/' +
        locationId +
        '/shifts?type=' +
        orderType +
        '&currentTime=' +
        currTime.toISOString() +
        '&future=true&selectedTime=' +
        selectedDate.toISOString() +
        '&timezoneOffset=' +
        selectedDate.getTimezoneOffset();
    }
    this.store.dispatch(new FetchLocationStatus(url));
  }

  fetchOrderLevelModifiers = () => {
    const selectedLocation = this.store.selectSnapshot(
      LocationDefaultState.getLocationDefaults
    );
    const selectedDelivery = this.store.selectSnapshot(
      DeliveryState.getSelectedDelivery
    );
    let data = {
      menuProfileId: selectedLocation.activeProfile._id,
      deliveryType: selectedDelivery._id,
    };
    this.store.dispatch(new FetchOrderLevelModifiers(data));
  };
}

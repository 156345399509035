<div class="promo-container">
  <ng-container *ngIf="isMobileView; else desktopHeader">
    <div class="banner"><span>{{ 'promo-codes' | translate }}</span></div>
    <div
      class="back-btn"
      (click)="goBack()"
      aria-label="go back"
      data-qa="goBack"
    >
      <img src="assets/images/keyboard_arrow_left_icon.svg" class="left-icon" alt="left button"/>
    </div>
  </ng-container>
  <ng-template #desktopHeader>
    <div class="desktop-header">
      <div class="head-deals">
        <div class="head-text">{{ 'promo-codes' | translate }}</div>
        <img
          src="assets/images/close-modal.svg"
          class="left-icon"
          data-qa="navigate-details-btn"
          (click)="closePopup()"
          alt="close button"
        />
      </div>
    </div>
  </ng-template>
  <!-- <moduurnv2-deals-apply></moduurnv2-deals-apply> -->
  <!-- <div class="title">This Week’s Offers</div> -->
  <ng-container *ngIf="allDeals; else loader">
  <div class="deals" [ngClass]="{'deals-mobile':isMobileView}" *ngIf="allDeals.length>0 && !isEmptyDealScreen; else noDeal">
    <ng-container *ngFor="let deal of allDeals">
      <ng-container *ngIf="deal.show">
      <moduurnv2-deals-list
      (continue)="continue()"
      [deal]="deal"
      (close)="close()"
      ></moduurnv2-deals-list>
    </ng-container>
    </ng-container>
  </div>
  <ng-template #noDeal>
    <moduurnv2-no-deal></moduurnv2-no-deal>
  </ng-template>
</ng-container>
<ng-template #loader>
  <div class="content-loader" *ngFor="let i of counter(5)">
      <div class="img-loader loader"></div>
      <div class="option-item">
        <div class="content-item loader"></div>
        <div class="content-item loader"></div>
        <div class="content-item loader"></div>
        <div class="content-item loader"></div>
        <div class="content-item loader"></div>
      </div>
  </div>
</ng-template>
</div>

// angular
import { Injectable } from '@angular/core';

// app
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { Discount } from '../models';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DiscountsService {
  private Discountsinit: Discount[] = [
    {
      _id: 1,
      name: 'Pepperoni Pizza + Chicken Salad',
      price: '25.99',
      image: 'assets/images/delicious-discounts.png',
      discount: '10%',
    },
  ];
  constructor(private http: HttpClient) {}

  fetchDiscounts(organization_id?: string): Observable<any> {
    return of(this.Discountsinit).pipe();
  }
}

// angular
import { Injectable } from '@angular/core';

// app
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { PaymentMethod } from '../models';
import { of } from 'rxjs';
import { CartState, LocationState } from '..';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodService {

  private Paymentsinit:PaymentMethod = {
    eTransfer: false,
    allowTip: false,
    payInterac: false,
    payLater: true,
    payNow: false,
  };
    constructor(private http: HttpClient, private store: Store) {}
  
    fetchPaymentMethods(): Observable<any> {
      return of(this.Paymentsinit).pipe();
    }
    setPaymentMethod(payload) {
      return of(payload).pipe();
    }

    fetchSavedCards(locationId,customerId,paymentGateway) {
    let  url = '';

      if(paymentGateway == 'PAYMENTGATEWAY02'){
        url = environment.paymentApiUrl + 'payment/bambora/' + locationId + "/getCustomer/" + customerId;
        return this.http.get(url);
      }
      else if(paymentGateway == 'PAYMENTGATEWAY04'){
        url = environment.paymentApiUrl + 'payment/chase/' + locationId + "/getCustomer/" + customerId;
        return this.http.get(url);
      }
      
    }
    removeSavedCard(data,paymentGateway) {
      if(paymentGateway == 'PAYMENTGATEWAY02'){
        let  url = environment.paymentApiUrl + 'payment/bambora/removeCard';
        return this.http.post(url,data);
      }
      else if(paymentGateway == 'PAYMENTGATEWAY04'){
        let  url = environment.paymentApiUrl + 'payment/chase/removeCard';
        return this.http.post(url,data);

      }
     
    }

    tokenizeCard(url,card,header?){
      let options = undefined;
      let body = JSON.stringify(card);
      if (header) {
        options = { headers: header };
      }
      return this.http.post(url,body,options).pipe(this.extractData);
    }

    private extractData(res) {
      let body = res;
      return body || {};
    }

    checkMealDiscount(code) {
      let deliveryLocation =
      localStorage.getItem('selectedAdrsLocation') &&
      localStorage.getItem('selectedAdrsLocation') != 'undefined'
        ? JSON.parse(localStorage.getItem('selectedAdrsLocation'))
        : undefined;
      let lat = deliveryLocation ? deliveryLocation['loc'][1] : undefined;
      let long = deliveryLocation ? deliveryLocation['loc'][0] : undefined;

      let distance = localStorage.getItem('locationDistance')
        ? JSON.parse(localStorage.getItem('locationDistance'))
        : undefined;

      const cart = this.store.selectSnapshot( app => app.cart.cart);
      const location = this.store.selectSnapshot(app => app.location.selectedLocation);

      let url = `${environment.cartApiUrl}${cart.cart_id}/mealCodeCheck?locationId=${location._id}`;
      // let url = `http://localhost:8288/api/v1/carts/${cart.cart_id}/mealCodeCheck?locationId=${location._id}`;

      if (lat && long && distance !== undefined)
        url += `&lat=${lat}&long=${long}&distance=${distance * 1000}`;
      const { futureDate } = this.store.selectSnapshot((app) => app.cart);
      if (futureDate)
        url += `&selectedTime=${new Date(futureDate).toISOString()}`;
      
      return this.http.post(url,{ code, organizationId: Config.organizationId });
    }
  }
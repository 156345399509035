import { MainMenu } from '../models';

export class FetchMainMenuList {
    static readonly type = '[MAINMENU] MainMenu';

    constructor(public payload?: string) {}
}

export class SetSelectedMainMenu {
    static readonly type = '[MAINMENU] Set';

    constructor(public payload: string) {}
}

<div class="notification-box" *ngIf="!isHidden">
  <div class="notification-header">
    <input
      type="checkbox"
      class="notification-check"
      (change)="enableNotification($event)"
    />
    <div class="notification-heading">{{ heading }}</div>
  </div>
  <div class="notification-body">
    <ng-content></ng-content>
  </div>
</div>

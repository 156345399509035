import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  LocationState,
  FetchLocationList,
  SetSelectedLocation,
} from '@moduurnv2/libs-orderingapp/src/core';
import { map } from 'rxjs/operators';

@Injectable()
export class LocationResolver implements Resolve<any> {
  constructor(private store: Store, private router: Router) {}
  resolve(route: ActivatedRouteSnapshot) {
    // const locations = this.store.selectSnapshot(LocationState.getLocationsList);
    // if (locations)
    //   return this.store.selectSnapshot(LocationState.getLocationsList);
    // else {
      return this.store.dispatch(new FetchLocationList()).pipe(
        map(() => {
          const locations = this.store.selectSnapshot(
            LocationState.getLocationsList
          );
          if (locations && locations.length === 1) {
            this.store.dispatch(new SetSelectedLocation(locations[0]._id));
            this.router.navigate(['locations', locations[0]._id, 'home'], {
              queryParamsHandling: 'merge',
              queryParams: route.queryParams,
            });
          } else {
            return locations;
          }
        })
      );
    // }
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {environment} from './../environments/environment';
import { AbstractControl,FormGroup  } from '@angular/forms';
import {Regex,paymentMethods} from './../services/constant';
import {ClearCart,ClearSelectedAddress,ClearDeals,ClearSelectedLocation,} from './../actions';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { AlertPopupComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/alert-popup/alert-popup.component';
import { TranslateService, } from '@ngx-translate/core';
import { State, Selector, StateContext, Action, Store } from '@ngxs/store';
import {  BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  //orgId : string = '5a5480ee2c393854d50189e4'

  private isMobileView=new BehaviorSubject<boolean>(false);
  display=this.isMobileView.asObservable();
  emailRegex = Regex.email;
  constructor(private http: HttpClient,private modal:ModalService,
    public translate: TranslateService,private store:Store,private router:Router) {
   }
  public logData(logDetails){
    // const body = logDetails;
    // const loggingUrl = environment.baseurl + 'logging/organization/' + this.orgId;
    // return this.http.post(loggingUrl,body);
  }
  public getLocations(organizationId:string,deliveryType:string,timezoneOffset){
    let getLocUrl = environment.mobileStoreApiUrl +'stores/organization/' +organizationId +'/stores';
    getLocUrl += `?type=${deliveryType}`;
    getLocUrl += `&timezoneOffset=${timezoneOffset}`;
    return this.http.get(getLocUrl);
  }

  emailpatternValidator() {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = this.emailRegex.test(control.value);
      return valid ? null : { inValidEmail: true };
    };
  }

  MatchPassword(password: string, confirmPassword: string) {
    return (formGroup: FormGroup) => {
      const passwordControl = formGroup.controls[password];
      const confirmPasswordControl = formGroup.controls[confirmPassword];

      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }

      if (confirmPasswordControl.errors && !confirmPasswordControl.errors.passwordMismatch) {
        return null;
      }

      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
      } else {
        confirmPasswordControl.setErrors(null);
      }
    }
  }

  passwordValidator(userControl: AbstractControl) {
    return new Promise(resolve => {
      setTimeout(() => {
        if (this.validatePassword(userControl.value)) {
          resolve(null);
        } else {
          resolve({ passwordValidationError: true });
        }
      }, 500);
    });
  }

  validatePassword(password: string) {
    return (password.length >= 6);
  }

  // isMobileScreen() {
  //   return screen.availWidth < 770;
  // }

  isMobileScreen(screenSize){
    this.isMobileView.next(screenSize);
  }

  forceClearCart(response){
    this.modal.showSmallModal(AlertPopupComponent, {
      data: {
        content: response['msg'] ? response['msg'] : response['message'],
        actionable: false,
        closeBtnText:'OK'
      },
      whenClosed: (data) => {
        this.store.dispatch(new ClearCart());
        const selectedLocation = this.store.selectSnapshot(
          (app) => app.location.selectedLocation
        );
        if (selectedLocation) {
          this.router.navigate([`/locations/${selectedLocation._id}/home`]);
        } else {
          this.router.navigate([`/locations`]);
          return false;
        }
      },
    });
  }

  clearLocation(){
    this.modal.showSmallModal(AlertPopupComponent, {
      data: {
        content: 'Your selected location is closed now.Please select another location',
        actionable: false,
        closeBtnText:'OK'
      },
      whenClosed: (data) => {
          this.store.dispatch(new ClearCart());
          this.store.dispatch(new ClearSelectedAddress());
          this.store.dispatch(new ClearDeals());
          this.store.dispatch(new ClearSelectedLocation());
          localStorage.removeItem('location');
          this.router.navigate([`/locations`]);
      },
    });
  }

  getActivePaymentMethods(modes){
    const {whitelabel,location} = this.store.selectSnapshot((app) => app);
    let enableGiftCard = false;
    if (
      whitelabel?.other?.paymentGateway?.textCode == 'PAYMENTGATEWAY02' &&
      whitelabel?.other?.loyaltyAndGiftCards?.isAllow && location?.selectedLocation?.loyaltyAndGiftCards?.isAllow
    ) {
      enableGiftCard = true;
    }
    const payMealProgram = modes['payMealProgram'];
    let allpaymentMethods = [...paymentMethods];
    allpaymentMethods = this.formCODText(allpaymentMethods);
    let paymentModes = allpaymentMethods.map((value) => {
      if (modes[value.type]) {

        if(enableGiftCard && value['id'] == 4)
          value['active'] = true;
        else if(payMealProgram && value['id'] == 6)
          value['active'] = true;
        else if(value['id'] == 3)
          value['active'] = true;
        else if(value.type === "payLater")
          value['active'] = true;
        else
          value['active'] = false;

      }else if(value['id'] == 6 && payMealProgram) {
        value['active'] = true;
      } else {
        value['active'] = false;
      }
      return value;
    });
    return paymentModes;
  }

  formCODText(allpaymentMethods){
    const selectedDelivery = this.store.selectSnapshot(
      (app) => app && app.delivery && app.delivery.selectedDelivery
    );
    let codIndex = paymentMethods.findIndex(method => method.id == 5);
    if (selectedDelivery?.textCode == 'DELIVERYTYPE02') {
      allpaymentMethods[codIndex].text = this.translate.instant('pay-at-location');
      allpaymentMethods[codIndex].name = this.translate.instant('pay-at-location');
    } else if (
      selectedDelivery?.textCode == 'DELIVERYTYPE08' ||
      selectedDelivery?.textCode == 'DELIVERYTYPE09'
    ) {
      allpaymentMethods[codIndex].text = this.translate.instant('pay-your-server');
      allpaymentMethods[codIndex].name = this.translate.instant('pay-your-server');
    } else {
      allpaymentMethods[codIndex].text = this.translate.instant('cash-on-delivery');
      allpaymentMethods[codIndex].name = this.translate.instant('cash-on-delivery');
    }
    return allpaymentMethods;
  }
}

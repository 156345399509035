<div
  class="deal-listing"
  [ngClass]="{ 'not-applied': checkDeal && deal?._id != checkDeal?._id ,'not-valid': !deal.isAvailable}"
>
  <div class="deal-header">
    <div class="coupon-code">{{ deal.promoCode }}</div>
    <ng-container *ngIf="deal.isAvailable; else unAvailableDeal">
    <button
      class="apply"
      type="button"
      data-qa="apply"
      *ngIf="deal?._id != checkDeal?._id; else removeButton"
      aria-label="apply-promocode"
      [disabled]="checkDeal && deal?._id != checkDeal?._id"
      (click)="applyPromoCode(deal)"
    >
      {{ 'apply' | translate }}
    </button>
    <ng-template #removeButton>
      <button
        class="apply remove"
        type="button"
        data-qa="remove"
        aria-label="remove-promocode"
        (click)="removeDeal()"
      >
        {{ 'remove' | translate }}
      </button>
    </ng-template>
  </ng-container>
  <ng-template #unAvailableDeal>
    <label class="apply unAvailable"> {{ 'not-applicable' | translate }}</label>
  </ng-template>
  </div>
  <div class="description">{{ deal.description }}</div>
  <button
    class="view-details"
    type="button"
    data-qa="view-detail"
    aria-label="view coupon detail"
    (click)="viewDealDetails(deal)"
  >
    {{ 'view-detail' | translate }}
  </button>
</div>
<!-- <moduurnv2-deal-view-details-mobile
  *ngIf="displayDetails"
  (closeEvent)="closeDealDetails()"
  [deal]="deal"
></moduurnv2-deal-view-details-mobile> -->

import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  constructor() {}

  postalCodeValidator(isRequired: boolean = false): ValidatorFn {
    return (control: AbstractControl): object | null => {
      try {
        if (control.value) {
          //For the time being hardcoding the patten. We need to load it from i81n
          const postalCodePattern = /^[0-9]{5}(?:-[0-9]{4})?$/;
          const valid = postalCodePattern.test(control.value);
          return valid
            ? null
            : {
                validationError: { translateKey: 'validation.INVALID_POSTAL' },
              };
        } else if (isRequired) {
          return {
            validationError: { translateKey: 'validation.REQUIRED_POSTAL' },
          };
        }

        return null;
      } catch (error) {
        return {
          validationError: { translateKey: 'validation.INVALID_POSTAL' },
        };
      }
    };
  }
}

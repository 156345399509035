import {
  BaseComponent,
} from '@moduurnv2/libs-orderingapp/src/core';

export class ItemCountBaseComponent extends BaseComponent {

  constructor() {
    super();
  }

  getImageURL(icon) {
    return `assets/images/${icon.filename}.svg`;
  }
}

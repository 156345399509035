import {
  environment,
  FetchLocationInfo,
  WhitelabelState
} from '@moduurnv2/libs-orderingapp/src/core';
import { OnInit, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { EventEmitter } from '@angular/core';
import { SweetAlertService } from '@moduurnv2/libs-orderingapp/src/core';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import {LocationBaseComponent} from './location-base-component';
import { Plugins } from '@capacitor/core';
const { Geolocation } = Plugins;
export class LocationInfoBaseComponent extends LocationBaseComponent implements OnInit {
  // @Input() location;
  location;
  @Input() modalPopup;
  @Output() closeEvent = new EventEmitter();
  @Input() data: any = null;
  currLat: number;
  currLng: number;
  // _modalClose: any = null;
  _data: any = null;
  showLocationImage : boolean = false;
  imageBaseUrl: string  =environment.imagebaseurl;
  constructor(
    router: Router,
    store: Store,
    sweetAlert: SweetAlertService,
    modal: ModalService,
    translate: TranslateService
  ) {
    super(router,store,sweetAlert,translate,modal);
  }
  ngOnInit(): void {
    this.location = this.data.location;
    this.showLocationImage  = this.showLocationImageFromConfig();
    this.store.dispatch(new FetchLocationInfo(this.location._id));
  }
  imgBasePath: string = environment.image_basepath;
  icon = {
    orderHere: this.imgBasePath + environment.images.order_here,
    orderHereWhite: this.imgBasePath + environment.images.order_here_white,
    phone: this.imgBasePath + environment.images.phone,
    favoriteIcon: this.imgBasePath + environment.images.favorite_icon,
    nonFavoriteIcon: this.imgBasePath + environment.images.nonFavorite_icon,
    getDirections: this.imgBasePath + environment.images.get_directions_icon,
    orderForLater: this.imgBasePath + environment.images.order_for_later,
    getDirection: this.imgBasePath + environment.images.get_direction_icon,
    orderForLaterInactive:
      this.imgBasePath + environment.images.order_for_later_inactive,
    logoIcon: environment.image_basepath + environment.images.logo_big,
    close: this.imgBasePath + environment.images.close,
    getDirectionDesktop:
      this.imgBasePath + environment.images.get_direction_desktop_icon,
  };
  // async orderFromOrganization(location, data?) {
  //   const selectedLocation = this.store.selectSnapshot(
  //     LocationState.getSelectedLocation
  //   );

  //   if (location.isLocationOpen) {
  //     if (selectedLocation && selectedLocation._id != location._id) {
  //       let selectedCart = this.store.selectSnapshot(CartState.getCart);
  //       if (selectedCart) {
  //         let vm = this;
  //         await this.sweetAlert
  //           .confirm(
  //             this.translate.instant('are-you-sure'),
  //             this.translate.instant('loc-change-clear-cart')
  //           )
  //           .then((choice) => {
  //             if (choice) {
  //               this.closePopup();
  //               this.router.navigate([`locations/${location._id}/home`]);
  //               this.store.dispatch(new SetSelectedLocation(location._id));
  //               this.store.dispatch(new FetchLocationDefaults(data));
  //             } else {
  //               return;
  //             }
  //           });
  //       } else {
  //         this.closePopup();
  //         this.router.navigate([`locations/${location._id}/home`]);
  //         this.store.dispatch(new SetSelectedLocation(location._id));
  //         this.store.dispatch(new FetchLocationDefaults(data));
  //       }
  //     } else {
  //       this.closePopup();
  //       this.router.navigate([`locations/${location._id}/home`]);
  //       this.store.dispatch(new SetSelectedLocation(location._id));
  //       this.store.dispatch(new FetchLocationDefaults(data));
  //     }
  //   }
  // }
  getSelectedCoordinates() {
    let isRefinedLocation = localStorage.getItem('refinedLocation')
      ? JSON.parse(localStorage.getItem('refinedLocation'))
      : undefined;
    if (isRefinedLocation) {
      this.currLat = isRefinedLocation.coordinates[1];
      this.currLng = isRefinedLocation.coordinates[0];
    }
  }

  getlocation(selectedLng, selectedLat) {
    this.getSelectedCoordinates();
      Geolocation.getCurrentPosition()
        .then((position) => {
          if (position) {
            this.currLat = position.coords.latitude;
            this.currLng = position.coords.longitude;
            this.getSelectedCoordinates();
            this.mapsSelector(
              this.currLat,
              this.currLng,
              selectedLng,
              selectedLat
            );
          }
        })
        .catch((error) => {
          this.mapsSelector(
            this.currLat,
            this.currLng,
            selectedLng,
            selectedLat
          );
        });
  }
  mapsSelector(currentLat, currentLng, selectedLng, selectedLat) {
    if (
      /* if we're on iOS, open in Apple Maps */
      navigator.platform.indexOf('iPhone') != -1 ||
      navigator.platform.indexOf('iPad') != -1 ||
      navigator.platform.indexOf('iPod') != -1
    ) {
      // var newWin = window.open(environment.mapbox.directionURLIOS);
      let url;
      if (currentLat != undefined && currentLng != undefined) {
        url =
          environment.mapbox.directionURLIOS +
          'saddr=' +
          currentLat +
          ',' +
          currentLng +
          '&daddr=' +
          selectedLat +
          ',' +
          selectedLng +
          '&amp;ll=';
      } else {
        url =
          environment.mapbox.directionURLIOS +
          '&daddr=' +
          selectedLat +
          ',' +
          selectedLng +
          '&amp;ll=';
      }
      let newWin = window.open(url);
      if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
        this.sweetAlert.alertWarning(
          '',
          this.translate.instant('device-popup-blocker')
        );
      }
    } else {
      /* else use Google */

      let url;
      if (currentLat != undefined && currentLng != undefined) {
        url =
          environment.mapbox.directionURL +
          'saddr=' +
          currentLat +
          ',' +
          currentLng +
          '&daddr=' +
          selectedLat +
          ',' +
          selectedLng +
          '&amp;ll=';
      } else {
        url =
          environment.mapbox.directionURL +
          '&daddr=' +
          selectedLat +
          ',' +
          selectedLng +
          '&amp;ll=';
      }
      let newWin = window.open(url);
      if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
        this.sweetAlert.alertWarning(
          '',
          this.translate.instant('device-popup-blocker')
        );
      }
    }
  }

  
  showLocationImageFromConfig (): boolean {
    const whitelabelSettings = this.store.selectSnapshot(WhitelabelState.getWhitelabel)
    return whitelabelSettings?.commonSettings?.showLocationImages ? whitelabelSettings.commonSettings.showLocationImages : false;
  }

  // closePopup() {
  //   if (this._modalClose) this._modalClose();
  // }

  // set modalClose(modalRef: any) {
  //   this._modalClose = (data) => {
  //     this.modal.closeComponentModal(modalRef, data);
  //   };
  // }
}

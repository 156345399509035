import { Component, OnInit,Input } from '@angular/core';
import { ModalService } from '../modal/modal.service';
import { SharedService } from '@moduurnv2/libs-orderingapp/src/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'moduurnv2-qr-code-component',
  templateUrl: './qr-code-component.component.html',
  styleUrls: ['./qr-code-component.component.scss']
})
export class QrCodeComponentComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  @Input('valueToGenerateQRCode') valueToGenerateQRCode: any='';
  @Input('descriptionQRCode') descriptionQRCode: any='';
  @Input('sizeQRCode') sizeQRCode: any='';
  @Input('enableCloseButton') enableCloseButton: boolean=false;
  @Input() data:any = null;
  @Input() _modalClose: any;
  isMobileView:boolean;
  constructor(private modal:ModalService, private shared: SharedService,) { }

  ngOnInit(): void {
     this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
    if(this.data) this.populateData(this.data);
  }

  populateData(data){
    this.valueToGenerateQRCode = data.valueToGenerateQRCode;
    this.descriptionQRCode = data.descriptionQRCode;
    this.sizeQRCode = data.sizeQRCode,
    this.enableCloseButton = data.enableCloseButton
  }

  getData(){
    this._modalClose(true);
  }

  closePopup() {
    if (this._modalClose) this._modalClose();
  }

  set modalClose(modalRef: any) {
    this._modalClose = (data) => {
        this.modal.closeAllComponentModals(modalRef, data);
    };
  }
}

import {
  BaseComponent,
  GiftLoyaltyMethodState,
  FetchSavedCards,
  GiftCard,
  AuthState,
  FetchGiftLoyaltyCards,
  environment,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil } from 'rxjs/operators';
import { SharedService } from '@moduurnv2/libs-orderingapp/src/core';
import { Auth } from './../../../core/models';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import { QrCodeComponentComponent} from 'apps/orderingapp/web-orderingapp/src/app/features/shared/qr-code-component/qr-code-component.component';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
export class RewardPointsBaseComponent   extends BaseComponent {
  @Select(GiftLoyaltyMethodState.getSavedCards) savedCards$: Observable<
  GiftCard[]
>;
  @Select(AuthState.getCommonAuthDetails)
  loggedInUserDetails$: Observable<Auth>;

  authSubscription: Subscription;
  loggedInUserDetails;
  paymentDetails = [];
  isMobileView:boolean;
  card: GiftCard = undefined;
  cardBalance=0;
  gift_balance=0;
  loyalty_balance=0;
  imgBasePath: string = environment.image_basepath;
  icon = {
    points: this.imgBasePath + environment.images.points,
  }
  cardPos: number = 0;
  cardListDetails: any;
  cardNumber: string;
  constructor(
    public store: Store,
    private shared: SharedService,
    private router: Router, public modal: ModalService,public translate: TranslateService
  ) {
    super();
    this.authSubscription = this.loggedInUserDetails$.subscribe((res) => {
      this.loggedInUserDetails = res;
    });
    // this.savedCards$.pipe(takeUntil(this.destroy$)).subscribe((cardList) => {
    //       if (cardList && cardList.length && cardList.length > 0) {
    //         const count = cardList.filter((c) => c.active==true).length;
    //     if(count>1)
    //     this.card = cardList.find((c=>c.active==true)&&(c=>c.gift_balance!='0.0')&& (c=>c.loyalty_balance!='0.0'));
    //     else
    //         this.card = cardList.find(c=>c.active==true);
    //         if(this.card){
    //         this.gift_balance = parseFloat(this.card.gift_balance);
    //         this.loyalty_balance = parseFloat(this.card.loyalty_balance);
    //         this.cardBalance= this.cardBalance=parseFloat(this.card.gift_balance) +
    //         parseFloat(this.card.loyalty_balance);
    //         }
    //       }
    //             else
    //     this.card = undefined;
    // });
      this.savedCards$.pipe(takeUntil(this.destroy$)).subscribe((cardList) => { 
          if (cardList && cardList.length && cardList.length > 0) {            
            this.cardListDetails = cardList.filter((c) => c.active==true);
            const count = this.cardListDetails.length;
        if(count>=1){
        // this.card = cardList.find((c=>c.active==true)&&(c=>c.gift_balance!='0.0')&& (c=>c.loyalty_balance!='0.0'));
          // this.cardListDetails = cardList.filter((c=>c.active==true) && (c=>c.gift_balance!=='0.0') && (c=>c.loyalty_balance!='0.0'));  
          this.cardListDetails = cardList.filter(cardDet => cardDet.active);
          if(this.cardListDetails.length) this.getCardDetails(this.cardPos);
        }
        // else
        //     this.card = this.cardListDetails[0];
        //     if(this.card){
        //     this.cardNumber = this.card.cardnumber;  
        //     this.gift_balance = parseFloat(this.card.gift_balance);
        //     this.loyalty_balance = parseFloat(this.card.loyalty_balance);
        //     this.cardBalance= this.cardBalance=parseFloat(this.card.gift_balance) +
        //     parseFloat(this.card.loyalty_balance);            
        //     }            
          }
          else
              this.card = undefined;

    });
  }

    ngOnInit(): void {
        this.loggedInUserDetails = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
       this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
      if(this.loggedInUserDetails)
        this.store.dispatch(new FetchGiftLoyaltyCards());
    }

    rewardsPage()
    {
      this.router.navigate(['user-rewards'])
    }


    viewQRCode()
    {
      if( this.card)
      {
      if(this.isMobileView )
      {
        this.modal.showSmallModal(QrCodeComponentComponent, {
          data: {
            valueToGenerateQRCode: this.card.cardnumber,
            descriptionQRCode: this.translate.instant('your-gift-loyalty-card-QR-code'),
            sizeQRCode:'256',
            enableCloseButton:true
          },
          whenClosed: (data) => {},
        });


      }
      else

      {
        this.modal.showSmallModal(QrCodeComponentComponent, {
          data: {
            descriptionQRCode: this.translate.instant('your-gift-loyalty-card-QR-code'),
            valueToGenerateQRCode: this.card.cardnumber,
            sizeQRCode:'256',
            enableCloseButton:true
          },
          whenClosed: (data) => {},
        });


      }
    }

    }

     cardNavigate(action){
        action == 'prev' ? this.cardPos-- : this.cardPos++;
        this.getCardDetails(this.cardPos);
    }

    getCardDetails(cardPos){
        this.card = this.cardListDetails[cardPos];
        this.cardNumber = this.card.cardnumber;
        this.gift_balance = parseFloat(this.card.gift_balance);
        this.loyalty_balance = parseFloat(this.card.loyalty_balance);
        this.cardBalance= this.cardBalance=parseFloat(this.card.gift_balance) +
        parseFloat(this.card.loyalty_balance);
    }






}

import { environment } from '@moduurnv2/libs-orderingapp/src/core/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';

@Injectable()
export class GoogleTagManagerHandler {

    googleTagManagerGlobalAccountId: any;
    globalGoogleTagManagerProperties: any;
    googleTagManagerContainerId: any;
    googleTagManagerGlobalId: any;
    googleTagManagerEnableTracking: any;
    orgId = Config.organizationId;
    public constructor(private http: HttpClient, private store: Store) {}
  
    public setupGoogleTagManager() {
      const organizationTagUrl =
      environment.organizationApiUrl + `organizations/${this.orgId}/googletagmanager`;

      this.http.get(organizationTagUrl).subscribe((response) => {
        if (
          response['data']
        ) {
          const googleTagManagerDetails = response['data'].googleTagManagerConfig;
          if (
            googleTagManagerDetails &&
            googleTagManagerDetails.isAllow 
          ) {
            if(googleTagManagerDetails.containerId){
              this.organisationContainerCheck(googleTagManagerDetails)
            }
            else{
              this.getGoogleTagManagerProperties().then(res=>{
                if(res){
                  this.googleTagManagerGlobalId = res;
                  this.setGoogleTagManagerScripts(this.googleTagManagerGlobalId);
                }
              });
            }
          }
        }
      });
    }

    setGoogleTagManagerScripts(trackingId){

        let tagManagerScript1 = document.createElement('noscript');
        tagManagerScript1.setAttribute('async', 'true');
        tagManagerScript1.innerText=
          `<iframe src="https://www.googletagmanager.com/ns.html?id=${trackingId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`
        let tagManagerScript2 = document.createElement('script');
        tagManagerScript2.innerText = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':
        new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=
        \'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,\'script\',\'dataLayer\',\'${trackingId}\');`;
        document.documentElement.firstChild.appendChild(tagManagerScript2);
        document.body.appendChild(tagManagerScript1);

    }
 
    getGoogleTagManagerProperties() {
      const url = environment.settingsApiUrl + `settings/constants/TAGMANAGERID`;
      return new Promise((resolve,reject)=>{
        this.http.get(url).subscribe((response) => {
          this.globalGoogleTagManagerProperties = response;
          let index = this.globalGoogleTagManagerProperties.findIndex(
            (x) => x.textCode == 'TAGMANAGERID01'
          );
        if (index != -1) {
            this.googleTagManagerGlobalId = this.globalGoogleTagManagerProperties[
            index
            ].text;
        }
        resolve(this.googleTagManagerGlobalId);
          });
      });
    }

    organisationContainerCheck(googleTagManagerDetails){
      this.googleTagManagerContainerId = googleTagManagerDetails.containerId;
        this.getGoogleTagManagerProperties().then(res=>{
          this.googleTagManagerEnableTracking =
          this.googleTagManagerContainerId && googleTagManagerDetails.isAllow
            ? true
            : false;
          this.setGoogleTagManagerScripts(this.googleTagManagerContainerId);
          if(res && this.googleTagManagerContainerId != res)
          this.setGoogleTagManagerScripts(res);
        });
    }
  }
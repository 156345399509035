import { Directive, HostBinding, OnInit, OnDestroy, Input, ElementRef } from '@angular/core';

@Directive({
    selector: '[bg-image]',
})
export class BgImageDirective implements OnInit, OnDestroy {

    @Input('bg-image') image: string;
    @HostBinding('style.background-image') bgImage: string;


    constructor(private el: ElementRef) { }

    ngOnInit() {
        const options = {
            rootMargin: '0px 30px 100px 30px',
            threshold: 0
        }
        try{
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.preLoad();
                    observer.unobserve(entry.target);
                } else {
                    return;
                }
            });
        }, options);
        observer.observe(this.el.nativeElement);
    }catch(err){
        this.preLoad();
    }
}

    ngOnDestroy() {}

    preLoad() {
        if(this.image)
            this.bgImage = `url(${this.image})`;
    }

}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  GiftAndLoyaltyCardService,
  SharedService,
  SweetAlertService,
} from '@moduurnv2/libs-orderingapp/src/core';
import { GiftCardBaseComponent } from '@moduurnv2/libs-orderingapp/src';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { FormBuilder } from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';



@Component({
  selector: 'moduurnv2-add-gift-card',
  templateUrl: './add-gift-card.component.html',
  styleUrls: ['./add-gift-card.component.scss'],
})
export class AddGiftCardComponent extends GiftCardBaseComponent {
  constructor(
    store: Store,
    router: Router,
    _shared: SharedService,
    fb: FormBuilder,
    loyaltyService: GiftAndLoyaltyCardService,
    modal: ModalService,
    public sweetAlert: SweetAlertService,
    public translate: TranslateService
  ) {
    super(fb, store, router, _shared, loyaltyService, modal, sweetAlert,translate);
  }
}

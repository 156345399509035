import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Observable, Subscription ,forkJoin,combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { Select, Store } from '@ngxs/store';
import * as CryptoJS from 'crypto-js';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import {
  SetSelectedLocation,
  FetchLocationList,
  SetSelectedDelivery,
  FetchDeliveryList,
  SetSelectedVenue,
  SetLocationAccess,
  ResetCustomerPerTable,
  ClearLoginDetails,
  ClearAllVenueDatas,
  ClearCart,
  SetQRScannedDetails,
  FetchLocationDefaults,
  ClearFutureDate,
  ClearSelectedLocation
} from '@moduurnv2/libs-orderingapp/src/core/actions';
import {
  LocationState,
  DeliveryState,
  WhitelabelState
} from '@moduurnv2/libs-orderingapp/src/core/state';
import {
  Location,
  Delivery,
  QRScanDetails
} from '@moduurnv2/libs-orderingapp/src/core/models';

@Injectable({
  providedIn: 'root',
})
export class QRScannerService {
  initialLoad: boolean = true;
  cipher = Config.cipher;
  @Select(LocationState.getLocationsList) locationsList$: Observable<
    Location[]
  >;
  @Select(DeliveryState.getDeliveryList) deliveryList$: Observable<Delivery[]>;
  @Select(DeliveryState.getSelectedDelivery) selectedDelivery$: Observable<Delivery>;
  @Select(LocationState.getSelectedLocation) selectedLocation$: Observable<any>;
  @Select(WhitelabelState.getQRScanDetails) qrScanDetails$: Observable<QRScanDetails>;
  constructor(private store: Store, private router: Router) {}

  setInitialDataOnQRScan() {
    //// let str = {
    ////   deliveryType: 'DELIVERYTYPE03',
    ////   venueName: 'P23',
    ////   venueType:{
    ////     textCode:'TABLETYPE01',
    ////     text:'Table'
    ////   },
    ////   locationId: '602f616bac3b4629f8c5c16b',
    //// };
    //// let test = CryptoJS.AES.encrypt(JSON.stringify(str), this.cipher);
    //// console.log(test);

    this.initialLoad = true;
    // let url = window.location.href;
    ////let url = 'http://localhost:4200/locations/locations/602f616bac3b4629f8c5c16b/home?qrScanDetails=U2FsdGVkX1+uf6UZY7cRnWb6vAVXXwU3oAHd1zgsBiV+6xQG9R7RS4wsUbC/jmZiaq7sYBRSXBg3odX9x3Q+5rF/ZXTZejUBt/1A7Wbp6VXwXdH0cPEW15jWpMvPu3uXllvR80FcnqTmaSyKDtaJjGn819IcgT0w4rSJRgc4+AbB7s9nwHLDC4PdUbPZkfmLHjqpfCh5pgxTOk9tWhpunHHdfvlytiAqGP4SvFiYtgk='
    //// url = url + '?qrScanDetails=' + test.toString(); // to be deleted
    //// let qrData = {};
    // console.log('==========================',url)
    // let queryParams = url.split('?')[1];
    //// this.store.dispatch(new ClearQRScannedDetails());
    // if (queryParams) {
      // let queryParam = queryParams.split('&');
      // let params;
      // let decryptedData;
      // let b64String;

      //// for (var i = 0; i < queryParam.length; i++) {
        // let qrScanParams = queryParam.find(query => query.includes('qrScanDetails'));
        // if(qrScanParams){
        // params = qrScanParams.slice(14);//queryParam[0].split('=');
        //// qrData[params[0]] = params[1];
        // b64String = decodeURIComponent(params);
        //// if (qrData['table']) {
        ////   let decrypted = CryptoJS.AES.decrypt(b64String, this.cipher);
        ////   decryptedData = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
        //// }

        //// if (qrData['qrScanDetails']) {
          // let decrypted = CryptoJS.AES.decrypt(b64String, this.cipher);
          // decryptedData = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));

          let QRScanDetails = this.getQRScanDetailsFromURL();
          console.log('QRScanDetails=>',QRScanDetails)
          if(QRScanDetails.isValidQR){
            this.store.dispatch(new SetQRScannedDetails(QRScanDetails.decryptedData));
            this.setInitialDataFromQrDetails(QRScanDetails.b64String, QRScanDetails.decryptedData);
          }
        //// }
      //// }

      //// if (qrData?.['table']) {
      ////   this.setInitialDataForLoungeDelivery(b64String, decryptedData);
      //// }

      //// if (qrData?.['qrScanDetails']) {
      ////   this.setInitialDataFromQrDetails(b64String, decryptedData);
      //// }
      // }
    // }
  }

  getQRScanDetailsFromURL(){
    let url = window.location.href;
    let queryParams = url.split('?')[1];
    let params;
    let b64String;
    let decryptedData;
    let isValidQR = false;
    if(queryParams){
    let queryParam = queryParams.split('&');
    let qrScanParams = queryParam.find(query => query.includes('qrScanDetails'));
      if(qrScanParams) {
        params = qrScanParams.slice(14);
        b64String = decodeURIComponent(params);
        let decrypted = CryptoJS.AES.decrypt(b64String, this.cipher);
        decryptedData = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
          if(b64String && decrypted && decryptedData){
            isValidQR = true;
          }
      }
    }

    return {isValidQR: isValidQR , b64String: b64String, decryptedData: decryptedData}
  }

  setInitialDataFromQrDetails(b64String, decryptedData) {
    console.log('qr->',decryptedData)
    const deliveryTypeTextCodeToSelect = decryptedData['deliveryType'];
    const locationIdToSelect = decryptedData['locationId'];
    this.store.dispatch(new ClearSelectedLocation());
    this.store.dispatch(new FetchLocationList());
    this.store.dispatch(new ClearCart());
    this.locationsList$.subscribe((locations) => {
      if (locations?.length > 0 && this.initialLoad) {
        this.store.dispatch(new ResetCustomerPerTable(''));
        let selectedLocation = locations.find(
          (loc) => loc._id == locationIdToSelect
        );
        if (selectedLocation) {
          let selectedDeliveryType = selectedLocation['deliveryTypes'].find(
            (delivery) => delivery.textCode == deliveryTypeTextCodeToSelect
          );
          if (selectedDeliveryType) {
            this.store.dispatch(new SetSelectedLocation(locationIdToSelect));
            this.store.dispatch(new ClearLoginDetails());
            this.store.dispatch(new FetchDeliveryList());
            this.store.dispatch(new SetLocationAccess(false));
            this.deliveryList$.subscribe((deliveries) => {
              if (
                deliveries &&
                deliveries.find(
                  (dlvry) => dlvry.textCode == deliveryTypeTextCodeToSelect
                ) &&
                this.initialLoad
              ) {
                this.store.dispatch(new FetchLocationDefaults());
                this.store.dispatch(
                  new SetSelectedDelivery(deliveryTypeTextCodeToSelect)
                );
                this.initialLoad = false;
                this.store.dispatch(new ClearFutureDate())
                if(decryptedData['venueName']) {
                  let table = { name: decryptedData['venueName'] };
                  this.store.dispatch(new SetSelectedVenue(table));
                  this.router.navigate([`locations/${locationIdToSelect}/home`],{ queryParams: { qrScanDetails: b64String } });
                } else {
                  // this.store.dispatch(new ClearQRScannedDetails());
                  this.router.navigate([`locations/${locationIdToSelect}/home`]);
                  this.store.dispatch(new ClearAllVenueDatas());
                }
              }
            });
          } else {
            console.error('Selected delivery type not configured');
          }
        } else {
          console.error('Selected location not available');
        }
      }
    });
  }

  setInitialDataForLoungeDelivery(b64String, decryptedData) {
    let locationId = window.location.pathname.split('/')[2];
    this.store.dispatch(new FetchLocationList());
    this.locationsList$.subscribe((locations) => {
      if (locations) {
        if (locations.length > 0) {
          this.store.dispatch(new ResetCustomerPerTable(''));
          let selLocation = locations.find((loc) => loc._id == locationId);
          if (selLocation?.['deliveryTypes']) {
            selLocation['deliveryTypes'].map((delivery) => {
              if (delivery.textCode == 'DELIVERYTYPE08' && this.initialLoad) {
                this.store.dispatch(new SetSelectedLocation(locationId));
                this.store.dispatch(new ClearLoginDetails());
                this.store.dispatch(new ClearAllVenueDatas());
                this.store.dispatch(new FetchDeliveryList());
                this.store.dispatch(new SetLocationAccess(false));
                this.deliveryList$.subscribe((deliveries) => {
                  if (deliveries) {
                    if (
                      delivery.textCode == 'DELIVERYTYPE08' &&
                      this.initialLoad
                    ) {
                      this.store.dispatch(
                        new SetSelectedDelivery(delivery.textCode)
                      );
                      this.initialLoad = false;
                      if (decryptedData) {
                        if (typeof decryptedData === 'string')
                          decryptedData = JSON.parse(decryptedData);
                        let lounge = { name: '' };
                        lounge.name = decryptedData['tableNumber'];
                        this.store.dispatch(new SetSelectedVenue(lounge));
                        this.router.navigate([`locations/${locationId}/home`], {
                          queryParams: { tableNumber: b64String },
                        });
                      }
                    }
                  }
                });
              }
            });
          }
        }
      }
    });
  }

}

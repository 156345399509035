export const apigenerator = (baseurl) => {
  return {
    customerApiUrl: `${baseurl}customerApi/v1/`,
    customerApiv3Url: `${baseurl}customerApi/v3/`,
    orderApiUrl: `${baseurl}orderApi/v3/orders/`,
    organizationApiUrl: `${baseurl}organizationapi/v1/`,
    mobileStoreApiUrl: `${baseurl}mobileStoreApi/v1/`,
    //mobileStoreApiUrl: `http://localhost:8288/api/v1/`,
    mobileStoreApiv3Url: `${baseurl}mobileStoreApi/v3/`,
    deliveriesApiUrl: `${baseurl}deliveryApi/v3/`,
    locationApiUrl: `${baseurl}locationapi/V3/`, // dev
    // locationApiUrl: `${baseurl}locationapi/v3/`, // qa and prod
    locationV1ApiUrl: `${baseurl}locationapi/v1/`,
    cartApiUrl: `${baseurl}cartApi/v1/carts/`,
    cartApiv3Url: `${baseurl}cartApi/v3/carts/`,
    placeOrderApiUrl: `${baseurl}orderApi/v2/orders/`,
    clearCartApiUrl: `${baseurl}cartApi/v1/carts/`,
    paymentApiUrl: `${baseurl}paymentApi/v1/`,
    userApiUrl: `${baseurl}userapi/v1/users/`,
    couponApiv3Url: `${baseurl}couponApi/v3/`,
    settingsApiUrl: `${baseurl}settingsapi/v1/`,
    healthCheckApiUrl: `${baseurl}healthCheckApi/v1/`,
    menuCombosApiUrl: `${baseurl}menuCombosApi/v1/`,
    orderLevelModifierApiUrl: `${baseurl}orderLevelModifierApi/v1/`,
  };
};

export const common = {
  images: {
    mobile: 'mobile.svg',
    //ICONS FOR LOGIN PAGE
    login_head: 'login_head.png',
    phone_icon: 'phone_icon.svg',
    facebook_icon: 'facebook_icon.svg',
    google_icon: 'google_icon.svg',
    microsoft_icon: 'microsoft.svg',
    email_icon: 'email_icon.svg',
    arrow_right_icon: 'keyboard_arrow_right_icon.svg',
    arrow_right_icon_png: 'keyboard_arrow_right_icon.png',
    arrow_left_icon: 'keyboard_arrow_left_icon.svg',
    arrow_left_icon_png: 'keyboard_arrow_left_icon.png',
    profile_head: 'profile_head.png',
    logout_icon: 'Logout Icon.svg',
    rate_icon: 'Rate Us Icon.svg',
    about_icon: 'About Icon.svg',
    help_icon: 'Help Icon.svg',
    adrsbook_icon: 'Address Book Icon.svg',
    editprofile_icon: 'Edit Profile Icon.svg',
    notification_icon: 'Notifications Icon.svg',
    settings_icon: 'Settings Icon.svg',
    payment_icon: 'Payments Icon.svg',
    points_icon: 'Points Icon.svg',
    payment_circle_icon: 'Payments_Icon_Circle.svg',

    //ICONS FOR ORGANIZATION LOCATIONS PAGE
    location_pin: 'Location Icon.svg',
    //ICONS FOR LOCATIONS PAGE
    delivery_methods: 'ic_room_service.svg',
    order_here: 'Order-Now-Icon.svg',
    order_here_white: 'Order-Now-Icon-white.svg',
    order_here_disabled: 'ic_room_service_disabled.svg',
    clock: 'Clock Icon Small.svg',
    phone: 'Call Icon Grey Small.svg',
    calendar: 'calendar.svg',
    compass: 'Compass Icon Small.svg',
    favorite_icon: 'Favorites Icon Red.svg',
    nonFavorite_icon: 'Favorites Icon White.svg',
    get_directions_icon: 'bxs-direction.svg',
    preparation_time: 'preparation-time.svg',
    order_for_later: 'calendar-icon.svg',
    get_direction_icon: 'get-direction-icon.svg',
    vegan_logo: 'vegan-logo-icon.svg',
    chillies_icon: 'chillies-icon.svg',
    order_for_later_inactive: 'order-for-later-inactive.svg',
    close: 'Close.svg',
    close_update_modal: 'Close-white.svg',
    special_instructions: 'Special Instructions.svg',
    alert: 'Alert Icon.svg',
    credit_card: 'Credit Card Icon.svg',
    visa_card: 'Credit-Card-Icon-Small.svg',
    get_direction_desktop_icon: 'bxs-direction-desktop.svg',

    //ICONS FOR ADDRESS PAGE
    add: 'Add Icon Small.svg',
    location: 'Location Icon Outline Small.svg',
    back_arrow: 'Chevron Back Dark.svg',
    edit: 'Edit Icon Small.svg',
    adrs_location_pin: 'Address-Location-Pin.svg',
    edit_blue: 'Edit Icon Small Blue.svg',
    success: 'Success Icon Small.svg',
    add_red: 'add-icon-red.svg',

    //order details
    receipt_icon: 'receipt-icon.svg',
    receipt_icon_png: 'receipt-icon.png',
    call_icon: 'call-icon.svg',
    call_icon_png: 'call-icon.png',
    view_all_orders_icon: 'view-all-orders-icon.svg',
    view_all_orders_icon_png: 'view-all-orders-icon.png',
    cancel_icon: 'cancel-icon.svg',
    cancel_icon_png: 'cancel-icon.png',
    save_icon: 'save-icon.svg',
    save_icon_png: 'save-icon.png',
    order_again_icon: 'order-again-icon.svg',
    order_again_icon_png: 'order-again-icon.png',
    timeline_tick: 'timeline-tick.svg',
    timeline_tickPng: 'timeline-tick.png',
    logo_big: 'logo-big.png',
    //ICONS FOR VIEW ORDERS
    view_order_header: 'view-order-header.png',
    order_again_blue_icon: 'Order Again Icon Small.svg',
    order_cancel_icon: 'Cancel Icon Small.svg',
    //ICONS FOR CART
    empty_bag: 'Empty_Bag.svg',
    desktop_modal_close: 'desktop-modal-close.svg',
    location_access_banner: 'location-access-banner.jpg',
    westjet_logo_big: 'WestJet Logo Big.svg',
    //Rewards
    points: 'reward_points Icon.svg',
    rewards_banner: 'reward-ponit-banner.png',
  },
  baseRoutePath: '',
  image_basepath: 'assets/images/',
  localServerApiUrl: 'http://localhost:8080/api/v3/',
  imagebaseurl: 'https://s3.amazonaws.com/devmodimages/',
  googleWebOAuthClientId:
    '864929024927-2hj82mue07bak6dbq6mo3riofa3n785q.apps.googleusercontent.com',
  mapbox: {
    accessToken:
      'pk.eyJ1IjoibW9kdXVybiIsImEiOiJja2UwdnVtdGs0MHU1MnNtaDk4ZXBjajZ1In0.vQNu8Qt6UiOENIMcOCLsTg',
    reverseDecodingURL: `https://api.mapbox.com/geocoding/v5/mapbox.places/`,
    distanceCalculateURL:
      'https://api.mapbox.com/directions/v5/mapbox/driving/',
    directionURL: 'https://maps.google.com/maps?',
    directionURLIOS: 'maps://maps.google.com/maps?',
  },
};

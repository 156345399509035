import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { Store, Select } from '@ngxs/store';
import {
  SetFutureDate,
  environment,
  LocationState,
  Location,
  CartState,
  BaseComponent,
  FetchLocationDefaults,
  DeliveryState,
  Delivery,
  SetCalenderPicker,
  LocationDefaultState,
  FetchFutureTimes,
  FutureTimesModel,
  ClearFutureTimes,
  SharedService,
  LocationDefaults,
} from '@moduurnv2/libs-orderingapp/src';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { IDayCalendarConfig, DatePickerComponent,IDatePickerConfig } from 'ng2-date-picker';
import * as moment from 'moment';
import { subscribeOn, takeUntil } from 'rxjs/operators';
import { ModalService } from '../modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

interface TimeRange {
  timeValue:HourMin,
  endTimeValue:HourMin
}

interface HourMin{
  hours: string;
  minutes:string;
}
@Component({
  selector: 'moduurnv2-future-picker',
  templateUrl: './future-picker.component.html',
  styleUrls: ['./future-picker.component.scss'],
})
export class FuturePickerComponent extends BaseComponent implements OnInit {
  @ViewChild('futureDate') futureDate;
  imgBasePath: string = environment.image_basepath;
  icon = {
    close: this.imgBasePath + environment.images.close,
  };
  @Input('openPicker') showModal: boolean = false;
  @Output('selectedTime') selectedTime = new EventEmitter();
  @Select(LocationState.getSelectedLocation) location$: Observable<Location>;
  @Select(DeliveryState.getSelectedDelivery) selectedDelivery$: Observable<
    Delivery
  >;
  @Select(CartState.getFutureTimes) futureTimes$: Observable<FutureTimesModel>;
  itemList = [];
  observer = {};
  selectedItem;
  interval = 1000 * 60 * 5;
  showInfo :boolean = false;
  public filterForm: FormGroup;
  public dayPickerConfig = <IDatePickerConfig>{
    format: 'DD.MM.YYYY',
    monthFormat: 'MMMM, YYYY',
    firstDayOfWeek: 'mo',
    showTwentyFourHours : false,
  };
  @ViewChild('dateFromDp') public dateFromDp: DatePickerComponent;
  @ViewChild('dateToDp') public dateToDp: DatePickerComponent;
  @Select(CartState.getFutureDate) futureDate$;
  selectedDate: moment.Moment;
  deliveryTimeObj: TimeRange; 
  _modalClose: any = null;
  _data: any = null;
  locationName: string;
  showDatePicker: boolean = false;
  daysArray: any[];
  timesArray: any[];
  showDateList: boolean = false;
  showTimeList: boolean = false;
  selectedTimeDate = {
    date: null,
    time: null,
  };
  selectedLocation: Location;
  selectedDelivery: Delivery;
  futureLoading: boolean;
  isMobileView: boolean=false;
  orderSettingsDetail: any;
  @HostListener('click', ['$event']) onOutsideClick(
    event: MouseEvent | TouchEvent
  ) {
    this.showTimeList = false;
    this.showDateList = false;
  }
  daysLimit: number = 8;
  futureDates;
  futureTimes;
  selectedVenue;
  isClassRoomOrder:boolean;
  hideCloseButton:boolean = false;
  constructor(
    private store: Store,
    private modal: ModalService,
    private translate: TranslateService,
    private http: HttpClient,
    private router: Router,private shared:SharedService
  ) {
    super();
    if(this.translate && this.translate.currentLang == 'fr'){
      this.dayPickerConfig.showTwentyFourHours = true;
     }
  }

  ngOnInit() {
   
    // this.initFutureDate();
    // this.setupFutureListener();
    // this.location$.pipe(takeUntil(this.destroy$)).subscribe((response) => {
    //   if (response && response.loading !== true) {
    //     this.selectedLocation = response;
    //     const dataObject = {
    //       location: response,
    //       locationName: response.name,
    //       orderSettings: response.orderSettings,
    //     };
    //     // this._data = dataObject;
    //     this.initFutureDate();
    //     this.createFutureDates();
    //   }
    // });
    this.store.dispatch(new ClearFutureTimes());
    this.selectedDelivery$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedDelivery) => {
        this.selectedDelivery = selectedDelivery;
      });
    this.fetchFutureOrders();
    this.futureTimes$
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          if(response){
          this.orderSettingsDetail = response;
          this.handleFutureTimesList(response);
          }
        },(error)=>{
          console.error(error);
          this.futureLoading = false;
        }
    );
  }

  futuerDate() {
    if(this.futureLoading)
      return;
    
    let futureEndTime;
    if(this.selectedTimeDate?.time?.endTimeValue && this.selectedDate){
      let futureEndTimeMoment;
      futureEndTimeMoment = moment(this.selectedDate).clone();
      futureEndTimeMoment.set({ hours: this.selectedTimeDate.time.endTimeValue.hours, minutes: this.selectedTimeDate.time.endTimeValue.minutes, second: 0 })
      futureEndTime = futureEndTimeMoment.toISOString()
    }

    const futureData = {
      location: this.selectedLocation,
      futureTime: this.selectedDate ? this.selectedDate.toISOString() : null,
      deliveryTimeObj : this.deliveryTimeObj ? this.deliveryTimeObj : undefined,
      selectedVenue: this.selectedVenue,
      futureEndTime: futureEndTime
    };
    console.log(futureData)
    this.store.dispatch(new SetFutureDate(futureData));
    this.store.dispatch(new FetchLocationDefaults(futureData));
    this._modalClose(futureData);
  }

  setObserver(item) {
    const root = document.getElementById('selected');

    if (this.observer[item.id] == null && root) {
      let options = {
        root: root,
        rootMargin: '0px',
        threshold: 0.2,
      };
      this.observer[item.id] = new IntersectionObserver((entries, observer) => {
        if (entries[0].isIntersecting) this.selectedItem = item;
      }, options);
      let target = document.querySelector(`#item${item.id}`);
      if (target) this.observer[item.id].observe(target);
    }
    return true;
  }

  closePicker() {
    this.showModal = false;
  }

  generateItemList() {
    const currentTime = new Date();
    const generatedTimes = [];
    for (let i = 0; i < this.daysLimit; i++) {
      const calculatedTime = new Date(
        currentTime.valueOf() + i * this.interval
      );

      let relativeText = ``;
      let startTimeText = ``;
      let endTimeText = ``;

      if (currentTime.getDate() === calculatedTime.getDate())
        relativeText += this.translate.instant('today');

      startTimeText += `${calculatedTime.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      })}`;

      const calculatedEndTime = new Date(
        calculatedTime.valueOf() + 1000 * 60 * 10
      );
      endTimeText += `${calculatedEndTime.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      })}`;
      const dateText = this.generatedDate(calculatedEndTime);
      generatedTimes.push({
        id: i,
        relativeText,
        startTime: startTimeText,
        endTime: endTimeText,
        dateText,
        time: calculatedTime.valueOf(),
      });
    }
    this.itemList = generatedTimes;
  }

  generatedDate(date) {
    const dateValue = new Date(date);
    let dateString = dateValue.toLocaleDateString([], {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    });
    return dateString;
  }

  setupFutureListener() {
    // this.futureDate$.pipe(takeUntil(this.destroy$)).subscribe((response) => {
    //   console.log('response', response);
    //   if (response)
    //     if (this.selectedDate === undefined)
    //       this.selectedDate = moment(response);
    //     else if (this.selectedDate !== moment(response)) {
    //       this.selectedDate = moment(response);
    //     }
    // });
  }

  initFutureDate() {
    const { orderSettings } = this._data;
    if (orderSettings) {
      const futureDate = orderSettings.futureOrder;
      if (futureDate) {
        let minDate = moment();
        let maxDate;
        if (futureDate.maximumTime || futureDate.minimumTime) {
          const addMax = {};
          const addMin = {};
          if (futureDate.maximumTime)
            addMax[futureDate.maxTimeUnit] = futureDate.maximumTime;
          if (futureDate.minimumTime)
            addMin[futureDate.minTimeUnit] = futureDate.minimumTime;
          maxDate = moment().add(addMax);
          minDate.add(addMin);
          if (minDate) this.selectedDate = minDate.clone();
        }
        this.dayPickerConfig = {
          min: minDate,
          max: maxDate,
           showTwentyFourHours : (this.translate && this.translate.currentLang == 'fr') ? true : false,
        };
      }
    }
  }

  closePopup() {
    if (this._modalClose) this._modalClose();
  }

  set modalClose(modalRef: any) {
    this._modalClose = (data) => {
      this.modal.closeComponentModal(modalRef, data);
    };
  }

  set data(data: any) {
    this._data = data;
    this.selectedLocation = data.location;
    this.locationName = data.locationName;
    this.selectedVenue = data.selectedVenue;
    this.isClassRoomOrder = data.isClassRoomOrder;
    this.hideCloseButton = (data.allowOverlayClick !== undefined && data.allowOverlayClick !== null) ? !data.allowOverlayClick : false;
  }

  createFutureDates() {
    const currentTime = moment();
    const { orderSettings } = this._data;
    if (orderSettings) {
      const futureDate = orderSettings.futureOrder;
      if (futureDate) {
        if (futureDate.maximumTime) {
          const maxDate = {};
          maxDate[futureDate.maxTimeUnit] = futureDate.maximumTime;
          const maxTime = moment().add(maxDate);
          const daysDiff = maxTime.diff(currentTime, 'days');
          if (daysDiff) this.daysLimit = daysDiff;
          if (futureDate.usePicker) {
            this.showCalendar();
          } else {
            this.showDate();
          }
        }
      }
    }
  }

  showDate() {
    // this.showDatePicker = true;
    const currentTime = moment();
    this.changeDateList(currentTime);
  }

  showCalendar() {
    // const { orderSettings } = this._data;

    // if (orderSettings) {
    //   const futureDate = orderSettings.futureOrder;
    //   if (futureDate) {
    //     let minDate = moment();
    //     if (futureDate.minimumTime) {
    //       const addMin = {};
    //       if (futureDate.minimumTime)
    //         addMin[futureDate.minTimeUnit] = futureDate.minimumTime;
    //       minDate.add(addMin);
    //       if (minDate) this.selectedDate = minDate.clone();
    //       if (futureDate.minimumTime && futureDate.minTimeUnit === 'days')
    //         minDate.set({ hours: 0, minutes: 0, seconds: 0 });
    //     }
    //   }
    // }
    // this.showDatePicker = false;
  }

  openDateList(event) {
    event.stopPropagation();
    event.preventDefault();
    this.showDateList = !this.showDateList;
    this.showTimeList = false;
  }

  openTimeList(event) {
    event.stopPropagation();
    event.preventDefault();
    this.showTimeList = !this.showTimeList;
    this.showDateList = false;
  }

  selectDay(day) {
    this.selectedTimeDate.date = day;
    this.futureTimes = day.timeArray;
    this.selectTime(day.timeArray[0]);
  }

  selectTime(time) {
    this.selectedTimeDate.time = time;
    this.showTimeList = false;
    this.setFutureTime();
  }

  changeDateList(day?) {
    let currentTime = moment().set({ seconds: 0, milliseconds: 0 });
    if (day) {
      currentTime = moment(day).set({ seconds: 0, milliseconds: 0 });
    }
    const daysArray = [];
    for (let i = 0; i < this.daysLimit; i++) {
      let relativeText = ``;
      const calculatedTime = moment()
        .add({ days: i })
        .set({ seconds: 0, milliseconds: 0 });
      if (this.dayPickerConfig.min) {
        const minTime = moment(this.dayPickerConfig.min).set({
          seconds: 0,
          milliseconds: 0,
        });
        if (
          calculatedTime.isBefore(minTime) &&
          !calculatedTime.isSame(minTime, 'date')
        ) {
          continue;
        }
      }
      if (this.dayPickerConfig.max) {
        const maxTime = moment(this.dayPickerConfig.max).set({
          seconds: 59,
          milliseconds: 0,
        });
        if (calculatedTime.isAfter(maxTime)) {
          break;
        }
      }

      const daysDiff = calculatedTime.diff(currentTime, 'days');
      if (daysDiff === 0) relativeText += `${this.translate.instant('today')} `;
      const dateTextValue = calculatedTime.format('ddd MMM D');
      relativeText += dateTextValue;
      const dateValue = calculatedTime.format('ddd MMM D YYYY');

      const dateObject = {
        dateText: relativeText,
        dateValue:
          daysDiff === 0
            ? calculatedTime.valueOf()
            : new Date(dateValue).valueOf(),
      };
      daysArray.push(dateObject);
    }
    this.daysArray = daysArray;
    this.changeTimeList(day);
    this.selectedTimeDate.date = daysArray[0];
    this.setFutureTime();
  }

  changeTimeList(day?) {
    let currentTime = moment(this.dayPickerConfig.min) || moment();
    // let location = this.store.selectSnapshot(LocationState.getSelectedLocation);
    if (day) {
      const momentDay = moment(day);
      if (currentTime.isBefore(momentDay)) currentTime = momentDay.clone();
    }
    const adjustedTime = currentTime.clone();
    const currentEndTime = currentTime
      .clone()
      .set({ hours: 23, minutes: 59, seconds: 59 });
    const timesArray = [];

    if (
      (currentTime.date() === moment(this.daysArray[0].dateValue).date() &&
        this._data.orderSettings.asapOrder.isAllow) ||
      (this._data.orderSettings.asapOrder.isAllow === false &&
        this._data.orderSettings.futureOrder.isAllow === false)
    )
      timesArray.push({
        timeText: 'ASAP',
        timeValue: 'ASAP',
      });
    const locationDefaultsDetails = this.store.selectSnapshot(LocationDefaultState.getLocationDefaults);
    const timeSlot = locationDefaultsDetails.locationData?.orderSettings?.futureOrder?.overrideTimeSlot?.isAllow ? 
                      locationDefaultsDetails.locationData?.orderSettings?.futureOrder?.overrideTimeSlot?.timeSlot : 30;

    while (adjustedTime.isBefore(currentEndTime)) {
      const startTime = adjustedTime.clone().subtract({ minutes: timeSlot });
      if (startTime.isSameOrAfter(currentTime)) {
        const timeValue = {
          hours: startTime.get('hours'),
          minutes: startTime.get('minutes'),
        };
        let timeFormat = 'hh:mm A';
        if(this.translate && this.translate.currentLang == 'fr'){
          timeFormat   = 'HH:mm'
        }
        let timeLimit = `${startTime.format(timeFormat)} - `;
        timeLimit += adjustedTime.format(timeFormat);
        const timeObject = {
          timeText: timeLimit,
          timeValue: timeValue,
        };
        timesArray.push(timeObject);
      }
      adjustedTime.add({ minutes: timeSlot });
    }
    this.timesArray = timesArray;
    this.selectedTimeDate.time = timesArray[0];
    this.setFutureTime();
  }

  setFutureTime() {
    const { date, time } = this.selectedTimeDate;
    if (time && time.timeValue === 'ASAP') {
      this.selectedDate = null;
      this.deliveryTimeObj = undefined;
      return;
    }
    let futureDate = moment();
    if (date && date.dateValue) futureDate = moment(date.dateValue);
    if (time && time.timeValue)
      futureDate.set({
        hours: time.timeValue.hours,
        minutes: time.timeValue.minutes,
      });
      if(time.timeValue && time.endTimeValue){
        this.deliveryTimeObj = {
          timeValue : time.timeValue ? {
            hours: time.timeValue.hours,
            minutes: time.timeValue.minutes
          } : undefined,
          endTimeValue :time.endTimeValue ? {
            hours: time.endTimeValue.hours,
            minutes: time.endTimeValue.minutes
          } : undefined
        }
      }
    this.selectedDate = futureDate.clone();
  }

  asapDate(location) {
    const futureData = {
      location: location,
      futureTime: null,
    };
    this.store.dispatch(new SetFutureDate(futureData));
    this._modalClose(futureData);
  }

  showASAP() {
    // if (this.orderSettingsDetail?.orderSettings?.futureOrder.isAllow) {
    //   return this.orderSettingsDetail?.orderSettings?.asapOrder.isAllow;
    // }
    if (this.orderSettingsDetail?.futureEnabled) {
      return this.orderSettingsDetail?.asapEnabled;
    }
    return true;
  }

  fetchFutureOrders() {
    this.futureLoading = true;
    let location = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    let selectedDelivery = this.store.selectSnapshot(
      DeliveryState.getSelectedDelivery
    );
    let locationId = location ? location._id : null;
    if (this.router.url === '/locations') {
      if(this._data?.location)
      location= this._data.location
      locationId = location._id;
      selectedDelivery = location.deliveryTypes[0]
    }

    const payload ={
      location : location,
      selectedVenue: this.selectedVenue,
      isClassRoomOrder:this.isClassRoomOrder,
      selectedDelivery: selectedDelivery
    }
    this.store.dispatch(new FetchFutureTimes(payload))
    // let url = `${
    //   environment.mobileStoreApiUrl
    // }stores/location/${locationId}/futureTimes?timezone=${-new Date().getTimezoneOffset()}&language=${this.translate.currentLang}`;
    // const deliveryMethod = this.store.selectSnapshot(
    //   DeliveryState.getSelectedDelivery
    // );

    // if (this.router.url !== '/locations' && deliveryMethod)
    //   url += `&deliveryMethod=${deliveryMethod.textCode}`;
    
    // if(deliveryMethod?.textCode == 'DELIVERYTYPE10' ){
    //   if(this.selectedVenue?.allowSchedule && this.selectedVenue?.shifts?.length > 0){
    //     url += `&selectedRoom=${this.selectedVenue._id}`;
    //   }
    // }

    // this.http.get(url).subscribe(
    //   (response) => {
    //     const futureTimesResponse = response as any;
    //     this.futureDates = futureTimesResponse.futureOrderOptions;
    //     this.store.dispatch(new SetCalenderPicker((response as any).usePicker));
    //     if(futureTimesResponse.usePicker === false ) {
    //       if(this.futureDates){
    //         this.selectedTimeDate.date = this.futureDates[0];
    //         this.futureTimes = this.futureDates[0]?.timeArray || [];
    //         if (this.futureTimes?.length) {
    //           this.selectedTimeDate.time = this.futureTimes[0];
    //           this.setFutureTime();
    //           if (this.futureTimes[0] && this.futureTimes[0].timeValue === 'ASAP'){
    //             this.selectedDate = null;
    //             this.deliveryTimeObj = null;
    //           }
    //         }
    //       } else {
    //         this.closePopup();
    //       }
    //     }else{
    //       this.initFutureDate();
    //     }
    //     this.showDatePicker = !futureTimesResponse.usePicker;
    //     this.futureLoading = false;
    //   },
    //   (error) => {
    //     console.error(error);
    //     this.futureLoading = false;
    //   }
    // );
  }

  handleFutureTimesList(response){
    const futureTimesResponse = response as any;
    this.futureDates = futureTimesResponse.futureOrderOptions;
    this.store.dispatch(new SetCalenderPicker((response as any).usePicker));
    if(futureTimesResponse.usePicker === false ) {
      if(this.futureDates){
        this.selectedTimeDate.date = this.futureDates[0];
        this.futureTimes = this.futureDates[0]?.timeArray || [];
        if (this.futureTimes?.length) {
          this.selectedTimeDate.time = this.futureTimes[0];
          this.setFutureTime();
          if (this.futureTimes[0] && this.futureTimes[0].timeValue === 'ASAP'){
            this.selectedDate = null;
            this.deliveryTimeObj = null;
          }
        }
      } else {
        this.closePopup();
      }
    }else{
      this.initFutureDate();
    }
     this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );

    this.showDatePicker = !futureTimesResponse.usePicker;
   
    this.futureLoading = false;
  }

  get haveFutureTimes() {
    return this.futureDates.length
      ? this.futureDates[0]?.timeArray.length
      : false;
  }

  invertInfo(){
    this.showInfo = !this.showInfo;
  }
}

import { Component,Input } from '@angular/core';
import { BaseComponent,CartState,ComboPrice,CartItems,DeleteCombo } from '@moduurnv2/libs-orderingapp/src';
import { Store, Select } from '@ngxs/store';
import { forkJoin, throwError, } from 'rxjs';
import { map,takeUntil } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { ModalService } from './../../shared/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertPopupComponent } from './../../shared/alert-popup/alert-popup.component';

@Component({
  selector: 'moduurnv2-combo-list-cart',
  templateUrl: './combo-list-cart.component.html',
  styleUrls: ['./combo-list-cart.component.scss'],
})
export class ComboListCartComponent extends BaseComponent {
  @Select(CartState.getCartComboPriceItems) cartComboPrice$: Observable<ComboPrice[]>;
  @Select(CartState.getCartItems) cartItems$: Observable<CartItems[]>;
  values$;
  comboItems;
  cartComboPrice = [];
  constructor(public cartstate: CartState,private store: Store,private translate:TranslateService,private modal:ModalService) {
    super();
    this.cartComboPrice$.pipe(takeUntil(this.destroy$)).subscribe((cartComboPrice) => {
      this.cartComboPrice = cartComboPrice;
      // if(cartComboPrice && this.comboItems){
        this.cartItems$.pipe(takeUntil(this.destroy$)).subscribe((cartItems) => {
          if(cartItems && cartItems[0]?.combos && this.cartComboPrice){
            // debugger;
            this.comboItems = cartItems[0].combos;
            this.computePrices();
          }
        })
      // }
    });
  }

  computePrices(){
      for(let cb=0;cb<this.comboItems.length;cb++){
        const combo = this.comboItems[cb];
        const matchingCombo = this.getComboPriceFromComboId(combo);
        if(matchingCombo){
          this.comboItems[cb].displayPrice = matchingCombo?.totalBeforetax ? matchingCombo.totalBeforetax : undefined;
          for(let cg=0;cg<this.comboItems[cb].comboGroups.length;cg++){
            const comboGroup = this.comboItems[cb].comboGroups[cg];
            const matchingComboGroup = this.getComboPriceGroupFromComboGroupId(matchingCombo,comboGroup);
            if(matchingComboGroup){
              if(matchingComboGroup.totalBeforeDiscount && matchingComboGroup.totalBeforeDiscount != matchingComboGroup.total){
                this.comboItems[cb].comboGroups[cg].isDiscountedPrice = true;
                this.comboItems[cb].comboGroups[cg].displayDiscountedPrice = parseFloat(matchingComboGroup.totalBeforeDiscount.toFixed(2));
              }
              this.comboItems[cb].comboGroups[cg].displayPrice = matchingComboGroup.total;
            }
          }
        }
      }
  }

  getComboPriceFromComboId(combo){
    return this.cartComboPrice.find(price => price.comboIdInCart == combo._id);
  }

  getComboPriceGroupFromComboGroupId(foundComboPrice,cartComboGroup){
    return foundComboPrice.groups.find(group => group.groupIdInCart == cartComboGroup._id);
  }

  deleteCombo(combo){
    let currentComboId = combo._id;
    this.modal.showSmallModal(AlertPopupComponent, {
      data: {
        header: this.translate.instant('are-you-sure-remove-combo'),
        actionable: true,
      },
      whenClosed: (data) => {
        if (data) this.store.dispatch(new DeleteCombo(currentComboId));
      },
    });
  }
}

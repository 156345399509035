import { Component, HostListener, Inject } from '@angular/core';

import { Injectable } from '@angular/core';

import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
// xplat
import { AppBaseComponent } from '@moduurnv2/web';
import { SharedService } from '@moduurnv2/libs-orderingapp';
import { Select, Store } from '@ngxs/store';
import {
  WhitelabelState,
  Whitelabel,
  LocationState,
  Location,
  DeliveryState,
  Delivery,
  SpinnerTypes,
  TypeOfLandingPages,
  HomeState,
  Category
} from '@moduurnv2/libs-orderingapp/src/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { MessagingService } from '@moduurnv2/web/core';
import { AnalyticsHandler,QRScannerService, GoogleTagManagerHandler } from '@moduurnv2/core/services';
import { PWAService } from '@moduurnv2/core/services';

import { environment } from '@moduurnv2/libs-orderingapp/src/core';
import * as CryptoJS from 'crypto-js';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { DOCUMENT } from '@angular/common';
import { AuthService as AzureAuthService } from './features/core/auth.service';
import { Title, Meta, DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { filter, map, takeUntil } from 'rxjs/operators';

import { SplashMessageComponent } from './../app/features/home/components/splash-message/splash-message.component';
import { ModalService } from '../app/features/shared/modal/modal.service';
@Component({
  selector: 'moduurnv2-root',
  templateUrl: './app.component.html',
})
@Injectable({
  providedIn: PWAService,
})
export class AppComponent extends AppBaseComponent {
  @Select(WhitelabelState.getWhitelabel) whitelabel$: Observable<Whitelabel>;
  @Select(LocationState.getLocationsList) locationsList$: Observable<
    Location[]
  >;
  @Select(HomeState.getHomeCategories) category$: Observable<Category>;
  @Select(LocationState.getSelectedLocation) selectedLocation$: Observable<Location>;
  @Select(DeliveryState.getDeliveryList) deliveryList$: Observable<Delivery[]>;
  @HostListener('window:resize', ['$event'])
	onResize(event) {
		this.width = event.target.innerWidth;
    this.setDisplay();
	}
  availableCategories: any;
  isMobileView: boolean;
  restaurantDetails: any;
  locationListSubscription: Subscription;
  deliveryListSubscription: Subscription;
  initialLoad: boolean = false;
  cipher = Config.cipher;
  selectedRoute;
  jsonLD: any;
  jsonldSafe: SafeHtml;
  width: number;
  spinnerTypes = SpinnerTypes;
  appPrimaryColor :string;
  appSecondaryColor :string;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta,
    private analyticsHandler: AnalyticsHandler,
    private googleTagManagerHandler: GoogleTagManagerHandler,
    public messagingService: MessagingService,
    private shared: SharedService,
    private store: Store,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private authService: AzureAuthService,
    private pwa : PWAService,
    private sanitizer: DomSanitizer,
    private QRScannerService:QRScannerService,
    private modal:ModalService
  ) {
    super();
    this.pwa.initOfflineListeners();
    this.pwa.checkServerHealth();
    this.whitelabel$.subscribe((response) => {});
    this.setupAnalytics();
    this.setupGoogleTagManager();
    this.setInitialDataOnQRScan();
    this.setInitialDataForSEO();
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let path = router.url.split('/');
        this.selectedRoute = path[path.length - 1];
        // const auth = this.store.selectSnapshot((app) => app.auth);
        const auth = this.store.selectSnapshot((app) => app.authEmail);
        // const { loggedInUser } = auth;
        const { login } = auth;
        const other = this.store.selectSnapshot(WhitelabelState.getOther);
        if(router.url.indexOf('/locations') > -1)
          this.openOrganizationSplashScreen();
        if (
          router.url.indexOf('/login') === -1 &&
          router.url.indexOf('/ssoredirection') === -1 &&
          other &&
          other.isForceLogin &&
          (login == undefined ||
          login.isLoggedIn === false)
        )
          this.router.navigate(['login']);
      }
    });
  }
  setupAnalytics() {
    this.analyticsHandler.setupAnalytics();
    //this.analyticsHand = new AnalyticsHandler(this.http).setupAnalytics()
  }

  setupGoogleTagManager() {
    this.googleTagManagerHandler.setupGoogleTagManager();
    //this.googleTagManagerHand = new GoogleTagManagerHandler(this.http).setupGoogleTagManager()
  }
  
  setInitialDataOnQRScan(){
    this.QRScannerService.setInitialDataOnQRScan();
  }


  /**
   * function used for change the display with screen width
   */
  setDisplay(){
    let isMobileViewFlag;
    if (!this.width) 
    this.width = screen.availWidth;
    if (this.width < 770) {
      isMobileViewFlag = true;
    } else {
      isMobileViewFlag = false;
    }
    this.shared.isMobileScreen(isMobileViewFlag);
    this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );


  }
  
  ngOnInit() {
    this.setDisplay();
    console.log("Updated Version!")
   
   
    
    this.messagingService.reciveNotificationIfEnabled();
    const { faviconImage,appPrimaryColor,appSecondaryColor } = this.store.selectSnapshot(
      WhitelabelState.getWhitelabel
    );
    this.appPrimaryColor = appPrimaryColor ? appPrimaryColor : '#000';
    this.appSecondaryColor == appSecondaryColor ? appSecondaryColor : '#fff';
    if (environment.imagebaseurl && faviconImage) {
      this._document
        .getElementById('appFavicon')
        .setAttribute('href', environment.imagebaseurl + faviconImage);
    }
    this.authService.runInitialLoginSequence();
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        // Track navigation end
        this.analyticsHandler.setPageRedirects(evt.urlAfterRedirects);
        this.setDynamicDataForSEO();
      }
     });
  }

  ngOnDestroy() {
    if (this.locationListSubscription)
      this.locationListSubscription.unsubscribe();
    if (this.deliveryListSubscription)
      this.deliveryListSubscription.unsubscribe();
  }

  setInitialDataForSEO(){
    const organizationData = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
    const envImgUrl = environment.imagebaseurl;
    this.jsonLdFormatting(organizationData,envImgUrl);
    this.setStaticDataForSEO(organizationData,envImgUrl);
    this.setPreRenderReadyScript();
  }

  getChild(activatedRoute: ActivatedRoute){
    if(activatedRoute.firstChild){
      return this.getChild(activatedRoute.firstChild);
    }else{
      return activatedRoute;
    }
  }

  setGeoTagsForSEO(){

    let selectedLocation = this.store.selectSnapshot(
      (app) => app.location.selectedLocation
    );

    if(selectedLocation){
    this.metaService.updateTag({
      name:'geo.position', content:selectedLocation.loc});
    this.metaService.updateTag({
      name:'geo.placename', content:selectedLocation.addressObj['city']});
    this.metaService.updateTag({
      name:'geo.region', content:selectedLocation.addressObj['country']});
      this.metaService.updateTag({
        name:'ICBM', content:selectedLocation.loc});
    }
  }

  setDynamicDataForSEO(){
    var titleRoute = this.getChild(this.activatedRoute)
    let organizationData = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
    let webAppTitle = '';
    let webAppDescription = ''
    let WebAppName = ''
    let orderOnlineText = ' Order online';

    this.restaurantDetails = organizationData.restaurantName + "," + organizationData.physicalAddress['city'];
    webAppTitle = organizationData.restaurantName + " in " + organizationData.physicalAddress['city']+".";
    webAppDescription = `Order from ${organizationData.restaurantName} in ${organizationData.physicalAddress['city']} . Moduurn makes online ordering easy`;
    WebAppName = `${organizationData.restaurantName} in ${organizationData.physicalAddress['city']}`;

    this.selectedLocation$.subscribe((selectedLocation) => {
      if(!(window.location.href.endsWith('locations')) && selectedLocation?.servicesSupported?.length){
        let deliveryTypes = selectedLocation.servicesSupported.map(x => x).join(", ");
        webAppTitle = `${selectedLocation.name} in ${selectedLocation.addressObj.city}.`;
        this.category$.subscribe((categories) => {
          if(categories?.items?.length){
            this.availableCategories = categories.items.map(x => x.name).join(", ");
            orderOnlineText = ` Order ${this.availableCategories} online`;
            WebAppName = `${selectedLocation.name} in ${selectedLocation.addressObj.city} .Supports ${deliveryTypes}.${orderOnlineText}`;
            webAppDescription = `Order from ${selectedLocation.name} in ${selectedLocation.addressObj.city} . Moduurn makes online ordering easy. Supports ${deliveryTypes}.${orderOnlineText}`;
          } else {
            WebAppName = `${selectedLocation.name} in ${selectedLocation.addressObj.city} .Supports ${deliveryTypes}.${orderOnlineText}`;
          }
          this.setUpdatedTagForSEO(WebAppName,webAppTitle,webAppDescription,orderOnlineText)
        })
      }
    else{
      webAppTitle = organizationData.restaurantName + " in " + organizationData.physicalAddress['city']+".";
      webAppDescription = `Order from ${organizationData.restaurantName} in ${organizationData.physicalAddress['city']} . Moduurn makes online ordering easy`;
      WebAppName = `${organizationData.restaurantName} in ${organizationData.physicalAddress['city']}`;
      this.setUpdatedTagForSEO(WebAppName,webAppTitle,webAppDescription,orderOnlineText)
    }})

    titleRoute.data.subscribe(data=> {
      // if(data?.description){
          // this.metaService.updateTag({name:'description', content:this.restaurantDetails + ' - ' + data.description});
      this.setUpdatedTagForSEO(WebAppName,webAppTitle,webAppDescription,orderOnlineText);
        // }
      this.setGeoTagsForSEO();
    })
  }

  setUpdatedTagForSEO(WebAppName,webAppTitle,webAppDescription,orderOnlineText){
    webAppTitle = webAppTitle + orderOnlineText;
    this.titleService.setTitle(webAppTitle);
    this.metaService.updateTag({name:'description', content:WebAppName});
    this.metaService.updateTag({ property: 'og:title', content: webAppTitle });
    this.metaService.updateTag({ property: 'og:description', content: webAppDescription});
    this.metaService.updateTag({ property: 'keywords', content: webAppDescription });
  }

  setStaticDataForSEO(organizationData,envImgUrl){
    this.restaurantDetails = organizationData.restaurantName + " in " + organizationData.physicalAddress['city'];
    let webAppTitle = '';
    let orderOnlineText = 'Order Online';
    // let webAppKeyWords = 'restaurants near me, order online, order takeout, order delivery, order ahead';
    let webAppKeyWords = 'restaurants near me, order online, order ahead';
    let webAppDescription = 'Moduurn makes online ordering easy.'
    if(this.restaurantDetails){
      webAppTitle = this.restaurantDetails + ' - ' + orderOnlineText;
      // webAppKeyWords = this.restaurantDetails + ',' + webAppKeyWords;
      webAppDescription = `Order delivery and takeout from ${this.restaurantDetails} . ${webAppDescription}`;  
      webAppKeyWords = webAppDescription;
      this.metaService.addTag({name:'description', content:webAppDescription})
    }
    this.titleService.setTitle(webAppTitle);
    this.metaService.addTag({ property: 'keywords', content: webAppKeyWords });
    this.metaService.addTag({ name: 'robots', content: 'index,follow' });
    this.metaService.addTag({ property: 'og:url', content: organizationData.webOrderingUrl });
    this.metaService.addTag({ property: 'og:type', content: 'website' });
    this.metaService.addTag({ property: 'og:title', content: webAppTitle });
    this.metaService.addTag({ property: 'og:image', content: envImgUrl + organizationData.restaurantLogo });
    this.metaService.addTag({ property: 'og:description', content: webAppDescription});
  }

  setPreRenderReadyScript(){
    setTimeout(() => {
      let preRenderScript = document.createElement('script');
      preRenderScript.innerText = 'window.prerenderReady=true;';
      document.documentElement.firstChild.appendChild(preRenderScript)
      console.log('prerenderReady is true')
    }, 2000);
  }

  jsonLdFormatting(organizationData,envImgUrl){
    this.jsonLD = {
      "@context": "https://schema.org",
      "@type": "Restaurant",
      "name": organizationData.restaurantName,
      "image": envImgUrl + organizationData.restaurantLogo,
      "@id": "",
      "url": organizationData.webOrderingUrl,
      "telephone": this.primaryPhoneCheck(organizationData),
      "menu": "",
      "servesCuisine": "Canadian",
      "acceptsReservations": "true",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": organizationData.physicalAddress['streetAddress'],
        "addressLocality": organizationData.physicalAddress['city'],
        "addressRegion": organizationData.physicalAddress['state'],
        "postalCode": organizationData.physicalAddress['postalcode'],
        "addressCountry": organizationData.physicalAddress['country']
      }  
    }
    this.jsonldSafe=this.getSafeHTML(this.jsonLD);
  }

  getSafeHTML(value:{}){
    const json = value
    ? JSON.stringify(value, null, 2).replace(/\//g, '\\/')
    :'';
    const html = `${json}`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  primaryPhoneCheck(data: any){
    return ('primaryPhone' in data?data.primaryPhone:'');
  }

  openOrganizationSplashScreen(){
    let sessionItems = Object.keys(sessionStorage);
    let viewOrg = !sessionItems.includes('OrgSplash');
    const landingPage = this.store.selectSnapshot(WhitelabelState.getLandingPage);
    if(viewOrg && landingPage){
      this.modal.closeAllModals();
      if(this.isMobileView)
      this.modal.showMobileModal(SplashMessageComponent,{data:{typeOfSplashScreen:TypeOfLandingPages.ORGANIZATIONLEVEL,displayOrder:1}});
      else
      this.modal.showLargeModalDesktop(SplashMessageComponent,{data:{typeOfSplashScreen:TypeOfLandingPages.ORGANIZATIONLEVEL,displayOrder:1}});
    }
  }

}

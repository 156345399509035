/**
 * Usage: value | formattedCurrency :currencyCode  :locate :display :digitsInfo *
 **/

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WhitelabelState, Whitelabel,LocationDefaultState,LocationDefaults } from '@moduurnv2/libs-orderingapp/src/core';
import {
  CurrencyPipe,
  getLocaleCurrencyCode,
  getCurrencySymbol,
  formatCurrency
} from '@angular/common';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
@Pipe({
  name: 'formattedCurrency',
  pure: false,
})

export class CurrencyLocalize extends CurrencyPipe implements PipeTransform {
  whitelabel ;
  allowNoPaymentOrdering;
  @Select(WhitelabelState.getWhitelabel) whitelabel$: Observable<Whitelabel>;
  @Select(LocationDefaultState.getLocationDefaults) locationDefaults$: Observable<LocationDefaults>;
  constructor(private translate: TranslateService) {
    super(translate.currentLang);
    this.whitelabel$.subscribe( response => {
      this.whitelabel = response;
    });
    this.locationDefaults$.subscribe( response => {
      if(response?.locationData?.orderSettings?.allowNoPaymentOrdering){
        this.allowNoPaymentOrdering = response;
      }
    });
  }
  transform(
    value: number,
    currencyCode: string = this.whitelabel.currency ? this.whitelabel.currency : '$',
    display: 'code' | 'symbol' | 'symbol-narrow' | string | boolean = 'symbol',
    digitsInfo: string = '1.2-2'
  ): string | null {
    if(this.allowNoPaymentOrdering){
      return super.transform('');
    } else {
      return super.transform(value, currencyCode, display, digitsInfo);
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import {
  BaseComponent,
  MenuItemCreation,
  CartState,
  CartItems,
  SweetAlertService,
  UpdateComboCount,
  DeleteCombo,
  SharedService,
  WhitelabelState,
  ValidateDeal
} from '@moduurnv2/libs-orderingapp';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ModalService } from '../modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { AlertPopupComponent } from '../alert-popup/alert-popup.component';
import { Router } from '@angular/router';
@Component({
  selector: 'moduurnv2-combo-count',
  templateUrl: 'combo-count.component.html',
  styleUrls: ['combo-count.component.scss'],
})
export class ComboCountComponent extends BaseComponent implements OnInit {
  @Input('currentCombo') currentCombo;
  @Input('menuitem') menuItem;
  @Input() isReviewPage: boolean = false;
  @Select(CartState.getCartComboPriceItems) cartComboPriceItems$: Observable<
    any
  >;
  @Select(CartState.getCartItems) cartItems$: Observable<CartItems[]>;
  value: number = 0;
  isEmpty: boolean;
  myCart: any;
  countSubject = new Subject();
  loading: boolean = false;
  isMobileView: boolean = true;
  constructor(
    public store: Store,
    public sweetAlert: SweetAlertService,
    private modal: ModalService,
    private shared: SharedService,
    public translate: TranslateService,
    private router: Router
  ) {
    super();
    this.isEmpty = true;
  }

  ngOnInit() {
    this.value = this.currentCombo.count;
    this.cartComboPriceItems$
      .pipe(takeUntil(this.destroy$))
      .subscribe((cartComboPrice) => {
        if (cartComboPrice) this.checkIfSelectedCombo(cartComboPrice);
      });
    this.cartItems$.pipe(takeUntil(this.destroy$)).subscribe((cartItems) => {
      this.loading = false;
    });
    this.setupCountSubject();
     this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
  }

  subtractCount(event) {
    // event.stopPropagation();
    const cart = this.store.selectSnapshot(CartState.getCart);
    if (this.value == 1 && this.isReviewPage) {
      const payload = {
        count: this.value - 1,
      };
      this.modal.showSmallModal(AlertPopupComponent, {
        data: {
          header: this.translate.instant('are-you-sure-remove-combo'),
          actionable: true,
        },
        whenClosed: (data) => {
          if (data) this.countSubject.next(payload);
        },
      });
    } else {
      const payload = {
        count: this.value - 1,
      };
      this.countSubject.next(payload);
    }
  }

  addCount() {
    const cart = this.store.selectSnapshot(CartState.getCart);
    if (cart) {
      this.value++;
      const payload = {
        count: this.value,
      };

      this.countSubject.next(payload);
    }
  }

  checkIfSelectedCombo(comboPrice) {
    const updatedCurrentComboPrice = comboPrice.find((combo) => this.currentCombo._id === combo.comboIdInCart);
    if (updatedCurrentComboPrice) {
      this.value = updatedCurrentComboPrice.count;
    } else if (!this.currentCombo) {
      this.value = 0;
    }
  }

  setupCountSubject() {
    this.countSubject
      .pipe(debounceTime(500), takeUntil(this.destroy$))
      .subscribe((data) => {
        const payload = data as any;
        if (payload) {
          this.loading = true;
          let currentComboId = this.currentCombo._id;
          if (payload?.count > 0) {
            this.store.dispatch(new UpdateComboCount(payload, currentComboId)).subscribe(result=>{
              const state = this.store.selectSnapshot((app) => app);
              const { cart } = state;
              const cartId = cart?.cart?.cart_id;
              if(cartId)
              this.store.dispatch(new ValidateDeal(cartId,cart.cart.subTotal))
            });
          } else {
            this.store.dispatch(new DeleteCombo(currentComboId)).subscribe(result=>{
              const state = this.store.selectSnapshot((app) => app);
              const { cart } = state;
              const cartId = cart?.cart?.cart_id;
              if(cartId)
              this.store.dispatch(new ValidateDeal(cartId,cart.cart.subTotal))
            });
          }
        }
      });
  }
}

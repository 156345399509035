<div class="phone-input">
  <select class="md-input" [(ngModel)]="selectedCountry" (ngModelChange)="emitPhone(phone)">
    <option selected [ngValue]="country" *ngFor="let country of countries">{{country.phone}}</option>
  </select>
  <input class="md-input" type="tel" placeholder="{{'enter-mobile'|translate}}" #phoneInput="ngModel" name="phoneInput" [(ngModel)]="phone" (ngModelChange)="emitPhone($event)" [required]="required" maxlength="18">
  <div *ngIf="phoneInput.touched || submitted">
    <small class="text-danger" *ngIf="emittedPhone.international != '' && emittedPhone.valid==false">{{'contact-number-error' |translate}}</small>
    <small class="text-danger" *ngIf="emittedPhone.international == '' && emittedPhone.valid==false && required">{{'mobile-number-req'| translate}}</small>
  </div>
</div>

<div class="giftloyalty-login-container">
  <div class="banner-text" *ngIf="isMobileView">
    {{ 'gift-loyalty-card' | translate }}
  </div>
  <form [formGroup]="giftCardForm" class="card-form-container">
    <div class="header">
      {{ 'card-number' | translate }}
    </div>
    <div class="group">
      <input
        type="text"
        formControlName="number"
        required
        data-qa="gift-card-number"
        aria-label="gift card number"
        name="number"
        id="giftCardNumber"
      />
      <label>{{ 'gift-card-num' | translate }}</label>
      <div
        *ngIf="
          giftCardForm.controls.number.touched &&
          !giftCardForm.controls.number.valid
        "
        class="alert"
      >
        {{ 'card-alert' | translate }}
      </div>
    </div>
    <div class="header">
      {{ 'pin-number' | translate }}
    </div>
    <div class="group">
      <input
        type="text"
        formControlName="giftCardPIN"
        required
        data-qa="gift-pin-number"
        aria-label="gift pin number"
        name="pin"
        id="giftCardPIN"
      />
      <div
        *ngIf="
          giftCardForm.controls.giftCardPIN.touched &&
          !giftCardForm.controls.giftCardPIN.valid
        "
        class="alert"
      >
        {{ 'valid-pin' | translate }}
      </div>
      <label>{{ 'gift-card-pin' | translate }}</label>
    </div>
    <div class="label">
      {{ 'gift-pin-note' | translate }}
    </div>
    <!-- <div class="alert-box">
      <img src="assets/images/Info-Icon.svg" class="icon" alt="info"/>
      <div class="text">
        {{ 'gift-card-note' | translate }}
      </div>
    </div> -->
    <div class="row sign-in-button-container">
      <button
        type="button"
        class="btn-submit"
        (click)="saveCard(addGiftCardBtn)"
        #addGiftCardBtn
        id="add-git-card-submit"
        data-qa="add-card"
        aria-label="add card"
      >
        {{ 'add-card-submit' | translate }}
      </button>
    </div>
  </form>
</div>


<div class="qr-code-container">
<div *ngIf="enableCloseButton">

  <div class="modal-header" *ngIf="!isMobileView">
    <span class="heading"
      >{{descriptionQRCode}}</span>
    <div class="close-image" >
      <img
        src="assets/images/Close.svg"
        data-qa="close-button"
        class="close" (click)="closePopup()"
        alt="close"
      />
    </div>
  </div>

  <div class="close-image"  *ngIf="isMobileView">
    <img
      src="assets/images/Close.svg"
      data-qa="close-button"
      class="close" (click)="closePopup()"
      alt="close"
    />
  </div>
</div>
<div class="qr-code"  *ngIf="valueToGenerateQRCode">
  <qrcode [qrdata]="valueToGenerateQRCode" [width]="sizeQRCode" [errorCorrectionLevel]="'M'"></qrcode>
</div>
<div class="text"  *ngIf="descriptionQRCode && isMobileView">{{descriptionQRCode}}</div>
</div>

import { Component, OnInit } from '@angular/core';
import {FormBuilder} from '@angular/forms'
import {Router} from '@angular/router'
import { environment } from '@moduurnv2/libs-orderingapp/src/core';
import { Store, Select } from '@ngxs/store';
import { EmailSignUpBaseComponent,SharedService,AuthState } from '@moduurnv2/libs-orderingapp';
import { ModalService } from '../../../shared/modal/modal.service';
import { ResendEmailVerificationComponent } from '../../../payment-method/components/gift-loyalty/resend-email-verification/resend-email-verification.component';
import { EmailVerificationComponent } from '../../../payment-method/components/gift-loyalty/email-verification/email-verification.component';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'moduurnv2-email-sign-up',
  templateUrl: './email-sign-up.component.html',
  styleUrls: ['./email-sign-up.component.scss']
})
export class EmailSignUpComponent extends EmailSignUpBaseComponent  implements OnInit {

  constructor(store: Store,router:Router,_shared:SharedService,fb:FormBuilder, public modal: ModalService,translate: TranslateService) {
    super(store,router,_shared,fb,modal,translate)
  }
  emailSignup:string = this.translate.instant('email-signup');
  enterDetails:string = this.translate.instant('enter-details-below');
  emailId:string = this.translate.instant('email-id');
  sampleEmail:string = this.translate.instant('sample-email');
  emailRequired:string = this.translate.instant('email-required');
  validEmail:string = this.translate.instant('valid-email');
  password:string = this.translate.instant('password');
  passwordMinChars:string = this.translate.instant('password-minimum-characters');
  passwordReqd:string = this.translate.instant('password-required');
  confirmPassword:string = this.translate.instant('confirm-password');
  confirmPasswordReqd:string = this.translate.instant('confirm-password-required');
  passwordNotMatch:string = this.translate.instant('password-not-match');
  continueAddress:string = this.translate.instant('continue-adrs');
  followPrivacy:string = this.translate.instant('follow-privacy');
  firstName:string = this.translate.instant('first-name');
  lastName:string = this.translate.instant('last-name');
  firstNameReqd:string = this.translate.instant('first-name-required');
  lastNameReqd:string = this.translate.instant('last-name-required');
  dateOfBirth:string = this.translate.instant('date-of-birth');
  optionalText:string = this.translate.instant('optional-text');
  invalidDateText:string = this.translate.instant('invalid-date');
  mobileNumber:string = this.translate.instant('mobile-number');
  agreeTerms:string = this.translate.instant('agree-terms');
  finish:string = this.translate.instant('finish');
  justLastStep:string = this.translate.instant('just-last-step');
  accountVerification:string = this.translate.instant('account-verification');
  set modalClose(modalRef: any) {
    this._modalClose = () => {
      this.modal.closeModal(modalRef);
    };
    this._modalCloseall = () => {
      this.modal.closeAllModals();
    };
  }
  goBack(value) {
    if(value===1)
    this.stepper=1;
    else if(value===2)
    this.stepper=2;
    else {
    if(this.isMobileView)
    this.modalValue.emit('REGISTER');
    else {
      if (this._modalClose) {
        this._modalClose();
      }else{
        this.modalValue.emit(false);
      }
    }
    }
  }
  ngOnInit()
  {
    const loginFrom = this.store.selectSnapshot(AuthState.getLoginFrom);
    if(loginFrom == 'giftAndLoyalty')
    {
      let componentTitle = this.translate.instant('gift-loyalty-card');
      //this.setSignupSuccessModal(componentTitle,ResendEmailVerificationComponent);
      this.setSignupSuccessModal(componentTitle,ResendEmailVerificationComponent);
    } else if(loginFrom == 'deals' || loginFrom == 'deals-listing')
    {
      this.setSignupSuccessModal('','deals-listing');
    } else if(loginFrom == 'menu')
    {
      this.setSignupSuccessModal('','deals-listing');
    }
    else
    {
      this.setSignupSuccessModal('',undefined);
    }
    super.ngOnInit();
  }
}

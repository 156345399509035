import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import {
  DeliveryMethodService,
  DeliveryState,
  LocationState,
  VenueManagementState,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable()
export class VerifyLoungeGuard implements CanActivate {
  constructor(
    public router: Router,
    private store: Store,
    private deliveries: DeliveryMethodService
  ) {}
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const { _id } = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    const selectedDelivery = this.store.selectSnapshot(
      DeliveryState.getSelectedDelivery
    );
    const { queryParams } = this.router.getCurrentNavigation().finalUrl;
    let tableNumber;
    if (queryParams && queryParams.tableNumber)
      tableNumber = queryParams.tableNumber;
    else {
      const venue = this.store.selectSnapshot(
        VenueManagementState.getSelectedVenue
      );
      if (venue && venue.name) tableNumber = venue.name;
    }
    if (selectedDelivery && selectedDelivery.textCode === 'DELIVERYTYPE08') {
      if (tableNumber == undefined) {
        this.router.navigate(['emptyTable']);
        return false;
      }
    }
    return this.deliveries.fetchDeliveries(_id).pipe(
      map((deliveries) => {
        const { deliveryTypes } = deliveries;
        const lounge = deliveryTypes.find(
          (delivery) => delivery.textCode == 'DELIVERYTYPE08'
        );
        if (
          (lounge && deliveryTypes.length === 1) ||
          deliveryTypes[0].textCode === 'DELIVERYTYPE08'
        ) {
          if (tableNumber == undefined) {
            this.router.navigate(['emptyTable']);
            return false;
          }
        }
        return true;
      })
    );
  }
}

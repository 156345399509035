<ng-container *ngIf="isMobileView; then mobile; else desktop"></ng-container>

<ng-template #mobile></ng-template>

<ng-template #desktop>
  <!-- Header -->
  <div [hidden]="selectedRoute == 'notsupport' || selectedRoute == 'login'">
    <ordering-app-header-desktop></ordering-app-header-desktop>
  </div>
</ng-template>

<div class="app-container">
  <router-outlet></router-outlet>
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true" [name]="spinnerTypes.ORDER_COMPLETION" >
    <p style="color: white; text-align: center;">
      <span style="font-size: clamp(1rem, 1.6vw, 1.6rem);font-family: Poppins">{{'loading-text' | translate }}</span>
      <span style="display:block;padding: 10em 5em 10em 5em;">
        {{ 'processing' | translate }}... </span>
    </p>
  </ngx-spinner>
  <ngx-spinner [bdColor]="appPrimaryColor" [color]="appSecondaryColor" [fullScreen]="true" type="ball-fussion" size="medium" [name]="spinnerTypes.QR_SCAN_INITIAL"></ngx-spinner>
  <ngx-json-ld [json]="jsonldSafe"></ngx-json-ld>
</div>
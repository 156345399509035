import { State, Selector, StateContext, Action, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Address } from '../models';
import {
  FetchAddressList,
  SetAddress,
  SetSelectedAddress,
  FetchDeliveryAddress,
  ChangeDeliveryAddress,
  ChangePopupStatus,
  ChangeMapPopupStatus,
  ChangeToNewDeliveryAddress,
  ChangeTempToDeliveryAddress,
  SaveNewDeliveryAddress,
  ChangeadrsDetailsPopupStatus,
  UpdateAddressList,
  DeleteDeliveryAddress,
  ChangeDeliveryAddressLocal,
  FetchDeliveryAddressDetails,
  setLocationDistance,
  FetchDeliverableDetails,
  ClearSelectedAddress,
  UpdateDeliverableStatus,
  SetEnteredAddress,
  ClearEnteredAddress,
  ClearErrorMessagesOnAddressAddition
} from '../actions';
import { Injectable } from '@angular/core';
import { AddressService } from '../services';
import { tap, catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { AuthState } from './auth.state';
import { throwError } from 'rxjs';

export class AddressStateModel {
  addressList: Address[];
  addressListDetails: Address[];
  addressListLocal: Address[];
  selectedAddress: Address;
  tempselectedAddress: Address;
  // setSelectedAddress: string;
  // modalChangeAdrsStatus: boolean;
  // modalChangeMapStatus: boolean;
  // modalAdrsDetailsStatus: boolean;
  isDeliverable: boolean;
  distance: any;
  isInvalidAddress:boolean;
  guestAddressResponse:Address[];
  enteredAddress:{};
  loggedInUserAddrSaveResponse:any;
  errorMessages:any[];
}

@State<AddressStateModel>({
  name: 'address',
  defaults: {
    selectedAddress: null,
    tempselectedAddress: null,
    addressList: null,
    addressListLocal: null,
    // setSelectedAddress: null,
    // modalChangeAdrsStatus: false,
    // modalChangeMapStatus: false,
    // modalAdrsDetailsStatus: false,
    addressListDetails: null,
    isDeliverable: null,
    distance:null,
    isInvalidAddress:null,
    guestAddressResponse:null,
    enteredAddress:null,
    loggedInUserAddrSaveResponse:null,
    errorMessages:[]
  },
})
@Injectable()
export class AddressState {
  constructor(private addressservice: AddressService, private store: Store) {}

  @Selector()
  static getAddressList(state: AddressStateModel) {
    return state.addressList;
  }
  @Selector()
  static getAddressListFromLocal(state: AddressStateModel) {
    state.addressListLocal = localStorage.getItem('locationList')
      ? JSON.parse(localStorage.getItem('locationList'))
      : undefined;
    return state.addressListLocal;
  }

  @Selector()
  static getSelectedAddress(state: AddressStateModel) {
    return state.selectedAddress;
  }
  @Selector()
  static getTempSelectedAddress(state: AddressStateModel) {
    return state.tempselectedAddress;
  }
  @Selector()
  static getGuestAddressResponse(state: AddressStateModel) {
    return state.guestAddressResponse;
  }

  @Selector()
  static getEnteredAddress(state: AddressStateModel) {
    return state.enteredAddress;
  }

  @Selector()
  static getLoggedInUserAddrSaveResponse(state: AddressStateModel) {
    return state.loggedInUserAddrSaveResponse;
  }
  @Selector()
  static getDeliveryAddress(state: AddressStateModel) {
    if (state.isDeliverable == false) state.selectedAddress = null;
    else if (state.isDeliverable == null || state.isDeliverable == true) {
      if (state.selectedAddress == null)
        state.selectedAddress =
          localStorage.getItem('selectedAdrsLocation') &&
          localStorage.getItem('selectedAdrsLocation') != 'undefined'
            ? JSON.parse(localStorage.getItem('selectedAdrsLocation'))
            : undefined;
    }
    return state.selectedAddress;
  }
  // @Selector()
  // static getAddressPopUpStatus(state: AddressStateModel) {
  //   return state.modalChangeAdrsStatus;
  // }

  // @Selector()
  // static getMapPopUpStatus(state: AddressStateModel) {
  //   return state.modalChangeMapStatus;
  // }
  // @Selector()
  // static getAdrsDetailsPopUpStatus(state: AddressStateModel) {
  //   return state.modalAdrsDetailsStatus;
  // }

  @Selector()
  static getDistance(state: AddressStateModel) {
    return state.distance;
  }

  @Action(FetchAddressList)
  fetchAddress({ setState }: StateContext<AddressStateModel>) {
    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    return this.addressservice.fetchDeliveryAddress(customer.customerId).pipe(
      tap((response) => {
        if (response) {
          setState(
            patch({
              addressList: response['data'],
            })
          );
        } else throw response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }
  @Action(FetchDeliveryAddress)
  fetchdeliveryAddress({ setState }: StateContext<AddressStateModel>) {
    const lastLocation =
      localStorage.getItem('selectedAdrsLocation') &&
      localStorage.getItem('selectedAdrsLocation') != 'undefined'
        ? JSON.parse(localStorage.getItem('selectedAdrsLocation'))
        : undefined;
    {
      setState(
        patch({
          selectedAddress: lastLocation,
        })
      );
    }
  }

  @Action(SetSelectedAddress)
  setAddress(
    { setState }: StateContext<AddressStateModel>,
    { payload }: SetSelectedAddress
  ) {
    setState(
      patch({
        selectedAddress: payload,
      })
    );
  }

  @Action(ChangeTempToDeliveryAddress)
  setNewFromTempAddress(
    { getState, setState }: StateContext<AddressStateModel>,
    { payload }: ChangeTempToDeliveryAddress
  ) {
    setState(
      patch({
        tempselectedAddress: payload,
      })
    );
  }
  @Action(ChangeToNewDeliveryAddress)
  saveNewAddress(
    { getState, setState }: StateContext<AddressStateModel>,
    { payload }: ChangeToNewDeliveryAddress
  ) {
    let selectedLocation = localStorage.getItem('selectedLocation')
      ? JSON.parse(localStorage.getItem('selectedLocation'))
      : undefined;
    if (selectedLocation) {
      this.store.dispatch(new setLocationDistance(selectedLocation._id));
    }
  }

  @Action(DeleteDeliveryAddress)
  deleteAddress(
    { getState, setState }: StateContext<AddressStateModel>,
    { payload }: DeleteDeliveryAddress
  ) {
    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    return this.addressservice
      .deleteDeliveryAddress(customer.customerId, payload)
      .pipe(
        tap((response) => {
          if (response) {
          } else throw response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  @Action(SaveNewDeliveryAddress)
  addNewAddress(
    { getState, setState }: StateContext<AddressStateModel>,
    { payload }: SaveNewDeliveryAddress
  ) {
    let AddressObj = {
      instructions: payload.instructions,
      buzzerNumber: payload.buzzerNumber,
      unitNumber: payload.unitNumber,
      postalcode: payload.postalcode,
      country: payload.country,
      state: payload.state,
      city: payload.city,
      streetAddress: payload.streetAddress,
      addressType: payload.addressType,
      disableAddressChecking:payload['disableAddressChecking'],
      loc:payload['loc']
    };
    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    if (payload._id == '') {
      return this.addressservice
        .saveDeliveryAddress(customer.customerId, AddressObj)
        .pipe(
          tap((response) => {
            if (response) {
              setState(
                patch({
                  loggedInUserAddrSaveResponse: response,
                })
              );
              return response;
            } else throw response;
          }),
          catchError((error) => {
            return throwError(error);
          })
        );
    } else {
      let AddressObj = {
        instructions: payload.instructions,
        buzzerNumber: payload.buzzerNumber,
        unitNumber: payload.unitNumber,
        postalcode: payload.postalcode,
        country: payload.country,
        state: payload.state,
        city: payload.city,
        streetAddress: payload.streetAddress,
        addressType: payload.addressType,
        disableAddressChecking:payload['disableAddressChecking'],
        _id: payload._id,
      };
      return this.addressservice
        .updateDeliveryAddress(customer.customerId, payload._id, AddressObj)
        .pipe(
          tap((response) => {
            if (response) {
              if (response) {
                setState(
                  patch({
                    loggedInUserAddrSaveResponse: response,
                  })
                );
                console.log('response:',response);
              }
            } else{
              console.log('throw error:',response);
               throw response;
            }
          }),
          catchError((error) => {
            return throwError(error);
          })
        );
    }
  }

  @Action(ChangeDeliveryAddress)
  changeAddress(
    { getState, setState }: StateContext<AddressStateModel>,
    { payload }: ChangeDeliveryAddress
  ) {
    const state = getState();
    setState(
      patch({
        selectedAddress: state.addressList.find(
          (address) => address._id === payload
        ),
        tempselectedAddress: state.addressList.find(
          (address) => address._id === payload
        ),
      })
    );

    localStorage.setItem(
      'selectedAdrsLocation',
      JSON.stringify(
        state.addressList.find((address) => address._id === payload)
      )
    );
    let selectedLocation = localStorage.getItem('selectedLocation')
      ? JSON.parse(localStorage.getItem('selectedLocation'))
      : undefined;
    if (selectedLocation) {
      this.store.dispatch(new setLocationDistance(selectedLocation._id));
    }
  }
  @Action(ChangeDeliveryAddressLocal)
  changeAddressLocal(
    { getState, setState }: StateContext<AddressStateModel>,
    { payload }: ChangeDeliveryAddress
  ) {
    const state = getState();
    let locationListFromStorage = JSON.parse(localStorage.getItem('locationList'))
    if(locationListFromStorage){
    setState(
      patch({
        selectedAddress: locationListFromStorage.find(
          (address) => address._id === payload
        ),
      })
    );
    localStorage.setItem(
      'selectedAdrsLocation',
      JSON.stringify(
        locationListFromStorage.find(
          (address) => address._id === payload
        )
      )
    );
    }
    let selectedLocation = localStorage.getItem('selectedLocation')
      ? JSON.parse(localStorage.getItem('selectedLocation'))
      : undefined;
    if (selectedLocation) {
      this.store.dispatch(new setLocationDistance(selectedLocation._id));
    }
  }
  // @Action(ChangePopupStatus)
  // ChangeStatus(
  //   { setState }: StateContext<AddressStateModel>,
  //   { payload }: ChangePopupStatus
  // ) {
  //   setState(
  //     patch({
  //       modalChangeAdrsStatus: payload,
  //     })
  //   );
  // }
  // @Action(ChangeMapPopupStatus)
  // ChangeMapStatus(
  //   { setState }: StateContext<AddressStateModel>,
  //   { payload }: ChangeMapPopupStatus
  // ) {
  //   setState(
  //     patch({
  //       modalChangeMapStatus: payload,
  //     })
  //   );
  // }
  // @Action(ChangeadrsDetailsPopupStatus)
  // ChangeadrsDetails(
  //   { setState }: StateContext<AddressStateModel>,
  //   { payload }: ChangeadrsDetailsPopupStatus
  // ) {
  //   setState(
  //     patch({
  //       modalAdrsDetailsStatus: payload,
  //     })
  //   );
  // }

  @Action(UpdateAddressList)
  UpdateAddressList({ setState }: StateContext<AddressStateModel>) {
    setState(
      patch({
        addressListLocal: localStorage.getItem('locationList')
          ? JSON.parse(localStorage.getItem('locationList'))
          : undefined,
      })
    );
  }
  @Action(FetchDeliveryAddressDetails)
  fetchDeliveryAddressDetails(
    { getState, setState }: StateContext<AddressStateModel>,
    { payload }: FetchDeliveryAddressDetails
  ) {
    const state = getState();
    return this.addressservice.fetchDeliveryAddressDetails(payload).pipe(
      tap((response) => {
        if (response) {
          let customer = this.store.selectSnapshot(
            AuthState.getCommonAuthDetails
          );
          if (
            customer &&
            customer.isLoggedIn &&
            response['errorCode'] != '4001'
          ) {
            for (let i = 0; i < response['data'].length; i++) {
              const index = state.addressList.findIndex(x => x._id == response['data'][i].id);
              if(index>-1 && state.addressList[index]){
                if (response['data'][i].distanceFromAddress != null)
                state.addressList[index].distance = response['data'][i].distanceFromAddress;
                state.addressList[index].deliverable =response['data'][i].isDeliveryAllowed;
              }
              // if (response['data'][i].distanceFromAddress != null) {
              //   state.addressList[i].distance =
              //     response['data'][i].distanceFromAddress;
              // }
              // state.addressList[i].deliverable =
              //   response['data'][i].isDeliveryAllowed;
            }
          } else {
            if (response['errorCode'] != '4001') {

              for (let i = 0; i < response['data'].length; i++) {
                let respAddrObj = response['data'][i];
                if(state.addressListLocal[i] && respAddrObj.loc?.lng && respAddrObj.loc?.lat && !respAddrObj.loc?.isInvalid){
                  if (respAddrObj.distanceFromAddress != null) {
                    state.addressListLocal[i].distance = respAddrObj.distanceFromAddress;
                  }
                  state.addressListLocal[i].deliverable = respAddrObj.isDeliveryAllowed;
                  let validOrNot = respAddrObj.loc.isInvalid ? 1 : 0;
                  if(respAddrObj.loc.lng && respAddrObj.loc.lat)
                  state.addressListLocal[i]['loc'] = [respAddrObj.loc.lng,respAddrObj.loc.lat,validOrNot];
                } else if(respAddrObj.checkedAddressDetails && respAddrObj.loc?.lng && respAddrObj.loc?.lat && !respAddrObj.loc?.isInvalid){
                  let address = respAddrObj.checkedAddressDetails;
                  address['_id']=respAddrObj.checkedAddressDetails['id'];
                  delete address['id'];
                  address['distance'] = respAddrObj.distanceFromAddress;
                  address['deliverable'] = respAddrObj.isDeliveryAllowed;
                  let validOrNot = respAddrObj.loc.isInvalid ? 1 : 0;
                  address['loc'] = [respAddrObj.loc.lng,respAddrObj.loc.lat,validOrNot];
                  state.addressListLocal.push(address)
                } else if(state.addressListLocal[i]){
                  let currentSelectedAddress = JSON.parse(localStorage.getItem('selectedAdrsLocation'));
                  if(currentSelectedAddress && currentSelectedAddress._id == state.addressListLocal[i]._id){
                    localStorage.removeItem('selectedAdrsLocation');
                    localStorage.removeItem('locationDistance');
                  }
                  if(state?.selectedAddress?._id == state.addressListLocal[i]._id){
                    this.store.dispatch(new ClearSelectedAddress())
                  }
                  state.addressListLocal.splice(i,1);
                }

                let previousLocs = JSON.parse(localStorage.getItem('locationList'));
                if(previousLocs?.length>0){
                let updatedLocs = [...previousLocs]
                if(respAddrObj.loc && respAddrObj.loc.lng && respAddrObj.loc.lat && !respAddrObj.loc?.isInvalid){
                 let index = previousLocs.findIndex((addr=> addr._id == respAddrObj.id));
                 if(index > -1 && respAddrObj.loc.lng && respAddrObj.loc.lat){
                  let validOrNot = respAddrObj.loc.isInvalid ? 1 : 0;
                  if (index >= 0) {
                    updatedLocs[index].loc = [respAddrObj.loc.lng,respAddrObj.loc.lat,validOrNot]
                  } else if(respAddrObj.checkedAddressDetails && respAddrObj.checkedAddressDetails !={}){
                    let newAddrs = respAddrObj.checkedAddressDetails;
                    newAddrs['loc'] = [respAddrObj.loc.lng,respAddrObj.loc.lat,validOrNot]
                    updatedLocs.push(newAddrs)
                  }
                 }
                } else if(updatedLocs[i])
                updatedLocs.splice(i,1)
                localStorage.setItem('locationList', JSON.stringify(updatedLocs));
                // this.store.dispatch(new UpdateAddressList())
                }
              }
              // this.store.dispatch(new UpdateAddressList(previousLocs));
              // let enteredAddress = state.enteredAddress;
              // if(enteredAddress && response['data'])
              // {
              //   let previousLocs = JSON.parse(localStorage.getItem('locationList'));
              //   let index = previousLocs.findIndex((adrs) =>
              //     adrs.id == enteredAddress['id']
              //   );
              //   let indexFromResponse = response['data'].findIndex((resp) =>
              //     resp.id == enteredAddress['id']
              //   )
              //   if(indexFromResponse > -1){
              //     let respAddrObj = response['data'][indexFromResponse];
              //     let validOrNot = respAddrObj && respAddrObj.isInvalid ? 1 : 0;
              //     let updatedLocs = [...previousLocs]
              //     if (index >= 0) {
              //       updatedLocs[index].loc = [respAddrObj.lng,respAddrObj.lat,validOrNot]
              //     } else {
              //       enteredAddress['loc'] = [respAddrObj.lng,respAddrObj.lat,validOrNot]
              //       updatedLocs.push(enteredAddress)
              //     }
              //     localStorage.setItem('locationList', JSON.stringify(updatedLocs));
              //     this.store.dispatch(new UpdateAddressList(updatedLocs));
              //   }
              // } else {

              // }
              
              // if (enteredAddress) {
              //   enteredAddress['loc'] = [this.selectedLocation.loc['lng']:undefined,
              //     enteredAddress.loc?this.selectedLocation.loc['lat']:undefined,
              //   ];
              // }
              // previousLocs.push(enteredAddress);
            }
          }
        } else {
        }
      }),
      catchError((error) => {
        console.error(error);
        return throwError(error);
      })
    );
  }
  @Action(FetchDeliverableDetails)
  fetchDeliverableDetails(
    { getState, setState }: StateContext<AddressStateModel>,
    { payload }: FetchDeliverableDetails
  ) {
    const state = getState();
    return this.addressservice.fetchDeliveryAddressDetails(payload).pipe(
      tap((response) => {
        // console.log('ress',response);
        let customer = this.store.selectSnapshot(
          AuthState.getCommonAuthDetails
        );
        // let isInvalid = (!response['data'][0].loc?.lat || !response['data'][0].loc?.lng || (response['data'][0].loc?.isInvalid)) ? true : false;
        let isInvalid = (!response['data'][0].loc?.lat || !response['data'][0].loc?.lng || response['data'][0].loc?.isInvalid) ? true : false;
        let errorMessages = response['data'][0].loc?.errorData;
        if (
          customer &&
          customer.isLoggedIn

        ){
          setState(
            patch({
              isDeliverable: response['data'][0].isDeliveryAllowed,
              isInvalidAddress:isInvalid,
              errorMessages:errorMessages
            })
          );
        } else{
        setState(
          patch({
            isDeliverable: response['data'][0].isDeliveryAllowed,
            distance: response['data'][0].distanceFromAddress,
            guestAddressResponse:response['data'][0],
            isInvalidAddress:isInvalid,
            errorMessages:errorMessages
          })
        );  
        // let previousLocs = JSON.parse(localStorage.getItem('locationList'));
        // if(previousLocs && previousLocs.length){
        //   let updatedLocs = [...previousLocs]
        //   for (let i = 0; i < response['data'].length; i++) {
        //     let respAddrObj = response['data'][i];
        //     if(state.addressListLocal){
        //       let indexToCheck = state.addressListLocal.findIndex(addr=> addr._id == respAddrObj.id);
        //       if(indexToCheck>-1 && state.addressListLocal[indexToCheck] && respAddrObj.loc?.lng && respAddrObj.loc?.lat && !respAddrObj.loc?.isInvalid){
        //         if (respAddrObj.distanceFromAddress != null) {
        //           state.addressListLocal[indexToCheck].distance = respAddrObj.distanceFromAddress;
        //         }
        //         state.addressListLocal[indexToCheck].deliverable = respAddrObj.isDeliveryAllowed;
        //         let validOrNot = respAddrObj.loc.isInvalid ? 1 : 0;
        //         if(respAddrObj.loc.lng && respAddrObj.loc.lat)
        //         state.addressListLocal[indexToCheck]['loc'] = [respAddrObj.loc.lng,respAddrObj.loc.lat,validOrNot];
        //       } else if(indexToCheck>-1 && respAddrObj.checkedAddressDetails && respAddrObj.loc?.lng && respAddrObj.loc?.lat && !respAddrObj.loc?.isInvalid){
        //         let address = respAddrObj.checkedAddressDetails;
        //         address['_id']=respAddrObj.checkedAddressDetails['id'];
        //         delete address['id'];
        //         address['distance'] = respAddrObj.distanceFromAddress;
        //         address['deliverable'] = respAddrObj.isDeliveryAllowed;
        //         let validOrNot = respAddrObj.loc.isInvalid ? 1 : 0;
        //         address['loc'] = [respAddrObj.loc.lng,respAddrObj.loc.lat,validOrNot];
        //         state.addressListLocal.push(address)
        //       } else if(indexToCheck>-1 && state.addressListLocal[indexToCheck]){
        //         state.addressListLocal.splice(indexToCheck,1);
        //       }
        //     }

        //     let index = previousLocs.findIndex((addr=> addr._id == respAddrObj.id));
        //     if(respAddrObj.loc && respAddrObj.loc.lng && respAddrObj.loc.lat && !respAddrObj.loc?.isInvalid){
        //     if(index > -1 && respAddrObj.loc.lng && respAddrObj.loc.lat){
        //       let validOrNot = respAddrObj.loc.isInvalid ? 1 : 0;
        //       if (index >= 0) {
        //         updatedLocs[index].loc = [respAddrObj.loc.lng,respAddrObj.loc.lat,validOrNot]
        //       } else if(respAddrObj.checkedAddressDetails && respAddrObj.checkedAddressDetails !={}){
        //         let newAddrs = respAddrObj.checkedAddressDetails;
        //         newAddrs['loc'] = [respAddrObj.loc.lng,respAddrObj.loc.lat,validOrNot]
        //         updatedLocs.push(newAddrs)
        //       }
        //     }
        //     } else if(index > -1 && updatedLocs[index])
        //     updatedLocs.splice(i,1)
        //   }
        //   localStorage.setItem('locationList', JSON.stringify(updatedLocs));
        // }
        }
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }



  @Action(ClearSelectedAddress)
  clearSelectedAddress({
    patchState,
    getState,
  }: StateContext<AddressStateModel>) {
    patchState({
      selectedAddress: null,
    });
  }
  @Action(UpdateDeliverableStatus)
  UpdateDeliverableStatus(
    { setState }: StateContext<AddressStateModel>,
    { payload }: UpdateDeliverableStatus
  ) {
    setState(
      patch({
        isDeliverable: payload,
      })
    );
  }

  @Action(SetEnteredAddress)
  SetEnteredAddress(
    { setState }: StateContext<AddressStateModel>,
    { payload }: any
  ) {
    setState(
      patch({
        enteredAddress: payload,
      })
    );
  }
  @Action(ClearEnteredAddress)
  ClearEnteredAddress(
    { setState }: StateContext<AddressStateModel>
  ) {
    setState(
      patch({
        enteredAddress: null,
      })
    );
  }
  @Action(ClearErrorMessagesOnAddressAddition)
  ClearErrorMessagesOnAddressAddition(
    { setState }: StateContext<AddressStateModel>
  ) {
    setState(
      patch({
        errorMessages: [],
      })
    );
  }

  
}

// Common
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sso-redirect',
  templateUrl: './sso-redirect.component.html',
  styleUrls: ['./sso-redirect.component.scss'],
})
export class SSORedirect implements OnInit {
  constructor() {}

  ngOnInit() {}
}

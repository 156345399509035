<div class="navbar-container" [ngStyle]="{ 'margin-top': topMargin }">
  <div
    class="navbar"
    id="myNavbar"
    *ngIf="
      dynamincMenuItems.length == 0 || dynamincMenuItems == undefined;
      else loadFromArray
    "
  >
    <div
      class="nav-item"
      (click)="routeTo('home')"
      data-qa="Home"
      [class.active]="selectedPage == 'home'"
      attr.aria-label="{{ 'home' | translate }}"
    >
      <div class="icon home-icon"></div>
      <div class="nav-text">{{ 'home' | translate }}</div>
    </div>
    <div
      class="nav-item"
      (click)="routeTo('menu')"
      data-qa="Menu"
      [class.active]="selectedPage == 'menu'"
      attr.aria-label="{{ 'menu' | translate }}"
    ><div class="numberCircle" *ngIf="cartCount">{{cartCount}}</div>
      <div class="icon menu-icon"></div>
      <div class="nav-text">{{ 'menu' | translate }}</div>
    </div>
    <div
      class="nav-item"
      (click)="routeTo('deals')"
      [class.active]="selectedPage == 'deals'"
      attr.aria-label="{{ 'deals' | translate }}"
      *ngIf="enableCoupon"
    >
      <div class="icon deals-icon"></div>
      <div class="nav-text">{{ 'deals' | translate }}</div>
    </div>
    <div
      *ngIf="!hideOrders"
      class="nav-item"
      (click)="routeTo('view-orders')"
      data-qa="orders"
      [class.active]="selectedPage == 'view-orders'"
      attr.aria-label="{{ 'orders' | translate }}"
    >
      <div class="icon order-icon"></div>
      <div class="nav-text">{{ 'orders' | translate }}</div>
    </div>
    <!-- <div class="nav-item" (click)="routeTo('profile')" [class.active]="selectedPage =='profile'">
    <div class="icon profile-icon"></div>
    <div class="nav-text">{{ 'profile' | translate }}</div>
  </div> -->
  </div> 
  <ng-template #loadFromArray>
    <div class="navbar" id="myNavbar">
      <div
      class="nav-item"
      (click)="routeTo('home')"
      data-qa="Home"
      [class.active]="selectedPage == 'home'"
      attr.aria-label="{{ 'home' | translate }}"
    >
      <div class="icon home-icon"></div>
      <div class="nav-text">{{ 'home' | translate }}</div>
    </div>
      <ng-container *ngFor="let item of dynamincMenuItems">
        <div *ngIf="(item.text=='Gift/Loyalty Card' && enabledGiftandLoyality)|| item.text!='Gift/Loyalty Card'"
          class="nav-item"
          (click)="routeTo(item.path)"
          [attr.data-qa]="item.path"
          [class.active]="selectedPage == item.route"
          attr.aria-label="{{ item.text | translate }}"
        >
          <div [class]="item.iconClass"></div>
          <div class="nav-text">{{ item.text | translate }}</div>
        </div>
      </ng-container>
    </div>
  </ng-template>
</div>

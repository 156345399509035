import { Component, OnInit } from '@angular/core';
import {
  WhitelabelState,
  environment,
  LocationState,
  ClearLoginDetails,
  ClearSelectedAddress,
  SetLoginFrom,
  ClearDeals,
  FetchLocationList,
} from '@moduurnv2/libs-orderingapp/src/core'; 
import { TranslateService } from '@ngx-translate/core';
import { AuthState, Auth } from '@moduurnv2/libs-orderingapp';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ModalService } from '../modal/modal.service';
import { LoginDesktopComponent } from '../../login/components/login-desktop/login-desktop.component';
@Component({
  selector: 'ordering-app-header-desktop',
  templateUrl: './header-desktop.component.html',
  styleUrls: ['./header-desktop.component.scss'],
})
export class HeaderDesktopComponent implements OnInit {
  organizationLogo: string;
  userMenuItems = [
    {
      text: 'Profile',
      imageActive: '/assets/images/Profile_Dropdown_Icon_Hover.svg',
      image: '/assets/images/Profile_Dropdown_Icon.svg',
    },
    // {
    //   text: 'Notifications',
    //   imageActive: '/assets/images/Notifications_Dropdown_Icon_hover.svg',
    //   image: '/assets/images/Notifications_Dropdown_Icon.svg',
    // },
    {
      text: 'Payments',
      imageActive: '/assets/images/Payments_Dropdown_Icon_Hover.svg',
      image: '/assets/images/Payments_Dropdown_Icon.svg',
    },
    {
      text: 'Gift/Loyalty Card',
      imageActive: '/assets/images/Points_Dropdown_Icon_Hover.svg',
      image: '/assets/images/Points_Dropdown_Icon.svg',
    },
    // {
    //   text: 'Settings',
    //   imageActive: '/assets/images/Settings_Dropdown_Icon_Hover.svg',
    //   image: '/assets/images/Settings_Dropdown_Icon.svg',
    // },
    {
      text: 'Logout',
      imageActive: '/assets/images/Logout_Dropdown_Icon_Hover.svg',
      image: '/assets/images/Logout_Dropdown_Icon.svg',
    },
  ];
  languageList = [
    { code: 'en', text: 'EN' },
    { code: 'fr', text: 'FR' },
    { code: 'es', text: 'SP' },
  ];
  allowOnlyGuestUsers: boolean = false;
  @Select(AuthState.getCommonAuthDetails) loggedInUserDetails$: Observable<
    Auth
  >;
  mobileIcon: string = environment.image_basepath + environment.images.mobile;
  constructor(
    private store: Store,
    public translate: TranslateService,
    public router: Router,
    private modal: ModalService
  ) {
    let whitelabel = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
    this.organizationLogo =
      environment.imagebaseurl + whitelabel.restaurantLogo;
    let organizationOtherConfigs = this.store.selectSnapshot(
      WhitelabelState.getOther
    );
    this.allowOnlyGuestUsers = organizationOtherConfigs.allowOnlyGuestUsers;
    let other = this.store.selectSnapshot(WhitelabelState.getOther);
    if(other && other.paymentGateway && other.paymentGateway.textCode != 'PAYMENTGATEWAY02'){
      this.userMenuItems = this.userMenuItems.filter(function( obj ) {
        return obj.text !== 'Payments';
    });
    }
    
  }
  ngOnInit(): void {}

  getSeletedMenu(item) {
    if (item && item.text) {
      switch (item.text) {
        case 'Profile':
          this.router.navigate([`/user-profile`]);
          break;
        case 'Payments':
          this.router.navigate([`/payment`]);
          break;
        case 'Gift/Loyalty Card':
          this.router.navigate([`/user-rewards`]);
          break;
        case 'Logout':
          this.store.dispatch(new ClearLoginDetails());
          this.store.dispatch(new ClearSelectedAddress());
          this.store.dispatch(new ClearDeals());
          this.store.dispatch(new FetchLocationList());
          const selectedLocation = this.store.selectSnapshot(
            LocationState.getSelectedLocation
          );
          const { isForceLogin } = this.store.selectSnapshot(
            WhitelabelState.getOther
          );
          if (isForceLogin) this.router.navigate(['login']);
          else if (selectedLocation)
            this.router.navigate([`locations/${selectedLocation._id}/home`]);
          break;
      }
    }
  }
  routeToHome() {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if (selectedLocation && selectedLocation._id) {
      this.router.navigate([`/locations/${selectedLocation._id}/home`]);
    } else{
      this.router.navigate([`/locations`])
    }
  }
  getSeletedLanguage(item) {
    if (item && item.code) this.translate.use(item.code);
  }
  getSelectedLanguage() {
    let currentLanguage = this.translate.currentLang;
    const filteredLanguage = this.languageList.find(
      (item) => item.code === currentLanguage
    );

    if (filteredLanguage) return filteredLanguage.text;
    else return 'EN';
  }
  scrollToFooter(id) {
    const el: HTMLElement | null = document.getElementById(id);
    if (el) {
      setTimeout(
        () =>
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          }),
        0
      );
    }
  }
  showRegister() {
    this.store.dispatch(new SetLoginFrom(undefined));
    this.modal.showMediumModalDesktop(LoginDesktopComponent);
  }
}

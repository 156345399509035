import { Component } from '@angular/core';
import { AddPaymentCardBaseComponent } from '@moduurnv2/libs-orderingapp/src';
import { Router } from '@angular/router';
import { Store, Select } from '@ngxs/store';
@Component({
  selector: 'moduurnv2-add-payment-card',
  templateUrl: './add-payment-card.component.html',
  styleUrls: ['./add-payment-card.component.scss'],
})
export class AddPaymentCardComponent extends AddPaymentCardBaseComponent {

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { environment } from '../environments/environment';
import { Deals,Auth } from '../models';
@Injectable({
  providedIn: 'root',
})
export class DealsService {
  constructor(private http: HttpClient, private store:Store) {}

  fetchDeals(cartId?: string, subTotal?: number,locationId?:string,customer?:Auth) {
    const url = `${environment.couponApiv3Url}coupon/organization/${Config.organizationId}/location/${locationId}`;
    let body = {};
    if (cartId && subTotal) {
      body = {
        cartId: cartId,
        subTotal: subTotal
      };
    }
    body['customerId'] = customer?.customerId ? customer.customerId : undefined;
    return this.http.post(url, body);
  }

  cartCoupon(cart,coupon?: Deals) {
    // const cart = this.store.selectSnapshot((app)=>app.cart.cart);
    const url = `${environment.cartApiUrl}${cart.cart_id}`;
    let body = {
      couponIds: [],
      subTotal:undefined
    };
    if (coupon?._id) {
      body = {
        couponIds: [coupon._id],
        subTotal : cart.subTotal ? cart.subTotal : undefined
      };
    }
    return this.http.put(url, body);
  }

  validateCoupon(cartId: string, cartTotal: number) {
    const url = `${environment.couponApiv3Url}coupon/cart/${cartId}/validate`;
    let body = {
      subTotal: cartTotal,
    };
    return this.http.post(url, body);
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Pipe({
  name: 'time24to12Transform',
  pure: false,
})
export class Time24to12Format implements PipeTransform {
  constructor(private translate: TranslateService) {
  }
  transform(time: any): any {
    var time24To12 = function (a) {
      //below date doesn't matter.
       let date = new Date('1955-11-05T' + a + 'Z').toLocaleTimeString('bestfit', {
        timeZone: 'UTC',
        // hour12: !0,
        hour: 'numeric',
        minute: 'numeric',
      });
      return  date;
    };
    if(this.translate && this.translate.currentLang == 'fr'){
      return time;
    }
    else{
       let formettedTime = moment(time, 'hh:mm A').format('h:mm A');
       return formettedTime.toUpperCase();
    }
  }
}

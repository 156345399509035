<div
  *ngIf="checkModifier; else loggedOut"
  (click)="checkAuth ? checkAuthorisation(menuItem) : addItem(menuItem)"
  data-qa="add-button"
  class="button-container"
  [ngClass]="{
    loading: loading,
    'disable-button': checkGroupFull
  }"
>
  <span
    class="add-item"
    ng-model="value"
    attr.aria-label="{{ 'add' | translate }}"
    >{{ 'add' | translate }}</span
  >
  <button class="button-add">
    +
  </button>
</div>
<!-- <span
  *ngIf="checkCustomize"
  class="customise"
  attr.aria-label="{{ 'customize' | translate }}"
  >{{ 'customize' | translate }}</span> -->

<ng-template #loggedOut>
  <div
    class="button-container"
    [ngClass]="{
      'show-count-container': reviewMenuItem === true ? menuItem.count : value,
      loading: loading
    }"
  >
    <button
      (click)="subtractCount(menuItem, $event)"
      data-qa="decrement-button"
      class="button-subtract"
      attr.aria-label="{{ 'subtract' | translate }}"
    >
      -
    </button>
    <div
      [ngClass]="{
        'count-value': value
      }"
      ng-model="value"
    >
      {{ value }}
    </div>
    <button
      [ngClass]="{
        'count-plus': reviewMenuItem === true ? menuItem.count : value,
        'disable-button': checkGroupFull
      }"
      class="button-add"
      data-qa="increment-button"
      (click)="addComboItemCount(menuItem, $event)"
      attr.aria-label="{{ 'add' | translate }}"
    >
      +
    </button>
  </div>
</ng-template>

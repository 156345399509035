import { Component, OnInit, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
// import {
//   environment,
//   LocationDefaultState,
//   SweetAlertService,
//   OrderLevelModifierState,
//   SubmitOrderLevelModifierItem,
//   DeliveryState,
//   FetchOrderLevelModifiers,
//   SharedService,
// } from '@moduurnv2/libs-orderingapp/src';
import {
  SubmitOrderLevelModifierItem,
  FetchOrderLevelModifiers,
} from '@moduurnv2/libs-orderingapp/src/core/actions';
import {
  LocationDefaultState,
  OrderLevelModifierState,
  DeliveryState,
} from '@moduurnv2/libs-orderingapp/src/core/state';
import {
  SweetAlertService,
  SharedService,
} from '@moduurnv2/libs-orderingapp/src/core/services';
import {
  environment
} from '@moduurnv2/libs-orderingapp/src/core/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '../../modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ThemeOptions } from '@moduurnv2/features/ui/directives/theme.directive';
import {LanguageLocalize} from '@moduurnv2/features/ui/pipes/language-localize.pipe'
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'moduurnv2-order-level-modifier',
  templateUrl: 'modifier.component.html',
  styleUrls: ['modifier.component.scss'],
})
export class OrderModifierComponent implements OnInit {


  @Input() data: any = null;
  imageBase = environment.imagebaseurl;
  close = environment.image_basepath + environment.images.close;
  checkIfEdit: boolean = false;
  submitClick: boolean = false;
  @Input() _modalClose: any;
  allowNoPaymentOrdering: boolean = false;
  themeOptions: ThemeOptions = {
    appPrimaryColor: true,
    appFontFamily:true
  };
  modifierslist: any;
  selectedModifier: any;
  portionCheck: boolean = false;
  fromCombo: string;
  fromComboDetails;
  showError:boolean = false;
  menuItemIdInGroup: string;
  modifier : any;
  @Select(OrderLevelModifierState.getSelectedModifier) selectedModifier$: Observable<any>;
  isMobileView: boolean;
  destroy$: Subject<boolean> = new Subject<boolean>();
  isHideDescription:Boolean;
  isShowCalorieDetails:Boolean; 

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private modal: ModalService,
    private translate: TranslateService,
    private sweetAlert:SweetAlertService,
    private shared:SharedService
  ) {}

  ngOnInit() { 
    const selectedLocationStatus = this.store.selectSnapshot(LocationDefaultState.getLocationDefaults)
    this.isShowCalorieDetails = selectedLocationStatus.activeProfile.showCalorieInformation;
    this.isHideDescription = selectedLocationStatus.activeProfile.hideDescription;     
    
    this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );    
 }



  set modalClose(modalRef: any) {
    this._modalClose = (data) => {
      this.modal.closeComponentModal(modalRef,data);
    };
  }

  choiceText(modifierItem) {
    const { isForced, maxLimit, minLimit } = modifierItem;
    let choiceOption = this.translate.instant('please-select');
    let endText = this.translate.instant('optional');
    if (isForced) endText = this.translate.instant('required');

    if (maxLimit === 1) return `${choiceOption} 1 `+this.translate.instant('option')+ ` (${endText})` ;
    else if (maxLimit > 0)
      if (!minLimit || minLimit === 0) return `${choiceOption} ${this.translate.instant('upto')} ${maxLimit} `+this.translate.instant('option')+'s'+ ` (${endText})`;
      else if (minLimit === maxLimit)
        return `${choiceOption} ${maxLimit} `+this.translate.instant('option')+'s'+ ` (${endText})`;
      else return `${choiceOption} ${this.translate.instant('between')} ${minLimit} ${this.translate.instant('and')} ${maxLimit} `+this.translate.instant('option')+'s'+ ` (${endText})`;
  }

  headerErrors(modifierValid, id = null) {

    const submitted = this.store.selectSnapshot(OrderLevelModifierState.getSubmitted);
    if (this.submitClick && submitted && modifierValid === false) {
      var element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
        this.submitClick = false;
      }
    }
    
    const selectedModifier = this.store.selectSnapshot(
      OrderLevelModifierState.getSelectedModifier
    );
    if(selectedModifier.selected)
        this.showError = false;
    return submitted ? modifierValid === false : false;
  }

  submitItem() {
    this.submitClick = true;
    const selectedModifier = this.store.selectSnapshot(
      OrderLevelModifierState.getSelectedModifier
    );
    if(selectedModifier.selected){
      this.showError=false;
        this.store.dispatch(new SubmitOrderLevelModifierItem());
        if (selectedModifier.valid) this.closePopup(1);
    } else{
      this.store.dispatch(new SubmitOrderLevelModifierItem());
      this.showError=true;
      this.headerErrors(false);
    }

  }





  closePopup(data=0) {
    if (this._modalClose) this._modalClose(data);
    this.fetchOrderLevelModifiers();
  }

  getActionCaption({ actionCaption,translatedactionCaption, name, translation }) {
    let Lang = new LanguageLocalize(this.translate,this.store);
    let nameTrans = Lang.transform(name,translation);
    let captionTrans=Lang.transform(actionCaption,translatedactionCaption);
    if (captionTrans) return `${captionTrans} ${nameTrans}`;
    else return `Please select your ${nameTrans}`;
  }
  // checkForCartEdit() {
  //   const { cartId } = this.store.selectSnapshot(MenuItemState.getItemDetails);
  //   if(cartId) {
  //     const cartItems = this.store.selectSnapshot(CartState.getCartItems);
  //   }
  // }



getModifierSelectedValue(id)
{
  this.modifierslist =this.store.selectSnapshot(OrderLevelModifierState.getGeneratedModifiers);
  if(this.modifierslist)
  this.selectedModifier= this.modifierslist.find(function (item) {
    if(item.selected==true)
    return (item._id == id && item.selected==true);
  });

}

fetchOrderLevelModifiers = ()=>{
  const selectedLocation = this.store.selectSnapshot(
    LocationDefaultState.getLocationDefaults
  );
  const selectedDelivery = this.store.selectSnapshot(
    DeliveryState.getSelectedDelivery
  );
  let data = {menuProfileId: selectedLocation.activeProfile._id, deliveryType: selectedDelivery._id};
  this.store.dispatch(new FetchOrderLevelModifiers(data));
}

}

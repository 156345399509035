<div
  class="row adrs-details-container show"
>
  <div class="main-header">
    <div class="heading">
      <span>{{ 'enter-address' | translate }}</span>
    </div>
    <div class="close">
      <img
        [src]="icon.close"
        (click)="closeAll()"
        class="icon"
        data-qa="close-adrs-details"
        alt="close"
      />
    </div>
  </div>
  <div class="adrs-card"  [ngClass]="{ changeHeight: modalAdrsDetailsStatus }">
    <div class="complete-adrs">
      <span>{{ 'completeadrs' | translate }}</span>
      <input
        type="text"
        [(ngModel)]="houseNo"
        class="addressdetails"
        placeholder="{{ 'house-details' | translate }}"
      />
      <hr class="border" />
      <span class="required" *ngIf="!isaddresDetails"
        >Please enter complete address</span
      >
    </div>
    <div class="complete-adrs address-search">
      <!-- <span>Search your address</span> -->
      <div id="geocoderSearch"></div>
      <!-- <pre id="geocoderResult"></pre> -->
    </div>
    <div class="complete-adrs">
      <span>{{ 'streetAddress_c' | translate }}</span><span class="required"> *</span>
      <div>
        <input
          type="text"
          [(ngModel)]="streetAddress"
          class="addressdetails"
          placeholder="{{ 'streetAddress_s' | translate }}"
          (ngModelChange)="validateStreet(this.streetAddress)"
        />
      <span class="required" *ngIf="!isValidStreet"
        >Please enter street</span
      >
      </div>
    </div>
    <hr class="border" />
    <div class="complete-adrs">
      <span>{{ 'city_c' | translate }}</span><span class="required"> *</span>
      <div>
        <input
          type="text"
          [(ngModel)]="city"
          class="addressdetails"
          placeholder="{{ 'city_s' | translate }}"
          (ngModelChange)="validateCity(this.city)"
        />
        <span class="required" *ngIf="!isValidCity"
          >Please enter city</span
        >
      </div>
    </div>
    <hr class="border" />
    <div class="complete-adrs">
      <span>{{ 'province_c' | translate }}</span><span class="required"> *</span>
      <div>
        <select class="md-input countryProvince" [(ngModel)]="selectedState">
          <option *ngFor="let state of states">{{ state.n }}</option>
        </select>
      </div>
    </div>
    <div class="complete-adrs">
      <span>{{ 'country_c' | translate }}</span><span class="required"> *</span>
      <div>
        <select class="md-input countryProvince"  (ngModelChange)="fetchStates($event)" [(ngModel)]="selectedCountry">
          <option *ngFor="let country of countries">{{ country.code }}</option>
        </select>
      </div>
    </div>

    <div class="complete-adrs">
      <div>
        <span *ngIf="country == 'United States'">{{
          'zipcode' | translate
        }}</span>
        <span *ngIf="country != 'United States'">{{
          'postalcode' | translate
        }}</span
        ><span class="required"> *</span>
      </div>
      <input
        type="text"
        class="addressdetails"
        placeholder="{{ 'zipcode_s' | translate }}"
        [(ngModel)]="zipCode"
        (ngModelChange)="validate(this.zipCode)"
      />
      <hr class="border" />
      <span class="required" *ngIf="!isValid && country != 'United States'">{{
        'postalcodeerror' | translate
      }}</span>
      <span class="required" *ngIf="!isValid && country == 'United States'">{{
        'zipcodeerror' | translate
      }}</span>
    </div>
    <div class="complete-adrs">
      <span>{{ 'buzzerNumber_c' | translate }}</span>
      <div>
        <input
          type="text"
          [(ngModel)]="buzzerNo"
          class="addressdetails"
          placeholder="{{ 'buzzerNumber_s' | translate }}"
        />
      </div>
    </div>
    <hr class="border" />
    <div class="complete-adrs">
      <span>{{ 'delivery-instrctn' | translate }}</span>
      <input
        type="text"
        class="addressdetails"
        [(ngModel)]="instructions"
        placeholder="{{ 'special-instrctn' | translate }}"
      />
      <hr class="border" />
      <!-- <div>{{ 'Eg-instruction' | translate }}</div> -->
      <div class="labeltext">
        {{ 'taglocation' | translate }}
      </div>
      <div class="btn-grp" [ngClass]="{ btnselected: adrsType == 3 }">
        <button
          class="btn"
          *ngIf="adrsType != 3"
          [ngClass]="{ selected: adrsType == 1 }"
          (click)="selectAdrsType(1)"
          data-qa="home-tag"
        >
          {{ 'Home' | translate }}
        </button>
        <button
          class="btn"
          *ngIf="adrsType != 3"
          [ngClass]="{ selected: adrsType == 2 }"
          (click)="selectAdrsType(2)"
          data-qa="office-tag"
        >
          {{ 'Office' | translate }}
        </button>
        <button
          class="btn"
          [ngClass]="{ selected: adrsType == 3 }"
          (click)="selectAdrsType(3)"
          data-qa="other-tag"
        >
          {{ 'Other' | translate }}
        </button>
      </div>
      <div
        class="adrs-type"
        [ngClass]="{ adrstypeactive: adrsType == 3 }"
        *ngIf="adrsType == 3"
      >
        <input
          type="text"
          class="othertype"
          placeholder="Save as"
          [(ngModel)]="otherAdrsType"
        />
        <hr class="border-adrs-type" />
      </div>
    </div>
  </div>
  <div class="btn-save">
    <button
      class="save"
      (click)="saveAdrs(saveAddressBtn)"
      #saveAddressBtn
      id="save-address-submit"
      [ngClass]="{ btnactive: isValid && isaddresDetails && zipCode && city && streetAddress && selectedState !='Select Province' }"
      [disabled]="!isValid || !isValidCity || !isValidStreet || !zipCode || selectedState=='Select Province'"
      data-qa="save-address"
    >
      {{ 'saveadrses' | translate }}
    </button>
  </div>
</div>

import { State, Selector, StateContext, Action, Store } from '@ngxs/store';
import { Auth } from '../models';
import { SetCommonAuthDetails,FetchGiftLoyaltyCards } from '../actions';
import { Injectable } from '@angular/core';
import { SetAgreeToTerms, SetCartId,SetLoginFrom } from '../actions/auth.actions';
import { UpdateDeliverableStatus, ClearSelectedAddress,FetchDeals } from '../actions';

export class AuthStateModel {
  loggedInUser: Auth;
  loginFrom:string;/* 'giftAndLoyalty' for login from GiftAndLoyalty form, undefined for normal login */
  // navigatedFrom:string;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    loginFrom: undefined,
    // navigatedFrom:undefined,
    loggedInUser: {
      isLoggedIn: false,
      userName: undefined,
      loginProvider: undefined,
      imgUrl: undefined,
      cartId: undefined,
      primaryPhone: undefined,
      customerId: undefined,
      agreeToTerms: false
    },
  },
})
@Injectable()
export class AuthState {
  constructor(private store: Store) {}

  @Selector()
  static getCommonAuthDetails(state: AuthStateModel) {
    return state.loggedInUser;
  }

  @Selector()
  static getLoginFrom(state: AuthStateModel) {
    return state.loginFrom;
  }
  // @Selector()
  // static getNavigatedFrom (state:AuthStateModel){
  //   return state.navigatedFrom
  // }

  @Selector()
  static getAgreeToTerms(state: AuthStateModel) {
    return state.loggedInUser.agreeToTerms;
  }

  @Action(SetCommonAuthDetails)
  SetCommonAuthDetails(
    { patchState }: StateContext<AuthStateModel>,
    { payload }: SetCommonAuthDetails
  ) {
    const loggedInUserDetails = payload;
    const currentPage = window?.location?.pathname.split('/')?.['3'];
    if(currentPage && (currentPage == 'deals-listing' || currentPage =='deals')){
        this.store.dispatch(new FetchDeals(loggedInUserDetails?.cartId));
    }

    patchState({
      loggedInUser: loggedInUserDetails,
    });

    this.store.dispatch(new UpdateDeliverableStatus(false));
    this.store.dispatch(new ClearSelectedAddress());
  }

  @Action(SetCartId)
  SetCartId(
    { getState, patchState }: StateContext<AuthStateModel>,
    { payload }: SetCartId
  ) {
    const { loggedInUser } = getState();
    if (loggedInUser && payload) {
      loggedInUser.cartId = payload;
      patchState({
        loggedInUser: loggedInUser,
      });
    }
  }

  @Action(SetLoginFrom)
  SetLoginFrom(
    { patchState }: StateContext<AuthStateModel>,
    { payload }: SetLoginFrom
  ) {
    patchState({
      loginFrom: payload,
    });
  }
  // @Action(setNavigatedfrom)
  // setNavigatedfrom(
  //   {patchState}:StateContext<AuthStateModel>,
  //   { payload}:setNavigatedfrom
  // ){
  //   patchState({
  //     navigatedFrom:payload
  //   })
  // }

  @Action(SetAgreeToTerms)
  SetAgreeToTerms(
    { getState, patchState }: StateContext<AuthStateModel>,
    { payload }: SetAgreeToTerms
  ) {
    const { loggedInUser } = getState();
    console.log(payload);
    patchState({
      loggedInUser: { ...loggedInUser, agreeToTerms : payload },
    });
  }
}

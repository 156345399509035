import { State, Selector, StateContext, Action, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { CustomerFavourites } from '../models';
import { FetchCustomerFavouritesList, FetchLocationList, UpdateFavouriteLocation } from '../actions';
import { Injectable } from '@angular/core';
import { CustomerFavouritesService } from '../services';
import { tap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs';
import { LocationState } from './location.state';
import { AuthState } from './auth.state';

export class CustomerFavouritesStateModel {
  customerFavouritesList: CustomerFavourites[];
}

@State<CustomerFavouritesStateModel>({
  name: 'customerfavourites',
  defaults: {
    customerFavouritesList: undefined
  }
})
@Injectable()
export class CustomerFavouritesState {
  constructor(private store: Store , private _customerFavourites: CustomerFavouritesService) {}

  @Selector()
  static getCustomerFavouritesList(state: CustomerFavouritesStateModel) {
    return state.customerFavouritesList;
  }

  @Action(FetchCustomerFavouritesList)
  fetchCustomerFavourites(
    { setState }: StateContext<CustomerFavouritesStateModel>,
    { payload }: FetchCustomerFavouritesList
  ) {
    return this._customerFavourites.fetchCustomerFavourites(payload).pipe(
      tap(response => {
        if (response)
          this.store.dispatch(new FetchLocationList());
        else throw response;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }


  @Action(UpdateFavouriteLocation)
  updateCustomerFavourites(
    { setState }: StateContext<CustomerFavouritesStateModel>,
    { payload }: any
  ) {
    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    return this._customerFavourites.updateCustomerFavourite(customer.customerId, payload).pipe(
      tap(response => {
        if (response)
          this.store.dispatch(new FetchLocationList());
        else throw response;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }
  
}

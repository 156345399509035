<ng-container *ngIf="whiteLabel else loading">
  <div class="inner-page" [ngStyle]="{ 'background': whiteLabel.appPrimaryColor}">

    <div class="validation-card">
      <div class="col-sm-12 logo"> <img src="{{ imageBaseUrl + whiteLabel.restaurantLogo}}" alt="restaurant icon"/></div>

      <ng-container *ngIf="verified else loadingValidation">
        <p [ngClass]="{error : verified.error }">
          <strong>{{verified.message}}</strong>
        </p>
        <p *ngIf="!verified.error"> You will be redirected to the home page in {{ counter }} seconds </p>
      </ng-container>

      <ng-template #loadingValidation> Please wait while we check the data ... </ng-template>
    </div>

  </div>
</ng-container>

<ng-template #loading> Loading the page ...</ng-template>

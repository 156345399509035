import { Component, OnInit, Input } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import {
  MenuItemState,
  FetchSelectedMenuItem,
  SubmitModifierItem,
  UpdateItemCount,
  environment,
  LocationDefaultState,
  SweetAlertService,
  ComboState,
  ValiateMenuItem,
  LocationState,
  ItemDetailsBaseComponent,
} from '@moduurnv2/libs-orderingapp/src';
import { Observable } from 'rxjs/internal/Observable';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '../modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ThemeOptions } from '@moduurnv2/features/ui/directives';
import {LanguageLocalize} from '@moduurnv2/features/ui/pipes/index'
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'moduurnv2-modifier',
  templateUrl: 'modifier.component.html',
  styleUrls: ['modifier.component.scss'],
})
export class ModifierComponent  implements OnInit {
  @Select(MenuItemState.getSelectedMenuItem) selectedMenuItem$: Observable<any>;
  @Select(MenuItemState.getMenuItemPrice) price$: Observable<any>;
  @Select(MenuItemState.getGeneratedCount) count$: Observable<any>;
  @Select(MenuItemState.getItemDetails) details$: Observable<any>;
  @Select(LocationState.getLocationStatus)locationStatus$:Observable<any>;
  @Select(MenuItemState.getMenuItemPoints) points$: Observable<any>;

  @Input() data: any = null;
  imageBase = environment.imagebaseurl;
  close = environment.image_basepath + environment.images.close;
  checkIfEdit: boolean = false;
  submitClick: boolean = false;
  @Input() _modalClose: any;
  allowNoPaymentOrdering: boolean = false;
  themeOptions: ThemeOptions = {
    appPrimaryColor: true,
    // appFontFamily:true
  };
  modifierslist: any;
  selectedModifier: any;
  portionCheck: boolean = false;
  fromCombo: string;
  fromComboDetails;
  menuItemIdInGroup: string;
  freeTextmodifierslist: any;
  selectedFreeTextModifier : any;
  isShowCalorieDetails:boolean;
  isHideDescription:boolean;

  destroy$: Subject<boolean> = new Subject<boolean>();
  selectedMenuItem:any;
  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private modal: ModalService,
    private translate: TranslateService,
    private sweetAlert:SweetAlertService
  ) {
    
  }

  ngOnInit() {
    if (this.data) this.loadInitialData(this.data); 
      this.locationStatus$.subscribe(data=>{    
      this.isShowCalorieDetails=data.activeProfile.showCalorieInformation;
      this.isHideDescription=data.activeProfile.hideDescription;
    })
    if (this.data) this.loadInitialData(this.data);
    this.selectedMenuItem$.pipe(takeUntil(this.destroy$))
      .subscribe((selectedMenuItem) => {
        if (selectedMenuItem) {
          this.selectedMenuItem = selectedMenuItem;
          if(this.fromCombo && this.fromComboDetails){
            this.checkPriceOverriding();
          }
        }
      })
    
  }
  checkPriceOverriding(){
    let menuItem = this.fromComboDetails.group_items.filter(item => item.menuItem._id == this.selectedMenuItem._id);
    if(menuItem && menuItem[0] && menuItem[0].selectedOverridePortions ){
     let selectedOverridePortions = menuItem[0].selectedOverridePortions;
      this.selectedMenuItem.portions.map(item => {
        let index = selectedOverridePortions.findIndex(portionId => portionId ==item._id);
        if(index > -1){
          item.price = menuItem[0].overridePriceValue;
        }
      })

    }
  }
  ngOnDestroy() {
    this.fromCombo = '';
  }

  loadInitialData(data: any) {
    const { menuItemId, fromCombo, menuItemIdInGroup } = data;
    this.fromCombo = fromCombo;
    this.fromComboDetails = this.store.selectSnapshot(ComboState.getComboGroup(fromCombo));
    this.menuItemIdInGroup = menuItemIdInGroup;
    const portionData=this.store.selectSnapshot(MenuItemState.getSelectedMenuItem)?.portions;
    if(portionData)
    this.getGeneratedPortion(portionData);

    this.store.dispatch(new FetchSelectedMenuItem(menuItemId, !!fromCombo,this.menuItemIdInGroup));
    this.checkIfEdit = this.store.selectSnapshot(MenuItemState.getCartDetails);
   
    this.checkNoPaymentOrdering();
  }

  set modalClose(modalRef: any) {
    this._modalClose = () => {
      this.modal.closeModal(modalRef);
    };
  }

  choiceText(modifierItem) {
    const { isForced, maxLimit, minLimit } = modifierItem;
    let choiceOption = this.translate.instant('please-select');
    let endText = this.translate.instant('optional');
    if (isForced) endText = this.translate.instant('required');

    if (maxLimit === 1) return `${choiceOption} 1 `+this.translate.instant('option')+ ` (${endText})` ;
    else if (maxLimit > 0)
      if (!minLimit || minLimit === 0) return `${choiceOption} ${this.translate.instant('upto')} ${maxLimit} `+this.translate.instant('option')+'s'+ ` (${endText})`;
      else if (minLimit === maxLimit)
        return `${choiceOption} ${maxLimit} `+this.translate.instant('option')+'s'+ ` (${endText})`;
      else return `${choiceOption} ${this.translate.instant('between')} ${minLimit} ${this.translate.instant('and')} ${maxLimit} `+this.translate.instant('option')+'s'+ ` (${endText})`;
  }

  headerErrors(modifierValid, id = null) {
    const submitted = this.store.selectSnapshot(MenuItemState.getSubmitted);
    const selectedMenuItem = this.store.selectSnapshot(
      MenuItemState.getSelectedMenuItem
    );
    if (this.submitClick && submitted && (modifierValid === false || !selectedMenuItem.valid)) {
      let menuItemDivId = this.getErrorMenuItemToHighlight(selectedMenuItem);
      var element = document.getElementById(menuItemDivId ? menuItemDivId : id);      
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        });
        this.submitClick = false;
      }
    }

    return submitted ? modifierValid === false : false;
  }

  getErrorMenuItemToHighlight(selectedMenuItem){
    let modifiersMenuItemIds = selectedMenuItem.modifiers ? selectedMenuItem.modifiers.filter(filtMenItms =>  !filtMenItms.valid) : '';
    let freeTextModifiersMenuItemIds = selectedMenuItem.freeTextModifiers ? selectedMenuItem.freeTextModifiers.filter(filtFreTxtMenItms =>  !filtFreTxtMenItms.valid) : '';
    return modifiersMenuItemIds.length > 0 ? modifiersMenuItemIds[0]._id : (freeTextModifiersMenuItemIds.length > 0 ? freeTextModifiersMenuItemIds[0]._id : null) ;
  }

  submitMenuItem() {
    this.submitClick = true;
    const selectedMenuItem = this.store.selectSnapshot(
      MenuItemState.getSelectedMenuItem
    );
    const currentCount = this.store.selectSnapshot(MenuItemState.getGeneratedCount);
    if(this.validateMaxItemsPerUser(selectedMenuItem._id,currentCount)){
        if(this.fromCombo){
          if(selectedMenuItem.valid){
            this.store.dispatch(new SubmitModifierItem(this.fromCombo, this.menuItemIdInGroup));
            return this.closePopup();
          }
        }
        this.store.dispatch(new SubmitModifierItem());
        if (selectedMenuItem.valid) this.closePopup();
      }
  }
  
  scrollOnTop(val){
    var element = document.getElementById('tb-'+val);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
     
    }

  }
  increaseCounter() {
    this.changeCounter(1);
  }

  decreaseCounter() {
    this.changeCounter(-1);
  }

  changeCounter(count) {
   const selectedMenuItem = this.store.selectSnapshot(
      MenuItemState.getSelectedMenuItem
    );
    const currentCount = this.store.selectSnapshot(MenuItemState.getGeneratedCount);
    if(this.validateMaxItemsPerUser(selectedMenuItem._id,currentCount+count)){
    this.store.dispatch(new UpdateItemCount(count));
    }
  }

  closePopup() {
    if (this._modalClose) this._modalClose();
  }

  getActionCaption({ actionCaption,translatedactionCaption, name, translation }) {
    let Lang = new LanguageLocalize(this.translate,this.store);
    let nameTrans = name ? Lang.transform(name,translation) : '';
    let captionTrans= actionCaption ? Lang.transform(actionCaption,translatedactionCaption) : '';
    if (captionTrans) return `${captionTrans} ${nameTrans}`;
    else return `Please select your ${nameTrans}`;
  }
  // checkForCartEdit() {
  //   const { cartId } = this.store.selectSnapshot(MenuItemState.getItemDetails);
  //   if(cartId) {
  //     const cartItems = this.store.selectSnapshot(CartState.getCartItems);
  //   }
  // }

  checkNoPaymentOrdering() {
    const locationDefaults = this.store.selectSnapshot(
      LocationDefaultState.getLocationDefaults
    );
    if (
      locationDefaults &&
      locationDefaults?.locationData?.orderSettings?.allowNoPaymentOrdering
    ) {
      this.allowNoPaymentOrdering =
        locationDefaults.locationData.orderSettings.allowNoPaymentOrdering;
    }
  }


    validateMaxItemsPerUser(selectedMenuItemId,selectedMenuCountToUpdate){
    const state = this.store.selectSnapshot((app) => app);
    const { menulist, cart,venuemanagement } = state;
    let guestCount = undefined;
      if(venuemanagement?.totalPersonPerTable?.count){
        guestCount = parseInt(venuemanagement.totalPersonPerTable['count']);
        }
        if(guestCount > 0){
          let selectedCategory;
          let cartItemPrice;

            if(cart.cart && cart.cart.itemPrice){
                  cartItemPrice = cart.cart.itemPrice;
                  let selectedItem = cart.cart.itemPrice.find(item=> item.itemId == selectedMenuItemId);
                  if(selectedItem){
                  let selectedCategoryId = selectedItem.menuSectionId;
                    selectedCategory = menulist.menuList.categories.find(cat =>cat._id == selectedCategoryId);
                  } else if(menulist.selectedCategory){
                    selectedCategory = menulist.selectedCategory;
                  }
            } else if(menulist.selectedCategory){
              selectedCategory = menulist.selectedCategory;
            }
          if(selectedCategory && selectedCategory.maxItemsPerOrder && selectedCategory.maxItemsPerOrder>0){
            if(cartItemPrice){
            let cartItemsFromCurrentCategory = cartItemPrice.filter(item=>item.menuSectionId == selectedCategory._id);
            let selectedMenuDetails = cartItemPrice.find(item=>item.itemId == selectedMenuItemId);
            const totalItemCountPerCategory = cartItemsFromCurrentCategory.reduce((total, eachItem) => {
                  return total + eachItem.count;
              }, 0);
            let totalItemsValidPerCategory = guestCount * selectedCategory.maxItemsPerOrder;

            if(this.isOrderItemCountExceeded(totalItemsValidPerCategory,totalItemCountPerCategory,selectedMenuCountToUpdate,selectedMenuDetails)){
            this.sweetAlert.toast('error', 'Sorry, that’s the most you can order.');
            //  if(selectedMenuDetails?.count) {
            //    this.value = selectedMenuDetails.count;
            //  } else {
            //    this.value = 0;
            //  }
              return false;
            } else {
              return true;
            }   
            } else if(selectedMenuCountToUpdate > guestCount * selectedCategory.maxItemsPerOrder){
              this.sweetAlert.toast('error', 'Sorry, that’s the most you can order.');
              return false;
            }
            else {
              return true;
            } 
          } else  {
            return true;
          }
          }  else if(guestCount == 0){
            this.sweetAlert.toast('error', 'You cant proceed without entering the number of guests.');
            return false;
        } else {
          return true;
        }
  }

  isOrderItemCountExceeded(totalItemsValidPerCategory,totalItemCountPerCategory,selectedMenuCountToUpdate,selectedMenuDetails){
    let isExceeded;
    if(this.checkIfEdit){
      if(((totalItemsValidPerCategory <= (selectedMenuCountToUpdate + this.checkIfEdit['count'])) && (!selectedMenuDetails || selectedMenuDetails.count < selectedMenuCountToUpdate))||(totalItemsValidPerCategory < selectedMenuCountToUpdate))
      {
        isExceeded = true
      } else isExceeded =  false;
    } else if(
      !this.checkIfEdit
    ) {if(((totalItemsValidPerCategory < (totalItemCountPerCategory+selectedMenuCountToUpdate)))||(totalItemsValidPerCategory < selectedMenuCountToUpdate))
      {
        isExceeded = true
      } else isExceeded = false;
    }

    return isExceeded;
  }

  get checkComboMax() {
    // if(this.fromComboDetails){
    //   const { itemCount } = this.fromComboDetails;
    //   const count = this.store.selectSnapshot(MenuItemState.getGeneratedCount);
    //   return itemCount? count >= itemCount: false;
    // }
    if(this.fromCombo){
      const cartCombo = this.store.selectSnapshot(ComboState.getCartCombo);
      if(cartCombo?.comboGroups){
        const foundCartCombo = cartCombo.comboGroups.find( comboGroup => comboGroup.groupId === this.fromComboDetails._id );
        if(foundCartCombo){
             const count = this.store.selectSnapshot(MenuItemState.getGeneratedCount);
          const itemCount = foundCartCombo.groupItems.reduce((total, item) => {
            return total + item.count;
          }, 0);
          return this.fromComboDetails.itemCount && (itemCount+count) >= this.fromComboDetails.itemCount;
        }
      }
        const { itemCount } = this.fromComboDetails;
        const count = this.store.selectSnapshot(MenuItemState.getGeneratedCount);
        return count >= itemCount;
      
    }
    return false;
  }

  get checkGroupFull(){
    if(this.fromCombo){
      const cartCombo = this.store.selectSnapshot(ComboState.getCartCombo);
      if(cartCombo?.comboGroups){
        const foundCartCombo = cartCombo.comboGroups.find( comboGroup => comboGroup.groupId === this.fromComboDetails._id );
        if(foundCartCombo){
          const itemCount = foundCartCombo.groupItems.reduce((total, item) => {
            return total + item.count;
          }, 0);
          return this.fromComboDetails.itemCount && itemCount >= this.fromComboDetails.itemCount;
        }
      }
    }
    return false;
  }

getModifierSelectedValue(id)
{
  this.modifierslist =this.store.selectSnapshot(MenuItemState.getGeneratedModifiers)
  this.selectedModifier= this.modifierslist.find(function (item) {
    if(item.selected==true)
    return (item._id == id && item.selected==true);
  });
  
}
getGeneratedPortion(portion)
{
  portion = portion.filter(item => item.selected=== true);
  if(!portion)
  return;
  if(portion.length>0)
    this.portionCheck=true;
}
setModifierValue(){
  this.store.dispatch(new ValiateMenuItem({}))

}

getFreeTextModifierSelectedValue(id)
{
  this.freeTextmodifierslist =this.store.selectSnapshot(MenuItemState.getGeneratedFreeTextModifiers)
  this.selectedFreeTextModifier= this.freeTextmodifierslist.find(function (item) {
    if(item.selected==true)
    return (item._id == id && item.selected==true);
  });
}

headerErrorsFreeTextModifier(modifier, id = null) {
  const submitted = this.store.selectSnapshot(MenuItemState.getSubmitted);
  const selectedMenuItem = this.store.selectSnapshot(
      MenuItemState.getSelectedMenuItem
    );
  if (this.submitClick && submitted && modifier.isForced == true && (modifier.value == false || !selectedMenuItem.valid)) {    
    let menuItemDivId = this.getErrorMenuItemToHighlight(selectedMenuItem);
    var element = document.getElementById(menuItemDivId ? menuItemDivId : id);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
      this.submitClick = false;
    }
  }
  return submitted ? (modifier.isForced == true && modifier.valid == false) :  false;
}

choiceTextFreeTextModifier(modifierItem) {
  const { isForced } = modifierItem;
  
  let endText = this.translate.instant('optional');
  if (isForced) endText = this.translate.instant('required');
  return '(' +endText + ')';
}
isLoyaltyEnabled(menuItem){
  if (menuItem.organizationId && menuItem.organizationId.other && menuItem.organizationId.other.employeeLoyalty && menuItem.organizationId.other.employeeLoyalty.isAllow)
        return true;   
  else
  return false;  
}
}

/**
 * Usage: value | formattedDecimal :digitsInfo
 **/

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DecimalPipe, formatNumber } from '@angular/common';

@Pipe({
  name: 'formattedDecimal',
  pure: false,
})
export class DecimalLocalize extends DecimalPipe implements PipeTransform {
  constructor(private translate: TranslateService) {
    super(translate.currentLang);
  }
  transform(value: number, digitsInfo: string = '3.2-2'): string | null {
    return formatNumber(value, this.translate.currentLang, digitsInfo);
  }
}

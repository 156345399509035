import { OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';

import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs';

import {
  BaseComponent,
  environment,
  Regex,
  AuthEmailState,
  SetForgotPasswordRequest,
  AuthEmailGenericResponse,
  SharedService,
  ClearForgotPasswordData
} from '@moduurnv2/libs-orderingapp/src/core';


export class EmailForgotPasswordBaseComponent extends BaseComponent implements OnInit {
  emailRegex = Regex.email;
  enteredEmail :string;
  loginLeftArrowIcon: string =
  environment.image_basepath + environment.images.arrow_left_icon;

  forgotPasswordForm = new FormGroup({
    email: new FormControl(
      '',
      Validators.compose([
        Validators.required,
        this._shared.emailpatternValidator(),
      ])
    )
  });
  formSubmitted:boolean =false;
  errorInForgotPassword:string = '';
  forgotPasswordSuccessMsg:string = '';
  authForgotPwdSubscription: Subscription;
  sendResetLinkBtn: any;
  @Select(AuthEmailState.getForgotPasswordResponse) forgotPasswordResp$: Observable<AuthEmailGenericResponse>;
  constructor(private store:Store,
    private _shared: SharedService) {
    super();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.authForgotPwdSubscription) this.authForgotPwdSubscription.unsubscribe();
  }

  get forgotPasswordFormControl() {
    return this.forgotPasswordForm.controls;
  }

  forgotPasswordSubmit(sendResetLinkBtn){
    this.sendResetLinkBtn = sendResetLinkBtn;
    this.startLoader(sendResetLinkBtn.id,sendResetLinkBtn.innerText)
    this.formSubmitted = true;
    this.forgotPasswordSuccessMsg='';
    this.errorInForgotPassword='';
    if (this.forgotPasswordForm.valid) {
      this.enteredEmail = this.forgotPasswordForm.value.email;
      const request ={
        email : this.enteredEmail,
        option:'',
        organizationId:''
      }
     this.store.dispatch(new ClearForgotPasswordData());
     this.store.dispatch(new SetForgotPasswordRequest(request));
     this.authForgotPwdSubscription = this.forgotPasswordResp$.subscribe(res=>{
       if(res && res.status ==200){
        this.forgotPasswordSuccessMsg = res['msg'];
        this.stopLoader(sendResetLinkBtn.id)
       } else if(res && res['msg']){
          this.errorInForgotPassword = res['msg'];
          this.stopLoader(sendResetLinkBtn.id)
       }
     },err=>{
      if(err && err['msg']){
        this.errorInForgotPassword = err['msg'];
        this.stopLoader(sendResetLinkBtn.id)
      }
     })
    } else {
      this.stopLoader(sendResetLinkBtn.id)
    }
  }
}

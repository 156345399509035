<div class="giftloyalty-login-container">
  <form [formGroup]="giftCardDetails" class="card-form-container">
    <div class="banner-text" *ngIf="isMobileView">
      {{ 'gift-loyalty-card' | translate }}
    </div>
    <div *ngIf="cardDetails?.length;else noCards">
      <!-- <div class="card-row">
        {{ 'card-number' | translate }}
        <div class="label">
          {{ card.cardnumber }}
        </div>
      </div>
      <div class="card-row">
          {{ 'card-balance' | translate }}
        <div class="label">
          {{ totalBalanceInCards | formattedCurrency }}
          <ng-container *ngIf="giftAndLoyaltyAmountPresent(card)">  ({{ card.gift_balance | formattedCurrency }} + {{ card.loyalty_balance | formattedCurrency }})</ng-container>
        </div>
      </div> -->
      <div class="card-wrapper" *ngIf="cardDetails?.length;else noCardsAvail" >
        <div class="card-view" *ngFor="let cardDet of cardDetails;">
          <div class="card-detail" (click)="setCardDetails(cardDet)" [ngStyle]="cardDet.selected ?  {'background' : bgColor , 'color' : '#FFFFFF'  } : {'background' : selectedBgColor , 'color' : '#000'  } ">
            <div class="card-number-qr-section">
              <div class="card-number-view">
                <div> {{ 'card-number' | translate }}</div>          
                <div>{{cardDet.cardnumber}}</div>
                <br>
                <hr>
              </div>
              <div class="card-qr-view">
                <moduurnv2-qr-code-component
                      [valueToGenerateQRCode]="cardDet.cardnumber"
                      [sizeQRCode]="65"
                    ></moduurnv2-qr-code-component>
              </div>        
          </div>
  
          <div class="card-balance">
            <div>Card Balance</div> 
            <div class="balance-detail">
              <span class="content">GIFT : </span>
              <span class="amount">{{cardDet.gift_balance | formattedCurrency}}</span>
              <span class="content">+</span>
              <span class="content">LOYALTY : </span>
              <span class="amount">{{cardDet.loyalty_balance | formattedCurrency}}</span>
              <span class="content">=</span>
              <span class="total-amount">{{ cardDet.cardBalance | formattedCurrency }} </span>
            </div>
          </div>
          
          </div>
        </div>  
      </div> 
      
    
      <div
        *ngIf="pageCount >= 1"
        [class]="
          pageCount == 1  ?  'card-row no-border' :'card-row'
        "
      >
        {{ 'order-amount' | translate }}
        <div class="label">
          {{ paymentDetails.amountToPay | formattedCurrency }}
        </div>

        <!-- <div class="tab-button">
          <input
            type="radio"
            name="tabSection"
            (click)="payTotalClick()"
            data-qa="pay-total-button"
            [disabled]="paymentDetails.amountToPay > totalBalanceInCards"
          />
          <div
            class="radio-container"
            [ngClass]="{
              disable: paymentDetails.amountToPay > totalBalanceInCards
            }"
          >
            {{ 'pay-total' | translate }}
          </div>
        </div>
        <div class="tab-button">
          <input
            type="radio"
            name="tabSection"
            (click)="payOtherClick()"
            data-qa="pay-total-button"
          />
          <div class="radio-container">
            {{ 'other' | translate }}
          </div>
          <div *ngIf="isOtherPayment" class="input-form-container">
            <input
              type="number"
              formControlName="amount"
              required
              data-qa="gift-pay-amount"
              placeholder="{{ 'gift-pay-amount' | translate }}"
              aria-label="gift pay amount"
              (change)="onChangeCardAmt()"
            />
            <div
              *ngIf="
                giftCardDetails.controls.amount.touched &&
                !giftCardDetails.controls.amount.valid
              "
              class="alert"
            >
              {{ 'gift-pay-amount' | translate }}
            </div>
          </div>
        </div> -->
            <div class="tab-button">
              {{ 'Payment'| translate }}
                <div class="input-form-container">
                  <input
                    type="number"
                    formControlName="amount"
                    required
                    data-qa="gift-pay-amount"
                    placeholder="{{ 'gift-pay-amount' | translate }}"
                    aria-label="gift pay amount"
                    [(ngModel)]="giftCardPaymentAmt"
                    (change)="onChangeCardAmt()"
                  />
                  <div
                    *ngIf="
                      giftCardDetails.controls.amount.touched &&
                      !giftCardDetails.controls.amount.valid
                    "
                    class="alert"
                  >
                    {{ 'gift-pay-amount' | translate }}
                  </div>
                </div>
              </div>
      </div>
      <div *ngIf="pageCount >= 2 && isOtherPayment" class="card-row no-border">
        {{ 'pending-amount' | translate }}
        <div class="label">
          {{ pendingAmount  | formattedCurrency }}
        </div>
      </div>
      <div *ngIf="pageCount >= 2 && isOtherPayment" class="alert-box">
        <img src="assets/images/Info-Icon.svg" class="icon" alt="info"/>
        <div class="text">
          {{ 'pending-amount-note' | translate }}
        </div>
      </div>
      <div
        class="row sign-in-button-container change-position"
      >
        <!-- <button
          type="button"
          (click)="addGiftCard()"
          *ngIf="pageCount == 0"
          class="add-card"
        >
          <img src="assets/images/add-icon-black.svg" class="icon" alt="add icon"/>
          <span data-qa="card-list-add" aria-label="card-add">
            {{ 'add-new-card' | translate }}
          </span>
        </button> -->
        <div class="new-card-btn-view" *ngIf="cardDetails?.length">
          <div class="add-new-btn">
            <button class="button" (click)="addGiftCard()"
            *ngIf="pageCount == 0">
              <span data-qa="card-list-add" aria-label="card-add">
                {{ 'add-new-card' | translate }}
              </span>
            </button>
          </div>
        </div>
        <button
          class="btn-submit"
          (click)="continue()"
          data-qa="continue-step"
          type="button"
          [disabled]="!giftCardPaymentSelection && card && pageCount != 0"
        >
          {{ 'continue-adrs' | translate }}
        </button>
      </div>
     
    </div>
  </form>
  <ng-template #noCards>
    <div class="no-cards">{{ 'no-cards' | translate }}</div>
    <div class="new-card-btn-view new-card-empty" >
      <div class="add-new-btn">
        <button class="button" (click)="addGiftCard()"
        *ngIf="pageCount == 0">
          <span data-qa="card-list-add" aria-label="card-add">
            {{ 'add-new-card' | translate }}
          </span>
        </button>
      </div>
    </div>
  </ng-template>
</div>

import { State, Action, StateContext, Store, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { retry, catchError,map,retryWhen, delay, take } from 'rxjs/operators';
import { throwError } from 'rxjs';
import {
  FetchOrderLevelModifiers,
  FetchSelectedMenuItem,
  SubmitOrderLevelModifierItem,
  AddOrderLevelModifierToCart,
  SetSelectedModifier,
  SetPaymentSelected,
  RemoveOrderLevelModifierItem,
  SetOrderLevelSubModifierItem,
  RemoveOrderLevelSubModifierItem,
  // SetPortion,
  // SetSubModifierItem,
  // RemoveModifierItem,
   SetOrderLevelModifierItem,
  // SubmitModifierItem,
  // UpdateItemCount,
  // SetItemDetails,
  // AddItemToCart,
  // UpdateCartItem,
  // RemoveSubModifierItem,
  // UpdateCustomItemCount,
  // AddCartCombo,
  // AddItemToCombo,
} from '../actions';
import { OrderLevelModifierService } from '../services';
import { tap, count } from 'rxjs/operators';
import { ComboState } from './combo.state';
import { OrderLevelModifiers} from '../models/order-level-modifiers.interface'
import { patch } from '@ngxs/store/operators';
import { CartState } from './cart.state';
export class OrderLevelModifierstateModel {
  fetchedItems: any;
  selectedItems: any;
  submitted : boolean;
  selectedModifier : any;
  paymentSelected : boolean;
}

@State<OrderLevelModifierstateModel>({
  name: 'orderLevelModifiers',
  defaults: {
    fetchedItems: null,
    selectedItems: null,
    submitted: false,
    selectedModifier : null,
    paymentSelected : false,
  },
})
@Injectable()
export class OrderLevelModifierState {
  constructor(private _modifierService: OrderLevelModifierService, private store: Store) {}

  @Selector()
  static getModifiers(state: OrderLevelModifierstateModel) {
    return state.fetchedItems;
  }
  @Selector()
  static getPaymentSelected(state: OrderLevelModifierstateModel) {
    return state.paymentSelected;
  }

  // @Selector()
  // static getGeneratedPortion(state: MenuItemStateModel) {
  //   return state.selectedMenuItem.portion;
  // }

  @Selector()
  static getGeneratedModifiers(state: OrderLevelModifierstateModel) {
    return state.selectedItems;
  }

  @Selector()
  static getSelectedModifier(state: OrderLevelModifierstateModel) {
    return state.selectedModifier;
  }

  // @Selector()
  // static getGeneratedCount(state: MenuItemStateModel) {
  //   return state.count;
  // }

  // @Selector()
  // static getItemDetails(state: MenuItemStateModel) {
  //   return state.itemDetails;
  // }

  @Selector()
  static getSubmitted(state: OrderLevelModifierstateModel) {
    return state.submitted;
  }

  // @Selector()
  // static getCartDetails(state: MenuItemStateModel) {
  //   return state.cartDetails;
  // }

  // @Selector()
  // static getMenuItemPrice(state: MenuItemStateModel) {
  //   const { selectedMenuItem, count } = state;
  //   const { price, modifiers, portions, organizationId } = selectedMenuItem;
  //   let basePrice = price || 0;

  //   const foundSelectedPortion =
  //     portions.find((portion) => portion.selected) || portions[0];
  //   if (foundSelectedPortion)
  //     if (organizationId.other.isRelative) {
  //       basePrice += foundSelectedPortion.price;
  //     } else {
  //       basePrice = foundSelectedPortion.price;
  //     }
  //   const totalModifierPrice = modifiers.reduce((modifiersTotal, modifier) => {
  //     if (modifier && (modifier.selected || modifier.count)) {
  //       const { modifierItems } = modifier;
  //       if (modifierItems && modifierItems.length > 0) {
  //         const modifierTotal = modifierItems.reduce(
  //           (modifierItemTotal, modifierItem) => {
  //             if (modifierItem && (modifierItem.selected || modifierItem.count)) {
  //               const { items, costDifference } = modifierItem;

  //               if (items && items.length > 0) {
  //                 const itemsPrice = items.reduce((itemsTotal, item) => {
  //                   if (item.selected || item.count) return itemsTotal + item.costDifference * (item.count ? item.count : 1);
  //                   else return itemsTotal;
  //                 }, 0);

  //                 return modifierItemTotal + itemsPrice;
  //               } else return modifierItemTotal + costDifference *( modifierItem.count ? modifierItem.count : 1);
  //             } else {
  //               return modifierItemTotal;
  //             }
  //           },
  //           0
  //         );
  //         return modifiersTotal + modifierTotal;
  //       }
  //     } else {
  //       return modifiersTotal;
  //     }
  //   }, 0);
  //   basePrice += totalModifierPrice;
  //   if (count) basePrice = basePrice * count;
  //   return basePrice;
  // }



  @Action(SetSelectedModifier)
  setSelectedModifier(
    {getState ,setState }: StateContext<OrderLevelModifierstateModel>,
    { id }: SetSelectedModifier
  ) {
    const { fetchedItems } = getState();
    let foundModifier = fetchedItems.find(modifier=> modifier._id == id);
    const validateDetails = this.checkValidations(foundModifier);
     return setState(
      patch({
        selectedModifier: validateDetails
      })
    );
  };

  @Action(SetPaymentSelected)
  setPaymentSelected(
    {getState ,setState }: StateContext<OrderLevelModifierstateModel>,
    { payload }: SetPaymentSelected
  ) {
   
     return setState(
      patch({
        paymentSelected: payload
      })
    );
  };

  @Action(FetchOrderLevelModifiers)
  fetchOrderLevelModifiers(
    {setState }: StateContext<OrderLevelModifierstateModel>,
    { payload}: FetchOrderLevelModifiers
  ) {
 
    return this._modifierService.fetchOrderLevelModifier(payload).pipe(
      tap((response) => {
        if (response) {
          this.formatModifiers(response);
          setState(
            patch({
              fetchedItems: this.selectCartItems(response),
            })
          );
        } else throw response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

//   @Action(SetPortion)
//   setGeneratedPortion(
//     { getState, patchState }: StateContext<MenuItemStateModel>,
//     { payload }: SetPortion
//   ) {
//     const { selectedMenuItem, fetchedMenuItem } = getState();
//     selectedMenuItem.portions = selectedMenuItem.portions.map((portion) => {
//       portion.selected = portion._id === payload;
//       return portion;
//     });
//     const tempCopy = clone(fetchedMenuItem);
//     Object.freeze(tempCopy);
//     let filteredModifiers = filterPortion(tempCopy, payload);
//     if(filteredModifiers && filteredModifiers.length){
//       filteredModifiers = formatModifiers(filteredModifiers);
//     }
//     selectedMenuItem.modifiers = filteredModifiers;
//     patchState({
//       selectedMenuItem: checkValidations(selectedMenuItem),
//     });
//   }

  @Action(SetOrderLevelModifierItem)
  setGeneratedSubModifier(
    { getState, patchState }: StateContext<OrderLevelModifierstateModel>,
    { id, payload }: SetOrderLevelModifierItem
  ) {
    const { selectedModifier } = getState();
    
    if (selectedModifier && !selectedModifier.isCounter) {
      const { modifierItems, maxLimit } = selectedModifier;

      if (modifierItems && modifierItems.length > 0) {
        const foundModifierItem = selectedModifier.modifierItems.find(
          (modifierItem) => modifierItem._id === payload.id
        );

        if (foundModifierItem) {
          const selectedItems = modifierItems.filter(
            (modifierItem) => modifierItem.selected
          );

          if (
            (maxLimit > 0 && maxLimit > selectedItems.length) ||
            maxLimit === 0
          ) {
            foundModifierItem.selected = true;
            selectedModifier.selected = true;
          } else if (maxLimit === 1) {
            selectedModifier.modifierItems = selectedModifier.modifierItems.map(
              (modifierItem) => {
                modifierItem.selected = false;
                modifierItem.items = modifierItem.items.map((item) => {
                  item.selected = false;
                  return item;
                });
                return modifierItem;
              }
            );
            foundModifierItem.selected = true;
            selectedModifier.selected = true;
          } else {
            foundModifierItem.selected = false;
            const selectedSubItems = modifierItems.filter(
              (modifierItem) => modifierItem.selected
            );
            if (selectedSubItems.length === 0) selectedModifier.selected = false;
          }
        }
      }
    }
    if (selectedModifier && selectedModifier.isCounter) {
      const { modifierItems, maxLimit } = selectedModifier;

      if (modifierItems && modifierItems.length > 0) {
        const foundModifierItem = selectedModifier.modifierItems.find(
          (modifierItem) => modifierItem._id === payload.id
        );

        if (foundModifierItem) {
          let count = 0;
          modifierItems.forEach(
            (modifierItem) =>{ count += (modifierItem.count ? 1: 0)}
          );

          if (
            (maxLimit > 0 && maxLimit >= count) ||
            maxLimit === 0
          ) {
            if(!foundModifierItem.selected){
              selectedModifier.selected = true;
              selectedModifier.count +=  1;
            }
            foundModifierItem.selected = true;
            foundModifierItem.count = payload.count;
            
          } else if (maxLimit === 1) {
           
            if(!foundModifierItem.selected){
              selectedModifier.selected = true;
              selectedModifier.count +=  1;
            }
            foundModifierItem.selected = true;
            foundModifierItem.count = payload.count;
            } else {
            foundModifierItem.selected = false;
            foundModifierItem.count = 0;
            const selectedSubItems = modifierItems.filter(
              (modifierItem) => modifierItem.selected
            );
            if (selectedSubItems.length === 0) {selectedModifier.selected = false; selectedModifier.count = 0;}
          }
        }
      }
    }

    patchState({
      selectedModifier: this.checkValidations(selectedModifier),
    });
  }

  @Action(RemoveOrderLevelModifierItem)
  removeGeneratedSubModifier(
    { getState, patchState }: StateContext<OrderLevelModifierstateModel>,
    { id, payload }: RemoveOrderLevelModifierItem
  ) {
    const { selectedModifier } = getState();

    if (selectedModifier && !selectedModifier.isCounter) {
      const { modifierItems } = selectedModifier;
      if (modifierItems && modifierItems.length > 0) {
        const foundModifierItem = selectedModifier.modifierItems.find(
          (modifierItem) => modifierItem._id === payload.id
        );
        if (foundModifierItem) {
          foundModifierItem.selected = false;
          const selectedModifierItems = selectedModifier.modifierItems.filter(
            (modifierItem) => modifierItem.selected === true
          );
          if (selectedModifierItems.length === 0)
          selectedModifier.selected = false;
        }
      }
    }
    if (selectedModifier && selectedModifier.isCounter) {
      const { modifierItems } = selectedModifier;
      if (modifierItems && modifierItems.length > 0) {
        const foundModifierItem = selectedModifier.modifierItems.find(
          (modifierItem) => modifierItem._id === payload.id
        );
        if (foundModifierItem) {
          foundModifierItem.selected = (payload.count) ?  true : false;
          foundModifierItem.count = payload.count;
          let selectedModifierItemsCount = 0;
          selectedModifier.modifierItems.forEach(
            (modifierItem) =>{
              selectedModifierItemsCount += (modifierItem.count ? 1 : 0)}
          );
          if (selectedModifierItemsCount=== 0)
            {
              selectedModifier.selected = false;
              selectedModifier.count = 0;
            }
            else{
              selectedModifier.selected = true;
              selectedModifier.count = selectedModifierItemsCount;
            }
        }
      }
    }
    patchState({
      selectedModifier: this.checkValidations(selectedModifier),
    });
  }

  @Action(SetOrderLevelSubModifierItem)
  setGeneratedSubModifierItem(
    { getState, patchState }: StateContext<OrderLevelModifierstateModel>,
    { id, subId, payload }: SetOrderLevelSubModifierItem
  ) {
    const { selectedModifier } = getState();
    
    if (selectedModifier  && !selectedModifier.isCounter) {
      let { modifierItems } = selectedModifier;
      const modifierMaxLimit = selectedModifier.maxLimit;
      const selectedModifierItems = modifierItems.filter(
        (modifierItem) => modifierItem.selected
      );
      if (modifierMaxLimit === 0 || selectedModifierItems.length <= modifierMaxLimit) {
        if (modifierItems && modifierItems.length > 0) {
          const foundModifierItem = selectedModifier.modifierItems.find(
            (modifierItem) => modifierItem._id === subId
          );
          
          if (foundModifierItem) {
            let { items, maxLimit } = foundModifierItem;
            const foundSubModifierItem = items.find(
              (subModifier) => subModifier._id === payload.id
            );
            const selectedItems = items.filter(
              (subModifier) => subModifier.selected
            );
            
            if (foundSubModifierItem) {
              if (maxLimit === 1) {
                // modifierItems = modifierItems.map((modifierItem) => {
                //   modifierItem.selected = false;
                const modifierPresent = selectedModifierItems.find(modifierItem => modifierItem._id === foundModifierItem._id)
                // if(modifierMaxLimit && selectedModifierItems.length === modifierMaxLimit && !modifierPresent )
                //   return;
                if (modifierMaxLimit === 1) {
                  modifierItems = modifierItems.map((modifierItem) => {
                    if(modifierItem._id != foundModifierItem._id){
                      modifierItem.selected = false;
                      modifierItem.items = modifierItem.items.map((item) => {
                        item.selected = false;
                        return item;
                      });
                    }
                    return modifierItem;
                  });
                } else {
                  items = items.map((item) => {
                    item.selected = false;
                    return item;
                  });
                }
                foundSubModifierItem.selected = true;
                foundModifierItem.selected = true;
                selectedModifier.selected = true;
              } else if (
                (maxLimit && maxLimit >= selectedItems.length) ||
                maxLimit === 0
              ) {
                if(maxLimit && maxLimit === selectedItems.length)
                  return;
                if (maxLimit === 1) {
                  items = items.map((item) => {
                    item.selected = false;
                    return item;
                  });
                }
                // const modifierPresent = selectedModifierItems.find(modifierItem => modifierItem._id === foundModifierItem._id)
                // if(modifierMaxLimit && selectedModifierItems.length === modifierMaxLimit && !modifierPresent )
                //   return;
                if (modifierMaxLimit === 1)
                  modifierItems = modifierItems.map((modifierItem) => {
                    if(modifierItem._id != foundModifierItem._id && modifierItem?.items)
                      modifierItem.items.forEach( item => {
                        item.selected = false;
                      })
                    modifierItem.selected = false;
                    return modifierItem;
                  });
                foundSubModifierItem.selected = true;
                foundModifierItem.selected = true;
                selectedModifier.selected = true;
              } else {
                foundSubModifierItem.selected = false;
                foundModifierItem.selected = false;
                selectedModifier.selected = false;
              }
            }
          }
        }
      } else {
        const foundModifierItem = selectedModifier.modifierItems.find(
          (modifierItem) => modifierItem._id === subId
        );
        let { items } = foundModifierItem;
        const foundSubModifierItem = items.find(
          (subModifier) => subModifier._id === payload.id
        );
        foundModifierItem.selected = false;
        foundSubModifierItem.selected = false;
      }
    }
    if (selectedModifier && selectedModifier.isCounter) {
      let { modifierItems } = selectedModifier;
      const modifierMaxLimit = selectedModifier.maxLimit;
      let selectedModifierItemscount = 0;
        modifierItems.forEach(
        (modifierItem) =>{
        selectedModifierItemscount += modifierItem.count ? 1 : 0 ;
        }
      );
      if (modifierMaxLimit === 0 || selectedModifierItemscount <= modifierMaxLimit) {
        if (modifierItems && modifierItems.length > 0) {
          const foundModifierItem = selectedModifier.modifierItems.find(
            (modifierItem) => modifierItem._id === subId
          );
          
          if (foundModifierItem) {
            let { items, maxLimit } = foundModifierItem;
            const foundSubModifierItem = items.find(
              (subModifier) => subModifier._id === payload.id
            );
            let selectedItemsCount = 0;
            items.filter(
              (subModifier) => {selectedItemsCount += (subModifier.count ? 1 : 0)}
            );
            
            if (foundSubModifierItem) {
              if (maxLimit === 1) {
                foundSubModifierItem.selected = true;
                foundSubModifierItem.count = payload.count;
                if(!foundModifierItem.selected){
                  foundModifierItem.selected = true;
                  foundModifierItem.count = 1;
                  selectedModifier.selected = true;
                  selectedModifier.count +=  1;
                }
              } else if (
                (maxLimit && maxLimit >= selectedItemsCount) ||
                maxLimit === 0
              ) {
                
                foundSubModifierItem.selected = true;
                foundSubModifierItem.count = payload.count;
                if(!foundModifierItem.selected){
                  foundModifierItem.selected = true;
                  foundModifierItem.count = 1;
                  selectedModifier.selected = true;
                  selectedModifier.count +=  1;
                }
               
              }
            }
          }
        }
      } else {
        const foundModifierItem = selectedModifier.modifierItems.find(
          (modifierItem) => modifierItem._id === subId
        );
        let { items } = foundModifierItem;
        const foundSubModifierItem = items.find(
          (subModifier) => subModifier._id === payload.id
        );
        foundModifierItem.selected = payload.count ? true :  false;
        foundModifierItem.count = selectedModifierItemscount + payload.count;
        foundSubModifierItem.selected =payload.count ? true :  false;
        foundSubModifierItem.count = payload.count;
      }
    }
    patchState({
      selectedModifier: this.checkValidations(selectedModifier),
    });
  }

  @Action(RemoveOrderLevelSubModifierItem)
  removeGeneratedSubModifierItem(
    { getState, patchState }: StateContext<OrderLevelModifierstateModel>,
    { id, subId, payload }: RemoveOrderLevelSubModifierItem
  ) {
    const { selectedModifier } = getState();
   
    if (selectedModifier && !selectedModifier.isCounter) {
      const { modifierItems } = selectedModifier;
      if (modifierItems && modifierItems.length > 0) {
        const foundModifierItem = modifierItems.find(
          (modifierItem) => modifierItem._id === subId
        );
        if (foundModifierItem) {
          const { items } = foundModifierItem;
          const foundSubModifierItem = items.find(
            (item) => item._id === payload.id
          );
          if (foundSubModifierItem) {
            foundSubModifierItem.selected = false;
            const selectedSubmodifierItems = items.filter(
              (item) => item.selected
            );
            if (selectedSubmodifierItems.length === 0) {
              foundModifierItem.selected = false;
              const selectedModifierItems = selectedModifier.modifierItems.filter(
                (modifierItem) => modifierItem.selected === true
              );
              if (selectedModifierItems.length === 0)
              selectedModifier.selected = false;
            }
          }
        }
      }
    }
    if (selectedModifier && selectedModifier.isCounter) {
      const { modifierItems } = selectedModifier;
      if (modifierItems && modifierItems.length > 0) {
        const foundModifierItem = modifierItems.find(
          (modifierItem) => modifierItem._id === subId
        );
        if (foundModifierItem) {
          const { items } = foundModifierItem;
          const foundSubModifierItem = items.find(
            (item) => item._id === payload.id
          );
          if (foundSubModifierItem) {
            foundSubModifierItem.selected = payload.count ? true:  false;
            foundSubModifierItem.count = payload.count;
            let selectedSubmodifierItemsCount = 0;
            items.forEach(
              (item) => { selectedSubmodifierItemsCount+= (item.count ? 1: 0) }
            );
            if (selectedSubmodifierItemsCount === 0) {
              foundModifierItem.selected = false;
              foundModifierItem.count = 0;
              let selectedModifierItemsCount = 0;
              selectedModifier.modifierItems.forEach(
                (modifierItem) => {selectedModifierItemsCount += (modifierItem.count ? 1 : 0)}
              );
              if (selectedModifierItemsCount === 0)
                {selectedModifier.selected = false;
                  selectedModifier.count = 0;
                }
                else{
                  selectedModifier.count = selectedModifierItemsCount;
                }
            }
          }
        }
      }
    }
    patchState({
      selectedModifier: this.checkValidations(selectedModifier),
    });
  }

  @Action(SubmitOrderLevelModifierItem)
  submitOrderLevelModifierItem({ getState, patchState }: StateContext<OrderLevelModifierstateModel>,{ payload }: SubmitOrderLevelModifierItem) {
    const { selectedModifier } = getState();
    if (selectedModifier.valid === true) {
      const formattedModifier = {
        cartModifier: selectedModifier._id,
        cartModifierItems: [],
      };
    
        if (selectedModifier && (selectedModifier.count || selectedModifier.selected)) {
          
          if (selectedModifier.modifierItems.length > 0) {
            formattedModifier.cartModifierItems = selectedModifier.modifierItems.map(
              (modifierItem) => {
                if (modifierItem && (modifierItem.count || modifierItem.selected)) {
                  const formattedModifierItem = {
                    cartModifierItem: modifierItem._id,
                    type: modifierItem.type,
                    count :modifierItem.count,
                    cartModifierSubItems: [],
                  };
                  if (modifierItem.items.length > 0) {
                    formattedModifierItem.cartModifierSubItems = modifierItem.items.map(
                      (item) => {
                        if (item && (item.count || item.selected))
                          return {
                            cartModifierSubItem: item._id,
                            count :item.count,
                          };
                      }
                    );
                    formattedModifierItem.cartModifierSubItems = formattedModifierItem.cartModifierSubItems.filter(
                      (item) => item != null
                    );
                  }

                  return formattedModifierItem;
                }
              }
            );
          }
          formattedModifier.cartModifierItems = formattedModifier.cartModifierItems.filter(
            (cartModifierItem) => cartModifierItem != null
          );
          this.store.dispatch(new AddOrderLevelModifierToCart(formattedModifier));
        }
       
    }

    patchState({
      submitted: true,
    });
  }

//   @Action(UpdateItemCount)
//   updateCount(
//     { getState, patchState }: StateContext<MenuItemStateModel>,
//     { payload }: UpdateItemCount
//   ) {
//     let { count } = getState();
//     count += payload;
//     if (count > 0)
//       patchState({
//         count: count,
//       });
//   }

//   @Action(UpdateCustomItemCount)
//   UpdateCustomItemCount(
//     { getState, patchState }: StateContext<MenuItemStateModel>,
//     { payload }: UpdateItemCount
//   ) {
//     let { count } = getState();
//     count = payload;
//     if (count > 0)
//       patchState({
//         count: count,
//       });
//   }

//   @Action(SetItemDetails)
//   setItemDetails(
//     { patchState }: StateContext<MenuItemStateModel>,
//     { payload }: SetItemDetails
//   ) {
//     let { cartDetails, ...itemDetails } = payload;
//     patchState({
//       itemDetails: itemDetails,
//       cartDetails: cartDetails,
//     });
//   }
// }

// const clone = (data) => {
//   return JSON.parse(JSON.stringify(data));
// };

 checkValidations = (modifier) => {
      const { minLimit, maxLimit, modifierItems } = modifier;
      let modifierValidFlag = true;
      const selectedModifierItems = modifierItems.filter(
        (modifierItem) => modifierItem.count || modifierItem.selected
      );
      let modifierItemsValid = true;
      let modifierRequiredCheck = true;

      if (modifier.isForced || selectedModifierItems.length > 0) {
        if (modifier.isForced) {
          const minimumModifierItems = minLimit || 1;
          if (selectedModifierItems.length < minimumModifierItems)
            modifierRequiredCheck = false;
        }
        modifierItemsValid = modifierItems.reduce(
          (modifierItemValid, modifierItem) => {
            let modifierItemValidFlag = true;
            let modifierItemRequiredCheck = true;
            const { items, minLimit, maxLimit } = modifierItem;
            const selectedSubItems = items.filter((item) => item.count || item.selected );
            if (modifierItem.isForced || selectedSubItems.length > 0) {
              if (modifierItem.isForced) {
                const minimumSubItems = minLimit || 1;
                if (selectedSubItems.length < minimumSubItems)
                  modifierItemRequiredCheck = false;
              }
              if (
                maxLimit &&
                (minLimit > selectedSubItems.length ||
                  maxLimit < selectedSubItems.length)
              )
                modifierItemValidFlag = false;
            }

            modifierItem.valid =
              modifierItemValidFlag && modifierItemRequiredCheck;
            return modifierItemValid && modifierItemValidFlag;
          },
          true
        );

        if (
          maxLimit &&
          (minLimit > selectedModifierItems.length ||
            maxLimit < selectedModifierItems.length)
        ) {
          modifierValidFlag = false;
        }
      } else
        modifierItems.forEach((modifierItem) => {
          modifierItem.valid = true;
        });

      modifierValidFlag =
        modifierValidFlag && modifierItemsValid && modifierRequiredCheck;
      modifier.valid = modifierValidFlag;
  return modifier;
};

// const filterPortion = ({ modifiers }, portionId) => {
//   const copy = clone(modifiers);
//   const filteredPortions = copy.filter((modifier) => {
//     const { portions } = modifier;
//     if (portions && portions.length > 0) {
//       const foundPorton = portions.find((portion) => portion === portionId);
//       return foundPorton != undefined;
//     } else return true;
//   });
//   return filteredPortions;
// };

 selectCartItems = (modifiers) => {
  
  let cartDetails = this.store.selectSnapshot(CartState.getCart);
  
  if (
    cartDetails &&
    cartDetails.orderLevelModifierPrice &&
    cartDetails.orderLevelModifierPrice.length > 0
  ) {
    const { orderLevelModifierPrice } = cartDetails;
    orderLevelModifierPrice.forEach((eachCartModifier) => {
      const { cartModifierItems } = eachCartModifier;
      // if (cartModifier) {
        const foundMenuModifier = modifiers.find(
          (eachModifier) => eachModifier._id === eachCartModifier.itemId
        );
        if (foundMenuModifier) {
          foundMenuModifier.selected = true;
          foundMenuModifier.isInCart = true;
          foundMenuModifier.count = 0;
          if (cartModifierItems && cartModifierItems.length > 0) {
            cartModifierItems.forEach((eachCartModifierItem) => {
              const { cartModifierSubItems } = eachCartModifierItem;
              const foundModifierItem = foundMenuModifier.modifierItems.find(
                (eachModifierItem) =>
                  eachModifierItem._id ===
                  eachCartModifierItem.cartModifierItem._id
              );
              foundModifierItem.selected = true;
              if(eachCartModifierItem.count){
                foundModifierItem.count = eachCartModifierItem.count;
                foundMenuModifier.count +=1;
              }

              let count = 0;
              if (cartModifierSubItems && cartModifierSubItems.length > 0) {
                cartModifierSubItems.forEach((eachCartItem) => {
                  const foundSubItem = foundModifierItem.items.find(
                    (eachItem) =>
                      eachItem._id === eachCartItem.cartModifierSubItem._id
                  );
                  foundSubItem.selected = true;
                  if(eachCartItem.count){
                    foundSubItem.count = eachCartItem.count;
                    count += eachCartItem.count;
                  }
                });
              }
              if(count && count > 0){
                foundMenuModifier.count +=1;
              }
            });
          }
        }
      // }
    });
  }

  return modifiers;
};
 formatModifiers = (modifiers) => {

  modifiers.forEach(modifier=>{
   if(modifier.isCounter)
   { modifier.count = 0;
    if(modifier.modifierItems && modifier.modifierItems.length){
      modifier.modifierItems.forEach(modifierItem =>{
        if(modifierItem.type == 'item'){
          modifierItem.count = modifierItem.minLimit ? modifierItem.minLimit : 0;
          if( modifierItem.count> 0){
            modifier.count = modifier.count > 0 ? parseInt(modifier.count) + 1: 0; 
            modifier.selected = true;
            modifierItem.selected = true;
          }
        }
        else if(modifierItem.type =='modifier'){
          if(modifierItem && modifierItem.items && modifierItem.items.length){
            let count =0;
            modifierItem.items.forEach(subModifierItem=>{
              subModifierItem.count = subModifierItem.minLimit ? subModifierItem.minLimit : 0;
              if(subModifierItem.count){
                subModifierItem.selected = true;
                count++; 
              }
              
              
            });
            if(count > 0){
              modifierItem.count = 1;
              modifierItem.selected = true;
              modifier.selected = true;
              modifier.count = modifier.count > 0 ? parseInt(modifier.count) + 1 : 1; 
            }
            else {
              modifierItem.count = 0;
            }
          }
        }
      });
    }
  }
  });
  return modifiers;
};
}

<ng-container *ngIf="appVersion$ | async as appVersion">
  <div class="container">
    <div class="title">New Version Available!</div>
    <div class="image">
      <img src="assets/images/technical-support.png" class="left-icon" />
    </div>
    <div class="message">
      Please update to the latest version to ensure the best performance and new
      features.
      <ng-container *ngIf="appVersion.isForceUpdate">
        Please update the app from store to continue
      </ng-container>
    </div>
    <button class="update btn-primary" (click)="navgateToStore()">
      Update
    </button>
    <ng-container *ngIf="appVersion.isRecommendUpdate">
      <button class="update btn-primary" (click)="continue()">
        Continue
      </button>
    </ng-container>
    <!-- <ng-container *ngIf="appVersion.isRecommendUpdate">
        <div class="title">New Version Available!</div>
        <div class="message">
          Please update to the latest version to ensure the best performance and
          new features.
        </div>
      </ng-container> -->
  </div>
</ng-container>

import {
  State,
  Selector,
  StateContext,
  Action,
  Store,
  createSelector,
} from '@ngxs/store';
import { Combo, CartCombo, CartComboGroups, CartCreation } from '../models';
import {
  AddCartCombo,
  AddItemToCombo,
  FetchCombos,
  UpdateItemCountCombo,
  UpdateCartCombo,
  ClearComboCart,
  AddComboToCart,
  SetSelectedCart,
  FetchETA,
  SetAckrooPaymentDetails,
  FetchCart,
  FetchCartMenuItems,
  ClearSelectedCombo,
  UpdateEntireCartCombo,
  SetMealPaymentDetails,
  
  
} from '../actions';
import { Injectable } from '@angular/core';
import { MenuItemService, MenuListService,SweetAlertService } from '../services';
import { HttpClient } from '@angular/common/http';
import { MenuItemState } from './menu-item.state';

import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { environment } from '../environments/environment';
import { CartState } from './cart.state';
import { AuthState } from './auth.state';
import { Router } from '@angular/router';


export class ComboStateModel {
  selectedCombo: Combo;
  cartCombo: CartCombo;
}

@State<ComboStateModel>({
  name: 'combos',
  defaults: {
    selectedCombo: null,
    cartCombo: null,
  },
})
@Injectable()
export class ComboState {
  comboIdandErrorArray = [];
  constructor(
    private menulists: MenuListService,
    private store: Store,
    private http: HttpClient,
    private router: Router,
    private sweetAlert:SweetAlertService
  ) {}

  @Selector()
  static getSelectedCombo(state: ComboStateModel) {
    return state.selectedCombo;
  }
  
  @Selector()
  static getCartCombo(state: ComboStateModel) {
    return state.cartCombo;
  }

  static getComboGroup(groupId: string) {
    return createSelector([ComboState], (state: ComboStateModel) => {
      const foundGroup = state.selectedCombo.combo_groups.find(
        (combo) => combo._id === groupId
      );
      return foundGroup || null;
    });
  }

  static getComboItem(menuItem) {
    return createSelector([ComboState], (state: ComboStateModel) => {
      let foundItem;
      state.selectedCombo.combo_groups.find( (combo) => {
        const foundInSubHeading = combo.subHeadings.find( subHeading => {
          foundItem =  subHeading.groupItems.find( groupItem => groupItem._id === menuItem);
          return !!foundItem;
        })
        return !!foundInSubHeading;
      });
      return foundItem;
    });
  }

  static getCartComboItem(menuItem,comboGroupId) {
    return createSelector([ComboState], (state: ComboStateModel) => {
      let foundItem;
      state.cartCombo.comboGroups.find( (combo) => {
        foundItem = combo.groupItems.find( groupItem => {
          return (groupItem.menuItem === menuItem && combo.groupId == comboGroupId);
        })
        return !!foundItem;
      });
      return foundItem;
    });
  }
  @Action(FetchCombos)
  fetchCombos(
    { patchState }: StateContext<ComboStateModel>,
    { payload }: FetchCombos
  ) {
    this.menulists.fetchCombo(payload).subscribe((response) => {
      const comboResponse = response as any;
      if (comboResponse && comboResponse.data) {
        const formattedCombo: Combo = comboResponse.data;
        formattedCombo.combo_groups = formattedCombo.combo_groups.map( comboGroup => {
          const formattedSubHeadings = [];
          comboGroup.group_items.forEach( groupItem => {
            if(groupItem?.menuItem && !groupItem.menuItem.isAvailable)
              return;
            const { subHeading } = groupItem;
            if(subHeading){
              const foundSubHeading = formattedSubHeadings.find( formattedSubHeading => formattedSubHeading._id === subHeading._id );
              if(foundSubHeading)
                foundSubHeading.groupItems.push(groupItem);
              else
              formattedSubHeadings.push({
                ...subHeading,
                groupItems: [ groupItem ]
              })
            } else {
              if(formattedSubHeadings && formattedSubHeadings.length){
                const foundDefault = formattedSubHeadings.find(formattedSubHeading => formattedSubHeading.name == undefined)
                if(foundDefault){
                  foundDefault.groupItems.push(groupItem)
                }else
                formattedSubHeadings.push({
                  ...subHeading,
                  groupItems: [ groupItem ]
                });
              }
              else
                formattedSubHeadings.push({
                  ...subHeading,
                  groupItems: [ groupItem ]
                });
            }
          });
          comboGroup.subHeadings = formattedSubHeadings;
          return comboGroup;
        });
        formattedCombo.combo_groups = formattedCombo.combo_groups.map(
          (group, groupIndex) => {
            group.open = groupIndex === 0;
            group.subHeadings = group.subHeadings.map((subHeading, index) => {
              subHeading.open = index === 0 && groupIndex === 0;
              return subHeading;
            })
            return group;
          }
        );
        this.validateCombo(formattedCombo);
        patchState({
          selectedCombo: formattedCombo,
        });
      }
    });
  }

  @Action(AddItemToCombo)
  addItemToCombo(
    { getState }: StateContext<ComboStateModel>,
    { payload, groupId }: AddItemToCombo
  ) {
    // // console.log(payload);
    const { _id, menuItem, selectedMenuItem, portion: portionId, cartModifiers, count ,cartFreeTextModifiers } = payload;
    const group = this.store.selectSnapshot(ComboState.getComboGroup(groupId));
    if (
      group && group.itemCount && (this.checkGroupItemsCount(groupId, menuItem) + count) > group.itemCount
    )
      return;
    
    const menuItemToCart = {
      count: count,
      menuItem: menuItem,
      menuItemId: menuItem,
      cartModifiers,
      menuItemIdInGroup: _id,
      selectedPortion: null,
      selectedModifiers: null,
      portion: null,
      cartFreeTextModifiers,
      selectedFreeTextModifiers : null,
    };
    if(selectedMenuItem){
      const{ portions, modifiers, portionActionCaption , freeTextModifiers} = selectedMenuItem;
      if(portions && portions.length >0){
        const foundSelectedPortion = portions.find( portion => portion._id === portionId );
        menuItemToCart.selectedPortion = foundSelectedPortion;
        menuItemToCart.portion = foundSelectedPortion._id;
        menuItemToCart.selectedPortion.portionActionCaption = portionActionCaption;
      }
      if(modifiers){
        menuItemToCart.selectedModifiers = modifiers.filter( modifier => {
          const foundCartModifier = cartModifiers.find(cartModifier => cartModifier.cartModifier === modifier._id)
          if(foundCartModifier){
            foundCartModifier.data = modifier;
            foundCartModifier.cartModifierItems = foundCartModifier.cartModifierItems.map( eachCartModifierItem => {
              const modifierItem = modifier.modifierItems.find( modifierItem => modifierItem._id === eachCartModifierItem.cartModifierItem)
              eachCartModifierItem.data = modifierItem;
              eachCartModifierItem.cartModifierSubItems = eachCartModifierItem.cartModifierSubItems.map( eachCartModifierSubItem => {
                eachCartModifierSubItem.data = modifierItem.items.find( item => item._id === eachCartModifierSubItem.cartModifierSubItem );
                return eachCartModifierSubItem;
              });
              return eachCartModifierItem
            })
            return true;
          }
        })
      }
      if(freeTextModifiers && freeTextModifiers.length){
        menuItemToCart.selectedFreeTextModifiers = freeTextModifiers.filter( modifier => {
          const foundCartModifier = cartFreeTextModifiers.find(cartModifier => cartModifier.cartModifier === modifier._id)
          if(foundCartModifier){
            foundCartModifier.data = modifier;
            return true;
          }
        })
        
      }
    }
      
    const { cartCombo } = getState();
    if (cartCombo) this.store.dispatch(new UpdateCartCombo(menuItemToCart, groupId));
    else 
    this.store.dispatch(new AddCartCombo(menuItemToCart, groupId));
  }

  @Action(UpdateItemCountCombo)
  updateItemCountCombo(
    { getState,patchState }: StateContext<ComboStateModel>,
    { payload, groupId }: UpdateItemCountCombo
  ) {
    const { cartCombo } = getState();
    const { count, menuItemId, cartGroupId } = payload;
    const cartGroup = cartCombo.comboGroups.find(
      (combo) => combo.groupId === groupId
    );
    const group = this.store.selectSnapshot(ComboState.getComboGroup(groupId));
    
    // console.log(group.itemCount);
    // console.log(this.checkGroupItemsCount(groupId, menuItemId));
    // console.log(count);
    if(count < 1){
      cartGroup.groupItems = cartGroup.groupItems.filter(groupItem => groupItem.menuItem !== menuItemId);
    } else if (group && group.itemCount && (this.checkGroupItemsCount(groupId, menuItemId) + count) <= group.itemCount) {
      const foundMenuItem = cartGroup.groupItems.find(groupItem => groupItem.menuItem === menuItemId);
      foundMenuItem.count = count;
    }
    cartCombo.comboGroups = cartCombo.comboGroups.filter( comboGroup => comboGroup.groupItems.length );
    // cartCombo.count = cartCombo.comboGroups.length;
    const comboSelected = this.store.selectSnapshot(ComboState.getSelectedCombo);
    this.validateCombo(comboSelected)
    if(cartCombo.comboGroups.length === 0)
      patchState({
        cartCombo: null
      });
    else
      patchState({
        cartCombo: {...cartCombo}
      });
      

  }

  @Action(UpdateCartCombo)
  updateCartCombo(
    { getState, patchState }: StateContext<ComboStateModel>,
    { payload, groupId }: UpdateCartCombo
  ) {
    // console.log(payload);
    // console.log(groupId);
    // console.log(getState());
    const { selectedCombo, cartCombo } = getState();
    const {
      count,
      portion,
      cartModifiers,
      menuItem,
      menuItemId,
      menuItemIdInGroup,
      selectedPortion,
      selectedModifiers,
      cartFreeTextModifiers,
      selectedFreeTextModifiers,
    } = payload;
    const group = selectedCombo.combo_groups.find(
      (combo) => combo._id === groupId
    );
    // console.log(group);
    const foundCartComboGroup = cartCombo.comboGroups.find( group => group.groupId === groupId);
    const newCartGroupItem = {
      menuItem: menuItem,
      menuItemId: menuItemId,
      count: count,
      portion: portion,
      cartModifiers: cartModifiers,
      groupMenuItemId: menuItemIdInGroup,
      selectedPortion,
      selectedModifiers,
      cartFreeTextModifiers : cartFreeTextModifiers,
      selectedFreeTextModifiers,

    }
    if(foundCartComboGroup){
      const [menuItemExistWithSameCustomization,countUpdatedMenuItem] = this.checkSelectedComboMenuItemExistInCurrentCart(newCartGroupItem,foundCartComboGroup,menuItemIdInGroup);
      if(menuItemExistWithSameCustomization){
        return this.store.dispatch(new UpdateItemCountCombo(countUpdatedMenuItem,groupId));
      } else {
        foundCartComboGroup.groupItems.push(countUpdatedMenuItem)
      }
      // if(foundCartComboGroup.groupItems.find(eachGrroupItem => eachGrroupItem.groupMenuItemId === menuItemIdInGroup)){
      //   return this.store.dispatch(new UpdateItemCountCombo(payload,groupId));
      // }
      // foundCartComboGroup.groupItems.push(newCartGroupItem)
    } else {
      // cartCombo.count ++;
      const newCartComboGroup: CartComboGroups = {
        groupId: groupId,
        groupItems: [newCartGroupItem],
        externalIntegId: group.externalIntegId,
      };
      cartCombo.comboGroups.push(newCartComboGroup);
    }
    this.validateCombo(selectedCombo);
    patchState({
      cartCombo: { ...cartCombo },
      selectedCombo: { ...selectedCombo }
    })
  }

  @Action(AddCartCombo)
  addCartCombo(
    { getState, patchState }: StateContext<ComboStateModel>,
    { payload, groupId }: AddCartCombo
  ) {
    // console.log(payload);
    // console.log(groupId);
    // console.log(getState());
    const { selectedCombo } = getState();
    const {
      _id,
      count,
      portion,
      cartModifiers,
      menuItem,
      menuItemIdInGroup,
      selectedPortion,
      selectedModifiers,
      cartFreeTextModifiers,
      selectedFreeTextModifiers,
    } = payload;
    const group = selectedCombo.combo_groups.find(
      (combo) => combo._id === groupId
    );
    // console.log(group);
    const newCartCombo:CartCombo = {
      comboId: selectedCombo._id,
      count: 1,
      comboGroups: [],
      valid: true,      
    };
    const newCartComboGroup: CartComboGroups = {
      groupId: groupId,
      groupItems: [
        {
          menuItem: menuItem,
          count: count,
          portion: portion,
          cartModifiers: cartModifiers,
          groupMenuItemId: menuItemIdInGroup,
          selectedPortion,
          selectedModifiers,
          cartFreeTextModifiers : cartFreeTextModifiers,
          selectedFreeTextModifiers,
        },
      ],
      externalIntegId: group.externalIntegId,
    };
    newCartCombo.comboGroups.push(newCartComboGroup);
    // const url = `https://q2wso2.moduurn.ca/cartApi/v1/carts/608a50b534ae9a2f5768fd8b/comboModule?isFuture=false`;
    // this.http.post(url, newCartCombo).subscribe( response => {
    //   // console.log(response);
    // }, err => {
    //   console.error(err);
    // })
    const foundMenuItem = group.group_items.find( menuItem => menuItem._id === menuItemIdInGroup);
    // console.log(foundMenuItem);
    foundMenuItem.selected = count;
    patchState({
      cartCombo: { ...newCartCombo }
    });
    this.validateCombo(selectedCombo);
    patchState({
      selectedCombo: { ...selectedCombo }
    });
  }

  @Action(UpdateEntireCartCombo)
  UpdateEntireCartCombo(
    { getState, patchState }: StateContext<ComboStateModel>,
    { payload }: UpdateEntireCartCombo
  ) {
    const { selectedCombo } = getState();
    this.validateCombo(selectedCombo);
    patchState({
      cartCombo: { ...payload },
      selectedCombo: { ...selectedCombo }
    })
  }


  @Action(ClearComboCart)
  clearComboCart(
    { patchState }: StateContext<ComboStateModel>
  ) {
    patchState({
      cartCombo: null
    })
  }

  
  @Action(ClearSelectedCombo)
  clearSelectedCombo(
    { patchState }: StateContext<ComboStateModel>
  ) {
    patchState({
      selectedCombo: null
    })
  }


  @Action(AddComboToCart)
  addComboToCart(
    { getState }: StateContext<ComboStateModel>
  ) {
    const { cartCombo } = getState();
    const currentCart = this.store.selectSnapshot(CartState.getCart);    
    if(this.comboValidation(cartCombo)){
      if (currentCart && currentCart.cart_id) {
        let url = `${environment.cartApiUrl}${currentCart.cart_id}/comboModule`;
        const futureDate = this.store.selectSnapshot(CartState.getFutureDate);
        url += `?isFuture=${!!futureDate}`
        this.http.post(url, cartCombo).subscribe( response => {
          // console.log(response);
          // const selectedLocation = this.store.selectSnapshot(
          //   LocationState.getSelectedLocation
          // );
          // this.router.navigate([
          //   `/locations/${selectedLocation._id}/menu/606ea00ef141462d3cb8f954`,
          // ]);
          window.history.back();
          this.store.dispatch(new ClearComboCart());
          this.store.dispatch(new FetchCartMenuItems({}));
          this.store.dispatch(new FetchCart());
        }, err => {
          console.error(err);
        });
      } else this.addComboToNewCart(cartCombo);
    } else {     
      this.sweetAlert.toast('error', 'Please select all required items for combo to proceed');      
    }
  }



  // ========= helpers

  checkGroupItemsCount(groupId, menuItemId?) {
    const cartCombo = this.store.selectSnapshot(ComboState.getCartCombo);    
    if(cartCombo){      
      const group = cartCombo.comboGroups.find(
        (combo) => combo.groupId === groupId
      );

      if (group && group.groupItems && group.groupItems.length > 0)
        return group.groupItems.reduce((total, item) => {
          return item.menuItem !== menuItemId ? total + item.count: total;
        }, 0);
      
    }    
    return 0;
  }

  comboValidation(combo: CartCombo){    
    const selectedCombo = this.store.selectSnapshot(ComboState.getSelectedCombo);
    const valid = combo.comboGroups.reduce( ( valid, comboGroup ) => {
      const selectedComboGroup = this.store.selectSnapshot(ComboState.getComboGroup(comboGroup.groupId))

      const currentValid = this.checkMaxCount(selectedComboGroup);
      return valid && currentValid; 
    },true);    
    return valid && this.checkRequiredSelected(combo, selectedCombo);;
  }

  checkMaxCount(selectedComboGroup){
   let  comboGroupErrorStatus={};    
    const { _id, itemCount } = selectedComboGroup;
    const currentCount = this.checkGroupItemsCount(_id);   
    this.comboIdandErrorArray.push({id:_id,validity:itemCount === currentCount})
    return itemCount === currentCount;
  }

  checkRequiredSelected(cartCombo, selectedCombo){    
    const valid = selectedCombo.combo_groups.reduce( (valid, comboGroup) => {
      return valid && ( comboGroup.required? !!cartCombo.comboGroups.find( cartComboGroup => cartComboGroup.groupId === comboGroup._id) : true ) 
    }, true);   
    return valid;
  }

  addComboToNewCart(cartCombo) {
    const selectedDelivery = this.store.selectSnapshot(
      (app) => app && app.delivery && app.delivery.selectedDelivery
    );
    const { _id: locationId } = this.store.selectSnapshot(
      (app) => app.location.selectedLocation
    );
    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    const futureDate = this.store.selectSnapshot(CartState.getFutureDate);
    const newCart: CartCreation = {
      organizationId: Config.organizationId,
      locationId: locationId,
      deliveryMethod: selectedDelivery._id,
      guest: customer?.customerId ? false: true,
      menuItems: [],
      customerId: customer?.customerId ? customer.customerId : undefined,
      combos: [cartCombo],
      futureOrderDetails: { isFuture: false, pickupTime: '' },
      totalPrice: 100,
    };
    if (futureDate) {
      newCart.futureOrderDetails.isFuture = true;
      newCart.futureOrderDetails.pickupTime = new Date(
        futureDate
      ).toISOString();
    }
    this.store.dispatch(new SetSelectedCart(newCart)).subscribe( response => {
        // console.log(response);
        window.history.back();
        this.store.dispatch(new ClearComboCart());
    });
    this.store.dispatch(new FetchETA());
    this.store.dispatch(new SetAckrooPaymentDetails(undefined));
    this.store.dispatch(new SetMealPaymentDetails(undefined));
  }

  validateCombo(selectedCombo:Combo) {
    // const cartCombo = this.store.selectSnapshot(ComboState.getCartCombo);
    // cartCombo.comboGroups.forEach( cartComboGroup => {
      // const foundSelectedGroup = selectedCombo.combo_groups.find( comboGroup => cartComboGroup.groupId === comboGroup._id);
    //   if(foundSelectedGroup)
    //     foundSelectedGroup.subHeadings = foundSelectedGroup.subHeadings.map( eachSubHeading => {
    //       // eachSubHeading.groupItems = eachSubHeading.groupItems.map( groupItem => {
            
    //       // });
    //       cartComboGroup.groupItems.forEach( cartGroupItem => {
    //         const foundGroupItem = eachSubHeading.groupItems.find( eachGroupItem => {
    //           eachGroupItem._id === cartGroupItem.groupMenuItemId;
    //         });
    //         if(foundGroupItem)
              
    //       })
    //       return eachSubHeading
    //     });
    // if(foundSelectedGroup)
    //   foundSelectedGroup['valid'] = this.checkGroupItemsCount(foundSelectedGroup._id) < foundSelectedGroup.itemCount;
    // });
    selectedCombo.combo_groups.forEach( eachComboGroup => {
      const countInCart = this.checkGroupItemsCount(eachComboGroup._id);               
      eachComboGroup['maxReached'] = countInCart >= eachComboGroup.itemCount;
      // if(countInCart >= eachComboGroup.itemCount){
        const cartCombo = this.store.selectSnapshot(ComboState.getCartCombo);
        let foundCartGroup;
        eachComboGroup.subHeadings.forEach((eachSubheading) => {
          eachSubheading.groupItems = eachSubheading.groupItems.map(
            (eachGroupItem) => {
              eachGroupItem['inComboCart'] = false;
              if (cartCombo) {
                foundCartGroup = cartCombo.comboGroups.find(
                  (cartComboGroup) =>
                    cartComboGroup.groupId === eachComboGroup._id
                );
                if (foundCartGroup) {
                  const foundCartComboItem = foundCartGroup.groupItems.find(
                    (cartGroupItem) =>
                      cartGroupItem.groupMenuItemId === eachGroupItem._id
                  );
                  eachGroupItem['inComboCart'] = !!foundCartComboItem;
                }
              }
              return eachGroupItem;
            }
          );
        });
      // }
    });
    // console.log(selectedCombo);
  }


    checkSelectedComboMenuItemExistInCurrentCart(formattedMenuItem,existingComboGroups,menuItemIdInGroup){
      let countUpdatedMenuItem; 
      let menuItemExistWithSameCustomization= true;
      // if(!existingCartComboStore)
      // menuItemExistWithSameCustomization = false;
      // else {
        // for(let i=0;i<existingCartComboStore.comboGroups.length;i++){
        //   let existingComboGroups = existingCartComboStore.comboGroups[i]
          let reversalOfGroupitems = JSON.parse(JSON.stringify(existingComboGroups.groupItems.reverse()));
          let menuItemIndexInCart = reversalOfGroupitems.findIndex(grpItem=> grpItem.groupMenuItemId == menuItemIdInGroup)
          if(menuItemIndexInCart > -1){
          let freeTextModifierAdded = false;
          let portionValidation = false;
          if(formattedMenuItem?.cartFreeTextModifiers?.length > 0){
            if(formattedMenuItem?.cartFreeTextModifiers.find(freeTextMod=> (freeTextMod.value && freeTextMod.value.length > 0))){
              // if a free text modifier is added to cart with a text entered,the menu item will be treated as a new menu item.
              freeTextModifierAdded = true;
            }
          }
          
          let similarItemInCart = existingComboGroups.groupItems[menuItemIndexInCart];
          if(similarItemInCart?.portion && formattedMenuItem?.portion){ 
            if(similarItemInCart.portion == formattedMenuItem.portion){
              portionValidation = true;
            } else {
              portionValidation = false;
            }
          } else if(!similarItemInCart?.portion && !formattedMenuItem?.portion){
            portionValidation = true;
          } else {
            portionValidation = false;
          }

          if(portionValidation && !freeTextModifierAdded && similarItemInCart?.cartModifiers?.length == formattedMenuItem?.cartModifiers?.length){
            
            for(let cm=0;cm<similarItemInCart.cartModifiers.length;cm++){
              let similarCartModifier = similarItemInCart.cartModifiers[cm]
              let cartModIndex = formattedMenuItem?.cartModifiers.findIndex((crtMod)=>crtMod.cartModifier == similarCartModifier.cartModifier);
              if(cartModIndex > -1){
                let formattedCartModifier = formattedMenuItem.cartModifiers[cartModIndex]
                if(formattedCartModifier?.cartModifierItems?.length == similarCartModifier.cartModifierItems?.length){
                  for(let cmItem=0;cmItem<similarCartModifier.cartModifierItems.length;cmItem++){
                    let similarCartModifierItem = similarCartModifier.cartModifierItems[cmItem]
                    let cartModItemIndex = formattedCartModifier.cartModifierItems.findIndex((crtModItem)=>crtModItem.cartModifierItem == similarCartModifierItem.cartModifierItem);
                    if(cartModItemIndex>-1){

                      if(formattedCartModifier.cartModifierItems[cartModItemIndex].cartModifierSubItems?.length == similarCartModifierItem?.cartModifierSubItems?.length){
                        for(let cmSubItem =0;cmSubItem<similarCartModifierItem.cartModifierSubItems.length;cmSubItem++){
                          let cartSubModItemIndex = formattedCartModifier.cartModifierItems[cartModItemIndex].cartModifierSubItems.findIndex((crtSubModItem)=>crtSubModItem.cartModifierSubItem == similarCartModifierItem.cartModifierSubItems[cmSubItem].cartModifierSubItem);
                          if(cartSubModItemIndex > -1){
                            menuItemExistWithSameCustomization = true; 
                          }
                           else {
                            menuItemExistWithSameCustomization = false; 
                            break;
                          }
                        }
                      } else {
                        menuItemExistWithSameCustomization = false; 
                        break;
                      }
                      // for(let cmSubItem =0;cmSubItem<similarCartModifierItem.cartModifierSubItems.length;cmSubItem++){
                      //   let similarCartModifierSubItem = similarCartModifierItem.cartModifierSubItems[cmSubItem];
                      //   if(formattedCartModifier.cartModifierItems[cartModItemIndex].cartModifierSubItems.length == similarCartModifierSubItem.length){

                      //   } else {
                      //     menuItemExistWithSameCustomization = false; 
                      //   }
                      // }
                    } else {
                      menuItemExistWithSameCustomization = false; 
                    }
                  }
                } else {
                  menuItemExistWithSameCustomization = false;
                }
              } else {
                menuItemExistWithSameCustomization = false;
              }
            }
            if(menuItemExistWithSameCustomization){
              let updatedCount = similarItemInCart.count +formattedMenuItem.count;
              countUpdatedMenuItem = {...formattedMenuItem};
              countUpdatedMenuItem['count'] = updatedCount;
            }

          } else {
            menuItemExistWithSameCustomization = false;
          }
          } else {
          menuItemExistWithSameCustomization = false;
          }
        // }
      // }


    if(menuItemExistWithSameCustomization)
      return [menuItemExistWithSameCustomization,countUpdatedMenuItem];
    else 
      return [menuItemExistWithSameCustomization,formattedMenuItem];
  }
}

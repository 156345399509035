import { Component, OnInit,Output,EventEmitter  } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { environment,SharedService } from '@moduurnv2/libs-orderingapp/src/core';
import { EmailLoginComponent } from '../email-login/email-login.component';
import {EmailForgotPasswordBaseComponent} from '@moduurnv2/libs-orderingapp'
import { ModalService } from '../../../shared/modal/modal.service';
@Component({
  selector: 'moduurnv2-email-forgot-password',
  templateUrl: './email-forgot-password.component.html',
  styleUrls: ['./email-forgot-password.component.scss']
})
export class EmailForgotPasswordComponent extends EmailForgotPasswordBaseComponent implements OnInit {
  @Output() modalValue: EventEmitter<any> = new EventEmitter<any>();
  _modalClose: any = null;
  constructor(store:Store,_shared:SharedService, private modal: ModalService) {
    super(store,_shared)
   }

  ngOnInit(): void {
  }

  set modalClose(modalRef: any) {
    this._modalClose = () => {
      this.modal.closeModal(modalRef);
    };
  }
  goBack() {
    this.modalValue.emit('LOGIN');
    if(this._modalClose)
    this._modalClose();
  }

}

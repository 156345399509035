import { State, Selector, StateContext, Action, Store } from '@ngxs/store';

import { LocationDefaults } from '../models';
import { FetchLocationDefaults,SetLocationStatusLocal } from '../actions';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { LocationService, SharedService } from '../services';
import { LocationState } from './location.state';
import { DeliveryState } from './delivery-methods.state';
import { throwError } from 'rxjs';

export class LocationDefaultsStateModel {
  locationDefaultDetails: LocationDefaults;
}

@State<LocationDefaultsStateModel>({
  name: 'locationDefaults',
  defaults: {
    locationDefaultDetails: undefined,
  },
})
@Injectable()
export class LocationDefaultState {
  constructor(
    private _locationService: LocationService,
    private store: Store,
    private shared:SharedService
  ) {}

  @Selector()
  static getLocationDefaults(state: LocationDefaultsStateModel) {
    return state.locationDefaultDetails;
  }

  @Action(FetchLocationDefaults)
  SetDefaultLocation(
    { setState }: StateContext<LocationDefaultsStateModel>,
    { payload }: any
  ) {
    const futureTime = payload && payload.futureTime || null;
    const selectedVenue = payload && payload.selectedVenue || undefined;
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    let selectedLocId;
    let selectedDeliveryCode;

    const selectedDelivery = this.store.selectSnapshot(
      DeliveryState.getSelectedDelivery
    );
    if (selectedDelivery && selectedDelivery.textCode)
      selectedDeliveryCode = selectedDelivery.textCode;
    else if (
      selectedLocation &&
      selectedLocation['deliveryTypes'] &&
      selectedLocation['deliveryTypes'].length
    )
      selectedDeliveryCode = selectedLocation['deliveryTypes'][0].textCode;

    if (selectedLocation && selectedLocation._id) {
      selectedLocId = selectedLocation._id;
    return this._locationService
      .fetchLocationDefaults(selectedLocId, selectedDeliveryCode, futureTime,selectedVenue)
      .pipe(
        tap((response) => {
          if (response) {
            if(response.isUnSubscribed){
              this.shared.clearLocation();
            } else{
              this.store.dispatch(new SetLocationStatusLocal(response))
            setState({
              locationDefaultDetails: response,
            });
          }
          } else throw response;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
  else
    return {}
}
}

import {
  BaseComponent,
  environment,
  LocationState,
  DeliveryState,
  Delivery,
  AddressState,
  Address,
  VenueManagementState,Venue,
  WhitelabelState,
  QRScanDetails
} from '@moduurnv2/libs-orderingapp/src/core';
import { OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil } from 'rxjs/operators';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';


export class VenueUpdateSelectorBaseComponent extends BaseComponent
  implements OnInit {
  viewUpdateVenueModalStatus: boolean = false;
  addressBookStatus: boolean = false;
  selectedLocation: any;
  selectedVenueHeading: string;
  selectedVenue: Venue;
  loungeName: string;
  selectedDelivery
  deliveryLocation: any;
  noSelectionMsg:string ='';
  @Select(AddressState.getDeliveryAddress) DeliveryAddress$: Observable<
    Address
  >;
  @Select(VenueManagementState.getSelectedPickUpLocation) selectedPickUpLocation$: Observable<
    Venue
  >;
  @Select(VenueManagementState.getSelectedVenue) selectedVenue$;
  @Select(VenueManagementState.getSelectedRoom) selectedRoom$: Observable<
  Venue
  >;
  // @Select(VenueManagementState.getSelectedTakeOut) selectedTakeOut$: Observable<
  // Venue
  // >;
  deliveryAddress: String;
  qrScanDetails:QRScanDetails;
  constructor(public store: Store, public modal: ModalService, public translate:TranslateService) {
    super();
    this.loungeName = this.store.selectSnapshot(DeliveryState.getDeliveryLocation);
  }

  ngOnInit() {
    this.qrScanDetails = this.store.selectSnapshot(WhitelabelState.getQRScanDetails);
    this.selectedLocation = this.store.selectSnapshot(LocationState.getSelectedLocation);
    }
    
  ngDoCheck(){
    this.setInitialData();
  }

  setInitialData(){
    this.selectedDelivery = this.store.selectSnapshot(
      DeliveryState.getSelectedDelivery
    );
    this.setVenue(this.selectedDelivery.textCode);
  }

  setVenue(selectedDelivery){
    this.selectedVenue = {name:''};
    if(this.qrScanDetails?.venueName && this.selectedDelivery.textCode == this.qrScanDetails.deliveryType && this.qrScanDetails.locationId == this.selectedLocation?._id){
      this.noSelectionMsg = this.translate.instant('select-a-table');
      this.selectedVenue$.pipe(takeUntil(this.destroy$)).subscribe((venue) => {
        if (venue) {
          this.selectedVenue = venue;
          this.selectedVenueHeading = this.qrScanDetails?.venueType.text;
        }
      });
    } else {
    switch (selectedDelivery) {
      case 'DELIVERYTYPE08':
        this.noSelectionMsg = this.translate.instant('select-a-table');
        this.selectedVenue$
        .pipe(takeUntil(this.destroy$))
        .subscribe((venue) => {
          if (venue) {
            this.selectedVenue = venue;
            this.selectedVenueHeading = 'ORDERING AT';
          }
        });
        break;
      case 'DELIVERYTYPE09':
        this.noSelectionMsg = this.translate.instant('select-a-pickup');
        this.selectedPickUpLocation$
        .pipe(takeUntil(this.destroy$))
        .subscribe((venue) => {
          if (venue) {
            this.selectedVenue = venue;
            this.selectedVenueHeading = this.translate.instant('pick-up-from');
          }
        });
        break;
      case 'DELIVERYTYPE10':
        this.noSelectionMsg = this.translate.instant('select-delivery-room');
        this.selectedRoom$
        .pipe(takeUntil(this.destroy$))
        .subscribe((venue) => {
          if (venue) {
            this.selectedVenue = venue;
            this.selectedVenueHeading = this.translate.instant('delivering-to');
          }
        });
        break;
      default:
        break;
    }
   }
  }

  showChangeButton(){
    if((this.selectedDelivery?.textCode !== 'DELIVERYTYPE08' && this.selectedDelivery?.textCode !== 'DELIVERYTYPE10') && ((!this.selectedVenue) || (this.selectedVenue && !this.selectedVenue.allowSchedule))){
      if(this.qrScanDetails?.venueName && this.selectedDelivery.textCode == this.qrScanDetails.deliveryType && this.qrScanDetails.locationId == this.selectedLocation?._id){
        return false;
      }
      return true;
    }
    return false;
  }

}

import { Component, OnInit } from '@angular/core';
import {EmailVerificationBaseComponent} from '@moduurnv2/libs-orderingapp'

@Component({
  selector: 'moduurnv2-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent extends EmailVerificationBaseComponent implements OnInit {

  constructor() {
    super()
   }

  ngOnInit(): void {
  }

}

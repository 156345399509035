import { Combo } from "../models";
import { MenuItems } from "../models/cart.interface";
import {CartCombo} from '../models/combo.interface';

export class FetchCombos {
  static readonly type = '[COMBO] FetchCombos';

  constructor(public payload: string) {}
}

export class AddItemToCombo {
  static readonly type = '[COMBO] AddItemToCombo';

  constructor(public payload: MenuItems | any,public groupId: string) {}
}
export class UpdateItemCountCombo {
  static readonly type = '[COMBO] UpdateItemCountCombo';

  constructor(public payload: MenuItems | any,public groupId: string) {}
}
export class UpdateCartCombo {
  static readonly type = '[COMBO] UpdateCartCombo';

  constructor(public payload: MenuItems | any,public groupId: string) {}
}
export class AddCartCombo {
  static readonly type = '[COMBO] AddCartCombo';

  constructor(public payload: MenuItems | any,public groupId: string) {}
}
export class ClearComboCart {
  static readonly type = '[COMBO] ClearComboCart';

  constructor() {}
}

export class ClearSelectedCombo {
  static readonly type = '[COMBO] ClearSelectedCombo';

  constructor() {}
}

export class AddComboToCart {
  static readonly type = '[COMBO] AddComboToCart';

  constructor() {}
}

export class UpdateEntireCartCombo {
  static readonly type = '[COMBO] UpdateEntireCartCombo';

  constructor(public payload: CartCombo) {}
}


import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import {  OnInit } from '@angular/core';
import { BaseComponent, SharedService} from '@moduurnv2/libs-orderingapp/src/core';
import { YepdeskEventState } from '@moduurnv2/libs-orderingapp/src/core/state';
import { takeUntil } from 'rxjs/operators';
import { GetYepdeskEventAction} from '@moduurnv2/libs-orderingapp/src/core/actions';
import { Store } from '@ngxs/store';

  export class YepdeskEventsBaseComponent extends BaseComponent implements OnInit {
    @Select(YepdeskEventState.getEvents) events$! : Observable<any>;
    initialevent:any
  events:any
    isMobileView: boolean;
  constructor(private store:Store , sharedService: SharedService) {
    super();
    sharedService.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
  }
    ngOnInit(): void {
      this.store.dispatch(new GetYepdeskEventAction())
      this.events$.pipe(takeUntil(this.destroy$)).subscribe(
        data => {
          this.initialevent = data;
          this.events = data;
        });
     }
     routepage(event:any){
      window.open(event.routingURL, "_blank");

    }

    loadDate(event:any){
      let startDate = new Date(event.startTime);
      let endDate = new Date(event.endTime);
     let date = this.getMonthInWords()[startDate.getMonth()] +' '+startDate.getDate() +' - '+
                                         this.getMonthInWords()[endDate.getMonth()] +' '+endDate.getDate()+ ' ' + endDate.getFullYear();
      return date
  }
  getMonthInWords(){
    return [ "January", "February", "March", "April", "May", "June",
             "July", "August", "September", "October", "November", "December" ];
  }

  stripLocation(event){
    let location = event.locationAddress.split(",")
    console.log(location)
    return location[location.length - 1]+","+ location[location.length - 2]+","+ location[location.length - 3]
  }
  filterevents(input:string){
    this.events = []
    this.initialevent.forEach(element => {
      if((element.name.toLowerCase()).includes(input.toLowerCase())){
        this.events.push(element)
      }
    });

  }

}


  
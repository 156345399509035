import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appCreditCard]'
})
export class CreditCardDirective {

  creditCardPattern = new RegExp("^[0-9]{4} {0,1}[0-9]{4} {0,1}[0-9]{4} {0,1}[0-9]{4}$");
  validCode = '';
  @Output() ngModelChange = new EventEmitter();

  constructor() {
  }

  @HostListener('keydown', ['$event'])  onKeyDown(event) {
    let cardNumber = event.target['value'];
    setTimeout(() => {
      cardNumber = event.target['value'];
      let creditCardNumber = '';
      let formatedCardNumber = '';
      for (let pos = 0; pos < cardNumber.length; pos++) {
        if (cardNumber.charAt(pos) != ' ') {
          creditCardNumber = (pos == 0) ? cardNumber.charAt(pos) : (creditCardNumber + cardNumber.charAt(pos));
        }
      }
      let creditCardPattern = new RegExp(this.creditCardPattern);
      if(creditCardPattern.test(creditCardNumber)) {
        for (let pos = 0; pos < creditCardNumber.length; pos++) {
          if (pos == 4 || pos == 8 || pos == 12) {
            formatedCardNumber = formatedCardNumber + ' ' + creditCardNumber.charAt(pos);
          } else {
            formatedCardNumber = (pos == 0) ? creditCardNumber.charAt(pos) : (formatedCardNumber + creditCardNumber.charAt(pos));
          }
        }
        if (formatedCardNumber.length == 19) {
          event.target['value'] = formatedCardNumber;
          this.validCode = formatedCardNumber;
          this.ngModelChange.emit(formatedCardNumber);
        }
      } else {
        for (let pos = 0; pos < creditCardNumber.length; pos++) {
          if (pos == 4 || pos == 8 || pos == 12) {
            formatedCardNumber = formatedCardNumber + ' ' + creditCardNumber.charAt(pos);
          } else {
            formatedCardNumber = (pos == 0) ? creditCardNumber.charAt(pos) : (formatedCardNumber + creditCardNumber.charAt(pos));
          }
        }
        if (formatedCardNumber.length > 19) {
          event.target['value'] = this.validCode;
          this.ngModelChange.emit(event.target['value']);
        } else {
          event.target['value'] = formatedCardNumber.replace(/[^0-9 ]/g, "");
          this.ngModelChange.emit(event.target['value']);
        }
      }
    })
  }
}

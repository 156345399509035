import {
  BaseComponent,
  SetCartTip,
  CartState,
  CartResponse,
  WhitelabelState,
  Whitelabel,
  SweetAlertService,
  SetDefaultTipApplied,
} from '@moduurnv2/libs-orderingapp/src/core';
import { OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

export class ManageTipBaseComponent extends BaseComponent implements OnInit {
  @Output() updateTip = new EventEmitter();
  @Input() tipAmount: number;
  @Input() avalibleTips: any;
  @Input() selectedTip: any;

  @Input() subTotal: number;

  @Select(CartState.getCartResponse) cartResponse$: Observable<CartResponse>;
  @Select(WhitelabelState.getWhitelabel) whitelabel$: Observable<Whitelabel>;
  @Select(CartState.getDeaultTipApplied)
  deaultTipApplied$: any;

  selectedPercent: number;
  selectedAmount: number;
  showData: boolean = false;
  whitelabel: Whitelabel;
  deaultTipApplied: boolean = false;

  constructor(private store: Store, private sweetAlert: SweetAlertService, private translate: TranslateService) {
    super();
    this.getWhitelabel();
    this.deaultTipApplied$
      .pipe(takeUntil(this.destroy$))
      .subscribe((deaultTipApplied) => {
        if (deaultTipApplied) {
          this.deaultTipApplied = deaultTipApplied;
        }
      });
  }

  ngOnInit(): void {
    // this.showData = this.avalibleTips.allowTip;
    if (!this.deaultTipApplied) {
      if (
        this.avalibleTips &&
        this.avalibleTips.defaultTip &&
        this.avalibleTips.tipValues
      ) {
        let defaultTipValue = this.avalibleTips.tipValues[
          this.avalibleTips.defaultTip - 1
        ];
        let selectedTip = {
          type: '%',
          value: defaultTipValue.value,
        };
        this.onValueChanged(selectedTip.value, selectedTip.type, true);
      }

      this.store.dispatch(new SetDefaultTipApplied(true));
    }
    if (this.selectedTip)
      this.onValueChanged(this.selectedTip.value, this.selectedTip.type, false);
  }

  onCheckClicked() {
    this.showData = !this.showData;
    if (!this.showData) this.onValueChanged(0, '$', true);
  }

  onValueChanged(value, type, dispach = true, validate = false) {
    if (type == '%') {
      this.selectedPercent = value;
      this.selectedAmount = null;
      this.onValueSet({ type: '%', value: value }, dispach);
      if (value > 0) this.showData = true;
    } else {
      if (validate) {
        const pattern = /^\d{0,2}(\.\d{0,2})?$/;
        if (!pattern.test(value)) {
          setTimeout(() => {
            value = 0;
            let element = document.getElementById('tipAmount');
            element['value'] = value;
            this.selectedAmount = value;
            this.selectedPercent = null;
            this.onValueSet({ type: '$', value: value }, dispach);
            if (value > 0) this.showData = false;
          });
        } else {
          if (value > (this.subTotal * 25) / 100) {
            this.sweetAlert
              .confirm(
                this.translate.instant('are-you-sure'),
                this.translate.instant('tip-greater')
              )
              .then((choice) => {
                if (choice) {
                  this.selectedAmount = value;
                  this.selectedPercent = null;
                  this.onValueSet({ type: '$', value: value }, dispach);
                  if (value > 0) this.showData = true;
                } else {
                  setTimeout(() => {
                    value = 0;
                    let element = document.getElementById('tipAmount');
                    element['value'] = value;
                    this.selectedAmount = value;
                    this.selectedPercent = null;
                    this.onValueSet({ type: '$', value: value }, dispach);
                    if (value > 0) this.showData = false;
                  });
                }
              });
          } else {
            this.selectedAmount = value;
            this.selectedPercent = null;
            this.onValueSet({ type: '$', value: value }, dispach);
            if (value > 0) this.showData = true;
          }
        }
      } else {
        this.selectedAmount = value;
        this.selectedPercent = null;
        this.onValueSet({ type: '$', value: value }, dispach);
        if (value > 0) this.showData = true;
      }
    }
  }

  onValueSet(item, dispach) {
    if (item) {
      if (item.type != '%') {
        if (!item.value) item.value = 0;
        item.value = parseFloat(item.value).toFixed(2);
      }
    }
    let value = { tip: item };

    if (dispach) {
      this.store.dispatch(new SetCartTip(value));
      this.cartResponse$
        .pipe(takeUntil(this.destroy$))
        .subscribe((cartResponse) => {
          if (cartResponse && cartResponse.status == 1) {
            this.updateTip.emit(item);
          }
        });
    }
  }

  getWhitelabel() {
    this.whitelabel$.subscribe((response) => {
      this.whitelabel = response;
    });
  }
}

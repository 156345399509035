import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate, Router } from '@angular/router';
import {
  CartState,
  FetchLocationDefaults,
  LocationState,
  SetSelectedLocation,
  SweetAlertService,
} from '@moduurnv2/libs-orderingapp/src/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { FuturePickerComponent } from 'apps/orderingapp/web-orderingapp/src/app/features/shared';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class OrderingTimeDeactivateGuard
  implements CanDeactivate<CanComponentDeactivate> {
  constructor(private store: Store) {}
  canDeactivate(component: CanComponentDeactivate) {
    const { orderSettings }  = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    )|| {};
    if(orderSettings){
    const futureDate = this.store.selectSnapshot(CartState.getFutureDate);
      return futureTimeValidation(orderSettings, futureDate);
    }else {
      return true;
    }
  }
}

@Injectable()
export class OrderingTimeActivateGuard implements CanActivate {
  _modalClose: any;
  constructor(
    private store: Store,
    private sweetAlert: SweetAlertService,
    public modal: ModalService,
    public translate: TranslateService,
    public router: Router
  ) {}
  canActivate() {
    const { orderSettings } = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    const selectedDelivery = this.store.selectSnapshot(
      (app) => app && app.delivery && app.delivery.selectedDelivery
    );
    const futureDate = this.store.selectSnapshot(CartState.getFutureDate);
    if (futureTimeValidation(orderSettings, futureDate)) {
      return true;
    } else if(selectedDelivery?.textCode == 'DELIVERYTYPE10'){
      return true;
    } else {
      // this.sweetAlert.toast('error', 'Incorrect delivery time selected');
      const location = this.store.selectSnapshot(
        LocationState.getSelectedLocation
      );
      // this.store.dispatch(new FetchFreshSelectedLocation());
      this.modal.showFuture(FuturePickerComponent, {
        data: {
          orderSettings: location.orderSettings,
          locationName: location.name,
          location: location,
        },
        whenClosed: (data) => {
          if (data) console.log(data);
        },
      });
      return false;
    }
  }

  async orderFromOrganization(location, data?) {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );

    if (location.isLocationOpen) {
      if (selectedLocation && selectedLocation._id != location._id) {
        let selectedCart = this.store.selectSnapshot(CartState.getCart);
        if (selectedCart) {
          let vm = this;
          await this.sweetAlert
            .confirm(
              this.translate.instant('are-you-sure'),
              this.translate.instant('loc-change-clear-cart')
            )
            .then((choice) => {
              if (choice) {
                this.closePopup();
                this.router.navigate([`locations/${location._id}/home`]);
                this.store.dispatch(new SetSelectedLocation(location._id));
                this.store.dispatch(new FetchLocationDefaults(data));
              } else {
                return;
              }
            });
        } else {
          this.closePopup();
          this.router.navigate([`locations/${location._id}/home`]);
          this.store.dispatch(new SetSelectedLocation(location._id));
          this.store.dispatch(new FetchLocationDefaults(data));
        }
      } else {
        this.closePopup();
        this.router.navigate([`locations/${location._id}/home`]);
        this.store.dispatch(new SetSelectedLocation(location._id));
        this.store.dispatch(new FetchLocationDefaults(data));
      }
    }
  }

  closePopup() {
    if (this._modalClose) this._modalClose();
  }

  set modalClose(modalRef: any) {
    this._modalClose = (data) => {
      this.modal.closeComponentModal(modalRef, data);
    };
  }
}

const futureTimeValidation = (orderSettings, futureDate) => {
  if (futureDate) {
    const futureTime = moment(futureDate);
    const {
      minimumTime,
      minTimeUnit,
      maximumTime,
      maxTimeUnit,
      isAllow,
    } = orderSettings.futureOrder;
    if (isAllow) {
      if (minimumTime) {
        const setObject = {};
        setObject[minTimeUnit] = minimumTime;
        const minTime = moment().add(setObject);
        const diff = minTime.diff(futureTime, 'minutes'); // Added a 2 minute tolerence
        if (minTime.isAfter(futureTime) && -2 >= diff && diff <= 2) {
          return false;
        }
      }
      if (maximumTime) {
        const setObject = {};
        setObject[maxTimeUnit] = maximumTime;
        const maxTime = moment().add(setObject);
        const diff = maxTime.diff(futureTime, 'minutes'); // Added a 2 minute tolerence
        if (maxTime.isBefore(futureTime) && -2 >= diff && diff <= 2) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  } else if (
    (orderSettings &&
      orderSettings.asapOrder &&
      orderSettings.asapOrder.isAllow) ||
    (orderSettings &&
      orderSettings.asapOrder &&
      orderSettings.asapOrder.isAllow === false &&
      orderSettings &&
      orderSettings.futureOrder &&
      orderSettings.futureOrder.isAllow === false)
  ) {
    return true;
  }
  return false;
};

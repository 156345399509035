<div class="payment-methods-wrapper">
    <ng-container *ngIf="!hideSavedCard && paymentDetails.length">
      <ng-container *ngFor="let card of paymentDetails; let i = index">
        <div class="payment-method" *ngIf="card.card_id != selectedCard.card_id" data-qa="card-details"
          (click)="selectCard(card)">
          <table class="card-view card-single-view" [ngStyle]="getCardStyle(i)">
            <tr>
              <td><img [src]="getCard(card.card_type).icon" alt="card type"/></td>
              <!-- <td></td> -->
              <td>{{ card.number }}</td>
              <td>EXP {{ card.expiry_month + '/' + card.expiry_year }}</td>
            </tr>
          </table>
        </div>
        <div class="payment-method card" (click)="unselectCard(card)" *ngIf="card.card_id == selectedCard.card_id"
          data-qa="unselect-card">
          <div class="card-view card-expanded-view" [ngStyle]="getCardStyle(i)">
            <div class="bank-logo">
              <img [src]="getCard(card.card_type).icon" alt="card type"/>
            </div>
            <div class="card-number">{{ card.number }}</div>
            <table class="card-details">
              <tr class="head-row">
                <td>{{ 'CARD HOLDER' | translate }}</td>
                <td class="center">{{ 'EXPIRES' | translate }}</td>
                <!-- <td>CVV</td> -->
              </tr>
              <tr class="detail-row">
                <th>{{ card.name }}</th>
                <th class="center">
                  {{ card.expiry_month + '/' + card.expiry_year }}
                </th>
                <!-- <th>{{ card.card_details.cvv }}</th> -->
              </tr>
            </table>
          </div>
          <div class="delete-info">
            <button type="button" class="delete-card" (click)="deleteCard(card)" data-qa="delete-card">
              <img src="assets/images/Delete-Icon-Small.svg" alt="delete icon"/>
              {{ 'Delete Card' | translate }}
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>

  <ng-container *ngIf="!paymentDetails.length && isProfile">
    <div class="no-info">{{ 'no-payments'| translate }}</div>
  </ng-container>
  <ng-container *ngIf="!isProfile">

    <ng-container *ngFor="let method of paymentMethods">

      <div class="payment-method payment-list" (click)="onPaymentClick(method)" data-qa="payment-methods"
        *ngIf="method.active"
        [ngClass]="{ disable: (ackrooPaymentDetails && method.id == 6) || (ackrooPaymentDetails && method.id == 5) || (ackrooPaymentDetails && method.id == 3 && ackrooPaymentDetails['isAllAmountPayedFromGiftCard']) }">
        <div class="payment-method-details">
            <img [src]="method.icon" class="method-icon" alt="payment method"/>
          <p>{{ method.name | translate }}</p>
        </div>
        <div class="right-arrow-wrapper">
            <img src="assets/images/Arrow-Right.svg" class="right-arrow-icon" alt="arrow icon"/>
        </div>
      </div>

    </ng-container>

  </ng-container>
</div>
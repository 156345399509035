import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import {
  LocationState,
  PaymentMethodState,
  LocationDefaultState
} from '@moduurnv2/libs-orderingapp/src/core';
import { Store, } from '@ngxs/store';
@Injectable()
export class PaymentSelectionGuard implements CanActivate {
  constructor(private router: Router,private store:Store) { }
  canActivate() {
    if(this.checkNoPaymentOrdering() == false){
    const selectedPaymentMethod = this.store.selectSnapshot(PaymentMethodState.getSelectedPayment);
    if(selectedPaymentMethod){
      return true;
    } else {
      let selectedLocation = this.store.selectSnapshot(
        LocationState.getSelectedLocation
      );
      this.router.navigate([`/locations/${selectedLocation._id}/order`]);
      return false;
    }
    } else {
      return true;
    }
  }

  checkNoPaymentOrdering(){
    const locationDefaults = this.store.selectSnapshot(
      LocationDefaultState.getLocationDefaults
    );
    if (
      locationDefaults &&
      locationDefaults?.locationData?.orderSettings?.allowNoPaymentOrdering
    ) {
      return locationDefaults.locationData.orderSettings.allowNoPaymentOrdering;
    } else {
      return false;
    }
  }
}
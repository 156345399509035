import { Component } from '@angular/core';
import { PaymentMethodBaseComponent ,OrderLevelModifierState,SetPaymentSelected} from '@moduurnv2/libs-orderingapp/src';
import { GiftLoyaltyLoginComponent }  from '../../payment-method/components/gift-loyalty/login/gift-loyalty-login.component';
import { GiftLoyaltyCardComponent } from '../../payment-method/components/gift-loyalty/card-details/gift-loyalty-card.component';
import { ResendEmailVerificationComponent } from '../../payment-method/components/gift-loyalty/resend-email-verification/resend-email-verification.component';
import {TranslateService} from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { LocationDefaults, LocationDefaultState, SweetAlertService} from '@moduurnv2/libs-orderingapp/src/core';
import { SharedService,GiftLoyaltyMethodState,FetchGiftLoyaltyCards } from '@moduurnv2/libs-orderingapp/src/core';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil } from 'rxjs/operators';
import { MealProgramDetailsComponent } from '../../payment-method/components/student-meal-program/meal-program-details/meal-program-details.component';
import { SetLoginFrom } from '@moduurnv2/libs-orderingapp/src';

@Component({
  selector: 'moduurnv2-payment',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss'],
})
export class PaymentMethodComponent extends PaymentMethodBaseComponent {
  isPaymentClicked:boolean = false;
  orderSettings: any;
  @Select(LocationDefaultState.getLocationDefaults)
  locationDefaults$: Observable<LocationDefaults>;
  studentMeal: { id: number; name: string; text: string; icon: string; type: string; active: boolean; };
  constructor(
    store: Store,
    router: Router,
    sweetAlert: SweetAlertService,
    shared: SharedService,
    modal: ModalService,
    translate: TranslateService
  ) {
    super(store, router, sweetAlert, shared, modal, translate);
    this.locationDefaults$
    .pipe(takeUntil(this.destroy$))
    .subscribe((locationDefaults) => {
      if (locationDefaults) {
        this.orderSettings = locationDefaults.locationData.orderSettings;
      }
    });
    
  }

  
  getGiftCardsAndLoyaltyComponentRef(item) {
    if (item.id == 4) {
      if (!this.isMobileView) {
        if (this.isGuestUser()) return GiftLoyaltyLoginComponent;
        else if(this.registeredUser && !this.emailValidated)
          return ResendEmailVerificationComponent;
        else return GiftLoyaltyCardComponent;
      }
    }
    return undefined;
  }

  geStudentMealProgramComponentRef(item) {
    if (item.id == 6) {
      if (!this.isMobileView) {
         return MealProgramDetailsComponent;
      }
    }
    return undefined;
  }
  getGiftCardsAndLoyaltyTitle(item) {
    if (item.id == 4) {
      return this.translate.instant('gift-loyalty-card');
    }
    return '';
  }

  getStudentsMealProgramTitle(item) {
    if (item.id == 6) {
      return this.translate.instant('meal-title');  
    }
    return '';
  }

  onPaymentClick(item){
    this.store.dispatch(new SetPaymentSelected(true));
    if(this.checkOrderLevelModifiers()){
      this.isPaymentClicked = true;
      let giftCardsAndLoyaltyComponent = this.getGiftCardsAndLoyaltyComponentRef(item);
      const giftCardsAndLoyaltyTitle = this.getGiftCardsAndLoyaltyTitle(item);
      let MealProgramDetailsComponent = this.geStudentMealProgramComponentRef(item);
      const studentsMealProgramTitle = this.getStudentsMealProgramTitle(item);
      // this.store.dispatch(new SetLoginFrom("payment"))
   
      if(item.id == 6){
        this.desktopView.emit({ isdesktop: this.isMobileView, type: 4 });
        if(this.isPaymentClicked){
          this.openStdentsMealProgram(
            MealProgramDetailsComponent,
            studentsMealProgramTitle
          );
          this.isPaymentClicked = false;
        }
      }
      else if(item.id == 4 && !this.isGuestUser()){
        this.desktopView.emit({ isdesktop: this.isMobileView, type: 4 });
        if(this.registeredUser && this.isPaymentClicked){
          this.openGiftCardsAndLoyalty(
            giftCardsAndLoyaltyComponent,
            giftCardsAndLoyaltyTitle
          );
          this.isPaymentClicked = false;
        }
        else
        this.checkForUserValidity(item,
          giftCardsAndLoyaltyTitle);
      } else {
        this.onClick(item,
          giftCardsAndLoyaltyComponent,
          giftCardsAndLoyaltyTitle)
          this.isPaymentClicked = false;
      }
    }
    else{
      this.sweetAlert.toast('error', 'Sorry, Please select the required items to continue.');
    }
  }

  checkForUserValidity(item,
    giftCardsAndLoyaltyTitle){
    this.store.dispatch(new FetchGiftLoyaltyCards());
    this.savedCards$.pipe(takeUntil(this.destroy$)).subscribe((giftCards) => {
      if(giftCards)
      this.GiftCardErrResponse$.subscribe(res=>{
        if(this.isPaymentClicked){

        if (
          res &&
          res.error &&
          res.error === 2103
        ) {
          this.emailValidated = false;
          this.giftCard = undefined;
          this.registeredUser = true;
        }

        // if(this.registeredUser){
          this.onClick(item,
            this.getGiftCardsAndLoyaltyComponentRef(item),
            giftCardsAndLoyaltyTitle);
        // }
          }
          this.isPaymentClicked = false;
      })


   
    });
  }
  checkOrderLevelModifiers(){
    if(this.orderSettings.isAllowOrderLevelModifier){
    let modifiers = this.store.selectSnapshot(OrderLevelModifierState.getModifiers);
    if(modifiers && modifiers.length){
      let isSelected = modifiers.every(function(element, index) {
        console.log(element);
        if(element && element.isForced){
          if(element.isInCart){
            return true;
          }
          else{
            return false;
          }
  
        }
        else
          return true;
      });
  
     return isSelected;
    }
    else{
      return true;
    }
  }
  else{
    return true;
  }
   
  }



}

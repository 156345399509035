// angular
import { Injectable } from '@angular/core';

// app
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { PromotionalCategory } from '../models';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PromotionalCategoryService {
  private PromotionalCategoryinit: PromotionalCategory ={
    _id :'01',
    name : 'Variously Vegan',
    items : [
      {
        _id: '1',
        name: 'Grilled Carrots',
        price: '30.00',
        image: '/assets/images/variouslyveg-2.png'
      },
      {
        _id: '2',
        name: 'Sesame Cucumber Salad',
        price: '30.00',
        image: '/assets/images/variouslyveg-3.png'
      },
      {
        _id: '3',
        name: 'Crispy tomato',
        price: '30.00',
        image: '/assets/images/variouslyveg-2.png'
      },
      {
        _id: '4',
        name: 'Alu gobi',
        price: '30.00',
        image: '/assets/images/variouslyveg-3.png'
      }
    ]
  } ;
  constructor(private http: HttpClient) {}

  fetchPromotionalCategory(organization_id?: string): Observable<any> {
    return of(this.PromotionalCategoryinit).pipe();
  }
}

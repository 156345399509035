import { Deals } from '../models';

export class FetchDeals {
  static readonly type = '[Deals] FetchList';

  constructor( public cartId?:string, public subTotal?:number ) {}
}
export class ValidateDeal {
  static readonly type = '[Deals] ValidateDeal';

  constructor( public cartId:string, public subTotal:number ) {}
}

export class SelectDeal {
  static readonly type = '[Deals] Add';

  constructor(public coupon: Deals) {}
}

export class RemoveDeal {
  static readonly type = '[Deals] Remove';

  constructor() {}
}

export class ClearDeals {
  static readonly type = '[Deals] ClearDeals';

  constructor() {}
}
export class ClearAllDeals {
  static readonly type = '[Deals] ClearAllDeals';

  constructor() {}
}

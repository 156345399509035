import { Component } from '@angular/core';
import { OrderCompleteBaseComponent } from '@moduurnv2/libs-orderingapp/src';
@Component({
  selector: 'moduurnv2-customer-card-info',
  templateUrl: './customer-card-info.component.html',
  styleUrls: ['./customer-card-info.component.scss'],
})
export class CustomerCardInfoComponent extends OrderCompleteBaseComponent {

}

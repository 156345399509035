import { Component, OnInit, Input } from '@angular/core';
import { AddressDetailsBaseComponent } from '@moduurnv2/libs-orderingapp/src/features/ui/base/address-card-details-component';
import { AddressMapComponent } from '../address-map/address-map.component';

@Component({
  selector: 'moduurnv2-address-details',
  templateUrl: './address-details.component.html',
  styleUrls: ['./address-details.component.scss']
})
export class AddressDetailsComponent extends AddressDetailsBaseComponent {

  openMapModal(){
    this.modal.showSmallModal(AddressMapComponent,{data:{modalMapStatus:true,isAddressBook:true}});
  }
}


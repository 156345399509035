import { Injectable } from '@angular/core';
import {
  Auth,
  AuthEmailState,
  AuthState,
} from '@moduurnv2/libs-orderingapp/src';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { UserSpecificationDataService } from './user-specification-data/user-specification-data.service';

import { zendeskWebwidgetConfig } from './zendesk-webwidget.model';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';

function getWindow(): any {
  return window;
}

@Injectable()
export class zendeskWebwidgetService {
  public window;
  private window1;
  private initialized = false;
  public zE;
  loggedInUserDetails: any;
  appVersion: string = Config.appVersion;
  authSubscription: Subscription;
  @Select(AuthState.getCommonAuthDetails)
  loggedInUserDetails$: Observable<Auth>;
  chatSentStatus: boolean = false;

  constructor(
    private ngxZendeskWebwidgetConfig?: zendeskWebwidgetConfig,
    private userSpecificationDataService = new UserSpecificationDataService()
  ) {
    if (!this.ngxZendeskWebwidgetConfig.accountUrl) {
      throw new Error(
        'Missing accountUrl. Please set in app config via ZendeskWidgetProvider'
      );
    }

    this.window = getWindow();
    this.initZendesk(this.ngxZendeskWebwidgetConfig);
  }

  public initZendesk(config: zendeskWebwidgetConfig): Promise<boolean> {
    const window = this.window;

    // tslint:disable
    window.zEmbed ||
      (function (e, t) {
        var n,
          o,
          d,
          i,
          s,
          a = [],
          r = document.createElement('iframe');
        (window.zEmbed = function () {
          a.push(arguments);
        }),
          (window.zE = window.zE || window.zEmbed),
          (r.src = 'javascript:false'),
          (r.title = ''),
          (r.style.cssText = 'display: none'),
          (d = document.getElementsByTagName(config.injectionTag || 'head')),
          (d = d[d.length - 1]),
          d.parentNode.insertBefore(r, d),
          (i = r.contentWindow),
          (s = i.document);
        try {
          o = s;
        } catch (e) {
          (n = document.domain),
            (r.src =
              'javascript:var d=document.open();d.domain="' + n + '";void(0);'),
            (o = s);
        }
        (o.open()._l = function () {
          var e = this.createElement('script');
          n && (this.domain = n),
            (e.id = 'js-iframe-async'),
            (e.src = 'https://assets.zendesk.com/embeddable_framework/main.js'),
            (this.t = +new Date()),
            (this.zendeskHost = config.accountUrl),
            (this.zEQueue = a),
            this.body.appendChild(e);
        }),
          o.write('<body onload="document._l();">'),
          o.close();
      })();
    // tslint:enable
    this.window1 = window;

    this.setSettings(); //zendesk default settings

    this.window.zE(() => {
      this.ngxZendeskWebwidgetConfig.beforePageLoad(this.window.zE);
    });

    return this.finishLoading();
  }
/**
 * widget settings added
 */
  public setSettings() {
    this.authSubscription = this.loggedInUserDetails$.subscribe((res) => {
      this.loggedInUserDetails = res;
    });

    const customerId = this.loggedInUserDetails
      ? this.loggedInUserDetails.customerId
      : '';
    const customer = customerId ? 'CustomerId:' + customerId + '\n' : '';
    const userOs = this.userSpecificationDataService.getOsName();
    const userBrowser = this.userSpecificationDataService.getBrowserName();
    const userScreenWidth = this.userSpecificationDataService.getScreenWidth();
    const userScreenHeight = this.userSpecificationDataService.getScreenHeight();
    const deviceType = this.userSpecificationDataService.getdeviceType();
    const userDetails =
      customer +
      'OS:' +
      userOs +
      '\nBrowser:' +
      userBrowser +
      '\nScreen:' +
      userScreenWidth +
      'W*' +
      userScreenHeight +
      'H\nDeviceType:' +
      deviceType +
      '\nAppVersion:' +
      this.appVersion;

    const settingss = 'textdata' + userDetails;

    this.window1.zESettings = {
      webWidget: {
        // color: { theme: '#78a300' },
        contactForm: {
          ticketForms: [
            {
              id: Config.zendeskUserFormId, 
          fields: [
            { id: 'description', prefill: { '*': '' } },
            { id: 'subject', prefill: { '*': 'Customer Information' } },
            { id: Config.zendeskUserField, prefill: { '*': userDetails } },
          ],
        }]

        },
      },
    };
  }
  private finishLoading(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const timeout = setTimeout(() => {
        this.initialized = false;
        reject(Error('timeout'));
      }, this.ngxZendeskWebwidgetConfig.timeOut || 30000); // 30 seconds

      this.window.zE(() => {
        this.ngxZendeskWebwidgetConfig.callback(this.window.zE);
        this.initialized = true;
        this.zE = this.window.zE;
        clearTimeout(timeout);
        resolve(true);
      });
    });
  }

  public isInitialized(): boolean {
    return this.initialized;
  }
  //Activate zendesk
  public activate(options?) {
    this.window.zE(() => {
      this.window.zE.activate(options);
    });
  }

  // public setSettings(settings) {
  //   this.initZendesk(this.ngxZendeskWebwidgetConfig);
  //   this.test(settings);
  // }

  setSentStatusTrue() {
    this.chatSentStatus = true;
    return this.chatSentStatus;
  }
  
  getSentStatus() {
    return this.chatSentStatus;
  }
}

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import {WhitelabelState} from '@moduurnv2/libs-orderingapp/src/core';
import { SetLoginFrom } from '@moduurnv2/libs-orderingapp/src';
import { LocationState } from '@moduurnv2/libs-orderingapp/src';
@Component({
  selector: 'moduurnv2-button-drop-down',
  templateUrl: './button-drop-down.component.html',
  styleUrls: ['./button-drop-down.component.scss'],
})
export class ButtonDropDownComponent implements OnInit {
  constructor(public store: Store) {}

  @Input() letterForImage: string = '';
  @Input() buttonImage: string = '';
  @Input() buttonText: string = '';
  @Input() imageHeight: string = '';
  @Input() isHoverEffect: boolean = false;
  @Input() isBorderVisible: boolean = false;
  @Input() items: any = [];

  @Output('selectedItem') selectedTime = new EventEmitter();


  isDropDownVisible: boolean = false;
  enabledGiftandLoyality:boolean=false;
  seletedLocationId:any;
  ngOnInit(): void {
    let other = this.store.selectSnapshot(WhitelabelState.getOther);
    if(other && other.paymentGateway && other.paymentGateway.textCode == 'PAYMENTGATEWAY02' && other.loyaltyAndGiftCards && other.loyaltyAndGiftCards.isAllow)
    {
      this.enabledGiftandLoyality=true;
    }
    const selectedLocation = this.store.selectSnapshot(LocationState.getSelectedLocation);
    if(selectedLocation && selectedLocation._id){
      this.seletedLocationId = selectedLocation._id;
    }    
  }
  clickItem(item) {
    this.selectedTime.emit(item);
    this.isDropDownVisible = false;
    this.store.dispatch(new SetLoginFrom('locations'))
  }
  toggleDropDown() {
    this.isDropDownVisible = !this.isDropDownVisible;
  }
  closeDropDown() {
    this.isDropDownVisible = false;
  }
}

<div class="login-grid-container">
  <!-- <div class="grid-item login-head">
    <div
      class="login-image"
      [ngStyle]="{ 'background-image': 'url(' + loginHeaderImg + ')' }"
    >
      <div class="head-icon-outer" data-qa="back-button" (click)="goBack()">
        <img [src]="loginLeftArrowIcon" class="left-icon" alt="back button"/>
      </div>
      <div class="head-text-outer">
        <div class="head-text-main">{{ 'get-started' | translate }} !</div>
        <div class="head-text-sub">{{ 'create-account' | translate }}</div>
      </div>
    </div>
  </div> -->
  <div class="login-head">
    <span class="header-text">{{ "Let's create your account" }}</span>
    <img
      src="/assets/images/Close.svg"
      data-qa="close-button"
      (click)="closRegister()"
      class="close"
      aria-label="close"
      alt="close"
    />
  </div>
  <div class="grid-item login-body">
    <div class="content">
      <ng-container *ngIf="loginProviders">
        <div
          class="login-method"
          data-qa="signup-button"
          *ngFor="let provider of loginProviders"
          (click)="signUp(provider.textCode)"
        >
          <div class="login-provider-img">
            <div
              [ngStyle]="setImageGradientStyle(provider.gradient)"
              class="login-provider-img-outer"
            >
              <img [src]="provider.iconUrl" alt="login provider"/>
            </div>
          </div>
          <div class="login-text">
            <div class="grid-item provider-heading">
              {{ provider.text | translate }}
            </div>
            <div class="grid-item provider-arrow">
              <img [src]="loginRightArrowIcon" alt="login right arrow"/>
            </div>
            <div class="grid-item provider-subheading">
              {{ provider.textTagLine | translate }}
            </div>
          </div>
        </div>
      </ng-container>

      <div class="terms-conditions">
        <div class="error-text">
          <label *ngIf="isSignupClicked && !setTerms">
            {{ 'terms-conditions-error' | translate }}</label
          >
        </div>
        <div class="login-text options">
          <label class="provider-subheading user-login options-label">
            {{ 'disclaimer-text' | translate }}
            <span class="primary-color">
              <a target="blank" href="https://moduurn.com/privacy-policy">
                {{ 'terms-conditions' | translate }}
              </a>
            </span>
            <input type="checkbox" [ngModel]="setTerms" (click)="changeTerms($event)" />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </div>
    <div
      class="user-account backcolor"
      data-qa="email-login"
      (click)="emailLogin()"
    >
      <div class="login-text">
        <div class="provider-subheading user-login existing-user">
          {{ 'have-account' | translate }}
          <span>{{ 'login' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<!--
<moduurnv2-email-auth class="login-popup" *ngIf="pageToShow" [pageToShow]="pageToShow" (showEmailAuthModal)="showEmailAuthModal($event)"></moduurnv2-email-auth> -->

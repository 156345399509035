import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LoginBaseComponent } from '@moduurnv2/libs-orderingapp';
import { AuthService } from '@moduurnv2/web/core';
import {
  LogService,
  SetAgreeToTerms,
  WhitelabelState,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Store } from '@ngxs/store';
import { ModalService } from '../../../shared/modal/modal.service';
import { EmailLoginComponent } from '../email-login/email-login.component';
import { EmailSignUpComponent } from '../email-sign-up/email-sign-up.component';
import { AuthService as AzureAuthService } from '../../../core/auth.service';
import { NavigationBackService } from '@moduurnv2/libs-orderingapp/src/core/services/navigation-back.service';
@Component({
  selector: 'moduurnv2-login-desktop',
  templateUrl: './login-desktop.component.html',
  styleUrls: ['./login-desktop.component.scss'],
})
export class LoginDesktopComponent extends LoginBaseComponent
  implements OnInit {
  _modalClose: any = null;
  allowEmailAuth:boolean;

  constructor(
    private _authService: AuthService,
    router: Router,
    private _logService: LogService,
    store: Store,
    private modal: ModalService,
    private authService: AzureAuthService,  
    navigationService: NavigationBackService  
  ) {
    super(router, store,navigationService);
  }

  ngOnInit() {
    const {
      allowOnlyGuestUsers,
      isAzureSSO,
      allowEmailAuth,
    } = this.store.selectSnapshot(WhitelabelState.getOther);
    this.loginProviders = this.loginProviders.filter((provider) => {
      if (
        provider.textCode === 'LOGINPROVIDER04' &&
        allowOnlyGuestUsers === false &&
        allowEmailAuth !== false
      )
        return true;
      if (provider.textCode === 'LOGINPROVIDER05' && isAzureSSO.isAllow)
        return true;
    });
    
  }

  googleLogin() {
    this._authService
      .googleSignIn()
      .then((user) => {
        if (user) {
          this._authService.userDetails = user;
          localStorage.setItem('loggedInUser', JSON.stringify(user));
          this.goBack();
        }
      })
      .catch((err) => {
        this._logService.error(err);
      });
  }

  googleUserDetails() {
    this._authService.googleLoggedInUserDetails();
  }

  setImageGradientStyle(gradient) {
    return {
      'background-image': `linear-gradient(${gradient.primary}, ${gradient.secondary})`,
    };
  }
  emailLogin() {
    this.pageToShow = 'LOGIN';
    this.modal.showMediumModalDesktop(EmailLoginComponent);
  }
  signUp(providerTextCode?) {
    this.isSignupClicked = true;
    if(!this.setTerms)
      return;
    // if (this.allowEmailAuth === false || this.allowEmailAuth && this.setTerms === true) {
    // if (this.allowEmailAuth === false || this.allowEmailAuth) {
      switch (providerTextCode) {
        case 'LOGINPROVIDER03':
          break;
        case 'LOGINPROVIDER04':
          this.emailSignUp();
          break;
        case 'LOGINPROVIDER05':
          this.azureSSO();
          break;
        default:
          break;
      }
    // }
  }
  emailSignUp() {
    this.pageToShow = 'REGISTER';
    this.modal.showMediumModalDesktop(EmailSignUpComponent);
  }
  set modalClose(modalRef: any) {
    this._modalClose = () => {
      this.modal.closeModal(modalRef);
    };
  }
  closRegister() {
    if (this._modalClose) this._modalClose();
  }

  azureSSO() {
    console.log('azure');
    this.authService.login('locations');
  }
}

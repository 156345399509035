import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HeaderBaseComponent } from '@moduurnv2/features';

@Component({
  selector: 'moduurnv2-header',
  templateUrl: 'header.component.html',
})
export class HeaderComponent extends HeaderBaseComponent {
  constructor(public translate: TranslateService) {
    super();
  }
  changeLang(language: string) {
    this.translate.use(language);
  }
}

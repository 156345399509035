import { Component, OnInit,Input } from '@angular/core';
import {
  VenuePickupLocationTimeSelectionBaseComponent
} from '@moduurnv2/libs-orderingapp/src/features/ui/base/venue-pickup-location-time-selection-base.component';
import { Store, Select } from '@ngxs/store';
import {ModalService} from './../../../shared/modal/modal.service'
import { FuturePickerComponent } from '../../../shared';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'moduurnv2-pickup-location-update-selection',
  templateUrl: './pickup-location-update-selection.component.html',
  styleUrls: ['./pickup-location-update-selection.component.scss','./../../common-styles/venue-update-selection.scss']
})
export class PickupLocationUpdateSelectionComponent extends VenuePickupLocationTimeSelectionBaseComponent implements OnInit {

}

import { State, Selector, StateContext, Action, Store,Select } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

import { FetchGiftLoyaltyCards,SetSelectedGiftCard,RemoveGiftCard} from '../actions';
import { PaymentMethodService, GiftAndLoyaltyCardService } from '../services';
import { GiftCard} from '../models';
import { CartState } from './cart.state';
import { LocationState } from './location.state';
import { AuthEmailState } from './auth-email.state';
import { WhitelabelState } from './whitelabel.state';
import { AuthState } from './auth.state';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { throwError } from 'rxjs';

export class GiftLoyaltyMethodModel {
  cards: GiftCard[];
  errorObject:any;
  selectedCard : GiftCard; 
}

@State<GiftLoyaltyMethodModel>({
  name: 'giftloyaltyMethods',
  defaults: {
    cards: null,
    errorObject:{},
    selectedCard  : null,

  }
})
@Injectable()
export class GiftLoyaltyMethodState {
  constructor(
    private giftService: GiftAndLoyaltyCardService,
    private store: Store
  ) {}
  @Selector()
  static getSavedCards(state: GiftLoyaltyMethodModel) {
    return state.cards;
  }
  @Selector()
  static getErrorResponse(state: GiftLoyaltyMethodModel) {
    return state.errorObject;
  }
  @Action(FetchGiftLoyaltyCards)
  fetchGiftLoyaltyCards(
    { setState }: StateContext<GiftLoyaltyMethodModel>,
   // { payload }: FetchGiftLoyaltyCards
  ) {
    // resting to fix the card not showing while adding a new card
    setState({
      cards: [],
      errorObject:{},
      selectedCard : null,
    });

    let customer = this.store.selectSnapshot(AuthEmailState.getUserInfo);
    let config = this.store.selectSnapshot(WhitelabelState.getOther);
    let commonAuth = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    // if(!customer || !commonAuth?.isLoggedIn)
    if(!customer)
    {
      setState({
        cards: [],
        errorObject:{},
        selectedCard : null,
      });
      return;
    }

    if(config && config.loyaltyAndGiftCards && config.loyaltyAndGiftCards.loyaltyProgram){
      switch(config.loyaltyAndGiftCards.loyaltyProgram){
        case 'LOYALTYPROGRAM01' : {
          return this.giftService.getCards(customer.email, Config.organizationId).pipe(
            tap(response => {
              if (response) {
                if(response['error'])
                {
                  setState({
                    cards: [],
                    errorObject:response,
                    selectedCard : null,
                  });
                }
                else
                {
                  setState(
                    patch({
                      cards: response,
                      errorObject:{}
                    })
                    );
                }
      
      
              } else throw response;
            }),
            catchError(error => {
              return throwError(error);
            })
          );
        }
        break;
        case 'LOYALTYPROGRAM02' : {
          return this.giftService.getGivexCards(customer.user_id, Config.organizationId).pipe(
            tap(response => {
              if (response) {
                if(response['status'] != '200')
                {
                  setState({
                    cards: [],
                    errorObject:response,
                    selectedCard : null,
                  });
                }
                else
                {
                  console.log('Fetched Cards',response['cards']);
                  setState(
                    patch({
                      cards: response['cards'],
                      errorObject:{}
                    })
                    );
                }
      
      
              } else throw response;
            }),
            catchError(error => {
              return throwError(error);
            })
          );
        }
        break;
        default : {
          setState({
            cards: [],
            errorObject:{},
            selectedCard : null,
          });
          return;
        }
        break;
      }

    }
    else{
      setState({
        cards: [],
        errorObject:{},
        selectedCard : null,
      });
      return;
    }
   
  }

  @Action(SetSelectedGiftCard)
  selectCard(
    { setState }: StateContext<GiftLoyaltyMethodModel>,
   // { payload }: FetchGiftLoyaltyCards
  ) {
    let customer = this.store.selectSnapshot(AuthEmailState.getUserInfo);
    if(!customer)
    {
      setState({
        cards: [],
        errorObject:{},
        selectedCard : null,
      });
      return;
    }
    return this.giftService.getCards(customer.email, Config.organizationId).pipe(
      tap(response => {
        if (response) {
          if(response['error'])
          {
            setState({
              cards: [],
              errorObject:response,
              selectedCard : null,
            });
          }
          else
          {
            setState(
              patch({
                cards: response,
                errorObject:{},
                selectedCard : null,
              })
              );
          }


        } else throw response;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }


  @Action(RemoveGiftCard)
  removeCard(
    { setState }: StateContext<RemoveGiftCard>,
    { payload }: RemoveGiftCard
  ) {

    let customer = this.store.selectSnapshot(AuthEmailState.getUserInfo);
    let config = this.store.selectSnapshot(WhitelabelState.getOther);
    const location = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
  

    let other = this.store.selectSnapshot(WhitelabelState.getOther);
 
   
 let  data  = {
    customerId: customer ? customer.user_id : "",
    card: payload,
    loyaltyProgram : config.loyaltyAndGiftCards.loyaltyProgram,
    organizationId : Config.organizationId,
    locationId : location ? location._id : '',
    cardNumber : payload.cardnumber,

};
    return this.giftService.remove(data).pipe(
      tap(response => {
        if (response) {
         
          if (
            other &&
            other.paymentGateway &&
            other.paymentGateway.textCode
          ) {
            this.store.dispatch(new FetchGiftLoyaltyCards());
          }
          
        } else throw response;
      }),
      catchError(error => {
        return throwError(error);
      })
    );
  }


}

import { Component, OnInit } from '@angular/core';
import { AddPaymentCardBaseComponent } from '@moduurnv2/libs-orderingapp/src';
import { ModalService } from 'apps/orderingapp/web-orderingapp/src/app/features/shared/modal/modal.service';
import { SharedService } from '@moduurnv2/libs-orderingapp/src/core';
import { FormBuilder } from '@angular/forms';

import { Router } from '@angular/router';
import { Store, Select } from '@ngxs/store';
@Component({
  selector: 'moduurnv2-add-payment-card-desktop',
  templateUrl: './add-payment-card-desktop.component.html',
  styleUrls: ['./add-payment-card-desktop.component.scss'],
})
export class AddPaymentCardDesktopComponent extends AddPaymentCardBaseComponent {
  constructor(
    public fb: FormBuilder,
    public store: Store,
    public router: Router,
    public shared: SharedService,
    public modal: ModalService
  ) {
    super(fb, store, router, shared);
  }

  _modalClose: any = null;
  closePopup() {
    if (this._modalClose) this._modalClose();
  }

  set modalClose(modalRef: any) {
    this._modalClose = () => {
      this.modal.closeModal(modalRef);
    };
  }

  onSubmit() {
    this.saveToAccount = true;
    super.onSubmit(false);
    this.modal.closeAllModals();
  }
  onCancel() {
    this.closePopup();
  }
}

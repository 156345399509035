export interface LocationSelectionAnalytics {
    _id: string;
    name: string;
    analyticsTrackingType: string;
    customerId?:string;
    customerEmail?:string;
    customerName?:string;
    webUrl?:string;
    extraDetails?:any;
    pixelId?:any;
  }

  export interface CategorySelectionAnalytics {
    _id: string;
    name: string;
    analyticsTrackingType: string;
    customerId?:string;
    customerEmail?:string;
    customerName?:string;
    webUrl?:string;
    extraDetails?:any;
  }

  export interface ServiceTypeSelectionAnalytics {
    _id: string;
    deliveryType: string;
    analyticsTrackingType: string;
    customerId?:string;
    customerEmail?:string;
    customerName?:string;
    webUrl?:string;
    extraDetails?:any;
  }

  export interface ItemCheckoutAnalytics {
    items: Array<Items>;
    analyticsTrackingType: string;
    webUrl?:string;
    totalPeriodAmount:any;
    customerId?:string;
    customerEmail?:string;
    customerName?:string; 
  }

  export interface Items{
    id: string;
    item_price: string;
    quantity: any;
    itemName?:string;
  }


  export interface DealSelectionAnalytics {
    _id: string;
    name: string;
    analyticsTrackingType: string;
    discountAmount:any;
    discount:any;
    customerId?:string;
    customerEmail?:string;
    customerName?:string;
    webUrl?:string;
  }

  export interface PurchaseAnalytics {
    _id: string;
    amount: any;
    analyticsTrackingType: string;
    paymentType:string;
    totalGross:any;
    totalNet:any;
    tips:any;
    webUrl?:string;
    customerId?:string;
    customerEmail?:string;
    customerName?:string; 
  }

  export enum AnalyticsTrackingType {
    LOCATION_SELECTION = 'LOCATION_SELECTION',
    CATEGORY_SELECTION = 'CATEGORY_SELECTION',
    SERVICETYPE_SELECTION = 'SERVICETYPE_SELECTION',
    ITEM_CHECKOUT = 'ITEM_CHECKOUT',
    PURCHASE = 'PURCHASE',
    DEAL_APPLY = 'DEAL_APPLY',
    DEAL_VIEW = 'DEAL_VIEW',
  }
import { OnInit } from '@angular/core';
import { DeliveryState, SharedService } from '@moduurnv2/libs-orderingapp/src/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


export class ScreenSwitchWrapperBaseComponent implements OnInit {
    isMobileView: boolean;
    destroy$: Subject<boolean> = new Subject<boolean>();
    
    //singleLocation: boolean;

    constructor(private shared: SharedService, public store:Store) {}

    ngOnInit() {
         this.shared.display.pipe(takeUntil(this.destroy$)).subscribe(
      (isMobileView) => (this.isMobileView = isMobileView)
    );
    }
}

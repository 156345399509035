<div class="header-change-password" *ngIf="!isMobileView && showOldPassword">
  <span class="header-text">{{ 'change-password' | translate }}</span
  ><img
    src="/assets/images/Close.svg"
    data-qa="close-button"
    class="close"
    aria-label="close"
    (click)="closeChangePassword()"
    alt="close"
  />
</div>
<div class="sign-up-outer-container">
  <form [formGroup]="changePasswordForm" (ngSubmit)="resetPassword(changePasswordBtn)">
    <label *ngIf="showOldPassword" for="currentPassword" class="email-container"
      >{{ 'old-password' | translate }}
      <p class="mandatory">*</p></label
    >
    <div *ngIf="showOldPassword" class="row form-group">
      <input
        id="currentPassword"
        type="password"
        name="currentPassword"
        class="form-control md-input"
        class="password-text"
        placeholder="{{ 'password-placeholder' | translate }}"
        formControlName="currentPassword"
        password-toggle
        autofocus
      />
      <div
        class="text-danger"
        *ngIf="
          (changePasswordFormControl.currentPassword.touched ||
            formSubmitted) &&
          changePasswordFormControl.currentPassword.errors?.required
        "
      >
        {{ 'old-password-required' | translate }}
      </div>
    </div>
    <label for="password" class="email-container"
      >{{ 'new-password' | translate }}
      <p class="mandatory">*</p></label
    >
    <div class="row form-group password-input">
      <input
        type="password"
        id="password"
        name="password"
        class="form-control md-input"
        class="password-text"
        placeholder="{{ 'password-minimum-characters' | translate }}"
        formControlName="password"
        password-toggle
      />
      <div
        class="text-danger"
        *ngIf="
          (changePasswordFormControl.password.touched || formSubmitted) &&
          changePasswordFormControl.password.errors?.required
        "
      >
        {{ 'password-required' | translate }}
      </div>
      <span
        class="text-danger"
        id="passwordValidation"
        *ngIf="
          (changePasswordFormControl.password.touched || formSubmitted) &&
          changePasswordFormControl.password.errors?.passwordValidationError
        "
      >
        {{ 'password-minimum-characters' | translate }}
      </span>
    </div>
    <label for="confirmPassword" class="email-container"
      >{{ 'confirm-new-password' | translate }}
      <p class="mandatory">*</p></label
    >
    <div class="row form-group password-input">
      <input
        id="confirmPassword"
        type="password"
        name="confirmPassword"
        class="form-control md-input"
        class="password-text"
        placeholder=""
        formControlName="confirmPassword"
        password-toggle
      />
      <div
        class="text-danger"
        *ngIf="
          (changePasswordFormControl.confirmPassword.touched ||
            formSubmitted) &&
          formSubmitted &&
          changePasswordFormControl.confirmPassword.errors?.required
        "
      >
        {{ 'confirm-password-required' | translate }}
      </div>
      <span
        class="text-danger"
        *ngIf="
          changePasswordFormControl.confirmPassword.touched &&
          changePasswordFormControl.confirmPassword.errors?.passwordMismatch
        "
      >
        {{ 'password-not-match' | translate }}
      </span>
    </div>
    <div class="btn-container">
      <div class="sign-in-button-container">
        <button class="btn-submit" data-qa="change-password" type="submit"
        #changePasswordBtn
        id="change-password-submit">
          {{ 'save-changes' | translate }}
        </button>
      </div>
      <div
        *ngIf="!isMobileView && showOldPassword"
        class="sign-in-button-container"
      >
        <button
          class="btn-cancel"
          (click)="closeChangePassword()"
          data-qa="change-password"
          type="button"
        >
          {{ 'cancel' | translate }}
        </button>
      </div>
    </div>
    <div class="row response-container" [ngClass]="{ active: updateErrorFlag }">
      {{ result }}
    </div>
  </form>
</div>

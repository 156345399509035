<div class="modal-header">
  <div class="head-text">{{ 'coupon-details' | translate }}</div>
  <img
    [src]="close"
    class="close"
    data-qa="close-button"
    aria-label="close"
    (click)="closePopup()"
    alt="close button"
  />
</div>
<div
  class="deals-wrapper hide-scroll"
  [ngClass]="{ 'deals-wrapper-desktop': !isMobileView }"
>
  <div class="item-info" [class.image-exist]="dataPassedToModal.image">
    <div
      class="item-image"
      *ngIf="dataPassedToModal.image"
      [bg-image]="imageBase + dataPassedToModal.image"
    ></div>
    <div class="item-details">
      <div class="title">{{ dataPassedToModal.title }}</div>
      <div class="item-description">
        {{ dataPassedToModal.description }}
      </div>
      <button type="button" class="promoCode">
        {{ dataPassedToModal.promoCode }}
      </button>
    </div>
  </div>
  <div class="terms-n-conditions">
    <p class="header">{{ 'terms-and-conditions' | translate }}</p>
    <div class="border"></div>
    <div
      class="terms"
      *ngFor="let item of dataPassedToModal.details; let i = index"
    >
      {{ i + 1 }} . {{ item }}
    </div>
  </div>
</div>
<div class="btn-wrapper" [ngClass]="{ 'btn-wrapper-desktop': !isMobileView }">
  <ng-container *ngIf="dataPassedToModal.isAvailable; else unAvailableDeal">
    <button
      *ngIf="checkDeal?._id != dataPassedToModal._id"
      (click)="copyPromoCode(dataPassedToModal)"
    >
      {{ 'apply' | translate }}
    </button>
    <button
      *ngIf="checkDeal?._id == dataPassedToModal._id"
      (click)="removePromoCode()"
    >
      {{ 'remove' | translate }}
    </button>
  </ng-container>
  <ng-template #unAvailableDeal>
    <label> {{ 'not-applicable' | translate }}</label>
  </ng-template>
</div>

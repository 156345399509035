<!-- <div class="modal" [ngClass]="{ showmap: modalMapStatus }">
  <div class="modal-content"> -->
<div class="map-container">
  <div class="header">
    <span class="close" (click)="closeMap()">
      <img src="assets/images/back-arrow.svg" alt="back"/>
    </span>
    <span class="header-text">{{'add new adrs small' | translate }}</span>
  </div>
  <div class="map-display" id="map">
    <div class="row">
      <div class="firstcolumn"></div>

      <div class="secondcolumn">
        <div class="input">
          <div id="geocoder" class="search-location"></div>
        </div>
      </div>
    </div>
    <div class="loader" id="currentLocation"></div>
  </div>

  <div class="map-controls">
    <div class="control-header">{{ 'selected-delvry-adrs' | translate }}</div>
    <div id="location" class="location"></div>
    <hr class="border" />
    <button
      class="primary-button"
      (click)="continuetoDetails($event)"
      data-qa="address-continue"
      aria-label="address continue"
      [disabled]="isDisabled"
      [ngClass]="{ disable: isDisabled }"
    >
      {{ 'Continue-adrs' | translate }}
    </button>
  </div>
  <!-- </div>
</div> -->
</div>

import { Injectable } from '@angular/core';
import { SocialAuthService } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';
@Injectable()
export class AuthService {
  constructor(private socialAuthService: SocialAuthService) {}
  userDetails: SocialUser;
  googleSignIn(): Promise<SocialUser> {
    const options = {
      // ux_mode: 'redirect',
      // response_type: 'code',
      // enable_profile_selector: true,
      prompt: 'select_account',
    };
    return this.socialAuthService
      .signIn(GoogleLoginProvider.PROVIDER_ID, options)
      .then((result) => {
        return result;
      });
  }

  googleSignOut(): void {
    this.socialAuthService.signOut();
  }

  googleLoggedInUserDetails() {
    return this.socialAuthService.authState.subscribe((res) => {
      this.userDetails = res;
      return res;
    });
  }
}
